const iconSrc = {

  'Modules ES6': 'icon-javascript',
  // 'Les webservices': 'icon-javascript',
  // 'Expressions rationnelles': 'icon-javascript',
  // 'Intégration API': 'icon-javascript',
  'Flexbox CSS': 'icon-css3',
  'Sélecteurs CSS': 'icon-css3',
  'Responsive': 'icon-css3',
  'DOM & JS': 'icon-dom',
  'Jquery': 'icon-jquery',
  'Balises HTML': 'icon-html5',
  'Express - Templating': 'icon-express',
  'Express - Routes & requêtes HTTP': 'icon-express',
  'Les partials EJS': 'icon-express',
  'Les sessions': 'icon-express',
  'Les webservices': 'icon-webservices',
  'Dataviz': 'icon-database',
  'Requêtes avancées': 'icon-database',
  'Les modules': 'icon-express',
  'Les redirections': 'icon-express',
  'Intégration API': 'icon-wrench',
  // 'QCM de la semaine': 'icon-eval-check',
  'Composant React': 'icon-react',
  'Propriété React': 'icon-react',
  'État React': 'icon-react',
  'Reverse Data Flow': 'icon-react',
  'Échange Backend': 'icon-react',
  'Cycle de vie React': 'icon-react',
  'Router React': 'icon-react',
  'Champs de saisie React': 'icon-react',
  // 'Redux': 'icon-react',
  'Websocket': 'icon-react',
  'Expo React Native': 'icon-react',
  'Router React Native': 'icon-react',
  // 'Upload de fichiers': 'icon-react',
  'Les références': 'icon-react',
  'Localstorage React Native': 'icon-react',
  'La camera': 'icon-react',
  'Map React Native': 'icon-react',
  // 'Optimisation': 'icon-deploiement',
  'Pilotage': 'icon-cube',
  'Problématique': 'icon-cube',
  // 'Tests utilisateurs': 'icon-cube',
  // 'Mockup & wireframe': 'icon-cube',
  // 'User stories': 'icon-cube',
  // 'Storyboard': 'icon-cube',
  // "User journeys": 'icon-cube',
  // "Identification Problématique": 'icon-cube',
  "Mise en place d'un sprint": 'icon-cube',
  "Conception et prototypage d'une application web et mobile": '1-conception',
  "Préparation du projet de développement d’une application web & mobile": '2-preparation-du-projet',
  "Pilotage du développement d’une application web & mobile": "3-pilotage-application",
  "Mise en production d’une application Web et Mobile": '4-mise-en-production',


  // ANCIEN ICONSRC
  'Javascript': 'icon-javascript',
  'DOM & évènement': 'icon-dom',
  // 'Jquery': 'icon-jquery',
  'Framework Express': 'icon-express',
  'Architecture': 'icon-cubes',
  'Express avancée': 'icon-express',
  'Base de données': 'icon-database',
  'Déploiement': 'icon-deploiement',
  'Web Service': 'icon-webservices',
  'Librairie': 'icon-wrench',
  'App web avec React JS': 'icon-react',
  'React JS avancé': 'icon-react',
  'App mobile avec React Native': 'icon-mobile',
  'Composants React Native': 'icon-puzzle',
  'Echange de données avec Redux': 'icon-redux',
  'Hackathon': 'icon-cube',
  'Review': 'icon-wrench',
  // "Info": 'icon-wrench',
  'QCM frontend': 'icon-html5',
  'QCM backend': 'icon-javascript',
  'Sprint produit': 'icon-cube',
  'Projet': 'icon-cube',
  // 'Briefing': 'icon-plug',
  'Backend avec node': 'icon-express',
  'Environnement': 'icon-deploiement',
  'Frontend : HTML, CSS et JS': 'icon-html5',
  'Tools & environment': 'icon-wrench',
  'tools & environment': 'icon-wrench',
  'Frontend avec React': 'icon-react',

  // NOUVEAU PROGRAMME FS

  // FRONTEND
  'DOM': 'icon-dom',
  'HTML': 'icon-html5',
  'CSS': 'icon-css3',
  "Requêtes HTTP": "icon-arrows-cw-outline",
  "Évènements JS": "icon-dom",

  //BONNES PRATIQUES
  "Optimisation": "icon-params",
  "Debug": "icon-bug",
  'Résolution de problèmes': 'icon-wrench',
  "SEO": "icon-link",
  'Sécurité': 'icon-lock',
  'TDD': 'icon-deploiement',

  //ENVIRONNEMENT & OUTILS
  "NodeJS": "icon-nodejs",
  "TypeScript": "icon-typescript",
  "Environnement de developpement": "icon-wrench",
  "Webservices": "icon-webservices",
  "Librairies & API": "icon-webservices",
  "Le terminal": "icon-terminal",

  // BACKEND
  "Upload de fichiers": "icon-upload-cloud",
  "Express": "icon-express",
  'MVC': 'icon-express',
  "Authentification": "icon-lock",

  // BDD
  'MongoDB': 'icon-mongodb_original_logo_icon_146424',
  'Modélisation BDD': 'icon-database',

  //DÉPLOIEMENT
  "Déploiement Expo": "icon-expo",
  'Hébergement': 'icon-deploiement',
  'Git': 'icon-git-squared',

  //ALGORITHME
  'Structures & types': 'icon-javascript',
  'Les dates': 'icon-calendar',
  'Les fonctions': 'icon-javascript',
  "Expressions rationnelles": "icon-regex",
  "Méthodes JS": "icon-javascript",

  // SETUP
  "Setup": "icon-wrench",

  // INFO
  "Info": "icon-info-outline",
  "Briefing": "icon-info-outline",

  // SPRINT PRODUIT
  'Tests utilisateurs': 'icon-group',
  'UX/UI': 'icon-brush',
  'User stories': 'icon-trello',
  'Storyboard': 'icon-th-list',
  "Le pitch": 'icon-lightbulb',

  // REACT NATIVE
  "Expo": "icon-expo",
  "React Navigation": 'icon-coverflow-empty',
  "Inputs React Native": "icon-expo",
  "Expo Camera": "icon-camera-alt",
  "Map & géolocalisation": "icon-map-pin",

  // REACT
  "Inverse data flow": "icon-react",
  "États & évènements": "icon-react",
  "Inputs React": "icon-react",
  "Cycle de vie": "icon-react",
  "Les composants": "icon-react",
  "Redux": "icon-redux",
  "Les propriétés": "icon-react",
  "Next.js Navigation": "icon-nextjs",

  //QCM
  "QCM de la semaine": "icon-eval-check",
  'positionnement': 'icon-eval-check',

  //DEVOPS
  "Terminal & scripting": "icon-terminal",
  "Python": "icon-python",
  "PostgreSQL": "icon-postgre",
  "Réseaux": "icon-connection",
  "Systèmes": "icon-params",
  "Versionning": "icon-git_original_logo_icon_146509",
  "Intégration continue (CI)": "icon-continuous-integration",
  "Déploiement continu (CD)": "icon-deploiement",
  "Conteneurisation": "icon-docker",
  "Orchestration": "icon-orchestration",
  "Cloud publique": "icon-upload-cloud",
  "Monitoring": "icon-heart-monitor",
  "Automatisation (IaC)": "icon-industrial-robot",

  //RECAT
  "Les bases de programmation": "icon-params",
  "Algorithme": "icon-javascript",
  "HTML & CSS": "icon-html5",
  "JavaScript Frontend": "icon-dom",
  "La base de données": "icon-database",
  "Intégration continue": "icon-git-squared",
  "Prototypage": "icon-brush",
  "Qualité & optimisation du code": "icon-params",
  "Design Sprint": "icon-trello",
  "Gestion de projet": "icon-trello",
  "App web React": "icon-react",
  "Fonctionnalités avancées": 'icon-redux',
  "App mobile React Native": "icon-expo",
  "Backend & Architecture logiciel": "icon-express"
};

export { iconSrc };
export default iconSrc;