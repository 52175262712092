import React, { Component } from 'react';
import { Row, Col, Steps, Layout, Icon } from 'antd';
import { Player, BigPlayButton, LoadingSpinner} from 'video-react';
import "video-react/dist/video-react.css"
const Step = Steps.Step;

export default class DisplayVideo extends Component {
  constructor(){
    super();
    this.state = {
      // videoSrc: '/videos/git&github.mp4',
      // videoPoster: "/videos/git&github.jpg",
      chapterProgress: 0,
      videoLock:false
      // chapters :[
      //               { name: 'HTML', time: 0 },
      //               { name: 'CSS', time: 288 },
      //               { name: 'JavaScript', time: 576  },
      //               { name: 'JQuery', time: 864  },
      //               { name: 'DOM', time: 1152  },
      //             ],

    };

  }

  componentDidMount() {
    this.refs.player.subscribeToStateChange(this.handleStateChange.bind(this));
    this.refs.player.video.video.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }

  handleStateChange = (state, prevState) => {
      
    
    var chapterProgress = 0;
    for (let i in this.props.chapters) {
      if(this.props.chapters[i].time > state.currentTime){
        break;
      }
      chapterProgress = parseInt(i);
    }
    // if(this.props.sequenceChapters == false && this.state.chapterProgress < chapterProgress) {
    //   this.refs.player.pause();
    // }
    //console.log(this.props.enableLiveCode);
    if(this.props.enableLiveCode.livecodePart1 == false) {
      if(this.state.videoLock == false) {
        this.refs.player.seek(0);
        this.refs.player.pause();
      }
      this.setState({videoLock: true});
    } else if(this.props.enableLiveCode.livecodePart2 == false && this.props.chapters[1] && this.props.chapters[1].time <= state.currentTime) {
      if(this.state.videoLock == false) {
        this.refs.player.pause();
        this.refs.player.seek(this.props.chapters[1].time);
      }
      this.setState({videoLock: true});
    } else {
      this.setState({videoLock: false})
    }

    this.setState({chapterProgress});
  }

  render() {
    //var chapters = this.state.chapters.map((data, i)=><Step onClick={()=>{this.refs.player.play();this.refs.player.seek(data.time); this.setState({chapterProgress: i})}} title={data.name}/>);

    var chapters = this.props.chapters.map((data, i)=> {
      if(this.props.enableLiveCode.livecodePart1 == false || (this.props.enableLiveCode.livecodePart2 == false && i == 1)) {
        return <Step onClick={()=>{this.refs.player.play();this.refs.player.seek(data.time); this.setState({chapterProgress: i})}} size="small" title={data.text} status="wait" icon={<Icon type="lock" />} />
      }

      return <Step onClick={()=>{this.refs.player.play();this.refs.player.seek(data.time); this.setState({chapterProgress: i})}} size="small" title={data.text}/>

    });

    //console.log(this.state.chapterProgress);
    return (
      <Row>
        <Col span={24} style={{height: '61vh'}} id="video" className={this.state.videoLock? "lock": null}>

          <Player ref="player"
            onload={() => console.log('Player onload', this.player.getState())}
            autoPlay = {false}
            poster={global.URI_FRONTEND+this.props.videoPoster}
            src = {this.props.videoSrc}
            fluid={false}
            height='100%'
          >
            <BigPlayButton position="center" />
            <LoadingSpinner />
          </Player>

        </Col>
        {
        (this.props.displayChapters == true)?
          <Col span={24} style={{marginTop: '4vh'}}>
            <Steps size="small" style={{cursor: "pointer"}} current={this.state.chapterProgress}>
              {chapters}
            </Steps>
          </Col>
          :
          null
       }
      </Row>
    );
  }
}
