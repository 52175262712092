import React, { Component } from "react";
import { Layout, Row, Col, List } from "antd";
import { connect } from "react-redux";
import RequestCard from "./RequestCard";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

const { Content } = Layout;

class HelpRequestDashboard extends Component {
  state = {
    request: [],
    availableHelpers: [],
    menuOpen: false,
  };

  componentWillMount = () => {
    const { batchid } = this.props;

    if (global.SOCKET) {
      global.SOCKET.emit("requestMonitoring", { batchId: `${batchid}` });
      global.SOCKET.on("requestMonitoring", this.requestMonitoring);
    }
  };

  componentWillUnmount = () => {
    if (global.SOCKET)
      global.SOCKET.off("requestMonitoring", this.requestMonitoring);
  };

  requestMonitoring = data => {
    const { batchid, studentFeeling, batch, updateRequestCount } = this.props;

    let tempRequest;
    let tempRequestFiltered = [];

    tempRequest = data.requests?.filter(e => e.batchId === batchid);

    if (tempRequest.length !== 0) {
      for (const e of tempRequest) {
        const filteredFeeling = studentFeeling?.filter(
          f => f.user === e.userId
        );

        let buddy = batch?.group_history?.groupList
          .find(f => f.find(g => g._id === e.userId))
          ?.filter(f => f._id !== e.userId);
        tempRequestFiltered.push({
          ...e,
          notionUnderstood:
            filteredFeeling && filteredFeeling[0]?.notionUnderstood
              ? filteredFeeling[0]?.notionUnderstood
              : undefined,
          buddy: buddy || [],
        });
      }

      this.setState({
        request: tempRequestFiltered ? tempRequestFiltered : [],
      });
      updateRequestCount(tempRequestFiltered.length);
    } else {
      this.setState({ request: [] });
      updateRequestCount(0);
    }
  };

  loadHelpers = async () => {
    const { batchid } = this.props;

    const data = await fetch(
      `${global.URI_BACKEND}/request/helpers/${batchid}`
    );
    const { result, helpers } = await data.json();

    if (result) {
      this.setState({ availableHelpers: helpers });
    }
  };

  render() {
    const { request, availableHelpers } = this.state;
    const { user, t } = this.props;

    return request.length ? (
      <List
        rowKey={(e) => e._id}
        itemLayout="horizontal"
        dataSource={request}
        renderItem={(requestItem) => (
          <RequestCard
            requestItem={requestItem}
            availableHelpers={availableHelpers}
            user={user}
            loadHelpers={this.loadHelpers}
          />
        )}
      />
    ) : (
      <p style={{ textAlign: "center", marginTop: 20 }}>{t("Aucune demande d'aide en cours.")}</p>
    );
  }
}

function mapStateToProps({ user, batchid, buddylist, batch }) {
  return { user, batchid, buddylist, batch };
}

export default connect(
  mapStateToProps,
  null
)(withTranslation()(HelpRequestDashboard));

const styles = {
  ListContainer: {
    marginTop: 34,
    minHeight: "80vh",
    paddingTop: 15,
    paddingBottom: 15,
    display: "flex",
    justifyContent: "center",
  },
};
