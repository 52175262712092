import React, { Component } from 'react';
import { Row, Anchor } from 'antd';

import './Menu.css';

const { Link } = Anchor;

export default class Menu extends Component {

  

  handleClickAnchor = (e,link) => {
    e.preventDefault();
    this.props.onCollapseChange(link.href)
  };

  

  render() {

    var listTitle = []

    if(this.props.titles !== undefined){
      listTitle = this.props.titles
    }


    const titles = listTitle.map((e, i) => {
          let target = `panel-${i}`
          let href = `#panel-${i}`
          if(this.props.current === 'overview'){
              target = e.id
              href = '#'+e.id
            }
        return <Link key={i} href={href} title={e.title} />
      },this)

    return (
  
      <Anchor affix={false} offsetTop={70} style={{background: 'linear-gradient(to right, transparent 4px, white 0, white 100%)' }} onClick={this.handleClickAnchor} bounds={5}>
      {titles}
      </Anchor>

    
   );
  }
}

