import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Card, Row, Col, Layout, Select, Input, Icon, Button, Form, Drawer, Modal, Dropdown, Menu, message } from 'antd';
import { connect } from 'react-redux';

import Userpanel from './userpanel';
import Header from '../header';
import HeaderModal from '../headermodal';

import checkRank from '../utils/checkRank';

const { Content } = Layout;
const { Meta } = Card;
const Search = Input.Search;
const Option = Select.Option;
const FormItem = Form.Item;
const confirm = Modal.confirm;

let uuid = 0;

const UpdateAlumnis = Form.create()(class extends React.Component {
  type = null;
  batchId = null;

  componentDidUpdate = (prevProps) => {
    if (this.props.displayDrawer !== prevProps.displayDrawer && this.props.displayDrawer) {
      this.uuid = 0;
      if (this.props.batchList) {
        this.setFormData()
      }
    }
  }

  setFormData = () => {
    for (var i = 0; i < this.props.batchList.length; i++) {
      this.addProposition();
    }
  }

  addProposition = () => {
    const { form } = this.props;

    var keys = form.getFieldValue('keys');
    var k = keys ? keys : [];

    const nextKeys = k.concat(this.uuid);

    this.uuid++;

    form.setFieldsValue({ keys: nextKeys });
  }

  removeProposition = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');

    if (keys.length === 0) return;

    form.setFieldsValue({ keys: keys.filter(key => key !== k) });
  }

  changeType = (value) => {
    this.type = value;
  }

  changeBatch = (value) => {
    this.batchId = value;
  }

  closeDrawer = () => {
    this.props.displayDrawer();
  }

  createAlumnis = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        fetch(`${global.URI_BACKEND}/user/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: values.email, firstname: values.firstname, lastname: values.lastname, type: this.type, batchList: [{ batchId: this.batchId }] })
        }).then(res => res.json()).then(data => {
          if (data.success) {
            this.props.setValueUserCreated(data.user.firstname, data.user.password);
            this.props.displayModal();
            this.closeDrawer(data.success);
          }
        })
      }
    });
  }

  render() {
    const { visibleDrawer, dataUser, form } = this.props
    const { getFieldDecorator } = this.props.form;
    const keys = form.getFieldValue('keys');

    var defaultFirstName = dataUser && dataUser.firstname
      ? dataUser.firstname
      : null;

    var defaultLastName = dataUser && dataUser.lastname
      ? dataUser.lastname
      : null;

    var defaultEmail = dataUser && dataUser.email
      ? dataUser.email
      : null;

    var defaultType = dataUser && dataUser.type
      ? dataUser.type
      : null;

    if (dataUser && dataUser.batchList) {
      var BatchItems;
      if (keys) {
        BatchItems = keys.map((keys, i) => {

          //student
          if (dataUser.batchList.length === 1 && dataUser.batchList[0].batchId && dataUser.batchList[0].userType === 'student') {
            var defaultBatch = dataUser.batchList[0].name
          }
          //Teacher or TA
          else {
            if (dataUser.batchList.length > 1 && dataUser.batchList[keys].batchId && dataUser.batchList[keys].userType !== 'student') {
              var defaultBatch = dataUser.batchList[keys]
            }
          }

          i = 1
            ? i + 1
            : null

          return (<FormItem label={"Batch " + i} key={keys}>
            {getFieldDecorator(`batch[${keys}]`, {
              rules: [
                { required: true, message: 'Ajouter un batch' },
              ],
              initialValue: defaultBatch
            })
              (<Select placeholder="Choose batch" onChange={this.changeBatch}>
                {
                  this.batchList
                    ? this.batchList.map((batch, i) => {
                      return (
                        <Option key={i} value={batch._id}>{batch.name}</Option>
                      )
                    })
                    : null
                }
              </Select>
              )}
            <Icon className="dynamic-delete-button" style={styles.supprButtonForm} type="minus-circle-o" onClick={() => this.removeProposition(keys)} />
          </FormItem>)
        })
      }
    }

    return (<Drawer
      title={dataUser ? "Update Alumnis" : "Create Alumnis"}
      width={500}
      placement="right"
      onClose={this.closeDrawer}
      maskClosable={false}
      destroyOnClose={true}
      visible={visibleDrawer}
    >
      <Form>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} style={{ marginTop: 20 }}>
            <FormItem>
              {getFieldDecorator(`firstname`, {
                rules: [
                  { required: true, message: 'Ajouter un Prénom' },
                ],
                initialValue: defaultFirstName
              })
                (<Input initialvalue={defaultFirstName} placeholder="Prénom" />)}
            </FormItem>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} style={{ marginTop: 20 }}>
            <FormItem>
              {getFieldDecorator(`lastname`, {
                rules: [
                  { required: true, message: 'Ajouter un Nom' },
                ],
                initialValue: defaultLastName
              })
                (<Input initialvalue={defaultLastName} placeholder="Nom" />)}
            </FormItem>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} style={{ marginTop: 20 }}>
            <FormItem>
              {getFieldDecorator(`email`, {
                rules: [
                  { required: true, message: 'Ajouter un Email' },
                ],
                initialValue: defaultEmail
              })
                (<Input initialvalue={defaultEmail} placeholder="Email" />)}
            </FormItem>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} style={{ marginTop: 20 }}>
            <FormItem>
              {getFieldDecorator('type', {
                rules: [
                  { required: true, message: 'Ajouter un type' },
                ],
                initialValue: defaultType
              })
                (<Select placeholder="Choose type" onChange={this.changeType}>
                  <Option value="student">Student</Option>
                  <Option value="assistant">Assistant</Option>
                  <Option value="teacher">Teacher</Option>
                </Select>
                )}
            </FormItem>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} style={{ marginTop: 20 }}>
            {BatchItems}
          </Col>

          <Col xs={{ span: 4, offset: 18 }} style={{ marginTop: 50 }}>
            {
              dataUser
                ? <Button onClick={this.editSlide} type="primary"><Icon type="edit" />Éditer</Button>
                : <Button type="primary" onClick={this.createAlumnis} ><Icon type="check" />Create</Button>
            }

          </Col>

        </Row>
      </Form>

    </Drawer>);
  }
});

class Alumnis extends Component {
  state = {
    alumnis: null,
    valueOne: 'all',
    results: [],
    searchInput: null,
    searchFieldDisabled: true,
    batch: null,
    loading: true,
    visibleDrawer: false,
    showModal: false,
    batchList: null
  };

  firstName = null;
  password = null;
  dataUser = null;

  // Get all users datas
  componentWillMount = () => {
    fetch(global.URI_BACKEND + '/alumnis').then(res => res.json()).then(data => {
      if (data.success) this.setState({ alumnis: data.users, loading: false });
    });

    fetch(`${global.URI_BACKEND}/batch/`).then(res => res.json()).then(data => {
      if (data.success) this.setState({ batchList: data.batches });
    });
  }

  // Display all users when the users data are available
  componentDidUpdate = (prevProps, prevState) => {
    const { alumnis, valueOne } = this.state;
    if (prevState.alumnis !== alumnis && valueOne === 'all') {
      const allAlumnis = [...alumnis];

      this.setState({ results: allAlumnis, batch: true });
    }
  }

  // Prevent accents based typos in the search engine
  RemoveAccents = (str) => {
    const accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";

    str = str.split('');

    let x;

    for (let i in str) {
      if ((x = accents.indexOf(str[i])) !== -1) str[i] = accentsOut[x];
    }

    return str.join('');
  };

  // Handle search Inputs depending on the choosen filter
  handleSearch = (value) => {
    const { alumnis, valueOne } = this.state;

    if (value === '') {
      const allAlumnis = [...alumnis];

      this.setState({ results: allAlumnis });
    }
    if (alumnis) {
      if (valueOne === 'all') {
        const results = [...alumnis];

        this.setState({ results: results });
      } else if (valueOne === "firstName") {
        const results = [];

        for (let e of alumnis) {
          if (this.RemoveAccents(e.firstname.toLowerCase()) === this.RemoveAccents(value.toLowerCase())) {
            results.push(e);

            this.setState({ results: results });
          }
        }
      } else if (valueOne === "lastName") {
        const results = [];

        for (let e of alumnis) {
          if (this.RemoveAccents(e.lastname.toLowerCase()) === this.RemoveAccents(value.toLowerCase())) {
            results.push(e);

            this.setState({ results: results });
          }
        }
      } else if (valueOne === "email") {
        const results = [];

        for (let e of alumnis) {
          if (this.RemoveAccents(e.email.toLowerCase()) === this.RemoveAccents(value.toLowerCase())) {
            results.push(e);

            this.setState({ results: results });
          }
        }
      }
    }
  }

  // Selector based filter mechanism
  handleChangeOne = (valueOne) => {
    const { alumnis } = this.state;

    const allAlumnis = [...alumnis];

    this.setState({ searchFieldDisabled: false, results: allAlumnis, valueOne: valueOne });
  }

  displayDrawer = () => {
    this.setState({ visibleDrawer: !this.state.visibleDrawer });
  }

  displayModal = () => {
    this.setState({ showModal: !this.state.showModal });
  }

  setValueUserCreated = (firstName, password) => {
    this.firstName = firstName
    this.password = password
  }

  editAlumnis = (user) => {
    console.log("EDIT USERRRR -->", user);
    this.dataUser = user
    this.displayDrawer()
  }

  deleteAlumnis = (user) => {
    confirm({
      title: 'Voulez-vous vraiment supprimer "' + user.firstname + ' ' + user.lastname + '" ?',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk: () => {
        fetch(`${global.URI_BACKEND}/user/${user._id}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(response => response.json()).then(data => {
          console.log("RETOUR DELETE ==", data);
          if (data.success) {
            message.success('"' + user.firstname + '" a bien été supprimé. !');
          }
        });
      }
    });
  }

  render() {

    const { results, searchFieldDisabled, loading, visibleDrawer, showModal } = this.state;
    const { tracks, user } = this.props;

    const Results = results;
    let batchName;

    const card = Results.map((User, i) => {
      const menu = (<Menu>
        <Menu.Item key="0">
          <div onClick={() => this.editAlumnis(User)}><Icon type="edit" /> éditer</div>
        </Menu.Item>
        <Menu.Item key="1">
          <div style={{ color: "red" }} onClick={() => this.deleteAlumnis(User)}><Icon type="close-circle-o" /> supprimer</div>
        </Menu.Item>
      </Menu>);

      if (User.batchList && User.batchList[0]) {
        if (User.batchList[0].name) {
          batchName = <Meta title={User.batchList[0].name} />
        } else if (!User.batchList[0].name && User.batchList[0].batchNumber) {
          batchName = <Meta title={"Batch #" + User.batchList[0].batchNumber} />
        } else {
          batchName = <Meta title="Batch unknown" />
        }
      } else {
        batchName = <Meta title="Batch unknown" />
      }
      if (User.firstname.match('student') === null && User.firstname.match('teacher') === null) {
        return (
          <Col key={i} xs={{ span: 10 }} md={{ span: 9 }} lg={{ span: 7 }} xl={{ span: 5 }} style={{ marginTop: 20 }}>
            <Card
              cover={
                <img alt="alumnis" style={{ width: "100%" }} src={
                  User.avatar
                    ? `./images/avatar/${User._id}.jpg`
                    : `./images/avatar/_male-default.jpg`
                } />
              }
              bodyStyle={{ padding: "1vh", cursor: "auto" }}>
              <p style={{ position: "absolute", bottom: (checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) ? "7vh" : "5vh", color: "#FFFFFF", textShadow: "1px 1px 3px black" }}>{User.firstname}</p>
              <p style={{ position: "absolute", bottom: (checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) ? "5vh" : "3vh", color: "#FFFFFF", fontWeight: "bold", textShadow: "1px 1px 3px black" }}>{User.lastname}</p>
              {
                (checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }))
                  ? <div style={styles.batchContainer}>
                    {batchName}
                    <div style={styles.Dropdown}>
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a href="#" className="ant-dropdown-link">
                          <Icon type="ellipsis" theme="outlined" />
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                  : batchName
              }
            </Card>
          </Col>
        )
      }
    })

    return (<div>
      <Header />
      <Layout style={{ minHeight: '100vh' }}>
        {
          tracks
            ? <Layout style={{ backgroundColor: 'white' }}>
              <Content style={{ minHeight: '79vh', maxHeight: '79vh' }}>
                <div style={{ backgroundColor: '#F6F6FA', minHeight: '100vh' }}>
                  <Row>

                    <Col style={styles.userPanel} xs={{ span: 24 }} sm={{ offset: 1, span: 22 }} md={{ offset: 1, span: 4 }}>
                      <Userpanel name={user.firstname} />
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ offset: 1, span: 22 }} md={{ offset: 1, span: 17 }} style={styles.alumnis_container}>

                      <div style={styles.projectTitleContainer}>
                        <Row type="flex" justify="space-around" align="middle">
                          <p className="lc-pink" style={styles.projectTitle}>Alumnis</p>
                        </Row>
                        <div style={styles.flexCenter}>
                          <div style={styles.subLine}></div>
                        </div>
                      </div>

                      <Row type="flex" justify="center" align="middle" style={styles.searchBar}>
                        <Col xs={{ span: 24 }} sm={{ span: 20 }} md={{ span: 18 }} lg={{ span: 16 }} xl={{ span: 14 }}>
                          <Search placeholder="Select a filter first" onSearch={(value) => this.handleSearch(value)}
                            enterButton="Search" disabled={searchFieldDisabled} />
                        </Col>
                      </Row>

                      <Row type="flex" justify={(checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) ? "space-around" : "center"} align="middle" style={styles.filter}>
                        <Col>
                          <Select defaultValue="All alumnis" onChange={this.handleChangeOne}>
                            <Option value="firstName">First name</Option>
                            <Option value="lastName">Last name</Option>
                            <Option value="email">Email</Option>
                          </Select>
                        </Col>
                        {
                          (checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }))
                            ? <Col>
                              <Button onClick={this.displayDrawer} >Create New</Button>
                            </Col>
                            : null
                        }
                      </Row>

                      {
                        loading
                          ? <Icon type="loading" style={styles.spinLoad} spin />
                          : <Row type="flex" justify="space-around" align="middle" style={styles.card}>
                            {card}
                          </Row>
                      }

                    </Col>

                    <UpdateAlumnis
                      displayDrawer={this.displayDrawer}
                      visibleDrawer={visibleDrawer}
                      showModal={this.displayModal}
                      setValueUserCreated={this.setValueUserCreated}
                      dataUser={this.dataUser}
                    />

                    <Modal
                      title={<HeaderModal />}
                      visible={showModal}
                      onOk={this.displayModal}
                      onCancel={this.displayModal}
                    >
                      <p style={{ fontSize: 15 }}>Création de <span style={{ fontSize: 20, fontWeight: "bold" }}>{this.firstName}</span> Reussi</p>
                      <p style={{ fontSize: 15 }}>Le mot de passe est : <span style={{ fontSize: 20, fontWeight: "bold" }}>{this.password}</span></p>
                    </Modal>

                  </Row>
                </div>
              </Content>
            </Layout>
            : <Redirect to='/' />
        }
      </Layout>

    </div>);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onCardClick: function (selectedUserId) {
      dispatch({ type: 'displayProfile', id: selectedUserId });
    }
  }
}

function mapStateToProps({ batchuserlist: userList, user, batch, tracks }) {
  return { userList, user, batch, tracks };
}

export default connect(mapStateToProps, mapDispatchToProps)(Alumnis)

const styles = {
  userPanel: { marginTop: 154 },
  spinLoad: { fontSize: 50, marginTop: 20, display: "flex", justifyContent: "center", },
  alumnis_container: { backgroundColor: '#fff', paddingBottom: 20, boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.3)', marginBottom: 40, marginTop: 154 },
  projectTitleContainer: { marginTop: 15 },
  projectTitle: { fontSize: 20 },
  flexCenter: { display: 'flex', justifyContent: "center", alignItems: "center" },
  subLine: { height: 1, width: '80%', backgroundColor: 'lightGrey' },
  searchBar: { marginTop: 15 },
  filter: { marginTop: 15 },
  card: { flexWrap: "wrap" },
  Dropdown: { display: "flex", fontSize: 20, justifyContent: "flex-end", transform: "rotate(90deg)", marginRight: 10 },
  batchContainer: { display: "flex", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }
}
