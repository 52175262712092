import typeChecker from '../../typeChecker';

function property(tc_prop = {}, pb_prop = {}) {
  const found = {};

  if (tc_prop.key) found.key = false;
  if (tc_prop.value) found.value = false;

  if (pb_prop.key?.type && tc_prop.key?.type === pb_prop.key?.type) {
    found.key = typeChecker(tc_prop.key, pb_prop.key);
  }

  if (pb_prop.value?.type && tc_prop.value?.type === pb_prop.value?.type) {
    found.value = typeChecker(tc_prop.value, pb_prop.value);
  }

  return Object.values(found).every(e => e === true);
}

export default property;