import React, { Component } from 'react';
import { Icon } from 'antd';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';
class UserMenu extends Component {
  logout = () => {
    global.SOCKET.emit('logout');
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('batchIdSelected');
    this.props.offline(this.props.user.userId);
    this.props.clearDatas();
  }

  render() {
    const { batch, user, t } = this.props;
    const { color, logoutIcon } = styles;

    return (<>
      <div className="popover-user-menu">
        {batch.stackType !== "workshop" && <div><Link to={`/profile/${user._id}`} style={color}>{t("Mon profil")}</Link></div>}
        <div style={{ minWidth: "125px" }} onClick={this.logout}><Icon type="logout" style={logoutIcon} />{t("Logout")}</div>
      </div>
    </>);
  }
}

function mapStateToProps({ user }) {
  return { user };
}

function mapDispatchToProps(dispatch) {
  return {
    offline: function (userId) {
      dispatch({ type: 'online', status: false, userId });
    },
    clearDatas: function () {
      dispatch({ type: 'tracks', tracks: null });
      dispatch({ type: 'batch', batch: null });
      dispatch({ type: 'stackid', id: null });
      dispatch({ type: 'batchid', id: null });
      dispatch({ type: 'batchuserlist', batchuserlist: [] });
      dispatch({ type: 'buddies', buddies: null });
      dispatch({ type: 'progression', progression: null });
      dispatch({ type: 'stackType', stackType: {} })
      // Warning: the user dispatch must be the last dispatch
      dispatch({ type: 'user', user: null });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserMenu));

const styles = {
  emargement: { cursor: 'pointer' },
  color: { color: '#272727' },
  logoutIcon: { marginRight: 5, color: 'red' },
  bodyModal: { backgroundColor: '#F6F6FA', borderRadius: 10 }
};