import React, { Component } from 'react';
import { Icon, Tooltip, Modal, Input, Button } from 'antd';
import { connect } from 'react-redux';

import { LinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';



// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';
import LinkedInPage from './LinkedInPage';

const regex = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;

class ImportFromLinkedIn extends Component {
  state = {
    linkedin: '',
    visible: false,
    noURL: false,
    disableOkButton: true,
    confirmLoading: false,
    lastClick: 0,
    isSent: false,
  }

  timeout = 5000;

  componentDidMount() {
    const { linkedin } = this.props;

    this.setState({ linkedin });
  }

  registerLinkedInUrl = () => {
    this.setState({ visible: true });
  }

  updateInParent = async () => {
    const { linkedin } = this.state;
    const { setStateInParent, triggerSocialsUpdate } = this.props;
   
     setStateInParent({linkedin});
   
     triggerSocialsUpdate();
  }

  onOk = async () => {


    // const { linkedin } = this.state;
    const { linkedinFromParent, setStateInParent, user } = this.props;

    this.setState({ linkedin: user.socials.linkedin })
  
      if (!linkedinFromParent) this.updateInParent();

      this.setState({ confirmLoading: true, visible: false });

      
      const data = await this.sendData();
      this.setState({ confirmLoading: false, visible: false, noURL: false }, () => {
        let experiences = (data && data.scrap && data.scrap.experiences)? data.scrap.experiences : [];
        setStateInParent({ experiences, liScrapLoading: false });
      });
    
  }



  onOk2 = async () => {

    const { linkedin, isSent } = this.state;
    const { linkedinFromParent, setStateInParent, user } = this.props;

    this.setState({ linkedin: linkedin })

    const match = linkedin.match(regex);

    if (!match) {
      this.setState({ confirmLoading: false });
    } else {
      if (!linkedinFromParent) this.updateInParent();

      this.setState({ confirmLoading: true, visible: false });

      fetch(`${global.URI_BACKEND}/user/profile/${user._id}/socials`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(linkedin)
      }).then(res => res.json()).then(data => {
        const { socials } = data;
        
        this.setState({ editSocialNetworks: false, ...socials });
      });

      const data = await this.sendData();
      this.setState({ confirmLoading: false, visible: false, noURL: false }, () => {
        let experiences = (data && data.scrap && data.scrap.experiences)? data.scrap.experiences : [];
        setStateInParent({ experiences, liScrapLoading: false });
      });
    }
  }

  onCancel = () => {
    this.setState({ visible: false });
    this.setState({ noURL: false });

  }
  onCancel2 = () => {
    this.setState({ noURL: true });
  }

  handleIconClick = async () => {

    this.setState({ lastClick: new Date(), visible: true });

  }

  sendData = async () => {
    const { linkedin } = this.state;
    const { _id, setStateInParent } = this.props;

    setStateInParent({ liScrapLoading: true });

    return (await fetch(`${global.URI_BACKEND}/user/profile/${_id}/experiences/import`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ linkedin })
    }).then(res => res.json()).then(data => {
      return data;
    }).catch(console.error));
  }

  onInputChange = ({ target }) => {
    const { linkedin } = this.state;

    const match = linkedin.match(regex);

    this.setState({ linkedin: target.value, disableOkButton: match });
  }

  clickable = Date.now() > this.state.lastClick + 5000

  render() {
    const { visible, noURL, confirmLoading, disableOkButton, linkedin, experiences } = this.state;
    const { user, t } = this.props

    const linkedinURL = user.socials?.linkedin;
    // console.log(user.socials.linkedin)

    // console.log("experiences", user.experiences)



    return <div>
      <Tooltip title={t("Importer depuis LinkedIn")} placement="left">
        {
          // CHANGER LE SENS DE LA CONDITION
          this.clickable && user.experiences.length > 0
            ? <Icon type="reload" className="clickable hover" onClick={this.handleIconClick} />
            : <Button type="primary" onClick={this.handleIconClick}>{t("Importer mes expériences depuis Linkedin")}</Button>
        }
        
      </Tooltip>
      {/* <LinkedInPage /> */}
      {
      linkedinURL && visible ? 
        <Modal visible={visible} destroyOnClose={true} closable={false} confirmLoading={confirmLoading} onOk={this.onOk}  onCancel={this.onCancel}>
          <div>
            <label>{t("Je souhaite récupérer les informations du compte suivant")}: <strong>{linkedinURL}</strong></label><br></br><br></br>
            <p>{t("Si ce compte n'est pas le votre, veuillez modifier votre URL Linkedin dans l'encart \"Réseaux Sociaux\"")}</p>
          </div>
        </Modal> :
        <Modal visible={visible} destroyOnClose={true} closable={false} confirmLoading={confirmLoading} okButtonProps={{ disabled: disableOkButton }} onOk={this.onOk2} onCancel={this.onCancel}>
          <div>
            <label>{t("Veuillez saisir l'url de votre profil LinkedIn")}</label>
            <Input placeholder="https://www.linkedin.com/in/..." onPressEnter={this.onOk2} autoFocus={true} onChange={this.onInputChange} />
          </div>
        </Modal>
      }
      {/* {noURL ? <Modal visible={visible} destroyOnClose={true} closable={false} confirmLoading={confirmLoading} okButtonProps={{ disabled: disableOkButton }} onOk={this.onOk} onCancel={this.onCancel}>
        <div>
          <label>Veuillez saisir l'url de votre profil LinkedIn</label>
          <Input placeholder="https://www.linkedin.com/in/..." onPressEnter={this.onOk} autoFocus={true} onChange={this.onInputChange} />
        </div>
      </Modal> : null} */}
    </div>;
  }
}

function mapStateToProps({ user }) {
  return { user };
}
export default connect(mapStateToProps)(withTranslation()(ImportFromLinkedIn));