import typeChecker from '../../typeChecker';
import variableDeclarator from '../../variableDeclarator';

function variableDeclaration(tc_dec = [], pb_dec = []) {
  if (!(Array.isArray(tc_dec) && Array.isArray(pb_dec))) return false;

  const declarations = [];
  let found_instructions = 0;

  if (tc_dec && pb_dec) {
    tc_dec_loop: for (let i = 0; i < tc_dec.length; i++) {
      const curTC = tc_dec[i];

      for (let j = found_instructions; j < pb_dec.length; j++) {
        const curPB = pb_dec[j];

        if (curTC?.type === curPB?.type && curPB?.type === 'VariableDeclarator') {
          const check = {};

          if (curTC.id) check.id = false;
          if (curTC.init) check.init = false;

          if (curPB.id?.type && curTC.id?.type === curPB.id?.type) {
            check.id = variableDeclarator(curTC.id, curPB.id);
          }

          if (curPB.init?.type && curTC.init?.type === curPB.init?.type) {
            check.init = typeChecker(curTC.init, curPB.init);
          }

          const checkResult = Object.values(check).every(e => e === true);

          declarations.push(checkResult);

          if (checkResult) {
            found_instructions++;
            continue tc_dec_loop;
          }
        }
      }
    }
  }

  return (declarations.length === pb_dec.length && declarations.every(e => e === true));
}

export default variableDeclaration;