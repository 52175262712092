import React, { Component } from 'react';
import { Layout, Row, Col, Avatar, List, Icon } from 'antd';
import { connect } from 'react-redux';

import Header from '../../Header/Header';
import Sider from '../Sider';
import Timer from './HelpRequest/Timer';

import checkRank from '../../../utils/checkRank';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

const { Content } = Layout;

class HelpRequest extends Component {
  state = {
    request: []
  }

  componentWillMount = () => {
    const { batchid } = this.props;

    if (global.SOCKET) {
      global.SOCKET.emit('requestMonitoring', { batchId: `${batchid}` })
      global.SOCKET.on('requestMonitoring', this.getRequests);
    }
  }

  componentWillUnmount = () => {
    if (global.SOCKET) global.SOCKET.off("requestMonitoring", this.getRequests)
  }
  
  closeRequest = requestItem => {
    const { _id, batchId, userId } = requestItem;

    global.SOCKET.emit('teacherClosesRequest', { requestId: _id, batchId, userId });
  }

  getRequests = data => {
    const { batchid } = this.props;

    this.setState({ request: data.requests?.filter(e => e.batchId === batchid) ?? [] });
  }

  render() {
    const { request } = this.state;
    const { user, t } = this.props;

    let listRequest = <List itemLayout="horizontal" dataSource={request} renderItem={((requestItem, i) => {

      const actualTime = new Date(requestItem.date.start).getTime();

      if (requestItem.status === "assigned" && requestItem.teachers[0]) {
        return (<List.Item className="request-row" style={{ minWidth: "40vw" }}>

          <Col span={5} style={styles.timerlist}>
            <Icon type="clock-circle" style={{ marginRight: 10 }} />

            <Timer startDate={actualTime} type="badge" />

          </Col>

          <Col span={18} style={{ display: "flex", alignItems: "center" }}>

            {/* Left */}
            <Col span={10} style={styles.userRequest}>
              <Col span={6} offset={1} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p style={styles.name}>{requestItem.firstname ?? 'No_Name'}</p>
                <p style={styles.name}>{requestItem.lastname ?? 'No_Name'}</p>
              </Col>

              <Col offset={3}>
                <Avatar size={60} src={requestItem.userId
                  ? `${global.URI_FRONTEND}/images/avatar/${requestItem.userId}.jpg`
                  : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`} />
              </Col>
            </Col>

            {/* Right */}
            <Col span={10} offset={1} style={styles.userRequest}>
              <Col offset={2}>
                <Avatar size={60} src={requestItem.userId
                  ? `${global.URI_FRONTEND}/images/avatar/${requestItem.teachers[0].teacherId}.jpg`
                  : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`} />
              </Col>

              <Col offset={3} span={10} style={{ display: "flex", flexDirection: "column" }}>
                <p style={styles.name}>{requestItem.teachers[0].firstname ?? 'No_Name'}</p>
                <p style={styles.name}>{requestItem.teachers[0].lastname ?? 'No_Name'}</p>
              </Col>
            </Col>

          </Col>

          <Col span={1}>
            {(requestItem.teachers?.[0]?.teacherId === user._id || checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) && <Icon type="close-circle" onClick={() => this.closeRequest(requestItem)} />}
          </Col>

        </List.Item>);
      } else {
        return (<List.Item className="request-row" style={{ minWidth: "40vw" }}>

          <Col span={5} style={styles.timerlist}>
            <Icon type="clock-circle" style={{ marginRight: 10 }} />
            <Timer startDate={actualTime} type="badge" />
          </Col>

          <Col span={18} style={{ display: "flex", alignItems: "center" }}>
            <Col span={10} style={styles.userRequest}>
              <Col span={6} offset={1} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p style={styles.name}>{requestItem.firstname ?? 'No_Name'}</p>
                <p style={styles.name}>{requestItem.lastname ?? 'No_Name'}</p>
              </Col>

              <Col offset={3}>
                <Avatar size={60} src={requestItem.userId
                  ? `${global.URI_FRONTEND}/images/avatar/${requestItem.userId}.jpg`
                  : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`} />
              </Col>
            </Col>

            <Col span={10} offset={1} style={styles.userRequest}>
              {requestItem.teachers?.length === 0 && <p style={styles.name}>En attente ...</p>}
            </Col>

          </Col>
          {user.type === 'teacher' && <Col span={1}><Icon type="close-circle" onClick={() => this.closeRequest(requestItem)} /></Col>}

        </List.Item>);
      }
    })} />;

    return (<div>

      <Layout>
        <Content style={{ minHeight: '79vh', maxHeight: '79vh' }}>
          {/* <div> */}
          <Row>
            {
              request.length > 0
                ? <Col style={styles.ListContainer} offset={1} span={22}>{listRequest}</Col>
                : <Col style={{ marginTop: 24, fontSize: 30 }} offset={2} span={16}>{t("Pas de demande")}</Col>
            }
          </Row>
          {/* </div> */}
        </Content>
      </Layout>
    </div>);
  }
}

function mapStateToProps({ user, batchid }) {
  return { user, batchid };
}

export default connect(mapStateToProps, null)(withTranslation()(HelpRequest));

const styles = {
  userRequest: { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" },
  name: { fontSize: 13, margin: 0 },
  ListContainer: { marginTop: 34, minHeight: "80vh", paddingTop: 15, paddingBottom: 15, display: "flex", justifyContent: "center" },
  timerlist: { display: "flex", justifyContent: "center", alignItems: "center" }
}
