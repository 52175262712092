export const dashboardstyles = {
    selectContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '24px'
    },
    select: {
        width: '200px'
    },
    contentTitle: { 
        letterSpacing: 0.7, 
        fontSize: 14, 
        fontWeight: 700, 
        textTransform: 'uppercase', 
        color: '#272727',
        textAlign: 'center',
        fontFamily: '"Poppins", sans-serif',
    },
    contentPanel: {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 300,
        height: '100%',
        alignItems: 'flex-start',
        padding: '10px 20px',
    },
    right: {
        padding: '10px 20px 10px 0px'
    },
    center: {
        padding: '10px 0px 0px 0px'
    },
    left: {
        padding: '10px 0px 10px 20px'
    },
    contentBody: {
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        width: '100%',
    },
    snapshotRow: {
        height: '30vh',
        maxHeight: '300px'
    },
    tableText: {
        fontWeight: 300,
        fontSize: '14px',
        color: '#010031',
        margin: 0
    },
    tableIcon: {
        marginLeft: 6,
        color: 'rgba(249, 74, 86, 0.5)'
    },
    tableRow: {
        padding: '10px 24px',
    },
    studentRow: {
        borderBottom: '0.5px solid #E5E5E5'
    },
    emoji: {
        height: 27,
        width: 'auto',
        marginRight: 14
    },
    feeling_wrapper: {
        display: 'flex',
        margin: '5px 0px'
    },
    feeling_count: {
        fontWeight: 600,
        fontSize: '12px',
        color: '#898C8F',
        textDecoration: 'underline',
        position: 'absolute'
    },
    progress_wrapper: {
        position: 'relative',
        width: '80%',
        display: 'flex',
        alignItems: 'center'
    },
    tag: {
        borderRadius: '10px'
    },
    menuRow: {
        marginBottom: '64px'
    }
}