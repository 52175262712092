import React, { useState } from "react";

//STYLES_________________________
import "../teacherdashboards.css";
import "../../Container/Content/Flashcard.css";
import "../dashboardstyles";
import "./DashboardNavMenu";

//UI_____________________________
import { Row, Table, Progress, Checkbox, Col, Modal } from "antd";

//COMPONENTS______________________
import Student from "./Student";
import DataDisplay from "./DataDisplay";

//SCROLLBAR______________________
import SimpleBar from "simplebar-react";
import { dashboardstyles } from "../dashboardstyles";

//REDUX_________________________
import { connect } from "react-redux";

//TRANSLATION
import { useTranslation } from "react-i18next";

const Positionnement = ({ batch, addStudentPane }) => {
  //STATE HOOKS____________________
  const [visible, setVisible] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const { t } = useTranslation();

  //DATA_____________________
  const columns = [
    {
      title: <span style={styles.heading}>{t("Élève")}</span>,
      dataIndex: "student",
      key: "student",
      align: "center",
      width: "21vw",
      className: "no-padding",
      render: student => <Student student={student} onClick={addStudentPane} />,
    },
    {
      title: <span style={styles.heading}>{t("Test de logique")}</span>,
      dataIndex: "test",
      align: "center",
      key: "test",
      width: "127px",
      render: data => (
        <>
          <span style={styles.text}>{data || 0}/24</span>
          <i className="icon-circled-star" style={{ color: "#F94A5680" }} />
        </>
      ),
    },
    {
      title: (
        <span
          style={{
            ...styles.heading,
            ...styles.blueHeading,
            verticalAlign: "middle",
          }}
        >
          Prep Work
        </span>
      ),
      className: "border-left border-right border-bottom",
      children: [
        {
          title: (
            <span style={{ ...styles.heading, ...styles.blueHeading }}>
              HTML
            </span>
          ),
          dataIndex: "prep.html",
          align: "center",
          key: "prep-html",
          className: "border-left padding-left",
          width: "7vw",
          render: data => (
            <Progress
              className={data === 0 && "ant-progress-null"}
              style={{ margin: "auto" }}
              status={
                data <= 50 && data > 0
                  ? "exception"
                  : data >= 100
                  ? "success"
                  : "normal"
              }
              format={data => <span>{data || 0}%</span>}
              type="circle"
              percent={data || 0}
              width={37}
            />
          ),
        },
        {
          title: (
            <span style={{ ...styles.heading, ...styles.blueHeading }}>
              CSS
            </span>
          ),
          dataIndex: "prep.css",
          align: "center",
          key: "prep-css",
          width: "6vw",
          render: data => (
            <Progress
              className={data === 0 && "ant-progress-null"}
              style={{ margin: "auto" }}
              status={
                data <= 50 && data > 0
                  ? "exception"
                  : data >= 100
                  ? "success"
                  : "normal"
              }
              format={data => <span>{data || 0}%</span>}
              type="circle"
              percent={data || 0}
              width={37}
            />
          ),
        },
        {
          title: (
            <span style={{ ...styles.heading, ...styles.blueHeading }}>JS</span>
          ),
          dataIndex: "prep.js",
          align: "center",
          key: "prep-js",
          className: "border-right padding-right",
          width: "7vw",
          render: data => (
            <Progress
              className={data === 0 && "ant-progress-null"}
              style={{ margin: "auto" }}
              status={
                data <= 50 && data > 0
                  ? "exception"
                  : data >= 100
                  ? "success"
                  : "normal"
              }
              format={data => <span>{data || 0}%</span>}
              type="circle"
              percent={data || 0}
              width={37}
            />
          ),
        },
      ],
    },
    {
      title: (
        <span
          style={{
            ...styles.heading,
            ...styles.redHeading,
            verticalAlign: "middle",
          }}
        >
          {t("Test de rentrée")}
        </span>
      ),
      className: "border-right border-bottom",
      children: [
        {
          title: (
            <span style={{ ...styles.heading, ...styles.redHeading }}>
              HTML / CSS
            </span>
          ),
          dataIndex: "qcm.html",
          align: "center",
          key: "qcm-html",
          width: "7vw",
          className: "padding-left",
          render: data => (
            <Progress
              className={data === 0 && "ant-progress-null"}
              style={{ margin: "auto" }}
              status={
                data <= 50 && data > 0
                  ? "exception"
                  : data >= 100
                  ? "success"
                  : "normal"
              }
              format={data => <span>{data || 0}%</span>}
              type="circle"
              percent={data || 0}
              width={37}
            />
          ),
        },
        {
          title: (
            <span style={{ ...styles.heading, ...styles.redHeading }}>JS</span>
          ),
          dataIndex: "qcm.js",
          align: "center",
          key: "qcm-js",
          className: "border-right padding-right",
          width: "7vw",
          render: data => (
            <Progress
              className={data === 0 && "ant-progress-null"}
              style={{ margin: "auto" }}
              status={
                data <= 50 && data > 0
                  ? "exception"
                  : data >= 100
                  ? "success"
                  : "normal"
              }
              format={data => <span>{data || 0}%</span>}
              type="circle"
              percent={data || 0}
              width={37}
            />
          ),
        },
      ],
    },
    {
      title: <span style={styles.heading}>{t("Entretien")}</span>,
      dataIndex: "interview",
      align: "center",
      key: "interview",
      width: "16vw",
      className: "reduce-padding",
      render: (item, data) => (
        <div style={{ textAlign: "left" }}>
          <h3 style={styles.bold}>{t("Objectif")}</h3>
          <p style={styles.text}>
            {item?.objective ? item.objective.split(";")[0] : "n/a"}
          </p>
          <p style={styles.link} onClick={() => openModal(data.student.id)}>
            {t("Voir plus")}
          </p>
        </div>
      ),
    },
    {
      title: <span style={styles.heading}>PSH</span>,
      dataIndex: "exception",
      align: "center",
      key: "exception",
      width: "5vw",
      render: exception => (
        <Checkbox onChange={onCheckboxChange} checked={exception} />
      ),
    },
  ];

  //FUNCTIONS_________________
  const onCheckboxChange = e => {
    console.log(e.target.checked);
  };

  const openModal = studentId => {
    const selection = batch.find(e => e.student.id === studentId);
    if (selection) {
      setSelectedStudent(selection);
      setVisible(true);
    }
  };

  const closeModal = () => {
    setVisible(false);
  };

  const generateColumns = (data, mode) => {
    return data.map(e => {
      return {
        title: (
          <span
            style={
              mode === "prep"
                ? { ...styles.heading, ...styles.blueHeading }
                : { ...styles.heading, ...styles.redHeading }
            }
          >
            {e}
          </span>
        ),
        dataIndex: e.split(" / ")[0].toLowerCase(),
        key: e.split(" / ")[0],
        align: "center",
        render: percent => (
          <Progress
            format={percentage => (
              <span
                style={{
                  color:
                    percentage === 100
                      ? "#98EDC4"
                      : percentage === 0
                      ? "#000000"
                      : "#F94A56",
                  fontWeight: 600,
                }}
              >
                {percentage || 0}%
              </span>
            )}
            type="circle"
            percent={percent || 0}
            width={37}
            strokeColor={percent === 100 ? "#98EDC4" : "#F94A56"}
          />
        ),
      };
    });
  };

  return (
    <>
      <Row style={styles.container}>
        <Col span={22} offset={1} className="qcm-table">
          <SimpleBar style={{ maxHeight: "calc(100vh - 150px)" }}>
            <Table columns={columns} dataSource={batch} pagination={false} />
          </SimpleBar>
        </Col>
      </Row>
      <Modal
        visible={visible}
        title={selectedStudent && selectedStudent.student.name}
        onCancel={closeModal}
        onOk={openModal}
        footer={null}
        style={{ top: 56, minWidth: "720px", paddingBottom: 0 }}
        bodyStyle={styles.modalBody}
        width="50vw"
        className="positionnement-modal"
      >
        {selectedStudent && (
          <>
            <SimpleBar
              style={{
                maxHeight: "calc(100vh - 120px)",
                minHeight: "65vh",
                width: "100%",
              }}
            >
              <div style={styles.modalContent}>
                <div style={{ ...styles.modalColumn, ...styles.columnLeft }}>
                  <div
                    style={styles.testScoreContainer}
                    className="test-score-container"
                  >
                    <DataDisplay
                      title="Test de Logique"
                      data={`${selectedStudent.test || 0}/24`}
                    />
                  </div>
                  <div style={{ ...styles.tableContainer, marginTop: 20 }}>
                    <h5
                      style={{
                        ...dashboardstyles.contentTitle,
                        alignSelf: "flex-start",
                      }}
                    >
                      Prep work
                    </h5>
                    <div
                      style={{ ...dashboardstyles.contentPanel, padding: 0 }}
                      className="table-container"
                    >
                      <Table
                        columns={generateColumns(["HTML", "CSS", "JS"], "prep")}
                        dataSource={[
                          Object.entries(selectedStudent.prep)
                            .map(e => {
                              return { [`${e[0]}`]: e[1] };
                            })
                            .reduce(
                              (a, c) => {
                                return { ...a, ...c };
                              },
                              { key: "1" }
                            ),
                        ]}
                        pagination={false}
                      />
                    </div>
                  </div>
                  <div style={{ ...styles.tableContainer, marginTop: 30 }}>
                    <h5
                      style={{
                        ...dashboardstyles.contentTitle,
                        alignSelf: "flex-start",
                      }}
                    >
                      Test de rentrée
                    </h5>
                    <div
                      style={{
                        ...dashboardstyles.contentPanel,
                        padding: 0,
                        width: "100%",
                      }}
                      className="table-container"
                    >
                      <Table
                        style={{ width: "100%" }}
                        columns={generateColumns(["HTML / CSS", "JS"], "test")}
                        dataSource={
                          selectedStudent.qcm
                            ? [
                                Object.entries(selectedStudent.qcm)
                                  .map(e => {
                                    return { [`${e[0]}`]: e[1] };
                                  })
                                  .reduce(
                                    (a, c) => {
                                      return { ...a, ...c };
                                    },
                                    { key: "1" }
                                  ),
                              ]
                            : [{ key: "1", html: 0, js: 0 }]
                        }
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ ...styles.modalColumn, alignItems: "center" }}>
                  <div style={styles.modalInfoContainer}>
                    <div style={styles.modalInfoSection}>
                      <h6 style={styles.modalInfoTitle}>Objectif</h6>
                      <p style={styles.modalInfoContent}>
                        {selectedStudent.interview?.objective || "n/a"}
                      </p>
                    </div>
                    <div style={styles.modalInfoSection}>
                      <h6 style={styles.modalInfoTitle}>
                        Cohérence du parcours
                      </h6>
                      <p style={styles.modalInfoContent}>
                        {selectedStudent?.interview?.background || "n/a"}
                      </p>
                    </div>
                    <div style={styles.modalInfoSection}>
                      <h6 style={styles.modalInfoTitle}>Niveau de logique</h6>
                      <p style={styles.modalInfoContent}>
                        {selectedStudent?.interview?.logicLevel || "n/a"}
                      </p>
                    </div>
                    <div style={styles.modalInfoSection}>
                      <h6 style={styles.modalInfoTitle}>Savoir-être</h6>
                      <p style={styles.modalInfoContent}>
                        {selectedStudent?.interview?.softSkills || "n/a"}
                      </p>
                    </div>
                    <div style={styles.modalInfoSection}>
                      <h6 style={styles.modalInfoTitle}>Bilan d'entretien</h6>
                      <p style={styles.modalInfoContent}>
                        {selectedStudent.interview?.overview || "n/a"}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      ...styles.modalInfoContainer,
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                    className="modal-info"
                  >
                    <div style={styles.modalInfoSection}>
                      <div style={styles.modalInfoSectionHeader}>
                        <h6 style={styles.modalInfoTitle}>PSH</h6>
                        <Checkbox
                          onChange={onCheckboxChange}
                          checked={selectedStudent.exception}
                        />
                      </div>
                      <p style={styles.modalInfoContent}>
                        {selectedStudent.exceptionInfo || "n/a"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </>
        )}
      </Modal>
    </>
  );
};

function mapStateToProps({ batchid }) {
  return { batchid };
}

export default connect(mapStateToProps, null)(Positionnement);

const styles = {
  container: {
    height: "100%",
    padding: "10px 0px",
  },
  heading: {
    color: "#010031",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "21px",
    // verticalAlign: 'top',
    // whiteSpace: 'nowrap',
    paddingTop: "30px",
  },
  blueHeading: {
    color: "#5247EA",
    paddingTop: 5,
  },
  redHeading: {
    color: "#F94A56",
    paddingTop: 5,
  },
  bold: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "18px",
  },
  text: {
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "6px",
  },
  link: {
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "18px",
    textDecoration: "underline",
    cursor: "pointer",
    marginBottom: 0,
  },
  modalColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    maxHeight: "100%",
    boxSizing: "border-box",
    minHeight: "100%",
  },
  columnLeft: {
    borderRight: "1px solid #F0F2F5",
    alignItems: "flex-end",
    boxSizing: "border-box",
    marginRight: 20,
    paddingRight: 30,
  },
  modalContent: {
    maxHeight: "calc(100vh - 120px)",
    minHeight: "65vh",
    width: "100%",
    display: "grid",
    padding: "30px 3vw",
    gridTemplateColumns: "293px 1fr",
  },
  modalBody: {
    backgroundColor: "#F6F7FB",
    padding: 0,
  },
  modalInfoContainer: {
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    width: "100%",
  },
  modalInfoSection: {
    borderBottom: "1px solid #F0F2F5",
    padding: "10px 20px",
    width: "100%",
  },
  modalInfoTitle: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
  },
  modalInfoContent: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "21px",
  },
  modalInfoSectionHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign: "left",
  },
  testScoreContainer: {
    width: "100%",
    height: "130px",
    textAlign: "left",
  },
};
