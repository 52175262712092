import React, { Component } from 'react';
import { Layout, Row, Col, Select, Button, Drawer, Form, Input, message, DatePicker, List, Badge } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
// import { EditOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';

import Userpanel from './userpanel';
import Header from '../header';
// import HeaderModal from '../headermodal';

const { Option } = Select;
const { RangePicker } = DatePicker;

const CreateBatch = Form.create()(class extends Component {

  handleSubmit = (e) => {

    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        var newBatch = JSON.stringify({
          name: values.name,
          batchNumber: parseInt(values.batchNumber),
          city: values.city,
          start: values.dates[0]._d,
          end: values.dates[1]._d
        });

        fetch(`${global.URI_BACKEND}/batch/add-new`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: newBatch
        })
          .then(res => res.json())
          .then(data => {
            if (data.result) {
              this.props.addBatch(data.newBatch);
              message.success('Nouveau batch créé !')
            } else {
              message.error('Erreur lors de la création du batch...')
            }
          })
          .catch(error => console.log(error));

        this.props.closeDrawer()
        this.props.form.resetFields();
      }
    });
  };

  handleUpdate = e => {

    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {

        var batchUpdated = {
          id: this.props.batchSelected._id,
          name: values.name,
          batchNumber: parseInt(values.batchNumber),
          city: values.city,
          start: values.dates[0]._d,
          end: values.dates[1]._d
        };
        this.props.updateBatch(batchUpdated);

        var dataBack = JSON.stringify(batchUpdated);

        fetch(`${global.URI_BACKEND}/batch/update`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: dataBack
        })
          .then(res => res.json())
          .then(data => {
            if (data.result) {
              message.success('Batch modifié !')
            } else {
              message.error('Erreur lors de la MAJ du batch...')
            }
          })
          .catch(error => console.log(error));

        this.props.closeDrawer();
        this.props.form.resetFields()
      }
    });
  };

  render() {

    var defaultName = this.props.batchSelected ? this.props.batchSelected.name : null;
    var defaultCity = this.props.batchSelected ? this.props.batchSelected.city : null;
    var defaultNumber = this.props.batchSelected ? this.props.batchSelected.batchNumber : null;

    var defaultStartDate = this.props.batchSelected ? moment(this.props.batchSelected.date.start, "DD-MM-YYYY") : null;
    var defaultEndDate = this.props.batchSelected ? moment(this.props.batchSelected.date.end, "DD-MM-YYYY") : null;
    var defaultDates = this.props.batchSelected ? [moment(defaultStartDate, "DD-MM-YYYY"), moment(defaultEndDate, "DD-MM-YYYY")] : null;

    const { getFieldDecorator } = this.props.form;

    return (<Form>
      <Form.Item>
        {getFieldDecorator(`name`, {
          rules: [
            { required: true, message: 'Nom obligatoire !' },
          ],
          initialValue: defaultName
        })
          (
            <Input
              placeholder="Nom (ex: Batch #10 Paris)"
              allowClear
            />
          )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator(`batchNumber`, {
          rules: [
            { required: true, message: 'N° obligatoire !' },
          ],
          initialValue: defaultNumber
        })
          (
            <Input
              placeholder="N° du batch"
              allowClear
            />
          )}
      </Form.Item>
      <Form.Item hasFeedback>
        {getFieldDecorator(`city`, {
          rules: [
            { required: true, message: 'Choix d\'une ville obligatoire !' },
          ],
          initialValue: defaultCity
        })
          (
            <Select placeholder="Ville" style={{ width: '100%' }}>
              <Option value="Paris">Paris</Option>
              <Option value="Lyon">Lyon</Option>
              <Option value="Lisbonne">Lisbonne</Option>
            </Select>
          )}
      </Form.Item>
      {/* <Form.Item>
          {getFieldDecorator(`stackId`,{
            rules: [
              { required: true, message: 'StackID obligatoire !' },
            ],
          })
          (
            <Input
              placeholder="StackID"
              allowClear
            />
          )}
        </Form.Item> */}
      <Form.Item>
        {getFieldDecorator('dates', {
          rules: [
            { type: 'array', required: true, message: 'Dates obligatoires !' }
          ],
          initialValue: defaultDates
        })
          (
            <RangePicker
              format="DD-MM-YYYY"
              style={{ width: '100%' }}
            />
          )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" onClick={this.props.batchSelected ? this.handleUpdate : this.handleSubmit}>
          {this.props.batchSelected ? "Editer" : "Créer"}
        </Button>
      </Form.Item>
    </Form>)
  }
});

class Batches extends Component {
  state = {
    isVisible: false,
    batchSelected: null,
    list: [],
    citySelected: "Toutes",
    loading: true
  }

  componentDidMount() {
    fetch(`${global.URI_BACKEND}/batch/list`).then(res => res.json()).then(data => {
      if (data.result) {
        this.setState({ list: data.batchesList, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }).catch(error => console.log(error));
  };

  showDrawer = () => this.setState({ isVisible: true });

  onClose = () => this.setState({ isVisible: false, batchSelected: null });

  batchUpdate = (batch) => {
    this.setState({ isVisible: true, batchSelected: batch });
  };

  onCityChange = (city) => {
    this.setState({ citySelected: city })
  };

  addNewBatch = (batch) => {
    var listCopy = [...this.state.list, batch];
    this.setState({ list: listCopy })
  };

  updateBatch = (batch) => {
    for (var i = 0; i < this.state.list.length; i++) {
      if (this.state.list[i]._id === batch.id) {
        this.state.list[i].name = batch.name;
        this.state.list[i].batchNumber = batch.batchNumber;
        this.state.list[i].city = batch.city;
        this.state.list[i].date.start = batch.start;
        this.state.list[i].date.end = batch.end;
      }
    }
  };

  render() {
    var selectionList;
    if (this.state.citySelected !== "Toutes") {
      selectionList = this.state.list.filter(e => e.city === this.state.citySelected)
    } else {
      selectionList = this.state.list
    }

    return (<div style={styles.mainContainer}>
      <Header />
      <Layout style={styles.layout}>
        <Row>
          <Col style={{ marginTop: 154 }} xs={{ span: 24 }} sm={{ offset: 1, span: 22 }} md={{ offset: 1, span: 4 }}>
            <Userpanel name={this.props.user.firstname} />
          </Col>
          <Col xs={{ span: 24 }} sm={{ offset: 1, span: 22 }} md={{ offset: 1, span: 17 }} style={styles.batchesContainer}>
            <div style={styles.batchesDiv}>
              <p className="lc-pink" style={styles.title}>Liste des batches</p>
              <div style={styles.divider}></div>
              <div style={styles.buttonGroup}>
                <Select defaultValue="Villes" style={{ width: 120 }} onChange={this.onCityChange}>
                  <Option value="Toutes">Toutes</Option>
                  <Option value="Paris">Paris</Option>
                  <Option value="Lyon">Lyon</Option>
                  <Option value="Lisbonne">Lisbonne</Option>
                </Select>
                <Button type="primary" onClick={this.showDrawer} icon="plus">
                  Créer un batch
                  </Button>
                <Drawer
                  title={this.state.batchSelected ? "Batch à éditer" : "Nouveau batch"}
                  visible={this.state.isVisible}
                  onClose={this.onClose}
                  placement="right"
                  width={500}
                >
                  <CreateBatch
                    closeDrawer={this.onClose}
                    batchSelected={this.state.batchSelected}
                    addBatch={this.addNewBatch}
                    updateBatch={this.updateBatch}
                  />
                </Drawer>
              </div>
              <div style={styles.batchesList}>
                <List
                  loading={this.state.loading}
                  dataSource={selectionList}
                  renderItem={(batch, i) => (
                    <List.Item key={i}>
                      <List.Item.Meta
                        title={batch.name}
                        description={
                          <Badge
                            status={new Date() > new Date(batch.date.start) ? "processing" : "success"}
                            text={new Date() > new Date(batch.date.start)
                              ? `En cours (Fin : ${moment(batch.date.end, "DD-MM-YYYY")})`
                              : `A venir (Début : ${moment(batch.date.start, "DD-MM-YYYY")})`
                            }
                            color={new Date() > new Date(batch.date.start) ? "green" : "gold"}
                          />
                        }
                      />
                      <div>
                        <Button icon="edit" onClick={() => this.batchUpdate(batch)}>Mettre à jour</Button>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    </div>);
  }
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, null)(Batches);

const styles = {
  mainContainer: { minHeight: '79vh', maxHeight: '100vh' },
  layout: { backgroundColor: '#F6F6FA', minHeight: '100vh' },
  batchesContainer: { backgroundColor: '#fff', paddingBottom: 20, boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.3)', marginBottom: 40, marginTop: 154 },
  batchesDiv: { height: '100vh', marginTop: 15, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' },
  title: { fontSize: 20 },
  divider: { height: 1, width: '80%', backgroundColor: 'lightGrey' },
  buttonGroup: { marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-around' },
  batchesList: { width: '80%', marginTop: 25 }
}
