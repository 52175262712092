export default function(refreshDashboard=false, action) {
    if(action.type === 'refresh'){
        return !refreshDashboard;
    }else {
        return refreshDashboard;
    }
    // if(action.type === 'setTrue') {
    //     console.log("setTrue")
    //     return true;
    // }else if (action.type === 'setFalse') {
    //     return false;
    // }
    
}