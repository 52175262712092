import moment from "moment"

const sortBatches = (a, b) => {
    let now = moment()

    let aStart = moment(a.date.start)
    let aEnd = moment(a.date.end)

    let bStart = moment(b.date.start)
    let bEnd = moment(b.date.end)

    return (
        now.isBetween(aStart, aEnd) && !now.isBetween(bStart, bEnd)
        ? -1
        : now.isBetween(bStart, bEnd) && !now.isBetween(aStart, aEnd)
        ? 1
        : bStart.isAfter(now) && !aStart.isAfter(now)
        ? -1
        : aStart.isAfter(now) && !bStart.isAfter(now)
        ? 1
        : bEnd.isBefore(aEnd)
        ? -1 
        : aEnd.isBefore(bEnd)
        ? 1 
        : 0
    )
}

export default sortBatches