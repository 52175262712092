import React, { Component } from 'react';
import { Col, Row, Button, Modal, notification } from 'antd';
import Selects from './Vote/Selects';

export default class Votes extends Component {
  state = {
    options: [],
    select: {},
    fullProjectList: [],
    voteConfirmation: false,

  }

  componentDidMount() {
    const { options = [], userId, options: fullProjectList, user } = this.props;

    fetch(`${global.URI_BACKEND}/project/votes/${userId}/`).then(res => res.json()).then(data => {
      if (!data?.error) {
        let select = {};

        for (const e of data) {
          select[e.like] = e._id;
        }

        this.setState({ options, select, fullProjectList });
      }
    });
  }

  handleChange = (v, k) => {
    let { select } = this.state;
    select[k] = v;

    this.setState({ select });
  }

  submitVotes = () => {
    const { select, fullProjectList } = this.state;
    const { userId } = this.props;
    this.setState({ voteConfirmation: true });

    const projects = Object.entries(select).map(([like, _id]) => ({ _id, like })).filter(e => e?._id?.match(/[a-f0-9]{24}/));

    if (fullProjectList.length >= 3 && projects.length !== 3) {
      notification.warn({ message: 'MVP', description: ' Pas si vite, tu dois voter pour trois projets 😉' });
    } else {
      notification.success({ message: 'MVP', description: 'Ton vote a bien été pris en compte !' });

      fetch(`${global.URI_BACKEND}/project/votes/${userId}/`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projects })
      }).then(res => res.json()).then(data => {
        if (!data.error) {
          let select = {};

          for (const e of data) {
            select[e.like] = e._id;
          }

          this.setState({ select });
        }
      });
    }
  }

  closeVoteConfirmation = () => {
    this.setState({ voteConfirmation: false });
  }

  render() {
    const { background, titleTop, titleBottom, button, bodyModal, modalMask } = styles;
    const { options, select, fullProjectList, voteConfirmation } = this.state;
    const { user } = this.props;

    return (
    <Row type="flex" justify="space-between" style={background}>
      <Col flex="auto">
        <div style={titleTop}>Je vote pour mes 3 projets favoris</div>
        <div style={titleBottom}>par ordre de préférence parmi les projets ci-dessous.</div>
      </Col>
      <Col flex="auto">
        <Row type="flex" align="bottom" id="vote-row__products">

          <Selects key={Date.now()} select={select} options={options} fullProjectList={fullProjectList} handleChange={this.handleChange} />

          <Col flex="auto">
            <Button style={button} onClick={this.submitVotes}>Soumettre mes votes</Button>

          </Col>
        </Row>
      </Col>
    </Row>
    )
  }
}

const styles = {
  background: { backgroundColor: '#593df3', padding: 10, borderRadius: 9, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', margin: 10 },
  titleTop: { fontSize: 28, fontWeight: 'bold', color: 'white' },
  titleBottom: { fontSize: 18, fontWeight: 300, color: 'white' },
  button: { fontSize: 14, fontWeight: 'bold', color: '#593df3' },
  bodyModal: { backgroundColor: '#F6F6FA', borderRadius: 10 },
  modalMask: { backgroundColor: '#878789' },

};