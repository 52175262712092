import typeChecker from '../../typeChecker';

function objectExpression(tc_obj = {}, pb_obj = {}) {
  const props = [];

  tc_obj_loop: for (let i = 0; i < tc_obj.properties?.length; i++) {
    const tc_prop = tc_obj.properties[i];

    for (let j = 0; j < pb_obj.properties?.length; j++) {
      const pb_prop = pb_obj.properties[j];

      props[i] = typeChecker(tc_prop, pb_prop);

      if (props[i]) {
        continue tc_obj_loop;
      }
    }
  }

  return (props.length === tc_obj.properties?.length && props.every(e => e === true));
}

export default objectExpression;