import typeChecker from './typeChecker';

/**
 * @param {Array} parsedBody Code de l'élève
 * @param {Array} testCode Code de test (provenant de la DB)
 * @returns {Boolean}
 */

export default function test(parsedBody = [], testCode = []) {
  // console.log('%cDébut de la fonction `test`.', 'color: #33A5FF'); // DEBUG
  // console.log(parsedBody, testCode)
  let found = [];
  let found_instructions = 0;

  tc_loop: for (let i = 0; i < testCode.length; i++) {
    const tc = testCode[i];

    for (let j = found_instructions; j < parsedBody.length; j++) {
      const pb = parsedBody[j];

      if (pb?.type && tc?.type === pb?.type) {
        found[i] = typeChecker(tc, pb);

        if (found[i]) {
          found_instructions = i + 1;
          continue tc_loop;
        }
      }
    }

    // DEBUG
    // if (!found[i]) {
    //   console.log(`%cDEBUG: test() > Une erreur a été trouvée à l'instruction : ${i}.`, 'font-weight: bold; color: #FF8080');
    // }
  }

  const filtered = found.filter(e => e !== undefined);
  const result = filtered.length > 0 && filtered.length === testCode.length && filtered.every(e => e === true);

  // console.log('Résultat:', found, '\nExercice Validé ?', result); // DEBUG

  return result;
}