import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import Header from './Header/Header';
import SiderMenu from './Franchised/SiderMenu';
import FranchisedContainer from './Franchised/FranchisedContainer';

class Dashboard extends Component {
  state = {
    selectedKeys: [],
    manuelop: [],
    selectedManual: {},
    isComponentMounted: false
  }

  componentDidMount() {
    this.fetchMenu();
  }

  fetchMenu = async () => {
    const { selectedKeys } = this.state;
    const language = 'fr'

    try {
      const response = await fetch(`${global.URI_BACKEND}/manuelop/${language}`);
      const data = await response.json();

      const newState = { manuelop: data.manuelops, isComponentMounted: true };

      if (selectedKeys.length === 0) {
        newState.selectedKeys = [data.manuelops?.[0]?._id, data.manuelops?.[0]?.subChapters?.[0]?._id] || [];
        newState.selectedManual = data.manuelops.find(m => m._id === newState.selectedKeys?.[0])?.subChapters?.find(s => s._id === newState.selectedKeys?.[1]) || [];
      }

      this.setState(newState);
    } catch (error) {
      console.error(error);
    }
  }

  selectKeys = selectedKeys => {
    const { manuelop } = this.state;

    const selectedManual = manuelop.find(m => m._id === selectedKeys[0])?.subChapters?.find(s => s._id === selectedKeys[1]) || [];

    this.setState({ selectedKeys, selectedManual });
  }

  render() {
    const { layout } = styles;
    const { user: { _id } } = this.props;
    const { manuelop, selectedManual, selectedKeys, isComponentMounted } = this.state;

    if (!_id) return <Redirect to="/" />;

    return (<Layout style={layout}>
      <Header />

      <Layout style={{ flex: 1 }}>
        {isComponentMounted && <SiderMenu manuelop={manuelop} selectKeys={this.selectKeys} defaultSelectedKeys={selectedKeys} />}
        <div style={{ width: '100%', overflowY: 'scroll', marginTop: 10 }}>
          {/* <Menu currentSelected={null} /> */}
          <FranchisedContainer selectedManual={selectedManual} title={selectedManual.title} chapterId={selectedKeys[0]} />
        </div>

      </Layout>
    </Layout>);
  }
}

function mapDispatchToProps({ user }) {
  return { user };
}

export default withRouter(connect(mapDispatchToProps)(Dashboard));

const styles = {
  layout: { height: '100%', fontFamily: 'roboto', minWidth: 992 }
};
