import React, { Component } from "react";
import { Col, Row } from "antd";
import moment from "moment";

import logo from "../Login/Lacapsulerose.png";

export default class WorkshopWaitroom extends Component {
  state = {
    checkInterval: null,
    workshop: null,
  };

  getWorkshopData = async id => {
    const data = await fetch(`${global.URI_BACKEND}/batch/workshop/${id}`);
    const json = await data.json();

    return json;
  };

  unsetInterval = () => {
    const { checkInterval } = this.state;
    if (checkInterval) {
      clearInterval(checkInterval);
      this.setState({ checkInterval: null });
    }
  };

  checkStatus = () => {
    const { workshop } = this.state;
    if (workshop && moment.utc().isSameOrAfter(moment(workshop.date.start))) {
      this.unsetInterval();
      window.location.href = "/home/today";
      return;
    }
  };

  componentDidMount() {
    const batchId = localStorage.getItem("batchIdSelected");

    this.getWorkshopData(batchId).then(data => {
      if (moment.utc().isSameOrAfter(moment(data.workshop.date.start))) {
        this.unsetInterval();
        window.location.href = "/home/today";
        return;
      }

      const checkInterval = setInterval(
        () => this.checkStatus(),
        1 * 60 * 1000
      );
      this.setState({ workshop: data.workshop, checkInterval });
    });
  }

  componentWillUnmount() {
    this.unsetInterval();
  }

  render() {
    const { container, rowHeight, height, title } = styles;

    return (
      <div style={{ height: "100vh" }}>
        <Col style={container}>
          <Row align="middle" justify="center" type="flex" style={rowHeight}>
            <a href="/">
              <img className="logo" src={logo} style={height} alt="logo" />
            </a>
          </Row>
        </Col>
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ height: "100%" }}
        >
          <Row align="middle" justify="center">
            <Row style={title}>Bienvenue à La Capsule !</Row>
            <Row style={{ ...title, fontSize: 23 }}>
              Soyez patient le workshop démarrera prochainement.
            </Row>
          </Row>
        </Row>
      </div>
    );
  }
}

const styles = {
  container: { width: 200, position: "absolute" },
  rowHeight: { height: 64 },
  height: { height: 32, marginLeft: "30px" },
  title: {
    fontSize: 33,
    fontFamily: "roboto",
    fontWeight: "bold",
    color: "#272727",
    textAlign: "center",
    marginBottom: 37,
  },
};
