import React, { Component } from 'react';

//STYLES___________________
import './Header.css'

//UI_____________________
import { Menu } from 'antd';

//NAVIGATION_____________________
import { Link } from 'react-router-dom';

//REDUX__________________________
import { connect } from 'react-redux';

//UTILITIES_______________________
// import checkRank from '../../utils/checkRank';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

const { Item } = Menu;

class Nav extends Component {

  componentDidMount() {
    const { onItemClick, menuSelected, user, menu } = this.props;

    let links = [
      {link: `/home/${menuSelected}`, key: '1'},
      {link: '/syllabus/course', key: '2'},
      {link: `/dashboards/student/${user._id}`, key: '3'},
      {link: `/dashboards/teacher`, key: '3'},
      {link: '/projects', key: '4'},
      {link: '/syllabus/title', key: '5'},
      {link: '/studentList/classmates', key: '6'},
      {link: '/batch/admin/buddies', key: '7'}
    ]

    if (menu === 'campus') {
      links = [
        {link: '/franchised', key: '1'},
        {link: '/dashboards/teacher', key: '2'},
        {link: '/batch/admin/batchedit', key: '3'},
      ]
    } else if (menu === 'post-program') {
      links = [
        {link: '/studentList/alumni', key: '1'},
        {link: '/post-program', key: '2'},
      ]
    }

    const activeNav = links.find(l => l.link === window.location.href.substr(global.URI_FRONTEND.length))?.key
    if (activeNav) onItemClick(activeNav) 
  }

  render() {
    const { main, item, itemVertical, menuVertical } = styles
    const { onItemClick, batch, menuSelected, user, firstStep, mode, menu, stackType, t, nav } = this.props
    // const disabled = moment(batch.currentDay.date).isBefore(moment(batch.date.end))

    //COMMENT : remove 'assistant' in hasPermissions (only for test)
    // const hasPermissions = checkRank(user, { allowedRanks: ['teacher', 'superadmin', 'admin', 'manager', 'assistant'], isFranchised: true });

    return menu === 'batch' && batch.stackType !== 'workshop' ? (
      <Menu theme={ mode === 'horizontal' ? 'dark' : 'light' } selectedKeys={ nav } mode={ mode } className={ `nav-${mode} batch ${user.type === 'student' ? user.type : 'other'}` } style={ mode === 'horizontal' ? main : menuVertical }>
        <Item style={mode === 'horizontal' ? item : itemVertical } key="1" onClick={() => onItemClick('1')}>
          <Link to={`/home/${menuSelected}`} style={mode === 'horizontal' ? item : itemVertical }>
            {t("Home")}
          </Link>
        </Item>
        <Item style={mode === 'horizontal' ? item : itemVertical } key="2" onClick={ ()=>onItemClick('2') }>
          <Link to ="/syllabus/course" style={mode === 'horizontal' ? item : itemVertical }>
            {t("Syllabus")}
          </Link>
        </Item>
        <Item style={mode === 'horizontal' ? item : itemVertical } key="3" onClick={ ()=>onItemClick('3') }>
          <Link to={ user?.type === 'student' ? `/dashboards/student/${user._id}` : '/dashboards/teacher' } style={mode === 'horizontal' ? item : itemVertical }>
            { user?.type === 'student' ? 'Dashboard' : t('Suivi Batch') }
          </Link>
        </Item>
        {
          stackType.keyStack === 'fullstack-js' &&
          <Item style={mode === 'horizontal' ? item : itemVertical } key="4" onClick={ ()=>onItemClick('4') }>
            <Link to={ `/projects` } style={mode === 'horizontal' ? item : itemVertical }>
              MVP
            </Link>
          </Item>
        }

        <Item style={mode === 'horizontal' ? item : itemVertical } key="6" onClick={ () => onItemClick('6') }>
          <Link to={'/studentList/classmates'} style={mode === 'horizontal' ? item : itemVertical }>
            { user?.type === 'student' ? 'Classmates' : t('Élèves') }
          </Link>
        </Item>
        {
          user?.type !== 'student' && user?.type !== 'assistant' &&
          <Item style={mode === 'horizontal' ? item : itemVertical } key="7" onClick={ () => onItemClick('7') }>
            <Link to={'/batch/admin/buddies'} style={mode === 'horizontal' ? item : itemVertical }>
              Admin
            </Link>
          </Item>
        }
      </Menu>
    ) : menu === 'campus' ? (
      <Menu theme={ mode === 'horizontal' ? 'dark' : 'light' } selectedKeys={ nav } mode={ mode } style={ mode === 'horizontal' ? main : menuVertical } className={ `nav-${mode} campus` }>
        <Item style={mode === 'horizontal' ? item : itemVertical } key="1" onClick={() => onItemClick('1')}>
          <Link to={`/franchised`} style={mode === 'horizontal' ? item : itemVertical }>
            {t("Manuel Opérationnel")}
          </Link>
        </Item>
        <Item style={mode === 'horizontal' ? item : itemVertical } key="2" onClick={() => onItemClick('2')}>
          <Link to={`/dashboards/teacher`} style={mode === 'horizontal' ? item : itemVertical }>
            Dashboard
          </Link>
        </Item>
        <Item style={mode === 'horizontal' ? item : itemVertical } key="3" onClick={() => onItemClick('3')}>
          <Link to={`/batch/admin/batchedit`} style={mode === 'horizontal' ? item : itemVertical }>
            Admin
          </Link>
        </Item>
      </Menu>
    ) : menu === 'post-program' ? (
      <Menu theme={ mode === 'horizontal' ? 'dark' : 'light' } selectedKeys={ nav } mode={ mode } style={ mode === 'horizontal' ? main : menuVertical } className={ `nav-${mode} post-program` }>
        <Item style={ mode === 'horizontal' ? item : itemVertical } key="1" onClick={() => onItemClick('1')}>
          <Link to={`/studentList/alumni`} style={mode === 'horizontal' ? item : itemVertical }>
            Alumni
          </Link>
        </Item>

        <Item style={ mode === 'horizontal' ? item : itemVertical } key="2" onClick={ ()=>onItemClick('2') }>
          <Link to ="/post-program" style={mode === 'horizontal' ? item : itemVertical }>
            Career Playbook
          </Link>
        </Item>

        {/* <Item style={ mode === 'horizontal' ? item : itemVertical } key="3" onClick={ ()=>onItemClick('3') }>
          <Link to={ '#' } style={mode === 'horizontal' ? item : itemVertical }>
            Ateliers
          </Link>
        </Item>

        <Item style={ mode === 'horizontal' ? item : itemVertical } key="4" onClick={ ()=>onItemClick('4') }>
          <Link to='#' style={mode === 'horizontal' ? item : itemVertical }>
            Masterclass
          </Link>
        </Item> */}
      </Menu>
    ) : null
  }
}

function mapStateToProps({ batch, menuSelected, user, firstStep, menu, stackType, nav }) {
  return { batch, menuSelected, user, firstStep, menu, stackType, nav };
}

function mapDispatchToProps(dispatch) {
  return {
    onItemClick: function (key) {
      dispatch({ type: 'nav', key });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Nav));

const styles = {
  main: {
    lineHeight: '40px', 
    background: 'transparent', 
    fontFamily: 'Roboto', 
    fontWeight: 500, 
    textTransform: 'uppercase', 
    letterSpacing: 1.57, 
    fontSize: 11 
  },
  item: { margin: 0 },
  itemVertical: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    borderBottom: '0.5px solid #F0F2F5',
    width: '100%',
    height: '47px',
    padding: '12px 24px',
    cursor: 'pointer',
    position: 'relative',
    fontWeight: 600,
    fontFamily: 'Poppins, sans-serif',
  },
  menuVertical: {
    borderBottom: 'none',
    marginBottom: 20
  }
}
