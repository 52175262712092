import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fontello/css/fontello.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import socketIOClient from 'socket.io-client';

import './i18n';

global.URI_BACKEND = 'https://ariane-backend.herokuapp.com';
if (process.env.REACT_APP_ENV === 'development') {
  global.URI_BACKEND = 'http://localhost:3000';
} 
// else if (process.env.REACT_APP_ENV === 'test') {
//   global.URI_BACKEND = 'https://site--ariane-backend-test--gkh7rqyr4vls.code.run';
// }

global.SOCKET = socketIOClient(global.URI_BACKEND, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  withCredentials: true,
  transports: ['websocket']
});

global.URI_FRONTEND = 'https://ariane.lacapsule.academy';
if (process.env.REACT_APP_ENV === 'development') {
  global.URI_FRONTEND = 'http://localhost:3001';
} else if (process.env.REACT_APP_ENV === 'test') {
  global.URI_FRONTEND = 'https://ariane-test.lacapsule.academy';
}

ReactDOM.render(<App />, document.getElementById('root'));

unregister();
