import typeChecker from '../../typeChecker';

function functionExpression(tc_funcExp = {}, pb_funcExp = {}) {
  const check = {};

  if (tc_funcExp?.params?.length > 0) check.params = false;
  if (tc_funcExp.async) check.async = false;
  if (tc_funcExp.body?.body?.length > 0) check.body = false;

  if (check.params && check.params === false) {
    const params = [];

    let found_params = 0;

    tc_params_loop: for (let i = 0; i < tc_funcExp.params?.length; i++) {
      const tc_params = tc_funcExp.params[i];

      for (let j = found_params; j < pb_funcExp.params?.length; j++) {
        const pb_params = pb_funcExp.params[j];

        const checkResult = typeChecker(tc_params, pb_params);

        params[i] = checkResult;

        if (checkResult) {
          found_params = i + 1;
          continue tc_params_loop;
        }
      }
    }

    check.params = (params.length === tc_funcExp.params?.length && params.every(e => e === true));
  }

  if (check.async === false) {
    check.async = tc_funcExp.async === pb_funcExp.async;
  }

  if (check.body === false) {
    console.log(tc_funcExp.body, pb_funcExp.body)
    check.body = typeChecker(tc_funcExp.body, pb_funcExp.body);
  }

  return Object.values(check).every(e => e === true);
}

export default functionExpression;