export default function(alumnisId = {}, action) {
  if(action.type == 'displayProfile') {
    const alumnisIdCopy = {...alumnisId};
    alumnisIdCopy.id = action.id;
    alumnisId = alumnisIdCopy;
      return alumnisIdCopy;
  } else {
      return alumnisId;
  }
}
