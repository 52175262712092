import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';

import ProjectCard from './ProjectCard';

class SubmittedProjects extends Component {
  state = {
    projects: [],
    update: 0
  }

  componentDidMount() {
    const { batchid } = this.props;
    window.addEventListener('resize', this.updateDimensions());
    
    fetch(`${global.URI_BACKEND}/project/batch/${batchid}`).then(res => res.json()).then(data => {
      if (!data?.error) {
        const { projects } = data;

        this.setState({ projects }, () => this.updateDimensions());
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions());
  }

  updateDimensions = () => {
    if (this.rowRef?.clientHeight) {
      this.setState({ projectsHeight: this.rowRef.clientHeight });
    }
  }

  updateProject = project => {
    const { projects, update } = this.state;

    let projectsCopy = projects.slice();
    projectsCopy[projectsCopy.findIndex(e => e._id === project._id)].team = project.team;

    this.setState({ projects: projectsCopy, update: update + 1 });
  }

  render() {
    const { card_container } = styles
    const { projectsHeight, projects = [], update } = this.state;
    const { batchid, collapsed, user } = this.props;

    const assignedStudents = projects.flatMap(e => e?.team.flatMap(s => s));

    const projectList = projects.map(e => {

      const votes = [];
      e.votes.forEach(s => {
        if (!assignedStudents?.some(v => v.userId === s?.student?._id)) {
          votes.push(s);
        }
      });

      return <ProjectCard collapsed={ collapsed } owner={ e.owner } key={`${e._id}-${update}`} showMod updateProject={this.updateProject} id={e._id} name={e.name} pitch={e.pitch} voted={e.voted} team={e.team} votes={votes} batchid={batchid} />
    });
    return (
      <Row>
      <Col span={ 22 } offset={ collapsed || user.type === 'student' || user.type === 'assistant' ? 1 : 0 }>
        <Row type="flex" style={ styles.card_container }>
          {projectList}
        </Row>
      </Col>
    </Row>
    )

  }
}

function mapStateToProps({ batchid, user }) {
  return { batchid, user };
}

export default connect(mapStateToProps)(SubmittedProjects);

const styles = {
  card_container: {
    // justifyContent: 'center',
    width: '100%'
  }
}