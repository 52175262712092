import React, { Component } from 'react';
// import ReactDOMServer from 'react-dom/server';
import { Icon, Row, Col, Layout, message, Anchor, Button, Popover, Select } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import hljs from 'highlight.js'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles/overwritequill.snow.css';
import 'highlight.js/styles/solarized-light.css'

import Header from './header';
import SideMenu from './sidemenu';
import FooterNav from './footernav';
import Spacer from './spacer';
import DisplayVideo from './video';

import Transform from "./v3/Components/Transform";

hljs.configure({ languages: ['javascript', 'html', 'css'] });

// const Step = Steps.Step;
const { Content } = Layout;
const { TabPane } = Tabs;
// const { Step } = Steps;
const { Link } = Anchor;

class Ressource extends Component {
  copyClipboard(txt) {
    var el = document.createElement('textarea');
    el.value = txt;
    el.style.visible = 'hidden';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    message.success('Link copied! Password: L@C@psule');
  }

  render() {
    const { enableLiveCode, resourceType, uri, text } = this.props;

    if (enableLiveCode == false && resourceType == "livecode") {
      return null;
    }

    if (resourceType !== "livecode") {
      let href = (uri && uri.indexOf("http") == 0) ? uri : global.URI_FRONTEND + uri;

      return (<Row type="flex" justify="space-around" align="middle" style={{ marginBottom: 5 }}>
        <Col offset={2} span={1} style={{ display: 'flex', alignItems: 'center' }}>
          <a style={{ fontSize: "15px", color: "rgba(0, 0, 0, 0.65)" }} target="_blank" rel="noopener noreferrer" href={href}>

            {
              resourceType === "archive"
                ? <Icon type="download" />
                : <Icon type="link" />
            }
          </a>


        </Col>
        <Col span={20} >
          <a className="pink" target="_blank" rel="noopener noreferrer" href={uri}>{text}</a>
        </Col>
      </Row>);
    } else {
      return (<Row type="flex" justify="space-around" align="middle" style={{ marginBottom: 5 }}>
        <Col offset={2} span={1} style={{ display: 'flex', alignItems: 'center' }}>
          <Icon style={{ fontSize: "15px" }} type="code" />
        </Col>
        <Col span={20} >
          <span onClick={() => { this.copyClipboard("git clone " + uri) }} className="lc-pink" style={{ cursor: "pointer" }}>git clone {uri.substring(0, 30)}...</span>
        </Col>
      </Row>);
    }
  }
}

class Slide extends Component {
  solutions = {};
  doc = { overview: null, content: null };
  timeLockSolution = 15 * 60 * 1000; // 15mins
  timeLockHelp = 7 * 60 * 1000; // 7mins
  timeLock = this.timeLockHelp;
  state = {
    isReviewShown: true,
    isSlidesShown: true,
    // slideSrc: "/slides/webservice/index.html?postMessageEvents=true",
    // slideSrc: "/slides/webservice/"
    summaryOverview: [],
    summaryContent: [],
    content: null,
    overview: null,
    displaySummary: 'summaryContent',
  };

  handleSolution = (e) => {
    if (this.timeLock == 0 && (e.target.name == '@solution' || e.target.name == '@help')) {
      let mainTable = e.target.closest('table');
      let table = this.doc.content.body.querySelector("#" + mainTable.id + " tbody");
      for (let i = 0; i < this.solutions[mainTable.id].length; i++) {
        table.appendChild(this.solutions[mainTable.id][i]);
      }
      this.solutions[mainTable.id] = null;
      let content = ReactHtmlParser(this.doc.content.body.innerHTML, { transform: Transform });
      this.setState({ content });

      this.timeLock = (e.target.name == '@solution') ? this.timeLockSolution : this.timeLockHelp;
      this.lock();
      setTimeout(this.unlock, this.timeLock);
    }
  }

  handleSyncAlgolia = () => {
    fetch(global.URI_BACKEND + '/content/sync-algolia', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: 'content=' + encodeURI(document.getElementById('content').innerHTML) + '&id=' + this.props.data[this.props.position.stepPosition].step.review[0]._id
    }).then(response => response.json()).then(data => { });
  }

  loadContent = (url, type) => {
    if (!url) return false;

    fetch(global.URI_BACKEND + '/content/external-load' + '?uri=' + encodeURI(url)).then(response => response.text()).then(data => {
      if (!data?.error) {
        var parser = new DOMParser();
        data = data.replace(/(\.[a-z0-9]+){/ig, '#' + type + ' $1{');
        data = data.replace(/}([a-z0-9]+){/ig, '}#' + type + ' $1{');

        this.doc[type] = parser.parseFromString(data, "text/html");

        let oldStyle = document.querySelector('#style-' + type);
        if (oldStyle) {
          oldStyle.remove();
        }

        let styleElmt = document.getElementsByTagName('head')[0];
        let newStyle = this.doc[type].querySelector('style');
        newStyle.id = "style-" + type;

        styleElmt.insertBefore(newStyle, styleElmt.firstChild);
        document.querySelector('style').sheet.insertRule('#' + type + ' table.noBorder td { border: 0px !important; }', document.querySelector('style').sheet.cssRules.length);
        document.querySelector('style').sheet.insertRule('#' + type + ' p, #' + type + ' h1, #' + type + ' h2 { margin: 2px !important; }', document.querySelector('style').sheet.cssRules.length);
        document.querySelector('style').sheet.insertRule('#' + type + ' { line-height: 1.5 !important; }', document.querySelector('style').sheet.cssRules.length);


        if (type == "content") {

          var tableList = this.doc.content.body.querySelectorAll("table");
          for (let i = 0; i < tableList.length; i++) {

            let trList = tableList[i].querySelectorAll('tr');
            let type = trList[0].textContent.toLowerCase().trim();
            if (['@solution', '@aide', '@tip'].indexOf(type) == -1) {
              continue;
            }

            tableList[i].classList.add("noBorder");

            let id = 'internal-' + Math.floor(Math.random() * 1000);
            trList[0].textContent = trList[0].textContent.trim() + ":" + id
            tableList[i].id = id;
            this.solutions[id] = [];
            for (let j = 1; j < trList.length; j++) {

              if (type == '@tip') {
                let pList = trList[j].querySelectorAll('p');
                for (let k = 0; k < pList.length; k++) {
                  this.solutions[id].push(pList[k]);
                }
              } else {
                this.solutions[id].push(trList[j]);
              }

              trList[j].remove();
            }

            if (type == '@tip') {
              let sibling = tableList[i].previousElementSibling;
              while (sibling) {
                if (sibling.matches("p") && sibling.textContent.trim().length > 0) break;
                sibling = sibling.previousElementSibling
              }
              let text = document.createTextNode(trList[0].textContent);
              let span = document.createElement("span");
              span.appendChild(text);
              sibling.appendChild(span);
              tableList[i].remove();

            }

          }

          //let content = this.loadAction(this.doc.body.innerHTML);
          let content = ReactHtmlParser(this.doc.content.body.innerHTML, { transform: Transform });
          this.setState({ content });

        } else if (type == "overview") {
          let overview = ReactHtmlParser(this.doc.overview.body.innerHTML, { transform: Transform });
          this.setState({ overview });
        }




        var summary = [];
        var titleList = this.doc[type].body.querySelectorAll("h1, h2");
        for (var i = 0; i < titleList.length; i++) {
          let title = titleList[i];
          if (title.tagName == "H1" && title.textContent.length > 0) {
            summary.push(
              {
                title: title.textContent,
                id: title.id,
                secondaryTitle: []
              }
            )
          } else if (summary.length > 0 && title.textContent.length > 0) {
            summary[summary.length - 1].secondaryTitle.push(
              {
                title: title.textContent,
                id: title.id
              }
            )
          }
        }
        if (type == "content") {
          this.setState({ summaryContent: summary });
        } else if (type == "overview") {
          this.setState({ summaryOverview: summary });
        }

      }
    });
  }

  unlock = () => {
    this.timeLock = 0;
    let content = ReactHtmlParser(this.doc.content.body.innerHTML, { transform: Transform });
    this.setState({ content });
  }

  lock = () => {
    let content = ReactHtmlParser(this.doc.content.body.innerHTML, { transform: Transform });
    this.setState({ content });
  }

  componentDidMount() {

    if (this.props.data[this.props.position.stepPosition].step.externalData) {
      setTimeout(this.unlock, this.timeLock);
    }

    if (this.props.data[this.props.position.stepPosition].stepType_v2 == "project" && this.props.data[this.props.position.stepPosition].overviewProject) {
      // console.log(this.props.data[this.props.position.stepPosition].overviewProject.externalData)
      this.loadContent(this.props.data[this.props.position.stepPosition].overviewProject.externalData, "overview");
    }
    // console.log(this.props.data[this.props.position.stepPosition].step.externalData)
    this.loadContent(this.props.data[this.props.position.stepPosition].step.externalData, "content");
    /**
     * Example showing how to receive events from the presentation
     * when the current slide changes. Note that you need to include
     * 'postMessageEvents=true' in the query string portion of the
     * presentation URL for this to work.
     */

    window.addEventListener('message', function (event) {
      var data = JSON.parse(event.data);
      // console.log(data);
      // Make sure we're talking to a presentation
      if (data.namespace === 'reveal') {

        // We only care about the slide changing. The 'ready' event
        // is sent in place of a 'slidechanged' for the very first
        // slide when the presentation loads.
        if (data.eventName === 'slidechanged' || data.eventName === 'ready') {

          // Dig out the presentation state, key properties:
          //   indexh: The index of the current horizontal slide
          //   indexv: The index of the current vertical slide (if any)
          //   indexf: The index of the current fragment (if any)
          // var state = data.state;

          //console.log(state);
        }

      }
    });


  }

  showReview = () => {
    this.setState({ isReviewShown: true })
  }

  showRessources = () => {
    this.setState({ isReviewShown: false })
  }

  showSlides = () => {
    this.setState({ isSlidesShown: true })
  }

  showVideo = () => {
    this.setState({ isSlidesShown: false })
  }

  callback = (key) => {

    if (key == 5) {
      this.setState({ displaySummary: "summaryOverview" });
    } else if (key == 2) {
      this.setState({ displaySummary: "summaryContent" });
    } else {
      this.setState({ displaySummary: null });
    }

  }

  render() {
    var slide = this.props.data[this.props.position.stepPosition];
    var nbColReview = 24;
    var nbColStep = 0;

    if (slide.stepType_v2 == "project") {
      nbColReview = 18
      nbColStep = 6
    } else {
      nbColReview = 24
      nbColStep = 0
    }

    var ressource = slide.step.resources.map((ressourceData, i) => <Ressource key={i} type={ressourceData.resourceType} uri={ressourceData.uri} text={ressourceData.title} resourceType={ressourceData.resourceType} position={this.props.position} enableLiveCode={slide.enableLiveCodeTwo} />)
    //var sequenceChapters = this.props.user.type !== 'student' ? false : true;
    var syncAlgolia = this.props.user.type !== 'student' ? true : false;
    var displayChapters = true
    // console.log(slide.step.slides);

    return (



      <div>



        {this.props.position
          ? <Header title={this.props.data[this.props.position.stepPosition].text} />
          : <Header />
        }



        <Layout style={{ minHeight: '100vh' }}>

          <SideMenu dataSteps={this.props.data} position={this.props.position} jumpToStep={this.props.jumpToStep} />

          <Layout style={{ backgroundColor: 'white' }}>

            <div style={styles.container}>

              <Content style={{ minHeight: '79vh', maxHeight: '79vh' }}>

                <Spacer vh space={12} />

                <Row gutter={16}>




                  {/* ----------------------- ARTHUR  START 2)----------------------------------------------------------------------------------------------------------------*/}
                  <div style={{ float: 'right' }}>
                    {
                      syncAlgolia ?
                        <Button style={{ marginBottom: '5px' }} type="primary" onClick={this.handleSyncAlgolia} icon="setting">Sync Algolia</Button>
                        : null
                    }


                    <FooterNav
                      projectNumber={this.props.projectNumber}
                      dayNumber={this.props.dayNumber}
                      user={this.props.user}
                      batchNumber={this.props.batchNumber}
                      enableLiveCode={slide.enableLiveCodeTwo}
                      previousStep={this.props.previousStep}
                      nextStep={this.props.nextStep} />
                  </div>

                  {
                    slide.step.externalData || slide.step.slides.length > 0 || slide.step.videos.length > 0 || slide.step.review.length > 0 || slide.step.resources.length > 0 ?

                      <div>



                        <div>

                          {
                            (slide.stepType_v2 !== "project") ?

                              <div>
                                <div style={styles.textContent}>
                                  <p>{this.props.data[this.props.position.stepPosition].text}</p>
                                </div>

                                <div style={styles.introText}>
                                </div>
                              </div>

                              : null
                          }



                          <Col span={nbColReview}>

                            <Tabs defaultActiveKey="1" onChange={this.callback}>

                              {
                                slide.step.slides.length > 0 ?

                                  <TabPane tab="Slide" key="1">

                                    <Col span={24} style={{ height: '70vh' }}>

                                      <iframe id="slides" title={slide.step.slides[0].uri} src={global.URI_FRONTEND + slide.step.slides[0].uri}
                                        width="100%" height="420" scrolling="no"
                                        frameBorder="0" controls={false}></iframe>

                                      <Icon type="arrows-alt" style={{ cursor: 'pointer', fontSize: 20, color: '#08c' }} onClick={() => { document.getElementById("slides").contentWindow.Reveal.triggerKey(70) }} />

                                      <Icon type="video-camera" style={{ paddingLeft: '20px', cursor: 'pointer', fontSize: 20, color: '#08c' }} onClick={() => { document.getElementById("slides").contentWindow.RevealNotes.open() }} />

                                    </Col>

                                  </TabPane>

                                  : null
                              }
                              {
                                this.state.overview && slide.stepType_v2 == "project" ?

                                  <TabPane tab="Overview Project" key="5">
                                    <div style={{ width: '100%', paddingBottom: '200px' }} id="overview">
                                      {this.state.overview}
                                    </div>

                                  </TabPane>

                                  : null
                              }
                              {
                                slide.step.review.length > 0 || slide.step.externalData ?

                                  <TabPane tab="Review" key="2">

                                    <div className="ql-readonly" id="text-review" >
                                      {
                                        slide.step.externalData ?

                                          <div style={{ width: '100%', paddingBottom: '200px' }} id="content" onClick={this.handleSolution} >
                                            {this.state.content}
                                          </div>
                                          :
                                          <ReactQuill
                                            onChange={this.loadingReactQuill}
                                            ref={(el) => { this.reactQuillRef = el }}
                                            modules={{
                                              toolbar: false,
                                              syntax: {
                                                highlight: text => hljs.highlightAuto(text).value
                                              }
                                            }}

                                            defaultValue={slide.step.review[0].text}
                                            readOnly
                                          />
                                      }

                                    </div>

                                  </TabPane>

                                  : null
                              }
                              {
                                slide.step.videos.length > 0 ?

                                  <TabPane tab="Video" key="3">

                                    <DisplayVideo chapters={slide.step.videos[0].chapters} displayChapters={displayChapters} enableLiveCode={{ livecodePart1: slide.enableLiveCode, livecodePart2: slide.enableLiveCodeTwo }} videoPoster={slide.step.videos[0].splashscreen} videoSrc={slide.step.videos[0].uri} />

                                  </TabPane>

                                  : null
                              }
                              {
                                slide.step.resources.length > 0 && (slide.stepType_v2 == "project" || slide.stepType_v2 == "challenge") ?

                                  <TabPane tab="Ressources" key="4">
                                    {ressource}
                                  </TabPane>

                                  : null
                              }

                            </Tabs>

                          </Col>


                            <Col span={nbColStep} >
                            {
                              this.state.displaySummary ?

                                <Anchor offsetTop={80}>
                                  {
                                    this.state[this.state.displaySummary].map(item => {


                                      return (<Link href={'#' + item.id} title={item.title}>

                                        {item.secondaryTitle.map(sousItem => {
                                          return (<Link href={'#' + sousItem.id} title={sousItem.title} />)
                                        })}

                                      </Link>)

                                    })
                                  }
                                </Anchor>
                                : null
                            }


                          </Col>
                        

                        </div>

                      </div>

                      : null
                  }

                  {/* -----------------------ARTHUR END 2)------------------------------------------------------ */}

                </Row>

              </Content>

            </div>
          </Layout>
        </Layout>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { batch: state.batch, progression: state.progression, user: state.user }
}

export default connect(mapStateToProps, null)(Slide);

var styles = {
  container: { marginLeft: '30px', marginRight: '12px' },
  spaceAfterButtons: { marginBottom: 40 },
  borderButtonSelected: { borderColor: '#EB5D56', paddingBottom: 6, borderBottomStyle: 'solid', borderBottomWidth: 5, cursor: 'pointer' },
  redColoredText: { fontSize: 18, color: '#EB5D56', cursor: 'pointer' },
  buttonNotSelected: { fontSize: 18, cursor: 'pointer' },
  containerFooter: { backgroundColor: 'white', bottom: 0, width: '100%', paddingLeft: 0, paddingRight: 0 },
  textContent: { /* color:'#EB5D56', */ fontSize: 20 },
  introText: { fontStyle: 'italic', marginBottom: '10px' }
}