import React, { Component } from 'react';
import { Layout, Row, Col, Table, Avatar } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import moment from 'moment';
import checkRank from '../../utils/checkRank';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

class Feedback extends Component {
  state = {
    feelingOfDay: [],
    previousDay: /*moment().subtract(1, 'day').format('LL')*/null
  };

  componentDidMount() {
    (async () => {
      const feelingOfDayRaw = await fetch(`${global.URI_BACKEND}/feelingofday/${this.props.batch._id}`);
      const feelingOfDay = await feelingOfDayRaw.json();
      if (feelingOfDay?.success) this.setState({feelingOfDay: feelingOfDay?.feeling, previousDay: moment(feelingOfDay?.previousDay).format('LL')});
    })();
  }

  render() {
    const { feelingOfDay, previousDay } = this.state;
    const { layout, title, contentSection, subtitle, subtitle2, image } = styles;
    const { batch, user, t } = this.props;

    if (!checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) {
      return <Redirect to='/' />;
    }

    let userFeeling = [];
    let nbrAnswer = 0;
    let avgNotion = 0;
    let avgProject = 0;

    let columns = [
      { title: t('Avatar'), dataIndex: 'avatar' },
      { title: t('Nom'), dataIndex: 'name' },
      { title: t('Compréhension générale'), dataIndex: 'answer1' },
      { title: t('Avancée des projets'), dataIndex: 'answer2' },
    ];

    if (feelingOfDay.length) {
      nbrAnswer = feelingOfDay.length

      let reducer = (accumulator, currentValue) => accumulator + currentValue;

      // MOYENNE NOTIONS DU GROUPE SUR LA JOURNÉE
      const notionAnswer = feelingOfDay.map((u, i) => u.notionUnderstood);
      avgNotion = notionAnswer.reduce(reducer) / nbrAnswer

      // MOYENNE AVANCÉE PROJET DU GROUPE SUR LA JOURNÉE
      const projectAnswer = feelingOfDay.map((u, i) => u.projectProgress);
      avgProject = projectAnswer.reduce(reducer) / nbrAnswer

      // MAP TABLEAU SATISFACTION ELEVES
      userFeeling = feelingOfDay.map((u, i) => {
        if (u.notionUnderstood === 1) { u.notionUnderstood = {emoji: 'very_happy', name: t("J'ai compris tous les concepts") } }
        else if (u.notionUnderstood === 2) { u.notionUnderstood = { emoji: 'happy', name: t("J'ai globalement compris les concepts") } }
        else if (u.notionUnderstood === 3) { u.notionUnderstood = { emoji: 'neutral', name: t("J'ai moyennement compris les concepts") } }
        else if (u.notionUnderstood === 4) { u.notionUnderstood = { emoji: 'bad', name: t("Je n'ai globalement pas compris les concepts") } }
        else if (u.notionUnderstood === 5) { u.notionUnderstood = { emoji: 'very_bad', name: t("Je n'ai rien compris") } }

        if (u.projectProgress === 1) { u.projectProgress = { emoji: 'grinning', name: t("J'ai commencé les challenges") } }
        else if (u.projectProgress === 2) { u.projectProgress = { emoji: 'happy', name: t("J'ai fini en avance") } }
        else if (u.projectProgress === 3) { u.projectProgress = { emoji: 'smiling', name: t("J'ai fini dans les temps") } }
        else if (u.projectProgress === 4) { u.projectProgress = { emoji: 'sleepy', name: t("Je n'ai pas fini") } }

        return {
          key: i + 1,
          avatar: <Avatar src={`${global.URI_FRONTEND}/images/avatar/${u.user._id}.jpg`} style={image} size={55} shape='square' icon='user' />,
          name: u.user.fullname,
          answer1: u.notionUnderstood && <span>{u.notionUnderstood?.name} <img style={{ height: 20 }} src={`/images/emojis/${u.notionUnderstood?.emoji}.png`} /></span>,
          answer2: u.projectProgress && <span>{u.projectProgress?.name} <img style={{ height: 20 }} src={`/images/emojis/${u.projectProgress?.emoji}.png`} /></span>,
        };
      });
    }

    return (
      <Layout style={layout}>
        <Layout>

          <Layout>
            <Row type='flex' style={{ flex: 1 }}>
              <Col style={contentSection}>
                <Row type='flex' justify='space-between' align='middle'>
                  <Col flex='auto'>
                    <Row style={title}>{t("Feedback")} - {batch.name}</Row>
                    <Row style={title}>{t("Date")} : {moment(previousDay).format('LL')}</Row>

                    <Row style={subtitle}>{t("Nombre de réponses")} : {nbrAnswer}</Row>

                    <Row style={subtitle2}>{t("Compréhension générale du groupe")} :
                      {avgNotion <= 1.5 && <> {t("Ils ont compris tous les concepts")} <img style={{ height: 30 }} src='/images/emojis/very_happy.png' alt='very_happy face' /></>}
                      {avgNotion > 1.5 && avgNotion <= 2.5 && <> {t("Ils ont globalement compris les concepts")} <img style={{ height: 30 }} src='/images/emojis/happy.png' alt='happy face' /></>}
                      {avgNotion > 2.5 && avgNotion <= 3.5 && <> {t("Ils ont moyennement compris les concepts")} <img style={{ height: 30 }} src='/images/emojis/neutral.png' alt='neutral face' /></>}
                      {avgNotion > 3.5 && avgNotion <= 4.5 && <> {t("Ils n'ont globalement pas compris les concepts")} <img style={{ height: 30 }} src='/images/emojis/bad.png' alt='bad face' /></>}
                      {avgNotion > 4.5 && <> {t("Ils n'ont rien compris")} <img style={{ height: 30 }} src='/images/emojis/very_bad.png' alt='very_bad face' /></>}
                    </Row>

                    <Row style={subtitle2}>{t("Moyenne d'avancée des projets")} :
                      {avgProject <= 1.5 && <> {t("Ils ont commencé les challenges")} <img style={{ height: 30 }} src='/images/emojis/grinning.png' alt='grinning face' /></>}
                      {avgProject > 1.5 && avgProject <= 2.5 && <> {t("Ils ont fini en avance")} <img style={{ height: 30 }} src='/images/emojis/happy.png' alt='happy face' /></>}
                      {avgProject > 2.5 && avgProject <= 3.5 && <> {t("Ils ont fini dans les temps")} <img style={{ height: 30 }} src='/images/emojis/smiling.png' alt='smiling face' /></>}
                      {avgProject > 3.5 && <> {t("Ils n'ont pas fini")} <img style={{ height: 30 }} src='/images/emojis/sleepy.png' alt='sleepy face' /></>}
                    </Row>
                  </Col>
                </Row>
                {feelingOfDay.length ?
                  <Table
                    style={{ marginTop: 30, marginBottom: 40 }}
                    dataSource={userFeeling}
                    columns={columns}
                    pagination={false}
                    scroll={{ y: 350 }}
                    sticky
                  />
                  : <Row style={{ textAlign: 'center', marginTop: 30, fontSize: 24 }}>{t("Aucune réponse")}</Row>}
              </Col>
            </Row>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps({ batchuserlist, user, batch, day }) {
  return { userList: batchuserlist, user, batch, day };
}

export default connect(mapStateToProps, null)(withTranslation()(Feedback));

const styles = {
  layout: { height: '100vh', fontFamily: 'roboto' },
  contentSection: {
    padding: '20px 25px 10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 29,
    fontFamily: 'roboto',
    fontWeight: 700,
    color: '#272727',
  },
  subtitle: {
    fontSize: 18,
    marginTop: 15,
    fontFamily: 'roboto',
    fontWeight: 700,
    color: '#272727',
  },
  subtitle2: {
    fontSize: 16,
    marginTop: 15,
    fontFamily: 'roboto',
    fontWeight: 500,
    color: '#272727',
  },
  image: {
    width: 55,
    height: 55,
    borderRadius: 9,
    backgroundColor: '#cccccc'
  },

};
