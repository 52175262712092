import React, { Component } from 'react';
import { Col, Select, Icon } from 'antd';

const { Option } = Select;

export default class Selects extends Component {
  state = {
    projects: {}
  }

  componentDidMount() {
    const { fullProjectList } = this.props;

    const projects = {};
    for (const { _id, name } of fullProjectList) {
      projects[_id] = name;
    }

    this.setState({ projects });
  }

  render() {
    const { heartColor, heartRow, selectWidth } = styles;
    const { handleChange, select, options } = this.props;
    const { projects } = this.state;

    const heart = <Icon type="heart" theme="filled" style={heartColor} />;

    const selectList = [];
    for (const e of options) {
      if (!Object.values(select).some(v => v === e._id)) {
        selectList.push(<Option key={e.name} value={e._id}>{e.name}</Option>);
      }
    }

    const inputValues = document.querySelectorAll('div#vote-row__products.ant-row-flex>div.ant-col>div>div.ant-select>div.ant-select-selection.ant-select-selection--single>div.ant-select-selection__rendered')
    for (const e of inputValues) {
      setTimeout(() => {
        e.style.color = "white";
        let p = e.parentNode;

        if (e.querySelector('div.ant-select-selection-selected-value')) {
          p.style.backgroundColor = "#593df3";
          p.parentNode.style.border = "1px solid white";
          p.parentNode.style.borderRadius = "5px";
        } else {
          p.style.backgroundColor = "#380db8";
          p.parentNode.style.border = "1px solid #380db8";
          p.parentNode.style.borderRadius = "5px";
        }
      }, 10); // setTimeout fixes an async problem
    }

    // console.log(projects, select)

    return <>
      <Col flex="auto">
        <div style={heartRow} className="heart-row__products">{heart}{heart}{heart}</div>
        <div>
          <Select value={projects[select['3']]} placeholder="Select a project" style={selectWidth} onChange={v => handleChange(v, 3)} allowClear>
            {selectList}
          </Select>
        </div>
      </Col>
      <Col flex="auto">
        <div style={heartRow} className="heart-row__products">{heart}{heart}</div>
        <div>
          <Select value={projects[select['2']]} placeholder="Select a project" style={selectWidth} onChange={v => handleChange(v, 2)} allowClear>
            {selectList}
          </Select>
        </div>
      </Col>
      <Col flex="auto">
        <div style={heartRow} className="heart-row__products">{heart}</div>
        <div>
          <Select value={projects[select['1']]} placeholder="Select a project" style={selectWidth} onChange={v => handleChange(v, 1)} allowClear>
            {selectList}
          </Select>
        </div>
      </Col>
    </>
  }
}

const styles = {
  heartColor: { fontSize: 17, color: 'white' },
  heartRow: { marginBottom: 10 },
  selectWidth: { width: 192 }
}