import React, { Component } from 'react';
import { Drawer } from 'antd';

export default class NetPromoterScore extends Component {

    componentDidMount(){
        const { npsClose } = this.props;
        global.SOCKET.on('npsCompleted', data => {
            if (data.result){
                console.log('websocket NPS OK');
                npsClose()
            }
        })
    };

    render() {

        const { visible, userId } = this.props;

        const scores = [];

        for (let i = 0; i < 10; i++) {
            scores.push(
                <div
                    className="nps-scores"
                >
                    <a
                        style={{ color: '#5247EA' }}
                        href={`https://lacapsule.typeform.com/to/E1X86f?prefilled_answer=${i + 1}&security_token=${userId}`}
                        target="_blank"
                    >
                        {i + 1}
                    </a>
                </div>
            )
        };

        return (
            <Drawer
                closable={false}
                visible={visible}
                width={350}
                id="satisfaction"
            >
                <img src="/images/backgrounds/emargement.jpg" alt="students smiling" className="satisfaction-img" />
                <div id="nps-container">
                    <p id="nps-title">TON AVIS EST IMPORTANT</p>
                    <p className="nps-text">Afin de proposer la meilleure expérience possible à nos élèves, nous aimerions avoir ton avis sur la formation.<br />
                    Pour ce faire, pourrais-tu prendre un petit moment pour répondre au court questionnaire ci-dessous stp ?</p>
                    <p id="nps-question">Recommanderiez-vous La Capsule à un ami ou un membre de votre famille qui souhaiterait apprendre à coder ? De 0 (non pas du tout) à 10 (oui tout à fait).</p>
                    <div id="note-container">
                        {scores}
                    </div>
                    <p className="nps-text">Ta réponse est anonyme.</p>
                </div>
            </Drawer>
        )
    }
};