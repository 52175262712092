import React, { useState, useEffect } from "react";

//STYLES_______________________
import { dashboardstyles } from "./dashboardstyles";
import "./studentdashboard.css";
import "./teacherdashboards.css";

//UI__________________________
import {
  Row,
  Col,
  Table,
  Badge,
  Layout,
  Spin,
  Menu,
  Radio,
  DatePicker,
} from "antd";

//CHARTS_______________________
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

//SCROLLBAR________________________
import SimpleBar from "simplebar-react";

//NAVIGATION________________________
import { useParams } from "react-router-dom";

//REDUX_____________________________
import { connect } from "react-redux";

//COMPONENTS_________________________
import CompetencesModal from "./components/CompetencesModal";
// import DashboardNavMenu from './components/DashboardNavMenu';
import FeelingEmoji from "./components/FeelingEmoji";
import RadarChartLabel from "./components/RadarChartLabel";
// import DataDisplay from './components/DataDisplay';
import CustomTick from "./components/CustomTick";

//MOMENT_____________________________
import moment from "moment";
import "moment/locale/fr";

//TRANSLATION
import { useTranslation } from "react-i18next";

// const { Content } = Layout;
const { WeekPicker } = DatePicker;

const StudentDashboard = ({
  batchid,
  studentId,
  mode = "student",
  batch,
  stackid,
  stackType,
  calendarModeParent,
  clickDateParent,
  language,
}) => {
  //STATE HOOKS____________________
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  const [studentData, setStudentData] = useState([]);
  const [studentDataLoading, setStudentDataLoading] = useState(false);

  const [weekStudentData, setWeekStudentData] = useState({});

  const [competenceData, setCompetenceData] = useState([]);
  const [competencesLoading, setCompetencesLoading] = useState(false);

  const [averagelHelpStats, setAveragelHelpStats] = useState([]);

  const [competencesProgress, setCompetencesProgress] = useState([]);
  const [competenceSelected, setCompetenceSelected] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  const [absences, setAbsences] = useState([]);
  const [cumulativeAbsences, setCumulativeAbsences] = useState(null);
  const [absencesLoading, setAbsencesLoading] = useState(false);
  const [periods, setPeriods] = useState([]);

  const [clickDate, setClickDate] = useState(moment().utc()); //TO BE CHANGED
  const [calendarMode, setCalendarMode] = useState("batch"); //TO BE CHANGED
  const [radioMode, setRadioMode] = useState("batch");

  const maxFeelingWeeks = batch.planning.filter(
    week => !["project", "review", "rncp"].includes(week.weekType)
  ).length;

  useEffect(() => {
    if (mode === "teacher") {
      if (calendarModeParent === "day") {
        setCalendarMode("batch");
      } else {
        setCalendarMode(calendarModeParent);
      }
      setClickDate(clickDateParent);
    }
  }, [clickDateParent]);

  const planning = batch.planning
    .map(e => e.days.map(f => moment(f.date).utc()))
    .flat()
    .filter(e => e.isBefore(batch.date.end));

  let startDate;
  let endDate;
  let defaultCalendarValue;
  let findWeek;
  let batchWeek;

  if (moment(clickDate).isAfter(moment(batch.date.end))) {
    defaultCalendarValue = moment(batch.date.end);
    findWeek = moment.utc(batch.date.end).week();
    batchWeek = findWeek - moment(batch.date.start).week();
  } else if (moment(batch.date.start).isAfter(moment(clickDate))) {
    defaultCalendarValue = moment(batch.date.start);
    findWeek = moment.utc(batch.date.start).week();
    batchWeek = findWeek - moment(batch.date.start).week();
  } else {
    defaultCalendarValue = moment();
    findWeek = moment.utc(clickDate).week();
    batchWeek = findWeek - moment(batch.date.start).week();
  }

  if (calendarMode === "batch") {
    startDate = moment.utc(batch.date.start).startOf("day");
    moment.utc().isAfter(moment.utc(batch.date.start))
      ? (endDate = moment.utc())
      : (endDate = startDate);
  } else if (calendarMode === "week") {
    startDate = moment
      .utc(batch.planning[batchWeek]?.days[0].date)
      .startOf("day");
    endDate = moment
      .utc(
        batch.planning[batchWeek].days[
          batch.planning[batchWeek].days.length - 1
        ]?.date
      )
      .endOf("day");
    if (
      moment.utc(batch?.currentDay?.date).isBefore(endDate) &&
      moment.utc(batch?.currentDay?.date).week() === moment.utc(endDate).week()
    )
      endDate = moment.utc();
  } else if (calendarMode === "day") {
    startDate = moment.utc(clickDate).utc().startOf("day");
    endDate = moment.utc(clickDate).utc().endOf("day");
  }

  //PARAMS____________________________
  const { id } = useParams();

  //EFFECT HOOKS___________________________
  useEffect(() => {
    const fetchStudentData = async () => {
      if (batchid) {
        setStudentDataLoading(true);
        setAbsencesLoading(true);

        let student = mode === "student" ? id : studentId;
        if (student) {
          let weeks = batch.planning.filter(
            e => !["review", "rncp" /* 'project'*/].includes(e.weekType)
          ).length;

          let weeklyData = Array.from({ length: weeks }, (v, k) => ({
            weekNumber: k + 1,
            week: `S${k + 1}`,
          }));
          let clickedWeekData = {};
          const firstWeek = moment(batch.date.start).isoWeek();
          const currentWeek =
            moment().isoWeek() - moment(batch.date.start).isoWeek() + 1;

          // BATCH, WEEK & DAY
          const requestData = await fetch(
            `${global.URI_BACKEND}/studentdashboard/studentweeklyhelprequests?batchId=${batchid}&studentId=${student}&startDay=${startDate}&endDay=${endDate}&selectedDay=${clickDate}`
          );
          const requestJSON = await requestData.json();

          if (requestJSON?.success) {
            if (calendarMode === "batch") {
              weeklyData = weeklyData.map(e => {
                let helprequests = requestJSON.studentWeeklyHelpRequests.find(
                  f => f.weekNumber - firstWeek + 1 === e.weekNumber
                );

                return { ...e, requests: helprequests?.totalRequests || 0 };
              });
            } else if (calendarMode === "week") {
              clickedWeekData.weekHelpRequests =
                requestJSON.studentWeeklyHelpRequests[0]?.totalRequests;
              clickedWeekData.todayHelpRequests =
                requestJSON.todayHelpRequestsNbr[0]?.totalRequests;
            }
          }

          const evaluationData = await fetch(
            `${global.URI_BACKEND}/studentdashboard/studentevaluations?batchId=${batchid}&studentId=${student}`
          );
          const evaluationJSON = await evaluationData.json();

          // ADD EVALUATION RESULT DEPENDING ON WEEK
          /* if (calendarMode === 'week') {
                        console.log(moment(startDate).isoWeek())
                        console.log('WEEK', moment(startDate).isoWeek() - moment(batch.date.start).isoWeek() + 1)
                    } */

          if (evaluationJSON?.success) {
            const evaluationsTable =
              evaluationJSON?.studentEvalResults[0]?.evaluations;

            weeklyData = weeklyData.map(e => {
              let evaluation = evaluationsTable?.find(
                f => f.weekNumber === e.weekNumber
              );

              return {
                ...e,
                evaluation: evaluation
                  ? {
                      percentage: evaluation?.successPercentage || null,
                      stepId: evaluation?.stepId,
                    }
                  : null,
              };
            });
          }

          // BATCH & WEEK
          const feelingData = await fetch(
            `${global.URI_BACKEND}/studentdashboard/studentweeklyfeeling?batchId=${batchid}&studentId=${student}&startDate=${startDate}&endDate=${endDate}`
          );
          const feelingJSON = await feelingData.json();

          if (feelingJSON?.success) {
            weeklyData = weeklyData.map(e => {
              let feeling = feelingJSON.studentweeklyfeeling.find(
                f => f.weekNumber === e.weekNumber
              );

              clickedWeekData.feelingsTable = feelingJSON.feelingTable;

              return {
                ...e,
                feeling: feeling ? Math.round(feeling.averageFeeling) : null,
              };
            });
          }

          // BATCH, WEEK & DAY
          const solutionData = await fetch(
            `${global.URI_BACKEND}/studentdashboard/studentsolutions?batchId=${batchid}&studentId=${student}&startDay=${startDate}&endDay=${endDate}&selectedDay=${clickDate}`
          );
          const solutionJSON = await solutionData.json();

          if (solutionJSON?.success) {
            if (calendarMode === "batch") {
              weeklyData = weeklyData.map(e => {
                let solutions = solutionJSON.studentWeeklySolutions.find(
                  f => f.weekNumber - firstWeek + 1 === e.weekNumber
                );

                return { ...e, solutions: solutions?.totalSolutions || 0 };
              });
            } else if (calendarMode === "week") {
              clickedWeekData.weekSolutions =
                solutionJSON.studentWeeklySolutions[0]?.totalSolutions;
              clickedWeekData.todaySolutions =
                solutionJSON.todaySolutionsNbr[0]?.totalSolutions;
            }
          }

          const absenceData = await fetch(
            `${global.URI_BACKEND}/studentdashboard/studentpresencev2?batchId=${batchid}&studentId=${student}&startDate=${startDate}&endDate=${endDate}&mode=${calendarMode}`
          );
          const absenceJSON = await absenceData.json();

          if (absenceJSON?.success) {
            if (
              !(
                moment
                  .utc(startDate)
                  .isAfter(moment.utc(batch?.currentDay?.date)) &&
                moment.utc(endDate).isAfter(moment.utc(batch?.currentDay?.date))
              )
            )
              setAbsences(absenceJSON.dayPresenceTable);
            setPeriods(absenceJSON.periods);

            if (absenceJSON.presencePercentage) {
              setCumulativeAbsences(
                absenceJSON.presencePercentage <= 100
                  ? absenceJSON.presencePercentage
                  : 100
              );
            } else {
              setCumulativeAbsences("NR");
            }

            setAbsencesLoading(false);

            weeklyData = weeklyData.map(e => {
              let week =
                absenceJSON.weekPresenceTable &&
                absenceJSON.weekPresenceTable.find(
                  f =>
                    f.weekNumber ===
                    e.weekNumber /* && f.weekNumber !== currentWeek */
                );
              let assiduitePercentage =
                week?.presencePercentage > 100 ? 100 : week?.presencePercentage;

              return { ...e, assiduité: assiduitePercentage };
            });
          } else {
            setAbsencesLoading(false);
          }

          const weekSolutions = await fetch(
            `${global.URI_BACKEND}/studentdashboard/totalbatchstats?batchId=${batchid}&startDay=${startDate}&endDay=${endDate}&mode=${calendarMode}`
          );
          const weekSolutionsJSON = await weekSolutions.json();

          setAveragelHelpStats(weekSolutionsJSON.averagelHelpStats);

          setStudentData(weeklyData);
          setStudentDataLoading(false);
          setWeekStudentData(clickedWeekData);
        }
      }
    };

    fetchStudentData();
  }, [batchid, studentId, mode, id, batch, stackType, clickDate]);

  useEffect(() => {
    const fetchCompetences = async () => {
      if (batchid) {
        setCompetencesLoading(true);
        let compListData = await fetch(
          `${global.URI_BACKEND}/studentdashboard/dynamic-competencies/${stackid}`
        );
        let compListJSON = await compListData.json();

        let dynamicCompetencies = [];

        if (compListJSON.success) {
          dynamicCompetencies = compListJSON.dynamicCompetencies;
        }

        let student = mode === "student" ? id : studentId;

        const competenceData = await fetch(
          `${global.URI_BACKEND}/studentdashboard/studentcompetencies/${
            /*language*/ "fr"
          }?batchId=${batchid}&studentId=${student}&startDay=${startDate}&endDay=${endDate}`
        );
        const competenceJSON = await competenceData.json();

        if (competenceJSON.success) {
          let competences = dynamicCompetencies.map(e => {
            let competence =
              competenceJSON?.studentCompetencies?.mainGroups?.find(
                group => group.name === e.mainGroup
              );

            return competence
              ? {
                  ...competence,
                  category: e.mainGroup,
                  A: competence?.totalProgress || 0,
                }
              : { category: e.mainGroup, A: 0 };
          });

          setCompetenceData(competences);
          setCompetencesLoading(false);
        }
      }
    };

    fetchCompetences();
  }, [batchid, studentId, mode, id, batch, stackid, clickDate /*language*/]);

  //FUNCTIONS_____________________
  const sortAbsences = (a, b) => {
    let jourA = moment(a.jour);
    let jourB = moment(b.jour);

    return jourA.isSame(jourB) ? 0 : jourA.isAfter(jourB) ? 1 : -1;
  };

  const openModal = async competence => {
    // TO DO : Rajouter route pour avoir la modale des compétences à la semaine

    if (calendarMode === "batch") {
      setModalLoading(true);
      setModalVisible(true);
      let student = mode === "student" ? id : studentId;
      let competenceLoaded = competencesProgress.find(
        e => e.mainGroup === competence
      );

      if (competenceLoaded) {
        setCompetenceSelected(competenceLoaded);
        setModalLoading(false);
      } else {
        const data = await fetch(
          `${global.URI_BACKEND}/studentdashboard/competence-progress/${batchid}/${student}/${competence}/${language}`
        );
        const json = await data.json();

        if (json.success) {
          let temp = { ...json.competences };

          const ownCompetenceData = competenceData?.find(
            e => e.category === competence
          );

          temp.overallProgress = ownCompetenceData["A"] || 0;
          temp.resources = temp.resources?.map(e => {
            let ownProgress = ownCompetenceData?.secondaryGroups?.find(
              group => group.name === e.secondaryGroup
            );

            return {
              ...e,
              progress: ownProgress?.progress || 0,
            };
          });

          setCompetenceSelected(temp);
          setCompetencesProgress([...competencesProgress, temp]);
          setModalLoading(false);
        } else {
          setModalVisible(false);
        }
      }
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setCompetenceSelected(null);
  };

  // const columnsEvaluationBatch = studentData.filter(e => stackType?.keyMode === 'part-time' ? e.weekNumber === 5 || e.weekNumber === 9 : e.weekNumber <= 8).map(e => {
  //     return {
  //         title: e.week,
  //         key: e.week,
  //         dataIndex: e.week,
  //         align: 'center',
  //         lineHeight: 2.5,
  //         render: data => data ? <Link to={`/batch/quizz/${data.stepId}`} className="hover-underline" style={styles.icon_wrapper}>{data.percentage}% <i className="icon-medal" color="#F6F7FB" /></Link> : <div style={{ ...styles.icon_wrapper, width: 60 }}></div>
  //     }
  // }
  // )

  const absenceColumns = [
    {
      title: t("Jour", { count: 1 }),
      dataIndex: "date",
      key: "date",
      align: "center",
      render: day => <p>{moment(day).format("L")}</p>,
    },
  ];
  if (periods?.includes("morning")) {
    absenceColumns.push({
      title: t("Matin"),
      dataIndex: "morning",
      key: "morning",
      align: "center",
      render: morning => (
        <Badge
          dot={true}
          color={
            morning === false
              ? "#F94A56"
              : morning === true
              ? "#98EDC4"
              : "#E5E5E5"
          }
        />
      ),
    });
  }
  if (periods?.includes("afternoon")) {
    absenceColumns.push({
      title: t("Après-midi"),
      dataIndex: "afternoon",
      key: "afternoon",
      align: "center",
      render: afternoon => (
        <Badge
          dot={true}
          color={
            afternoon === false
              ? "#F94A56"
              : afternoon === true
              ? "#98EDC4"
              : "#E5E5E5"
          }
        />
      ),
    });
  }
  if (periods?.includes("evening")) {
    absenceColumns.push({
      title: t("Soir"),
      key: "evening",
      dataIndex: "evening",
      align: "center",
      render: evening => (
        <Badge
          dot={true}
          color={
            evening === false
              ? "#F94A56"
              : evening === true
              ? "#98EDC4"
              : "#E5E5E5"
          }
        />
      ),
    });
  }

  const onChange = e => {
    setClickDate(moment().utc());
    setCalendarMode(e.target.value);
    setRadioMode(e.target.value);
  };

  const onChangeDateWeek = (date, dateString) => {
    setClickDate(date);
    setCalendarMode("week");
  };

  const disabledDate = current => {
    return !planning.find(e => e.isSame(current.utc().startOf("day")))
      ? true
      : false;
  };

  return (
    <Layout>
      <SimpleBar style={{ height: "100%" }}>
        {mode === "student" ? (
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Menu mode="horizontal">
              <Menu.Item disabled="true">
                <Radio.Group onChange={onChange} value={radioMode}>
                  <Radio value={"batch"}>{t("Au batch")}</Radio>
                  <Radio value={"week"}>{t("À la semaine")}</Radio>
                </Radio.Group>
                {radioMode === "batch" ? (
                  <WeekPicker
                    defaultValue={defaultCalendarValue}
                    placeholder={t("Sélectionner une semaine")}
                    disabled={true}
                  />
                ) : (
                  <WeekPicker
                    defaultValue={defaultCalendarValue}
                    style={{ zIndex: 1 }}
                    disabledDate={disabledDate}
                    onChange={onChangeDateWeek}
                    placeholder={"Sélectionner une semaine"}
                    allowClear={
                      false
                    } /* disabled={key != 'batch' ? true : false} */
                  />
                )}
              </Menu.Item>
            </Menu>
          </Row>
        ) : (
          ""
        )}
        {/* <DashboardNavMenu view={ view } setView={ setView } /> */}
        <Row
          style={{
            height: "100%",
            paddingTop: mode === "student" ? "40px" : 0,
          }}
        >
          <Col
            xs={stackType?.keyMode === "part-time" ? 8 : 6 }
            md={stackType?.keyMode === "part-time" ? 9 : 7 }
            offset={1}
            style={{
              ...dashboardstyles.contentPanel,
              ...dashboardstyles.right,
            }}
          >
            {calendarMode === "week" ? (
              <>
                <div style={styles.statBlock}>
                  <h2
                    style={{
                      ...dashboardstyles.contentTitle,
                      textAlign: "left",
                    }}
                  >
                    {t("Solutions débloquées")}
                  </h2>
                  <div style={styles.statContentBody}>
                    <div
                      style={
                        mode === "teacher"
                          ? styles.statContainerWeek
                          : styles.statContainer
                      }
                    >
                      <h3 style={styles.statText}>{t("À la semaine")}</h3>
                      {studentDataLoading ? (
                        <Spin style={{ margin: "auto " }} />
                      ) : (
                        <h4 style={styles.statCount}>
                          {weekStudentData?.weekSolutions
                            ? weekStudentData?.weekSolutions
                            : 0}
                        </h4>
                      )}
                      <h3 style={styles.statText}>
                        {t("solutions débloquées")}
                      </h3>
                      {mode === "teacher" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={styles.statTextGray}>
                            {weekStudentData?.weekSolutions -
                              averagelHelpStats[0]?.weeklyGlobalSolutions >
                            0
                              ? "+ "
                              : ""}
                            {weekStudentData?.weekSolutions
                              ? weekStudentData?.weekSolutions -
                                averagelHelpStats[0]?.weeklyGlobalSolutions
                              : 0 - averagelHelpStats[0]?.weeklyGlobalSolutions}
                          </p>
                          <h3
                            style={{ ...styles.statText, color: "lightgray" }}
                          >
                            {t("vs")} batch
                          </h3>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={
                        mode === "teacher"
                          ? styles.statContainerWeek
                          : styles.statContainer
                      }
                    >
                      <h3 style={styles.statText}>{t("Aujourd'hui")}</h3>
                      {studentDataLoading ? (
                        <Spin style={{ margin: "auto " }} />
                      ) : (
                        <h4 style={styles.statCount}>
                          {weekStudentData?.todaySolutions
                            ? weekStudentData?.todaySolutions
                            : 0}
                        </h4>
                      )}
                      <h3 style={styles.statText}>
                        {t("solutions débloquées")}
                      </h3>
                      {mode === "teacher" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={styles.statTextGray}>
                            {weekStudentData?.todaySolutions -
                              averagelHelpStats[0]?.dailyGlobalSolutions >
                            0
                              ? "+ "
                              : ""}
                            {weekStudentData?.todaySolutions
                              ? weekStudentData?.todaySolutions -
                                averagelHelpStats[0]?.dailyGlobalSolutions
                              : 0 - averagelHelpStats[0]?.dailyGlobalSolutions}
                          </p>
                          <h3
                            style={{ ...styles.statText, color: "lightgray" }}
                          >
                            {t("vs")} batch
                          </h3>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div style={styles.statBlock}>
                  <h2
                    style={{
                      ...dashboardstyles.contentTitle,
                      textAlign: "left",
                    }}
                  >
                    {t("Help requests")}
                  </h2>
                  <div style={styles.statContentBody}>
                    <div
                      style={
                        mode === "teacher"
                          ? styles.statContainerWeek
                          : styles.statContainer
                      }
                    >
                      <h3 style={styles.statText}>{t("À la semaine")}</h3>
                      {studentDataLoading ? (
                        <Spin style={{ margin: "auto " }} />
                      ) : (
                        <h4 style={styles.statCount}>
                          {weekStudentData?.weekHelpRequests
                            ? weekStudentData?.weekHelpRequests
                            : 0}
                        </h4>
                      )}
                      <h3 style={styles.statText}>{t("help requests")}</h3>
                      {mode === "teacher" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={styles.statTextGray}>
                            {weekStudentData?.weekHelpRequests -
                              averagelHelpStats[0]?.weeklyGlobalHelpRequests >
                            0
                              ? "+ "
                              : ""}
                            {weekStudentData?.weekHelpRequests
                              ? weekStudentData?.weekHelpRequests -
                                averagelHelpStats[0]?.weeklyGlobalHelpRequests
                              : 0 -
                                averagelHelpStats[0]?.weeklyGlobalHelpRequests}
                          </p>
                          <h3
                            style={{ ...styles.statText, color: "lightgray" }}
                          >
                            {t("vs")} batch
                          </h3>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={
                        mode === "teacher"
                          ? styles.statContainerWeek
                          : styles.statContainer
                      }
                    >
                      <h3 style={styles.statText}>{t("Aujourd'hui")}</h3>
                      {studentDataLoading ? (
                        <Spin style={{ margin: "auto " }} />
                      ) : (
                        <h4 style={styles.statCount}>
                          {weekStudentData?.todayHelpRequests
                            ? weekStudentData?.todayHelpRequests
                            : 0}
                        </h4>
                      )}
                      <h3 style={styles.statText}>{t("help requests")}</h3>
                      {mode === "teacher" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={styles.statTextGray}>
                            {weekStudentData?.todayHelpRequests -
                              averagelHelpStats[0]?.dailyGlobalHelpRequests >
                            0
                              ? "+ "
                              : ""}
                            {weekStudentData?.todayHelpRequests
                              ? weekStudentData?.todayHelpRequests -
                                averagelHelpStats[0]?.dailyGlobalHelpRequests
                              : 0 -
                                averagelHelpStats[0]?.dailyGlobalHelpRequests}
                          </p>
                          <h3
                            style={{ ...styles.statText, color: "lightgray" }}
                          >
                            {t("vs")} batch
                          </h3>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2 style={dashboardstyles.contentTitle}>
                  {t("Solutions débloquées")}
                </h2>
                <div
                  style={{
                    ...dashboardstyles.contentBody,
                    ...styles.chartContainer,
                    height: "30vh",
                    marginBottom: "30px",
                  }}
                >
                  {studentDataLoading ? (
                    <Spin style={{ margin: "auto " }} />
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={studentData}
                        margin={{ top: 20, right: 0, bottom: 10, left: -20 }}
                      >
                        <XAxis
                          dataKey="week"
                          tickLine={false}
                          tick={<CustomTick />}
                          interval={0}
                        />
                        <CartesianGrid vertical={false} />
                        <YAxis
                          axisLine={false}
                          allowDecimals={false}
                          tickLine={false}
                          tick={<CustomTick />}
                        />
                        <Bar dataKey="solutions" fill="#F94A56" />
                        <Tooltip />
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </div>
                <h2 style={dashboardstyles.contentTitle}>
                  {t("Help requests")}
                </h2>
                <div
                  style={{
                    ...dashboardstyles.contentBody,
                    ...styles.chartContainer,
                    height: "30vh",
                    marginBottom: "30px",
                  }}
                >
                  {studentDataLoading ? (
                    <Spin style={{ margin: "auto " }} />
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={studentData}
                        margin={{ top: 20, right: 0, bottom: 10, left: -20 }}
                      >
                        <XAxis
                          dataKey="week"
                          tickLine={false}
                          tick={<CustomTick />}
                          interval={0}
                        />
                        <CartesianGrid vertical={false} />
                        <YAxis
                          allowDecimals={false}
                          axisLine={false}
                          tickLine={false}
                          tick={<CustomTick />}
                        />
                        <Bar dataKey="requests" fill="#5247EA" />
                        <Tooltip />
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </div>
              </>
            )}

            <div style={styles.statBlock}>
              <h2
                style={
                  calendarMode === "week"
                    ? {
                        ...dashboardstyles.contentTitle,
                        textAlign: "left" /* , marginTop: "170px"  */,
                      }
                    : { ...dashboardstyles.contentTitle, textAlign: "left" }
                }
              >
                {t("Mon ressenti")}
              </h2>
              <div
                style={{
                  ...dashboardstyles.contentBody,
                  ...styles.feeling_container,
                }}
              >
                {studentDataLoading ? (
                  <Spin style={{ margin: "auto " }} />
                ) : calendarMode === "week" ? (
                  weekStudentData.feelingsTable?.map((e, i) => {
                    return e.feeling ? (
                      <div style={styles.feeling_wrapper}>
                        <FeelingEmoji feeling={e.feeling} key={i} />
                        <p style={styles.feelingText}>{e.day}</p>
                      </div>
                    ) : (
                      <div style={styles.feeling_wrapper}>
                        <div
                          key={i}
                          style={{
                            borderRadius: "50px",
                            height: 20,
                            width: 20,
                            backgroundColor: "#E5E5E5",
                          }}
                        ></div>
                        <p style={styles.feelingText}>{e.day}</p>
                      </div>
                    );
                  })
                ) : (
                  studentData.map((e, i) => {
                    return e.feeling && i < maxFeelingWeeks ? (
                      <div style={styles.feeling_wrapper}>
                        <FeelingEmoji feeling={e.feeling} key={i} />
                        <p style={styles.feelingText}>{e.week}</p>
                      </div>
                    ) : i >= maxFeelingWeeks ? (
                      ""
                    ) : (
                      <div style={styles.feeling_wrapper}>
                        <div
                          key={i}
                          style={{
                            borderRadius: "50px",
                            height: 20,
                            width: 20,
                            backgroundColor: "#E5E5E5",
                          }}
                        ></div>
                        <p style={styles.feelingText}>{e.week}</p>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </Col>
          <Col
            xs={stackType?.keyMode === "part-time" ? 14 : 11 }
            md={stackType?.keyMode === "part-time" ? 13 : 10}
            id="evaluation"
            style={{
              ...dashboardstyles.contentPanel,
              ...dashboardstyles.center,
            }}
          >
            <h2 style={dashboardstyles.contentTitle}>
              {t("Mon arbre de compétences")}
            </h2>
            <div
              style={{
                ...dashboardstyles.contentBody,
                ...styles.chartContainer,
                height: "calc(60vh + 57px)",
                padding: 0,
                marginBottom: "30px",
              }}
              className="radar-container"
            >
              {competencesLoading ? (
                <Spin style={{ margin: "auto" }} />
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <RadarChart
                    data={competenceData}
                    outerRadius={"70%"}
                    startAngle={155}
                    endAngle={155 - 360}
                  >
                    <PolarGrid gridType="polygon" />
                    <PolarAngleAxis
                      dataKey="category"
                      tick={<RadarChartLabel openModal={openModal} count={competenceData.length} />}
                    />
                    <PolarRadiusAxis angle={40} domain={[0, 100]} />
                    <Radar
                      name="Mike"
                      dataKey="A"
                      stroke="#5247ea"
                      strokeWidth={2}
                      fill="#8884d8"
                      fillOpacity={0.4}
                    />
                  </RadarChart>
                </ResponsiveContainer>
              )}
            </div>
          </Col>
          {stackType?.keyMode !== "part-time" && (
            <Col
              xs={4}
              md={5}
              style={{
                ...dashboardstyles.contentPanel,
                ...dashboardstyles.left,
              }}
            >
              <>
                <h2 style={dashboardstyles.contentTitle}>
                  {t("Mon assiduité")}
                </h2>
                {calendarMode !== "week" && (
                  <div
                    style={{
                      ...dashboardstyles.contentBody,
                      ...styles.chartContainer,
                      height: "30vh",
                      paddingLeft: "20px",
                    }}
                    className="line-chart"
                  >
                    {studentDataLoading ? (
                      <Spin style={{ margin: "auto" }} />
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          data={studentData}
                          margin={{ top: 0, right: 0, bottom: -10, left: -30 }}
                        >
                          <Line
                            type="monotone"
                            dataKey="assiduité"
                            stroke="#5247ea"
                            strokeWidth={2}
                            dot={true}
                          />
                          <XAxis
                            dataKey="week"
                            tickLine={false}
                            tick={<CustomTick />}
                            interval={0}
                            padding={{ right: 10 }}
                          />
                          <CartesianGrid vertical={false} />
                          <YAxis
                            axisLine={false}
                            tickLine={false}
                            domain={[0, 100]}
                            padding={{ top: 10 }}
                            /*ticks={[25, 50, 75, 100]} interval={ 0 }*/ tick={
                              <CustomTick />
                            }
                          />
                          <Tooltip />
                        </LineChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                )}
              </>
              <div style={styles.purpleContainer}>
                {/* {calendarMode === 'week' ? */}
                <p
                  style={{
                    ...styles.textStyle,
                    marginBottom: -10,
                    marginRight: 10,
                  }}
                >
                  <span style={{ marginRight: 6 }}>
                    {calendarMode === "week"
                      ? t("À la semaine")
                      : t("Depuis le début du batch")}
                  </span>{" "}
                  {studentDataLoading ? (
                    <Spin style={{ marginLeft: 20, marginTop: 10 }} />
                  ) : cumulativeAbsences ? (
                    <span style={styles.numberStyle2}>
                      <span style={styles.numberStyle}>
                        {cumulativeAbsences === "NR"
                          ? cumulativeAbsences
                          : `${cumulativeAbsences}%`}
                      </span>
                    </span>
                  ) : (
                    <span style={styles.numberStyle2}>
                      <span style={styles.numberStyle}>0</span>%
                    </span>
                  )}
                </p>
                {/* } */}
              </div>
              <h2 style={dashboardstyles.contentTitle}>{t("Mes absences")}</h2>
              <div
                style={{
                  ...dashboardstyles.contentBody,
                  height: "calc(30vh + 78px)",
                }}
                className="table-container"
              >
                {
                  /*calendarMode === 'week' ?

                                    absencesLoading ?
                                        <Spin style={{ margin: 'auto ' }} />
                                        : weekStudentData.absencesTable?.length === 0 ?
                                            <p style={Object.assign({ ...styles.feelingText }, { margin: 'auto', whiteSpace: 'normal', textAlign: 'center' })}>Aucune absence enregistrée pour cette période.</p>
                                            :
                                            <SimpleBar style={{ maxHeight: 'calc(30vh + 78px)' }}>
                                                <Table columns={absenceColumns} dataSource={weekStudentData.absencesTable} pagination={false} />
                                            </SimpleBar>
                                    : */
                  absencesLoading ? (
                    <Spin style={{ margin: "auto " }} />
                  ) : absences?.length === 0 ? (
                    <p
                      style={Object.assign(
                        { ...styles.feelingText },
                        {
                          margin: "auto",
                          whiteSpace: "normal",
                          textAlign: "center",
                        }
                      )}
                    >
                      {t("Aucune donnée")}
                    </p>
                  ) : (
                    <SimpleBar style={{ maxHeight: "calc(30vh + 78px)" }}>
                      <Table
                        columns={absenceColumns}
                        dataSource={absences}
                        pagination={false}
                      />
                    </SimpleBar>
                  )
                }
              </div>
            </Col>
          )}
        </Row>
      </SimpleBar>
      <CompetencesModal
        visible={modalVisible}
        loading={modalLoading}
        closeModal={closeModal}
        openModal={openModal}
        competence={competenceSelected}
      />
    </Layout>
  );
};

function mapStateToProps({ batchid, batch, stackid, stackType, language }) {
  return { batchid, batch, stackid, stackType, language };
}
export default connect(mapStateToProps, null)(StudentDashboard);

const styles = {
  statContainer: {
    width: "48%",
    height: "102px",
    backgroundColor: "#ffffff",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  statContainerWeek: {
    width: "48%",
    height: "150px",
    backgroundColor: "#ffffff",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  statCount: {
    color: "#F94A56",
    fontWeight: 600,
    fontSize: "48px",
    margin: 0,
    lineHeight: "48px",
  },
  statText: {
    color: "#010031",
    fontWeight: 600,
    fontSize: "11px",
    margin: 0,
  },
  statTextGray: {
    color: "#010031",
    fontWeight: 600,
    fontSize: "11px",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "lightgray",
    border: "2px lightgray solid",
    width: "30%",
    height: "30px",
    marginRight: "10px",
    borderRadius: "4px",
  },
  statContentBody: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  statBlock: {
    marginBottom: 35,
    width: "100%",
  },
  chartContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 20px 20px 0px",
    marginBottom: 15,
  },
  purpleIcon: {
    color: "#5247ea",
    opacity: 0.6,
  },
  pinkIcon: {
    color: "#F94A56",
    opacity: 0.6,
  },
  icon_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
  },
  table_container: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  emoji: {
    height: "24px",
    width: "24px",
  },
  feeling_wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "50px",
  },
  feelingText: {
    margin: 0,
    fontWeight: 300,
    color: "#989C8F",
    fontSize: "14px",
    fontFamily: '"Poppins", sans-serif',
    lineHeight: "14px",
  },
  feeling_container: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "5%",
    height: "95px",
  },
  purpleContainer: {
    color: "#ffffff",
    backgroundColor: "#5247ea",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: 4,
    backgroundImage: "url(/images/backgrounds/bg_quizz_3.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "54px",
    paddingRight: 8,
    marginBottom: 35,
  },
  numberStyle: {
    color: "#ffffff",
    fontSize: 34,
    fontWeight: 500,
    fontFamily: '"Poppins", sans-serif',
  },
  numberStyle2: {
    color: "#ffffff",
    fontSize: 30,
    fontWeight: 500,
    fontFamily: '"Poppins", sans-serif',
  },
  textStyle: {
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: 12,
    fontFamily: '"Poppins", sans-serif',
  },
  tagStylePurple: {
    backgroundColor: "#EBE9FE",
    color: "#5247ea",
    width: 50,
    height: 22,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 10px 0 5px",
    borderRadius: 12,
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: 0.44,
  },
  tagStylePink: {
    backgroundColor: "#FAE7E7",
    color: "#F94A56",
    width: 50,
    height: 22,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 10px 0 5px",
    borderRadius: 12,
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: 0.44,
  },
  purpleIcon: { color: "#5247ea", opacity: 0.6 },
  pinkIcon: { color: "#F94A56", opacity: 0.6 },
};
