import React, { Component } from 'react';
import { Col, Row, Icon, Input } from 'antd';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

class Demo extends Component {
  state = {
    id: '',
    url: null,
    editUrl: false,
    editable: false
  }

  edit = {}
  timeout = {}

  componentDidMount() {
    const { demo: { url } = {}, id, editable } = this.props;

    this.setState({ url, id, editable });
  }

  onEditHandle = e => {
    let type = e.replace('edit', '');
    type = type[0].toLowerCase() + type.slice(1);

    if (this.state[e] && this.state[type] !== this.edit[type]) {
      const { id } = this.state;
      const { user: { firstname, lastname, _id }, batchid, updateId, updateDemo } = this.props;

      const myself = { firstname, lastname, userId: _id };
      const dataToSend = id ? { [type]: this.state[type].trim() } : { [type]: this.state[type].trim(), myself, batchid };

      fetch(`${global.URI_BACKEND}/project/${id || 'new'}/demo/${type}`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend)
      }).then(res => res.json()).then(data => {
        if (!data?.error) {
          const { _id } = data;

          if (this.timeout[e]) clearTimeout(this.timeout[e]);
          updateId(_id);
          this.setState({ [type]: data.demo[type], [e]: !this.state[e], id: _id }, () => {
            const { url } = this.state;
            updateDemo({ url });
          });
        }
      });
    } else {
      this.edit[type] = this.state[type];
      this.timeout[e] = setTimeout(() => this.onCancelEdit(e), 30000);
      this.setState({ [e]: !this.state[e] });
    }
  }

  onCancelEdit = e => {
    let type = e.replace('edit', '');
    type = type[0].toLowerCase() + type.slice(1);

    if (this.timeout[e]) clearTimeout(this.timeout[e]);

    this.setState({ [e]: false, [type]: this.edit[type] });
  }

  onInputChange = e => {
    const { id, value } = e.target;

    this.setState({ [id]: value });
  }

  render() {
    const { card, title, editIcon, description, link, videoContainer, video, noData } = styles;
    const { url, editUrl, editable } = this.state;
    const { t } = this.props

    return <Row gutter={28}>
      <Col xs={24} xl={12}>
        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>{t("La vidéo de mon projet")}</div>
            {
              !editUrl && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editUrl')} />
                </div>
                : editUrl && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editUrl')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editUrl')} />
                </div>
            }
          </Row>
          <div style={description}>
            {
              !editUrl
                ? url
                  ? <a style={link} href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                  : <div style={noData}>{t("Lien manquant")}</div>
                : <Input id="url" placeholder={url} onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editUrl')} value={url} />
            }
            {
              url && <Row style={videoContainer} type="flex" justify="center">
                <iframe title={t("Demo")} style={video} src={`https://vimeo.com/api/oembed.json?url=${url}`} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen mozallowfullscreen webkitallowfullscreen></iframe>
              </Row>
            }
          </div>
        </div>
      </Col>
    </Row>;
  }
}

export default withTranslation()(Demo)

const styles = {
  card: { borderRadius: 9, backgroundColor: 'white', marginBottom: 22, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)' },
  title: { padding: 24, fontWeight: 'bold', fontSize: 17, borderBottom: '1px solid #f5f5f5' },
  description: { padding: 24, fontWeight: 'light', fontSize: 14, color: '#444444' },
  link: { fontSize: 14, marginBottom: 20 },
  videoContainer: { marginTop: 20, marginBottom: 10 },
  video: { width: 670, height: 377, borderRadius: 11 },
  editIcon: { color: '#000000', opacity: 0.65, fontSize: 18, cursor: 'pointer', marginLeft: 10 },
  noData: { color: '#bbb' }
}