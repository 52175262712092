import React, { useState, useEffect } from 'react'

//STYLES______________________
import './Header.css'

//MOMENT________________________
import moment from 'moment';

//NAVIGATION________________________
import { Link, useHistory } from 'react-router-dom'

//UTILITIES_________________
import checkRank from '../../utils/checkRank';

//REDUX_______________________
import { connect } from 'react-redux'

//UI______________________
import { Drawer, Dropdown, Menu, Icon, Badge, Spin } from 'antd'

//COMPONENTS_________________
import Nav from './Nav';

//SCROLLBAR_________________________
import SimpleBar from 'simplebar-react';

//UTILS_____________________________
import sortBatches from '../../utils/sortBatches';

//TRANSLATION________________________
import { useTranslation } from 'react-i18next';

const { Item } = Menu

const SideMenu = ({ user, batch, onClose, visible = false, changeBatch, navStatus, onItemClick, toggleMenu }) => {
    //TRANSLATION________________________
    const { t } = useTranslation();
   
    //STATE HOOKS______________________
    const [currentBatch, setCurrentBatch] = useState(batch.name)
    const [batches, setBatches] = useState([])
    const [batchesLoaded, setBatchesLoaded] = useState(!["superadmin", "manager", "admin"].includes(user.type) && user?.batchList.length <=1)
    const [careerCenter, setCareerCenter] = useState(false)

    //NAVIGATION______________________
    const history = useHistory()

    useEffect(() => {
        fetch(`${global.URI_BACKEND}/batch/career-center/${user._id}` ).then(data => data.json()).then(response => {
            if (response.success) {
                setCareerCenter(response.careerCenter)
            }
        });
    }, [user._id])
    
    //FUNCTIONS_____________________
    const handleChange = async (batchId) => {
        if (batchId === batch._id) {
            history.push("/home/today")
            return
        }
        
        const rawResponse = await fetch(`${global.URI_BACKEND}/batch/findBatch/${batchId}/${user._id}` );
        const response = await rawResponse.json();

        if(response.result){
            changeBatch(response);
            setCurrentBatch(response.batch.batchType)
            localStorage.setItem("batchIdSelected", response.batch._id);
            window.location.href = "/home/today";
        }  
    }

    const changeMenu = menu => {
        onItemClick('1')
        toggleMenu(menu)
    }

    const loadBatches = () => {
        if (!batchesLoaded) {
            fetch(global.URI_BACKEND + '/login/getBatchDates', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user: user })
            })
            .then(res => res.json())
            .then(async ({ batches }) => {
                if (batches) {
                    setBatches(batches.sort(sortBatches))
                    setBatchesLoaded(true)
                }
            })
        }
    }

    //DISPLAY_____________________
    const batchMenu = 
    <SimpleBar style={ { maxHeight: '400px', backgroundColor: 'white', borderRadius: '3px', paddingTop: 20, paddingBottom: 20 } }>
        <Menu style={ styles.menu }>
            { 
                !batches.length ?
                <Item style={ styles.menuItem } key="loading">
                    <Spin style={ { margin: 'auto' } } size="small"/>
                </Item>
                :
                batches.map((e, i) => 
                <Item style={ styles.menuItem } key={ i } onClick={ ()=>handleChange(e._id) }>
                    { moment().isBetween(moment(e.date.start),moment(e.date.end)) && <Badge status="success" style={ { position: 'absolute', left: 25 } }/> }
                    { e.batchType }
                </Item>
            ) }
        </Menu>
    </SimpleBar>

    return (
        <Drawer 
        visible={ visible } 
        placement="left" 
        closable={ false } 
        style={ styles.drawer } 
        onClose={ onClose }
        bodyStyle={ styles.body }>
            <Nav mode="vertical"/>
            <Menu>
                { 
                    checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin'] }) || user?.batchList.length > 1 ?
                    <Item style={ { borderBottom: '0.5px solid #F0F2F5', position: 'relative' } }>
                        <i className="icon-computer" style={ { ...styles.grey, ...styles.picto, fontSize: '13px' } } />
                        <Dropdown 
                        overlay={ batchMenu } 
                        placement="bottomLeft" 
                        overlayStyle={ styles.overlay } 
                        overlayClassName="hide-scrollbar"
                        onMouseEnter={ loadBatches }>
                            <div style={ styles.item }>
                                {t("Mon batch")}
                                <Icon type="down" style={ styles.menuIcon }/>
                            </div>
                        </Dropdown>    
                    </Item>
                    :
                    <Item style={ styles.item } onClick={ () => changeMenu('batch') }>
                        <Link to={ `/home/today` }>
                            <i className="icon-computer" style={ { ...styles.grey, ...styles.picto, fontSize: '13px' } } />
                            {t("Mon batch")}
                            {
                                !batchesLoaded &&
                                <Spin style={ { position: 'absolute', right: '10px', top: '30%' } } size="small"/>
                            }
                        </Link>
                    </Item>
                }
                {
                    user?.type !== 'workshop' && careerCenter &&
                    <Item style={ styles.item } onClick={ () => changeMenu('post-program') }>
                        <Link to="/studentList/alumni">
                            <i className="icon-graduation" style={ { ...styles.grey, ...styles.picto } } />
                            Career Center
                        </Link>
                    </Item>
                }
                {/* 
                <Item>
                    <Dropdown>
                        <div style={ styles.item }>
                            <i className="icon-location" style={ { ...styles.grey, ...styles.picto, fontSize: '13px' } } />
                            Mon Campus
                            { user?.batchList.length > 1 && <Icon type="down" style={ styles.menuIcon }/> }
                        </div>
                    </Dropdown>
                </Item>
                */}
                {
                    (user.isFranchised || user.type === "superadmin") &&
                    <Item style={ styles.item } onClick={ ()=>changeMenu('campus') }>
                        <Link to="/franchised">
                            <i className="icon-handshake" style={ { ...styles.grey, ...styles.picto, fontSize: '13px' } }/>
                            Campus Manager
                        </Link>
                    </Item>
                }
            </Menu>
        </Drawer>
    )
}

function mapStateToProps({ user, batch, menu }) {
    return { user, batch, menu }
}

function mapDispatchToProps(dispatch) {
    return {
        changeBatch: function (data) {
            dispatch({ type: 'tracks', tracks: data.stack.tracks })
            dispatch({ type: 'postprogram', postprogram: data.stack.postprogram })
            dispatch({ type: 'batch', batch: data.batch })
            dispatch({ type: 'stackid', id: data.stack._id })
            dispatch({ type: 'batchid', id: data.batch._id })
            dispatch({ type: 'batchuserlist', batchuserlist: data.batchUserList })
            dispatch({ type: 'progression', progression: data.batch.enabledStep })
        },
        onItemClick: function (key) {
            dispatch({ type: 'nav', key });
        },
        toggleMenu: menu => {
            dispatch({ type: 'toggleMenu', menu })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

const styles = {
    drawer: {
        marginTop: '45px'
    },
    body: {
        padding: 0
    },
    grey: {
        color: '#898C8F',
    },
    picto: {
        position: 'absolute',
        left: 20,
        fontSize: '16px'
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#898C8F',
        textTransform: 'uppercase',
        width: '100%',
        height: '47px',
        cursor: 'pointer',
        position: 'relative',
        fontWeight: 600,
        fontFamily: '"Poppins", sans-serif',
        borderBottom: '0.5px solid #F0F2F5',
    },
    subItem: {
        fontWeight: 300,
        fontSize: '14px',
        color: '#898C8F',
        fontFamily: '"Poppins", sans-serif'
    },
    menuIcon: {
        color: '#898C8F',
        position: 'absolute',
        right: -5
    },
    menu: {
        borderRadius: '3px',
        padding: 0,
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#898C8F',
        borderBottom: '0.5px solid #F0F2F5',
        height: '42px',
        position: 'relative'
    },
    overlay: {
        // maxHeight: '400px',
        // overflowY: 'scroll',
        // overflowX: 'visible',
        minWidth: '250px',
        padding: '0px 5px 5px 5px',
        borderRadius: '3px'
    }
}