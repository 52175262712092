import React, { useState, useEffect, useRef } from "react";

//STYLES__________________________
import "./classmates.css";

//REDUX______________________________
import { connect } from "react-redux";

//COMPONENTS________________________________
import Header from "../Header/Header";
import ClassmateCard from "./ClassmateCard";
import Filters from "./Filters";

//UI______________________________
import { Layout } from "antd";

//NAVIGATION_________________________
import { useParams } from "react-router-dom";

//TRANSLATION________________________
import { useTranslation } from "react-i18next";

function Classmates({ user, batch, batchUserList, batchid }) {
  //PARAMS_________________________________
  const { mode } = useParams();

  //TRANSLATION________________________
  const { t } = useTranslation();

  //STATE HOOKS_____________________
  const [currentTab, setCurrentTab] = useState(
    mode === "classmates" ? "classmates" : "alumni"
  );

  const [classmates, setClassmates] = useState([]);
  const [team, setTeam] = useState([]);

  const [alumni, setAlumni] = useState([]);
  const [filters, setFilters] = useState({ cities: [], specialities: [] });
  const [useFilters, setUseFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  //REFERENCE HOOKS_______________________
  const container = useRef(null);

  //EFFECT HOOKS_________________________
  //load user data
  useEffect(() => {
    if (mode === "classmates" && batchid && batchUserList) {
      setClassmates(
        batchUserList.filter(
          e =>
            e.batchList.find(f => f.batchId === batchid) &&
            e.batchList.find(f => f.batchId === batchid).userType === "student"
        )
      );
      setTeam(
        batchUserList.filter(
          e =>
            e.batchList.find(f => f.batchId === batchid) &&
            e.batchList.find(f => f.batchId === batchid).userType !== "student"
        )
      );
    } else if (mode === "alumni") {
      loadFilters();
      loadAlumniData(0);
    }
  }, [batch, batchUserList, batchid, mode]);

  useEffect(() => {
    if (mode === "classmates") setCurrentTab("classmates");
    else if (mode === "alumni") setCurrentTab("alumni");
  }, [mode]);

  //return to top on tab change
  useEffect(() => {
    if (container) {
      container.current.scrollTo({ top: 0 });
    }
  }, [currentTab]);

  //FUNCTIONS_____________________
  const toggleTabs = bool => {
    return bool
      ? Object.assign({ ...styles.tab }, { ...styles.active_tab })
      : styles.tab;
  };

  //handle infinite scroll on alumni view
  const handleScroll = () => {
    if (container.current) {
      const { scrollTop, scrollHeight, clientHeight } = container.current;
      if (
        scrollTop + clientHeight + 0.5 === scrollHeight ||
        scrollTop + clientHeight === scrollHeight
      ) {
        // POSSIBLE FUTURE ISSUES
        const nextPage = page + 1;
        setPage(nextPage);
        if (useFilters) {
          loadFilteredAlumniData(selectedFilters, page);
        } else {
          loadAlumniData(nextPage);
        }
      }
    }
  };

  //load alumni data without filters
  const loadAlumniData = async (page = 0) => {
    if (alumni.length <= page * 16) {
      const data = await fetch(
        `${global.URI_BACKEND}/alumnis?page=${page ? page : 0}`
      );
      const json = await data.json();

      if (!json?.error) {
        setAlumni([...alumni, ...json.alumnis]);
        setHasMore(json.hasMore);
      }
    }
  };

  //load filtered alumni data
  const loadFilteredAlumniData = async (obj, page) => {
    const data = await fetch(`${global.URI_BACKEND}/alumnis/filters`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ filters: obj, filteredPage: page }),
    });
    const json = await data.json();

    if (!json?.error) {
      page === 0
        ? setAlumni(json.alumnis)
        : setAlumni([...alumni, ...json.alumnis]);
      setHasMore(json.hasMore);
    }
  };

  //get dynamic filters on page load
  const loadFilters = async () => {
    const data = await fetch(`${global.URI_BACKEND}/alumnis/filters`);
    const json = await data.json();

    if (!json?.error) {
      setFilters({ cities: json.cities, specialities: json.specialities });
    }
  };

  //switch to filtered view or change filters
  const applyFilters = async obj => {
    setUseFilters(true);
    setSelectedFilters(obj);
    setPage(0);

    loadFilteredAlumniData(obj, 0);
  };

  return (
    <Layout style={styles.page_container}>
      <Header enableButtonAndSearch />
      <Layout.Content>
        <div style={styles.menu}>
          {mode === "classmates" && (
            <>
              <div
                className="tab"
                style={toggleTabs(currentTab === "classmates")}
                onClick={() => setCurrentTab("classmates")}
              >
                {user.type === "student" ? t("Classmates") : t("Élèves")}
              </div>
              <div
                className="tab"
                style={toggleTabs(currentTab === "team")}
                onClick={() => setCurrentTab("team")}
              >
                {t("Équipe")}
              </div>
            </>
          )}
          {mode === "alumni" && <h2 style={styles.title}>{t("Alumni")}</h2>}
        </div>
        <div
          className="cards-container hide-scrollbar"
          ref={container}
          onScroll={handleScroll}
        >
          {currentTab === "classmates" &&
            classmates &&
            classmates.map((e, i) => (
              <ClassmateCard
                person={e}
                key={i}
                batch={batch}
                batchId={batchid}
                isStudent
              />
            ))}
          {currentTab === "team" &&
            team &&
            team.map((e, i) => (
              <ClassmateCard
                person={e}
                key={i}
                batch={batch}
                batchId={batchid}
              />
            ))}
          {currentTab === "alumni" &&
            alumni &&
            alumni.map((e, i) => (
              <ClassmateCard person={e} key={i} batch={batch} isAlumni />
            ))}
        </div>
        {/* { mode === 'alumni' && <Filters cities={ filters.cities } specialities={ filters.specialities } page={ page } onApplyFilters={ applyFilters }/> } */}
      </Layout.Content>
    </Layout>
  );
}

function mapStateToProps({ user, batch, batchid, batchuserlist }) {
  return {
    user,
    batch,
    batchid,
    batchUserList: batchuserlist,
  };
}

export default connect(mapStateToProps, null)(Classmates);

const styles = {
  page_container: {
    maxHeight: "100vh",
    justifyContent: "center",
    backgroundColor: "#F6F7FB",
  },
  menu: {
    height: "48px",
    width: "100%",
    backgroundColor: "#ffffff",
    paddingTop: "5.3px",
    display: "flex",
    alignItems: "center",
  },
  tab: {
    height: "100%",
    padding: "0px 20px",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#C4C4C4",
    borderBottom: "3px solid #C4C4C4",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: 600,
    fontFamily: '"Poppins", sans-serif',
    display: "flex",
    alignItems: "center",
  },
  active_tab: {
    color: "#010031",
    borderColor: "#F94A56",
  },
  cards_wrapper: {
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    overflowY: "scroll",
    boxSizing: "border-box",
  },
  title: {
    position: "absolute",
    left: 20,
    color: "rgba(137, 140, 143, 0.9)",
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "uppercase",
    lineHeight: "27px",
  },
};
