import { identifier, literal, property } from './statements_and_declarations';
import { arrayExpression, callExpression, memberExpression, objectExpression } from './expressions_and_patterns';

function variableDeclarator(tc_dec = {}, pb_dec = {}) {
  if (tc_dec.type === pb_dec.type && pb_dec.type) {
    switch (pb_dec.type) {
      /* Statements and Declarations */
      case 'Identifier':
        return identifier(tc_dec, pb_dec);

      case 'Literal':
        return literal(tc_dec, pb_dec);

      case 'Property':
        return property(tc_dec, pb_dec);

      /* Expressions and Patterns */
      case 'ArrayExpression':
        return arrayExpression(tc_dec, pb_dec);

      case 'CallExpression':
        return callExpression(tc_dec, pb_dec);

      case 'MemberExpression':
        return memberExpression(tc_dec, pb_dec);

      case 'ObjectExpression':
        return objectExpression(tc_dec, pb_dec);

      default:
        console.log(`DEBUG: variabledeclarator > Unknown type: ${pb_dec.type}.`);
        return false;
    }
  }

  return false;
}

export default variableDeclarator;