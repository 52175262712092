import React, { Component } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Icon,
  Menu,
  Dropdown,
  Badge,
} from "antd";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import logo from "../Login/Lacapsulerose.png";

const { Item } = Form;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class WorkshopSignup extends Component {
  state = {
    workshopNotStarted: false,
    logError: false,
    endpoint: global.URI_BACKEND,
    loading: false,
    batchList: [],
    batch: "Choose Workshop",
    batchIdSelected: null,
  };

  handleBatchList = e => {
    const { batchList } = this.state;

    this.setState({
      batch: e.item.props.children,
      batchIdSelected: batchList[e.key].batchId || batchList[e.key]._id,
    });
  };

  componentDidMount() {
    const {
      match: {
        params: { campus },
      },
    } = this.props;

    this.setState({ loading: true }, () => {
      this.logout();
      fetch(`${global.URI_BACKEND}/batch/workshops/${campus || ""}`)
        .then(res => res.json())
        .then(async data => this.setState({ batchList: data }));

      // To disabled submit button at the beginning.
      this.props.form.validateFields();
      this.setState({ loading: false });
    });
  }

  logout = () => {
    const { offline, clearDatas, user } = this.props;
    global.SOCKET.emit("logout");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("batchIdSelected");
    offline(user.userId);
    clearDatas();
  };

  newUserSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true, logError: false }, () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const { firstname, lastname, email, phone } = values;
          const body = { firstname, lastname, email, phone };

          body.batchId = this.state.batchIdSelected;
          body.type = "workshop";

          fetch(global.URI_BACKEND + "/user/v2", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          })
            .then(res => res.json())
            .then(async data => {
              if (data.success) {
                const body = {
                  email: data.user.email,
                  password: data.user.password,
                  batchIdSelected: this.state.batchIdSelected,
                };

                fetch(global.URI_BACKEND + "/login", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(body),
                })
                  .then(res => res.json())
                  .then(async data => {
                    if (data.success) {
                      const { user, batches, stack } = data;

                      localStorage.setItem("email", body.email);
                      localStorage.setItem("password", body.password);
                      localStorage.setItem("batchIdSelected", batches[0]._id);

                      if (data.message === "batch has not started yet") {
                        this.setState({ workshopNotStarted: true });
                      }
                      if (global.SOCKET)
                        global.SOCKET.emit("online", {
                          userId: user._id,
                          batchId: batches[0]._id,
                        });

                      data.batches[0].stackType = stack.keyMode;

                      this.props.importDatas(data);
                    } else {
                      this.setState({ logError: true });
                    }
                  });
              }
            });
        }
      });
    });
  };

  render() {
    const {
      loginForm,
      title,
      formContent,
      input,
      mb15,
      mb27,
      login,
      button,
      spinLoad,
      container,
      height,
      rowHeight,
      link,
    } = styles;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;
    const { user, menuSelected, firstStep } = this.props;
    const { logError, loading, batchIdSelected } = this.state;
    // Only show error after a field is touched.
    const userNameError = isFieldTouched("email") && getFieldError("email");
    const firstNameError =
      isFieldTouched("firstname") && getFieldError("firstname");
    const lastNameError =
      isFieldTouched("lastname") && getFieldError("lastname");
    const phoneError = isFieldTouched("phone") && getFieldError("phone");

    const batchesList = this.state.batchList
      ? this.state.batchList.map((batch, i) => {
          let name = (
            <span style={{ marginLeft: 17, position: "relative" }}>
              {batch.name}
            </span>
          );

          if (
            moment
              .utc()
              .isBetween(
                moment.utc(batch.date?.start),
                moment.utc(batch.date?.end)
              )
          ) {
            name = (
              <span style={{ marginLeft: 17, position: "relative" }}>
                <Badge
                  status="success"
                  style={{ position: "absolute", left: -16 }}
                />
                {batch.name}
              </span>
            );
          }

          return <Menu.Item key={i}>{name}</Menu.Item>;
        })
      : null;

    const batchList = <Menu onClick={this.handleBatchList}>{batchesList}</Menu>;

    return !loading && batchesList.length === 0 ? (
      <div style={{ height: "100vh" }}>
        <Col style={container}>
          <Row align="middle" justify="center" type="flex" style={rowHeight}>
            <a href="/">
              <img className="logo" src={logo} style={height} />
            </a>
          </Row>
        </Col>
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ height: "100%" }}
        >
          <Row align="middle" justify="center">
            <Row style={{ ...title, fontSize: 23 }}>
              Aucun workshop disponible pour le moment.
            </Row>
          </Row>
        </Row>
      </div>
    ) : (
      <div>
        <Col style={container}>
          <Row align="middle" justify="center" type="flex" style={rowHeight}>
            <a href="/">
              <img className="logo" src={logo} style={height} alt="logo"/>
            </a>
          </Row>
        </Col>

        <Row type="flex" align="middle" justify="center" style={loginForm}>
          {this.state.workshopNotStarted ? (
            <Redirect to={`/workshops/waiting`} />
          ) : user && user._id ? (
            <Redirect to={`/home/${menuSelected}`} />
          ) : null}
          <Row align="middle" justify="center">
            <Row style={title}>Welcome !</Row>

            <Form layout="inline" onSubmit={this.newUserSubmit}>
              <Row
                type="flex"
                align="middle"
                justify="center"
                style={formContent}
              >
                <>
                  <Col span={24} style={mb15}>
                    <Item
                      name="firstname"
                      validateStatus={firstNameError ? "error" : ""}
                      help={firstNameError || ""}
                    >
                      {getFieldDecorator("firstname", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter your firstname.",
                          },
                        ],
                      })(<Input style={input} placeholder="Firstname" />)}
                    </Item>
                  </Col>

                  <Col span={24} style={mb15}>
                    <Item
                      name="lastname"
                      validateStatus={lastNameError ? "error" : ""}
                      help={lastNameError || ""}
                    >
                      {getFieldDecorator("lastname", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter your lastname.",
                          },
                        ],
                      })(<Input style={input} placeholder="Lastname" />)}
                    </Item>
                  </Col>

                  <Col span={24} style={mb15}>
                    <Item
                      name="email"
                      validateStatus={userNameError ? "error" : ""}
                      help={userNameError || ""}
                    >
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter your email address.",
                          },
                        ],
                      })(<Input style={input} placeholder="Email address" />)}
                    </Item>
                  </Col>

                  <Col span={24} style={mb27}>
                    <Item
                      name="phone"
                      validateStatus={phoneError ? "error" : ""}
                      help={phoneError || ""}
                    >
                      {getFieldDecorator("phone", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter your phone number.",
                          },
                        ],
                      })(<Input style={input} placeholder="Phone Number" />)}
                    </Item>
                  </Col>
                </>
                <Row>
                  <div style={{ marginBottom: 5 }}>
                    <Dropdown
                      overlay={batchList}
                      overlayStyle={{ maxHeight: 300, overflow: "auto" }}
                      style={{ minWidth: 300 }}
                    >
                      <Button style={{ minWidth: 300 }}>
                        {this.state.batch}
                        <Icon type="down" />
                      </Button>
                    </Dropdown>
                  </div>
                </Row>

                <Col span={24} style={link}>
                  <Link to={`/login/${batchIdSelected && batchIdSelected}`}>
                    If you already have and account select a workshop and click
                    here to Log in.
                  </Link>
                </Col>

                <Item style={login}>
                  {!logError && this.state.loading ? (
                    <Icon type="loading" style={spinLoad} spin />
                  ) : null}
                  {logError && (
                    <div style={{ color: "red" }}>
                      Error, please enter valid information.
                    </div>
                  )}
                  <Row>
                    <Button
                      style={button}
                      type="primary"
                      size="large"
                      htmlType="submit"
                      disabled={
                        hasErrors(getFieldsError()) ||
                        this.state.batch === "Choose Workshop"
                      }
                    >
                      Sign up
                    </Button>
                  </Row>
                </Item>
              </Row>
            </Form>
          </Row>
        </Row>
      </div>
    );
  }
}

const WrappedWorkshopSignup = Form.create({ name: "workshop_login" })(
  WorkshopSignup
);

function mapStateToProps({
  stackid,
  user,
  batchid,
  batch,
  menuSelected,
  firstStep,
}) {
  return { stackId: stackid, user, batchid, batch, menuSelected, firstStep };
}

function mapDispatchToProps(dispatch) {
  return {
    online: function (status, userId) {
      dispatch({ type: "online", status, userId });
    },
    offline: function (userId) {
      dispatch({ type: "online", status: false, userId });
    },
    importDatas: function (data) {
      dispatch({ type: "tracks", tracks: data.stack.tracks });
      dispatch({ type: "postprogram", postprogram: data.stack.postprogram });
      dispatch({ type: "batch", batch: data.batches[0] });
      dispatch({ type: "stackid", id: data.stack._id });
      dispatch({ type: "batchid", id: data.batches[0]._id });
      dispatch({
        type: "progression",
        progression: data.batches[0].enabledStep,
      });
      dispatch({
        type: "stackType",
        stackType: {
          keyMode: data.stack.keyMode,
          keyStack: data.stack.keyStack,
        },
      });
      // Warning: the user dispatch must be the last dispatch
      dispatch({ type: "user", user: data.user });
    },
    importUser: function (user) {
      dispatch({ type: "user", user });
    },
    clearDatas: function () {
      dispatch({ type: "tracks", tracks: null });
      dispatch({ type: "batch", batch: null });
      dispatch({ type: "stackid", id: null });
      dispatch({ type: "batchid", id: null });
      dispatch({ type: "batchuserlist", batchuserlist: [] });
      dispatch({ type: "buddies", buddies: null });
      dispatch({ type: "progression", progression: null });
      dispatch({ type: "stackType", stackType: {} });
      // Warning: the user dispatch must be the last dispatch
      dispatch({ type: "user", user: null });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedWorkshopSignup);

const styles = {
  loginForm: { height: "100vh", backgroundColor: "#fafafa" },
  title: {
    fontSize: 33,
    fontFamily: "roboto",
    fontWeight: "bold",
    color: "#272727",
    textAlign: "center",
    marginBottom: 37,
  },
  formContent: { width: 450 },
  input: { width: 450, height: 50 },
  mb15: { marginBottom: 15, textAlign: "center" },
  mb27: { marginBottom: 27, textAlign: "center" },
  login: { textAlign: "center", marginBottom: 20 },
  button: {
    width: 100,
    fontSize: 13,
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
  forgot: { color: "#999999", fontSize: 12, fontWeight: "light" },
  spinLoad: {
    fontSize: 50,
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
    color: "#fff",
  },
  gitHubButton: {
    backgroundColor: "black",
    borderColor: "black",
    paddingTop: 5,
    paddingBottom: 39,
    fontWeight: "bold",
  },
  gitHubImg: {
    marginLeft: "-5px",
    marginRight: 10,
    width: 35,
    borderRadius: "50%",
  },
  container: { width: 200, position: "absolute" },
  rowHeight: { height: 64 },
  height: { height: 32, marginLeft: "30px" },
  link: { marginTop: 27, marginBottom: 27, textAlign: "center" },
};
