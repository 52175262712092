//MOMENT____________________________
import moment from "moment-timezone";

export const getCurrentTime = async (currentDay, batchId, remote = false, stackId = null) => {
  let todayDate;
  let timestamp;
  let timezone;
  let duringBatch;

  await fetch(`${global.URI_BACKEND}/batch/currenttime`)
    .then(res => res.json())
    .then(data => {
      todayDate = moment.tz(data.timestamp, data.timezone);
      timestamp = data.timestamp;
      timezone = data.timezone;
    });

  if (currentDay && todayDate) {
    const periods = ["morning", "afternoon", "evening" /* 'officeHours' */]; // Unlock officeHours when feature ready

    for (let time of periods) {
      if (!currentDay[time]) continue;

      let start;
      let end;

      if (stackId === "63513b5c197ad46873055b3f") {
        start = moment(currentDay[time]?.start).tz(timezone);
        end = moment(currentDay[time]?.end).tz(timezone);
      } else if (remote) {
        start = moment(currentDay[time]?.start)
          .tz(timezone)
          .subtract(20, "minutes");
        end = moment(currentDay[time]?.start).tz(timezone).add(10, "minutes");
      } else {
        const offset = time === "morning" ? 60 : 30;

        start = moment(currentDay[time]?.start)
          .tz(timezone)
          .subtract(offset, "minutes");
        end = moment(currentDay[time]?.end).tz(timezone);
      }

      const now = moment(timestamp).tz(timezone);

      if (start && end && now.isSameOrAfter(start) && now.isSameOrBefore(end)) {
        duringBatch = time;
        break;
      }
    }
  }

  return { duringBatch, timestamp, timezone };
};
