import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import Header from './Header/Header';
import Menu from './Header/Menu';
import SiderMenu2 from './Batch/SiderMenu/SiderMenu2';
import Container from './Batch/Container';

class Dashboard extends Component {
  state = {
    selectedKeys: []
  }

  selectKeys = selectedKeys => {
    this.setState({ selectedKeys });
  }

  render() {
    const { layout } = styles;
    // const { selectedKeys } = this.state;
    const { user: { _id } } = this.props;

    if (!_id) return <Redirect to="/" />;

    return (
      <>
        <Layout style={layout}>
          <Header />
          <Layout>
            <SiderMenu2 />
            <Layout.Content>
              <Container postprogram={true} />
            </Layout.Content>
          </Layout>
        </Layout>
      </>
    )
  }
}

function mapDispatchToProps({ user }) {
  return { user };
}

export default withRouter(connect(mapDispatchToProps)(Dashboard));

const styles = {
  layout: { height: '100%', fontFamily: 'roboto', width: '100vw' }
};
