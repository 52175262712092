import React, { Component } from 'react';
import { Col, Row, Button, Modal } from 'antd';
import NewStudentModal from './ModalFooter/NewStudentModal';

export default class ModalFooter extends Component {
  state = {
    visible: false,
    selected: []
  }

  handleSubmit = () => {
    const { sendData } = this.props;

    sendData();
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  closeModal = () => {
    this.setState({ visible: false });
  }

  onOk = () => {
    const { selected } = this.state;
    const { projectId, updateTeam } = this.props;

    fetch(`${global.URI_BACKEND}/project/admin/${projectId}/assign`, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ students: selected.map(e => ({ ...e, selected: true })) })
    }).then(res => res.json()).then(data => {
      if (!data?.error) {
        updateTeam(data.team);
        const { updateProject } = this.props;

        updateProject(data);
      }
    });
  }

  selectUsers = users => {
    this.setState({ selected: users });
  }

  render() {
    const { button } = styles;
    const { batchid, projectId, updateTeam, updateProject } = this.props;
    const { visible } = this.state;

    return <Row type="flex" justify="center" gutter={[13, 10]}>
      <Col flex="194px">
        <Button style={button} type="primary" ghost onClick={this.showModal}>Ajouter un élève</Button>
      </Col>
      <Modal title={'Add a new Student'} bodyStyle={{ padding: 0 }} width={441} visible={visible} onCancel={this.closeModal} onOk={this.onOk} destroyOnClose centered>
        <NewStudentModal projectId={projectId} batchid={batchid} updateTeam={updateTeam} updateProject={updateProject} selectUsers={this.selectUsers} />
      </Modal>
      <Col flex="194px">
        <Button style={button} type="primary" onClick={this.handleSubmit}>Valider cette équipe</Button>
      </Col>
    </Row>
  }
}

const styles = {
  button: { width: 194, height: 50, fontSize: 15, fontWeight: 500 }
}