import React, { Component } from 'react';
import { Layout, Col, Row, Avatar, Icon, Divider, Input, Button, Select, Tag, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import Header from './Header/Header';
import Experiences from './Profile/Experiences';
import Recruitment from './Profile/Recruitment';
import UserProfile from './Profile/UserProfile';
import ImportFromLinkedIn from './Profile/ImportFromLinkedIn';
import moment from 'moment';
import frenchDate from './static-data/french-date';
import Webcam from '../components/webcam';
import checkRank from '../utils/checkRank';
import socialnetwork from '../images/socialnetwork.svg';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';


const { TextArea } = Input;
const { Option } = Select;

class Profile extends Component {
  state = {
    _id: '',
    firstname: '',
    lastname: '',
    online: null,
    batchList: [],
    email: '',
    type: '',
    phone: '',
    city: '',
    career: {
      job: '',
      isFreelance: false,
      isContractor: false,
      isRecruiter: false,
      inOffice: false,
      lookingForJob: false,
      lookingForAssociate: false,
      lookingForFreelance: false
    },
    specs: [],
    description: '',
    experiences: [],
    linkedin: '',
    instagram: '',
    twitter: '',
    github: '',
    personal: '',
    announcement: {},
    editUser: false,
    editSocialNetworks: false,
    editExperiences: false,
    editAnnouncement: false,
    componentIsMounted: false,
    avatarVisible: false,
    hover: false,
    liScrapLoading: false,
  }

  componentDidMount() {
    const { match: { params: { id } }, user: { _id } } = this.props;
    fetch(`${global.URI_BACKEND}/user/profile/${id || _id}`).then(res => res.json()).then(data => {
      if (data.error) window.location.href = `/profile`;
      const { profile: { _id = '', firstname = '', lastname = '', online = false, batchList = [], email = '', type = '', phone = '', city = '', career = { job: '', isFreelance: false, isContractor: false, isRecruiter: false, inOffice: false, lookingForJob: false, lookingForAssociate: false, lookingForFreelance: false, isProductOwner: false, isDeveloper: false }, specs, description = '', experiences = [], socials: { linkedin = '', instagram = '', twitter = '', github = '', personal = '' } = {}, announcement }, batch: { name: batchName, date: { start = '', end = '' } = {} } } = data;
      this.setState({forcedUser: data.profile})

      this.setState({ _id, firstname, lastname, online, batchList, email, type, phone, city, career, specs, description, experiences, linkedin, instagram, twitter, github, personal, announcement, batchName, start, end, componentIsMounted: true });
    });
  }

  

  onEditUser = () => {
    const { editUser, _id } = this.state;

    if (editUser) {
      const { career, city, phone, description, specs } = this.state;

      const dataToSend = { career, city, phone, description, specs };

      fetch(`${global.URI_BACKEND}/user/profile/${_id}/me`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend)
      }).then(res => res.json()).then(data => {
        const { github, instagram, twitter, personal, experiences } = this.state;

        if (github || instagram || twitter || personal) {
          this.setState({ editUser: false, ...data });
        } else {
          this.setState({ editUser: false, editSocialNetworks: false, ...data });
        }
        if (experiences.length === 0) {
          this.setState({ editExperiences: false });
        }
      });
    } else {
      const { github, instagram, twitter, personal, experiences } = this.state;

      if (github || instagram || twitter || personal) {
        this.setState({ editUser: true });
      } else {
        this.setState({ editUser: true, editSocialNetworks: false });
      }
      if (experiences.length === 0) {
        this.setState({ editExperiences: true });
      }
    }
  }

  onEditSocialNetworks = () => {
    const { editSocialNetworks, _id } = this.state;

    if (editSocialNetworks) {
      const { linkedin, github, instagram, twitter, personal } = this.state;

      const dataToSend = { linkedin, github, instagram, twitter, personal };

      fetch(`${global.URI_BACKEND}/user/profile/${_id}/socials`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend)
      }).then(res => res.json()).then(data => {
        const { socials } = data;

        this.setState({ editSocialNetworks: false, ...socials });
      });
    } else {
      this.setState({ editSocialNetworks: true });
    }
  }

  onEditExperiences = () => {
    const { editExperiences } = this.state;

    this.setState({ editExperiences: !editExperiences });
  }

  onEditAnnouncement = () => {
    const { editAnnouncement } = this.state;

    this.setState({ editAnnouncement: !editAnnouncement });
  }

  onJobInputChange = e => {
    const { value } = e.target;
    const { career } = this.state;

    career.job = value;

    this.setState({ career });
  }

  onInputChange = e => {
    const { id, value } = e.target;

    this.setState({ [id]: value });
  }

  onJobSelect = e => {
    const { id } = e.target;
    const { career } = this.state;

    career[id] = !career[id];

    this.setState({ career });
  }

  handleSpecChange = specs => {
    this.setState({ specs });
  }

  setStateInParent = (newState, nested = false) => {
    if (nested) {
      this.setState(prevState => {
        let states = {};

        for (const key in newState) {
          states = Object.assign({}, prevState);
          for (const subKey in newState[key]) {
            states[key][subKey] = newState[key][subKey];
          }
        }

        return states;
      });
    } else {
      this.setState(newState);
    }
  }

  triggerSocialsUpdate = async () => {
    this.setState({ editSocialNetworks: true }, () => {
      this.onEditSocialNetworks();
    });
  }

  handleAvatarClick = () => {
    this.setState({ avatarVisible: true });
  }

  closeAvatarModal = () => {
    this.setState({ avatarVisible: false });
  }

  avatarMouseEnter = () => {
    this.setState({ hover: true });
  }

  avatarMouseLeave = () => {
    this.setState({ hover: false });
  }
    

  render() {
    const { layout, content, box, box2, avatarBox, avatar, desc, badge, badgeOnline, badgeOffline, userInfos, fullname, jobStyle, noJob, jobStatusBox, jobStatusStyle, jobStatusStyleNotSelected, batchInfo, batchInfoNumber, batchInfoDates, contact, contactEmail, contactIcons, expBoxTitle, expTitle, expJob, expDates, expDesc, divider, socialIcon, socialIcon2, socialNetwork, socialNetworkInput, editIcon, checkIcon, crossIcon, confirmButton, bodyModal, modalMask, hoverAvatar, relative, liScrapLoadingStyle } = styles;
    const { _id, firstname, lastname, online, email, type, phone, city, career, description, experiences, linkedin, instagram, twitter, github, personal, specs, announcement, editUser, editSocialNetworks, editExperiences, editAnnouncement, batchName, start, end, componentIsMounted, avatarVisible, hover, liScrapLoading, forceVisible,forcedUser } = this.state;
    const { user, t } = this.props;
    const { job, isFreelance, isContractor, isRecruiter, inOffice, lookingForJob, lookingForAssociate, lookingForFreelance, isDeveloper, isProductOwner } = career;

  
    // Hack style
    const userInformations = { ...userInfos, width: `calc(100% - ${avatarBox.width}px)` }; // AntDesign 3.x sometimes does not support `flex="auto"` well

    let userStatus = badge;
    if (online === true) {
      userStatus = badgeOnline;
    } else if (online === false) {
      userStatus = badgeOffline;
    }

    const editNoSocialsRegistered = editUser && !linkedin && !github && !instagram && !twitter && !personal;
    const editNoExperiences = editUser && experiences.filter(e => e).length === 0;
    const editNoAnnouncement = editUser && !announcement.title && !announcement.text && !announcement.tags?.length > 0;

    // const batch = batchList?.find(e => e.userType === 'student') || {};

    const experiencesList = experiences.filter(e => e).map((e, i) => <div key={i}>
      <Row type="flex" style={{ padding: 28 }}>
        <div style={{ width: 46 }}>
          <Avatar src={e?.icon} shape="square" size={46} />
        </div>
        <div style={{ width: 'calc(100% - 46px)', paddingLeft: 18 }}>
          <div style={expTitle}>{e?.title}</div>
          <div style={expJob}>{e?.job}</div>
          <div style={expDates}>{e?.dates}</div>
          <div style={expDesc}>{e?.desc}</div>
        </div>
      </Row>
    </div>).reduce((prev, curr) => [prev, <Divider plain style={divider} />, curr], []);

    const technologies = {
      'HTML': 'orange',
      'JavaScript': 'gold',
      'JS': 'gold',
      'Node': 'green',
      'Node.js': 'green',
      'NodeJS': 'green',
      'MongoDB': 'green',
      'Mongoose': 'green',
      'React': 'cyan',
      'React.js': 'cyan',
      'ReactJS': 'cyan',
      'CSS': 'blue'
    }

    const specialities = specs.map(e => {
      const color = technologies[e] || null;

      return <Tag key={`specialities-${e}`} color={color}>{e}</Tag>;
    }); // Temporary

    const startDate = new Date(start);
    const endDate = new Date(end);

    const startFormatted = `${startDate.getDate()} ${frenchDate.months[startDate.getMonth()]}`;
    const endFormatted = `${endDate.getDate() - 1} ${frenchDate.months[endDate.getMonth()]}`;

    const ownProfile = user._id === _id;
    const ownProfileOrTeacher = ownProfile || checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] });



    return (
    <Layout style={layout}>

    <Header  />
      
      <Layout>

        <Row type="flex" justify="space-between" style={content} gutter={29}>
          <Col span={17}>
            <div style={box}>
              <Row type="flex">
                <Col style={avatarBox}>
                  <div style={relative}>
                    {
                      (ownProfile && hover) && <div id="user-change__avatar" style={hoverAvatar}>
                        <Icon type="picture" style={{ fontSize: 40 }} />
                      </div>
                    }
                    {
                      ownProfileOrTeacher
                        ? <Avatar key={new Date()} style={avatar} size={130} src={`../images/avatar/${_id}.jpg`} shape="square" id="user-profile__avatar" onClick={this.handleAvatarClick} onMouseEnter={this.avatarMouseEnter} onMouseLeave={this.avatarMouseLeave} />
                        : <Avatar style={avatar} size={130} src={`../images/avatar/${_id}.jpg`} shape="square" />
                    }
                    <span style={userStatus} />
                    {
                      ownProfileOrTeacher && <Modal centered title={`Take or upload a picture for ${user.firstname}`} visible={avatarVisible} onCancel={this.closeAvatarModal} footer={null} bodyStyle={bodyModal} maskStyle={modalMask}>
                        <Webcam studentId={user._id} userConnected={user._id} handleCancel={this.closeAvatarModal} />
                      </Modal>
                    }
                  </div>
                </Col>
                <Col style={userInformations}>
                  <Row type="flex" justify="space-between">
                    <div style={{ width: '100%' }}>
                      <Row type="flex" justify="space-between">
                        <Col flex="auto">
                          <div style={fullname}>{`${firstname} ${lastname}`}</div>
                          {
                            editUser
                              ? <Input placeholder={t("Travail")} onChange={this.onJobInputChange} value={job} />
                              : job && type === 'student'
                                ? <div className="lc-pink" style={jobStyle}>{job}</div>
                                : type !== 'student'
                                  ? <div style={jobStyle}>{job && `${job} &`} {type}</div>
                                  : <div style={noJob}>{t("Travail non renseigné")}</div>
                          }
                        </Col>
                        <Col flex="auto">
                          {
                            type === 'student' && <div style={batchInfo}>
                              <div style={batchInfoNumber}>{batchName}</div>
                              <div style={batchInfoDates}>{startFormatted} - {endFormatted}</div>
                              {/* <div style={batchInfoDates}>6 Janvier - 13 Mars</div> */}
                            </div>
                          }
                        </Col>
                        {!editUser && ownProfile ? <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={this.onEditUser} /> : null }

                         {/* Ceci est a enlever */}
                        {/* {!editUser && !ownProfile ? <button onClick={forceVisibleFunc}>Envoyer emargement</button> : null } */}

                      </Row>
                      {
                        (editUser || isDeveloper || isFreelance || isProductOwner || isContractor) && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 3 }}>
                          <UserProfile editUser={editUser} setStateInParent={this.setStateInParent} isDeveloper={isDeveloper} isFreelance={isFreelance} isProductOwner={isProductOwner} isContractor={isContractor} />
                        </div>
                      }

                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 3 }}>
                        {
                          (editUser || /*isFreelance || isContractor ||*/ isRecruiter || inOffice || lookingForJob || lookingForAssociate || lookingForFreelance) && <Row type="flex" id="user-job__row" align="middle">
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>{t("Statut")} :</div>
                            {/* {
                              editUser
                                ? <div style={jobStatusBox}>
                                  <span className={isFreelance ? "lc-pink" : ""} style={isFreelance ? jobStatusStyle : jobStatusStyleNotSelected} onClick={this.onJobSelect} id="isFreelance" className="user-job-selector">Freelance</span>
                                </div>
                                : isFreelance && <div style={jobStatusBox}>
                                  <span className="lc-pink" style={jobStatusStyle}>Freelance</span>
                                </div>
                            }
                            {
                              editUser
                                ? <div style={jobStatusBox}>
                                  <span className={isContractor ? "lc-pink" : ""} style={isContractor ? jobStatusStyle : jobStatusStyleNotSelected} onClick={this.onJobSelect} id="isContractor" className="user-job-selector">Autoentrepreneur</span>
                                </div>
                                : isContractor && <div style={jobStatusBox}>
                                  <span className="lc-pink" style={jobStatusStyle}>Autoentrepreneur</span>
                                </div>
                            } */}
                            {/* {
                              editUser
                                ? <div style={jobStatusBox}>
                                  <span className={isRecruiter ? "lc-pink" : ""} style={isRecruiter ? jobStatusStyle : jobStatusStyleNotSelected} onClick={this.onJobSelect} id="isRecruiter" className="user-job-selector">Recrute</span>
                                </div>
                                : (isRecruiter || announcement.title === 'Recrutement') && <div style={jobStatusBox}>
                                  <span className="lc-pink" style={jobStatusStyle}>Recrute</span>
                                </div>
                            } */}
                            {
                              editUser
                                ? <div style={jobStatusBox}>
                                  <span className={inOffice ? "lc-pink" : ""} style={inOffice ? jobStatusStyle : jobStatusStyleNotSelected} onClick={this.onJobSelect} id="inOffice" /*className="user-job-selector"*/>En poste</span>
                                </div>
                                : inOffice && <div style={jobStatusBox}>
                                  <span className="lc-pink" style={jobStatusStyle}>{t("En poste")}</span>
                                </div>
                            }
                            {
                              editUser
                                ? <div style={jobStatusBox}>
                                  <span className={lookingForJob ? "lc-pink" : ""} style={lookingForJob ? jobStatusStyle : jobStatusStyleNotSelected} onClick={this.onJobSelect} id="lookingForJob" /*className="user-job-selector"*/>En recherche d'emploi</span>
                                </div>
                                : lookingForJob && <div style={jobStatusBox}>
                                  <span className="lc-pink" style={jobStatusStyle}>{t("En recherche d'emploi")}</span>
                                </div>
                            }
                            {
                              editUser
                                ? <div style={jobStatusBox}>
                                  <span className={lookingForAssociate ? "lc-pink" : ""} style={lookingForAssociate ? jobStatusStyle : jobStatusStyleNotSelected} onClick={this.onJobSelect} id="lookingForAssociate" /*className="user-job-selector"*/>Recherche associé</span>
                                </div>
                                : lookingForAssociate && <div style={jobStatusBox}>
                                  <span className="lc-pink" style={jobStatusStyle}>{t("Recherche associé")}</span>
                                </div>
                            }
                            {
                              editUser
                                ? <div style={jobStatusBox}>
                                  <span className={lookingForFreelance ? "lc-pink" : ""} style={lookingForFreelance ? jobStatusStyle : jobStatusStyleNotSelected} onClick={this.onJobSelect} id="lookingForFreelance" /*className="user-job-selector"*/>Recherche freelance</span>
                                </div>
                                : lookingForFreelance && <div style={jobStatusBox}>
                                  <span className="lc-pink" style={jobStatusStyle}>{t("Recherche freelance")}</span>
                                </div>
                            }
                          </Row>
                        }
                      </div>
                      <div style={{ marginTop: 3, display: 'flex' }} id="user-specialities__row">
                        {(editUser || specialities.length > 0) && <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>{t("Spécialités")} :</div>}
                        {
                          editUser
                            ? <Select style={{ width: 'calc(100% - 75px)' }} mode="multiple" placeholder="Specialités" onChange={this.handleSpecChange} defaultValue={specs}>
                              <Option value="HTML">HTML</Option>
                              <Option value="JS">JS</Option>
                              <Option value="CSS">CSS</Option>
                              <Option value="React">React</Option>
                            </Select>
                            : <div>
                              {specialities}
                            </div>
                        }
                      </div>
                    </div>
                  </Row>
                  <Row type="flex" id="user-infos__row" align="middle" style={contact} gutter={20}>
                    {
                      editUser
                        ? <div style={{display: "flex", alignItems: "center", marginRight: "10px"}}><Icon type="environment" theme="filled" style={contactIcons} /><Input id="city" placeholder={t("Ville")} onChange={this.onInputChange} value={city} /></div>
                        : city && <Col flex="auto"><Icon type="environment" theme="filled" style={contactIcons} />{city}</Col>
                    }

                    <div style={{display: "none"}}>
                    <Col flex="auto">
                      <a id="user-email__mailto" href={`mailto:${email}`} style={contactEmail}>
                        <Icon type="mail" theme="filled" style={contactIcons} />{email}
                      </a>
                    </Col>
                    </div>
                    {
                      editUser
                        ? <div style={{display: "flex", alignItems: "center", marginRight: "10px"}}><Icon type="phone" theme="filled" style={contactIcons} /><Input id="phone" placeholder={t("Phone")} onChange={this.onInputChange} value={phone} /></div>
                        : phone && <Col flex="auto"><Icon type="phone" theme="filled" style={contactIcons} />{phone.replace(/(\d{1})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, "+33 ($1)$2 $3 $4 $5 $6")}</Col>
                    }
                  </Row>
                </Col>
              </Row>
              {
                (editUser || description) && <div style={desc}>
                  {
                    editUser
                      ? <TextArea rows={4} id="description" placeholder={t("Description")} onChange={this.onInputChange} value={description} />
                      : description && <TextArea  rows={2} style={{borderStyle: "none", resize: "none"}} value={description}/>
                  }
                </div>
              }
              {
                _id === user._id && (!editUser
                  ? <span style={{ position: 'absolute', right: 27, bottom: 7 }}>
                  </span>
                  : <Row type="flex" justify="end" style={confirmButton}>
                    <Button type="primary" onClick={this.onEditUser}>{t("Enregistrer")}</Button>
                  </Row>)
              }
            </div>

{ /* PARTIE EXPERIENCE - SCRAP LINKEDIN */}
            {
              (experiencesList.length <= 0 || editNoExperiences || !editNoExperiences ) && <div style={{ ...box, padding: 0 }} id="user-exp__row" className={liScrapLoading ? 'disabled' : ''}>

                {liScrapLoading && <div style={liScrapLoadingStyle}><p style={{marginTop: "40px"}}>{t("Veuillez attendre quelques secondes")}</p><p>{t("vos données sont en cours d'importation")}</p><Spin size="large" /></div>}


                <Row style={expBoxTitle} id="user-recruitment__row" type="flex" justify="space-between">
                  <div>{t("Expériences")}</div>
                  {
                    ownProfile && <ImportFromLinkedIn linkedin={linkedin} _id={_id} setStateInParent={this.setStateInParent} triggerSocialsUpdate={this.triggerSocialsUpdate} />
                  }
                  
                  {/* <LinkedInPage /> */}
                  {
                    ownProfile && experiencesList.length === 0 && (!editExperiences
                      ? <div>
                        <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={this.onEditExperiences} />
                      </div>
                      : <div>
                        <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={this.onEditExperiences} />
                      </div>)
                  }
                </Row>
                {/* {experiencesList} */}
                {componentIsMounted && <Experiences key={Date.now()} setStateInParent={this.setStateInParent} onEditExperiences={this.onEditExperiences} wrappedComponentRef={form => this.form = form} editExperiences={editExperiences} user={user} _id={_id} experiences={experiences.filter(e => e).slice().sort((a, b) => new moment(b.date_start) - new moment(a.date_start))} />}
              </div>
            }
          </Col>

          {/* RESEAUX SOCIAUX */}

          <Col span={7}>
            {
              (_id === user._id ) && !linkedin && !twitter && !instagram && !github && !personal && !editSocialNetworks ?  <div style={box2} id="user-socials__row">

                 {
                  !(editNoSocialsRegistered || editSocialNetworks)
                    ? <Row type="flex" justify="center" style={confirmButton}>
                      <Button style={{marginBottom: "5vh", marginTop: "-3vh", marginLeft: "45%"}} type="primary" onClick={this.onEditSocialNetworks}>{t("Mettre à jour mes réseaux")}</Button>
                      <img src={socialnetwork} style={{height: "120px", filter: "saturate(0%)", opacity: "0.4", marginBottom: "20px"}} />
                      </Row>
                    : <Row type="flex" justify="end" style={confirmButton}>
                      <Button type="primary" onClick={this.onEditSocialNetworks}>{t("Enregistrer")}</Button>
                    </Row>
                }</div> :  <div style={box2} id="user-socials__row">
                
                   <Row type="flex" align="middle">
                    {
                      editSocialNetworks
                        ? <div style={socialNetworkInput}>
                          <Icon type="linkedin" style={socialIcon} />
                          <Input id="linkedin" placeholder="https://www.linkedin.com/..." onChange={this.onInputChange} value={linkedin} />
                        </div>
                        : <a href={linkedin} target="_blank" rel="noopener noreferrer" style={socialNetwork}>
                          {linkedin? <div><Icon type="linkedin" style={socialIcon} /><Icon type="check" style={checkIcon} />{linkedin}</div> : <Icon type="linkedin" style={socialIcon2} /> } 
                        </a>
                    }
                  </Row>
                
                
                    <Row type="flex" align="middle">
                    {
                      editSocialNetworks
                        ? <div style={socialNetworkInput}>
                          
                          <Icon type="twitter" style={socialIcon} />
                          <Input id="twitter" placeholder="https://www.twitter.com/..." onChange={this.onInputChange} value={twitter} />
                        </div>
                        : <a href={twitter} target="_blank" rel="noopener noreferrer" style={socialNetwork}>
                        {twitter? <div><Icon type="twitter" style={socialIcon} /><Icon type="check" style={checkIcon} />{twitter}</div> : <Icon type="twitter" style={socialIcon2} /> } 
                        </a>
                    }
                  </Row>
                
                
                   <Row type="flex" align="middle">
                    {
                      editSocialNetworks
                        ? <div style={socialNetworkInput}>
                          <Icon type="instagram" style={socialIcon} />
                          <Input id="instagram" placeholder="https://www.instagram.com/..." onChange={this.onInputChange} value={instagram} />
                        </div>
                        :
                        <a href={instagram} target="_blank" rel="noopener noreferrer" style={socialNetwork}>
                        {instagram? <div><Icon type="instagram" style={socialIcon} /><Icon type="check" style={checkIcon} />{instagram}</div> : <Icon type="instagram" style={socialIcon2} /> } 
                        </a>
                    }
                  </Row>
                
                
                   <Row type="flex" align="middle">
                    {
                      editSocialNetworks
                        ? <div style={socialNetworkInput}>
                          <Icon type="github" style={socialIcon} />
                          <Input id="github" placeholder="https://www.github.com/..." onChange={this.onInputChange} value={github} />
                        </div>
                        : <a href={github} target="_blank" rel="noopener noreferrer" style={socialNetwork}>
                          {github? <div><Icon type="github" style={socialIcon} /><Icon type="check" style={checkIcon} />{github}</div> : <Icon type="github" style={socialIcon2} /> } 
                        </a>
                    }
                  </Row>
                
                
                  <Row type="flex" align="middle">
                    {
                      editSocialNetworks
                        ? <div style={socialNetworkInput}>
                          <Icon type="link" style={socialIcon} />
                          <Input id="personal" placeholder="https://..." onChange={this.onInputChange} value={personal} />
                        </div>
                        :
                        <a href={personal} target="_blank" rel="noopener noreferrer" style={socialNetwork}>
                        {personal? <div><Icon type="link" style={socialIcon} /><Icon type="check" style={checkIcon} />{personal}</div> : <Icon type="link" style={socialIcon2} /> } 
                        </a>
                    }
                  </Row>
                
                
                { ownProfile &&
                  <>
                  {
                    !(editNoSocialsRegistered || editSocialNetworks)
                      ? <div className="edit-profile__icon" style={{ position: 'absolute', top: 32, right: 25 }}>
                        <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={this.onEditSocialNetworks} />
                      </div>
                      : <Row type="flex" justify="end" style={confirmButton}>
                        <Button type="primary" onClick={this.onEditSocialNetworks}>{t("Enregistrer")}</Button>
                      </Row>
                  }
                  </>
                }
              </div>
            }
            {
              (announcement.text?.length > 0 || announcement.tags?.length > 0 || user._id === _id) && <Recruitment key={Date.now()} announcement={announcement} selfProfile={user._id === _id} editAnnouncement={editAnnouncement} setStateInParent={this.setStateInParent} onEditAnnouncement={this.onEditAnnouncement} _id={_id} />
            }
          </Col>
        </Row>

      </Layout>
    </Layout>);
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(withTranslation()(Profile));

const styles = {
  layout: { minHeight: '100vh', height: 'auto' },
  content: { margin: '25px 14.5px' },
  box: { borderRadius: 9, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', padding: 30, backgroundColor: 'white', marginBottom: 27, position: 'relative' },
  box2: { borderRadius: 9, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', padding: 39, backgroundColor: 'white', marginBottom: 27, position: 'relative' },
  avatarBox: { width: 130 },
  avatar: { borderRadius: 9 },
  desc: { fontSize: 14, color: '#444444', fontWeight: 'light', marginTop: 10, position: 'relative' },
  badge: { height: 22, width: 22, borderRadius: '50%', backgroundColor: '#d9d9d9', position: 'absolute', top: '116px', left: '116px' },
  badgeOnline: { height: 22, width: 22, borderRadius: '50%', backgroundColor: '#2cac76', position: 'absolute', top: '116px', left: '116px' },
  badgeOffline: { height: 22, width: 22, borderRadius: '50%', backgroundColor: '#fa423c', position: 'absolute', top: '116px', left: '116px' },
  userInfos: { paddingLeft: 22, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
  fullname: { fontSize: 20, fontWeight: 'bold', color: '#444444' },
  jobStyle: { fontSize: 14, fontWeight: 500, textTransform: 'uppercase', letterSpacing: '1.98px', marginTop: 0 },
  noJob: { fontSize: 14, fontWeight: 500, color: '#bbb', textTransform: 'uppercase', letterSpacing: '1.98px', marginTop: 0 },
  jobStatusBox: { display: 'flex', alignItems: 'center' },
  jobStatusStyle: { fontSize: 9, fontWeight: 'bold', borderRadius: 4, backgroundColor: '#fce4e3', padding: 6 },
  jobStatusStyleNotSelected: { fontSize: 9, fontWeight: 'bold', color: '#b3b3b3', borderRadius: 4, backgroundColor: '#F6F7FB', padding: 6 },
  batchInfo: { textAlign: 'right' },
  batchInfoNumber: { fontSize: 14, fontWeight: 'bold', letterSpacing: 0.33, color: '#838486' },
  batchInfoDates: { fontSize: 12, letterSpacing: '1px', textTransform: 'uppercase', color: '#838486' },
  contact: { fontSize: 14, fontWeight: 'bold', color: '#444444', letterSpacing: 0.33, marginTop: 10, marginRight: 0, marginLeft: 0 },
  contactEmail: { color: '#444444' },
  contactIcons: { marginRight: 6 },
  expBoxTitle: { fontSize: 17, fontWeight: 'bold', color: '#2c2c2c', letterSpacing: 0.82, padding: 25, borderBottom: '1px solid #f5f5f5' },
  expTitle: { fontSize: 14, fontWeight: 'bold', color: '#444444' },
  expJob: { fontSize: 14, fontWeight: 300, color: '#444444' },
  expDates: { fontSize: 14, fontWeight: 300, color: '#b4b4b4' },
  expDesc: { fontSize: 14, fontWeight: 300, color: '#444444', marginTop: 17 },
  divider: { margin: 0, background: '#f5f5f5' },
  statsStar: { height: 26, width: 26, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
  statsPoints: { marginLeft: 15, fontSize: 14, color: 'rgba(0, 0, 0, 0.65)' },
  socialIcon: { position: 'relative', top: 2, fontSize: 18, width: 20, marginRight: 9 },
  socialIcon2: { opacity: 0.3, position: 'relative', top: 2, fontSize: 18, width: 20, marginRight: 9 },
  checkIcon: { color:"#53ad77", position: 'relative', top: 2, fontSize: 18, width: 20, marginRight: 9 },
  crossIcon: { color: "#F94A56", position: 'relative', top: 2, fontSize: 18, width: 20, marginRight: 9 },
  socialNetwork: { fontWeight: 300, letterSpacing: 0.33, width: '70%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
  socialNetworkInput: { width: '60%' },
  editIcon: { color: '#000000', opacity: 0.65, fontSize: 24, cursor: 'pointer' },
  confirmButton: { marginTop: 10 },
  bodyModal: { backgroundColor: '#F6F6FA', borderRadius: 10 },
  modalMask: { backgroundColor: '#878789aa' },
  hoverAvatar: { position: 'absolute', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'all .3s ease' },
  relative: { position: 'relative' },
  liScrapLoadingStyle: { opacity: "0.9", zIndex: 10, top: 0, left: 0, bottom: 0, right: 0, overflow: "auto", background: "lightgrey", position: 'fixed', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }
}
