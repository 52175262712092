import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  Icon,
  Progress,
  Tooltip,
  Modal,
  Spin,
} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import Editor from "react-simple-code-editor";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";

//STYLES______________________
import "./Quizz.css";
import "ace-builds/src-noconflict/theme-solarized_light";
import "../Content/Flashcard.css";

//COMPONENTS____________________
import CustomIcon from "../../../CustomIcon";
import Answer from "./Answer";

//SCROLLBAR______________________
import SimpleBar from "simplebar-react";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

const CodeQuizDisplay = props => {
  const styles = {
    editor: {
      width: "90%",
      boxSizing: "border-box",
      fontFamily: '"Dank Mono", "Fira Code", monospace',
      ...theme.plain,
      backgroundColor: "#282C34",
      marginBottom: "30px",
    },
  };

  const onValueChange = code => {
    // setCode( code );
  };

  const highlight = code => (
    <Highlight {...defaultProps} theme={theme} code={props.code} language="jsx">
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </>
      )}
    </Highlight>
  );

  return (
    <Editor
      className={"editor-box"}
      value={props.code}
      onValueChange={onValueChange}
      highlight={highlight}
      padding={10}
      style={styles.editor}
    />
  );
};

class Quizz extends Component {
  answerId;
  solution = false;
  propositionSolution = false;
  propositionId;
  answers = [];
  score = 0;
  time = 0;

  state = {
    index: 0,
    questionCount: 0,
    correctAnswer: null,
    selectedProposition: null,
    status: "in progress",
    current: 0,
    finalScore: 0,
    pickedAnswers: [],
    progressPercentage: 0,
    unlockTimer: null,
    lastAttempt: null,
    isUnlocked: false,
    timer: 120,
    modalOpen: false,
    modalDisplay: "correct",
    loading: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { url: prevUrl } = prevProps;
    const { url, stepsList, id } = this.props;
    const { isUnlocked, unlockTimer } = this.state;

    if (isUnlocked) clearInterval(unlockTimer);

    if (prevUrl !== url) {
      // Current
      const current = stepsList.find(e => e.id === id);
      const currentPos = stepsList.indexOf(current);

      // Next
      const nextPos = currentPos < stepsList.length - 1 && currentPos + 1;
      const next = stepsList[nextPos];

      this.answerId = null;
      this.solution = false;
      this.answers = [];
      this.score = 0;
      this.time = 0;
      this.setState({
        index: 0,
        questionCount: 0,
        correctAnswer: null,
        selectedProposition: null,
        status: "in progress",
        current: 0,
        next,
      });
    }
  }

  componentDidMount() {
    const { stepsList, id, stepData, questions, user, batch } =
      this.props;

    // Current
    const current = stepsList.find(e => e.id === id);
    const currentPos = stepsList.indexOf(current);

    // Next
    const nextPos = currentPos < stepsList.length - 1 && currentPos + 1;
    const next = stepsList[nextPos];

    this.setState({ current, next });

    fetch(`${global.URI_BACKEND}/stepprogression/user/${user._id}/${batch._id}`)
      .then(res => res.json())
      .then(data => {
        if (data.success && data.progression !== null) {
          var tempQuestionsCount = [];
          for (const e of data.progression.progression) {
            if (e?.step === stepData.stepId) {
              this.setState({
                pickedAnswers: e.sections,
                progressPercentage: e.stepPercentage,
              });
              e.sections.forEach(section =>
                this.answers.push({
                  answerId: section.answerNumber + 1,
                  questionId: section.index,
                  result: section.result,
                })
              );

              if (e?.stepPercentage !== 100) {
                tempQuestionsCount = Math.round(
                  questions.length * (e.stepPercentage / 100)
                );
                this.setState({ questionCount: tempQuestionsCount });
              } else if (e?.stepPercentage === 100) {
                tempQuestionsCount = Math.round(
                  questions.length * (e.stepPercentage / 100) - 1
                );
                const endDate = e?.sections[e.sections.length - 1]?.date;

                this.setState({
                  finalScore: e.scorePercentage,
                  questionCount: tempQuestionsCount,
                  status: "finished",
                  lastAttempt: endDate,
                });

                if (moment().isAfter(moment(endDate).add(2, "hours"))) {
                  this.setState({ isUnlocked: true });
                } else {
                  let duration = moment.duration(
                    moment(endDate).add(2, "hours").diff(moment())
                  );
                  let minutes = duration.asMinutes();
                  this.setState({ timer: Math.round(minutes) });
                }
              }

              for (const f of e.sections) {
                if (f.result === true) this.score++;
              }
            }
          }
        }
      });

    let interval = 5;
    const unlockTimer = setInterval(
      () => this.unlockRedoQuizz(),
      interval * 1000 * 60
    );

    this.setState({ unlockTimer });
  }

  componentWillUnmount = () => {
    const { unlockTimer } = this.state;
    clearInterval(unlockTimer);
  };

  unlockRedoQuizz = () => {
    const { lastAttempt, isUnlocked, timer } = this.state;
    if (
      !isUnlocked &&
      lastAttempt &&
      moment().isAfter(moment(lastAttempt).add(2, "hours"))
    ) {
      this.setState({ isUnlocked: true, timer: 120 });
    } else if (timer >= 5) {
      this.setState({ timer: timer - 5 });
    }
  };

  selectProposition = (i, solution, id) => {
    this.setState({
      selectedProposition: i,
      propositionSolution: solution,
      propositionId: id,
    });
  };

  onValidateClick = (/* i, solution, id */) => {
    const {
      questions,
      user: { _id },
      batch,
      stepData,
      user,
      updateProgress
    } = this.props;
    const {
      questionCount,
      selectedProposition: i,
      propositionSolution: solution,
      propositionId: id,
    } = this.state;
    this.setState({ loading: true });

    // this.setState({ selectedProposition: i })
    if (solution) this.setState({ correctAnswer: i });

    const newAnswer = {
      questionId: questions[questionCount].id,
      answerId: id,
      result: solution ? true : false,
    };
    const answerIndex = this.answers.findIndex(
      e => e.questionId === questionCount + 1
    );
    const hasAnswer = this.answers.find(
      e => e.questionId === questionCount + 1
    );

    if (solution && (!hasAnswer || (hasAnswer && !hasAnswer.result))) {
      this.score++;
    } else if (!solution && hasAnswer && hasAnswer.result) {
      this.score--;
    }

    if (answerIndex >= 0) {
      this.answers[answerIndex] = newAnswer;
    } else {
      this.answers.push(newAnswer);
    }

    var tempStepPercentage = Math.round(
      ((questionCount + 1) / questions.length) * 100
    );

    if (tempStepPercentage >= 99) tempStepPercentage = 100;

    const body = {
      student: user._id,
      batch: batch._id,
      step: stepData.stepId,
      type: "quizz",
      stackDay: stepData.day,
      title: stepData.text,
      mainGroup: stepData.mainGroup,
      questionCount: questions.length,
      scoreResult: this.score,
      secondaryGroup: stepData.secondaryGroup,
      scorePercentage: Math.round(
        (this.score / (this.state.questionCount + 1)) * 100
      ),
      stepPercentage: tempStepPercentage,
      date: moment().format(),
      index: questions[questionCount].id,
      result: solution == null ? false : true,
      answerNumber: i,
      mainGroupSections: questions[questionCount].mainGroup
        ? questions[questionCount].mainGroup
        : stepData.mainGroup,
      secondaryGroupSections: questions[questionCount].secondaryGroup
        ? questions[questionCount].secondaryGroup
        : stepData.secondaryGroup,
    }; // Data sent to backend when ongoing quizz

    fetch(`${global.URI_BACKEND}/stepprogression/quizz/${user._id}`, {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(data => {
        if (data.progression !== null) {
          for (const e of data.progression) {
            if (e?.step === stepData.stepId) {
              this.setState({
                finalScore: e.scorePercentage,
                progressPercentage: e.stepPercentage,
                lastAttempt: new Date(e.date),
              });
            }
          }
          this.setState({ loading: false });

          // ENVOI DONNÉES PROGRESSION QUAND QUIZZ FINI

          if (!this.nextQuestion()) {
            this.setState({ status: "finished" });
            fetch(`${global.URI_BACKEND}/stepprogression/`, {
              method: "post",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                student: _id,
                batch: batch._id,
                step: stepData.stepId,
                title: stepData.text,
                stackDay: stepData.day,
                type: "quizz",
                scorePercentage: Math.round(
                  (this.score / (this.state.questionCount + 1)) * 100
                ),
                scoreResult: this.score,
                stepPercentage: 100,
              }),
            })
              .then(res => res.json())
              .then(data => {
                if (data.stepProgression) {
                  for (const e of data.stepProgression.progression) {
                    if (e?.step === stepData.stepId) {
                      this.setState({
                        pickedAnswers: e.sections,
                        progressPercentage: e.stepPercentage,
                      });
                    }
                  }
                }
              })
              .catch(console.error);
          }
        }
      }).then(() => updateProgress())
      .catch(console.error);
  };

  nextQuestion = () => {
    const { questions } = this.props;
    const { questionCount, index } = this.state;

    if (questionCount + 1 < questions.length) {
      this.setState({
        questionCount: questionCount + 1,
        index: index + 1,
        selectedProposition: null,
        correctAnswer: null,
      });
      return true;
    }
    return false;
  };

  previousQuestion = () => {
    const { questions } = this.props;
    const { questionCount, index } = this.state;
    if (questionCount - 1 >= 0) {
      this.setState({
        questionCount: questionCount - 1,
        index: index - 1,
        progressPercentage: Math.round(
          ((questionCount - 1) / questions.length) * 100
        ),
      });
    }
  };

  redoQuizz = () => {
    const { user, stepData } = this.props;

    fetch(`${global.URI_BACKEND}/stepprogression/quizz/delete/${user._id}/`, {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        step: stepData.stepId,
        stepPercentage: 0,
        scorePercentage: 0,
      }),
    })
      .then(res => res.json())
      .then(data => {})
      .catch(console.error);

    this.score = 0;
    this.answers = [];
    this.setState({
      selectedProposition: null,
      questionCount: 0,
      status: "in progress",
      progressPercentage: 0,
    });
  };

  showModal = mode => {
    this.setState({ modalDisplay: mode });
    this.setState({ modalOpen: true });
  };

  render() {
    const {
      questionCount,
      selectedProposition,
      correctAnswer,
      status,
      finalScore,
      pickedAnswers,
      progressPercentage,
      isUnlocked,
      timer,
      modalOpen,
      modalDisplay,
      loading,
    } = this.state;
    const { questions, nextStep, stepData, t, next } =
      this.props;
    const {
      questionTitle,
      results,
      mt20,
      button2,
      heading,
      progressionContainer,
      quizzContainer,
      buttonContainer,
      outlineButton,
      outlineButtonDisabled,
      quizzPage,
      resultsHeader,
      resultsTitle,
      resultsPercentage,
      emojiWrapper,
      countContainer,
      resultSection,
      resultSectionDeactivate,
      icon,
      backButton,
      redoButton,
      disabledButton,
      questionCard,
      cardTitle,
      modal,
      questionText,
    } = styles;

    const wrappedQuestions = questions[questionCount]?.question.map((p, i) => {
      return (
        <h2 style={questionTitle} key={i}>
          {p}
        </h2>
      );
    });
    const wrappedPropositions = questions[questionCount]?.propositions.map(
      (p, i) => (
        <Answer
          key={i}
          questionHasCode={questions[questionCount].code?.length > 0}
          withCode={p.withCode}
          proposition={p}
          index={i}
          // isSelected={selectedProposition === i /* this.answers.find(e => e.questionId === questionCount + 1) && this.answers.find(e => e.questionId === questionCount + 1).answerId === i + 1 */}
          selectedProposition={selectedProposition}
          correctAnswer={correctAnswer}
          radioClick={this.selectProposition}
        />
      )
    );

    const questionWithCode = questions[questionCount]?.code?.length > 0;

    const codeValue = questions[questionCount]?.code;

    const scorePercentage =
      status === "finished" && finalScore
        ? finalScore
        : Math.round((this.score / questions.length) * 100);

    let correctScore = Math.round(
      (questions.length * finalScore || scorePercentage) / 100
    );
    let incorrectScore =
      finalScore === 100 || scorePercentage === 100
        ? 0
        : Math.round(
            (questions.length * (100 - finalScore || scorePercentage)) / 100
          );

    let modalTitle;
    if (
      status === "finished" &&
      (pickedAnswers.length > 0 || this.answers.length > 0)
    ) {
      if (modalDisplay === "correct") {
        modalTitle = t("réponsesCorrectes", { count: correctScore });
      } else if (modalDisplay === "incorrect") {
        modalTitle = t("réponsesFausses", { count: incorrectScore });
      }
    }

    // var tempFinalResult = [];
    // let pickedAnswersTable = [];

    // let className = 'answer-frame2';
    // if (pickedAnswers) className += ' answered';

    // for (const q of questions) {
    //   tempFinalResult.push(q.question)
    // }

    // for (let i = 0; i < rawPropositions?.length; i++) {
    //   if (pickedAnswers[i] !== undefined && pickedAnswers[i].index == rawPropositions[i].id) {
    //     pickedAnswersTable.push(rawPropositions[i].propositions[pickedAnswers[i].answerNumber])
    //   }
    // }

    return (
      <>
        <SimpleBar
          style={{
            height: "calc(100vh - 91px)",
            width: "100vw",
            padding: "10px 10px 0px 10px",
          }}
        >
          <Row style={quizzPage}>
            <Col span={5} offset={1} style={{ paddingTop: 40 }}>
              <h1 style={heading}>{t("Quiz")}</h1>
              <Progress
                style={{ width: "100%" }}
                id="quizz-progress"
                showInfo={false}
                strokeColor="#5246E9"
                strokeWidth={10}
                maxLines={Infinity}
                percent={progressPercentage > 2 ? progressPercentage : 0}
                format={() => (
                  <span style={{ color: "black" }}>
                    {progressPercentage
                      ? `${progressPercentage}%`
                      : "0%"}
                  </span>
                )}
              />
              <div style={progressionContainer}>
                <span>{t("Progression")}</span>
                <span>
                  {" "}
                  {questionCount + 1} / {questions.length}
                </span>
              </div>
              {stepData.stepType_v2 !== "rncp" &&
                questionCount > 0 &&
                status !== "finished" && (
                  <Button
                    style={backButton}
                    type="primary"
                    size="large"
                    onClick={() => this.previousQuestion()}
                  >
                    <Icon type="arrow-left" /> {t("Précédent")}
                  </Button>
                )}
            </Col>
            <Col span={14} style={quizzContainer}>
              {status !== "finished" && (
                <>
                  {questionWithCode && (
                    <CodeQuizDisplay code={codeValue.trim()} />
                  )}
                  {wrappedQuestions}
                  {wrappedPropositions}
                  {loading === true ? (
                    <div style={buttonContainer}>
                      <Button style={outlineButtonDisabled}>
                        <Spin size="sm" />
                      </Button>
                    </div>
                  ) : selectedProposition === null ? (
                    <div style={buttonContainer}>
                      <Button style={outlineButtonDisabled} disabled>
                        {t("Valider")}
                      </Button>
                    </div>
                  ) : (
                    <div style={buttonContainer}>
                      <Button
                        style={outlineButton}
                        onClick={this.onValidateClick}
                      >
                        {t("Valider")}
                      </Button>
                    </div>
                  )}
                </>
              )}
              {status === "finished" && (
                <>
                  <div style={results}>
                    <div style={resultsHeader}>
                      <span style={{ ...resultsTitle, ...resultsPercentage }}>
                        {finalScore !== undefined
                          ? finalScore
                          : scorePercentage}
                        %
                      </span>
                      <p style={resultsTitle}>
                        {scorePercentage <= 60 || finalScore <= 60
                          ? t("Encore un peu de travail !")
                          : scorePercentage <= 80 || finalScore <= 80
                          ? t("Tu y es presque !")
                          : scorePercentage <= 99 || finalScore <= 99
                          ? t("Pas mal !")
                          : t("Félicitations !")}
                      </p>
                    </div>
                    <span role="img" aria-label="smiley" style={emojiWrapper}>
                      <img src="/images/emojis/iconDev.svg" alt="dev" />
                    </span>
                  </div>
                  <div style={countContainer}>
                    <div
                      style={
                        correctScore > 0
                          ? resultSection
                          : resultSectionDeactivate
                      }
                      onClick={() => this.showModal("correct")}
                    >
                      <CustomIcon icon="check" style={icon} />
                      <h6 style={resultsTitle}>
                        {t("réponsesCorrectes", { count: correctScore })}
                      </h6>
                    </div>
                    <div
                      style={
                        incorrectScore > 0
                          ? resultSection
                          : resultSectionDeactivate
                      }
                      onClick={() => this.showModal("incorrect")}
                    >
                      <CustomIcon icon="cross" style={icon} />
                      <h6 style={resultsTitle}>
                        {t("réponsesFausses", { count: incorrectScore })}
                      </h6>
                    </div>
                  </div>
                  <Row type="flex" justify="center" align="middle" style={mt20}>
                    {stepData.stepType_v2 !== "rncp" && (
                      <>
                        {isUnlocked ? (
                          <Link
                            to={`/batch/${stepData?.stepType}/${stepData?.stepId}`}
                          >
                            <Button
                              style={redoButton}
                              type="primary"
                              size="large"
                              onClick={() => this.redoQuizz()}
                            >
                              <Icon type="arrow-left" />
                              {t("Refaire")}
                            </Button>
                          </Link>
                        ) : (
                          <Tooltip
                            title={`Revenez dans ${
                              timer >= 60
                                ? `${Math.round(timer / 60)} heures`
                                : `${timer} minutes`
                            } pour réessayer`}
                            placement="bottom"
                          >
                            <Button
                              style={{ ...redoButton, ...disabledButton }}
                              disabled
                              type="primary"
                              size="large"
                              onClick={() => this.redoQuizz()}
                            >
                              <Icon type="arrow-left" />
                              {t("Refaire")}
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    )}
                    {next && (
                      <Link to={`/batch/${next?.stepType}/${next?.stepId}`}>
                        <Button
                          style={button2}
                          type="primary"
                          size="large"
                          onClick={() => nextStep(next)}
                        >
                          {t("Étape suivante")} <Icon type="arrow-right" />
                        </Button>
                      </Link>
                    )}
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </SimpleBar>

        <Modal
          visible={modalOpen}
          footer={null}
          onCancel={() => this.setState({ modalOpen: false })}
          title={modalTitle}
          width="70vw"
          bodyStyle={modal}
          destroyOnClose
          style={{ top: 56 }}
        >
          <SimpleBar
            style={{
              maxHeight: "78vh",
              width: "100%",
              padding: "10px 10px 0px 10px",
            }}
            className="quizz-modal"
          >
            {status === "finished" &&
              (pickedAnswers.length === questions.length ||
                this.answers.length === questions.length) &&
              questions
                .filter(e => {
                  let answer =
                    this.answers.find(f => f.questionId === e.id) ||
                    pickedAnswers.find(f => f.index === e.id);
                  if (modalDisplay === "correct") {
                    return answer.result;
                  }
                  return !answer.result;
                })
                .map((e, index) => (
                  <div style={questionCard} key={index}>
                    <h4 style={{ ...cardTitle, ...questionText }}>{e.id}.</h4>
                    {e?.code?.length > 0 && (
                      <CodeQuizDisplay code={e.code.trim()} />
                    )}
                    {e.question.map((p, i) => (
                      <div style={questionText} key={i}>
                        {p}
                      </div>
                    ))}
                    {e.propositions.map((f, i) => {
                      return (
                        <Answer
                          key={i}
                          withCode={f.withCode}
                          proposition={f}
                          index={i}
                          reviewMode
                          correct={f.solution}
                          incorrect={
                            pickedAnswers.length === questions.length
                              ? pickedAnswers.find(q => q.index === e.id)
                                  ?.answerNumber === i && !f.solution
                              : this.answers.find(q => q.questionId === e.id)
                                  ?.answerId -
                                  1 ===
                                  i && !f.solution
                          }
                        />
                      );
                    })}
                  </div>
                ))}
          </SimpleBar>
        </Modal>
      </>
    );
  }
}

function mapStateToProps({ stepsList, user, batch }) {
  return { stepsList, user, batch };
}

function mapDispatchToProps(dispatch) {
  return {
    nextStep: function (step) {
      dispatch({ type: "selectStep", step });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Quizz));

const styles = {
  mTop: { marginTop: 20 },
  questionContainer: {
    padding: "16px 32px",
    backgroundColor: "#1f1e2f",
    fontFamily: "courier",
    color: "#ffffff",
    fontSize: 16,
    width: "100%",
  },
  timeline: {
    color: "white",
    backgroundColor: "#5246E9",
    padding: "8px 15px 8px 15px",
    borderRadius: "40px",
  },
  width: { width: "100%" },
  results: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: 5,
    padding: 40,
  },
  scoreStyle: { color: "#F7F6FD", fontSize: 35, marginBottom: 0 },
  congratsColor: { color: "#F7F6FD", fontSize: 25 },
  mt20: { marginTop: 20 },
  button2: {
    backgroundColor: "#534DE8",
    border: "none",
    fontSize: "14px",
    borderRadius: 35,
    width: "195px",
    margin: "0px 30px",
  },
  answerSection: {
    width: "100%",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: 5,
  },
  heading: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
  },
  progressionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 0px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "18px",
  },
  quizzContainer: {
    position: "relative",
    paddingBottom: 50,
    marginLeft: 80,
  },
  quizzPage: {
    height: "calc(100vh - 120px)",
    paddingTop: 20,
  },
  questionTitle: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "24px",
    width: "90%",
  },
  buttonContainer: {
    width: "90%",
    flexDirection: "column",
    display: "flex",
    paddingTop: 20,
    justifyContent: "end",
    alignItems: "end",
  },
  outlineButton: {
    width: 170,
    height: 43,
    backgroundColor: "#ffffff",
    borderRadius: "50px",
    border: "2px solid #5247EA",
    color: "#5247EA",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: 14,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 3px",
  },
  outlineButtonDisabled: {
    width: 170,
    height: 43,
    backgroundColor: "#f5f5f5",
    borderRadius: "50px",
    border: "2px solid #d9d9d9",
    color: "#d9d9d9",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: 14,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 3px",
  },
  resultsHeader: {
    display: "flex",
    color: "#000000",
    alignItems: "center",
  },
  resultsTitle: {
    color: "#010031",
    margin: 0,
    fontFamily: "Poppins, sans-serif",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "24px",
  },
  resultsPercentage: {
    color: "#010031",
    fontSize: "36px",
    lineHeight: "54px",
    marginRight: 10,
    fontWeight: 600,
  },
  emojiWrapper: {
    minHeight: "41px",
    width: "auto",
    fontSize: "30px",
  },
  countContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: 5,
    padding: 40,
    marginTop: 30,
  },
  resultIcon: {
    height: "39px",
    width: "39px",
    borderRadius: "50%",
    backgroundColor: "#5247EA",
  },
  resultSection: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 25px",
    alignItems: "center",
    cursor: "pointer",
  },
  resultSectionDeactivate: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 25px",
    alignItems: "center",
    fontStyle: "italic",
    pointerEvents: "none",
  },
  icon: {
    marginRight: 15,
    cursor: "pointer",
  },
  backButton: {
    border: "2px solid #5247EA",
    background: "#ffffff",
    color: "#5247EA",
    width: "100%",
    marginTop: "20px",
    borderRadius: 35,
  },
  redoButton: {
    border: "2px solid #5247EA",
    background: "#ffffff",
    color: "#5247EA",
    borderRadius: 35,
    width: "195px",
    margin: "0px 30px",
  },
  disabledButton: {
    opacity: 0.4,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: "url('/images/backgrounds/ArianeBackground.png')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    maxHeight: "80vh",
    padding: 0,
  },
  questionCard: {
    width: "85%",
    maxWidth: "810px",
    position: "relative",
    paddingLeft: "50px",
    margin: "10px 0px",
    display: "flex",
    flexDirection: "column",
  },
  cardTitle: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  questionText: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 300,
    fontSize: "16px",
    margin: "10px 0px 20px 0px",
  },
};
