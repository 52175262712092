import { Avatar /* Icon, Tag, List, Badge */ } from "antd";
import React, { Component } from "react";
import moment from "moment";
import { Calendar as CalendarDP2 } from "react-datepicker2";
import { /* batch,*/ connect } from "react-redux";
// import { Link } from 'react-router-dom';
import SimpleBar from "simplebar-react";
// import checkRank from '../../utils/checkRank';
// import { iconSrc } from '../static-data';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.eventDiv = React.createRef();

    const { /* batch: { date: { start = 0, end = 0 } } = {}, */ day } =
      this.props;

    this.state = {
      detailsIndex: null,
      steps: [],
      selectedDate: moment(day, "YYYY-MM-DD"),
      eventHeight: 0,
      disabledRanges: [],
    };
  }

  componentDidMount() {
    const { /* disabledRanges ,*/ selectedDate: stateDate } = this.state;
    const {
      batch: { date: { start = 0, end = 0 } = {} } = {},
      batch,
      batchid,
      stackid,
      day,
      user,
    } = this.props;

    let batchPlanning = [];
    const planning = batch.planning
      .map(e => e.days.map(f => moment(f.date).utc()))
      .flat();

    // Génération des jours actifs du planning du batch
    for (
      let batchDay = moment(start).utc();
      batchDay.isBefore(moment(end).utc());
      batchDay.add(1, "day")
    ) {
      if (planning.findIndex(f => f.isSame(moment(batchDay).utc())) >= 0) {
        batchPlanning.push({
          disabled: false,
          start: moment(batchDay).utc(),
          end: moment(batchDay).utc(),
        });
      } else {
        batchPlanning.push({
          disabled: true,
          start: moment(batchDay).utc(),
          end: moment(batchDay).utc(),
        });
      }
    }

    this.setState({ disabledRanges: batchPlanning });

    let selectedDate = stateDate;

    this.loadMessages(stackid, batchid, user._id, "fr", start, end, day);
    // this.loadSteps(selectedDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { language: prevLanguage } = prevProps;
    const { language, setStepsLoaded } = this.props;
    const { selectedDate } = this.state;

    if (prevLanguage !== language) {
      setStepsLoaded(false);
      this.loadSteps(selectedDate);
    }
  }

  onDateChange = date => {
    const { selectedDate } = this.state;
    const { selectDay, batchid, stackid, user, setStepsLoaded } = this.props;

    if (date.isSame(selectedDate, "day")) return;
    selectDay(moment(date, "YYYY-MM-DD"));
    this.setState({ selectedDate: moment(date, "YYYY-MM-DD") });

    setStepsLoaded(false);
    this.loadMessages(
      stackid,
      batchid,
      user._id,
      "fr",
      moment(date, "YYYY-MM-DD")
    );
    this.loadSteps(date);
  };

  loadSteps = (date = []) => {
    const { batchid, setSteps, user, language } = this.props;

    if (language) {
      fetch(
        `${global.URI_BACKEND}/step?batchId=${batchid}&userId=${user._id}&date=${moment(
          date,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD")}&language=${language}`
      )
        .then(res => res.json())
        .then(data => {
          const { steps /* newDate */ } = data;
          setSteps(steps);
          this.setState({ steps: steps /* newDate */ });
        });
    }
    // this.setState({selectedDate: moment(date, 'YYYY-MM-DD')})
  };

  loadMessages = (stackid, batchid, userid, lang, daySelected) => {
    fetch(
      `${global.URI_BACKEND}/message?&dateSelected=${daySelected}&stackid=${stackid}&batchid=${batchid}&userid=${userid}&lang=${lang}`
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ messages: data.messagesList });
      });
  };

  render() {
    // const { user, day } = this.props;
    const {
      selectedDate,
      disabledRanges,
      messages /* eventHeight, detailsIndex */,
    } = this.state;
    const {
      section,
      bottomLine,
      description,
      row,
      content,
      title,
      detailsSpacer,
    } = styles;
    const {
      batch: {
        date: { start, end },
      },
      batch: { currentDay },
    } = this.props;

    const messageList =
      messages?.length > 0
        ? messages
            .map((e, i) => {
              const avatarStyle = {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 55,
                backgroundColor: "#EBE9FE",
                borderRadius: 6,
                width: 55,
              };

              return (
                <div style={row}>
                  <Avatar
                    shape="square"
                    size={55}
                    style={avatarStyle}
                    src={
                      e.sender.urlavatar
                        ? `${global.URI_FRONTEND}/images/avatar/${e.sender.urlavatar}`
                        : `${global.URI_FRONTEND}/avatar/_male-default.jpg`
                    }
                  />

                  <div style={content}>
                    {e.sender.firstname} {e.sender.lastname}
                    <div style={title}>{e.title}</div>
                    <div style={description}>{e.content}</div>
                  </div>
                </div>
              );
            })
            .reduce((prev, curr) => [
              prev,
              <div key={Math.random()} style={detailsSpacer} />,
              curr,
            ])
        : null;

    return (
      <div style={section} id="calendar-section">
        <CalendarDP2
          ranges={disabledRanges}
          showTodayButton={
            false /*moment(currentDay.date).isBetween(start, end)*/
          }
          onChange={value => this.onDateChange(value)}
          value={selectedDate}
          selectedDay={selectedDate}
          min={moment(start).subtract(1, "day")}
          max={moment(end)}
        />

        <div style={bottomLine} />

        {/* <div style={details}>
        {
          newDate ?
            <div className="lc-pink"><Icon type="exclamation-circle" theme="filled" /> Décalé au {moment(newDate, 'YYYY-MM-DD').format('DD/MM')}</div>
            : <div></div>
        }
        <div>Détail de la journée</div>
      </div> */}

        <div ref={this.eventDiv} style={{ flex: 1 }}>
          <SimpleBar className="vertical">{messageList}</SimpleBar>
        </div>
        {/* COMMENT: activer les messages et les rendez-vous reçus */}
        {/* <div style={{marginTop:25, display:'flex', flexDirection:'column', alignItems:'center'}}>
        <h3>MES RENDEZ-VOUS</h3>
        <List
            style={{paddingTop:4, paddingBottom:4, paddingLeft:10, paddingRight:10, width:'100%',maxHeight:'40vh', overflow:'scroll', marginBottom:10,}}
            itemLayout="horizontal"
            dataSource={this.props.messages.filter(e => moment(e.appointmentDate).startOf('day').isSame(moment(day).startOf('day')))}
            renderItem={(item,i) => (
                <>
                <List.Item split={false} style={{backgroundColor:'#FAFAFA', borderRadius:8, padding:10, marginBottom:10}} key={i}>
                    <List.Item.Meta 
                    onClick={()=> detailsIndex === null ? this.setState({detailsIndex:  i}) : this.setState({detailsIndex: null})} style={{cursor:'pointer'}}
                    avatar={<Avatar size={40} src={`http://localhost:3001/images/avatar/${item.messages[0].sender._id}.jpg`} />}
                    title={<div style={{display:'flex', justifyContent:'space-between'}}>{item.messages[0].sender.firstname} 
                    <div><Badge count={item.type === 'rendez-vous' ? "RDV" : item.type === 'office hours' ? "Office Hours" : item.type.charAt(0).toUpperCase()+item.type.slice(1)} style={{ backgroundColor: '#f94a5648', color: '#f94a56', marginLeft:5 }}/> 
                    {item.type !== 'conseils' ? <Badge count={moment(item.appointmentDate).format('DD-MM-YYYY')} style={{ backgroundColor: 'transparent', borderColor: 'transparent',color: '#0c0fc9e0', fontWeight:'bold'}}/>: null }
                    </div></div>}
                    description={<div>{item.messages[0].text}<br/>
                     {item.messages[0].note && checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) ? <><span style={{textDecoration:'underline'}}>Note à l'équipe:</span> {item.messages[0].note}<br/></> : null}
                     <span style={{fontSize:'smaller'}}>envoyé le {moment(item.messages[0].date).format('DD-MM-YYYY')}</span></div>}
                    />
                </List.Item>
                {i === detailsIndex ? 
                    item.messages.slice(1).map((msg, j)=> {
                    if(msg.text || checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })){
                        return (
                        <List.Item style={{marginTop:'-10px', backgroundColor:'#FAFAFA', borderBottomLeftRadius:8, borderBottomRightRadius:8, padding:10, marginBottom:10}} key={j+i+10}>
                            <List.Item.Meta
                            avatar={<Avatar size={40}  src={`http://localhost:3001/images/avatar/${msg.sender._id}.jpg`} />}
                            title={msg.sender.firstname} 
                            description={<div>{msg.text ? <>{msg.text}<br/></> : null}
                             {msg.note && checkRank(user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) ? <><span style={{textDecoration:'underline'}}>Note à l'équipe:</span> {msg.note}<br/></> : null}
                             <span style={{fontSize:'smaller'}}>envoyé le {moment(msg.date).format('DD-MM-YYYY')}</span></div>}
                            />
                        </List.Item>
                        )
                    }else{
                        return null
                    }})
                    : null}
                </>
            )}
        />
      </div> */}
      </div>
    );
  }
}

function mapStateToProps({
  user,
  batchid,
  batch,
  progression,
  tracks,
  day,
  stackid,
  messages,
  language,
}) {
  return {
    user,
    batchid,
    batch,
    progression,
    tracks,
    day,
    stackid,
    messages,
    language,
  };
}

export default connect(mapStateToProps)(Calendar);

const styles = {
  section: {
    backgroundColor: "white",
    height: "auto",
    maxWidth: 350,
    width: "100%",
  },
  // calendarSpacer: { borderTop: '3px solid #f5f5f5', height: 2, width: '100%' },
  details: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "2px solid #f5f5f5",
    letterSpacing: 0.7,
    fontSize: 13,
    color: "#4f48b2",
    fontWeight: "bold",
    margin: "0px 18px 0px 18px",
    paddingTop: "10px",
  },
  row: {
    padding: "10px 23px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: { marginLeft: 17, textAlign: "left", flex: 2 },
  title: {
    letterSpacing: 0.3,
    fontSize: 14.5,
    color: "#272727",
    fontWeight: "bold",
  },
  titleDisabled: {
    letterSpacing: 0.3,
    fontSize: 14.5,
    color: "#c7c7c7",
    fontWeight: "bold",
    cursor: "not-allowed",
  },
  description: {
    fontSize: 12,
    fontWeight: 300,
    color: "#272727",
    letterSpacing: 0.25,
  },
  detailsSpacer: { border: "0.5px solid #f0f2f5" },
  bottomLine: { margin: "0 18px", borderBottom: "2px solid #f5f5f5" },
};
