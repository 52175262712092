import React from 'react';
import { Icon, Tooltip } from 'antd';

//TRANSLATION________________________
import { useTranslation } from 'react-i18next';

function Orion() {
  const { t } = useTranslation();
  const { link, icon } = styles;
  return (<a href="https://orion.lacapsule.academy/" target="_blank" rel="noopener noreferrer" style={link}>
    <Tooltip title={t("Go to Orion !")}>
      <Icon type="message" style={icon} />
    </Tooltip>
  </a>);
}

export default Orion;

const styles = {
  link: { display: 'flex', alignItems: 'middle' },
  icon: { fontSize: 20, color: '#bcbcbc' }
};
