export default function statusbar(state = 'available', action) {
  if (action.type === 'available') {
    return state = 'available';
  } else if (action.type === 'away') {
    return state = 'away';
  } else if (action.type === 'busy') {
    return state = 'busy';
  } else {
    return state;
  }
}
