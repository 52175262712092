import React, { Component } from 'react';
import { Avatar, Popover, Col, Row } from 'antd';
import { connect } from 'react-redux';

import UserMenu from './UserMenu';

class User extends Component {

  render() {
    const { userRow, avatar, textCenter } = styles;
    const { user, batch, user: { firstname, _id, avatar: hasAvatar }, openDrawer, mobile } = this.props;

    return (
      <Popover content={<UserMenu openDrawer={openDrawer} user={user} batch={batch} />}>
        <Row style={userRow} type="flex">
          <Col>
            <Avatar src={ hasAvatar ? `${global.URI_FRONTEND}/images/avatar/${_id}.jpg` : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`} style={avatar} size={28} shape="square"/>
          </Col>
          {
            !mobile &&
            <Col style={textCenter} >
              {firstname}
            </Col>
          }
        </Row>
      </Popover>
    );
  }
}

function mapStateToProps({ user, batch }) {
  return { user, batch };
}

function mapDispatchToProps(dispatch) {
  return {
    changeBatch: function (data) {
      dispatch({ type: 'tracks', tracks: data.stack.tracks });
      dispatch({ type: 'postprogram', postprogram: data.stack.postprogram });
      dispatch({ type: 'batch', batch: data.batch });
      dispatch({ type: 'stackid', id: data.stack._id });
      dispatch({ type: 'batchid', id: data.batch._id });
      dispatch({ type: 'batchuserlist', batchuserlist: data.batchUserList });
      dispatch({ type: 'progression', progression: data.batch.enabledStep });
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(User);

const styles = {
  userRow: { color: 'rgba(255, 255, 255, 0.85)', margin: 0, cursor: 'pointer', marginLeft: 20, height: '100%', alignItems: 'center' },
  avatar: { margin: '0 10px 0 0', borderRadius: 5 },
  textCenter: { display: 'flex', alignItems: 'center', color: '#898C8F', fontWeight: 600, marginRight: 10, fontFamily: '"Poppins", sans-serif' }
};
