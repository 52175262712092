import React, { Component } from 'react';
import { Row, Col, Avatar, Icon } from 'antd';

export default class TeamSelection extends Component {
  selectStudent = id => {
    const { students } = this.props;

    const current = students.find(e => e._id === id);
    current.selected = !current.selected;

    const sortedStudents = students.slice();

    // console.log('sortedStudents', sortedStudents);

    // this.setState({ students: sortedStudents }, () => {
    const { updateTeam } = this.props;

    updateTeam(sortedStudents);
    // });
  }

  render() {
    const { username, heart } = styles;
    const { students, voted } = this.props;

    const studentList = students.map((e, i) => {
      // console.log(e)
      const hearts = [];
      let checkStyle = { color: "#ffe3e3", fontSize: 22, backgroundColor: 'white', borderRadius: '50%' };

      for (let i = 0; i < 3; i++) {
        let filled = false;

        if (i < e.like) filled = true;

        hearts.push(<Icon key={i} type="heart" theme={filled ? "filled" : "outlined"} style={heart} />);
      }

      if (e.selected) checkStyle.color = '#ff5050';

      const className = e.selected ? "selected-student clickable" : "clickable";

      return <Col key={i} span={24} className={className} onClick={() => this.selectStudent(e._id)}>
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <Row type="flex" align="middle">
              <Col flex="48px" style={{ marginRight: 23 }}>
                <Avatar shape="square" size={48} src={`../images/avatar/${e.student?._id || e.userId}.jpg`} />
              </Col>
              <Col flex="auto" style={username}>{e.fullname || `${e.firstname} ${e.lastname}`}</Col>
            </Row>
          </Col>
          <Col style={{ width: 'auto', display: 'block' }}>
            <Row type="flex" align="middle">
              {
                e.like && <Col flex="auto" className="submitted-projects-hearts__div">
                  {hearts}
                </Col>
              }
              <Col flex="22px" style={{ marginLeft: 27 }}>
                <Icon type="check-circle" theme="filled" style={checkStyle} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    });

    return <Row type="flex" className="submitted-projects__modal">
      {studentList}
    </Row>;
  }
}

const styles = {
  username: { color: '#444444', fontWeight: 'bold', fontSize: 18, marginRight: 10 },
  heart: { color: '#ff3a47', fontSize: 17 }
}