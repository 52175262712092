import React, { Component } from "react";
import CanvasDraw from "react-canvas-draw";
import { Button, Col, Row, notification, message } from "antd";
// import moment from "moment";
import { connect } from "react-redux";
import "./draw.css";
import moment from "moment-timezone";
// TRANSLATION -------------------
import { withTranslation } from "react-i18next";
import { getCurrentTime } from "../functions/currentTime";

message.config({ duration: 3, maxCount: 1 });

class Draw extends Component {
  state = {
    color: "#000000",
    width: 350,
    height: 200,
    brushRadius: 2,
    lazyRadius: 3,
    saveData: "",
    image: "",
    date: moment.utc(),
    duringBatch: null,
    fetching: false,
    officeHoursDay: false,
    todayDate: null, //! Ceci est ajouter par Aboubacar
  };

  componentDidMount = async () => {
    const { isForcedDuringBatch, duringBatch, size = "medium" } = this.props;
    if (!isForcedDuringBatch) {
      this.setState({ duringBatch });
    }

    if (size === "large") {
      this.setState({ height: 300, width: 500 })
    }
  };

  /**
   * @saveData is a JSON stringified data provided by CanvasDraw
   * its length is 37 if the canvas is empty
   */

  clear = canvas => {
    canvas.clear();
    this.setState({ saveData: "", fetching: false });
  };

  send = (student, canvas) => {
    const {
      batch,
      onSend,
      batchId,
      forcedDuringBatch,
      selectedDay,
      refreshDashboardFunc,
    } = this.props;
    const { duringBatch } = this.state;
    const { currentDay, planning } = batch;
    let batchid;
    if (batchId) {
      batchid = batchId;
    } else {
      batchid = batch._id;
    }

    if (selectedDay) {
      var forcedCurrentDay = planning
        .reduce((a, c) => [...a, ...c.days], [])
        .find(day =>
          moment
            .utc(day.date)
            .startOf("day")
            .isSame(moment.utc(selectedDay).startOf("day"))
        );
    }
    
    fetch(
      `${global.URI_BACKEND}/emargement/emargev2/${student._id}/${batchid}`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          period: forcedDuringBatch ? forcedDuringBatch : duringBatch,
          currentDay: selectedDay ? forcedCurrentDay : currentDay,
          signature: canvas.ctx.drawing.canvas.toDataURL("image/webp"),
          force: forcedDuringBatch ? true : false,
        }),
      }
    )
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          notification.success(
            { message: "Emargement", description: data.description },
            5
          );
          this.clear(canvas);
          this.setState({ fetching: false });
          selectedDay && refreshDashboardFunc();
          onSend && onSend();
        } else {
          notification.warn(
            { message: "Emargement", description: data.description },
            5
          );
          this.clear(canvas);
          this.setState({ fetching: false });
        }
      });
  };


  render() {
    const { main, margin10 } = styles;
    const {
      color,
      height,
      width,
      brushRadius,
      lazyRadius,
      saveData,
      date,
      duringBatch,
      fetching,
    } = this.state;
    const { student, newKey, t, onClose, forcedDuringBatch } = this.props;

    const disabled = !(saveData.length > 37 && (duringBatch || forcedDuringBatch));

    return (
      <div style={main}>
        <div className="satisfaction-q1">
          <p>{t("Emargement")}</p>
        </div>

        <div
          className="satisfaction-confirm-text"
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <p>
            {t("Confirmer présence", {
              date: moment.utc(date),
              context: duringBatch,
            })}
          </p>
          {/* <p>Merci de signer pour confirmer ta</p>
        <p>présence  <span style={bold}>{duringBatch === 'morning' ? `de la matinée` : duringBatch === 'afternoon' ? `de l'après-midi` : duringBatch === 'evening' ? `de la soirée` : `à l'office hour`}</span> du <span style={bold}>{moment.utc(date, 'YYYY-MM-DD').format('DD-MM-YYYY')}.</span></p> */}
        </div>

        <div
          onTouchEnd={() =>
            this.setState({ saveData: this.canvas.getSaveData() })
          }
          onMouseUp={() =>
            this.setState({ saveData: this.canvas.getSaveData() })
          }
        >
          <Row type="flex" justify="end">
            <Button
              type="link"
              disabled={disabled}
              onClick={() => this.clear(this.canvas)}
            >
              {t("Effacer")}
            </Button>
          </Row>
          <Row type="flex" justify="center">
            <CanvasDraw
              ref={canvasDraw => (this.canvas = canvasDraw)}
              key={student._id + newKey}
              brushColor={color}
              canvasHeight={height}
              canvasWidth={width}
              brushRadius={brushRadius}
              lazyRadius={lazyRadius}
              saveData={localStorage.getItem("savedDrawing")}
              backgroundColor={"#ddd"}
            />
          </Row>
          <Row style={margin10} type="flex" justify="space-around">
            <Col span={12} align="center" justify="center">
              <Button
                className="colored-button"
                type="primary"
                disabled={disabled || fetching}
                loading={fetching}
                onClick={() => {
                  this.setState({ fetching: true });
                  this.send(student, this.canvas);
                  onClose && onClose();
                }}
              >
                {t("Envoyer")}
              </Button>
            </Col>
          </Row>

          <div
            className="satisfaction-text"
            style={{ paddingLeft: 10, paddingRight: 10, textAlign: "center" }}
          >
            <p>{t("Signature agents de controle")}</p>
            {/* <p>transmis aux financeurs ainsi qu’aux agents de</p>
          <p>contrôle de la formation professionnelle des</p>
          <p>Services régionaux des DIRECCTE.</p> */}
            <br />
            <p>{t("Merci d'apposer ta signature avec soin.")}</p>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    refreshDashboardFunc: function () {
      dispatch({ type: "refresh" });
    },
  };
}

function mapStateToProps({ batch }) {
  return { batchDates: batch.date, batch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Draw));

const styles = {
  main: { padding: "0 20px" },
  margin10: { margin: 10 },
  dateSelector: { margin: 10 },
};
