import React, { Component } from 'react';
import { Menu, Layout, Icon } from 'antd';
import './SiderMenu.css';
import { Link } from 'react-router-dom';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

export default class SiderMenu extends Component {

  state = {
    collapsed: false,
  }

  onCollapse = () => {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  };

  onCollapse2 = () => {
    const { collapsed } = this.state;

    if (collapsed == true) {
      this.setState({ collapsed: !collapsed });
    }
  };

  render() {
    const { sider, menu, menuTitle, text } = styles;
    const { manuelop, defaultSelectedKeys, selectKeys } = this.props;
    const { collapsed } = this.state;

    const manuelopMenu = manuelop?.map(manuel => {
      return <SubMenu key={manuel._id} title={<><Icon onClick={this.onCollapse2} type={manuel.icon} /><span style={menuTitle}>{manuel.title}</span></>}>
        {
          manuel?.subChapters?.map(chapter => {
            const url = chapter.url;

            return <Item key={chapter._id} onClick={() => selectKeys([manuel._id, chapter._id])}>
              <Link to={url} style={text}>{chapter.title}</Link>
            </Item>;
          })
        }
      </SubMenu>
    });

    return (<Sider width={227} collapsible collapsed={collapsed} onCollapse={this.onCollapse} id="career-playbook__menu" theme="light" style={sider}>
      <Menu inlineIndent={10} mode="inline" defaultOpenKeys={defaultSelectedKeys} defaultSelectedKeys={defaultSelectedKeys} style={menu}>
        {manuelopMenu}
      </Menu>
    </Sider>);
  }
}

const styles = {
  sider: { background: '#fff', overflow: 'auto', width: 'auto', left: 0 },
  menu: { height: '100%', borderRight: 0 },
  menuTitle: { fontWeight: 'bold', marginRight: 10 },
  text: { overflow: 'hidden', textOverflow: 'ellipsis' }
};
