import variableDeclarator from '../../variableDeclarator';
import typeChecker from '../../typeChecker';

function forStatement(tc_for = {}, pb_for = {}) {
  const found = { init: false, test: false, update: false, body: false };

  if (!found.init && pb_for.init?.type && tc_for.init?.type === pb_for.init?.type) {
    if (pb_for.init.declarations?.length > 0 && tc_for.init.declarations?.length === pb_for.init.declarations?.length) {
      for (const curTC of tc_for.init.declarations) {
        for (const curPB of pb_for.init.declarations) {
          if (curTC?.type === curPB?.type && curPB?.type === 'VariableDeclarator') {
            found.init = variableDeclarator(curTC.init, curPB.init);
          }
        }
      }
    }
  }

  if (found.init && pb_for.test?.type && tc_for.init?.type === pb_for.init?.type) {
    found.test = typeChecker(tc_for.test, pb_for.test);
  }

  if (found.test && pb_for.update?.type && tc_for.update?.type === pb_for.update?.type) {
    found.update = typeChecker(tc_for.update, pb_for.update);
  }

  if (found.update && pb_for.body?.type && tc_for.body?.type === pb_for.body?.type) {
    found.body = typeChecker(tc_for.body, pb_for.body);
  }

  return Object.values(found).every(e => e === true);
}

export default forStatement;