import React, {Component} from 'react';
import {Row, Col, Layout, Badge, Table} from 'antd';
import {connect} from 'react-redux';
import {Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,} from 'recharts';

import Userpanel from './userpanel';
import Header from '../header';
import SideMenu from '../sidemenu';

import checkRank from '../utils/checkRank';

const {Content} = Layout;

class displayStat extends Component {

constructor(props){
    super(props);
    this.state={
        student:null
    }
}

  componentWillMount(){

      const idStudent = this.props.match.params.studentId
      const studentList = this.props.userList

      console.log('id from studentListStat ',idStudent)

      for(var i = 0; i<studentList.length; i++){
        
        if(idStudent === studentList[i]._id){

            console.log('student -->',studentList[i])

            this.setState({
                student:studentList[i]
            })
        }

      }

  }

  

  render() {

    // RADAR 
    const data = [
        {
          subject: 'CSS', A: 100, B: 110, fullMark: 150,
        },
        {
          subject: 'HTML', A: 90, B: 130, fullMark: 150,
        },
        {
          subject: 'JavaScript', A: 86, B: 130, fullMark: 150,
        },
        {
          subject: 'Front End', A: 76, B: 100, fullMark: 150,
        },
        {
          subject: 'Back End', A: 90, B: 90, fullMark: 150,
        },
        {
          subject: 'API', A: 65, B: 85, fullMark: 150,
        },
      ];

    // Crédits 
    const dataLineCrédits= [
        {
          name: 'S1', Crédits: 5,
        },
        {
          name: 'S2', Crédits: 30,
        },
        {
          name: 'S3', Crédits: 20,
        },
        {
          name: 'S4', Crédits: -27,
        },
        {
          name: 'S5', Crédits: 18,
        },
        {
          name: 'S6', Crédits: 23, 
        },
        {
          name: 'S7', Crédits: -34, 
        },
        {
          name: 'S8', Crédits: 18,
        },
        {
          name: 'S9', Crédits: 23, 
        },
        {
          name: 'S10', Crédits: -2, 
        },
      ];

    // Help Me 
    const dataLineHelpMe= [
      {
        name: 'S1', help: 5,
      },
      {
        name: 'S2', help: 30,
      },
      {
        name: 'S3', help: 20,
      },
      {
        name: 'S4', help: -27,
      },
      {
        name: 'S5', help: 18,
      },
      {
        name: 'S6', help: 23, 
      },
      {
        name: 'S7', help: -34, 
      },
      {
        name: 'S8', help: 18,
      },
      {
        name: 'S9', help: 23, 
      },
      {
        name: 'S10', help: -2, 
      },
    ];

    // Table Quizz
    const columns = [
        {
          title: 'W',
          dataIndex: 'semaine',
        },
        {
          title: 'Q°1',
          dataIndex: 'q_1',
        },
        {
          title: 'Q°2',
          dataIndex: 'q_2',
        },
        {
          title: 'Q°3',
          dataIndex: 'q_3',
        },
        {
          title: 'Q°4',
          dataIndex: 'q_4',
        },
        {
          title: 'Q°5',
          dataIndex: 'q_5',
        }
      ];
    const dataTable = [
        {
          key: '1',
          semaine: '1',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
        {
          key: '2',
          semaine: '2',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
        {
          key: '3',
          semaine: '3',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
        {
          key: '4',
          semaine: '4',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
        {
          key: '5',
          semaine: '5',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
        {
          key: '6',
          semaine: '6',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
        {
          key: '7',
          semaine: '7',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
        {
          key: '8',
          semaine: '8',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
        {
          key: '9',
          semaine: '9',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
        {
          key: '10',
          semaine: '10',
          q_1:'80',
          q_2:'80',
          q_3:'80',
          q_4:'80',
          q_5:'80',
        },
      ];
    
    
      return (
      <div>
        <Header/>
        <Layout style={styles.layout}>
          {
            checkRank(this.props.user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })
              ? <SideMenu disableNavigation="disableNavigation"/>
              : null
}
          <Layout style={styles.background}>
            <Content style={styles.content}>
              <div style={styles.contentBackground}>
                <Row>
                  <Col style={styles.marginTop} offset={1} span={4}>
                    <Userpanel name={this.props.user.firstname}/>
                  </Col>
                  <Col offset={1} span={16}>

                  <Row>
                      <Col style={{marginTop:'154px', marginBottom:'20px'}} >
                      
                      {/* Info Bar : Avatar + Name */}
                      <div style={styles.infoBar}>

                        <img
                          alt={this.state.student._id}
                          style={styles.avatar}
                          src={this.state.student.avatar
                          ? `${global.URI_FRONTEND}/images/avatar/${this.state.student._id}.jpg`
                          : `${global.URI_FRONTEND}/avatar/_male-default.jpg`}
                          onError={(e) => e.target.src = `${global.URI_BACKEND}/avatar/_male-default.jpg`}/>
                        
                        {this.state.student.lastname}  {this.state.student.firstname}

                      </div>
                            
                        {/* CHARTS -------------*/}
                      <div style={{display:'Flex', flexDirection:'row', marginTop:'20px',paddingTop:'20px',paddingBottom:'20px', backgroundColor:'#fff'}}>
                        
                        <div style={{width:"50%",display:'flex',flexDirection:'column', alignItems:'center'}}>
                            <h2>Skill Map</h2>
                            <Badge count={'+80%'} style={styles.quizzResultat} />
                            <RadarChart cx={150} cy={125} outerRadius={75} width={300} height={300} data={data}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="subject" />
                                <PolarRadiusAxis />
                                <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                            </RadarChart>
                        </div>

                        <div style={{width:"50%",textAlign:'center', paddingLeft:'10px', paddingRight:'10px'}}>
                            <h2>Performance Quiz</h2>
                            <Table pagination={false} bordered='true' columns={columns} dataSource={dataTable} size="small" />
                        </div>

                     </div >

                       <div style={{display:'Flex', flexDirection:'row', marginTop:'20px',paddingTop:'20px',paddingBottom:'20px', backgroundColor:'#fff'}}>
                       
                       <LineChart
                                width={800}
                                height={300}
                                data={dataLineCrédits}
                                margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="Crédits" stroke="#82ca9d" />
                        </LineChart>
                       </div>

                       <div style={{display:'Flex', flexDirection:'row', marginTop:'20px',paddingTop:'20px',paddingBottom:'20px', backgroundColor:'#fff'}}>
                       
                       <LineChart
                                width={800}
                                height={300}
                                data={dataLineHelpMe}
                                margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="help" stroke="red" />
                        </LineChart>
                       </div>
                       
                       
                        {/* END CHARTS ------------ */}
                      
                      </Col>
                </Row>

                  </Col>
                </Row>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  };
};

function mapStateToProps(state) {
  return {userList: state.batchuserlist, user: state.user, batch: state.batch};
};

export default connect(mapStateToProps)(displayStat);

const styles = {
  marginTop: {
    marginTop: 154
  },
  layout: {
    minHeight: '100vh'
  },
  userList: {
    backgroundColor: '#fff',
    padding: 15,
    textAlign: 'center',
    marginBottom: 10
  },
  avatar: {
    width: '80%',
    maxWidth: 65,
    borderRadius: '50%',
    margin: '5px 30px 10px 0'
  },
  userInfo: {
    margin: 0,
    fontSize: 13
  },
  ownInfo: {
    margin: 0,
    fontSize: 13,
    color: '#097D8A'
  },
  mainLine: {
    height: 1,
    width: '80%',
    marginLeft: '10%',
    backgroundColor: 'lightGrey',
    marginBottom: 10
  },
  background: {
    backgroundColor: 'white'
  },
  content: {
    minHeight: '79vh',
    maxHeight: '79vh'
  },
  contentBackground: {
    backgroundColor: '#F6F6FA',
    minHeight: '100vh'
  },
  cardBodyStyle: {
    padding: 10
  },
  teacher: {
    fontSize: 11,
    fontStyle: 'italic',
    color: '#EB5D56'
  },
  assistant: {
    fontSize: 11,
    fontStyle: 'italic',
    color: '#097D8A'
  },
  student: {
    fontSize: 11,
    fontStyle: 'italic',
    color: '#808080'
  },
  infoBar:{
    display:'flex', 
    flexDirection:'Row',
    paddingLeft:'10px', 
    alignItems:'center', 
    backgroundColor:'#fff',
    fontSize:20,
    fontWeight:'bold',
    color: '#021528',
    borderRadius:'3px'
  },
  quizzResultat:{
    // backgroundColor:'#9dd5cf', 
    backgroundColor: '#41cc34',
    paddingLeft:'5px', 
    paddingRight:'5px', 
    fontWeight:'bold', 
    fontSize:'15px' 
  },
};
