import React from "react";
import { Text } from "recharts";

//STYLES___________________________
import "../teacherdashboards.css";

const RadarChartLabel = ({
  radius,
  stroke,
  x,
  y,
  textAnchor,
  verticalAnchor,
  payload,
  openModal,
  count,
  ...props
}) => {
  console.log(payload, x, y, textAnchor, verticalAnchor, props)
  let yOffset = y;
  if (((payload.index + 1) * (360 / count)) > 200) yOffset += 25;
  return (
    <Text
      onClick={() => openModal(payload.value)}
      radius={radius}
      stroke={stroke}
      fontFamily="Poppins"
      fontWeight="600"
      fontSize="11.5px"
      fill="#898C8F"
      width={50}
      x={x}
      y={yOffset}
      dy={textAnchor === "middle" ? "0.4em" : 0}
      className="recharts-text recharts-polar-angle-axis-tick-value module-title"
      textAnchor={textAnchor}
    >
      {payload.value}
    </Text>
  );
};

export default RadarChartLabel;
