import React, { Component } from 'react';
import { Form, Input, Icon, Button, Row } from 'antd';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

let id = 0;

const { Item } = Form;

class DynamicFieldSet extends Component {
  state = {
    repositories: []
  }

  componentDidMount() {
    const { form, repositories = [] } = this.props;

    const repos = repositories.map(e => e.url);
    const keys = repositories.map((e, i) => i);

    id = repos.length;

    form.setFieldsValue({ keys });
    this.setState({ repositories: repos });
  }

  remove = k => {
    const { form: { getFieldValue, setFieldsValue } } = this.props;
    const { repositories } = this.state;
    const keys = getFieldValue('keys');

    const newKeys = keys.filter(key => key !== k);
    repositories.splice(k, 1);

    setFieldsValue({ keys: newKeys });
    this.setState({ repositories });
  }

  add = () => {
    const { form: { getFieldValue, setFieldsValue } } = this.props;
    const keys = getFieldValue('keys');
    const nextKeys = keys.concat(id++);

    setFieldsValue({ keys: nextKeys });
  }

  handleSubmit = e => {
    const { form: { validateFields, setFieldsValue }, setStateInParent } = this.props;

    validateFields((error, values) => {
      if (error) return;

      let { keys, repos = [] } = values;
      const { user: { firstname, lastname, _id }, batchid, projectId, updateId } = this.props;

      repos = repos.filter(e => e);
      keys = repos.map((_, i) => i);

      const myself = { firstname, lastname, userId: _id };
      const dataToSend = projectId ? { repositories: keys.map(k => ({ title: null, url: repos[k] })) } : { repositories: keys.map(k => ({ title: null, url: repos[k] })), myself, batchid };

      fetch(`${global.URI_BACKEND}/project/${projectId || 'new'}/repositories/repositories`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend)
      }).then(res => res.json()).then(data => {
        if (!data?.error) {
          const { repositories, _id } = data;

          setFieldsValue({ repos, keys });
          setStateInParent({ repositories, editRepositories: false });
          updateId(_id);
          this.setState({ repositories: repos });
        }
      });
    });
  }

  render() {
    const { item, input, button, noData } = styles;
    const { repositories } = this.state;
    const { editRepositories, form: { getFieldDecorator, getFieldValue }, t } = this.props;

    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => <Item style={item} label={''} required={false} key={index}>
      {
        editRepositories
          ? <div>
            {getFieldDecorator(`repos[${index}]`, { validateTrigger: ['onChange', 'onBlur'], initialValue: repositories[index], rules: [{ required: true, message: t("Insérer une url") }] })(
              <Input placeholder={repositories[index]} style={input} />
            )}
            <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.remove(k)} />
          </div>
          : <Row>
            <a href={repositories[index]} target="_blank" rel="noopener noreferrer">{repositories[index]}</a>
          </Row>
      }
    </Item>);

    return (<Form>
      {formItems}
      {
        formItems.length === 0 && <div style={noData}>{t("Aucun répertoire")}</div>
      }
      {
        editRepositories && <Item>
          <Button type="dashed" onClick={this.add} style={button}>
            <Icon type="plus" /> {t("Ajouter un répertoire")}
          </Button>
        </Item>
      }
    </Form>);
  }
}

export default Form.create({ name: 'repository_form_item' })(withTranslation()(DynamicFieldSet));

const styles = {
  item: { margin: 0 },
  input: { width: '60%', marginRight: 8 },
  button: { width: '100%' },
  noData: { color: '#bbb' }
}