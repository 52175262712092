import React, { Component } from 'react';
import { Button, Col, Row, Icon } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class SelectorButtons extends Component {
  state = {}

  componentDidMount() {

    const { stepsList, id } = this.props;

    // Current
    const curr = stepsList.find(e => e.id === id);
    const currPos = stepsList.indexOf(curr);

    // Previous
    const prevPos = currPos > 0 && currPos - 1;
    const prev = stepsList[prevPos];
    
    // Next
    const nextPos = currPos < stepsList.length - 1 && currPos + 1;
    var next = stepsList[nextPos];
    var progress = null;   
    for(let i=0; i<this.props.progression.length; i++) {
      //console.log('I', i)
      //console.log('this.props.progression[i]', this.props.progression[i])
      if(this.props.progression[i].stepId === next.stepId){
        progress = this.props.progression[i];
      }
    }
    if(!progress || !progress.enable) {
      next = false;
    }

    this.setState({ current: curr, previous: prev, next });
  }

  componentDidUpdate = prevProps => {
    const { stepsList,id} = this.props;

    if (prevProps.id !== id) {
      const curr = stepsList.find(e => e.id === id);
      const currPos = stepsList.indexOf(curr);

      // Previous
      const prevPos = currPos > 0 && currPos - 1;
      const prev = stepsList[prevPos];

      // Next
      const nextPos = currPos < stepsList.length - 1 && currPos + 1;
      var next = stepsList[nextPos];
      var progress = null;   
      for(let i=0; i<this.props.progression.length; i++) {
        if(this.props.progression[i].stepId === next.stepId){
          progress = this.props.progression[i];
        }
      }
      if(!progress || !progress.enable) {
        next = false;
      }

      this.setState({ current: curr, previous: prev, next });

    }

  }

  handleSelectStep = step => {
    const { selectStep } = this.props;

    selectStep(step);
  }

  render() {
    const { mb } = styles;
    const { previous, next } = this.state;
   
    return (<Row type="flex" justify="space-between" style={mb}>
      {/* <Col xs={11}>
        <Button type="secondary" size="large" block={true} disabled={!previous} onClick={() => this.handleSelectStep(previous)}>
          <Link to={`/batch/${previous?.type}/${previous?.id}`}>Back</Link>
        </Button>
      </Col> */}
      <Col xs={22}>
        {/*
          (!next)? 
            <Button type="primary" size="large" ghost={true} block={true}>
              <Link to={`/home`}>J'ai tout compris <Icon type="heart" theme='filled'/></Link>
            </Button>
            :
            <Button type="primary" size="large" ghost={true} block={true} onClick={() => this.handleSelectStep(next)}>
              <Link to={`/batch/${next?.type}/${next?.id}`}>J'ai tout compris <Icon type="heart" theme='filled'/></Link>
            </Button>
        */}
      </Col>
    </Row>);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectStep: function (step) {
      dispatch({ type: 'selectStep', step });
    }
  }
}

function mapStateToProps(state) {
  return { 
    stepsList : state.stepsList,
    progression: state.progression
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorButtons);

const styles = {
  mb: { marginBottom: 24 }
};
