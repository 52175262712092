// import getDate from '../utils/getDate';
import moment from 'moment';

export default function day(state = new Date(), action) {
  if (action.type === 'changeDay') {
    if (action.day) return state = action.day;
    else return state = moment();
  } else {
    return state;
  }
}