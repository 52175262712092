import typeChecker from '../../typeChecker';

function expressionStatement(tc_exp = {}, pb_exp = {}) {
  if (tc_exp.type === pb_exp.type && pb_exp.type) {
    return typeChecker(tc_exp, pb_exp);
  }

  return false;
}

export default expressionStatement;