import typeChecker from '../../typeChecker';

function binaryExpression(tc_bin = {}, pb_bin = {}) {
  // 'instanceof' | 'in' | '+' | '-' | '*' | '/' | '%' | '**' | '|' | '^' | '&' | '==' | '!=' | '===' | '!==' | '<' | '>' | '<=' | '<<' | '>>' | '>>>';
  const check = {};

  if (tc_bin.operator) check.operator = false;
  if (tc_bin.left) check.left = false;
  if (tc_bin.right) check.right = false;

  if (pb_bin.operator && tc_bin.operator === pb_bin.operator) {
    check.operator = true;
  }

  if (pb_bin.left?.type && tc_bin.left?.type === pb_bin.left?.type) {
    check.left = typeChecker(tc_bin.left, pb_bin.left);
  }

  if (pb_bin.right?.type && tc_bin.right?.type === pb_bin.right?.type) {
    check.right = typeChecker(tc_bin.right, pb_bin.right);
  }
  return Object.values(check).every(e => e === true);
}

export default binaryExpression;