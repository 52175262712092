import React, {Component} from 'react';
import { Col, Descriptions, Icon, Spin, Avatar, Select, Pagination, Button, Tooltip, Form, Input, Modal} from 'antd';
import { connect } from 'react-redux';
import uid2 from 'uid2';
import { Link } from 'react-router-dom';
import UserInfo from '../UserFile/UserInfo';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

const {Option} = Select;
const { Item } = Form;
const { Search } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}


class AllUsers extends Component {
  state={
    visible:false,
  }

    deleteItem = () => {
        fetch(global.URI_BACKEND + `/user/deleteUser/${this.props.user._id}`, {method:'DELETE'}).then(res => res.json()).then(async data => data.result ? this.props.delUser(this.props.index) : null)
    
    }

    showModal = () => {
      this.setState({visible:true});
    }

    handleCancel = () => {
      this.setState({visible:false});
    }

    userEdit = (user) => {
      this.props.editInParent(user, this.props.index)
    }

  render() {
    const { user } = this.props;

    let batches = 'N/A';
    if(user.batchList[0] && user.batchList[0].name) batches = user.batchList[0].name.replace("Batch ", "");
    if(user.batchList.length > 1){
      for(let i=1; i<user.batchList.length; i++){
        if(user.batchList[i] && user.batchList[i].name) batches += `, ${user.batchList[i].name.replace("Batch ", "")}`;
      }
    }
   
    return (
      <div style={{width: 360, backgroundColor:'white',display:'flex', alignItems:'center', borderRadius:20, padding:20, margin:10, borderBottom:"1px solid #E7E8ED", borderRight:"1px solid #E7E8ED"}}>
        <Descriptions title={<div style={{display:'flex', justifyContent:'space-between'}}><Link to={`/user/${user._id}`}><Avatar src={`${global.URI_FRONTEND}/images/avatar/${user._id}.jpg`} /> {user.fullname}</Link> <div><Icon type="edit" style={{fontSize:19, color:'#F94A56'}} onClick={this.showModal} /><Icon type="close-circle" style={{fontSize:19, color:'#F94A56', marginLeft:25}} onClick={this.deleteItem}/></div></div>}>
          <Descriptions.Item span={1} label="Type">{user.type} </Descriptions.Item>
          <Descriptions.Item span={2} label="Email">{user.email}</Descriptions.Item>
          <Descriptions.Item span={1} label="Batch">{batches}</Descriptions.Item>
        </Descriptions>

        <Modal 
        width={'80vw'}
        bodyStyle={{backgroundColor:'rgb(240, 242, 245)'}}
        title="Modifier cet utilisateur"
        visible={this.state.visible}
        footer={null}
        onCancel={this.handleCancel}>
          <UserInfo editUser={user} parentEdit={this.userEdit}/>
        </Modal>
      </div>
    );
  }
}


class DisplayUsers extends Component {
  state = {
    users: [],
    loadingScreen: true,
    minValue: 0,
    maxValue: 30,
    campus: [],
    currentCampus: 'all',
    visible: false,
    confirmLoading: false,
    userSearch: [],
    searchValue: false,
  };
    
  componentDidMount() {
    fetch(global.URI_BACKEND + `/user/allUsers/${(this.props.currentUser.type === 'manager') ? JSON.stringify(this.props.currentUser.assignedCities) : "null"}`).then(res => res.json()).then(async data => data.result ? this.setState({users: data.users, loadingScreen: false, campus: (data.campus) ? data.campus : []}) : null);
  };

    
  delUser = (i) => {
    let tempUsers = [...this.state.users];
    tempUsers.splice(i,1);
    this.setState({users: tempUsers});
  };

  handleChange = value => {
    if (value <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 30
      });
    } else {
      this.setState({
        minValue: (value - 1)*30,
        maxValue: value * 30
      });
    }
  };

  showModal = () => {
    this.setState({visible: true});
  };

  handleSubmit = (e) => {
    e.preventDefault();
  
    this.setState({ confirmLoading: true}, () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const body = values;

          fetch(global.URI_BACKEND + '/user/v2', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(body)
            }).then(res => res.json()).then(async data => {
              if (data.success) {
               
              let usersTemp = [...this.state.users];
              usersTemp.unshift(data.user); 
              
              this.setState({ visible: false, confirmLoading: false, users: usersTemp});
  
              }
          });
        }
    });
  });
  };
  
  handleCancel = () => {
    this.setState({visible: false});
  };

  userEdit = (user, i) => {
    let tempUsers = [...this.state.users];
    tempUsers[i] = user;
    this.setState({users: tempUsers});
  }

  Search = (value) => {
    const regex = new RegExp(`${value}.*`, 'i');
    this.setState({userSearch: this.state.users.filter(e => e.fullname.search(regex) !== -1)});
  };

  render() {

    const { center, m2, wrap, select } = styles;
    const {users, loadingScreen, minValue, maxValue, campus, currentCampus, visible, confirmLoading, userSearch, searchValue} = this.state;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const { t } = this.props

    const campusError = isFieldTouched('campus') && getFieldError('campus');
    const firstnameError = isFieldTouched('firstname') && getFieldError('firstname');
    const lastnameError = isFieldTouched('lastname') && getFieldError('lastname');
    const emailError = isFieldTouched('email') && getFieldError('email');
    const typeError = isFieldTouched('type') && getFieldError('type');

    const usersToDisplay = searchValue ? userSearch : users;
    const userFilter = usersToDisplay ? usersToDisplay.filter(e => e.campus.find(elem => elem.city === currentCampus)) : [];

    let userList = (currentCampus === 'all') ? usersToDisplay.slice(minValue, maxValue).map((user, i)=> {
        return (<AllUsers key={uid2(5)} index={i} user={user} delUser={this.delUser} editInParent={this.userEdit}/>);
      })
      : userFilter.slice(minValue, maxValue).map((user, i)=> {
        return (<AllUsers key={uid2(5)} index={i} user={user} delUser={this.delUser} editInParent={this.userEdit}/>);
      });

    return (
    <Col span={24} >
      <div style={{display:'flex'}} >
        <Tooltip title={t("Créer un nouvel utilisateur")} placement="right">
          <Button type="primary" ghost shape="circle" onClick={this.showModal} style={{fontSize: 20, margin: 10, marginLeft: 25}}>+</Button>
        </Tooltip>
        {campus.length > 1 && <Select style={{margin:10, width:120, marginLeft:15}} value={currentCampus} onChange={(value)=>this.setState({currentCampus:value})}>
          <Option value='all'>{t("Tous les campus")}</Option>
          {campus.map((campus, i) => {
            return <Option key={i+6} value={campus.campus}>{campus.campus.charAt(0).toUpperCase()+campus.campus.slice(1)}</Option>
            })}
        </Select>}
        <Search placeholder={t("Rechercher les utilisateurs")} style={{margin:10, marginLeft:15, width:250}} allowClear onSearch={(value) => {
                if(value){
                  this.Search(value);
                  this.setState({searchValue:true});
                }else{
                  this.setState({userSearch:[], searchValue:false})
                }}} />
      </div>
      <div style={{...center,minHeight:'100vh'}} >
        <div style={wrap}>
        {userList}
        </div>
      {loadingScreen && <Spin indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />} />}
      {usersToDisplay?.length > 30 && currentCampus === 'all' && <Pagination style={m2} defaultCurrent={1} total={usersToDisplay.length} defaultPageSize={30} onChange={this.handleChange} />}
      {userFilter?.length > 30 && <Pagination style={m2} defaultCurrent={1} total={userFilter.length} defaultPageSize={30} onChange={this.handleChange} />}
      </div>

      {/*Modal create user*/}
      <Modal
        title={t("Créer un nouvel utilisateur")}
        visible={visible}
        onOk={this.handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={this.handleCancel}
        okButtonProps={{disabled:hasErrors(getFieldsError())}}
      >
        <Form style={{...center, flexDirection:'column'}}>

        <div style={{display: 'flex', justifyContent: "space-evenly", alignItems: 'center', width: '100%', flexWrap:'wrap' }}>
          <Item style={{marginBottom:15}} name="firstname" validateStatus={firstnameError ? 'error' : ''} help={firstnameError || ''} >
            {getFieldDecorator('firstname', {
              rules: [{ required: true, message: 'Please enter user first name.' }]
            })(<Input placeholder={t("Prénom")}/> )} 
            </Item> 
            <Item style={{marginBottom:15}} name="lastname" validateStatus={lastnameError ? 'error' : ''} help={lastnameError || ''}>
            {getFieldDecorator('lastname', {
              rules: [{ required: true, message: 'Please enter user last name.' }]
            })(<Input placeholder={t("Nom")}/> )} 
            </Item>
          </div>
          <Item style={{marginBottom:15, width:'80%'}} name="email" validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please enter user email.' }]
            })(<Input placeholder={t("Email")}/> )} 
            </Item>
          <div style={{display: 'flex', justifyContent: "space-evenly", alignItems: 'center', width: '100%', flexWrap:'wrap' }}>
            <Item  style={select} name="type" validateStatus={typeError ? 'error' : ''} help={typeError || ''}>
            {getFieldDecorator('type', {
              rules: [{ required: true, message: 'Please enter user type.' }]
            })( 
            <Select placeholder={t("Sélectionner un type")}>
            <Option value="student">Student</Option>
            <Option value="assistant">Teacher Assistant</Option>
            <Option value="teacher">Teacher</Option>
            <Option value="manager">Manager</Option>
            <Option value="superadmin">Admin</Option>
            </Select> 
            )} 
            </Item>       
            <Item  style={select} name="campus" validateStatus={campusError ? 'error' : ''} help={campusError || ''} >
          {getFieldDecorator('campus', {
            rules: [{ required: true, message: 'Please choose a campus.' }]
          })(<Select placeholder="Campus">
            <Option value={null}>{t("Tous les campus")} (admin only)</Option>
          {campus.map((campus, i) => {
          return <Option key={i+2} value={campus._id}>{campus.campus.charAt(0).toUpperCase()+campus.campus.slice(1)}</Option>
          })}
          </Select> )} 
          </Item> 
          </div>
          
        </Form>
      </Modal>
    </Col>);
  }
}

function mapStateToProps({ user }) {
  return { currentUser: user };
}

const WrappedDisplayUser = Form.create({ name: 'create_user' })(DisplayUsers);
export default connect(mapStateToProps, null)(withTranslation()(WrappedDisplayUser));

const styles = {
    item: {marginBottom:5, width:'88%'},
    select: {width: 120, marginBottom:15},
    center: { display: 'flex',flexDirection:'column',  alignItems: 'center' },
    wrap: { display: 'flex', justifyContent: "space-evenly", alignItems: 'center', flexWrap:'wrap' },
    m2: {marginTop:20, marginBottom:30},
    select: {width: 140, marginBottom:15},
}