import React from 'react'

//STYLES___________________________
import '../teacherdashboards.css'

const BarChartLabelFlashcards = ({ viewBox, color, value, onClick, range, data, name }) => {

    let dayNumber = name

    let flahcardData = data?.find(e => e.name === name)
    let count = range === 'notFinished' ? flahcardData?.notFinished || null : range === 'low' ? flahcardData?.low || null : range === 'medium' ? flahcardData?.medium || null : range === 'high' ? flahcardData?.high || null : null
    return (
        <text
            x={viewBox.x}
            y={viewBox.y}
            dy={12}
            dx={20}
            fontSize='12'
            fontFamily='sans-serif'
            fontWeight={600}
            fill={value === 0 ? `rgba(255, 255, 255, 0)` : color ? color : '#FFFFFF'}
            textAnchor="middle"
            onClick={() => onClick(count, dayNumber, range)}
            className="hover-underline"
        >
            {count}
        </text>
    )
}

export default BarChartLabelFlashcards