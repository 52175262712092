import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Icon, message, Row, Typography } from 'antd';
import moment from 'moment';
import './App.css';
import "./fontello/css/fontello.css"

import Slide from './slide';
// import Classroom from './classroom';
import Ressources from './components/ressources';
import Quizz from './quizz';
import Exercise from './exercise';
import QuizList from './quizList';
import PracticeList from './practice';
import Logout from './logout'
import StudentListStat from './components/studentlistStat';
import StudentStat from './components/studentStat';
import Groups from './components/groups';
import Alumnis from './components/alumnis';
import Batches from './components/batches';
import UserProfile from './components/userProfile';
import Emargement from './components/emargement';

import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';

import user from './reducers/user.reducer';
import tracks from './reducers/tracks.reducer';
import batch from './reducers/batch.reducer';
import batchid from './reducers/batchid.reducer';
import stackid from './reducers/stackid.reducer';
import menucollapsed from './reducers/menucollapsed.reducer';
import menuSelected from './reducers/menuToSelect.reducer';
import isassigned from './reducers/isassigned.reducer';
import selfassigned from './reducers/selfassigned.reducer';
import statusbar from './reducers/statusbar.reducer';
import studentid from './reducers/studentid.reducer';
import teacherid from './reducers/teacherid.reducer';
import studentname from './reducers/studentname.reducer';
import buddies from './reducers/buddies.reducer';
import buddylist from './reducers/buddylist.reducer';
import progression from './reducers/progression.reducer';
import batchuserlist from './reducers/batchuserlist.reducer';
import EditQuiz from './reducers/editQuiz.reducer';
import EditPractice from './reducers/editPractice.reducer';
import codeEditor from './reducers/editor.reducer';
import alumnisId from './reducers/alumnis.reducer';
import day from './reducers/day.reducer';
import language from './reducers/language.reducer';
import firstStep from './reducers/firstStep.reducer';
import stepsList from './reducers/stepsList.reducer';
import selectStep from './reducers/selectStep.reducer';
import nav from './reducers/nav.reducer';
import postProgram from './reducers/postprogram.reducer';
import messages from './reducers/mesages.reducer';
import menu from './reducers/menu.reducer'
import stackType from './reducers/stackType.reducer'
import refreshDashboard from './reducers/refreshDashboard.reducer';
import pageLocked from "./reducers/pageLocked.reducer";

// v3
import Batch from './v3/Batch';
import HelpRequest from './v3/Batch/TeacherDashboards/HelpRequest';
import HelpRequestDashboard from './v3/Batch/TeacherDashboards/HelpRequestDashboard';
import Dashboard from './v3/Dashboard';
// COMMENT : rajouter pour test dashboard 
import Dashboards from './v3/Batch/TeacherDashboards';
import testAce from './v3/testAce';
import Login from './v3/Login';
import Project from './v3/Project';
import Profile from './v3/Profile';
import UserFile from './v3/UserFile';
import PostProgram from './v3/PostProgram';
// import LiveCode from './v3/liveCode';
import Franchised from './v3/Franchised';
import WorkshopSignup from './v3/Login-Workshop/WorkshopSignup';
import WorkshopWaitroom from './v3/Login-Workshop/WorkshopWaitroom';
// import LoginWorkshop from './v3/Login-Workshop/LoginWorkshop';
import Classmates from './v3/Classmates';
import Syllabus from './v3/Syllabus/'
import MVPDashboard from './v3/MVPDashboard';

// Pour linkedin authentification
import { LinkedInCallback } from "react-linkedin-login-oauth2";

const globalReducers = combineReducers({
  user,
  tracks,
  postProgram,
  batch,
  batchid,
  stackid,
  menucollapsed,
  menuSelected,
  isassigned,
  selfassigned,
  statusbar,
  studentid,
  teacherid,
  studentname,
  buddies,
  buddylist,
  progression,
  batchuserlist,
  EditQuiz,
  EditPractice,
  codeEditor,
  alumnisId,
  day,
  language,
  firstStep,
  stepsList,
  selectStep,
  nav,
  messages,
  menu,
  stackType,
  refreshDashboard,
  pageLocked
});




const store = createStore(globalReducers);

function PrivateRoute({ children, ...rest }) {
  return (<Route {...rest} render={({ location, match }) => {
    if (!rest.user._id && !location.pathname.match(/logout/i)) return <Redirect to="/logout" />

    if (rest.pageLocked && !location.pathname.match(/batch\/admin\/emargement/i)) return <Redirect to="/batch/admin/emargement" />
    
    return React.cloneElement(children, { location, match })
    }}
  />);
}

function mapStateToPropsPrivateRoute({ user, pageLocked }) {
  return { user, pageLocked };
}

const PrivateRouteRedux = withRouter(connect(mapStateToPropsPrivateRoute)(PrivateRoute));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

class App extends Component {
  state = {
    loaddata: false,
    defaultMeridiem: moment.utc().isBefore(moment.utc().startOf('day').add(12, 'hours')) ? 'am' : 'pm',
    hasSigned: { am: null, pm: null },
    showStatus: false,
    forceModalDisplay: false,
    windowDimensions: getWindowDimensions()
  }

  handleResize = () => {
    this.setState({ windowDimensions: getWindowDimensions() });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (global.SOCKET) {
      global.SOCKET.off('offline', this.offline);
      global.SOCKET.off('online', this.online);
      global.SOCKET.off('connect', this.reconnect);
    }
  }

  offline = data => {
    const { online } = this.props;
    online(false, data.userId);
  }

  online = data => {
    const { online } = this.props;
    online(true, data.userId);
  }

  newMessage = data => {
    if (data.receiver.includes(this.props.user._id)) {
      if (data.msg) this.props.addMessage(data.msg);
      message.info('Nouveau message reçu.');
    }
  }

  reconnect = (data) => {
    const userId = this.props?.user?._id;
    const batchId = this.props?.batch?._id;
    //console.log("reconnect");
    if (userId && batchId) global.SOCKET.emit('online', { userId, batchId });
  }

  componentDidMount() {
    try {
      window.addEventListener('resize', this.handleResize);
      Notification.requestPermission(function (status) {
        if (Notification.permission !== status) Notification.permission = status;
      });
    } catch (e) {

    }

    const { setPageLocked } = this.props;
    const pageLocked = localStorage.getItem("pageLocked");
    setPageLocked(Boolean(pageLocked));

    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    const batchIdSelected = localStorage.getItem("batchIdSelected");
    if (!email || !password || !batchIdSelected) {
      localStorage.clear();
      this.setState({ loaddata: true }, () => { return; });
    }

    if (email && password && batchIdSelected) {

      fetch(global.URI_BACKEND + '/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, batchIdSelected })
      }).then(res => res.json()).then(data => {
        if (!data.user) {
          localStorage.clear();
          this.setState({ loaddata: true }, () => {
            return;
          })
          return false
        };

        const { user, batches, stack, activeDay } = data;
        data.batches[0].stackType = stack.keyMode;

        const { setDay, setLanguage } = this.props
        activeDay && setDay(activeDay);
        setLanguage(user?.preferredLanguage)

        if (global.SOCKET) global.SOCKET.emit('online', { userId: user._id, batchId: batches[0]._id });
        // STUDENT GROUP FINDER
        let myBuddies = [];
        let found = false;
        let studentPosition;

        for (const e of batches[0].group_history.groupList) {
          // eslint-disable-next-line no-loop-func
          e.forEach((student, i) => {
            if (!found && student.firstname === user.firstname && student.lastname === user.lastname) {
              found = true;
              studentPosition = i;
              return;
            }
          });
          if (found) {
            found = false;
            e.splice(studentPosition, 1);
            myBuddies = e;
          }
        }

        data.batches[0].stackType = stack.keyMode;

        let dataBatchTemp = { ...data };
        delete dataBatchTemp.batchList;
        this.props.importDatas(dataBatchTemp, myBuddies);
        this.setState({ loaddata: true });
      });
    } else {
      this.setState({ loaddata: true });
    }

    if (global.SOCKET) {
      global.SOCKET.on('offline', this.offline);
      global.SOCKET.on('online', this.online);
      //global.SOCKET.on('reconnect', this.reconnect);
      global.SOCKET.on('connect', () => { console.log("connect"); this.reconnect() });

      global.SOCKET.on('disconnect', (reason) => { console.log('disconnect', reason) });
      global.SOCKET.io.on("reconnect", (attempt) => { console.log('reconnect') });
      global.SOCKET.on("connect_error", (error) => { console.log(error) });

      global.SOCKET.on('newMessage', this.newMessage);
      global.SOCKET.on('test', (e) => { console.log(e) });
    }
  }

  render() {
    const { loaddata, windowDimensions } = this.state;
    const minWidth = 700;

    return (<div style={{ height: '100%' }}>
      {(windowDimensions.width < minWidth) &&
        <Row style={styles.overlay}>
          <Icon type="desktop" theme="outlined" style={styles.desktopIcon} />
          <Typography.Title style={styles.Title}>Votre fenêtre est trop petite.</Typography.Title>
          <p>Elargissez votre fenêtre à plus de {minWidth} pixels pour accéder aux ressources.</p>
        </Row>}
      {
        loaddata
          ? <Router>
            <Switch>
              <Route exact path="/linkedin" component={LinkedInCallback} />

              <Route exact path='/' component={Login} />

              <Route exact path='/workshop/:campus?' component={WorkshopSignup} />

              <Route exact path='/workshops/waiting' component={WorkshopWaitroom} />

              <Route exact path="/login/:addBatchId?" component={Login} />

              <PrivateRouteRedux path="/home/:menu">
                <Dashboard />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/profile/:id([0-9a-f]{24})?">
                <Profile />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/batch/:type(slide|quizz|exercise|admin|workshop|flashcard)/:id([0-9a-f]{24}|helprequest|courses|buddies|feedback|emargement|batchedit|campusedit|crm|users)?">
                <Batch />
              </PrivateRouteRedux>

              {/* <PrivateRouteRedux path="/stack/:stack/classroom">
                <Classroom />
              </PrivateRouteRedux> */}

              <PrivateRouteRedux path="/stack/:stack/ressources">
                <Ressources />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/slide">
                <Slide />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/quizz">
                <Quizz />
              </PrivateRouteRedux>

              {/* <PrivateRouteRedux path="/exercise">
                <Exercise />
              </PrivateRouteRedux> */}

              {/* <PrivateRouteRedux path="/quizList">
                <QuizList />
              </PrivateRouteRedux> */}

              <PrivateRouteRedux path="/editQuiz/:quizID">
                <editQuiz />
              </PrivateRouteRedux>

              {/* <PrivateRouteRedux path="/practice">
                <PracticeList />
              </PrivateRouteRedux> */}

              <PrivateRouteRedux path="/editPractice/:practiceID">
                <editPractice />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/editSlide">
                <editSlide />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/studentList/:mode">
                <Classmates />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/studentListStat">
                <StudentListStat />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/studentStat/:studentId">
                <StudentStat />
              </PrivateRouteRedux>

              {/* <PrivateRouteRedux path="/emargement">
                <Emargement />
              </PrivateRouteRedux> */}

              <PrivateRouteRedux path="/project/:pid([0-9a-f]{24})?">
                <Project />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/Project-List">
                <projectList />
              </PrivateRouteRedux>

              {/* <PrivateRouteRedux path="/groups">
                <Groups />
              </PrivateRouteRedux> */}

              <PrivateRouteRedux path="/HelpRequest">
                <HelpRequest />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/HelpRequestDashboard">
                <HelpRequestDashboard />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/batches">
                <Batches />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/alumnis">
                <Alumnis />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/UserProfile">
                <UserProfile />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/user/:id([0-9a-f]{24})?">
                <UserFile />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/post-program/:type?/:id([0-9a-f]{24})?">
                <PostProgram />
              </PrivateRouteRedux>

              <PrivateRouteRedux path="/franchised/:type?/:id([0-9a-f]{24})?">
                <Franchised />
              </PrivateRouteRedux>

              {/* <PrivateRouteRedux path="/live-code/:type?/:id([0-9a-f]{24})?">
                <LiveCode />
              </PrivateRouteRedux> */}

              {/* COMMENT : rajouter pour test dashboard  */}
              <PrivateRouteRedux path="/dashboards/:mode/:id?">
                <Dashboards />
              </PrivateRouteRedux>

              <Route exact path="/test-aceeditor" component={testAce} />

              <PrivateRouteRedux path='/syllabus/:pageType'>
                <Syllabus />
              </PrivateRouteRedux>

              <Route path='/logout' exact component={Logout} />

              <Route path="/projects">
                <MVPDashboard />
              </Route>
              {/* <Route render={() => <Redirect to={{ pathname: "/home/today" }} />} /> */}
            </Switch>
          </Router>
          : <div>
            <Icon type="loading" style={styles.spinLoad} spin />
          </div>
      }
    </div>);
  }
}

function mapStateToProps({ stackid, user, batchid, batch }) {
  return { stackId: stackid, user, batchid, batch };
}

function mapDispatchToProps(dispatch) {
  return {
    online: function (status, userId) {
      dispatch({ type: 'online', status, userId });
    },
    addMessage: function (message) {
      dispatch({ type: 'addMessage', message });
    },
    setLanguage: function (language) {
      dispatch({ type: 'setLanguage', language });
    },
    importDatas: function (data, myBuddies) {
      dispatch({ type: 'tracks', tracks: data.stack.tracks });
      dispatch({ type: 'postprogram', postprogram: data.stack.postprogram });
      dispatch({ type: 'batch', batch: data.batches[0] });
      dispatch({ type: 'stackid', id: data.stack._id });
      dispatch({ type: 'batchid', id: data.batches[0]._id });
      dispatch({ type: 'batchuserlist', batchuserlist: data.batchUserList });
      if (myBuddies) dispatch({ type: 'buddies', buddies: myBuddies });
      dispatch({ type: 'progression', progression: data.batches[0].enabledStep });
      dispatch({ type: 'messages', messages: data.messages });
      dispatch({ type: 'stackType', stackType: { keyMode: data.stack.keyMode, keyStack: data.stack.keyStack } })
      // Warning: the user dispatch must be the last dispatch
      dispatch({ type: 'user', user: data.user });
    },
    setDay: function (day) {
      dispatch({ type: 'changeDay', day });
    },
    setPageLocked: function(bool) {
      dispatch({ type: "togglePageLocked", pageLocked: bool })
    }
  }
}

const AppRedux = connect(mapStateToProps, mapDispatchToProps)(App);

class AppProvider extends Component {
  render() {
    return (<Provider store={store}>
      <AppRedux />
    </Provider>);
  }
}

const styles = {
  spinLoad: { fontSize: 150, marginTop: 20, display: "flex", justifyContent: "center", color: "#fff" },
  bodyModal: { backgroundColor: '#F6F6FA', borderRadius: 10 },
  overlay: {
    position: 'absolute',
    paddingLeft: '10%',
    paddingRight: '10%',
    left: 0,
    top: 0,
    zIndex: 10000,
    opacity: 0.9,
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  desktopIcon: {
    fontSize: '80px',
    color: '#f94a56'
  },
  Title: {
    marginTop: '3%',
    fontSize: 30,
    color: '#FFFFFF',
    textAlign: 'center',
  },
}

export default AppProvider;
