import React, { Component } from 'react';
import { Row, Col, Layout, Icon, Modal, Button } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import hljs from 'highlight.js'
import ReactQuill from 'react-quill';
import './styles/overwritequill.snow.css';
import 'highlight.js/styles/solarized-light.css'

import Lottie from 'react-lottie';

import Header from './header';
import SideMenu from './sidemenu';
import FooterNav from './footernav';
import Spacer from './spacer';

import right from './animations/right.json';
import wrong from './animations/wrong.json';

const { Content } = Layout;

class Quizz extends Component {
  answerId;
  solution = false;
  answers = [];
  score = 0;
  status = "in-progress";
  time = 0;

  state = {
    questionCount: 0,
    correctAnswer: null,
    selectedProposition: null,
    modalVisible: false
  }

  _validated = () => {
    this.answers.push({ questionId: this.getCurrentQuestion()._id, answerId: this.answerId });
    this.updateScore();

    if (!this.nextQuestion()) {
      this.props.nextStep({ scoreInPercent: Math.ceil((this.score / this.getQuestions().length) * 100), score: this.score, answers: this.answers });
    }
  }

  getQuestions() {
    return this.props.data[this.props.position.stepPosition].step.questions;
  }

  getCurrentQuestion() {
    return this.getQuestions()[this.state.questionCount];
  }

  updateScore() {
    if (this.solution === true) this.score++;

    this.solution = false;
  }

  nextQuestion() {
    if (this.state.questionCount + 1 < this.getQuestions().length) {
      this.setState({ questionCount: this.state.questionCount + 1, selectedProposition: null, modalVisible: false });
      return true;
    } else {
      this.status = "finished";
      return false;
    }
  }

  radioClick = (i, solution, id) => {
    this.solution = solution;
    this.answerId = id;
    this.setState({ selectedProposition: i, modalVisible: true });
    if (solution === true) {
      this.setState({ correctAnswer: i });
    }
  }

  closeModal = () => {
    this.setState({ modalVisible: false });
  }

  render() {
    var isRNCP = (this.props.data[this.props.position.stepPosition].stepType_v2 === "rncp") ? true : false;
    const { questionCount, correctAnswer, selectedProposition, modalVisible } = this.state;

    if (this.props.data[this.props.position.stepPosition].step != null) {
      var questions = this.getQuestions();
      var wrappedPropositions = questions[questionCount].propositions.map((p, i) => {
        return (
          <div key={p._id}
            className="ql-readonly"
            onClick={selectedProposition == null ? () => this.radioClick(i, p.solution, p._id) : null}
            style={selectedProposition != null
              ? selectedProposition === i
                ? correctAnswer === i
                  ? !isRNCP ? styles.correctRadioItem : styles.selectedRadioItem
                  : !isRNCP ? styles.incorrectRadioItem : styles.selectedRadioItem
                : styles.radioItem
              : styles.radioItem}
          >

            <ReactQuill
              key={'quiz-proposition-' + this.props.position.stepPosition + '-' + questionCount + '-' + i}
              modules={{
                toolbar: false,
                syntax: {
                  highlight: text => hljs.highlightAuto(text).value
                }
              }}
              defaultValue={p.text}
              readOnly
            />

            <div style={{ marginLeft: 5 }}>
              {!isRNCP && selectedProposition != null
                ? selectedProposition === i
                  ? correctAnswer === i
                    ? <Icon type="check" theme="outlined" style={styles.green} />
                    : <Icon type="close" theme="outlined" style={styles.red} />
                  : null
                : null}
            </div>

          </div>
        )
      })
    }

    return (
      <div className="ql-readonly">
        {this.props.position
          ? <Header title={this.props.data[this.props.position.stepPosition].text} />
          : <Header />
        }
        <Layout style={{ minHeight: '100vh' }}>

          <SideMenu dataSteps={this.props.data} position={this.props.position} jumpToStep={this.props.jumpToStep} />

          {
            modalVisible && !isRNCP && <ModalResponse modalKey={this.props.position.stepPosition + '-' + questionCount} selectedProposition={selectedProposition} questionCount={questionCount} question={questions} modalVisible={modalVisible} _validated={this._validated} closeModal={this.closeModal} />
          }

          <Layout style={{ backgroundColor: 'white' }}>

            <div style={styles.container}>

              <Content style={{ minHeight: '79vh' }}>

                <Spacer vh space={12} />

                <Row>
                  <Col span={22}>
                    <div style={{ fontSize: 16, marginBottom: 40 }}>

                      {`Question ${questionCount + 1}: `}
                      {questions
                        ? <ReactQuill
                          key={'quiz-question-' + this.props.position.stepPosition + '-' + questionCount}
                          modules={{
                            toolbar: false,
                            syntax: {
                              highlight: text => hljs.highlightAuto(text).value
                            }
                          }}
                          defaultValue={questions[questionCount].question} readOnly />
                        : 'abence de données'}

                    </div>
                    <Col span={22}>
                      <div>
                        {wrappedPropositions ? wrappedPropositions : null}
                      </div>
                    </Col>
                  </Col>

                  <Col span={2}>
                    <div style={{ position: 'fixed', bottom: '5px', right: "5%" }} >
                      <FooterNav previousStep={this.props.previousStep} nextStep={this._validated} disableNext={selectedProposition === null} />
                    </div>
                  </Col>
                </Row>

              </Content>
              {/* <FooterNav previousStep={this.props.previousStep} nextStep={this._validated} disableNext={selectedProposition === null}/> */}
            </div>
          </Layout>
        </Layout>
      </div>
    )
  }
}

function ModalResponse({ modalKey, question, questionCount, _validated, modalVisible, selectedProposition, closeModal }) {
  for (const iterator of question[questionCount].propositions) {
    if (iterator.solution) {
      var correctAnswer = iterator.text;
    }
  }

  const optionsAnimationWright = { autoplay: true, loop: false, animationData: right, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } };
  const optionsAnimationWrong = { autoplay: true, loop: false, animationData: wrong, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } };

  return (<Modal
    visible={modalVisible}
    title={<div style={styles.flexCenter}>
      <span>Question n°{questionCount + 1}</span>
    </div>}
    footer={null}
    onCancel={closeModal}
    maskClosable={false}
    bodyStyle={{ fontSize: 16 }}
  >

    <>
      <div style={styles.flexCenter}>
        {
          question[questionCount].propositions[selectedProposition].solution
            ? <Lottie
              options={optionsAnimationWright}
              height={100}
              width={100}
              style={{ margin: 0 }}>
            </Lottie>
            : <Lottie
              options={optionsAnimationWrong}
              height={60}
              width={60}
              style={{ margin: 0 }}>
            </Lottie>
        }
      </div>
      <div style={styles.questionModal}>Question :</div>
      <ReactQuill
        key={'quiz-question-review-' + modalKey}
        modules={{ toolbar: false, syntax: { highlight: text => hljs.highlightAuto(text).value } }}
        defaultValue={question[questionCount].question}
        readOnly />
      <Spacer space={4} />
      <div style={styles.questionModal}>Réponse :</div>
      <ReactQuill
        key={'quiz-response-' + modalKey}
        modules={{ toolbar: false, syntax: { highlight: text => hljs.highlightAuto(text).value } }}
        defaultValue={correctAnswer}
        readOnly />
    </>

    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
      <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#F94A56", color: '#fff' }} onClick={_validated}>
        Next <Icon type="right" />
      </Button>
    </div>
  </Modal>);
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(withRouter(Quizz));

var styles = {
  container: { marginLeft: '2%', marginRight: '2%' },
  containerFooter: { backgroundColor: 'white', bottom: 0, width: '100%', paddingLeft: 0, paddingRight: 0 },
  validateButton: { marginLeft: '-16vh', marginTop: 50, width: 190, height: 42, borderRadius: 5, borderWidth: 2, borderStyle: 'solid', borderColor: '#097D8A', color: '#097D8A', fontSize: 17, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  redColoredText: { fontSize: 18, color: '#EB5D56', cursor: 'pointer' },
  radioItem: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20, marginBottom: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#A5ACB3', borderRadius: 5 },
  selectedRadioItem: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20, marginBottom: 10, borderStyle: 'solid', borderWidth: 3, borderColor: '#595959', borderRadius: 5 },
  correctRadioItem: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20, marginBottom: 10, borderStyle: 'solid', borderWidth: 3, borderColor: '#27ae60', borderRadius: 5 },
  incorrectRadioItem: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20, marginBottom: 10, borderStyle: 'solid', borderWidth: 3, borderColor: '#e74c3c', borderRadius: 5 },
  green: { color: '#27ae60', fontSize: 17 },
  red: { color: '#e74c3c', fontSize: 17 },
  flexCenter: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  questionModal: { fontSize: 16, textDecoration: "underline", display: 'flex', alignItems: 'center' }
}
