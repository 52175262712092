import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import {
  Row,
  Col,
  Layout,
  Avatar,
  Icon,
  Switch,
  Modal
} from 'antd';
import { connect } from 'react-redux';

import Userpanel from './userpanel';
import Header from '../header';

const { Content } = Layout;

class UserProfile extends Component {

  state = {
    selectedUser: null,
    ModalText: 'Content of the modal',
    visible: false,
    confirmLoading: false
  }

  componentWillMount = () => {
    fetch(global.URI_BACKEND + `/user/${this.props.alumnisId.id}/infos`).then(function (response) {
      return response.json();
    }).then(data => {
      console.log('data selected user: ', data);
      if (data.success) {
        this.setState({ selectedUser: data })
      }
    });
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  handleOk = () => {
    this.setState({ ModalText: 'The modal will be closed after two seconds', confirmLoading: true });
    setTimeout(() => {
      this.setState({ visible: false, confirmLoading: false });
    }, 2000);
  }

  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({ visible: false });
  }

  render() {
    const { visible, confirmLoading, ModalText } = this.state;

    return (
      <div>
        {
          this.props.tracks
            ? this.state.selectedUser
              ? <div>
                <Header />
                <Layout style={{ minHeight: '100vh' }}>
                  <Layout style={{ backgroundColor: 'white' }}>
                    <Content style={{ minHeight: '79vh', maxHeight: '79vh' }}>
                      <div style={{ backgroundColor: '#F6F6FA', minHeight: '100vh' }}>
                        <Row>

                          <Col style={styles.userPanel} xs={{ span: 24 }} sm={{ offset: 1, span: 22 }} md={{ offset: 1, span: 4 }}>
                            <Userpanel name={this.props.user.firstname} />
                          </Col>

                          <Col xs={{ span: 24 }} sm={{ offset: 1, span: 22 }} md={{ offset: 1, span: 17 }}>

                            <div style={styles.background}>
                              <Col style={{ marginTop: 154 }}>

                                <div style={styles.profileBackground}></div>
                                <Row>
                                  <Col style={styles.avatar}>
                                    <Avatar size={120} src={`../images/avatar/${this.state.selectedUser.user._id}.jpg`} icon="user" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col style={styles.name}>
                                    {this.state.selectedUser.user.firstname + ' ' + this.state.selectedUser.user.lastname}
                                  </Col>
                                </Row>

                                <Row>
                                  <Col style={styles.batch}>
                                    {
                                      this.state.selectedUser.user.batchList && this.state.selectedUser.user.batchList[0]
                                        ? this.state.selectedUser.user.batchList[0].name
                                          ? this.state.selectedUser.user.batchList[0].name
                                          : "Batch #" + this.state.selectedUser.user.batchList[0].batchNumber
                                        : "Batch unknown"
                                    }
                                  </Col>
                                </Row>

                              </Col>

                              <Row style={{ marginTop: "9vh", fontWeight: "bold", fontSize: "1.3em" }}>
                                <Col offset={1}>
                                  Edit description
                                      <Icon type="edit" onClick={this.showModal} />
                                </Col>
                              </Row>

                              <Row>
                                <Col span={22} offset={1}>
                                  Longos si quaeso post exsudatos placidiora adventans vestri adventans deverti commeatus deverti miretur vestri itinerum pagos itinerum adventans pagos ad labores repente quaeso adventans ductante si mutato adfatim fiducia deverti adventans mutato si mutato ductante
                                    </Col>
                              </Row>

                              <Row style={{ marginTop: "3vh" }}>
                                <Col xs={{ span: 10, offset: 1 }} sm={{ offset: 1, span: 8 }}>
                                  <Row type="flex" align="middle">
                                    <Col span={24} style={{ fontWeight: "bold", fontSize: "1.3em" }}>Infos<Icon type="edit" onClick={this.showModal} /></Col>
                                    <Col style={{ fontWeight: "bold" }} span={24}>Tel: 06 42 42 42 42</Col>
                                    <Col style={{ fontWeight: "bold" }} span={24}>Ville: Paris</Col>
                                    <Col style={{ fontWeight: "bold" }} span={24}>Mail: {this.state.selectedUser.user.email}</Col>
                                  </Row>
                                </Col>

                                <Col xs={{ span: 8, offset: 4 }} sm={{ offset: 6, span: 8 }} style={{ fontWeight: "bold", display: "flex", flexDirection: "column" }}>
                                  <Row>
                                    Find {this.state.selectedUser.user.firstname} on <Icon type="edit" onClick={this.showModal} />
                                  </Row>
                                  <Col style={{ display: "flex" }}>
                                    <div style={styles.icons}>
                                      <Icon style={{ fontSize: "1.5em" }} type="linkedin" />
                                    </div>
                                    <div style={styles.icons}>
                                      <Icon style={{ fontSize: "1.5em" }} type="github" />
                                    </div>
                                    <div style={styles.icons}>
                                      <Icon style={{ fontSize: "1.5em" }} type="phone" />
                                    </div>
                                  </Col>
                                </Col>
                              </Row>

                              <Row style={{ marginTop: "3vh", fontWeight: "bold" }}>
                                <Col offset={1} span={24}>
                                  Freelance ?
                                      <Switch style={{ marginLeft: "1vh" }} checkedChildren="Yes" unCheckedChildren="No" defaultChecked="defaultChecked" />
                                </Col>

                                <Col offset={1} span={24}>
                                  Looking for a job ?
                                      <Switch style={{ marginLeft: "1vh" }} checkedChildren="Yes" unCheckedChildren="No" defaultChecked="defaultChecked" />
                                </Col>

                                <Col offset={1} span={24}>
                                  Entrepreneur ?
                                      <Switch style={{ marginLeft: "1vh" }} checkedChildren="Yes" unCheckedChildren="No" defaultChecked="defaultChecked" />
                                </Col>
                              </Row>

                              <Modal title="Title" visible={visible} onOk={this.handleOk} confirmLoading={confirmLoading} onCancel={this.handleCancel}>
                                <p>{ModalText}</p>
                              </Modal>

                            </div>

                          </Col>
                        </Row>
                      </div>
                    </Content>
                  </Layout>
                </Layout>
              </div>
              : null
            : <Redirect to='/' />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    batch: state.batch,
    alumnisId: state.alumnisId,
    tracks: state.tracks
  }
}

export default connect(mapStateToProps)(UserProfile)

const styles = {
  userPanel: {
    marginTop: 154
  },
  background: {
    backgroundColor: 'white',
    minHeight: '75vh',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)'
  },
  profileBackground: {
    position: 'absolute',
    backgroundImage: "url(../profile_farmer.jpg)",
    height: '30vh',
    minWidth: '100%',
    backgroundSize: 'cover',
    opacity: 0.3
  },
  avatar: {
    textAlign: 'center',
    padding: '15px 15px 0px 15px',
    zIndex: 1000
  },
  name: {
    textAlign: 'center',
    fontSize: '30px'
  },
  batch: {
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 'bold'
  },
  icons: {
    backgroundColor: "rgb(240, 240, 250)",
    borderRadius: 50,
    marginRight: "1vh",
    minHeight: "30px",
    minWidth: "30px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  }
}
