import React, { Component } from "react";
import { message } from "antd";

import Code from "./Practice/Code";

message.config({ maxCount: 1 });

export default class Practice extends Component {
  render() {
    const {
      practice,
      titleKey,
      setProgression,
      type,
      id,
      stepData,
      updatePractice,
      next,
      updateProgress,
    } = this.props;

    return (
      <Code
        id={id}
        practice={practice}
        type={type}
        titleKey={titleKey}
        /* setProgression={setProgression} */ 
        stepData={stepData}
        updatePractice={updatePractice}
        next={next}
        updateProgress={updateProgress}
      />
    );
  }
}
