import React from 'react'

//STYLES___________________________
import '../teacherdashboards.css'

const CustomTick = ({ x, y, stroke, payload, textAnchor, color = "#C4C4C4" }) => {
    return (
        <g>
            <text
            stroke={ stroke }
            fontFamily="Poppins"
            fontWeight="300"
            fontSize="12px"
            fill={ color }
            x={ x } 
            y={ y }
            dy={ textAnchor === 'middle' ? '0.8em' : '0.4em' }
            dx={ textAnchor === 'middle' ? 0 : '-0.2em' }
            textAnchor={ textAnchor }
            >
                <tspan>
                    { payload.value }
                </tspan>
            </text>    


        </g>
    )
}

export default CustomTick