import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import { connect } from 'react-redux';

class Logout extends Component {

  // state = {
  //   logout : false
  // }

  componentWillMount() {
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('batchIdSelected');
    this.props.offline(this.props.user.userId);
    this.props.clearDatas();
    global.SOCKET.emit('logout');
    //this.setState({logout: true});
  }

  render() {
    //let redirect = this.props.user ? <Redirect to={`/`}/> : ;
    return(  <Redirect to={`/`}/> )};
};


function mapStateToProps(state) {
  return {
    user: state.user,
  }
}


function mapDispatchToProps(dispatch) {
  return {
    offline: function(userId){
      dispatch({type: 'online', status: false, userId});
    },
    clearDatas: function(){

      //console.log("data vers redux : ",data);
      dispatch({type: 'tracks', tracks: null});
      dispatch({type: 'batch', batch: null});
      dispatch({type: 'stackid', id: null});
      dispatch({type: 'batchid', id: null});
      dispatch({type: 'batchuserlist', batchuserlist: []});
      dispatch({type: 'buddies', buddies: null});
      dispatch({type: 'progression', progression: null});
      dispatch({ type: 'stackType', stackType: {} })
      // Warning: the user dispatch must be the last dispatch
      dispatch({type: 'user', user: null});
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
