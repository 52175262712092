import React, {Component, Fragment} from 'react';
import {Menu, Icon, Button, Layout} from 'antd';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import TeacherComponent from './teachercomponent';


// const SubMenu = Menu.SubMenu;
const {Sider} = Layout;
const { SubMenu } = Menu;

// var text = '';

class SideMenu extends Component {

  toggle = () => {
    if (this.props.collapsed) {
      this.props.isntCollapsed();
    } else {
      this.props.isCollapsed();
    };
  };

  updateStep = (position, course=null) => {
    console.log('updateStep', position, course);
    this.props.jumpToStep(position, course);
  };

  render() {

    console.log('position', this.props.position);

    if (!this.props.disableNavigation) {
      var classRoomIndex = 0;

   console.log(this.props.position);

   console.log('ARTHUR --->STEP ---> ',this.props.dataSteps)

      var wrappedSteps = this.props.dataSteps.map((step, i) => {

        

        classRoomIndex = i + 2;
        var iconType = '';
        var themeType = '';
        if (step.enable) {
          if (step.stepType === 'slide' && step.stepType_v2 === 'project') {
            iconType = 'build';
            themeType = "outlined";
          } else if (step.stepType === 'slide' && step.stepType_v2 === 'challenge') {
            iconType = 'experiment';
            themeType = "outlined";
          }else if (step.stepType === 'slide') {
            iconType = 'read';
            themeType = "outlined";
          } else if (step.stepType === 'quizz') {
            iconType = 'question-circle';
            themeType = "outlined";
          } else if (step.stepType === 'exercise') {
            iconType = 'code-o';
            themeType = "outlined";
          };
        } else {
          iconType = 'lock';
          themeType = "outlined";
        };

        if(step.stepType === 'exercise') {
          var subPractice = step.step.courses.map((course, y)=>{
            return (
              <Menu.Item
                key={i+'-'+y}
                onClick={(
                  step.enable || this.props.user.type !== 'student')
                  ? () => this.updateStep(i, y)
                  : null}
              >
                <span>{`${i+1}.${y+1}-`}</span> {course.title}
              </Menu.Item>
          )});

          return (<SubMenu
              key={i}

              disabled={(
                  step.enable || this.props.user.type !== 'student')
                  ? null
                  : true}
              title={
                <Fragment>
                  <Icon type={iconType} theme={themeType}/> {
                    !this.props.collapsed
                      ? <span>{`${i + 1}-`}</span>
                      : null
                  }
                  <span>{step.text}</span>
                </Fragment>
              }
             >
             {subPractice}

          </SubMenu>);
        } else {

          return (<Menu.Item
            key={i+'-0'}
            onClick={(
              step.enable || this.props.user.type !== 'student')
              ? () => this.updateStep(i)
              : null}
            // position={i}
            disabled={(
              step.enable || this.props.user.type !== 'student')
              ? null
              : true}>
            <Icon type={iconType} theme={themeType}/> {
              !this.props.collapsed
                ? <span>{`${i + 1}-`}</span>
                : null
            }
            <span>{step.text}</span>
          </Menu.Item>);
       }


      });
    };

    return (<Sider collapsible="collapsible" collapsed={this.props.collapsed} trigger={null} style={styles.marginTop7vh}>
      <Button type="primary" onClick={this.toggle} style={styles.button}>
        <Icon type={this.props.collapsed
            ? 'menu-unfold'
            : 'menu-fold'}/>
      </Button>
      {
        this.props.user.type !== 'student'
          ? <TeacherComponent/>
          : null
      }

      {
        !this.props.disableNavigation
          ? <Menu
            defaultSelectedKeys={[(this.props.position.stepPosition).toString()+'-'+(this.props.position.currentCourse).toString()]}
            // onSelect={(data)=>{this.updateStep(data.item.props.position)}}
            theme="dark" mode="inline">
              {wrappedSteps}
              <Menu.Item key='home'>
                <Link to={`/stack/${this.props.stackId}/classroom`}>
                  <Icon type="home"/>
                  <span>Classroom</span>
                </Link>
              </Menu.Item>
            </Menu>
          : null
      }
    </Sider>);
  };
};

function mapStateToProps(state) {
  return {collapsed: state.menucollapsed, stackId: state.stackid, user: state.user};
};

function mapDispatchToProps(dispatch) {
  return {
    isCollapsed: function() {
      dispatch({type: 'iscollapsed'});
    },
    isntCollapsed: function() {
      dispatch({type: 'isntcollapsed'});
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

const styles = {
  marginTop7vh: {
    marginTop: '7vh'
  },
  button: {
    width: '100%',
    marginTop: 3,
    height: 50
  }
};
