import React, { Component, useState } from 'react';
import './Quizz.css'

import Editor from "react-simple-code-editor";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";

const CodeQuizDisplay = (props) => {
  const [code, setCode] = useState(props.code)
  const styles = {
    editor: {
      width: '100%',
      boxSizing: "border-box",
      fontFamily: '"Dank Mono", "Fira Code", monospace',
      ...theme.plain,
      backgroundColor: "#282C34",
    }
  };

  const onValueChange = (code) => {
    // setCode( code );
  };

  const highlight = (code) => (
    <Highlight {...defaultProps} theme={theme} code={props.code} language="jsx">
      {({ tokens, getLineProps, getTokenProps }) => (
        <>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </>
      )}
    </Highlight>
  );

  return (
    <Editor
      className={'editor-box'}
      value={code}
      onValueChange={onValueChange}
      highlight={highlight}
      padding={10}
      style={styles.editor}
    />
  );
}

export default class Answer extends Component {
  render() {
    const { radioClick, proposition, selectedProposition, correctAnswer, index, questionHasCode, withCode, isSelected, correct, incorrect, reviewMode } = this.props;
    const { answerBody, answerCodeBody } = styles;

    let className = 'answer-frame quizz-answer';

    if (!reviewMode) className += ' answer-frame__hoverable';

    if ((selectedProposition !== null && selectedProposition === index) || isSelected || incorrect) {
      className += ' selected';
    }

    if (reviewMode && ((selectedProposition !== null && selectedProposition === index) || incorrect)) {
      className += ' red';
    }

    if (withCode) className += ' quizz-proposition-code';

    if (correct) className += ' correct'
    // if (incorrect) className += ' red'

    return (
      <div className={className} style={withCode ? answerCodeBody : answerBody} onClick={!reviewMode ? () => radioClick(index, proposition.solution, proposition.id) : null/* : null */}>
        {withCode ? 
          <CodeQuizDisplay code={proposition.text.trim()} /> :
          <table className="noBorder">
            <tbody>
              <tr className={correct ? 'correct-text' : incorrect ? 'red-text' : ''}>{proposition.text}</tr>
            </tbody>
          </table>}
      </div>
    )
  }
}

const styles = {
  answerBody: {
    backgroundColor: '#FFFFFF',
    width: '90%',
    margin: '16px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 20,
    alignItems: 'center'
  },
  answerCodeBody: {
    width: '90%',
    margin: '16px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}