import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

// import { DateTime } from 'luxon';
import moment from 'moment';

const resources = {
  en: {
    translation: {
      // Today.js
      "Welcome": "Welcome",
      "Notions journée": "Today's notions",
      "tonBuddy_one": "Your buddy",
      "tonBuddy_other": "Your buddies",
      "Ton groupe": "Your group",

      // StepHeader.js
      "Synchroniser la ressource": "Sync resource",
      "Étapes": "Steps",
      "Débloquer une ressource": "Unlock resource",
      "Débloquer une solution/vidéo/livecode": "Unlock solution/video/livecode",

      // Header.js
      "Mon batch": "My batch",

      // Nav.js
      "Home": "Home",
      "Syllabus": "Program",
      "Titre RNCP": "Title",
      "Manuel Opérationnel": "Operational Manual",
      "Suivi Batch": "Batch infos",
      "Élèves": "Students",

      // UserMenu.js
      "Mon profil": "Profile",
      "Logout": "Logout",

      // Syllabus.js
      "Comprendre": "Learn",
      "Rechercher une ressource": "Look for a resource...",
      "Chargement": "Loading...",
      "Ressource_zero": "No resource matches your query",
      "Ressource_one": "{{count}} resource found",
      "Ressource_other": "{{count}} resources found",
      "Notions abordées": "Notions covered",

      // SyllabusCategory.js
      "Jour_one": "Day",
      "Jour_other": "Days",
      "Semaine_one": "Week",
      "Semaine_other": "Weeks",

      // StudentDashboard.js
      "Sélectionner une semaine": "Select week",
      "Solutions débloquées": "Unlocked solutions",
      "solutions débloquées": "unlocked solutions",
      "vs": "v",
      "Aujourd'hui": "Today",
      "Mon arbre de compétences": "My competence tree",
      "Mon assiduité": "My assiduity",
      "Mes absences": "My absences",
      "Mon ressenti": "My feeling",
      "Depuis le début du batch": "Since batch start",

      // TeacherDashboard.js
      "Aucune donnée": "No data found for the specified period.",
      "Élève": "Student",
      "Compétences": "Competences",
      "Solutions Ariane": "Solutions",
      "Help requests": "Help requests",
      "Ressenti de la": "Feelings from",
      "Assiduité": "Diligence",
      "Matin": "Morning",
      "Après-midi": "Afternoon",
      "Soir": "Evening",
      "journée": "day",
      "veille": "yesterday",
      "semaine": "week",
      "Tous les élèves": "All students",
      "Score": "Score",
      "Nbr de flashcards": "Flashcards nbr",
      "évaluations": "evaluations",
      "Dernières flashcards": "Last flashcards",
      "Absents": "Absences",
      "Élèves en difficulté": "Students in difficulty",
      "Élèves en avance": "Students in advance",
      "Moyenne du batch": "Batch average",
      "Nombre d'absences": "Number of absences",
      "jour_one": "{{count}} day",
      "jour_other": "{{count}} days",
      "élève_one": "{{count}} student",
      "élève_other": "{{count}} students",
      "élève": "student",

      // Dashboards.js
      "Positionnement": "Positioning",

      // UserInfo.js && StudentDashboard && Dashboards
      "Au batch": "Whole batch",
      "À la semaine": "By week",
      "À la journée": "By day",

      "Evaluation": "Evaluation",
      "Lundi": "Monday",
      "Mardi": "Tuesday",
      "Mercredi": "Wednesday",
      "Jeudi": "Thursday",
      "Vendredi": "Friday",
      "Samedi": "Saturday",
      "Dimanche": "Sunday",
      "Quiz": "Quiz",
      "Exercice": "Exercise",
      "Projet": "Project",
      "Flashcard": "Flashcard",
      "Challenge": "Challenge",
      "Évaluation finale": "Final evaluation",
      "Frontend": "Frontend",
      "Backend": "Backend",
      "Base de données": "Database",
      "Algorithmie": "Algorithmic",
      "Sécurité": "Security",
      "Workflow": "Workflow",
      "S": "W",
      "Compte": "Account",
      "Profil": "Profile",
      "Mes rendez-vous": "My apointments",
      "Note à l'équipe": "Note to team",
      "envoyé le": "sent on",
      "Nouveau message": "New message",
      "Message": "Message",
      "Mes solutions": "My solutions",
      "Depuis le début de la semaine": "Since the beginning of the week",
      "Mes évaluations": "My evaluations",
      "Écrivez votre message": "Write your message",
      "Écrivez un message privé": "Write private message",
      "Envoyer": "Send",
      "Type de message": "Message type",
      "Rendez-vous": "Apointments",
      "Événement": "Event",
      "Conseils": "Tips",
      "Date et heure": "Date and time",

      // HelpRequestHub.js
      "Nombre d'appels": "Help requests",
      "Temps cumulé": "Cumulated time spent with",
      "Temps moyen d'aide ce jour": "Mean help time this day",
      "Nombre de demandes ce jour": "Help requests this day",
      "Nombre de demandes en cours": "Current help requests number",
      "Help request en cours": "Current help requests",

      // HelpRequestDashboard.js
      "Pas de demande": "No current help requests",

      // Positionnement.js
      "Test de logique": "Logic test",
      "Test de rentrée": "Back-to-work test",
      "Entretien": "Interview",
      "Objectif": "Goal",
      "Voir plus": "See more",

      // MVPDashboard.js
      "MVP Soumis": "Submitted MVP",

      // Pitch.js
      "Cible": "Goal",
      "Problématique": "Problematic",
      "Votre solution en deux mots": "Sum up your idea",
      "Son originalité": "Its originality",
      "Url Slides": "Slides URL",
      "Ouvrir Slides": "Open Slides",

      // ProjectCard.js 
      "Modifier l'équipe": "Modify team",
      "Organiser une équipe": "Organize a team",
      "Afficher le projet": "Show project",

      // /Project/Pitch.js 
      "Choix cible projet": "Please specify your project's purpose",
      "Choix problématique projet": "Please specify your project's problematic",
      "Choix solution projet": "Please specify the solution your project provides",
      "Choix originalité projet": "Please specify your project's originality'",
      "Lien incorrect ou manquant": "Missing or incorrect link",

      // Project.js
      "Nom du Projet": "Project name",
      "Pitch": "Pitch",
      "Prototype": "Prototype",
      "Demo": "Demo",

      // Project_Components/Project.js
      "Créer un projet": "Create project",
      "Ouvrir db schema": "Open db schema",
      "Rechercher": "Search",
      "Il n'y a aucun projet dans le": "There is no project in",

      // Database.js
      "myRepository_one": "My repository",
      "myRepository_other": "My repositories",

      // Repository.js
      "Insérer une url": "Please specify a URL or empty the input field.",
      "Aucun répertoire": "No repository linked to this project",
      "Ajouter un répertoire": "Add repository",

      // Demo.js
      "La vidéo de mon projet": "Project video",
      "Lien manquant": "Missing or incorrect presentation link",
      // "demo video": "Video vidéo",

      // Classmates.js
      "Classmates": "Classmates",
      "Équipe": "Team",
      "Alumni": "Alumni",

      // AdminSider.js
      "Buddies": "Buddies",
      "Feedback": "Feedback",
      "Emargement": "Emargement",
      "Gestion des utilisateurs": "Users administration",

      // GroupsList.js
      "Groupes du jour": "Today's groups",
      "Générer de nouveaux groupes": "Generate new groups",
      "Confirmez pour nouveaux groupes...": "Confirm to generate new groups...",

      // Feedback.js
      "Avatar": "Avatar",
      "Nom": "Name",
      "Compréhension générale": "Global understanding",
      "Avancée des projets": "Projects progress",
      "Date": "Date",
      "Nombre de réponses": "Total answers",
      "Compréhension générale du groupe": "Group's global understanding",
      "Moyenne d'avancée des projets": "Average projects progress",
      "Aucune réponse": "No answer this day.",

      "J'ai compris tous les concepts": "I understood all the concepts",
      "Ils ont compris tous les concepts": "They understood all the concepts",
      "J'ai globalement compris les concepts": "I have generally understood the concepts",
      "Ils ont globalement compris les concepts": "They have generally understood the concepts",
      "J'ai moyennement compris les concepts": "I did not completely understand the concepts",
      "Ils ont moyennement compris les concepts": "They did not completely understand the concepts",
      "Je n'ai globalement pas compris les concepts": "Overall, I did not understand the concepts",
      "Ils n'ont globalement pas compris les concepts": "Overall, they did not understand the concepts",
      "Je n'ai rien compris": "I did not understand anything",
      "Ils n'ont rien compris": "They did not understand anything",
      "J'ai commencé les challenges": "I started the challenges",
      "Ils ont commencé les challenges": "They started the challenges",
      "J'ai fini en avance": "I finished early",
      "Ils ont fini en avance": "They finished early",
      "J'ai fini dans les temps": "I finished in time",
      "Ils ont fini dans les temps": "They finished in time",
      "Je n'ai pas fini": "I have not finished",
      "Ils n'ont pas fini": "They have not finished",

      // Emargement.js
      "Sélectionner plusieurs jours": "Select several days",
      "Link": "Link",

      // EditBatch.js
      "Début": "Start",
      "Fin": "End",
      "Adresse": "Address",
      "Staff": "Staff",
      "Prix": "Price",
      "Modifier ce batch": "Modify batch",
      "Ajouter un utilisateur": "Add user",
      "Supprimer ce batch": "Delete batch",
      // "Ajout d'un utilisateur": "Ajout d'un utilisateur",
      "Rechercher les utilisateurs": "Search users",
      "Aucun utilisateur trouvé": "No user found",
      "Ajouter à l'équipe": "Add to staff",
      "Élèves actuels": "Current students",
      "Aucun utilisateur ajouté": "No one assigned",
      "Gestion des teachers": "Teachers management",
      "Équipe actuelle": "Current staff",
      "Rechercher dans l'équipe": "Search staff",
      "Modifier le": "Modify",
      "Sélectionner un état": "Select state",
      "Ouvert": "Open",
      "Fermé": "Closed",
      "Professeur principal": "Professeur principal",
      "matin": "morning",
      "après-midi": "afternoon",
      "soir": "evening",

      "Créer un nouveau batch": "Create batch",
      "Tous les campus": "All campuses",
      "Tous les batchs": "All batches",
      "Batchs passés": "Passed batches",
      "Batchs en cours": "Current batches",
      "Batchs à venir": "Upcoming batches",
      "Toutes les stacks": "All stacks",
      "Selectionner une date de départ": "Select start date",
      "Oui": "Yes",
      "Non": "No",

      // DisplayUsers.js
      "Créer un nouvel utilisateur": "Create new user",
      "Prénom": "First name",
      "Nom": "Last name",
      "Email": "Email",
      "Sélectionner un type": "Select type",

      // Orion.js
      "Go to Orion !": "Go to Orion !",

      // Profile.js
      "Travail": "Job",
      "Travail non renseigné": "No information on work",
      "Statut": "Status",
      "En poste": "In office",
      "En recherche d'emploi": "Looking for a job",
      "Recherche associé": "Looking for an associate",
      "Recherche freelance": "Looking for freelance",
      "Spécialités": "Specialties",
      "Ville": "City",
      "Phone": "Phone",
      "Description": "Description",
      "Enregistrer": "Save",
      "Veuillez attendre quelques secondes": "Please wait a few seconds",
      "vos données sont en cours d'importation": "your data is being imported",
      "Expériences": "Experiences",
      "Mettre à jour mes réseaux": "Update my social networks",

      // Recruitment.js
      "Recrutement": "Recruitment",
      "Services et librairies": "Frameworks",
      "Annuler": "Cancel",
      "BDD": "DB",

      // ImportFromLinkedIn.js
      "Importer depuis LinkedIn": "Click here to import your profile data from LinkedIn. Be careful, this operation might take a few seconds",
      "Importer mes expériences depuis Linkedin": "Import experiences from Linkedin",
      "Je souhaite récupérer les informations du compte suivant": "I would like to import data from this account",
      "Modifier votre URL Linkedin": "If this account isn't yours, please update your LinkedIn account URL in \"Social Netwokrs\" panel",
      "Veuillez saisir l'url de votre profil LinkedIn": "Please enter LinkedIn profile URL",

      // Satisfaction.js
      "Comment s'est passée": "How was your last day?",
      "As-tu bien compris la notion du jour ?": "Did you understand yesterday's concepts?",
      "Ou en étais-tu dans ton projet": "Where were you in the project by the end of the day?",

      // draw.js
      "Signature agents de controle": "Note : this document is likely to be transmitted to the financers as well as to the agents of control of the professional training.",
      "Merci d'apposer ta signature avec soin.": "Please sign carefully.",
      "Confirmer présence_morning": "Please sign to confirm your attendance on {{date, DATE_HUGE}} morning",
      "Confirmer présence_afternoon": "Please sign to confirm your attendance on {{date, DATE_HUGE}} afternoon",
      "Confirmer présence_evening": "Please sign to confirm your attendance on {{date, DATE_HUGE}} evening",
      "Effacer": "Clear",

      // Course.js 
      "J'ai lu cette partie": "I have read this part",
      "J'ai fini cette partie": "I have done this part",
      "Vous devez synchroniser les ressources": "Please sync resources",
      "-- Titre manquant --": "-- Missing title --",
      "Overview": "Overview",
      "Cours": "Course",
      "Instructions": "Instructions",
      "Slides": "Slides",
      "Résumé": "Résumé",
      "Vidéo": "Video",
      "Ressources": "Resources",
      "Utiliser 1 crédit pour afficher la solution": "Use 1 credit to display solution",
      "Un peu d'aide": "Some help",

      // Group.js
      "Ce batch est désormais terminé": "This batch is over...",
      "Les groupes ne peuvent plus être générés": "Groups can no longer be generated.",

      // DashboardNavMenu.js
      "Période": "Period",

      // Experiences.js
      "Encore en poste": "Still on the job",
      "Votre expérience est vide pour le moment": "Your experience is empty for now",
      "Ajouter une expérience": "Add experience",
      "Entreprise": "Company",
      "Icone": "Icon",

      // Code.js
      "Editer test": "Edit syntactic tests (step {{count}})",
      "Editer solution": "Edit solution (step {{count}})",
      "Voir correction": "Display correction (step {{count}})",
      "Effacer solution": "Erase solution (step {{count}})",
      "Afficher solution": "Display solution (step {{count}})",
      "Suivant": "Next",
      "Exercices": "Exercises",
      "Editer le code d'initalisation": "Edit init code",
      "Ajouter un fichier": "Add file",
      "Nom du fichier": "File name",
      "Type de fichier": "File type",
      "Ajouter": "Add",
      "Réduire": "Reduce",
      "Agrandir": "Expand",
      "Sélectionner un affichage": "Select a display type",
      "Modification de correction": "Correction editing",
      "Enregister une solution pour cette step": "Please save a solution for this step before running tests.",
      "Enregistrer le code saisi": "You are about to save your code as the exercise correction file",
      "partie": "part",
      "étape": "step",
      "Aucune solution enregistrée": "No stored solution for this step.",
      "Solution actuelle": "Current solution",
      "Importer la solution enregistrée": "Import saved solution",
      "Test": "Test",
      "Afficher la solution": "Display solution",
      "Débloquer une solution": "You are about to unlock a solution",
      "Tu regardes trop tôt": "If you look at the solution too soon without trying hard enough on your own, you may not progress",
      "Avoir bien assimilé": "To make sure you've got it right, you'll have to do the exercise again tomorrow!",
      "CTRL + ENTRÉE": "CTRL + ENTER",

      // QuizzDisplay.js
      "réponsesCorrectes_one": "{{count}} correct answer",
      "réponsesCorrectes_other": "{{count}} correct answers",
      "réponsesFausses_one": "{{count}} answer to be revised",
      "réponsesFausses_other": "{{count}} answers to be revised",
      "Progression": "Progress",
      "Précédent": "Back",
      "Valider": "Validate",
      "Encore un peu de travail !": "Still a little work to do !",
      "Tu y es presque !": "You're nearly there !",
      "Pas mal !": "Not bad !",
      'Félicitations !': 'Congratulations !',
      "Refaire": "Redo",
      "Étape suivante": "Next step",

      // Flashcard.js
      "Écris ta réponse ici...": "Write your resource here...",
      "Mauvaise réponse": "Bad answer",
      "Bonne réponse": "Good answer",
      "Voir la réponse": "See answer",
      "Veuillez renseigner votre réponse": "Please specify your answer",

      // FranchisedContainer.js
      "Page franchisés": "Franchised container",

      // react-datepicker > MonthSelector & DaysOfWeek
      "Janvier": "January",
      "Février": "February",
      "Mars": "March",
      "Avril": "April",
      "Mai": "May",
      "Juin": "June",
      "Juillet": "July",
      "Août": "August",
      "Septembre": "September",
      "Octobre": "October",
      "Novembre": "November",
      "Décembre": "December",
      "Di": "Su",
      "Lu": "Mo",
      "Ma": "Tu",
      "Me": "We",
      "Je": "Th",
      "Ve": "Fr",
      "Sa": "Sa",

      // AlumniCard.js
      "Freelance": "Freelance",
      "Entrepreneur": "Entrepreneur",
      "Recruteur": "Recruiter",
      "Développeur": "Developer",
      "Project Owner": "Project Owner",
      "Recherche de nouveaux talents": "Looking for talents",
      "Afficher le profil": "Show profile",

      // SyllabusCategory.js
      "Info": "Info",
      "Algorithme": "Algorithm",
      "Frontend": "Frontend",
      "DB": "DB",
      "Déploiement": "Deployment",
      "Review": "Review",
      "Sprint Produit": "Sprint Produit",
      "Demoday": "Demoday",
      // notions
      "Balises HTML": "HTML Tags",
      "positionnement": "Positioning",
      "Expressions rationnelles": "Regular expressions",
      "Portée des variables": "Scope of variables",
      "Hébergement": "Hosting",
      "Routes & requêtes HTTP": "Routes & HTTP requests",
      "Les webservices": "Webservices",
      "Les redirections": "Redirections",
      "Modélisation BDD": "DB Modeling",
      "Requêtes avancées": "Advanced queries",
      "Composant React": "React Components",
      "État React": "React States",
      "Échange Backend": "Backend Exchange",
      "Cycle de vie React": "React Lifecycle",
      "Router React": "React Router",
      "Champs de saisie React": "React input fields",
      "QCM de la semaine": "Weekly quiz",
      "La camera": "Camera",
      "Upload de fichiers": "File upload",
      "Évènements JS": "JS events",
      "Sélecteurs CSS": "CSS Selectors",
      "Flexbox CSS": "Flexbox CSS",
      "Résolution de problèmes": "Problem solving",
      "Les sessions": "Sessions",
      "Intégration API": "API Integration",
      "Identification Problématique": "Problem Identification",
      "Optimisation": "Optimization",
      "Les fonctions": "Functions",
      "Les partials EJS": "EJS partials",
      "Les dates": "Dates",
      "Propriété React": "React properties",
      // title / titles
      "Conception et prototypage d'une application web et mobile": "Design and prototyping of a web and mobile application",
      "Préparation du projet de développement d’une application web & mobile": "Preparation of a web & mobile application development project",
      "Pilotage du développement d’une application web & mobile": "Steering the development of a web & mobile application",
      "Mise en production d’une application Web et Mobile": "Production of a Web and Mobile application",

      // Resource.js
      "Les références": "References",
    }
  },
  fr: {
    translation: {
      // Today.js
      "Welcome": "Bienvenue",
      "Notions journée": "Les notions de la journée",
      "tonBuddy_one": "Ton buddy du jour",
      "tonBuddy_other": "Tes buddies du jour",
      "Ton groupe": "Ton groupe du jour",

      // StepHeader.js
      "Synchroniser la ressource": "Synchroniser la ressource",
      "Étapes": "Étapes",
      "Débloquer une ressource": "Débloquer une ressource",
      "Débloquer une solution/vidéo/livecode": "Débloquer une solution/vidéo/livecode",

      // Header.js
      "Mon batch": "Mon batch",

      // Nav.js
      "Home": "Accueil",
      "Syllabus": "Programme",
      "Titre RNCP": "Titre RNCP",
      "Manuel Opérationnel": "Manuel Opérationnel",
      "Suivi Batch": "Suivi Batch",
      "Élèves": "Élèves",

      // UserMenu.js
      "Mon profil": "Mon profil",
      "Logout": "Se déconnecter",

      // Syllabus.js
      "Comprendre": "Comprendre",
      "Rechercher une ressource": "Rechercher une ressource...",
      "Chargement": "Chargement...",
      "Ressource_zero": "Aucune ressource ne correspond à la recherche",
      "Ressource_one": "{{count}} ressource correspondante",
      "Ressource_other": "{{count}} ressources correspondantes",
      "Notions abordées": "Notions abordées",

      // SyllabusCategory.js
      "Jour_one": "Jour",
      "Jour_other": "Jours",
      "Semaine_one": "Semaine",
      "Semaine_other": "Semaines",

      // StudentDashboard.js
      "Sélectionner une semaine": "Sélectionner une semaine",
      "Solutions débloquées": "Solutions débloquées",
      "solutions débloquées": "solutions débloquées",
      "vs": "vs",
      "Aujourd'hui": "Aujourd'hui",
      "Mon arbre de compétences": "Mon arbre de compétences",
      "Mon assiduité": "Mon assiduité",
      "Mes absences": "Mes absences",
      "Mon ressenti": "Mon ressenti",
      "Depuis le début du batch": "Depuis le début du batch",

      //TeacherDashboard.js
      "Aucune donnée": "Aucune donnée enregistrée pour cette période.",
      "Élève": "Élève",
      "Compétences": "Compétences",
      "Solutions Ariane": "Solutions Ariane",
      "Help requests": "Demandes d'aide",
      "help requests": "demandes d'aide",
      "Ressenti de la": "Ressenti de la",
      "Assiduité": "Assiduité",
      "Matin": "Matin",
      "Après-midi": "Après-midi",
      "Soir": "Soir",
      "journée": "journée",
      "veille": "veille",
      "semaine": "semaine",
      "Tous les élèves": "Tous les élèves",
      "Score": "Score",
      "Nbr de flashcards": "Nbr de flashcards",
      "évaluations": "évaluations",
      "Dernières flashcards": "Dernières flashcards",
      "Absents": "Absents",
      "Élèves en difficulté": "Élèves en difficulté",
      "Élèves en avance": "Élèves en avance",
      "Moyenne du batch": "Moyenne du batch",
      "Nombre d'absences": "Nombre d'absences",
      "jour_one": "{{count}} jour",
      "jour_other": "{{count}} jours",
      "élève_one": "{{count}} élève",
      "élève_other": "{{count}} élèves",
      "élève": "elève",

      // Dashboards.js
      "Positionnement": "Positionnement",

      // UserInfo.js && StudentDashboard && Dashboards
      "Au batch": "Au batch",
      "À la semaine": "À la semaine",
      "À la journée": "À la journée",

      "Evaluation": "Evaluation",
      "Lundi": "Lundi",
      "Mardi": "Mardi",
      "Mercredi": "Mercredi",
      "Jeudi": "Jeudi",
      "Vendredi": "Vendredi",
      "Samedi": "Samedi",
      "Dimanche": "Dimanche",
      "Quiz": "Quiz",
      "Exercice": "Exercice",
      "Projet": "Projet",
      "Flashcard": "Flashcard",
      "Challenge": "Challenge",
      "Évaluation finale": "Évaluation finale",
      "Frontend": "Frontend",
      "Backend": "Backend",
      "Base de données": "Base de données",
      "Algorithmie": "Algorithmie",
      "Sécurité": "Sécurité",
      "Workflow": "Workflow",
      "S": "S",
      "Compte": "Compte",
      "Profil": "Profil",
      "Mes rendez-vous": "Mes rendez-vous",
      "Note à l'équipe": "Note à l'équipe",
      "envoyé le": "envoyé le",
      "Nouveau message": "Nouveau message",
      "Message": "Message",
      "Mes solutions": "Mes solutions",
      "Depuis le début de la semaine": "Depuis le début de la semaine",
      "Mes évaluations": "Mes évaluations",
      "Écrivez votre message": "Écrivez votre message",
      "Écrivez un message privé": "Écrivez un message privé",
      "Envoyer": "Envoyer",
      "Type de message": "Type de message",
      "Rendez-vous": "Rendez-vous",
      "Événement": "Événement",
      "Conseils": "Conseils",
      "Date et heure": "Date et heure",

      // HelpRequestHub.js
      "Nombre d'appels": "Nombre d'appels",
      "Temps cumulé": "Temps cumulé",
      "Temps moyen d'aide ce jour": "Temps moyen d'aide ce jour",
      "Nombre de demandes ce jour": "Nombre de demandes ce jour",
      "Nombre de demandes en cours": "Nombre de demandes en cours",
      "Help request en cours": "Demandes d'aide en cours",

      // HelpRequestDashboard.js
      "Pas de demande": "Aucune demande d'aide en cours",

      // Positionnement.js
      "Test de logique": "Test de logique",
      "Test de rentrée": "Test de rentrée",
      "Entretien": "Entretien",
      "Objectif": "Objectif",
      "Voir plus": "Voir plus",

      // MVPDashboard.js
      "MVP Soumis": "MVP Soumis",

      // Pitch.js
      "Cible": "Cible",
      "Problématique": "Problématique",
      "Votre solution en deux mots": "Votre solution en deux mots",
      "Son originalité": "Son originalité",
      "Url Slides": "URL Slides",
      "Ouvrir Slides": "Ouvrir Slides",

      // ProjectCard.js 
      "Modifier l'équipe": "Modifier l'équipe",
      "Organiser une équipe": "Organiser une équipe",
      "Afficher le projet": "Afficher le projet",

      // /Project/Pitch.js 
      "Choix cible projet": "Veuillez saisir la cible du projet",
      "Choix problématique projet": "Veuillez saisir une problématique à laquelle répond le projet",
      "Choix solution projet": "Veuillez saisir la solution qu'apporte le projet",
      "Choix originalité projet": "Veuillez spécifier l'originalité du projet",
      "Lien incorrect ou manquant": "Lien incorrect ou manquant",

      // Project.js
      "Nom du Projet": "Nom du Projet",
      "Pitch": "Pitch",
      "Prototype": "Prototype",
      "Demo": "Demo",

      // Project_Components/Project.js
      "Créer un projet": "Créer un projet",
      "Ouvrir db schema": "Ouvrir db schema",
      "Rechercher": "Rechercher",
      "Il n'y a aucun projet dans le": "Il n'y a aucun projet dans le",

      // Database.js
      "myRepository_one": "Mon répertoire",
      "myRepository_other": "Mes répertoires",

      // Repository.js
      "Insérer une url": "Veuillez insérer une url ou supprimer le champ de saisie.",
      "Aucun répertoire": "Aucun répertoire n'est associé à ce projet",
      "Ajouter un répertoire": "Ajouter un répertoire",

      // Demo.js
      "La vidéo de mon projet": "La vidéo de mon projet",
      "Lien manquant": "Lien (Vimeo) de présentation incorrect ou manquant",
      // "demo video": "Démo vidéo",

      // Classmates.js
      "Classmates": "Classmates",
      "Équipe": "Équipe",
      "Alumni": "Alumni",

      // AdminSider.js
      "Buddies": "Buddies",
      "Feedback": "Feedback",
      "Emargement": "Emargement",
      "Gestion des utilisateurs": "Gestion des utilisateurs",

      // GroupsList.js
      "Groupes du jour": "Groupes du jour",
      "Générer de nouveaux groupes": "Générer de nouveaux groupes",
      "Confirmez pour nouveaux groupes...": "Confirmez pour générer de nouveaux groupes...",

      // Feedback.js
      "Avatar": "Avatar",
      "Nom": "Nom",
      "Compréhension générale": "Compréhension générale",
      "Avancée des projets": "Avancée des projets",
      "Date": "Date",
      "Nombre de réponses": "Nombre de réponses",
      "Compréhension générale du groupe": "Compréhension générale du groupe",
      "Moyenne d'avancée des projets": "Moyenne d'avancée des projets",
      "Aucune réponse": "Aucune réponse pour ce jour.",

      "J'ai compris tous les concepts": "J'ai compris tous les concepts",
      "Ils ont compris tous les concepts": "Ils ont compris tous les concepts",
      "J'ai globalement compris les concepts": "J'ai globalement compris les concepts",
      "Ils ont globalement compris les concepts": "Ils ont globalement compris les concepts",
      "J'ai moyennement compris les concepts": "J'ai moyennement compris les concepts",
      "Ils ont moyennement compris les concepts": "Ils ont moyennement compris les concepts",
      "Je n'ai globalement pas compris les concepts": "Je n'ai globalement pas compris les concepts",
      "Ils n'ont globalement pas compris les concepts": "Ils n'ont globalement pas compris les concepts",
      "Je n'ai rien compris": "Je n'ai rien compris",
      "Ils n'ont rien compris": "Ils n'ont rien compris",
      "J'ai commencé les challenges": "J'ai commencé les challenges",
      "Ils ont commencé les challenges": "Ils ont commencé les challenges",
      "J'ai fini en avance": "J'ai fini en avance",
      "Ils ont fini en avance": "Ils ont fini en avance",
      "J'ai fini dans les temps": "J'ai fini dans les temps",
      "Ils ont fini dans les temps": "Ils ont fini dans les temps",
      "Je n'ai pas fini": "Je n'ai pas fini",
      "Ils n'ont pas fini": "Ils n'ont pas fini",

      // Emargement.js
      "Sélectionner plusieurs jours": "Sélectionner plusieurs jours",
      "Link": "Lien",

      // EditBatch.js
      "Début": "Début",
      "Fin": "Fin",
      "Adresse": "Adresse",
      "Staff": "Staff",
      "Prix": "Prix",
      "Modifier ce batch": "Modifier ce batch",
      "Ajouter un utilisateur": "Ajouter un utilisateur",
      "Supprimer ce batch": "Supprimer ce batch",
      // "Ajout d'un utilisateur": "Ajout d'un utilisateur",
      "Rechercher les utilisateurs": "Rechercher les utilisateurs",
      "Aucun utilisateur trouvé": "Aucun utilisateur trouvé",
      "Ajouter à l'équipe": "Ajouter à l'équipe",
      "Élèves actuels": "Élèves actuels",
      "Aucun utilisateur ajouté": "Aucun utilisateur ajouté",
      "Gestion des teachers": "Gestion des teachers",
      "Équipe actuelle": "Équipe actuelle",
      "Rechercher dans l'équipe": "Rechercher dans l'équipe",
      "Modifier le": "Modifier le",
      "Sélectionner un état": "Sélectionner un état",
      "Ouvert": "Ouvert",
      "Fermé": "Fermé",
      "Professeur principal": "Professeur principal",
      "morning": "matin",
      "afternoon": "après-midi",
      "evening": "soir",

      "Créer un nouveau batch": "Créer un nouveau batch",
      "Tous les campus": "Tous les campus",
      "Tous les batchs": "Tous les batchs",
      "Batchs passés": "Batchs passés",
      "Batchs en cours": "Batchs en cours",
      "Batchs à venir": "Batchs à venir",
      "Toutes les stacks": "Toutes les stacks",
      "Selectionner une date de départ": "Selectionner une date de début",
      "Oui": "Oui",
      "Non": "Non",

      // DisplayUsers.js
      "Créer un nouvel utilisateur": "Créer un nouvel utilisateur",
      "Prénom": "Prénom",
      "Nom": "Nom",
      "Email": "Email",
      "Sélectionner un type": "Sélectionner un type",

      // Orion.js
      "Go to Orion !": "Aller sur Orion !",

      // Profile.js
      "Travail": "Travail",
      "Travail non renseigné": "Travail non renseigné",
      "Statut": "Statut",
      "En poste": "En poste",
      "En recherche d'emploi": "En recherche d'emploi",
      "Recherche associé": "Recherche associé",
      "Recherche freelance": "Recherche freelance",
      "Spécialités": "Spécialités",
      "Ville": "Ville",
      "Phone": "Téléphone",
      "Description": "Description",
      "Enregistrer": "Enregistrer",
      "Veuillez attendre quelques secondes": "Veuillez attendre quelques secondes",
      "vos données sont en cours d'importation": "vos données sont en cours d'importation",
      "Expériences": "Expériences",
      "Mettre à jour mes réseaux": "Mettre à jour mes réseaux",

      // Recruitment.js
      "Recrutement": "Recrutement",
      "Services et librairies": "Services et librairies",
      "Annuler": "Annuler",
      "BDD": "BDD",

      // ImportFromLinkedIn.js
      "Importer depuis LinkedIn": "Clique ici pour importer ton profil depuis LinkedIn. ATTENTION ! L'opération peut prendre une dizaine de secondes",
      "Importer mes expériences depuis Linkedin": "Importer mes expériences depuis Linkedin",
      "Je souhaite récupérer les informations du compte suivant": "Je souhaite récupérer les informations du compte suivant",
      "Modifier votre URL Linkedin": "Si ce compte n'est pas le votre, veuillez modifier votre URL Linkedin dans l'encart \"Réseaux Sociaux\"",
      "Veuillez saisir l'url de votre profil LinkedIn": "Veuillez saisir l'url de votre profil LinkedIn",

      // Satisfaction.js
      "Comment s'est passée": "Comment s'est passée ta dernière journée ?",
      "As-tu bien compris la notion du jour ?": "As-tu bien compris la notion du jour ?",
      "Ou en étais-tu dans ton projet": "Ou en étais-tu dans ton projet en fin de journée ?",

      // draw.js
      "Signature agents de controle": "Note : ce document est susceptible d’être transmis aux financeurs ainsi qu’aux agents de contrôle de la formation professionnelle des Services régionaux des DIRECCTE.",
      "Merci d'apposer ta signature avec soin.": "Merci d'apposer ta signature avec soin.",
      "Confirmer présence_morning": "Merci de signer pour confirmer ta présence pour la matinée du {{date, DATE_HUGE}}",
      "Confirmer présence_afternoon": "Merci de signer pour confirmer ta présence pour l'après-midi du {{date, DATE_HUGE}}",
      "Confirmer présence_evening": "Merci de signer pour confirmer ta présence pour la soirée du {{date, DATE_HUGE}}",
      "Effacer": "Effacer",

      // Course.js 
      "J'ai lu cette partie": "J'ai lu cette partie",
      "J'ai fini cette partie": "J'ai fini cette partie",
      "Vous devez synchroniser les ressources": "Vous devez synchroniser les ressources",
      "-- Titre manquant --": "-- Titre manquant --",
      "Overview": "Overview",
      "Cours": "Cours",
      "Instructions": "Instructions",
      "Slides": "Slides",
      "Résumé": "Résumé",
      "Vidéo": "Vidéo",
      "Ressources": "Ressources",
      "Débloquer la solution": "Débloquer la solution",
      "Un peu d'aide": "Un peu d'aide",

      // Group.js
      "Ce batch est désormais terminé": "Ce batch est désormais terminé...",
      "Les groupes ne peuvent plus être générés": "Les groupes ne peuvent plus être générés.",

      // DashboardNavMenu.js
      "Période": "Période",

      // Experiences.js
      "Encore en poste": "Encore en poste",
      "Votre expérience est vide pour le moment": "Votre expérience est vide pour le moment",
      "Ajouter une expérience": "Ajouter une expérience",
      "Entreprise": "Entreprise",
      "Icone": "Icone",

      // Code.js
      "Editer test": "Editer les tests syntactiques (étape {{count}})",
      "Editer solution": "Editer la solution (étape {{count}})",
      "Voir correction": "Afficher la correction (étape {{count}})",
      "Effacer solution": "Effacer la solution (étape {{count}})",
      "Afficher solution": "Afficher la solution (étape {{count}})",
      "Suivant": "Suivant",
      "Exercices": "Exercices",
      "Editer le code d'initalisation": "Editer le code d'initalisation",
      "Ajouter un fichier": "Ajouter un fichier",
      "Nom du fichier": "Nom du fichier",
      "Type de fichier": "Type de fichier",
      "Ajouter": "Ajouter",
      "Réduire": "Réduire",
      "Agrandir": "Agrandir",
      "Sélectionner un affichage": "Sélectionner un affichage",
      "Modification de correction": "Modification de correction",
      "Enregister une solution pour cette étape": "Merci d'enregister une solution pour cette étape avant d'effectuer des tests.",
      "Enregistrer le code saisi": "Vous êtes sur le point d'enregistrer le code saisi comme étant la correction de l'exercice",
      "partie": "partie",
      "étape": "étape",
      "Aucune solution enregistrée": "Aucune solution n'est actuellement enregistrée pour cette étape.",
      "Solution actuelle": "Solution actuelle",
      "Importer la solution enregistrée": "Importer la solution enregistrée",
      "Test": "Test",
      "Afficher la solution": "Afficher la solution",
      "Débloquer une solution": "Tu es sur le point de débloquer une solution",
      "Tu regardes trop tôt": "Si tu regardes trop tôt la solution sans avoir suffisamment cherché par toi-même, tu risques de ne pas progresser.",
      "Avoir bien assimilé": "Pour t'assurer d'avoir bien assimilé, il faudra refaire l'exercice demain !",

      // QuizzDisplay.js
      "réponsesCorrectes_one": "{{count}} réponse correcte",
      "réponsesCorrectes_other": "{{count}} réponses correctes",
      "réponsesFausses_one": "{{count}} réponse à revoir",
      "réponsesFausses_other": "{{count}} réponses à revoir",
      "Progression": "Progression",
      "Précédent": "Précédent",
      "Valider": "Valider",
      "Encore un peu de travail !": "Encore un peu de travail !",
      "Tu y es presque !": "Tu y es presque !",
      "Pas mal !": "Pas mal !",
      'Félicitations !': 'Félicitations !',
      "Refaire": "Refaire",
      "Étape suivante": "Étape suivante",

      // Flashcard.js
      "Écris ta réponse ici...": "Écris ta réponse ici...",
      "Mauvaise réponse": "Mauvaise réponse",
      "Bonne réponse": "Bonne réponse",
      "Voir la réponse": "Voir la réponse",
      "Veuillez renseigner votre réponse": "Veuillez renseigner votre réponse",

      // FranchisedContainer.js
      "Page franchisés": "Page franchisés",

      // react-datepicker > MonthSelector & DaysOfWeek
      "Janvier": "Janvier",
      "Février": "Février",
      "Mars": "Mars",
      "Avril": "Avril",
      "Mai": "Mai",
      "Juin": "Juin",
      "Juillet": "Juillet",
      "Août": "Août",
      "Septembre": "Septembre",
      "Octobre": "Octobre",
      "Novembre": "Novembre",
      "Décembre": "Décembre",
      "Di": "Di",
      "Lu": "Lu",
      "Ma": "Ma",
      "Me": "Me",
      "Je": "Je",
      "Ve": "Ve",
      "Sa": "Sa",

      // AlumniCard.js
      "Freelance": "Freelance",
      "Entrepreneur": "Entrepreneur",
      "Recruteur": "Recruteur",
      "Développeur": "Développeur",
      "Project Owner": "Project Owner",
      "Recherche de nouveaux talents": "Recherche de nouveaux talents",
      "Afficher le profil": "Afficher le profil",

      // SyllabusCategory.js
      // titles
      "Info": "Info",
      "Algorithme": "Algorithme",
      "Frontend": "Frontend",
      "DB": "BDD",
      "Déploiement": "Déploiement",
      "Review": "Review",
      "Sprint Produit": "Sprint Produit",
      "Demoday": "Demoday",
      // notions
      "Balises HTML": "Balises HTML",
      "positionnement": "Positionnement",
      "Expressions rationnelles": "Expressions rationnelles",
      "Portée des variables": "Portée des variables",
      "Hébergement": "Hébergement",
      "Routes & requêtes HTTP": "Routes & requêtes HTTP",
      "Les webservices": "Les webservices",
      "Les redirections": "Les redirections",
      "Modélisation BDD": "Modélisation BDD",
      "Requêtes avancées": "Requêtes avancées",
      "Composant React": "Composant React",
      "État React": "État React",
      "Échange Backend": "Échange Backend",
      "Cycle de vie React": "Cycle de vie React",
      "Router React": "Router React",
      "Champs de saisie React": "Champs de saisie React",
      "QCM de la semaine": "QCM de la semaine",
      "La camera": "La caméra",
      "Upload de fichiers": "Upload de fichiers",
      "Évènements JS": "Évènements JS",
      "Sélecteurs CSS": "Sélecteurs CSS",
      "Flexbox CSS": "Flexbox CSS",
      "Résolution de problèmes": "Résolution de problèmes",
      "Les sessions": "Les sessions",
      "Intégration API": "Intégration API",
      "Identification Problématique": "Identification problématique",
      "Optimisation": "Optimisation",
      "Les fonctions": "Les fonctions",
      "Les partials EJS": "Les partials EJS",
      "Les dates": "Les dates",
      "Propriété React": "Propriété React",

      // title / titles
      "Conception et prototypage d'une application web et mobile": "Conception et prototypage d'une application web et mobile",
      "Préparation du projet de développement d’une application web & mobile": "Préparation du projet de développement d’une application web & mobile",
      "Pilotage du développement d’une application web & mobile": "Pilotage du développement d’une application web & mobile",
      "Mise en production d’une application Web et Mobile": "Mise en production d’une application Web et Mobile",

      // Resource.js
      "Les références": "Les références",

    }
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: false/*process.env.REACT_APP_ENV === "development"*/,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

// new usage
i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
  moment.locale(lng)
  return moment(value).format('LL')
});


export default i18n;