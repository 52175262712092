import React, { Component } from "react";
import { Drawer } from "antd";
import Draw from "../../components/draw";
import { connect } from "react-redux";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

class ForceEmargement extends Component {
  state = {
    step: this.props?.step,
    selectedNotion: null,
    selectedProject: null,
    fetching: false,
    closable: false,
    hasSigned: false,
    hasFeeling: this.props?.hasFeeling,
  };

  onClose = () => {
    const { closeDrawer } = this.props;
    closeDrawer();
  };

  render() {
    const { forceVisible, forcedUser, batchId, duringBatch, selectedDay } =
      this.props;

    return (
      <Drawer
        drawerStyle={{ background: "#f7f7f7", minHeight: "100vh" }}
        placement="right"
        onClose={this.onClose}
        visible={forceVisible}
        destroyOnClose={true}
        closable={true}
        maskClosable={true}
        width={650}
        id="satisfaction"
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.85)" }}
      >
        <img
          src="/images/backgrounds/emargement.jpg"
          alt="students smiling"
          className="satisfaction-img"
        />

        <div className="satisfaction-container satisfaction-bg-1">
          <Draw
            newKey={Date.now()}
            emCheck={false}
            student={forcedUser}
            batchId={batchId}
            onSend={null}
            isForcedDuringBatch={true}
            onClose={this.onClose}
            forcedDuringBatch={duringBatch || null}
            selectedDay={selectedDay || null}
          />
        </div>
      </Drawer>
    );
  }
}

function mapStateToProps({ user, batch, batchid, stackid }) {
  return { user, batch, batchid, stackid };
}

export default connect(mapStateToProps)(withTranslation()(ForceEmargement));
