import React, { useState, useEffect } from "react";

//STYLES_____________________________
import { dashboardstyles } from "./dashboardstyles";

//REDUX________________________________
import { connect } from "react-redux";

//UI____________________________
import { Row, Col, Table, Icon, Avatar } from "antd";
import SimpleBar from "simplebar-react";

//MOMENT_____________________________
import moment from "moment";
import "moment/locale/fr";

//COMPONENTS_______________________
import HelpRequestDashboard from "./HelpRequestDashboard";
import DataDisplay from "./components/DataDisplay";

// TRANSLATION -------------------
import { useTranslation } from "react-i18next";

const HelpRequestHub = ({ batch, batchData, studentFeelings, buddyList }) => {
  //STATE HOOKS_________________________________
  const [currentRequests, setCurrentRequests] = useState(0);
  const [dailyStudentRequests, setDailyStudentRequests] = useState([]);
  const [dailyHelperRequests, setDailyHelperRequests] = useState([]);

  const [cumulativeStudentRequests, setCumulativeStudentRequests] = useState(
    []
  );
  const [weeklyStudentRequests, setWeeklyStudentRequests] = useState([]);

  const [dailyStats, setDailyStats] = useState({});

  const [view, setView] = useState("day");

  const { t } = useTranslation();

  useEffect(() => {
    const backHelpStats = data => {
      const individualHelpStats = data[0];
      const dailyGlobalHelpStat = data[1];
      const cumulatedStudentLiveRequests = data[2];
      const cumulatedWeeklyRequestsStats = data[3];

      let helpStatsOfTheDay = {};
      let studentDailyTemp = [];
      let helperDailyTemp = [];

      if (dailyGlobalHelpStat.length !== 0) {
        for (const e of dailyGlobalHelpStat) {
          if (e.date !== undefined) {
            helpStatsOfTheDay = {
              averageDuration: Math.round(e.averageDuration),
              totalMinutes: e.totalMinutes,
              totalRequests: e.totalRequests,
              averageWaitInterval: Math.round(e.averageWaitInterval),
              totalWaitInterval: e.totalWaitInterval,
            };
          }
        }
      }

      if (individualHelpStats.length !== 0) {
        for (const e of individualHelpStats[0].students) {
          if (e?.userType === "student") {
            const studentData = batchData?.find(f => f.student.id === e._id);
            studentDailyTemp.push({
              userId: e.user,
              fullname: e.fullname,
              total_requests: e.total_requests,
              total_minutes: e.total_minutes,
              notionUnderstood: studentData?.feeling,
            });
          } else {
            helperDailyTemp.push(e);
          }
        }
        studentDailyTemp.sort(function (a, b) {
          return b.total_minutes - a.total_minutes;
        });

        helperDailyTemp.sort(function (a, b) {
          return b.total_minutes - a.total_minutes;
        });
      }

      if (cumulatedWeeklyRequestsStats.length !== 0) {
        let cumulatedWeekData = [];
        let week = 1;
        const firstWeek = moment(batch.date.start).week();
        const lastWeek = moment(batch.date.end).week();

        for (let i = firstWeek; i < lastWeek + 1; i++) {
          const checked = cumulatedWeeklyRequestsStats.find(
            e => e.weekNumber === i
          );
          if (checked) {
            cumulatedWeekData.push({
              weekNumber: i,
              week: week,
              averageDuration: checked.averageDuration,
              nbrRequests: checked.totalWeekRequests,
            });
          } else {
            cumulatedWeekData.push({
              weekNumber: i,
              week: week,
              averageDuration: 0,
              nbrRequests: 0,
            });
          }
          week = week + 1;
        }

        const averageDurationChart = [];

        for (const e of cumulatedWeekData) {
          averageDurationChart.push({
            name: `S${e.week}`,
            timeAverage: e.averageDuration,
            nbrRequests: e.nbrRequests,
          });
        }
        setWeeklyStudentRequests(averageDurationChart);
      }

      setDailyStudentRequests(studentDailyTemp);
      setDailyHelperRequests(helperDailyTemp);
      setCumulativeStudentRequests(cumulatedStudentLiveRequests);
      setDailyStats(helpStatsOfTheDay);
    };

    const date = moment().utc();

    fetch(
      `${global.URI_BACKEND}/studentstats/data-from-back?batchId=${batch._id}&date=${date}`
    )
      .then(res => res.json())
      .then(data => {
        if (data?.success) setCurrentRequests(data.currentRequests.length);
      });
    if (global.SOCKET) {
      global.SOCKET.on("backHelpStats", backHelpStats);
    }

    return () => {
      if (global.SOCKET) {
        global.SOCKET.off("backHelpStats", backHelpStats);
      }
    };
  }, [batch._id, batch.date.end, batch.date.start, batchData]);

  //DATA____________________________
  const studentColumns = [
    {
      title: <div
        className="student-label"
        style={{
          ...styles.contentSubtitle,
          textAlign: "center",
          marginBottom: "1vh",
        }}
      >{t("")}</div>,
      dataIndex: "fullname",
      key: "fullname",
      width: '40%',
      align: 'center',
      render: (fullname, data) =>
      (<div style={styles.studentsColumn}>
        <Avatar
          shape="square"
          className="student-avatar"
          size={30}
          style={styles.AvatarStyle}
          src={
            data.userId
              ? `${global.URI_FRONTEND}/images/avatar/${data.userId}.jpg`
              : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`
          }
        />
        {data.notionUnderstood ? (
          <img
            style={{
              height: "30px",
              zIndex: 2,
              marginLeft: "-3vw",
              marginTop: "5vh",
            }}
            src={`/images/emojis/${data.notionUnderstood?.emoji}.png`}
            alt=""
          />
        ) : (
          ""
        )}
        <div style={{ ...styles.courseTitle }} className="student-name-label">
          {fullname}
        </div>
      </div>),
    }, {
      title: <div
        className="student-label"
        style={{
          ...styles.contentSubtitle,
          textAlign: "center",
          marginBottom: "1vh",
        }}
      >
        {t("Nombre d'appels")}{" "}
        <Icon
          style={{ color: "#f0b1b5" }}
          type="question-circle"
        />
      </div>,
      dataIndex: "total_requests",
      align: "center",
      key: "total_requests",
      width: '25%',
      render: total_requests =>
      (<>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: 400 }}>{total_requests}</span>
        </div>
      </>)
    }, {
      title: <div
        className="student-label"
        style={{
          ...styles.contentSubtitle,
          textAlign: "center",
          marginBottom: "1vh",
        }}
      >
        {t("Temps cumulé")}{" "}
        <Icon
          style={{ color: "#f0b1b5" }}
          type="clock-circle"
        />
      </div>,
      dataIndex: "total_minutes",
      align: "center",
      key: "total_minutes",
      width: '35%',
      render: total_minutes =>
      (<>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: 400 }}>
            {total_minutes}
            min
          </span>
        </div>
      </>)
    },
  ]

  const teacherColumns = [
    {
      title: <div
        className="student-label"
        style={{
          ...styles.contentSubtitle,
          textAlign: "center",
          marginBottom: "1vh",
        }}
      >{t("")}</div>,
      dataIndex: "fullname",
      key: "fullname",
      width: '40%',
      align: 'center',
      render: (fullname, data) =>
      (<div style={styles.studentsColumn}>
        <Avatar
          shape="square"
          className="student-avatar"
          size={30}
          style={styles.AvatarStyle}
          src={
            data.user
              ? `${global.URI_FRONTEND}/images/avatar/${data.user}.jpg`
              : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`
          }
        />
        {data.notionUnderstood ? (
          <img
            style={{
              height: "30px",
              zIndex: 2,
              marginLeft: "-3vw",
              marginTop: "5vh",
            }}
            src={`/images/emojis/${data.notionUnderstood?.emoji}.png`}
            alt=""
          />
        ) : (
          ""
        )}
        <div style={{ ...styles.courseTitle }} className="student-name-label">
          {fullname}
        </div>
      </div>),
    }, {
      title: <div
        className="student-label"
        style={{
          ...styles.contentSubtitle,
          textAlign: "center",
          marginBottom: "1vh",
        }}
      >
        {t("Nombre d'appels")}{" "}
        <Icon
          style={{ color: "#f0b1b5" }}
          type="question-circle"
        />
      </div>,
      dataIndex: "total_requests",
      align: "center",
      key: "total_requests",
      width: '25%',
      render: total_requests =>
      (<>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: 400 }}>{total_requests}</span>
        </div>
      </>)
    }, {
      title: <div
        className="student-label"
        style={{
          ...styles.contentSubtitle,
          textAlign: "center",
          marginBottom: "1vh",
        }}
      >
        {t("Temps cumulé")}{" "}
        <Icon
          style={{ color: "#f0b1b5" }}
          type="clock-circle"
        />
      </div>,
      dataIndex: "total_minutes",
      align: "center",
      key: "total_minutes",
      width: '35%',
      render: total_minutes =>
      (<>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: 400 }}>
            {total_minutes}
            min
          </span>
        </div>
      </>)
    },
  ]

  return (
    <>
      {/* <DashboardNavMenu view={ view } setView={ setView } showWeek={ false } /> */}
      {view === "day" && (
        <Row
          style={{ height: "100%", display: "flex", alignItems: "flex-start" }}
        >
          <Col
            span={10}
            offset={1}
            style={{ ...dashboardstyles.contentPanel, paddingLeft: 20 }}
            className="centerTableHead"
          >
            <div style={dashboardstyles.contentTitle}>{t("Élèves")}</div>
            <div style={dashboardstyles.contentBody}>

              <SimpleBar className="tableHeaderCentered" style={{ height: "38vh" }}>
                {dailyStudentRequests.length !== 0 ? (
                  <Table
                    columns={studentColumns}
                    dataSource={dailyStudentRequests}
                    pagination={false}
                    style={{ maxHeight: "100%" }}
                  />) : (
                  <div
                    style={{
                      height: "35vh",
                      marginBottom: "1.5vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>{t("Aucune donnée")}</p>
                  </div>
                )
                }
              </SimpleBar>

            </div>
            <div style={{ ...dashboardstyles.contentTitle, marginTop: 20 }}>
              Teachers
            </div>

            <div style={dashboardstyles.contentBody}>
              <SimpleBar className="tableHeaderCentered" style={{ height: "38vh" }}>
                {dailyHelperRequests.length !== 0 ? (
                  <Table
                    columns={teacherColumns}
                    dataSource={dailyHelperRequests}
                    pagination={false}
                    style={{ maxHeight: "100%" }}
                  />) : (
                  <div
                    style={{
                      height: "35vh",
                      marginBottom: "1.5vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>{t("Aucune donnée")}</p>
                  </div>
                )}
              </SimpleBar>
            </div>
          </Col>

          <Col
            span={12}
            style={{ ...dashboardstyles.contentPanel, padding: "10px 0px" }}
          >
            <Row
              className="data-display-row"
              style={{
                marginBottom: "40px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                width: "100%"
              }}
            >
              <DataDisplay
                title={t("Temps moyen d'attente")}
                data={
                  dailyStats.averageWaitInterval
                    ? `${dailyStats.averageWaitInterval} min`
                    : "NR"
                }
                style={{
                  padding: "10px 20px 0px 0px",
                  width: "20%"
                }}
                insetStyle={{
                  height: "70px"
                }}
              />

              <DataDisplay
                title={t("Temps moyen d'aide")}
                data={
                  dailyStats.averageDuration
                    ? `${dailyStats.averageDuration} min`
                    : "NR"
                }
                style={{
                  padding: "10px 20px 0px 0px",
                  width: "20%"
                }}
                insetStyle={{
                  height: "70px"
                }}
              />

              <DataDisplay
                title={t("Demandes faites aujourd'hui")}
                data={
                  dailyStats.totalRequests ? dailyStats.totalRequests : "NR"
                }
                style={{
                  padding: "0px 10px",
                  width: "20%",
                  minWidth: "100px"
                }}
                insetStyle={{
                  height: "70px"
                }}
              />

              <DataDisplay
                title={t("Demandes en cours")}
                data={currentRequests}
                style={{
                  padding: "10px 0px 0px 20px",
                  width: "20%",
                  minWidth: "100px"
                }}
                insetStyle={{
                  height: "70px"
                }}
              />
            </Row>
            <Row style={{ width: "100%" }}>
              <div style={dashboardstyles.contentTitle}>
                {t("Help requests en cours")}
              </div>
              <div
                style={{
                  ...dashboardstyles.contentBody,
                  height: "calc(100vh - 360px)",
                  backgroundColor: "#F6F7FB",
                  width: "100%",
                }}
              >
                <SimpleBar style={{ height: "calc(100vh - 360px)", width: "100%" }}>
                  <HelpRequestDashboard
                    studentFeeling={studentFeelings}
                    batchData={batchData}
                    buddyList={buddyList}
                    updateRequestCount={setCurrentRequests}
                  />
                </SimpleBar>
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

function mapStateToProps({ batch, buddylist }) {
  return { batch, buddylist };
}

export default connect(mapStateToProps, null)(HelpRequestHub);

const styles = {
  contentSubtitle: {
    letterSpacing: 0.7,
    fontSize: 11,
    lineHeight: '17px',
    color: "#565555",
    fontWeight: 300,
    fontStyle: "italic",
  },
  courseRow: {
    padding: "10px",
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  AvatarStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EBE9FE",
    borderRadius: 6,
    width: 55,
    height: 55,
    marginRight: 10,
  },
  courseTitle: {
    letterSpacing: 0.3,
    fontSize: 14.5,
    lineHeight: '180%',
    color: "#272727",
    fontWeight: "bold",
  },
  studentsColumn: {
    display: 'flex',
    alignItems: 'center',
  }
};
