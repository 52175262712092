import typeChecker from "../../typeChecker"
import isObject from "../../helpers/isObject"

function functionDeclaration(tc = {}, pb = {}) {
    let check = {}
    for (const key in tc) {
        if (Array.isArray(tc[key])) {
            check[key] = tc[key].map(e => pb[key].map(f => typeChecker(e, f)).some(f => f === true)).every(e => e === true)
        } else if (tc[key]?.type) {
            check[key] = typeChecker(tc[key], pb[key])
        } else if (isObject(tc[key])) {
            check[key] = Object.keys(tc[key]).every(e => tc[key][e] === pb[key][e])
        } else {
            if (tc[key] !== pb[key]) console.log(key, tc[key], pb[key])
            check[key] = tc[key] === pb[key]
        }
    }

    return Object.values(check).every(e => e === true)
}

export default functionDeclaration;