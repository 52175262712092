import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux'

import AdminSider from './AdminSider'
import Groups from './Groups';
import Emargement from './Emargement';
import Collapse from './Collapse';
import Feedback from './Feedback';
import EditBatch from './EditBatch';
import EditCampus from './EditCampus';
import CRM from './CRM';
import DisplayUsers from './DisplayUsers';
import HelpRequest from '../Batch/TeacherDashboards/HelpRequest';

const { Content } = Layout

function Admin({ user, showHideHeader }) {
  //PARAMS__________________________
  const { id } = useParams()

  //STATE HOOKS__________________________
  const [keys, setKeys] = useState([id])
  //show side bar or not
  const [sideBarIsVisible, setSideBarIsVisible] = useState(true);

  useEffect(() => {
    const pageLocked = localStorage.getItem('pageLocked')
    if (pageLocked) {
      showHideNavigation()
    }
  }, []);

  //FUNCTIONS___________________
  //hide or show sidebar
  const showHideSideBar = () => {
    setSideBarIsVisible(!sideBarIsVisible)
  }
  //show or hide Sidebar and Header
  const showHideNavigation = () => {
    showHideHeader()
    showHideSideBar()
  }

  let contentToDisplay;

  switch (id) {
    case 'courses':
      contentToDisplay = <Collapse />;
      break;
    case 'buddies':
      contentToDisplay = <Groups />;
      break;
    case 'emargement':
      contentToDisplay = <Emargement navigationIsVisible={sideBarIsVisible} showHideNavigation={showHideNavigation} />;
      break;
    case 'helprequest':
      contentToDisplay = <HelpRequest />;
      break;
    case 'feedback':
      contentToDisplay = <Feedback />;
      break;
    case 'batchedit':
      contentToDisplay = <EditBatch />;
      break;
    case 'campusedit':
      contentToDisplay = <EditCampus />;
      break;
    case 'crm':
      contentToDisplay = <CRM />;
      break;
    case 'users':
      contentToDisplay = <DisplayUsers />;
      break;
    default:
      contentToDisplay = <Redirect to='/' />;
      break;
  }

  return user.type === 'student' || user.type === 'assistant' ?
    <Redirect to="/home/today" />
    : (
      <>
        <Layout className="hide-scrollbar" style={{ backgroundColor: '#F6F7FB', flex: 1, overflow: 'scroll' }}>
          {sideBarIsVisible && <AdminSider keys={keys} setKeys={setKeys} />}
          <Content style={{ backgroundColor: 'rgb(240, 242, 245)' }}>
            {contentToDisplay}
          </Content>
        </Layout>
      </>
    )
}

function mapStateToProps({ user }) {
  return { user }
}
export default connect(mapStateToProps, null)(Admin)