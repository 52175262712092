import React, { Component } from 'react';
import { Col, Row, Form, Input, Button, Icon, Menu, Dropdown, Badge } from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import checkRank from '../../utils/checkRank';
import sortBatches from '../../utils/sortBatches';
import ReactGA from 'react-ga';
import SimpleBar from 'simplebar-react'
import "./login.css"

ReactGA.initialize('UA-84143680-1');

const { Item } = Form;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class LoginForm extends Component {
  state = {
    workshopNotStarted: false,
    logError: '',
    chooseBatch: false,
    batchList: [],
    batch: "Choose Batch",
    batchIdSelected: null,
    endpoint: global.URI_BACKEND,
    loading: false
  }

  dataBatchTeacher;

  //Push to redux the choosen batch
  handleBatchList = (e) => {
    const { batchList } = this.state;
    this.setState({
      batch: e.item.props.children,
      batchIdSelected: batchList[e.key].batchId || batchList[e.key]._id
    });
  }

  login = (url, body) => {

    fetch(global.URI_BACKEND + url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }).then(res => res.json()).then(async data => {

      if (data.success) {
        const { user, batches, stack } = data;
        const { setDay, setLanguage } = this.props

        localStorage.setItem("email", body.email);
        localStorage.setItem("password", body.password);

        if (this.state.batchIdSelected) localStorage.setItem("batchIdSelected", this.state.batchIdSelected);
        // else if (url === '/login/git-login') localStorage.setItem("batchIdSelected", batches[batches.length - 1]._id);

        if (stack) data.batches[0].stackType = stack.keyMode;

        const tabTemp = data.batches || [];
        // if (checkRank(data.user, { allowedRanks: ['superadmin', 'manager', 'admin'] })) {

        //   for (const e of data.batches) {
        //     tabTemp.push(e);
        //   }

        // } else {

        //   for (const e of data.user.batchList) {
        //     tabTemp.push(e)
        //   }

        // }

        if (tabTemp.length > 1) {

          if (body.batchIdSelected) {
            if (global.SOCKET) global.SOCKET.emit('online', { userId: data.user._id, batchId: body.batchIdSelected });
            this.props.importDatas(data);
          }
          // else if (url === '/login/git-login') {
          //   if (global.SOCKET) global.SOCKET.emit('online', { userId: data.user._id, batchId: batches[batches.length - 1]._id });
          //   this.props.importDatas(data);
          // }

          tabTemp.sort(sortBatches);

          this.setState({ batchList: tabTemp, chooseBatch: true, loading: false });

        } else if (user.type === 'student') {

          localStorage.setItem("batchIdSelected", batches[0]._id);
          setDay(batches[0]?.currentDay?.date)
          setLanguage(user?.preferredLanguage)

          if (global.SOCKET) global.SOCKET.emit('online', { userId: user._id, batchId: batches[0]._id });
          // STUDENT GROUP FINDER
          let myBuddies = [];
          let found = false;
          let studentPosition;

          for (const e of batches[0].group_history.groupList) {
            // eslint-disable-next-line no-loop-func
            e.forEach((student, i) => {
              if (!found && student.firstname === user.firstname && student.lastname === user.lastname) {
                found = true;
                studentPosition = i;
                return;
              }
            });
            if (found) {
              found = false;
              e.splice(studentPosition, 1);
              myBuddies = e;
            }
          }

          let dataBatchTemp = { ...data };

          delete dataBatchTemp.batchList;
          this.props.importDatas(dataBatchTemp, myBuddies);

          var batchNumber = parseInt(data.batches[0].name.slice(7, 9));
          if (batchNumber > 11 && !data.user.lastLogin) {
            var price;
            var title;
            if (data.batches[0].name.toLowerCase().indexOf("parttime") !== -1) {
              price = 3900;
              title = "PartTime";
            } else {
              price = (data.batches[0].name.toLowerCase().indexOf("paris") !== -1) ? 5500 : 4500;
              title = "Fullstack";
            }

            ReactGA.event({ category: 'Formation', action: 'Achat', label: title, value: price });
          }

        } else {
          localStorage.setItem("batchIdSelected", batches[0]._id);
          setDay(batches[0]?.currentDay?.date)

          if (global.SOCKET) global.SOCKET.emit('online', { userId: user._id, batchId: batches[0]._id });
          let dataBatchTemp = { ...data };

          delete dataBatchTemp.batchList;
          this.props.importDatas(dataBatchTemp);
        }

      } else if (data.error === 'batch has not started yet') {

        this.setState({ workshopNotStarted: true });
      } else {
        let err = (url === '/login') ? "Incorrect email or password." : (data.error === "Accès à l'adresse mail GitHub refusé.") ? "Accès à l'adresse mail GitHub refusé." : "Adresse email non trouvée, contactez La Capsule."
        this.setState({ logError: /*err*/ data.error });
      }
    })
  }

  componentDidMount() {
    const url = window.location.href;

    const hasCode = url.includes("?code=");

    const err = url.includes("?error=");

    // If Github API returns the code parameter

    if (hasCode) {
      this.setState({ loading: true, logError: '' }, () => {
        const data = {
          code: url.split("?code=")[1]
        };

        window.history.pushState({}, null, url.split("?code=")[0]);


        // this.login('/login/git-login', data);

      });
    }

    if (err) {
      this.setState({ logError: "Accès à l'adresse mail GitHub refusé." })
    }

    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { addBatchId } = this.props
    const { batchIdSelected } = this.state;

    this.setState({ loading: true, logError: '' }, () => {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          const { email, password } = values;
          const body = { email, password };

          if (batchIdSelected) body.batchIdSelected = batchIdSelected;
          if (addBatchId) body.addBatchId = addBatchId

          this.login('/login', body);

        }
      });
    });
  };

  render() {
    const { menu, menuItem, loginForm, title, formContent, input, mb15, mb27, login, button, spinLoad, gitHubButton, gitHubImg } = styles;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const { user, menuSelected, batch, firstStep, addBatchId } = this.props;
    const { logError } = this.state;

    // Only show error after a field is touched.
    const userNameError = isFieldTouched('email') && getFieldError('email');
    const passwordError = isFieldTouched('password') && getFieldError('password');

    const batchList = 
    this.state.batchList.length > 0 ? (
      <SimpleBar style={ { maxHeight: '210px', backgroundColor: 'white', borderRadius: '3px', paddingTop: 20, paddingBottom: 20 } } forceVisible>
        <Menu style={ menu } onClick={this.handleBatchList}>
            { this.state.batchList.sort(sortBatches).map((e, i) => 
              <Menu.Item style={ menuItem } key={ i }>
                  { moment().isBetween(moment(e.date.start),moment(e.date.end)) && <Badge status="success" style={ { position: 'absolute', left: 20 } }/> }
                  { e.batchType }
              </Menu.Item>
            ) }
        </Menu>
    </SimpleBar>
    ) : null

    // const batchesList = this.state.batchList
    //   ? this.state.batchList.sort(sortBatches).map((batch, i) => {
    //     let name = <span style={{ marginLeft: 17, position: 'relative' }}>
    //       {batch.name}
    //     </span>;

    //     if (moment.utc().isBetween(moment.utc(batch.date?.start), moment.utc(batch.date?.end))) {
    //       name = <span style={{ marginLeft: 17, position: 'relative' }}>
    //         <Badge status="success" style={{ position: 'absolute', left: -16 }} />
    //         {batch.name}
    //       </span>;
    //     }

    //     return (<Menu.Item key={i}>{name}</Menu.Item>);
    //   })
    //   : null;

    // const batchList = (<Menu onClick={this.handleBatchList}>{batchesList}</Menu>);

    return (
      <Row type="flex" align="middle" justify="center" style={loginForm}>

        {user && user._id ?
          <Redirect to={`/home/${menuSelected}`} />
          : (this.state.workshopNotStarted) ?
            <Redirect to={`/workshops/waiting`} />
            : null}

        <Row align="middle" justify="center">

          <Row style={title}>Welcome !</Row>

          {/* <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'center' }}>
            <a href={`https://github.com/login/oauth/authorize?client_id=1f8d82218651b3414e47&scope=user:email`}><Button style={gitHubButton} type="primary" size="large" ><img src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" style={gitHubImg} />Login with GitHub</Button></a>
          </div> */}

          {/* Lien Romane */}
          {/* <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'center' }}>
          <a href={`https://github.com/login/oauth/authorize?client_id=6c3f7cef0fb8ea0b2d70&scope=user:email`}><Button style={gitHubButton} type="primary" size="large" ><img src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" style={gitHubImg} />Login with GitHub</Button></a>
          </div> */}


          {/*           <Row style={{ fontSize: 16, fontFamily: 'roboto', textAlign: 'center', marginBottom: 15 }}>Ou</Row>
 */}

          <Form layout="inline" onSubmit={this.handleSubmit}>

            <Row type="flex" align="middle" justify="center" style={formContent}>

              <Col span={24} style={mb15}>
                <Item name="email" validateStatus={userNameError ? 'error' : ''} help={userNameError || ''} >
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please enter your email address.' }]
                  })(<Input style={input} placeholder="Email address" />)}
                </Item>
              </Col>

              <Col span={24} style={mb27}>
                <Item name="password" validateStatus={passwordError ? 'error' : ''} help={passwordError || ''} >
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please enter your password.' }]
                  })(<Input style={input} type="password" placeholder="Password" />)}
                </Item>
              </Col>

              <Item style={login}>
                {
                  !logError && this.state.loading
                    ? <Icon type="loading" style={spinLoad} spin />
                    : this.state.chooseBatch
                      ? <Row>
                        <div style={{ marginBottom: 5 }}>
                          <Dropdown overlay={batchList}  overlayClassName="hide-scrollbar" overlayStyle={{ maxHeight: 210, width: 280 }}>
                            <Button style={{ width: 280 }}>{this.state.batch}<Icon type="down" /></Button>
                          </Dropdown>
                        </div>
                      </Row>
                      : null
                }
                {
                  logError && <div style={{ color: 'red' }}>{logError}</div>
                }
                <Row>
                  <Button style={button} type="primary" size="large" htmlType="submit" disabled={hasErrors(getFieldsError())}>Login</Button>
                </Row>
              </Item>
            </Row>
          </Form>
        </Row>
      </Row>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

function mapStateToProps({ stackid, user, batchid, batch, menuSelected, firstStep }) {
  return { stackId: stackid, user, batchid, batch, menuSelected, firstStep };
}

function mapDispatchToProps(dispatch) {
  return {
    online: function (status, userId) {
      dispatch({ type: 'online', status, userId });
    },
    importDatas: function (data, myBuddies) {
      dispatch({ type: 'tracks', tracks: data.stack.tracks });
      dispatch({ type: 'postprogram', postprogram: data.stack.postprogram });
      dispatch({ type: 'batch', batch: data.batches[0] });
      dispatch({ type: 'stackid', id: data.stack._id });
      dispatch({ type: 'batchid', id: data.batches[0]._id });
      dispatch({ type: 'batchuserlist', batchuserlist: data.batchUserList });
      if (myBuddies) dispatch({ type: 'buddies', buddies: myBuddies });
      dispatch({ type: 'progression', progression: data.batches[0].enabledStep });
      dispatch({ type: 'messages', messages: data.messages });
      dispatch({ type: 'stackType', stackType: { keyMode: data.stack.keyMode, keyStack: data.stack.keyStack } })
      // Warning: the user dispatch must be the last dispatch
      dispatch({ type: 'user', user: data.user });
    },
    setDay: function (day) {
      dispatch({ type: 'changeDay', day });
    },
    setLanguage: function (language) {
      dispatch({ type: 'setLanguage', language });
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedLoginForm);

const styles = {
  loginForm: { height: '100vh' },
  title: { fontSize: 33, fontFamily: 'roboto', fontWeight: 'bold', color: '#272727', textAlign: 'center', marginBottom: 37 },
  formContent: { width: 450 },
  input: { width: 450, height: 50 },
  mb15: { marginBottom: 15, textAlign: 'center' },
  mb27: { marginBottom: 27, textAlign: 'center' },
  login: { textAlign: 'center', marginBottom: 20 },
  button: { width: 100, fontSize: 13, color: '#fff', fontWeight: 'bold', textAlign: 'center' },
  forgot: { color: '#999999', fontSize: 12, fontWeight: 'light' },
  spinLoad: { fontSize: 50, marginTop: 20, marginBottom: 20, display: "flex", justifyContent: "center", color: "#fff" },
  gitHubButton: { backgroundColor: 'black', borderColor: 'black', paddingTop: 5, paddingBottom: 39, fontWeight: 'bold' },
  gitHubImg: { marginLeft: '-5px', marginRight: 10, width: 35, borderRadius: '50%' },
  menuIcon: {
    color: '#898C8F',
    position: 'absolute',
    right: -5
  },
  menu: {
      borderRadius: '3px',
      padding: 0,
      maxHeight: 210
  },
  menuItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 300,
      fontSize: '12px',
      lineHeight: '18px',
      color: '#898C8F',
      borderBottom: '0.5px solid #F0F2F5',
      height: '34px',
      position: 'relative',
      width: '100%',
      margin: 0
  },
}
