import React, { Component } from 'react';
import { Form, Input, Icon, Button, Row, Avatar, message, Upload, Checkbox, Col } from 'antd';
import DatePicker from 'react-datepicker2';
import moment from 'moment';
import CompanyIcon from './CompanyIcon';
import experiencesIcon from '../../images/experience.svg';
import { Markup } from 'interweave';


// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

let id = 0;

const { Item } = Form;
const { TextArea } = Input;

class Experiences extends Component {
  state = {
    experiences: [],
    singleEdit: [],
    images: {},
    loading: false
  }

  componentDidMount() {
    const { form, experiences = [{}] } = this.props;

    const keys = experiences.map((_, i) => i);
    // const images = experiences.map(e => e.icon);

    let images = {};
    experiences.forEach((e, i) => images[i] = e.icon);

    id = experiences.length;

    form.setFieldsValue({ keys });
    this.setState({ experiences: experiences.slice(), images });
  }

  remove = k => {
    const { form: { getFieldValue, setFieldsValue } } = this.props;
    const { experiences } = this.state;
    const keys = getFieldValue('keys');
    const newKeys = keys.filter(key => key !== k);

    fetch(`${global.URI_BACKEND}/experiences/${experiences[k]?._id}`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => res.json()).then(data => {});

    setFieldsValue({ keys: newKeys });
  }

  add = () => {
    const { form: { getFieldValue, setFieldsValue } } = this.props;
    const { singleEdit } = this.state;
    const keys = getFieldValue('keys');
    const currentId = id;
    const nextKeys = keys.concat(id++);

    setFieldsValue({ keys: nextKeys });
    this.setState({ singleEdit: singleEdit.concat(currentId) });
  }

  handleSubmit = e => {
    if (e) e.preventDefault();
    const { form: { validateFields, setFieldsValue }, setStateInParent } = this.props;

    validateFields((error, values) => {
      if (error) return;

      let { experiences = [], keys } = values;
      const { _id } = this.props;
      const { images } = this.state;

      experiences = experiences.slice().map((e, i) => {
        const r = e;
        if (images[keys[i]]) r.icon = images[keys[i]];
        return r;
      }).filter(e => e.company);


      fetch(`${global.URI_BACKEND}/experiences/${_id}`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ experiences })
      }).then(res => res.json()).then(data => {
        if (data?.success) {
          const { experiences } = data;

          for (let e of experiences) {
            e.date_end = moment(e.date_end);
            e.date_start = moment(e.date_start);
          }

          let images = {};
          experiences.forEach((e, i) => images[i] = e.icon);

          setFieldsValue({ experiences });
          setStateInParent({ experiences, editExperiences: false });
          this.setState({ experiences, singleEdit: [], images });
        }
      });
    });
  }

  singleEdit = k => {
    const { singleEdit } = this.state;
    let edit;

    if (singleEdit.some(e => e === k)) edit = singleEdit.filter(key => key !== k);
    else edit = singleEdit.concat(k);

    this.setState({ singleEdit: edit });
  }

  setStateInParent = state => this.setState(state);

  changeStillWorkingAt = (checked, k) => {
    const { experiences } = this.state;

    const expCopy = experiences.slice();
    expCopy[k].still_working_at = checked;

    this.setState({ experiences: expCopy });
  }

  render() {
    const { experienceRow, input, dateInput, iconContainer, icon, content, mb10, mb0, text, expTitle, expJob, expDates, expDesc, image } = styles;
    const { experiences, singleEdit, images } = this.state;
    const { editExperiences, form: { getFieldDecorator, getFieldValue, setFieldsValue }, user, _id, t } = this.props;


    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map(k => {

      var date_start = experiences[k]?.date_start ? moment(experiences[k].date_start) : null;
      var date_end = experiences[k]?.date_end ? moment(experiences[k].date_end) : null;

      
      return <Item label="" required={false} key={k} className="form-item__custom">
        {
          singleEdit.some(e => e === k)
            ? <Row type="flex" style={experienceRow}>
              <div style={iconContainer}>
                <Item style={mb0}>
                  <CompanyIcon images={images} k={k} setStateInParent={this.setStateInParent} edit={singleEdit.some(e => e === k)} imageUrl={experiences[k]?.icon} />
                </Item>
              </div>
              <div style={content}>
                <Item style={{ display: 'none' }}>
                  {getFieldDecorator(`experiences[${k}]._id`, { validateTrigger: ['onChange', 'onBlur'], initialValue: experiences[k]?._id })(
                    <Input style={input} placeholder="Id" />
                  )}
                </Item>
                <div className="experiences-form__row" style={mb10}>
                  <Item style={mb0}>
                    {getFieldDecorator(`experiences[${k}].company`, { validateTrigger: ['onChange', 'onBlur'], initialValue: experiences[k]?.company })(
                      <Input style={input} placeholder={t("Entreprise")} />
                    )}
                  </Item>
                  <Item style={mb0}>
                    {getFieldDecorator(`experiences[${k}].job`, { validateTrigger: ['onChange', 'onBlur'], initialValue: experiences[k]?.job })(
                      <Input style={input} placeholder={t("Travail")} />
                    )}
                  </Item>
                 {/*  <div className="experiences-edit__buttons" style={{ position: 'absolute', right: 0, display: 'flex', fontSize: 14 }}>
                     <Icon className="dynamic-save-button" type="save" onClick={() => this.singleEdit(k)} /> 
                    <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.remove(k)} />
                  </div> */}
                </div>
                <Row type="flex" align="middle" style={mb10} className="experiences-form__row">
                  <Item style={mb0}>
                    {getFieldDecorator(`experiences[${k}].date_start`, { validateTrigger: ['onChange', 'onBlur'], initialValue: date_start })(
                      <DatePicker style={dateInput} isGregorian={true} timePicker={false} placeholder="YYYY/MM/DD" />
                    )}
                  </Item>
                  <div>~</div>
                  <Item style={experiences[k].still_working_at ? { display: 'none' } : mb0}>
                    {getFieldDecorator(`experiences[${k}].date_end`, { validateTrigger: ['onChange', 'onBlur'], initialValue: date_end })(
                      <DatePicker style={dateInput} isGregorian={true} timePicker={false} placeholder="YYYY/MM/DD" />
                    )}
                  </Item>
                  <Item style={mb0}>
                    {getFieldDecorator(`experiences[${k}].still_working_at`, {
                      validateTrigger: ['onChange'], valuePropName: 'checked', onChange: ({ target: { checked }, target }) => {
                        setFieldsValue({ [target['data-__field'].name]: checked }, () => { this.changeStillWorkingAt(checked, k) });
                      }, initialValue: experiences[k]?.still_working_at
                    })(
                      <Checkbox>{t("Encore en poste")}</Checkbox>
                    )}
                  </Item>
                </Row>
                <Item style={mb0}>
                  {getFieldDecorator(`experiences[${k}].description`, { validateTrigger: ['onChange', 'onBlur'], initialValue: experiences[k]?.description })(
                    <TextArea rows={6} style={text} placeholder={t("Description")} />
                  )}
                </Item>
                {
                <Row type="flex" justify="center" style={styles.buttonRow}>
                  <Button style={{ position: 'absolute', right: 28 }} type="primary" onClick={this.handleSubmit}>{t("Enregistrer")}</Button>
                </Row>}
              </div>
            </Row>
            : (experiences[k]?.company || experiences[k]?._id) && <Row type="flex" style={experienceRow} className="form-item__row">

              {/* ----- HACK FORM ANTD ----- */}
              <Row type="flex" style={{ display: 'none' }}>
                <Item style={mb0}>
                  {getFieldDecorator(`experiences[${k}]._id`, { validateTrigger: ['onChange', 'onBlur'], initialValue: experiences[k]?._id })(
                    <Input style={input} placeholder="Id" />
                  )}
                </Item>
                <div style={iconContainer}>
                  <Item style={mb0}>
                    {getFieldDecorator(`experiences[${k}].icon`, { validateTrigger: ['onChange', 'onBlur'], initialValue: experiences[k]?.icon })(
                      <Input style={input} placeholder={t("Icone")} />
                    )}
                  </Item>
                </div>
                <div style={content}>
                  <div className="experiences-form__row" style={mb10}>
                    <Item>
                      {getFieldDecorator(`experiences[${k}].company`, { validateTrigger: ['onChange', 'onBlur'], initialValue: experiences[k]?.company })(
                        <Input style={input} placeholder={t("Entreprise")} />
                      )}
                    </Item>
                    <Item>
                      {getFieldDecorator(`experiences[${k}].job`, { validateTrigger: ['onChange', 'onBlur'], initialValue: experiences[k]?.job })(
                        <Input style={input} placeholder={t("Travail")} />
                      )}
                    </Item>
                    <div style={{ position: 'absolute', right: 0, display: 'flex', fontSize: 14 }}>
                      <Icon className="dynamic-edit-button" type="edit" onClick={() => this.singleEdit(k)} />
                      <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.remove(k)} />
                    </div>
                  </div>
                  <Row type="flex" align="middle" style={mb10} className="experiences-form__row">
                    <Item>
                      {getFieldDecorator(`experiences[${k}].date_start`, { validateTrigger: ['onChange', 'onBlur'], initialValue: date_start })(
                        <DatePicker style={dateInput} isGregorian={true} timePicker={false} placeholder="YYYY/MM/DD" />
                      )}
                    </Item>
                    <div>~</div>
                    <Item style={experiences[k]?.still_working_at && { display: 'none' }}>
                      {getFieldDecorator(`experiences[${k}].date_end`, { validateTrigger: ['onChange', 'onBlur'], initialValue: date_end })(
                        <DatePicker style={dateInput} isGregorian={true} timePicker={false} placeholder="YYYY/MM/DD" />
                      )}
                    </Item>
                    <Item style={mb0}>
                      {getFieldDecorator(`experiences[${k}].still_working_at`, { validateTrigger: ['onChange'], valuePropName: 'checked', initialValue: experiences[k]?.still_working_at })(
                        <Checkbox>Encore en poste</Checkbox>
                      )}
                    </Item>
                  </Row>
                  <Item>
                    {getFieldDecorator(`experiences[${k}].description`, { validateTrigger: ['onChange', 'onBlur'], initialValue: experiences[k]?.description })(
                      <TextArea rows={6} style={text} placeholder={t("Description")} />
                    )}
                  </Item>
                </div>
              </Row>
              {/* ----- END HACK FORM ANTD ----- */}

              <div style={iconContainer}>
                <div style={icon}>
                  {
                    experiences[k]?.icon
                      ? <img style={image} alt="company" src={`data:image/png;base64,${experiences[k]?.icon}`} />
                      : <div style={image} />
                  }
                </div>
              </div>
              <div style={content}>
                <div style={expTitle}>{experiences[k]?.job}</div>
                <div style={expJob}>{experiences[k]?.company}</div>
                <div style={expJob}>{experiences[k]?.city}</div>
              {/*   {
                  _id === user._id && <div className="experiences-edit__buttons" style={{ position: 'absolute', right: 0, top: 0, display: 'flex', fontSize: 14 }}>
                    <Icon className="dynamic-edit-button" type="edit" onClick={() => this.singleEdit(k)} />
                    <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.remove(k)} />
                  </div>
                } */}
                {/* <div style={expDates}>{moment(experiences[k]?.date_start).format('YYYY')} - {experiences[k].still_working_at ? <span>{t("Aujourd'hui")}</span> : moment(experiences[k]?.date_end).format('YYYY')}</div> */}
                <div style={expDates}>{experiences[k]?.date}</div>
                {
                  experiences[k]?.description && <div style={expDesc}><Markup content={experiences[k]?.description} /></div>
                }
              </div>
            </Row>
        }
      </Item>;
    });

    return (<Form id="experiences" style={{padding: "20px"}}>
      {/* CHANGER LE SENS DE LA CONDITION */}
      {formItems.length < 0 ? 
      <Row type="flex" justify="center">
        <Col type="flex" justify="center" align="center">
        <p>{t("Votre expérience est vide pour le moment")}</p>
        <img src={experiencesIcon} style={{height: "150px", opacity: "0.3"}} />
        </Col>
      </Row>
      
      : formItems }
      
      {
        _id === user._id && singleEdit.length > 0 &&
        <Row type="flex" justify="center" style={styles.buttonRow}>
          <Button type="dashed" onClick={this.add}><Icon type="plus" /> {t("Ajouter une expérience")}</Button>
        </Row>
      }
    </Form>);
  }
}

export default Form.create({ name: 'experiences_form_item' })(withTranslation()(Experiences));

const styles = {
  experienceRow: { padding: 28 },
  input: { width: 250 },
  dateInput: { width: 100 },
  iconContainer: { width: 46 },
  icon: { height: 46, width: 46, display: 'flex' },
  image: { width: '100%', alignSelf: 'center' },
  content: { width: 'calc(100% - 46px)', paddingLeft: 18, position: 'relative' },
  mb10: { marginBottom: 10, lineHeight: 0, display: 'flex', alignItems: 'center' },
  mb0: { marginBottom: 0 },
  text: { width: '100%' },
  expTitle: { fontSize: 14, marginBottom: 4, fontWeight: 'bold', color: '#444444' },
  expJob: { fontSize: 14, marginBottom: 4, fontWeight: 300, color: '#444444' },
  expDates: { fontSize: 14, fontWeight: 300, color: '#b4b4b4' },
  expDesc: { fontSize: 14, fontWeight: 300, color: '#444444', marginTop: 17, width:"700px" },
  buttonRow: { padding: 28 }
}