export default function batch(state = '', action){
  if (action.type === 'batch') {
    return state = action.batch;
  }
  // if (action.type === 'updateBatchProgression') {
  //   var stateCopy = JSON.parse(JSON.stringify(state))
  //   stateCopy.progression = action.newProgression
  // }
  else {
    return state;
  }
}
