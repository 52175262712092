import React from 'react'

//STYLES________________________________
import './customicon.css'

const CustomIcon = ({ icon, size = 'medium', theme = 'dark', onClick, color = 'blue', style, transparent = false }) => {

    return (
        <div className={ `background icon-background-${size} icon-background-${color}-${theme}` } onClick={ onClick && onClick } style={ transparent ? styles.transparent : style ? style : {} }>
            <i className={ `icon-${icon} custom-icon icon-foreground-${size} icon-foreground-${color}-${theme}`}></i>
        </div>
    )
}
export default CustomIcon;

const styles = {
    transparent: {
        background: 'transparent'
    }
}