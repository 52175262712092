import typeChecker from '../../typeChecker';

function callExpression(tc_callExp = {}, pb_callExp = {}) {
  const check = {};

  if (tc_callExp.callee) check.callee = false;
  if (tc_callExp.arguments) check.arguments = false;

  if (pb_callExp.type && tc_callExp.type === pb_callExp.type) {
    check.callee = typeChecker(tc_callExp.callee, pb_callExp.callee);
  }

  if (pb_callExp.arguments && tc_callExp.arguments.length === pb_callExp.arguments.length) {
    const args = [];

    let found_instructions = 0;

    tc_arg_loop: for (let i = 0; i < tc_callExp.arguments?.length; i++) {
      const tc_arg = tc_callExp.arguments[i];

      for (let j = found_instructions; j < pb_callExp.arguments?.length; j++) {
        const pb_arg = pb_callExp.arguments[j];

        const checkResult = typeChecker(tc_arg, pb_arg);

        args[i] = checkResult;

        if (checkResult) {
          found_instructions = i + 1;
          continue tc_arg_loop;
        }
      }
    }

    check.arguments = (args.length === tc_callExp.arguments?.length && args.every(e => e === true));
  }

  return Object.values(check).every(e => e === true);
}

export default callExpression;