import React, { Component } from 'react';
import { Badge } from 'antd';

// Use this Component :
// put a props with name "startDate" in components call
// props "startDate" must be a "new Date().getTime()"

export default class Timer extends Component {
  state = {
    endDate: new Date().getTime(),
    timerColor: "#2ecc71"
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({ endDate: new Date().getTime() });
  }

  Timer = (start, end) => {
    const { timerColor } = this.state;

    const seconds = (Math.floor((end - start) / 1000) % 60);
    const minutes = (Math.floor((end - start) / (60 * 1000)) % 60);
    const hours = (Math.floor((end - start) / (60 * 60 * 1000)) % 24);

    if (hours >= 0 && minutes >= 1 && timerColor === "#2ecc71") this.setState({ timerColor: "#e67e22" });

    if (hours >= 0 && minutes >= 3 && timerColor === "#e67e22") this.setState({ timerColor: "#F94A56" });

    let time = '';

    if (hours < 10) time += `0${hours}`;
    else time += hours;

    if (minutes < 10) time += ` : 0${minutes}`;
    else time += ` : ${minutes}`;

    if (seconds < 10) time += ` : 0${seconds}`;
    else time += ` : ${seconds}`;

    return time;
  }

  render() {
    const { endDate, timerColor } = this.state;
    const { type, startDate } = this.props;

    if (type === "badge") {
      return (<Badge count={this.Timer(startDate, endDate)} style={{ backgroundColor: timerColor }} />);
    } else {
      return (<div>{this.Timer(startDate, endDate)}</div>);
    }
  }
}
