import React from 'react'

//STYLES___________________________
import '../teacherdashboards.css'

const BarChartLabel = ({ viewBox, color, value, onClick, range, data, name }) => {
    let quizData = data?.find(e => e.name === name)

    let count = range === 'low' ? quizData?.low || null : range === 'medium' ? quizData?.medium || null : range === 'high' ? quizData?.high || null : null
    return (
        <text
            x={viewBox.x}
            y={viewBox.y}
            dy={12}
            dx={20}
            fontSize='12'
            fontFamily='sans-serif'
            fontWeight={600}
            fill={value === 0 ? `rgba(255, 255, 255, 0)` : color ? color : '#FFFFFF'}
            textAnchor="middle"
            onClick={() => onClick(count, quizData.stepId, range)}
            className="hover-underline"
        >
            {count}
        </text>
    )
}

export default BarChartLabel