export default function batchuserlist(state = '', action) {
  if (action.type === 'batchuserlist') {
    return action.batchuserlist;
  } else if (action.type === 'online') {
    var copyState = JSON.parse(JSON.stringify(state));
    if (!Array.isArray(copyState)) return [];
    for (let e of copyState) {
      if (e._id === action.userId) {
        e.online = action.status;
      };
    };
    return copyState;
  } else {
    return state;
  };
};
