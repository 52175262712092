import React, { useState, useEffect } from "react";

//UI___________________________________
import { Row, Col, Spin, Avatar, notification } from "antd";

//COMPONENTS______________________________
import Resource from "../Container/StepHeader/Resource";

//REDUX________________________
import { connect } from "react-redux";

//SCROLLBAR_________________________
import SimpleBar from "simplebar-react";

//TRANSLATION
import { useTranslation } from "react-i18next";

const Today = ({
  steps,
  user,
  stepsLoaded = false,
  buddies,
  tracks,
  myDailyBuddy,
  stackid
}) => {
  //STATE HOOKS_______________________________
  const [selectedDay, setSelectedDay] = useState(null);
  const { t } = useTranslation();

  //FUNCTIONS_____________________
  const openNotification = () => {
    notification.success({
      message: `Nouveau ${user.remote ? "group" : "buddy"} attribué`,
      description: "Retrouve-le sur ton dashboard !",
    });
  };

  //EFFECT HOOKS_______________________________
  useEffect(() => {
    if (global.SOCKET) {
      global.SOCKET.on("groupsGeneration", handleBuddyGen);

      return () => global.SOCKET.off("groupsGeneration", handleBuddyGen);
    }
  });

  useEffect(() => {
    let foundDay = false;
    for (const t of tracks) {
      for (const p of t.projects) {
        for (const d of p.days) {
          for (const s of d.steps) {
            if (s._id) {
              if (steps?.find(e => e.stepId === s.stepId)) {
                let tmp = { ...d };
                tmp.imagepath = p.imagepath;
                foundDay = true;
                setSelectedDay(tmp);
                break;
              }
            }
          }
          if (foundDay) break;
        }
        if (foundDay) break;
      }
      if (foundDay) break;
    }
  }, [steps, tracks]);

  const handleBuddyGen = data => {
    if (data.success) {
      for (let i = 0; i < data.groups.length; i++) {
        let studentToFind = data.groups[i].find(
          student => student._id === user._id
        );

        if (studentToFind) {
          let buddy = data.groups[i].filter(
            student => student._id !== user._id
          );

          myDailyBuddy(buddy);
          openNotification();
        }
      }
      // } else {
      //     console.log(data);
    }
  };

  return (
    <>
      <Row style={styles.container} className="dashboard-container">
        <Col style={styles.navRow}>
          <div style={styles.flex}>
            <Avatar
              size={60}
              shape="square"
              src={
                user.avatar
                  ? `/images/avatar/${user._id}.jpg`
                  : "/images/avatar/_male-default.jpg"
              }
            ></Avatar>
            <h2 style={styles.heading}>
              {t("Welcome")} {user.firstname} !
            </h2>
          </div>
          <div style={styles.flex}>
            {user.type === "student" && buddies && stackid !== "63513b5c197ad46873055b3f" && (
              <>
                <p style={styles.subheading}>
                  {user.remote
                    ? t("Ton groupe")
                    : t("tonBuddy", { count: buddies.length })}
                </p>
                {buddies.map((e, i) => (
                  <Avatar
                    key={i}
                    size={40}
                    shape="square"
                    src={
                      e._id
                        ? `/images/avatar/${e._id}.jpg`
                        : `/images/avatar/_male-default.jpg`
                    }
                    title={`${e.firstname} ${e.lastname}`}
                  />
                ))}
              </>
            )}
          </div>
        </Col>
        <Col style={styles.list}>
          <p style={{ ...styles.subheading, marginBottom: "5px" }}>
            {t("Notions journée")}
          </p>
          {stepsLoaded ? (
            <SimpleBar
              forceVisible
              style={{ maxHeight: "calc(100vh - 250px)" }}
            >
              {steps?.length > 0 &&
                steps
                  .filter(e =>
                    user.type === "student" ? e.mainGroup !== "Teachers" : true
                  )
                  .map((e, i) => (
                    <Resource
                      resource={e}
                      key={i}
                      // stepsUnlocked={e.enable}
                      index={i}
                      // QCMUnlocked={e.unlockedQuiz}
                      // flashcardsUnlocked={e.unlockedFlashCard}
                      day={selectedDay}
                    />
                  ))}
            </SimpleBar>
          ) : (
            <Spin style={{ margin: "100px 50%" }} />
          )}
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps({
  tracks,
  batchid,
  batch,
  user,
  day,
  buddies,
  stackType,
  stackid
}) {
  return { tracks, user, batchid, batch, day, buddies, stackType, stackid };
}

function mapDispatchToProps(dispatch) {
  return {
    myDailyBuddy: function (buddy) {
      dispatch({ type: "buddies", buddies: buddy });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Today);

const styles = {
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  list: {
    maxHeight: "60vh",
    width: "80%",
    paddingTop: "30px",
  },
  navRow: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "20px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  heading: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: 20,
    margin: "0px 0px 0px 10px",
    color: "#010031",
  },
  subheading: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 300,
    margin: "0px 10px 0px 0px",
    color: "#010031",
    fontSize: 14,
  },
};
