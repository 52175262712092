import React, { Component } from 'react';
import { Input, Select, Button } from 'antd';

import './classmates.css'

const { Search } = Input;
const { Option } = Select;

export default class Filters extends Component {
  state = {
    search: '',
    city: '',
    profile: '',
    specs: []
  }

  timeout;
  delay = 500; // default 500 (ms);

  update = state => {
    this.setState(state, () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.onConfirm, this.delay);
    });
  }

  handleClear = filter => {
    filter === 'specs' ? this.setState({ [`${filter}`]: [] }) : this.setState({ [`${filter}`]: '' })
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.onConfirm, this.delay);


  }

  handleSearchChange = e => {
    this.update({ search: e.target.value });
  }

  handleCityChange = city => {
    this.update({ city });
  }

  handleProfileChange = profile => {
    this.update({ profile });
  }

  handleSpecChange = specs => {
    this.update({ specs });
  }

  onConfirm = () => {
    const { search, city, profile, specs } = this.state;
    const { onApplyFilters } = this.props;
    if (this.timeout) clearTimeout(this.timeout);

    onApplyFilters({ search, city, profile, specs }, 0);
  }

  render() {
    const { container, input, buttonContainer, button } = styles;
    const { cities = [], specialities = [] } = this.props;

    const allProfiles = [
      { name: 'Freelance', status: 'isFreelance' },
      { name: 'Entrepreneur', status: 'isContractor' },
      { name: 'Recruteur', status: 'isRecruiter' },
      { name: 'Développeur', status: 'isDeveloper' },
      { name: 'Project Owner', status: 'isProjectOwner' },
      { name: 'En Poste', status: 'inOffice' },
      { name: 'Recherche Job', status: 'lookingForJob' },
      { name: 'Recherche Associé', status: 'lookingForAssociate' },
      { name: 'Recherche Freelance', status: 'lookingForFreelance' },
      { name: 'Recherche de nouveaux talents', status: 'lookingForNewTalents' }
    ];

    return <div style={container} id="filter-container">

      <Search autoFocus allowClear style={input} placeholder="Nom" onChange={this.handleSearchChange} onClear={ ()=>this.handleClear('search') }/>
      { cities.length > 0 && <Select allowClear onClear={ ()=>this.handleClear('city') } style={ { ...input, border: '1px solid rgba(0, 0, 0, 0.17)' } } placeholder="Ville" onChange={ this.handleCityChange } showSearch>
          { cities.map(e => <Option key={ `city-${e}` } value={ e.keys }>{ e.label }</Option>) }
        </Select>
      }
      {
        allProfiles.length > 0 && <Select allowClear onClear={ ()=>this.handleClear('profile') } style={{ ...input, border: '1px solid rgba(0, 0, 0, 0.17)' } } placeholder="Profil" onChange={this.handleProfileChange} showSearch>
          { allProfiles.map(e => <Option key={`profile-${e}`} value={e.status}>{e.name}</Option>) }
        </Select>
      }
      {
        specialities.length > 0 && <Select allowClear onClear={ ()=>this.handleClear('specs') } style={{ ...input, border: '1px solid rgba(0, 0, 0, 0.17)' } } placeholder="Spécialité" mode="multiple" onChange={this.handleSpecChange} showSearch>
          { specialities.map(e => <Option key={`speciality-${e}`} value={e}>{e}</Option>) }
        </Select>
      }

      <div style={buttonContainer}>
        <Button style={button} type="primary" onClick={this.onConfirm}>Appliquer</Button>
      </div>
    </div>
  }
}

const styles = {
  container: { width: 258, backgroundColor: 'white', padding: 18, position: 'absolute', right: 66, height: '85vh', top: 110 },
  input: { width: '100%', marginBottom: 15, borderRadius: '4px', color: 'F94A56' },
  buttonContainer: { width: '100%', position: 'absolute', bottom: 0, marginBottom: 15 },
  button: { width: 'calc(100% - 36px)', height: 43, fontSize: 14, borderRadius: '50px', marginBottom: 20 }
};