import React, { Component } from 'react';
import { Row, Col, Avatar, Icon } from 'antd';

export default class NewStudentModal extends Component {
  state = {
    students: [],
    selected: []
  }

  componentDidMount() {
    const { batchid } = this.props;
    fetch(`${global.URI_BACKEND}/project/admin/batch/${batchid}/unassigned`).then(res => res.json()).then(data => {
      if (!data?.error) {
        const { unassignedUsers } = data;
        this.setState({ students: unassignedUsers });
      }
    });
  }

  selectStudent = id => {
    const { students, selected } = this.state;
    let update = selected.slice();

    if (selected.some(e => e._id === id)) {
      // const find = update.find(e => e._id === id);
      update = update.filter(e => !e._id === id);
    } else {
      update.push({ ...students.find(e => e._id === id), selected: true });
    }

    this.setState({ selected: update }, () => {
      const { selectUsers } = this.props;
      
      selectUsers(update);
    });
  }

  // selectStudent = id => {
  //   const { students } = this.state;
  //   const { projectId, updateTeam } = this.props;

  //   const current = students.find(e => e._id === id);
  //   current.selected = !current.selected;
  //   fetch(`${global.URI_BACKEND}/project/admin/${projectId}/assign`, {
  //     method: 'put',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ student: current })
  //   }).then(res => res.json()).then(data => {
  //     // console.log(data)
  //     updateTeam(data.team);
  //     const { updateProject } = this.props;

  //     updateProject(data);
  //   });
  // }

  render() {
    const { username, heart } = styles;
    const { students, selected } = this.state;

    const studentList = students.map((e, i) => {
      const hearts = [];
      let checkStyle = { color: "#ffe3e3", fontSize: 22, backgroundColor: 'white', borderRadius: '50%' };

      const checked = selected.some(s => s._id === e._id);

      for (let i = 0; i < 3; i++) {
        let filled = false;

        if (i < e.like) filled = true;

        hearts.push(<Icon key={i} type="heart" theme={filled ? "filled" : "outlined"} style={heart} />);
      }

      if (checked) checkStyle.color = '#ff5050';

      const className = checked ? "selected-student clickable" : "clickable";

      return <Col key={i} span={24} className={className} onClick={() => this.selectStudent(e._id)}>
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <Row type="flex" align="middle">
              <Col flex="48px" style={{ marginRight: 23 }}>
                <Avatar shape="square" size={48} src={`../images/avatar/${e._id}.jpg`} />
              </Col>
              <Col flex="auto" style={username}>{e.fullname}</Col>
            </Row>
          </Col>
          <Col style={{ width: 'auto', display: 'block' }}>
            <Row type="flex" align="middle">
              <Col flex="22px" style={{ marginLeft: 27 }}>
                <Icon type="check-circle" theme="filled" style={checkStyle} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    });

    return <Row type="flex" className="submitted-projects__modal">
      {studentList}
    </Row>;
  }
}

const styles = {
  username: { color: '#444444', fontWeight: 'bold', fontSize: 18, marginRight: 10 },
  heart: { color: '#ff3a47', fontSize: 17 }
}