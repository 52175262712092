import React from "react";

//REDUX______________________________
import { connect } from "react-redux";

//STYLES_____________________________
import "./syllabus.css";

//UI__________________________________
import { Row, Col } from "antd";
import { iconSrc } from "../static-data";

//COMPONENTS________________________
import CustomIcon from "../CustomIcon";

// TRANSLATION -------------------
import { useTranslation } from "react-i18next";

export const SyllabusItem = connect(
  mapStateToProps,
  null
)(({ size = "normal", item, type, enabled, onClick = () => {} }) => {
  //PROPS_____________________________________
  const { day, title, project, secondaryGroup } = item;

  const { t } = useTranslation();

  //FUNCTIONS______________________________
  const toggleStyle = style => {
    return type === "course"
      ? Object.assign({ ...style }, { color: "#5247EA" })
      : type === "title"
      ? Object.assign({ ...style }, { color: "#F94A56" })
      : style;
  };

  //DISPLAY_____________________________________
  return (
    <div
      className={"syllabus-item" + (!enabled ? " disabled" : "")}
      style={{
        ...styles.item_body,
        opacity: enabled ? 1 : 0.95,
        minWidth: size === "small" ? 280 : 500,
      }}
      onClick={() => onClick()}
    >
      <div style={styles.header}>
        <h4 style={toggleStyle(styles.item_title)}>
          {day === 41 ? (
            <span>
              {t("Jour", { count: 2 })} {day} - 47
            </span>
          ) : (
            <span>
              {t("Jour", { count: 1 })} {day}
            </span>
          )}
        </h4>
        <div style={styles.subtitle_container}>
          <h5
            style={{
              ...styles.item_subtitle,
              fontSize: size === "small" ? "13px" : "14px",
              fontWeight: size === "small" ? "regular" : "bold",
            }}
          >
            {t(title)}
            {!title && project === "Projet" && project}
          </h5>
          {size !== "small" && (
            <h6 style={styles.item_heading}>
              {/* { project && project !== 'Projet' && t("Projet") + ' - ' } */}
              {project && title !== "Projet" && project + " - "}
              {/* { notion && <span>{ t(notion) }</span> } */}
              {<span>{t(secondaryGroup)}</span>}
            </h6>
          )}
        </div>
      </div>
      <div style={styles.img_container}>
        <div
          style={{
            ...(type === "course" ? styles.img : styles.img_red),
            opacity: enabled ? 1 : 0.5,
            transform: size === "small" ? "scale(0.7)" : "scale(1)",
          }}
        >
          <i
            className={
              item.tag
                ? iconSrc[item.tag]
                : item.secondaryGroup
                ? iconSrc[item.secondaryGroup]
                : iconSrc["Projet"]
            }
            style={type === "course" ? styles.icon : styles.icon_red}
          />
        </div>
      </div>
    </div>
  );
});

export const SyllabusCategory = connect(
  mapStateToProps,
  null
)(
  ({
    size = "normal",
    category,
    type,
    index,
    user,
    onItemClick,
    stackType,
  }) => {
    //console.log("category", category.items)

    const { t } = useTranslation();

    return (
      <Row style={styles.container}>
        <Col
          md={{ span: 12, offset: 6 /*type === 'course' ? 6 : 5*/ }}
          sm={{ span: 22, offset: 1 }}
          style={{
            ...styles.content_container,
            display: size === "small" ? "unset" : "flex",
            marginLeft: size === "small" ? "3%" : "25%",
          }}
        >
          {type === "title" && (
            <div style={styles.picto_header}>
              <CustomIcon
                color="black"
                icon={iconSrc[category.title]}
                size="large"
                theme="light"
                transparent
              />
            </div>
          )}
          <h3
            style={
              type === "course"
                ? styles.title
                : Object.assign({ ...styles.title }, { left: "3%" })
            }
          >
            {type === "course"
              ? stackType.keyStack === "fullstack-js" &&
                stackType.keyMode === "full-time" &&
                index === 8
                ? t("Semaine", { count: 2 }) + ` ${index + 1} / ${index + 2}`
                : t("Semaine", { count: 1 }) + ` ${index + 1}`
              : type === "title" && `${index + 1} - ${category.title}`}
          </h3>
          {category.items &&
            category.items.length > 0 &&
            category.items.map((e, i) => (
              <SyllabusItem
                item={e}
                key={i}
                index={i}
                type={type}
                enabled={e.enabled}
                size={size}
                onClick={() => onItemClick(e)}
              />
            ))}
        </Col>
      </Row>
    );
  }
);

function mapStateToProps({ batch, stackid, user, stackType }) {
  return {
    batch,
    stackid,
    user,
    stackType,
  };
}

const styles = {
  container: {
    display: "flex",
    paddingTop: 24,
    paddingBottom: 24,
  },
  content_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  title: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "21.09px",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    position: "relative",
    left: "2%",
    fontFamily: '"Poppins", sans-serif',
  },
  blocked: {
    opacity: 0.8,
  },
  item_body: {
    width: "100%",
    minWidth: "500px",
    borderRadius: "7px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "6px 0px",
    padding: "10px 16px",
    backgroundColor: "#ffffff",
    overflow: "hidden",
    position: "relative",
    boxSizing: "border-box",
  },
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  wrapper_hover: {
    width: "105%",
  },
  item_body_hover: {
    transform: "scale(1.05)",
    maxWidth: "778px",
    boxShadow: "rgb(0 0 0 / 25%) 0px 2px 4px",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  item_title: {
    fontWeight: "bold",
    margin: 0,
    fontFamily: '"Poppins", sans-serif',
    whiteSpace: "nowrap",
  },
  subtitle_container: {
    marginLeft: 32,
    fontSize: "14px",
    whitespace: "wrap",
  },
  item_subtitle: {
    fontSize: "14px",
    margin: 0,
    lineHeight: "16px",
    fontWeight: "bold",
    fontFamily: '"Poppins", sans-serif',
  },
  item_heading: {
    fontSize: "14px",
    margin: 0,
    lineHeight: "16px",
    fontWeight: "normal",
    fontFamily: '"Poppins", sans-serif',
  },
  img: {
    width: "48px",
    height: "48px",
    borderRadius: "7px",
    margin: 5,
    overflow: "hidden",
    backgroundColor: "rgba(82, 71, 235, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    verticalAlign: "middle",
    objectFit: "cover",
    boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.05)",
  },
  icon: {
    fontSize: "20px",
    color: "rgb(82, 71, 234)",
  },
  img_red: {
    width: "48px",
    height: "48px",
    borderRadius: "7px",
    margin: 7,
    overflow: "hidden",
    backgroundColor: "rgba(249, 74, 86, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon_red: {
    fontSize: "20px",
    color: "#F94A56",
  },
  img_container: {
    whiteSpace: "nowrap",
    // width: '100px',
    display: "flex",
  },
  picto_header: {
    position: "absolute",
    left: -30,
    top: -8,
  },
};
