import React, { Component } from "react";

//UI_________________________________
import { Col, Row, Button, Icon } from "antd";

//REDUX___________________________
import { connect } from "react-redux";

//STATIC__________________________
import Logo from "./Logo/Logo";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

//COMPONENTS_________________________
import Nav from "./Nav";
import HelpButton from "./HelpButton";
import Orion from "./Orion";
import User from "./User/User";
import Satisfaction from "./Satisfaction";
import AddPhoto from "./AddPhoto";
import Status from "./Status";
import "./Header.css";
import checkRank from "../../utils/checkRank";
import SideMenu from "./SideMenu";
import Language from "./Language";
import { getCurrentTime } from "../../functions/currentTime";

class Header extends Component {
  state = {
    visible: false,
    step: null,
    hasFeeling: false,
    signingInterval: null,
    sideMenuVisible: false,
    currentMenu: "batch",
    mobile: window.innerWidth < 530,
    addPhotoVisible: false,
    duringBatch: null,
  };

  async componentDidMount() {
    const { user } = this.props;

    if (
      checkRank(user, { allowedRanks: ["teacher", "student", "superadmin"] })
    ) {
      this.checkEmargement();

      // Check if he's supposed to sign every minute
      const signingIntervalInMinutes = 1;

      const signingInterval = setInterval(() => {
        this.checkEmargement();
      }, signingIntervalInMinutes * 60 * 1000);

      this.setState({ signingInterval });
    } else {
      this.checkAddPhoto()
    }

    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    if (this.state.signingInterval) {
      clearInterval(this.state.signingInterval);
      this.setState({ signingInterval: null });
    }

    window.removeEventListener("resize", this.resize);
  }

  openDrawer = () => {
    this.setState({ visible: true });
  };

  closeDrawer = () => {
    this.checkAddPhoto().then(() => this.setState({ visible: false }));
  };

  toggleMenu = () => {
    // const { sideMenuVisible } = this.state
    this.setState({ sideMenuVisible: !this.state.sideMenuVisible });
  };

  forceDisplay = visible => {
    if (visible) {
      this.checkEmargement();
    }
  };
  resize = () => {
    window.innerWidth < 1100
      ? this.setState({ mobile: true })
      : this.setState({ mobile: false });
  };

  openPhotoDrawer = () => {
    this.setState({ addPhotoVisible: true });
  };

  closePhotoDrawer = () => {
    this.setState({ addPhotoVisible: false });
  };

  checkAddPhoto = async () => {
    const { user, batch } = this.props;

    if (user.avatar) return;

    return fetch(
      `${global.URI_BACKEND}/user/avatar/${user._id}/${batch._id || ""}`
    )
      .then(res => res.json())
      .then(data => {
        if (
          data.result &&
          !data.avatar &&
          ["teacher", "student", "assistant", undefined].includes(data.batchType)
        )
          this.openPhotoDrawer();
      });
  };

  checkEmargement = async () => {
    const { batch, user, stackType } = this.props;
    const { remote } = user;
    const { city, currentDay, stackId } = batch;

    const isRemote = (remote || city === 'Remote')

    let { duringBatch } = await getCurrentTime(
      currentDay,
      null,
      isRemote,
      stackId
    );

    this.setState({ duringBatch })
    try {
      if (duringBatch && stackType.keyMode !== "workshop") {
        fetch(
          `${global.URI_BACKEND}/emargement/checkv2/${user._id}/${batch._id}/${duringBatch}`
        )
          .then(res => res.json())
          .then(data => {
            if (data?.userInBatch && data?.step) {
              this.setState({
                visible: data?.visible,
                step: data?.step,
                hasFeeling: data?.hasFeeling,
              });

              if (!data?.visible) this.checkAddPhoto();
            } else {
              this.checkAddPhoto();
              this.setState({ visible: false });
            }
          });
      } else {
        this.checkAddPhoto();
        this.setState({ visible: false });
      }
    } catch (error) {
      this.setState({ visible: false });
    }
  };

  render() {
    const { header, /* content,*/ navButton, flex } = styles;
    const {
      visible,
      sideMenuVisible,
      navStatus,
      mobile,
      step,
      hasFeeling,
      addPhotoVisible,
      duringBatch,
    } = this.state;
    const { batch, user, menu, t } = this.props;

    return (
      <>
        <Row type="flex" style={header} justify="space-between" align="middle">
          <Row type="flex" style={flex}>
            <Logo mobile={mobile} />
            <Col style={flex}>
              <Button style={navButton} onClick={() => this.toggleMenu()}>
                {menu === "batch"
                  ? t("Mon batch") + ` #${batch.batchNumber}`
                  : menu === "post-program"
                    ? "Career Center"
                    : menu === "campus"
                      ? "Campus Manager"
                      : null}
                <Icon type={sideMenuVisible ? "close" : "menu"} />
              </Button>
            </Col>
            <Col style={flex}>
              <Nav mode="horizontal" />
            </Col>
          </Row>
          <Row type="flex" align="middle" style={flex}>
            {<Language />}
            {/* duringBatch && canSeeHelpButton && */ <HelpButton />}
            {batch.stackType !== "workshop" && <Orion />}
            {/* {batch._id !== "60c9d8d3832198839197bb43" ? <Orion /> : null} */}
            <User openDrawer={this.openDrawer} mobile={mobile} />
          </Row>
          {checkRank(user, { allowedRanks: ["teacher"] }) && (
            <Status forceDisplay={this.forceDisplay} />
          )}
        </Row>

        <SideMenu
          visible={sideMenuVisible}
          onClose={this.toggleMenu}
          navStatus={navStatus}
        />

        {visible && (
          <Satisfaction
            visible={visible}
            step={step}
            hasFeeling={hasFeeling}
            closeDrawer={this.closeDrawer}
            duringBatch={duringBatch}
          />
        )}
        <AddPhoto
          visible={addPhotoVisible}
          closeDrawer={this.closePhotoDrawer}
        />
      </>
    );
  }
}

function mapStateToProps({ user, batch, stackid, menu, stackType }) {
  return { user, batch, stackid, menu, stackType };
}

export default connect(mapStateToProps)(withTranslation()(Header));

const styles = {
  header: {
    height: 45,
    backgroundColor: "#02002f",
    width: "100vw",
    maxWidth: "100vw",
    boxSizing: "border-box",
    minHeight: 45,
    maxHeight: 45,
  },
  content: {
    width: "calc(100% - 190px)",
    letterSpacing: 0.57,
  },
  navButton: {
    background: "rgba(255, 255, 255, 0.71)",
    color: "#ffffff",
    textTransform: "uppercase",
    fontWeight: "bold",
    height: "26px",
    border: "none",
    fontFamily: '"Poppins", sans-serif',
  },
  flex: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
};
