export default function tracks(state = '', action) {
  if (action.type === 'tracks') {
    return action.tracks;
  } else if (action.type === 'openmenuitem') {
    var copyTracks = JSON.parse(JSON.stringify(state));

    if (action.levelType === 'track') {
      for (let e of copyTracks) {
        if (e._id === action.levelId) {
          if (action.isOpen) {
            e.open = true;
          } else {
            e.open = false;
          }
        }
      }
    } else if (action.levelType === 'project') {
      for (let e of copyTracks[action.trackPosition].projects) {
        if (e._id === action.levelId) {
          if (action.isOpen) {
            e.open = true;
          } else {
            e.open = false;
          };
        };
      };
    };
    return copyTracks;
  } else {
    return state;
  };
};
