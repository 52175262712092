import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Layout, Icon } from 'antd';
import { connect } from 'react-redux';

import Userpanel from './userpanel';
import Header from '../header';
import SideMenu from '../sidemenu';

import checkRank from '../utils/checkRank';

const { Content } = Layout;

class StudentList extends Component {

  render() {


    var ressourcesList = {};
    var progress = {};
    if (this.props.tracks) {

      this.props.progression.tracks.map((progressionTrack, i) => {
        progressionTrack.projects.map((progressionProject, i) => {
          progressionProject.days.map((progressionDay, i) => {
            progressionDay.steps.map((progressionStep, i) => {
              progress[progressionStep.stepId] = { ...progressionStep };
            })
          })
        })
      })


      this.props.tracks.map((track, trackPosition) => {
        track.projects.map((project, projectPosition) => {
          project.days.map((day, dayPosition) => {
            day.steps.map((step, stepPosition) => {
              if (step.stepType != "slide" || step.stepType_v2 || !step.mainGroup) {
                return false;
              }

              if (!ressourcesList[step.mainGroup]) {
                ressourcesList[step.mainGroup] = {};
              }
              if (!ressourcesList[step.mainGroup][step.secondaryGroup]) {
                ressourcesList[step.mainGroup][step.secondaryGroup] = [];
              }
              let unlock = (progress[step._id] && progress[step._id].enable) ? true : false;
              let to = `/batch/${this.props.batchId}/stack/${this.props.stackId}/track/${trackPosition}/project/${projectPosition}/day/${dayPosition}/step/${stepPosition}`;
              ressourcesList[step.mainGroup][step.secondaryGroup].push({ ...step, unlock, to });
            })

          })

        })

      })
    }

    console.log(ressourcesList);

    let displayAllGroup = [];
    for (var primaryGroup in ressourcesList) {
      let displayGroup = [];
      for (var secondaryGroup in ressourcesList[primaryGroup]) {
        let ressourceList = [];

        for (let i = 0; i < ressourcesList[primaryGroup][secondaryGroup].length; i++) {
          let item = ressourcesList[primaryGroup][secondaryGroup][i];
          if (item.unlock == false) {
            ressourceList.push(<li style={{ opacity: 0.3, cursor: 'not-allowed' }}><Icon type="lock" /> {item.text}</li>);
          } else {
            ressourceList.push(<li><Link target="_blank" style={{ color: 'rgba(0, 0, 0, 0.65)' }} to={item.to}><Icon type="check-circle" /> {item.text}</Link></li>);
          }

        }

        displayGroup.push(<div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '300px', margin: '10px' }}>
          <img style={{ height: '50px' }} src={'/images/picto-techno/' + secondaryGroup.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '.svg'} />
          <h4 style={{ fontSize: "16px" }}>{secondaryGroup}</h4>
          <ul style={{ listStyle: 'none', paddingLeft: '0px', width: '80%' }}>
            {ressourceList}
          </ul>
        </div>);

      }

      displayAllGroup.push(
        <Row>
          <h3 style={{ textAlign: 'center', fontSize: "18px", textTransform: "uppercase", marginTop: '50px' }}>{primaryGroup}</h3>
          <div style={styles.mainLine} />
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {displayGroup}
          </div>
        </Row>
      );

    }

    return (
      <div>
        <Header />
        <Layout style={styles.layout}>
          {
            checkRank(this.props.user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })
              ? <SideMenu disableNavigation="disableNavigation" />
              : null
          }
          <Layout style={styles.background}>
            <Content style={styles.content}>
              <div style={styles.contentBackground}>
                <Row>
                  <Col style={styles.marginTop} offset={1} span={4}>
                    <Userpanel name={this.props.user.firstname} />
                  </Col>
                  <Col style={styles.ressources} offset={1} span={16}>

                    {displayAllGroup}

                  </Col>

                </Row>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  };
};

function mapStateToProps(state) {

  return {
    user: state.user,
    stackId: state.stackid,
    batchId: state.batchid,
    batch: state.batch,
    tracks: state.tracks,
    progression: state.progression
  }
}

export default connect(mapStateToProps)(StudentList);

const styles = {
  marginTop: {
    marginTop: 154
  },
  layout: {
    minHeight: '100vh'
  },
  ressources: {
    marginTop: 154,
    backgroundColor: '#fff',
    padding: 25,
    marginBottom: 10
  },
  background: {
    backgroundColor: 'white'
  },
  content: {
    minHeight: '79vh',
    maxHeight: '79vh'
  },
  contentBackground: {
    backgroundColor: '#F6F6FA',
    minHeight: '100vh'
  },
  mainLine: {
    height: 1,
    width: '80%',
    marginLeft: '10%',
    backgroundColor: 'lightGrey',
    marginBottom: '30px'
  }
};
