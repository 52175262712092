import React, { Component } from "react";
import { Col, Row, Button, Progress, Input, Modal, Icon, Tooltip } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import moment from "moment";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import CustomIcon from "../../../CustomIcon";

import "./Flashcard.css";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

const { TextArea } = Input;

class Flashcard extends Component {
  solution = false;
  answers = [];
  score = 0;
  time = 0;
  flipDuration = 400;

  state = {
    displayComponent: false,
    index: 0,
    questionCount: 0,
    status: "in progress",
    displayContent: true,
    finalScore: 0,
    finalPercentage: 0,
    progressPercentage: 0,
    flipcard: false,
    result: null,
    answerStudent: "",
    visibleWrong: false,
    visibleCorrect: false,
    wrongAnswers: [],
    correctAnswers: [],
    checkFlipcard: [],
    noAnswerMsg: false,
    isunlocked: false,
    timer: 120,
    unlockTimer: null,
    lastAttempt: null,
    flipCardTable: [],
  };

  loadProgression = () => {
    const { stepData, flashcard, user, batch } = this.props;

    fetch(`${global.URI_BACKEND}/stepprogression/user/${user._id}/${batch._id}`)
      .then(res => res.json())
      .then(data => {
        if (data.success && data.progression !== null) {
          var tempQuestionsCount = [];
          for (const e of data.progression.progression) {
            if (e?.step === stepData.stepId) {
              this.setState({ progressPercentage: e.stepPercentage });

              if (e?.stepPercentage !== 100) {
                tempQuestionsCount = Math.round(
                  flashcard.length * (e.stepPercentage / 100)
                );
                this.setState({ questionCount: tempQuestionsCount });
              } else if (e?.stepPercentage === 100) {
                tempQuestionsCount = Math.round(
                  flashcard.length * (e.stepPercentage / 100) - 1
                );
                const endDate = e?.sections[e.sections.length - 1]?.date;
                const finalScoreTemp = Math.round(
                  (e.scorePercentage / 100) * flashcard.length
                );
                const finalPercentageTemp = e.scorePercentage;
                this.setState({
                  finalScore: finalScoreTemp,
                  finalPercentage: finalPercentageTemp,
                  questionCount: tempQuestionsCount,
                  status: "finished",
                  lastAttempt: endDate,
                });

                if (moment().isAfter(moment(endDate).add(2, "hours"))) {
                  this.setState({ isUnlocked: true });
                } else {
                  let duration = moment.duration(
                    moment(endDate).add(2, "hours").diff(moment())
                  );
                  let minutes = duration.asMinutes();
                  this.setState({ timer: Math.round(minutes) });
                }
              }

              const wrongAnswersTemp = [];
              const correctAnswersTemp = [];

              for (const f of e.sections) {
                if (f.result === true) {
                  correctAnswersTemp.push({ index: f.index, answer: f.answer });
                } else {
                  wrongAnswersTemp.push({ index: f.index, answer: f.answer });
                }
                this.score = correctAnswersTemp.length;
                this.setState({
                  wrongAnswers: wrongAnswersTemp,
                  correctAnswers: correctAnswersTemp,
                });
              }
            }
          }
        }
      });
  };

  componentDidUpdate(prevProps) {
    const { url: prevUrl } = prevProps;
    const { url, id, tracks, batch } = this.props;
    let { stepsList } = this.props;

    if (prevUrl !== url) {
      for (const t of tracks) {
        for (const p of t.projects) {
          for (const d of p.days) {
            for (const s of d.steps) {
              for (let step of stepsList) {
                if (step.id === s.stepId) {
                  if (s.mainGroup) {
                    step.mainGroup = s.mainGroup;
                    step.day = d.day;
                  }
                }
              }
            }
          }
        }
      }
      stepsList = stepsList.filter(step => step.mainGroup !== "Teachers");

      const { planning } = batch;

      for (let week of planning) {
        for (let day of week.days) {
          for (let step of stepsList) {
            if (day.countDay === step.day) {
              step.date = moment(day.date);
            }
          }
        }
      }


      this.solution = false;
      this.answers = [];
      this.score = 0;
      this.time = 0;
      this.setState({
        index: 0,
        questionCount: 0,
        status: "in progress",
        current: 0,
      });
    }
  }

  componentDidMount() {
    const { id, tracks, batch } = this.props;
    let { stepsList } = this.props;

    for (const t of tracks) {
      for (const p of t.projects) {
        for (const d of p.days) {
          for (const s of d.steps) {
            for (let step of stepsList) {
              if (step.id === s.stepId) {
                if (s.mainGroup) {
                  step.mainGroup = s.mainGroup;
                  step.day = d.day;
                }
              }
            }
          }
        }
      }
    }
    stepsList = stepsList.filter(step => step.mainGroup !== "Teachers");

    const { planning } = batch;

    for (let week of planning) {
      for (let day of week.days) {
        for (let step of stepsList) {
          if (day.countDay === step.day) {
            step.date = moment(day.date);
          }
        }
      }
    }

    this.loadProgression();

    let interval = 5;
    const unlockTimer = setInterval(
      () => this.unlockRedoQuizz(),
      interval * 1000 * 60
    );

    this.setState({ unlockTimer });
  }

  componentWillUnmount = () => {
    const { unlockTimer } = this.state;
    clearInterval(unlockTimer);
  };

  nextQuestion = () => {
    const {
      flashcard,
      batch,
      stepData,
      user,
      updateProgress
    } = this.props;
    const { questionCount, index, answerStudent } = this.state;

    /*         if (questionCount + 1 < flashcard.length) {
     */
    var tempStepPercentage = Math.round(
      ((questionCount + 1) / flashcard.length) * 100
    );

    const body = {
      student: user._id,
      batch: batch._id,
      step: stepData.stepId,
      type: "flashcard",
      stackDay: stepData.day,
      title: stepData.text,
      mainGroup: stepData.mainGroup,
      secondaryGroup: stepData.secondaryGroup,
      questionCount: flashcard.length,
      scoreResult: this.score,
      scorePercentage: Math.round(
        (this.score / (this.state.questionCount + 1)) * 100
      ),
      stepPercentage: tempStepPercentage,
      date: moment().format(),
      index: flashcard[questionCount].id,
      result: this.solution,
      answer: answerStudent,
      mainGroupSections: flashcard[questionCount].mainGroup
        ? flashcard[questionCount].mainGroup
        : stepData.mainGroup,
      secondaryGroupSections: flashcard[questionCount].secondaryGroup
        ? flashcard[questionCount].secondaryGroup
        : stepData.secondaryGroup,
    };

    fetch(`${global.URI_BACKEND}/stepprogression/flashcard/${user._id}`, {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(data => {
        if (data.progression !== null) {
          for (const e of data.progression) {
            if (e?.step === stepData.stepId) {
              const finalScoreTemp = Math.round(
                (e.scorePercentage / 100) * flashcard.length
              );
              this.setState({
                finalScore: finalScoreTemp,
                progressPercentage: e.stepPercentage,
                lastAttempt: new Date(e.date),
              });
            }
          }
        }
        if (questionCount + 1 === flashcard.length) {
          this.loadProgression();
        }
      })
      .then(() => updateProgress())
      .catch(console.error);

    if (questionCount + 1 === flashcard.length) {
      this.setState({ status: "finished", flipcard: false });
    } else {
      this.setState({
        questionCount: questionCount + 1,
        index: index + 1,
        answerStudent: "",
        displayContent: false,
      });

      let delay = 3;

      setTimeout(() => {
        this.setState({
          flipcard: false,
        })
      }, delay)
      setTimeout(() => {
        this.setState({ displayContent: true });
      }, this.flipDuration + delay);

    }
  };

  correctAnswerClick = () => {
    this.solution = true;
    this.score++;
    this.setState({ flashcard: true });
  };

  wrongAnswerClick = () => {
    this.solution = false;
  };

  turnCard = () => {
    const { flipcard, answerStudent, questionCount, flipCardTable } =
      this.state;

    const flipCardTemp = flipCardTable;
    flipCardTemp.push(questionCount);
    this.setState({ flipCardTable: flipCardTemp });

    if (answerStudent.length > 0) {
      let delay = 3;
      this.setState({
        displayContent: false,
      });

      setTimeout(() => {
        this.setState({
          flipcard: !flipcard,
          noAnswerMsg: false,
        })
      }, delay)
      setTimeout(() => {
        this.setState({ displayContent: true });
      }, this.flipDuration + delay);
    } else {
      this.setState({ noAnswerMsg: true });
    }
  };

  turnAnswers = i => {
    const { checkFlipcard } = this.state;
    const cpyCheckFlipcard = checkFlipcard;

    if (cpyCheckFlipcard.includes(i)) {
      const indexOfId = cpyCheckFlipcard.indexOf(i);
      cpyCheckFlipcard.splice(indexOfId, 1);
    } else {
      cpyCheckFlipcard.push(i);
    }
    this.setState({ checkFlipcard: cpyCheckFlipcard });
  };

  onInputChange = e => {
    const { id, value } = e.target;

    this.setState({ [id]: value });
  };

  showModalWrong = () => {
    this.setState({ visibleWrong: true });
  };

  showModalCorrect = () => {
    this.setState({ visibleCorrect: true });
  };

  handleCancel = e => {
    this.setState({
      visibleWrong: false,
      visibleCorrect: false,
      checkFlipcard: [],
    });
  };

  redoQuizz = () => {
    const { user, stepData } = this.props;

    fetch(`${global.URI_BACKEND}/stepprogression/quizz/delete/${user._id}/`, {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        step: stepData.stepId,
        stepPercentage: 0,
        scorePercentage: 0,
      }),
    })
      .then(res => res.json())
      .then(data => { })
      .catch(console.error);

    this.score = 0;
    this.answers = [];

    this.setState({
      questionCount: 0,
      status: "in progress",
      progressPercentage: 0,
      finalScore: 0,
    });
  };

  unlockRedoQuizz = () => {
    const { lastAttempt, isUnlocked } = this.state;
    if (
      !isUnlocked &&
      lastAttempt &&
      moment().isAfter(moment(lastAttempt).add(2, "hours"))
    ) {
      this.setState({ isUnlocked: true });
    } else {
      this.setState({ timer: this.state.timer - 5 });
    }
  };

  changeStep = step => {
    const { nextStep, setDay } = this.props;

    nextStep(step);
    setDay(step.date);
  };

  checkOpacity = () => {
    this.setState({ displayComponent: true })
    setTimeout(() => {
      this.setState({ displayComponent: false })
    }, 300)
  }

  render() {
    const {
      questionCount,
      status,
      finalScore,
      finalPercentage,
      progressPercentage,
      flipcard,
      answerStudent,
      wrongAnswers,
      correctAnswers,
      checkFlipcard,
      noAnswerMsg,
      isUnlocked,
      timer,
      flipCardTable,
      displayContent,
      displayComponent
    } = this.state;
    const { flashcard, batch, stepData, t, next } = this.props;
    const {
      results,
      results2,
      scoreStyle,
      button2,
      button3,
      modal,
      noAnwser,
      redoButton,
      disabledButton,
    } = styles;



    const question = flashcard[questionCount]?.question.map((p, i) => {
      return (
        <tr key={i}>
          <td>{p}</td>
        </tr>
      );
    });

    const answer = flashcard[questionCount]?.answer.map((p, i) => {
      return (
        <tr key={i}>
          <td>{p}</td>
        </tr>
      );
    });

    const wrongAnswersTable = wrongAnswers.map((p, i) => {
      return (
        <Flippy
          key={i}
          style={{ height: "342px", width: "565px", marginBottom: "20px" }}
          flipDirection="vertical"
          flipOnHover={false}
          flipOnClick={false}
          isFlipped={checkFlipcard.includes(i)}
        >
          <FrontSide
            animationDuration={this.flipDuration}
            style={{
              ...results,
              height: "342px",
              width: "565px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {
              displayContent &&
              <>
                <div
                  style={{ width: "520px", marginTop: "-5px", cursor: "pointer" }}
                >
                  <img
                    src="/images/returnicon.svg"
                    alt="icon-return"
                    width="20px"
                    onClick={() => {
                      this.turnAnswers(i);
                    }}
                  />
                </div>
                <SimpleBar style={{ height: "300px", width: "465px" }}>
                  <div
                    style={{
                      minHeight: "300px",
                      width: "465px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ ...scoreStyle, textAlign: "center" }}>
                      {flashcard[p.index - 1]?.question}
                    </span>
                    {flashcard[p.index - 1]?.codeflashcard?.length > 0 ? (
                      <div id="flashcard" style={{ width: "100%" }}>
                        <table
                          style={{
                            ...scoreStyle,
                            textAlign: "start",
                            width: "100%",
                            marginTop: 10,
                            fontFamily: "consolas",
                          }}
                          className="noBorder"
                        >
                          <tbody>
                            <tr>
                              <td>{flashcard[p.index - 1]?.codeflashcard[0]}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                    <TextArea
                      rows={4}
                      id="answerStudent"
                      style={{ width: "465px", marginTop: 10 }}
                      value={p.answer}
                      disabled={true}
                    />
                  </div>
                </SimpleBar>
              </>
            }
          </FrontSide>
          <BackSide
            animationDuration={this.flipDuration}
            style={{ ...results, height: "342px", width: "565px" }}
          >
            {displayContent && (
              <>
                <div
                  style={{
                    width: "520px",
                    marginTop: "-10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src="/images/returnicon.svg"
                    alt="icon-return"
                    width="20px"
                    onClick={() => {
                      this.turnAnswers(i);
                    }}
                  />
                </div>
                <SimpleBar style={{ height: "300px", width: "465px" }}>
                  <div
                    style={{
                      minHeight: "300px",
                      width: "465px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {flashcard[p.index - 1]?.answer.length ? (
                      <span
                        style={{
                          ...scoreStyle,
                          fontSize: 14,
                          marginBottom:
                            flashcard[p.index - 1]?.answerCode?.length > 0
                              ? 20
                              : 0,
                        }}
                      >
                        {flashcard[p.index - 1]?.answer}
                      </span>
                    ) : (
                      ""
                    )}
                    {flashcard[p.index - 1]?.answerCode?.length > 0 ? (
                      <div id="flashcard" style={{ width: "100%" }}>
                        <table
                          style={{
                            ...scoreStyle,
                            textAlign: "start",
                            width: "100%",
                            fontFamily: "consolas",
                          }}
                          className="noBorder"
                        >
                          <tbody>
                            <tr>
                              <td>{flashcard[p.index - 1]?.answerCode[0]}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </SimpleBar>
              </>
            )}
          </BackSide>
        </Flippy>
      );
    });

    const correctAnswersTable = correctAnswers.map((p, i) => {
      return (
        <Flippy
          key={i}
          style={{ height: "342px", width: "565px", marginBottom: "20px" }}
          flipDirection="vertical"
          flipOnHover={false}
          flipOnClick={false}
          isFlipped={checkFlipcard.includes(i)}
        >
          <FrontSide
            animationDuration={this.flipDuration}
            style={{
              ...results,
              height: "342px",
              width: "565px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {
              displayContent &&
              <>
                <div
                  style={{ width: "520px", marginTop: "-5px", cursor: "pointer" }}
                >
                  <img
                    src="/images/returnicon.svg"
                    alt="icon-return"
                    width="20px"
                    onClick={() => {
                      this.turnAnswers(i);
                    }}
                  />
                </div>
                <SimpleBar style={{ height: "300px", width: "465px" }}>
                  <div
                    style={{
                      minHeight: "300px",
                      width: "465px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ ...scoreStyle, textAlign: "center" }}>
                      {flashcard[p.index - 1]?.question}
                    </span>
                    {flashcard[p.index - 1]?.codeflashcard?.length > 0 ? (
                      <div id="flashcard" style={{ width: "100%" }}>
                        <table
                          style={{
                            ...scoreStyle,
                            textAlign: "start",
                            width: "100%",
                            marginTop: 10,
                            fontFamily: "consolas",
                          }}
                          className="noBorder"
                        >
                          <tbody>
                            <tr>
                              <td>{flashcard[p.index - 1]?.codeflashcard[0]}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                    <TextArea
                      rows={4}
                      id="answerStudent"
                      style={{ width: "465px", marginTop: 10 }}
                      value={p.answer}
                      disabled={true}
                    />
                  </div>
                </SimpleBar>
              </>
            }
          </FrontSide>

          <BackSide
            animationDuration={this.flipDuration}
            style={{ ...results, height: "342px", width: "565px" }}
          >
            {
              displayContent &&
              <>
                <div
                  style={{ width: "520px", marginTop: "-5px", cursor: "pointer" }}
                >
                  <img
                    src="/images/returnicon.svg"
                    alt="icon-return"
                    width="20px"
                    onClick={() => {
                      this.turnAnswers(i);
                    }}
                  />
                </div>
                <SimpleBar style={{ height: "300px", width: "465px" }}>
                  <div
                    style={{
                      minHeight: "300px",
                      width: "465px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {flashcard[p.index - 1]?.answer.length ? (
                      <span
                        style={{
                          ...scoreStyle,
                          fontSize: 14,
                          marginBottom:
                            flashcard[p.index - 1]?.answerCode?.length > 0 ? 20 : 0,
                        }}
                      >
                        {flashcard[p.index - 1]?.answer}
                      </span>
                    ) : (
                      ""
                    )}
                    {flashcard[p.index - 1]?.answerCode?.length > 0 ? (
                      <div id="flashcard" style={{ width: "100%" }}>
                        <table
                          style={{
                            ...scoreStyle,
                            textAlign: "start",
                            width: "100%",
                            fontFamily: "consolas",
                          }}
                          className="noBorder"
                        >
                          <tbody>
                            <tr>
                              <td>{flashcard[p.index - 1]?.answerCode[0]}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </SimpleBar>
              </>
            }
          </BackSide>
        </Flippy>
      );
    });

    const questionWithCode =
      flashcard[questionCount]?.codeflashcard?.length > 0;
    const codeValue = flashcard[questionCount]?.codeflashcard[0];
    const answerWithCode = flashcard[questionCount]?.answerCode?.length > 0;
    const answerValue = flashcard[questionCount]?.answerCode[0];

    console.log(next)
    return (
      <div>
        <Row type="flex" style={{ height: "100%" }}>
          {
            <Modal
              bodyStyle={modal}
              style={{ marginTop: "-45px" }}
              width={"70vw"}
              footer={null}
              title={t("réponsesFausses", {
                count: finalScore
                  ? flashcard.length - finalScore
                  : flashcard.length - this.score,
              })}
              visible={this.state.visibleWrong}
              closable={true}
              onCancel={this.handleCancel}
            >
              <SimpleBar style={{ height: "100%", width: "100%" }}>
                <div
                  /* onClick={() => { this.turnAnswers(); }} */ style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {wrongAnswersTable}
                </div>
              </SimpleBar>
            </Modal>
          }

          {
            <Modal
              bodyStyle={modal}
              width={"70vw"}
              style={{ marginTop: "-45px" }}
              footer={null}
              title={t("réponsesCorrectes", {
                count: finalScore ? finalScore : this.score,
              })}
              visible={this.state.visibleCorrect}
              closable={true}
              onCancel={this.handleCancel}
            >
              <SimpleBar style={{ height: "100%", width: "100%" }}>
                <div
                  /* onClick={() => { this.turnAnswers(); }} */ style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {correctAnswersTable}
                </div>
              </SimpleBar>
            </Modal>
          }

          <Col span={6} offset={2} style={{ paddingTop: 10 }}>
            <div style={{ width: "220px" }}>
              <span style={{ ...scoreStyle, fontSize: 16, fontWeight: 600 }}>
                Flashcards
              </span>
              <Progress
                style={{ width: "114%", marginBottom: "1vh", marginTop: "3vh" }}
                success={{ strokeColor: "#98EDC4" }}
                strokeColor="#5246E9"
                strokeWidth={8}
                percent={progressPercentage > 2 ? progressPercentage : 0}
                format={() => ""}
              />
              <div
                style={{
                  ...scoreStyle,
                  fontSize: 14,
                  fontWeight: 500,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontWeight: 600 }}>{t("Progression")}</span>
                <span style={{ fontWeight: 600 }}>
                  {questionCount + 1}/{flashcard.length}
                </span>
              </div>
              {/*                             <div style={{ display: "flex", justifyContent: 'center' }}><Button style={{ ...button1, marginTop: 20 }} type="primary" size="large">Mélanger</Button></div>
               */}{" "}
            </div>
          </Col>

          {status === "finished" ? (
            <Col
              span={16}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 50,
              }}
            >
              <Flippy
                style={{ width: "100%", height: "100%" }}
                flipOnHover={false}
                flipOnClick={false}
                flipDirection="vertical"
                isFlipped={flipcard}
              >
                <FrontSide
                  animationDuration={this.flipDuration}
                  style={{
                    ...results2,
                    textAlign: "center",
                    color: "white",
                    height: "342px",
                    width: "565px",
                    padding: 50,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 40,
                    }}
                  >
                    <div
                      style={{
                        width: "240px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          ...scoreStyle,
                          fontSize: "36px",
                          fontWeight: 600,
                          color: "white",
                        }}
                      >
                        {finalPercentage
                          ? finalPercentage
                          : Math.round((this.score / flashcard.length) * 100)}
                        %
                      </span>
                      {/*                                             {this.score == flashcard.length || finalScore == flashcard.length ? <span style={{ ...scoreStyle, marginBottom: 20, fontSize: "18px" }}>Félicitations !</span> : <span style={{ ...scoreStyle, marginBottom: 20, fontSize: "18px" }}>Encore un peu de travail ! </span>}
                       */}
                      <span
                        style={{
                          ...scoreStyle,
                          marginBottom: 20,
                          fontSize: "18px",
                          color: "white",
                        }}
                      >
                        {this.score <= flashcard.length / 3 ||
                          finalScore <= flashcard.length / 3
                          ? t("Encore un peu de travail !")
                          : this.score <= flashcard.length / 1.8 ||
                            finalScore <= flashcard.length / 1.8
                            ? t("Tu y es presque !")
                            : this.score <= flashcard.length / 1.15 ||
                              finalScore <= flashcard.length / 1.15
                              ? t("Pas mal !")
                              : t("Félicitations !")}
                      </span>
                      {this.score === flashcard.length ||
                        finalScore === flashcard.length ? (
                        <span>
                          <img
                            src="/images/emojiconfetti.png"
                            alt="icon-confetti"
                            width="34px"
                          />
                        </span>
                      ) : (
                        <span>
                          <img
                            src="/images/iconDev.png"
                            alt="icon-dev"
                            width="34px"
                          />
                        </span>
                      )}
                    </div>

                    <div
                      style={{
                        width: "240px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={
                          finalScore > 0
                            ? {
                              width: "200px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 15,
                              cursor: "pointer",
                            }
                            : {
                              width: "200px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 15,
                              pointerEvents: "none",
                              fontStyle: "italic",
                            }
                        }
                        onClick={finalScore > 0 && this.showModalCorrect}
                      >
                        <CustomIcon icon="check" theme="light" />{" "}
                        {/* <div style={{ backgroundColor: 'white', borderRadius: 50, width: "39px", height: "39px", display: "flex", justifyContent: 'center', alignItems: 'center' }}> */}
                        {/* <img src="/images/check.png" alt="icon-check" width="16px" height="16px" /> */}
                        {/* </div> */}
                        <span
                          style={{
                            ...scoreStyle,
                            fontSize: "18px",
                            marginLeft: "20px",
                            color: "white",
                          }}
                        >
                          {t("réponsesCorrectes", {
                            count: finalScore ? finalScore : this.score,
                          })}
                        </span>
                      </div>
                      <div
                        style={
                          flashcard.length - finalScore > 0
                            ? {
                              width: "200px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 15,
                              cursor: "pointer",
                            }
                            : {
                              width: "200px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 15,
                              pointerEvents: "none",
                              fontStyle: "italic",
                            }
                        }
                        onClick={
                          flashcard.length - finalScore > 0 &&
                          this.showModalWrong
                        }
                      >
                        <CustomIcon icon="cross" theme="light" />
                        {/* <div style={{ backgroundColor: 'white', borderRadius: 50, width: "39px", height: "39px", display: "flex", justifyContent: 'center', alignItems: 'center' }}><img src="/images/cross.png" alt="icon-cross" width="16px" height="16px" /></div> */}
                        <span
                          style={{
                            ...scoreStyle,
                            fontSize: "18px",
                            marginLeft: "20px",
                            color: "white",
                          }}
                        >
                          {t("réponsesFausses", {
                            count: finalScore
                              ? flashcard.length - finalScore
                              : flashcard.length - this.score,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </FrontSide>

                <div
                  style={{
                    width: "565px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginTop: 25,
                  }}
                >
                  {isUnlocked ? (
                    <Link
                      to={`/batch/${stepData?.stepType}/${stepData?.stepId}`}
                    >
                      <Button
                        style={redoButton}
                        type="primary"
                        size="large"
                        onClick={() => this.redoQuizz()}
                      >
                        <Icon type="arrow-left" />
                        {t("Refaire")}
                      </Button>
                    </Link>
                  ) : (
                    <Tooltip
                      title={`Revenez dans ${timer >= 60
                          ? `${Math.round(timer / 60)} heures`
                          : `${timer} minutes`
                        } pour réessayer`}
                      placement="bottom"
                    >
                      <Button
                        style={{ ...redoButton, ...disabledButton }}
                        disabled
                        type="primary"
                        size="large"
                        onClick={() => this.redoQuizz()}
                      >
                        <Icon type="arrow-left" />
                        {t("Recommencer")}
                      </Button>
                    </Tooltip>
                  )}

                  {next && batch.stackType !== "workshop" && (
                    <Link to={`/batch/${next?.stepType}/${next?.stepId}`}>
                      <Button
                        style={{ ...button2 }}
                        type="primary"
                        size="large"
                        onClick={() => {
                          this.changeStep(next);
                        }}
                      >
                        {t("Étape suivante")}
                      </Button>
                    </Link>
                  )}
                </div>
              </Flippy>
            </Col>
          ) : (
            <Col
              span={16}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 50,
              }}
            >
              <Flippy
                flipOnHover={false}
                flipOnClick={false}
                flipDirection="vertical"
                isFlipped={flipcard}
                style={{ width: "100%", height: "100%" }}
              >
                {
                  !displayComponent && (
                    <FrontSide
                      animationDuration={this.flipDuration}
                      style={{ ...results, height: "342px", width: "565px" }}
                    >
                      {flipCardTable.includes(questionCount) &&
                        noAnswerMsg === false &&
                        !flipcard ? (
                        <div
                          style={{
                            width: "510px",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src="/images/returnicon.svg"
                            alt="icon-return"
                            width="20px"
                            onClick={() => {
                              this.turnCard();
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <SimpleBar style={{ height: "242px", width: "465px" }}>
                        <div
                          style={{
                            minHeight: "242px",
                            width: "465px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ ...scoreStyle, textAlign: "center" }}>
                            {question}
                          </span>
                          {questionWithCode ? (
                            <div id="flashcard" style={{ width: "100%" }}>
                              <table
                                style={{
                                  ...scoreStyle,
                                  textAlign: "start",
                                  width: "100%",
                                  marginTop: 10,
                                  fontFamily: "consolas",
                                }}
                                className="noBorder"
                              >
                                <tbody>
                                  <tr>
                                    <td>{codeValue}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            ""
                          )}
                          <TextArea
                            rows={4}
                            id="answerStudent"
                            style={{
                              width: "465px",
                              marginTop: 10,
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 300,
                            }}
                            placeholder={t("Écris ta réponse ici...")}
                            onChange={this.onInputChange}
                            value={answerStudent}
                          />
                        </div>
                      </SimpleBar>
                    </FrontSide>
                  )
                }
                {
                  !displayComponent && (
                    <BackSide
                      animationDuration={this.flipDuration}
                      style={{
                        ...results,
                        height: "342px",
                        width: "565px",
                        zIndex: -1,
                      }}
                    >
                      {
                        displayContent &&
                        <>
                          <div
                            style={{
                              width: "510px",
                              marginTop: "-30px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="/images/returnicon.svg"
                              alt="icon-return"
                              width="20px"
                              onClick={() => {
                                this.turnCard();
                              }}
                            />
                          </div>
                          <SimpleBar style={{ height: "242px", width: "465px" }}>
                            <div
                              style={{
                                minHeight: "242px",
                                width: "465px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {/*  <span style={{ ...scoreStyle, fontWeight: 500 }}>{question}</span> */}
                              {flashcard[questionCount]?.answer.length ? (
                                <span
                                  style={{
                                    ...scoreStyle,
                                    fontSize: 14,
                                    marginBottom: answerWithCode ? 20 : 0,
                                  }}
                                >
                                  {answer}
                                </span>
                              ) : (
                                ""
                              )}
                              {answerWithCode ? (
                                <div id="flashcard" style={{ width: "100%" }}>
                                  <table
                                    style={{
                                      ...scoreStyle,
                                      textAlign: "start",
                                      width: "100%",
                                      fontFamily: "consolas",
                                    }}
                                    className="noBorder"
                                  >
                                    <tbody>
                                      <tr>
                                        <td>{answerValue}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </SimpleBar>
                        </>
                      }
                    </BackSide>
                  )
                }



                {
                  !displayComponent && (
                    flipcard ? (
                      <div
                        style={{
                          width: "565px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          style={{ ...button3, marginTop: 15, marginRight: 20 }}
                          type="primary"
                          size="large"
                          onClick={() => {
                            this.checkOpacity();
                            this.wrongAnswerClick();
                            this.nextQuestion();
                          }}
                        >
                          {t("Mauvaise réponse")}
                        </Button>
                        <Button
                          style={{ ...button2, marginTop: 15, marginLeft: 20 }}
                          type="primary"
                          size="large"
                          onClick={() => {
                            this.checkOpacity();
                            this.correctAnswerClick();
                            this.nextQuestion();
                          }}
                        >
                          {t("Bonne réponse")}
                        </Button>
                      </div>
                    ) : flipCardTable.includes(questionCount) &&
                      !noAnswerMsg &&
                      !flipcard ? (
                      ""
                    ) : (
                      <div
                        style={{
                          width: "565px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ ...button2, marginTop: 15 }}
                          type="primary"
                          size="large"
                          onClick={() => {
                            this.turnCard();
                          }}
                        >
                          {t("Voir la réponse")}
                        </Button>
                        {noAnswerMsg ? (
                          <p style={noAnwser}>
                            * {t("Veuillez renseigner votre réponse")}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  )
                }

              </Flippy>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ stepsList, user, batch, tracks }) {
  return { stepsList, user, batch, tracks };
}

function mapDispatchToProps(dispatch) {
  return {
    nextStep: function (step) {
      dispatch({ type: "selectStep", step });
    },
    setDay: function (day) {
      dispatch({ type: "changeDay", day });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Flashcard));

const styles = {
  questionContainer: {
    padding: "16px 32px",
    backgroundColor: "#1f1e2f",
    fontFamily: "courier",
    color: "#ffffff",
    fontSize: 16,
    width: "100%",
  },
  results: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "white",
    borderRadius: 15,
    minHeight: 300,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  results2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#534DE8",
    borderRadius: 15,
    minHeight: 300,
    /* backgroundImage: 'url(/images/backgrounds/bg_quizz_3.png)', */ backgroundSize:
      "cover",
    backgroundRepeat: "no-repeat",
  },
  scoreStyle: {
    fontSize: "16px",
    marginBottom: 0,
    fontFamily: "Poppins, sans-serif",
    color: "#010031",
    fontWeight: 300,
  },
  congratsColor: { color: "#F7F6FD", fontSize: 25 },
  button1: {
    color: "#534DE8",
    backgroundColor: "white",
    border: "2px #534DE8 solid",
    fontSize: "14px",
    marginBottom: "4vh",
    width: "195px",
    height: "39px",
    borderRadius: "50px",
    letterSpacing: "5%",
  },
  button2: {
    backgroundColor: "#534DE8",
    border: "none",
    fontSize: "14px",
    /*marginBottom: '4vh',*/ width: "195px",
    height: "39px",
    borderRadius: 35,
  },
  button3: {
    color: "#534DE8",
    backgroundColor: "white",
    border: "none",
    fontSize: "14px",
    marginBottom: "4vh",
    width: "195px",
    height: "39px",
    borderRadius: "50px",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: "url('/images/backgrounds/ArianeBackground.png')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "80vh",
    overflow: "scroll",
  },
  redoButton: {
    border: "2px solid #5247EA",
    background: "#ffffff",
    color: "#5247EA",
    borderRadius: 35,
    width: "195px" /* margin: '0px 30px' */,
  },
  disabledButton: { opacity: 0.4 },
  noAnwser: {
    fontSize: "12px",
    fontFamily: "Poppins, sans-serif",
    color: "red",
    fontWeight: 300,
  },
};
