import React, { Component } from 'react';
import { Col, Row } from 'antd';

import logo from './Lacapsulerose.png';

export default class Logo extends Component {
  render() {
    const { container, rowHeight, height } = styles;

    return (<Col style={container}>
      <Row align="middle" justify="center" type="flex" style={rowHeight}>
        <a href="/"><img className="logo" src={logo} style={height} /></a>
      </Row>
    </Col>);
  }
}

const styles = {
  container: { width: 200, position: 'absolute' },
  rowHeight: { height: 64 },
  height: { height: 32, marginLeft: "30px" }
}