import typeChecker from '../../typeChecker';

function blockStatement(tc_body = {}, pb_body = {}) {
  const instructions = [];
  if (pb_body.body?.length > 0) {
    tc_body_loop: for (const tc_element of tc_body.body) {
      for (const pb_element of pb_body.body) {
        const found = typeChecker(tc_element, pb_element);

        if (found) {
          instructions.push(found);

          continue tc_body_loop;
        }
      }
    }
  }
  return (instructions.length === tc_body.body.length && instructions.every(e => e === true));
}

export default blockStatement;