import React, { Component } from "react";
import { Col, Row, Avatar, Icon, Button, Switch, Modal } from "antd";
import Timer from "../../Batch/TeacherDashboards/HelpRequest/Timer";
import "../../Batch/Container/Content/Flashcard.css";
import { connect } from "react-redux";
import moment from "moment";
import "./waitlist.css";

const emoji = {
  5: "😟",
  3: "🤔",
  0: "😊",
};

class WaitList extends Component {
  state = {
    modalOpen: false,
    nbRequests: 0,
  };

  openModal = () => {
    const { batchid, user } = this.props;

    fetch(
      `${global.URI_BACKEND}/request/count/${batchid}/${user._id}/${moment
        .utc()
        .format("YYYY-MM-DD")}`
    )
      .then(res => res.json())
      .then(data => {
        if (data.result) {
          this.setState({ nbRequests: data.totalRequests, modalOpen: true });
        }
      });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  handleRequest = () => {
    const { studentRequest } = this.props;

    studentRequest();
    this.closeModal();
  };

  render() {
    const {
      width,
      p12,
      avatar,
      time,
      name,
      spacer,
      switchBtn,
      modal,
      text,
      buttonsContainer,
    } = styles;
    const { requestList, request } = this.props;
    const { userId, user, isStatusAwaySocket, cancelRequest } = this.props;
    const { nbRequests } = this.state;

    let notifications;

    let reaction;
    for (const key in emoji) {
      if (nbRequests >= Number(key)) {
        reaction = emoji[key];
        break;
      }
    }

    if (requestList?.length > 0) {
      notifications = requestList
        .map((e, i) => {
          return (
            <Col key={i} style={p12} span={24}>
              <Row type="flex" align="middle" justify="start">
                {/* <Col flex="46px"> */}
                <Col span={6}>
                  <Avatar
                    shape="square"
                    size={46}
                    src={`${global.URI_FRONTEND}/images/avatar/${e.userId}.jpg`}
                    style={avatar}
                  />
                </Col>
                {/* <Col flex="auto" style={textWrapper}> */}
                <Col span={15}>
                  <Row style={time}>
                    <Timer startDate={new Date(e.date.start).getTime()} />
                  </Row>
                  <Row style={name}>{`${e.firstname} ${e.lastname}`}</Row>
                </Col>
                <Col span={3}>
                  {["superadmin", "teacher"].includes(user.type) || e.userId === userId ||
                  e.teachers.filter(teacher => teacher.teacherId === userId)
                    .length > 0 ? (
                    <Icon type="close-circle" onClick={() => cancelRequest(e)} />
                  ) : null}
                </Col>
              </Row>
            </Col>
          );
        })
        .reduce((prev, curr) => [
          prev,
          <Col span={24} key={Math.random()} style={spacer} />,
          curr,
        ]);
    } else if (user.type === "student") {
      notifications = <Col style={text}>Aucune demande d'aide en attente.</Col>;
    }

    const hasOwnRequest = requestList.some(request => request.userId === user._id)
    return (
      <Row justify="center" style={width}>
        {user.type === "student" && !hasOwnRequest && (
          <Col>
            <Button
              className="colored-button"
              style={{ width: "100%" }}
              type="primary"
              onClick={this.openModal}
            >
              Help Request
            </Button>
            <Modal
              bodyStyle={modal}
              style={{ marginTop: "-45px", zIndex: 1050 }}
              // width={"70vw"}
              footer={null}
              title={null}
              visible={this.state.modalOpen}
              closable={true}
              onCancel={this.closeModal}
              maskStyle={{ zIndex: 1040 }}
              centered
            >
              <p style={text}>
                {reaction}
                {nbRequests
                  ? `Tu as déjà fais ${nbRequests} ${
                      nbRequests === 1 ? "demande d'aide" : "demandes d'aide"
                    } aujourd'hui.`
                  : "Tu n'as pas encore fais de demande d'aide aujourd'hui."}
              </p>
              <p style={text}></p>
              <p style={text}>
                As-tu bien consulté ton buddy avant de faire une demande d'aide
                ? Attention, ton buddy doit être présent pendant la demande !
              </p>
              <p style={text}>Faire la demande ?</p>
              <div style={buttonsContainer}>
                <Button
                  className="outline-button"
                  type="primary"
                  onClick={this.closeModal}
                >
                  Non, je vais continuer à chercher
                </Button>
                <Button
                  className="colored-button"
                  type="primary"
                  onClick={this.handleRequest}
                >
                  Oui, je veux faire la demande
                </Button>
              </div>
            </Modal>
          </Col>
        )}

        {user.type !== "student" && (
          <Col style={switchBtn}>
            <Switch
              className={`help-request-switch ${user.status}`}
              checked={user.status === "away" ? true : false}
              size="large"
              defaultChecked={user.status === "away" ? true : false}
              checkedChildren="away"
              unCheckedChildren={user.status === "available" ? "ready" : "busy"}
              onChange={checked => {
                isStatusAwaySocket(checked);
              }}
            />
          </Col>
        )}
        <Col>{notifications}</Col>
      </Row>
    );
  }
}

function mapStateToProps({ batchid, user }) {
  return { batchid, user };
}

export default connect(mapStateToProps, null)(WaitList);

const styles = {
  width: { width: 260 },
  p12: { paddingTop: 12, paddingBottom: 12 },
  textWrapper: { marginLeft: 16 },
  avatar: { borderRadius: 10 },
  time: { fontSize: 10, color: "#a9a8a8" },
  name: { fontSize: 14, color: "#02002f", fontWeight: "bold" },
  spacer: { borderTop: "1px solid #e8e8e8" },
  switchBtn: { display: "flex", justifyContent: "center" },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "0.85rem",
    fontWeight: 500,
    textAlign: "center",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
  },
};
