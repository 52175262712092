import React, { Component } from "react";
import {
  Col,
  Descriptions,
  Icon,
  Spin,
  Button,
  Tooltip,
  Modal,
  Input,
  DatePicker,
  Form,
  InputNumber,
  Select,
  List,
  Avatar,
  Divider,
  TimePicker,
  Popconfirm,
  message,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import uid2 from "uid2";

import "moment/min/locales";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

const { Item } = Form;
const { Option } = Select;
const { Search } = Input;
moment.locale("en");

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class BatchInfo extends Component {
  state = {
    visibleStaff: false,
    staff: [],
    staffCount: 0,
    visibleStudent: false,
    students: [],
    main: false,
    userSearch: [],
    visible: false,
    confirmLoading: false,
    date: [],
    dateRNCP: null,
    dateIceBreaker: null,
    timeMorningStart: null,
    timeMorningEnd: null,
    timeAfternoonStart: null,
    timeAfternoonEnd: null,
    price: null,
    altPrice: null,
    batchstate: null,
    address: null,
    city: null,
    currentStack: null,
    searchValue: false,
    hours: {},
    editBatchError: ""
  };

  componentDidMount() {
    if (this.props.batch.staff && this.props.batch.staff.length !== 0) {
      const newStaff = this.props.batch.staff;
      this.setState({ staffCount: newStaff.length });

      // fetch(global.URI_BACKEND + `/batch/currentStaff/batch/${this.props.batch._id}`).then(res => res.json()).then(async data => data.success ? this.setState({staff: data.staff, main: data.main}) : null)
    }
    // fetch(global.URI_BACKEND + `/user/batchStudents/${this.props.batch._id}`).then(res => res.json()).then(async data => data.success ? this.setState({students: data.users}) : null)
  }

  getStudents() {
    fetch(
      global.URI_BACKEND + `/batch/currentStaff/batch/${this.props.batch._id}`
    )
      .then(res => res.json())
      .then(async data =>
        data.success
          ? this.setState({ staff: data.staff, main: data.main })
          : null
      );
    fetch(global.URI_BACKEND + `/user/batchStudents/${this.props.batch._id}`)
      .then(res => res.json())
      .then(async data =>
        data.success ? this.setState({ students: data.users }) : null
      );
  }

  showModalStaff = () => {
    this.setState({ visibleStaff: true });
    this.getStudents();
  };

  showModalStudent = () => {
    this.setState({ visibleStudent: true });
    this.getStudents();
  };

  handleCancelStaff = () => {
    this.setState({ visibleStaff: false, userSearch: [] });
  };

  Search = (value, type) => {
    const regex = new RegExp(`${value}.*`, "i");
    type === "staff"
      ? this.setState({
        userSearch: this.props.allStaff.filter(
          e => e.fullname.search(regex) !== -1
        ),
      })
      : this.setState({
        userSearch: this.props.allStudents.filter(
          e => e.fullname.search(regex) !== -1
        ),
      });
  };

  addStaff = (user, main) => {
    fetch(global.URI_BACKEND + `/batch/addStaff`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        batch: this.props.batch,
        user: { _id: user._id, type: user.type, main },
        type: "batch",
      }),
    })
      .then(res => res.json())
      .then(async data =>
        data.success
          ? this.setState({
            staff: [
              ...this.state.staff,
              {
                fullname: user.fullname,
                type: user.type,
                _id: user._id,
                main,
              },
            ],
          })
          : null
      );
  };

  delStaff = user => {
    fetch(
      global.URI_BACKEND +
      `/batch/deleteStaff/batch/${this.props.batch._id}/${JSON.stringify({
        _id: user._id,
        type: user.type,
      })}/null`,
      { method: "DELETE" }
    )
      .then(res => res.json())
      .then(async data =>
        data.success
          ? this.setState({
            staff: this.state.staff.filter(e => e._id !== user._id),
          })
          : null
      );
  };

  addStudent = user => {
    fetch(global.URI_BACKEND + `/user/v2`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        type: user.type,
        batchId: this.props.batch._id,
      }),
    })
      .then(res => res.json())
      .then(async data =>
        data.success
          ? this.setState({ students: [...this.state.students, data.user] })
          : null
      );
  };

  delStudent = user => {
    fetch(
      global.URI_BACKEND +
      `/user/deleteUserFromBatch/${this.props.batch._id}/${user._id}`,
      { method: "DELETE" }
    )
      .then(res => res.json())
      .then(async data =>
        data.success
          ? this.setState({
            students: this.state.students.filter(e => e._id !== user._id),
          })
          : null
      );
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { batch } = this.props;
    const {
      city,
      date,
      address,
      batchstate,
      price,
      altPrice,
      currentStack,
      dateRNCP,
      dateIceBreaker,
      hours,
    } = this.state;

    this.setState({ confirmLoading: true, logError: "", editBatchError: "" }, () => {
      const body = {
        id: batch._id,
        city: city ? city.charAt(0).toUpperCase() + city.slice(1) : null,
        sessionExamStart: dateRNCP
          ? moment(dateRNCP).tz("Africa/Abidjan")
          : null,
        iceBreaker: dateIceBreaker
          ? moment(dateIceBreaker).tz("Africa/Abidjan")
          : null,
        date:
          date.length !== 0
            ? {
              start: moment(date[0]).tz("Africa/Abidjan"),
              end: moment(date[1]).tz("Africa/Abidjan"),
            }
            : null,
        address: address ? address : null,
        state: batchstate ? batchstate : null,
        price: price ? price : null,
        altPrice: altPrice ? altPrice : undefined,
        stackId: currentStack ? currentStack : null,
        hours: Object.keys(hours).length ? hours : undefined,
      };

      fetch(global.URI_BACKEND + "/batch/updateInfo", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
        .then(res => res.json())
        .then(async data => {
          if (data.success) {
            this.props.changeBatch(data.batch, data.batch._id);
            this.setState({ visible: false, confirmLoading: false });
          } else {
            this.setState({
              confirmLoading: false,
              editBatchError: "Erreur, le batch n'a pas pu être modifié",
            });
          }
        });
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCancelStudent = () => {
    this.setState({ visibleStudent: false, userSearch: [] });
  };

  confirm(e) {
    this.delBatch();
  }

  cancel(e) {
    console.log(e);
    message.error("Suppression annulé");
  }

  delBatch = () => {
    message.success("Batch supprimé");
    fetch(
      global.URI_BACKEND +
      `/batch/deleteBatch/${this.props.batch._id}/${this.props.batch.stackId}`,
      { method: "DELETE" }
    )
      .then(res => res.json())
      .then(async data =>
        data.success ? this.props.changeBatch(null, this.props.batch._id) : null
      );
  };

  // hours
  onChangeTime = (period, key, time) => {
    const { hours } = this.state;
    const newHours = { ...hours }
    if (!newHours[period]) newHours[period] = {};

    newHours[period][key] = moment(time).tz('Africa/Abidjan');
    this.setState({ hours: newHours });
  };

  render() {
    const { center, m5, icon } = styles;
    const { batch, batchstate, stackTitle, stacks } = this.props;
    const {
      staff,
      visibleStaff,
      userSearch,
      visible,
      confirmLoading,
      main,
      searchValue,
      students,
      visibleStudent,
      staffCount,
      editBatchError,
    } = this.state;
    const { t } = this.props;

    const inArray = (id, type) => {
      let isInArray;
      type === "staff"
        ? (isInArray = staff.filter(e => e._id === id))
        : (isInArray = students.filter(e => e._id === id));
      return isInArray.length !== 0 ? true : false;
    };

    let batchPeriods = {};
    const periods = ["morning", "afternoon", "evening" /*"officeHours"*/];

    if (batch.planning) {
      batchPeriods = batch.planning.reduce((a, c) => {
        c.days.forEach(day => {
          for (const key in day) {

            if (
              !a[key] &&
              periods.includes(key) &&
              Boolean(day[key]?.start) &&
              Boolean(day[key]?.end)
            )
              a[key] = day[key];
          }
        });

        return a;
      }, {});
    }
    const timeFormat = "HH:mm";
    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 20,
          padding: 20,
          display: "flex",
          margin: 15,
          borderBottom: "1px solid #E7E8ED",
          borderRight: "1px solid #E7E8ED",
        }}
      >
        <Descriptions
          style={{ opacity: batch.state === "open" ? 1 : 0.5 }}
          title={
            <>
              {batch.name}{" "}
              {batch.state === "open" ? (
                <Icon type="eye" style={{ marginLeft: 5 }} />
              ) : (
                <Icon type="eye-invisible" style={{ marginLeft: 5 }} />
              )}
            </>
          }
        >
          <Descriptions.Item
            label={
              <>
                <Icon
                  type="calendar"
                  style={{
                    fontSize: 19,
                    color:
                      batchstate === "En Cours"
                        ? "lime"
                        : batchstate === "Passé"
                          ? "red"
                          : "grey",
                    marginRight: 3,
                  }}
                />{" "}
                {t("Début")}
              </>
            }
          >
            {moment(batch.date.start).format("DD-MM-YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label={t("Fin")}>
            {moment(batch.date.end).format("DD-MM-YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Stack">{stackTitle}</Descriptions.Item>
          <Descriptions.Item label={t("Adresse")}>
            {batch.address}
          </Descriptions.Item>
          <Descriptions.Item label={t("Staff")}>
            <span
              style={{ textDecoration: "underline", color: "#F94A56" }}
              onClick={this.showModalStaff}
            >
              Voir Staff
            </span>{" "}
            ({staffCount}
            {staffCount < 2 ? " affecté" : " affectés"})
          </Descriptions.Item>
          <Descriptions.Item label={t("Prix")}>
            {batch.price ? `${batch.price} €` : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Date IceBreaker">
            {batch.date.iceBreaker
              ? moment(batch.date.iceBreaker).format("DD-MM-YYYY à HH:mm")
              : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Date du passage de titre RNCP">
            {batch.date.sessionExamStart
              ? moment(batch.date.sessionExamStart).format("DD-MM-YYYY")
              : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label={t("Prix alternatif")}>
            {batch.altPrice ? `${batch.altPrice} €` : "N/A"}
          </Descriptions.Item>
          <br />
          {periods.map(period => {
            const hasPeriod = batchPeriods[period];
            return hasPeriod ? (
              <Descriptions.Item label={"Horaires " + t(period)} key={hasPeriod._id}>
                {`${moment(hasPeriod.start).format(timeFormat)} - ${moment(
                  hasPeriod.end
                ).format(timeFormat)}`}
              </Descriptions.Item>
            ) : null;
          })}
        </Descriptions>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            marginRight: 15,
          }}
        >
          <Tooltip title={t("Modifier ce batch")}>
            <Icon type="edit" style={icon} onClick={this.showModal} />
          </Tooltip>
          <Tooltip title={t("Ajouter un utilisateur")}>
            <Icon
              type="user-add"
              style={icon}
              onClick={this.showModalStudent}
            />
          </Tooltip>
          <Popconfirm
            placement="topRight"
            title="Etes vous sure de vouloir supprimer ce batch ?"
            onConfirm={this.delBatch}
            onCancel={this.cancel}
            okText="Oui"
            cancelText="Non"
          >
            <Tooltip title={t("Supprimer ce batch")}>
              <Icon type="close-circle" style={icon} />
            </Tooltip>
          </Popconfirm>
        </div>

        {/*Modal add student*/}
        <Modal
          title={t("Ajouter un utilisateur") + ` - ${batch.name}`}
          visible={visibleStudent}
          onOk={this.handleCancelStudent}
          onCancel={this.handleCancelStudent}
        >
          <Search
            placeholder={t("Rechercher les utilisateurs")}
            style={{ marginBottom: 10 }}
            allowClear
            onSearch={value => {
              if (value) {
                this.Search(value, "students");
                this.setState({ searchValue: true });
              } else {
                this.setState({ userSearch: [], searchValue: false });
              }
            }}
          />
          {userSearch.length === 0 && searchValue ? (
            <p>{t("Aucun utilisateur trouvé")}</p>
          ) : (
            <List>
              {userSearch.map((e, i) => {
                return (
                  <List.Item key={i + 3}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={`${global.URI_FRONTEND}/images/avatar/${e._id}.jpg`}
                        />
                      }
                      title={e.fullname}
                      description={e.type}
                    />
                    {inArray(e._id, "students") ? (
                      <Icon
                        type="check-circle"
                        style={{ fontSize: 19, color: "lime" }}
                      />
                    ) : (
                      <Tooltip title={t("Ajouter à l'équipe")}>
                        <Icon
                          type="check-circle"
                          style={{ fontSize: 19, color: "grey" }}
                          onClick={() => this.addStudent(e)}
                        />
                      </Tooltip>
                    )}
                  </List.Item>
                );
              })}
            </List>
          )}
          <Divider style={{ marginTop: 10, marginBottom: 15 }} />
          <h4>{t("Élèves actuels")}</h4>
          {students.length === 0 ? (
            <p>{t("Aucun utilisateur ajouté")}</p>
          ) : (
            <List>
              {students.map((e, i) => {
                return (
                  <List.Item key={i + 4}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={`${global.URI_FRONTEND}/images/avatar/${e._id}.jpg`}
                        />
                      }
                      title={e.fullname}
                      description={e.type}
                    />
                    <Icon
                      type="close-circle"
                      style={{ fontSize: 19, color: "red" }}
                      onClick={() => this.delStudent(e)}
                    />
                  </List.Item>
                );
              })}
            </List>
          )}
        </Modal>

        {/*Modal add staff*/}
        <Modal
          title={t("Gestion des teachers") + ` - ${batch.name}`}
          visible={visibleStaff}
          onOk={this.handleCancelStaff}
          onCancel={this.handleCancelStaff}
        >
          <h4>{t("Équipe actuelle")}</h4>
          {staff.length === 0 ? (
            <p>{t("Aucun utilisateur ajouté")}</p>
          ) : (
            <List>
              {staff.map((e, i) => {
                return (
                  <List.Item key={i + 4}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={`${global.URI_FRONTEND}/images/avatar/${e._id}.jpg`}
                        />
                      }
                      title={e.fullname}
                      description={e.main ? "main " + e.type : e.type}
                    />
                    <Icon
                      type="close-circle"
                      style={{ fontSize: 19, color: "red" }}
                      onClick={() => {
                        this.delStaff(e);
                        if (e.main) this.setState({ main: false });
                      }}
                    />
                  </List.Item>
                );
              })}
            </List>
          )}
          <Divider style={{ marginTop: 0 }} />
          <Search
            placeholder={t("Rechercher dans l'équipe")}
            style={{ marginBottom: 10 }}
            allowClear
            onSearch={value => {
              if (value) {
                this.Search(value, "staff");
                this.setState({ searchValue: true });
              } else {
                this.setState({ userSearch: [], searchValue: false });
              }
            }}
          />
          {userSearch.length === 0 && searchValue ? (
            <p>{t("Aucun utilisateur trouvé")}</p>
          ) : (
            <List>
              {userSearch.map((e, i) => {
                return (
                  <List.Item key={i + 3}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={`${global.URI_FRONTEND}/images/avatar/${e._id}.jpg`}
                        />
                      }
                      title={e.fullname}
                      description={e.type}
                    />
                    {inArray(e._id, "staff") ? (
                      <Icon
                        type="check-circle"
                        style={{ fontSize: 19, color: "lime" }}
                      />
                    ) : main ? (
                      <Tooltip title={t("Ajouter à l'équipe")}>
                        <Icon
                          type="check-circle"
                          style={{ fontSize: 19, color: "grey" }}
                          onClick={() => this.addStaff(e, false)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        trigger="click"
                        title={
                          <div style={{ padding: 2 }}>
                            {t("Professeur principal")} ?
                            <Icon
                              type="check-circle"
                              style={{
                                fontSize: 15,
                                marginLeft: 5,
                                color: "lime",
                              }}
                              onClick={() => {
                                this.setState({ main: true });
                                this.addStaff(e, true);
                              }}
                            />
                            <Icon
                              type="close-circle"
                              style={{
                                fontSize: 15,
                                color: "red",
                                marginLeft: 5,
                              }}
                              onClick={() => this.addStaff(e, false)}
                            />
                          </div>
                        }
                      >
                        <Icon
                          type="check-circle"
                          style={{ fontSize: 19, color: "grey" }}
                        />
                      </Tooltip>
                    )}
                  </List.Item>
                );
              })}
            </List>
          )}
        </Modal>

        {/*Modal edit batch*/}
        <Modal
          title={t("Modifier le") + ` ${batch.name}`}
          visible={visible}
          onOk={this.handleSubmit}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <Form>
            {editBatchError && <p style={{ color: "#FF757A" }}>{editBatchError}</p>}
            <p>
              Batch prevu du{" "}
              <strong>
                {`${moment(batch.date.start).format("DD-MM-YYYY")} - ${moment(
                  batch.date.end
                ).format("DD-MM-YYYY")}`}
              </strong>{" "}
            </p>
            <div>
              <span>Modifier la date du debut ? :</span>
              <DatePicker
                defaultValue={moment(batch.date.start)}
                onChange={value => this.setState({ date: [value, value] })}
                placeholder="Modifier la date de debut du Batch"
              />
            </div>
            <br />
            <div>
              {periods.map(period => {
                const hasPeriod = batchPeriods[period];
                return hasPeriod ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                    key={hasPeriod._id}
                  >
                    <span>Horaires {t(period)}</span>
                    <TimePicker
                      defaultValue={moment(hasPeriod.start)}
                      onChange={(time) => this.onChangeTime(period, "start", time)}
                      format={timeFormat}
                      style={{ marginRight: 5, marginLeft: 5 }}
                    />
                    {" - "}
                    <TimePicker
                      defaultValue={moment(hasPeriod.end)}
                      onChange={(time) => this.onChangeTime(period, "end", time)}
                      format={timeFormat}
                      style={{ marginLeft: 5 }}

                    />
                  </div>
                ) : null;
              })}
              <div>
                IceBreaker{" "}
                <DatePicker
                  showTime
                  placeholder="Ice Breaker"
                  defaultValue={
                    batch.date.iceBreaker
                      ? moment(batch.date.iceBreaker)
                      : moment(batch.date.start)
                  }
                  onChange={value => this.setState({ dateIceBreaker: value })}
                  onOk={val => console.log(val)}
                />
              </div>
              <br />
              <div>
                Titre RNCP{" "}
                <DatePicker
                  defaultValue={
                    batch.date.sessionExamStart
                      ? moment(batch.date.sessionExamStart)
                      : moment(batch.date.end)
                  }
                  onChange={value => this.setState({ dateRNCP: value })}
                />
              </div>
            </div>
            <div>
              <span>Prix : </span>
              <InputNumber
                style={m5}
                placeholder={t("Prix")}
                defaultValue={batch.price}
                onChange={value => this.setState({ price: value })}
              />
            </div>
            <div>
              <span>Prix alternatif : </span>
              <InputNumber
                style={m5}
                placeholder={t("Prix")}
                defaultValue={batch.altPrice}
                onChange={value => this.setState({ altPrice: value })}
              />
            </div>
            <div>
              <span>Etat: </span>
              <Select
                style={{ width: 120, margin: 10 }}
                placeholder={t("Sélectionner un état")}
                defaultValue={batch.state}
                onChange={value => this.setState({ batchstate: value })}
              >
                <Option value="open">{t("Ouvert")}</Option>
                <Option value="close">{t("Fermé")}</Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p>Adresse: {batch.address}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span>Stack: </span>
              <Select
                defaultValue={stackTitle}
                onChange={value => this.setState({ currentStack: value })}
              >
                {stacks.map((stack, i) => {
                  return (
                    <Option key={i + 10} value={stack._id}>
                      {stack.title}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

const BatchInfoTr = withTranslation()(BatchInfo);

class EditBatch extends Component {
  state = {
    stacks: [],
    batches: [],
    visible: false,
    confirmLoading: false,
    logError: "",
    allStaff: [],
    students: [],
    campus: [],
    loadingScreen: true,
    currentCampus: "all",
    currentStack: "all",
    currentBatchState: null,
  };

  componentDidMount() {
    this.props.form.validateFields();

    fetch(global.URI_BACKEND + "/batch/allData", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user: this.props.user }),
    })
      .then(res => res.json())
      .then(async data =>
        data.batches
          ? this.setState({
            batches: data.batches.sort(
              (a, b) => b.batchNumber - a.batchNumber
            ),
            loadingScreen: false,
          })
          : null
      );

    fetch(
      global.URI_BACKEND +
      `/batch/relatedInfo/${this.props.user.type}/${JSON.stringify(
        this.props.user.assignedCities
      )}`
    )
      .then(res => res.json())
      .then(async data =>
        data.success
          ? this.setState({
            allStaff: data.users,
            stacks: data.stacks,
            campus: data.campus,
            students: data.students,
          })
          : null
      );
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ confirmLoading: true, logError: "" }, () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const {
            campus,
            date,
            name,
            number,
            price,
            altPrice,
            stack,
            state,
            franchised,
          } = values;
          const stackjson = JSON.parse(stack);
          const body = {
            batchNumber: number,
            stackId: stackjson._id,
            stackName: stackjson.title,
            campus: campus,
            date: date.format("YYYY-MM-DD"),
            franchised,
          };

          if (name) body.name = name;
          if (price) body.price = price;
          if (altPrice) body.altPrice = altPrice;
          if (state === "open") body.state = state;

          fetch(global.URI_BACKEND + "/batch", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          })
            .then(res => res.json())
            .then(async data => {
              if (data.success) {
                let tempBatches = [...this.state.batches];
                tempBatches.unshift(data.batch);
                this.setState({
                  visible: false,
                  confirmLoading: false,
                  batches: tempBatches,
                });
              } else {
                this.setState({
                  logError: "Erreur, le batch n'a pas pu être créé",
                });
              }
            });
        }
      });
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  changeBatch = (batch, batchId) => {
    const { batches } = this.state;
    const tempBatches = batches.map(el =>
      batch && el._id === batchId ? batch : el
    );

    this.setState({ batches: tempBatches });
  };

  batchState = date => {
    return moment().isBefore(moment(date.start))
      ? "À Venir"
      : moment().isBetween(moment(date.start), moment(date.end))
        ? "En Cours"
        : "Passé";
  };

  render() {
    const { center, item, select } = styles;
    const {
      visible,
      confirmLoading,
      batches,
      stacks,
      allStaff,
      campus,
      loadingScreen,
      currentCampus,
      currentBatchState,
      currentStack,
      students,
    } = this.state;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;
    const { t } = this.props;

    const dateError = isFieldTouched("date") && getFieldError("date");
    const campusError = isFieldTouched("campus") && getFieldError("campus");
    const stackError = isFieldTouched("stack") && getFieldError("stack");

    let batcheslist =
      currentCampus === "all" && currentStack === "all"
        ? batches.map((batch, i) => {
          if (
            !this.state.currentBatchState ||
            this.batchState(batch.date) === this.state.currentBatchState
          ) {
            return (
              <BatchInfoTr
                key={uid2(5)}
                index={i}
                batch={batch}
                allStudents={students}
                allStaff={allStaff}
                changeBatch={this.changeBatch}
                batchstate={this.batchState(batch.date)}
                stacks={stacks}
                stackTitle={stacks.find(e => e._id === batch.stackId)?.title}
              />
            );
          } else {
            return null;
          }
        })
        : currentCampus !== "all" && currentStack === "all"
          ? batches
            .filter(e => e.city === currentCampus)
            .map((batch, i) => {
              if (
                !this.state.currentBatchState ||
                this.batchState(batch.date) === this.state.currentBatchState
              ) {
                return (
                  <BatchInfoTr
                    key={uid2(5)}
                    index={i}
                    batch={batch}
                    allStudents={students}
                    allStaff={allStaff}
                    changeBatch={this.changeBatch}
                    batchstate={this.batchState(batch.date)}
                    stacks={stacks}
                    stackTitle={
                      stacks.find(e => e._id === batch.stackId)?.title
                    }
                  />
                );
              } else {
                return null;
              }
            })
          : currentCampus === "all" && currentStack !== "all"
            ? batches
              .filter(e => e.stackId === currentStack)
              .map((batch, i) => {
                if (
                  !this.state.currentBatchState ||
                  this.batchState(batch.date) === this.state.currentBatchState
                ) {
                  return (
                    <BatchInfoTr
                      key={uid2(5)}
                      index={i}
                      batch={batch}
                      allStudents={students}
                      allStaff={allStaff}
                      changeBatch={this.changeBatch}
                      batchstate={this.batchState(batch.date)}
                      stacks={stacks}
                      stackTitle={
                        stacks.filter(e => e._id === batch.stackId)[0].title
                      }
                    />
                  );
                } else {
                  return null;
                }
              })
            : batches
              .filter(e => e.stackId === currentStack && e.city === currentCampus)
              .map((batch, i) => {
                if (
                  !this.state.currentBatchState ||
                  this.batchState(batch.date) === this.state.currentBatchState
                ) {
                  return (
                    <BatchInfoTr
                      key={uid2(5)}
                      index={i}
                      batch={batch}
                      allStudents={students}
                      allStaff={allStaff}
                      changeBatch={this.changeBatch}
                      batchstate={this.batchState(batch.date)}
                      stacks={stacks}
                      stackTitle={
                        stacks.filter(e => e._id === batch.stackId)[0].title
                      }
                    />
                  );
                } else {
                  return null;
                }
              });

    return (
      <Col span={24}>
        <Tooltip title={t("Créer un nouveau batch")} placement="right">
          <Button
            type="primary"
            ghost
            shape="circle"
            onClick={this.showModal}
            style={{ fontSize: 20, margin: 10, marginLeft: 25 }}
          >
            +
          </Button>
        </Tooltip>

        <Select
          style={{ width: 120, marginLeft: 20 }}
          value={currentCampus}
          onChange={value => this.setState({ currentCampus: value })}
        >
          <Option value="all">{t("Tous les campus")}</Option>
          {campus.map((campus, i) => {
            return (
              <Option key={i + 6} value={campus.campus}>
                {campus.campus}
              </Option>
            );
          })}
        </Select>

        <Select
          style={{ width: 150, marginLeft: 20 }}
          value={currentBatchState}
          onChange={value => this.setState({ currentBatchState: value })}
        >
          <Option value={null}>{t("Tous les batchs")}</Option>
          <Option value="Passé">{t("Batchs passés")}</Option>
          <Option value="En Cours">{t("Batchs en cours")}</Option>
          <Option value="À Venir">{t("Batchs à venir")}</Option>
        </Select>

        <Select
          style={{ width: 210, marginLeft: 20 }}
          value={currentStack}
          onChange={value => this.setState({ currentStack: value })}
        >
          <Option value="all">{t("Toutes les stacks")}</Option>
          {stacks.map((stack, i) => {
            return (
              <Option key={i + 9} value={stack._id}>
                {stack.title}
              </Option>
            );
          })}
        </Select>

        {loadingScreen && (
          <div style={{ ...center, height: 500 }}>
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />}
            />
          </div>
        )}

        {batcheslist}

        {/*Modal create batch*/}
        <Modal
          title={t("Créer un nouveau batch")}
          visible={visible}
          onOk={this.handleSubmit}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          okButtonProps={{ disabled: hasErrors(getFieldsError()) }}
        >
          <Form style={{ ...center, flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <Item
                style={{ marginBottom: 15 }}
                name="date"
                validateStatus={dateError ? "error" : ""}
                help={dateError || ""}
              >
                {getFieldDecorator("date", {
                  rules: [
                    { required: true, message: "Please enter valid dates." },
                  ],
                })(
                  <DatePicker
                    placeholder={t("Selectionner une date de départ")}
                  />
                )}
              </Item>
              <Item style={{ marginBottom: 15 }} name="price">
                {getFieldDecorator("price")(
                  <InputNumber placeholder={t("Prix")} />
                )}
              </Item>
              <Item style={{ marginBottom: 15 }} name="altPrice">
                {getFieldDecorator("altPrice")(
                  <InputNumber placeholder={t("Prix alternatif")} />
                )}
              </Item>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <Item style={select} name="state">
                {getFieldDecorator("state")(
                  <Select placeholder={t("Sélectionner un état")}>
                    <Option value="open">{t("Ouvert")}</Option>
                    <Option value="closed">{t("Fermé")}</Option>
                  </Select>
                )}
              </Item>
              <Item style={select} name="franchised">
                {getFieldDecorator("franchised")(
                  <Select placeholder="Franchised">
                    <Option value={true}>{t("Oui")}</Option>
                    <Option value={false}>{t("Non")}</Option>
                  </Select>
                )}
              </Item>
              <Item
                style={select}
                name="campus"
                validateStatus={campusError ? "error" : ""}
                help={campusError || ""}
              >
                {getFieldDecorator("campus", {
                  rules: [
                    { required: true, message: "Please choose a campus." },
                  ],
                })(
                  <Select placeholder="Campus">
                    {campus.map((campus, i) => {
                      return (
                        <Option key={i + 2} value={JSON.stringify(campus)}>
                          {campus.campus}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Item>
            </div>

            <Item
              style={item}
              name="stack"
              validateStatus={stackError ? "error" : ""}
              help={stackError || ""}
            >
              {getFieldDecorator("stack", {
                rules: [{ required: true, message: "Please enter stack ID." }],
              })(
                <Select placeholder="Please select a stack">
                  {stacks.map((stack, i) => {
                    return (
                      <Option key={i + 1} value={JSON.stringify(stack)}>
                        {stack.title}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Item>
          </Form>
        </Modal>
      </Col>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

const WrappedEditBatch = Form.create({ name: "create_batch" })(EditBatch);
export default connect(
  mapStateToProps,
  null
)(withTranslation()(WrappedEditBatch));

const styles = {
  item: { marginBottom: 5, width: "88%" },
  select: { width: 120, marginBottom: 15 },
  center: { display: "flex", justifyContent: "start", alignItems: "center" },
  m5: { margin: 10 },
  icon: { fontSize: 19, color: "#F94A56" },
};
