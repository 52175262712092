import React, { Component } from 'react';
import { Col, Row, Icon, Input } from 'antd';

export default class Prototype extends Component {
  state = {
    id: '',
    uriTrello: null,
    uriMockup: null,
    uriUserStory: null,
    editUriTrello: false,
    editUriMockup: false,
    editUriUserStory: false,
    editable: false
  }

  edit = {}
  timeout = {}

  componentDidMount() {
    const { prototype: { uriTrello, uriMockup, uriUserStory } = {}, id, editable } = this.props;

    this.setState({ uriTrello, uriMockup, uriUserStory, id, editable });
  }

  onEditHandle = e => {
    let type = e.replace('edit', '');
    type = type[0].toLowerCase() + type.slice(1);

    if (this.state[e] && this.state[type] !== this.edit[type]) {
      const { id } = this.state;
      const { user: { firstname, lastname, _id }, batchid, updateId, updateProjectPrototype } = this.props;

      const myself = { firstname, lastname, userId: _id };
      const dataToSend = id ? { [type]: this.state[type].trim() } : { [type]: this.state[type].trim(), myself, batchid };

      fetch(`${global.URI_BACKEND}/project/${id || 'new'}/projectPrototype/${type}`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend)
      }).then(res => res.json()).then(data => {
        if (!data?.error) {
          const { _id } = data;

          if (this.timeout[e]) clearTimeout(this.timeout[e]);
          updateId(_id);
          this.setState({ [type]: data.projectPrototype[type], [e]: !this.state[e], id: _id }, () => {
            const { uriTrello, uriMockup, uriUserStory } = this.state;
            updateProjectPrototype({ uriTrello, uriMockup, uriUserStory });
          });
        }
      });
    } else {
      this.edit[type] = this.state[type];
      this.timeout[e] = setTimeout(() => this.onCancelEdit(e), 30000);
      this.setState({ [e]: !this.state[e] });
    }
  }

  onCancelEdit = e => {
    let type = e.replace('edit', '');
    type = type[0].toLowerCase() + type.slice(1);

    if (this.timeout[e]) clearTimeout(this.timeout[e]);

    this.setState({ [e]: false, [type]: this.edit[type] });
  }

  onInputChange = e => {
    const { id, value } = e.target;

    this.setState({ [id]: value });
  }

  render() {
    const { card, title, editIcon, description, link, imageContainer, image, noData } = styles;
    const { uriTrello, uriMockup, uriUserStory, editUriTrello, editUriMockup, editUriUserStory, editable } = this.state;

    return <Row gutter={28}>
      <Col xs={24} xl={8}>
        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>Trello</div>
            {
              !editUriTrello && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editUriTrello')} />
                </div>
                : editUriTrello && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editUriTrello')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editUriTrello')} />
                </div>
            }
          </Row>
          <div style={description}>
            {
              !editUriTrello
                ? uriTrello
                  ? <a style={link} href={uriTrello} target="_blank" rel="noopener noreferrer">{uriTrello}</a>
                  : <div style={noData}>Lien incorrect ou manquant</div>
                : <Input id="uriTrello" placeholder={uriTrello} onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editUriTrello')} value={uriTrello} />
            }
            <Row style={imageContainer} type="flex" justify="center">
              <img src="/trello.png" alt="trello" style={image} />            
            </Row>
          </div>
        </div>
      </Col>

      <Col xs={24} xl={8}>
        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>Prototype</div>
            {
              !editUriMockup && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editUriMockup')} />
                </div>
                : editUriMockup && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editUriMockup')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editUriMockup')} />
                </div>
            }
          </Row>
          <div style={description}>
            {
              !editUriMockup
                ? uriMockup
                  ? <a style={link} href={uriMockup} target="_blank" rel="noopener noreferrer">{uriMockup}</a>
                  : <div style={noData}>Lien incorrect ou manquant</div>
                : <Input id="uriMockup" placeholder={uriMockup} onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editUriMockup')} value={uriMockup} />
            }
            <Row style={imageContainer} type="flex" justify="center">
              <img src="/proto.jpeg" style={image} alt="prototype" />
            </Row>
          </div>
        </div>
      </Col>

      <Col xs={24} xl={8}>
        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>Whimsical</div>
            {
              !editUriUserStory && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editUriUserStory')} />
                </div>
                : editUriUserStory && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editUriUserStory')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editUriUserStory')} />
                </div>
            }
          </Row>
          <div style={description}>
            {
              !editUriUserStory
                ? uriUserStory
                  ? <a style={link} href={uriUserStory} target="_blank" rel="noopener noreferrer">{uriUserStory}</a>
                  : <div style={noData}>Lien incorrect ou manquant</div>
                : editUriUserStory && <Input id="uriUserStory" placeholder={uriUserStory} onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editUriUserStory')} value={uriUserStory} />
            }
            <Row style={imageContainer} type="flex" justify="center">
              <div style={image}>
                {/* placeholder image */}
              </div>
            </Row>
          </div>
        </div>
      </Col>
    </Row>;
  }
}

const styles = {
  card: { borderRadius: 9, backgroundColor: 'white', marginBottom: 22, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)' },
  title: { padding: 24, fontWeight: 'bold', fontSize: 17, borderBottom: '1px solid #f5f5f5' },
  description: { padding: 24, fontWeight: 'light', fontSize: 14, color: '#444444' },
  link: { fontSize: 14, marginBottom: 20 },
  imageContainer: { display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 10 },
  image: { width: '100%', borderRadius: 11 },
  editIcon: { color: '#000000', opacity: 0.65, fontSize: 18, cursor: 'pointer', marginLeft: 10 },
  noData: { color: '#bbb' }
}