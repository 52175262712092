import React, { Component } from "react";

//STYLES______________________________
import "./Course.css";

//UI__________________________________
import {
  Row,
  Col,
  Progress,
  Tabs,
  Spin,
  Icon,
  Button,
  Popover,
  message,
  Checkbox,
  List,
  Select,
  Anchor,
} from "antd";
import { Player, BigPlayButton, LoadingSpinner } from "video-react";

//REDUX______________________________
import { connect } from "react-redux";

//UID_____________________________
import uid2 from "uid2";

//HTML PARSE___________________________
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";

//MOMENT______________________________
import moment from "moment";

//SCROLLBAR___________________________
import SimpleBar from "simplebar-react";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

const { TabPane } = Tabs;
const { Option } = Select;
const { Item } = List;
const { Link } = Anchor;

class Course extends Component {
  //STATE__________________________________
  state = {
    videoLock: true,
    current: null,
    overview: null,
    courses: null,
    resumes: null,
    summaryOverview: [],
    summaryContent: [],
    sumEachPart: [],
    progressState: [],
    checked: [],
    stepPercentageTable: [],
    stepProgression: 0,
    section: 0,
    noRessource: false,
    lastRequest: null,
  };

  constructor(props) {
    super(props);

    this.courseContainer = React.createRef();
    this.resumeContainer = React.createRef();
  }

  //DATA_________________________
  solutions = {};

  doc = { overview: null, content: null, resume: null };

  timeLockSolution = 15 * 1000 * 60;
  timeLockHelp = 7 * 1000 * 60;
  timeLock = this.timeLockHelp;

  courseList = [];
  resumeList = [];

  tabRefs = [];
  linkRefs = [];

  resumeTabRefs = [];
  resumeLinkRefs = [];

  //LIFE CYCLE_____________________
  componentDidMount() {
    const {
      externalData,
      stepData,
      overviewProject,
      user,
      id,
      postprogram,
      batch,
    } = this.props;
    const { current } = this.state;

    if (externalData) {
      this.setState({ current: "courses" });
      setInterval(this.checkUnlockTime, 1 * 1000 * 60);
    } else {
      this.setState({ current: "slides" });
    }

    const cpyChecked = [];
    const tempStepTable = [];
    var tempStepProgression2 = 0;

    fetch(`${global.URI_BACKEND}/stepprogression/user/${user._id}/${batch._id}`)
      .then(res => res.json())
      .then(data => {
        if (data.success && data.progression !== null) {
          const progressStateFromBack = [];
          tempStepProgression2 = 0;
          for (const e of data.progression.progression) {
            if (e.step === stepData.stepId) {
              tempStepProgression2 = Math.round(e.stepPercentage);
              for (const f of e.sections) {
                progressStateFromBack.push({
                  index: f.index,
                  title: f.title,
                  date: f.date,
                  percentage: f.percentage,
                  weightInPercentage:
                    f.weightInPercentage /* mainGroup: f.mainGroupSections, secondaryGroup: f.secondaryGroupSections */,
                });
                if (e.stepPercentage && f.percentage != null) {
                  if (
                    stepData.stepType === "slide" &&
                    stepData.stepType_v2 === "course"
                  ) {
                    tempStepTable.push(Math.round(f.percentage));
                  } else {
                    tempStepTable.push(f.percentage * f.weightInPercentage);
                  }
                } else if (e.stepPercentage && f.percentage == null) {
                  tempStepTable.push(0);
                }
                // Gestion checkbox
                if (f.percentage !== 0) {
                  cpyChecked.push(f.index);
                }
              }
            }
          }
          this.setState({
            progressState: progressStateFromBack,
            stepProgression: tempStepProgression2,
            checked: cpyChecked,
            stepPercentageTable: tempStepTable,
          });
        }

        if (postprogram) {
          this.loadContent(externalData, "content", true, id);
        } else if (stepData.stepType_v2 === "challenge") {
          this.loadContent(externalData, "content", true, stepData.stepId);
        } else if (current === "overview") {
          this.loadContent(
            overviewProject.externalData,
            "overview",
            false,
            overviewProject._id
          );
        } else {
          this.loadContent(externalData, "content", true, stepData.stepId);
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { externalData: prevExternalData } = prevProps;
    const { externalData, stepData, overviewProject, resume } = this.props;
    const { current: prevCurrent } = prevState;
    const { current } = this.state;

    if (prevCurrent !== current) {
      if (current === "resume") {
        this.resumeList = [];
        this.loadContent(resume.uri, "resume", false, stepData.stepId);
      } else if (current === "overview") {
        this.loadContent(
          overviewProject.externalData,
          "overview",
          false,
          overviewProject._id
        );
      }
    }

    if (prevExternalData !== externalData) {
      this.solutions = {};
      this.doc = { overview: null, content: null };
      this.timeLockSolution = 15 * 1000 * 60;
      this.timeLockHelp = 7 * 1000 * 60;
      this.timeLock = this.timeLockHelp;
      this.courseList = [];
      this.resumeList = [];

      this.setState(
        { videoLock: false, current: "courses", overview: null, courses: null },
        () => {
          if (externalData) this.setState({ current: "courses" });
          else this.setState({ current: "slides" });

          if (stepData.stepType_v2 === "project" && overviewProject) {
            this.loadContent(
              overviewProject.externalData,
              "overview",
              false,
              overviewProject._id
            );
          } else {
            this.loadContent(externalData, "content", true, stepData.stepId);
          }
        }
      );
    }
  }

  //FUNCTIONS_____________________
  //handle tab change
  toggleTabs = current =>
    this.setState({ section: 0 }, () => this.setState({ current }));

  //load content
  loadContent = (url, type, displayHelper, id) => {
    if (!url) return false;

    fetch(
      `${global.URI_BACKEND}/scrapRessources/${id}/${type}/${this.props.language}`
    )
      .then(response => response.json())
      .then(data => {
        if (data.rawResponse === undefined || data.rawResponse === null) {
          this.setState({ noRessource: true });
        } else {
          this.setState({ noRessource: false });

          data = data.rawResponse.replace(/(\.[a-z0-9]+){/gi, `#${type} $1{`);
          data = data.replace(/}([a-z0-9]+){/gi, `}#${type} $1{`);

          const parser = new DOMParser();
          this.doc[type] = parser.parseFromString(data, "text/html");

          var newStyle = this.doc[type].body.querySelector("style");

          if (!newStyle) {
            newStyle = this.doc[type].querySelector("style");
          }
          newStyle.id = `style-${type}`;

          const oldStyle = document.querySelector(`#style-${type}`);
          if (oldStyle) oldStyle.remove();

          const oldStyleFranchised = document.querySelector(`#style-doc`);
          if (oldStyleFranchised) oldStyleFranchised.remove();

          const styleElmt = document.getElementsByTagName("head")[0];
          styleElmt.insertBefore(newStyle, styleElmt.firstChild);

          const sheet = document.querySelector("style").sheet;

          if (sheet) {
            sheet.insertRule(
              `#${type} table.noBorder td { border: 0px !important; }`,
              document.querySelector("style").sheet.cssRules.length
            );
          }

          if (displayHelper) {
            let tableList = this.doc[type].body.querySelectorAll("table");

            let count = 0;
            let id;

            for (const e of tableList) {
              let trList = e.querySelectorAll("tr");
              let type = trList[0].textContent.toLowerCase().trim();
              if (
                [
                  "@solution",
                  "@aide",
                  "@tip",
                  "@meta-description",
                  "@meta-tag",
                ].indexOf(type) === -1
              )
                continue;

              e.classList.add("noBorder");

              if (type === "@solution" || type === "@aide") {
                id = `internal-${count}`;
                count++;
              } else {
                id = `internal-${uid2(6)}`;
              }

              trList[0].textContent = `${trList[0].textContent.trim()}:${id}`;
              e.id = id;
              this.solutions[id] = [];

              for (let j = 1; j < trList.length; j++) {
                if (type === "@tip") {
                  const pList = trList[j].querySelectorAll("p");
                  for (let k = 0; k < pList.length; k++) {
                    this.solutions[id].push(pList[k]);
                  }
                } else {
                  this.solutions[id].push(trList[j]);
                }
                trList[j].remove();
              }

              if (type === "@tip") {
                let sibling = e.previousElementSibling;
                while (sibling) {
                  if (
                    sibling.matches("p") &&
                    sibling.textContent.trim().length > 0
                  )
                    break;
                  sibling = sibling.previousElementSibling;
                }
                const text = document.createTextNode(trList[0].textContent);
                const span = document.createElement("span");
                span.appendChild(text);
                sibling.appendChild(span);
                e.remove();
              }
            }
          }

          //var childrenList = this.doc[type].body.children;

          // CONDITION TO ENCOUNTER CHANGES IN GDOC STRUCTURE - (div around all children in new version)

          /* if (childrenList[0].tagName == 'DIV' && childrenList[1].tagName == 'DIV') {
           */

          var childrenList = this.doc[type]
            .getElementById("contents")
            .getElementsByTagName("div")[0].children;

          if (type === "content") {
            const content = ReactHtmlParser(
              this.doc.content
                .getElementById("contents")
                .getElementsByTagName("div")[0].innerHTML,
              { transform: this.transform }
            );
            this.setState({ content });
          } else if (type === "resume") {
            const resume = ReactHtmlParser(
              this.doc.resume
                .getElementById("contents")
                .getElementsByTagName("div")[0].innerHTML,
              { transform: this.transform }
            );
            this.setState({ resume });
          } else {
            const overview = ReactHtmlParser(
              this.doc.overview
                .getElementById("contents")
                .getElementsByTagName("div")[0].innerHTML,
              { transform: this.transform }
            );
            this.setState({ overview });
          }

          const summary = [];
          const titleList = this.doc[type].body.querySelectorAll("h1, h2");

          for (const t of titleList) {
            const title = t;

            if (title.tagName === "H1" && title.textContent.length > 0) {
              summary.push({
                title: title.textContent,
                id: title.id,
                secondaryTitle: [],
              });
            } else if (
              title.textContent.length > 0 &&
              summary[summary.length - 1]
            ) {
              summary[summary.length - 1].secondaryTitle.push({
                title: title.textContent,
                id: title.id,
              });
            }
          }

          if (type === "resume") {
            for (const child of childrenList) {
              if (child.tagName === "H1" && child.innerText.length > 0) {
                this.resumeList.push({ title: child.innerText });
                continue;
              } else if (this.resumeList.length === 0) {
                continue;
              }

              if (!this.resumeList[this.resumeList.length - 1])
                this.resumeList.push({});
              if (!this.resumeList[this.resumeList.length - 1].resume)
                this.resumeList[this.resumeList.length - 1].resume = [];
              if (type === "resume")
                this.resumeList[this.resumeList.length - 1].resume +=
                  child.outerHTML;
            }

            const resumes = this.resumeList.map(e => ({
              title: e.title,
              resume: ReactHtmlParser(e.resume, { transform: this.transform }),
            }));

            if (type === "resume") {
              this.setState({ /* summaryContent: summary, */ resumes });
            }
          } else {
            for (const child of childrenList) {
              if (child.tagName === "H1" && child.innerText.length > 0) {
                if (type === "content" || type === "overview") {
                  if (child.innerText.match("@maingroup")) {
                    const words = child.innerText.split(" ");
                    const indexMain = words.indexOf("@maingroup");
                    const indexSecond = words.indexOf("@secondarygroup");
                    const mainGroupTemp = [];
                    const secondaryGroupTemp = [];
                    for (let i = 0; i < words.length; i++) {
                      if (i > indexMain && i < indexSecond) {
                        mainGroupTemp.push(words[i]);
                      }
                      if (i > indexSecond) {
                        secondaryGroupTemp.push(words[i]);
                      }
                    }

                    this.courseList.push({
                      title: child.innerText
                        .replace("@maingroup", "")
                        .replace("@secondarygroup", "")
                        .replace(mainGroupTemp.join(" "), "")
                        .replace(secondaryGroupTemp.join(" "), ""),
                      mainGroup: mainGroupTemp.join(" "),
                      secondaryGroup: secondaryGroupTemp.join(" "),
                    });
                  } else {
                    this.courseList.push({
                      title: child.innerText,
                      mainGroup: "",
                      secondaryGroup: "",
                    });
                  }
                }

                continue;
              } else if (this.courseList.length === 0) {
                continue;
              }

              if (!this.courseList[this.courseList.length - 1])
                this.courseList.push({});
              if (!this.courseList[this.courseList.length - 1].content)
                this.courseList[this.courseList.length - 1].content = [];
              if (type === "content")
                this.courseList[this.courseList.length - 1].content +=
                  child.outerHTML;
            }

            /////// Count emojis and percentage per section / Initialize student project progression  /////////

            var tableEmoji = [];
            var tableEmojiTotal = [];
            var tableOfEmojiTable = [];
            var sumEach = [];

            for (const e of this.courseList) {
              for (const f of e.content) {
                var matchEmoji = f?.match("👉");
                if (matchEmoji != null) {
                  tableEmoji.push(matchEmoji[0]);
                }
              }

              tableEmojiTotal.push(tableEmoji.length); //Number of emoji per section
              tableOfEmojiTable.push(tableEmoji); // Array of all the array/ section with their emoji
              tableEmoji = []; // Empty the table before another iteration
            }

            // Calculate percentage of each section regarding the whole project, return an array to update the sumEachState
            for (const e of tableOfEmojiTable) {
              var sumOne = Math.round(
                (e.length / tableEmojiTotal.reduce((a, b) => a + b, 0)) * 100
              );
              sumEach.push(sumOne);
            }
            // Initialization of "progressState" table used for student Project progression - If no tasks to do in the section, return no percentage value (cf. sumEach) //
            var preProgressTable = [];

            if (this.state.progressState.length === 0) {
              for (let i = 0; i < this.courseList.length; i++) {
                if (sumEach[i] === 0) {
                  if (this.courseList[i].mainGroup !== "") {
                    preProgressTable.push({
                      index: i,
                      title: this.courseList[i].title,
                      date: moment().format(),
                      mainGroup: this.courseList[i].mainGroup,
                      secondaryGroup: this.courseList[i].secondaryGroup,
                    });
                  } else {
                    preProgressTable.push({
                      index: i,
                      title: this.courseList[i].title,
                      date: moment().format(),
                    });
                  }
                } else {
                  if (this.courseList[i].mainGroup !== "") {
                    preProgressTable.push({
                      index: i,
                      title: this.courseList[i].title,
                      date: moment().format(),
                      percentage: 0,
                      weightInPercentage: sumEach[i],
                      mainGroup: this.courseList[i].mainGroup,
                      secondaryGroup: this.courseList[i].secondaryGroup,
                    });
                  } else {
                    preProgressTable.push({
                      index: i,
                      title: this.courseList[i].title,
                      date: moment().format(),
                      percentage: 0,
                      weightInPercentage: sumEach[i],
                    });
                  }
                }
              }

              this.setState({ progressState: preProgressTable });
            }

            this.setState({ sumEachState: sumEach });

            if (this.state.stepPercentageTable.length === 0) {
              var stepLength = 0;
              for (const s of sumEach) {
                if (s !== 0) {
                  stepLength++;
                }
                this.state.stepPercentageTable.length = stepLength;
                this.state.stepPercentageTable.fill(0);
              }
            }

            //// FIN MAJ PROGRESSION ////

            const courses = this.courseList.map(e => ({
              title: e.title,
              content: ReactHtmlParser(e.content, {
                transform: this.transform,
              }),
              mainGroup: e.mainGroup,
              secondaryGroup: e.secondaryGroup,
            }));

            // Fin mise en forme cours

            if (type === "content") {
              this.setState({ summaryContent: summary, courses });
            }
          }
        }
      });
  };

  //convert to html nodes
  transform = (node, index) => {
    const { t } = this.props;
    if (
      (node.name === "span" &&
        node.children &&
        node.children.find(e => e.name === "img")) ||
      node.name === "img"
    ) {
      node.attribs.style = this.setImageStyles(node.name, node.attribs.style);
    }

    const { solutionButton, infoButton } = styles;

    if (
      node.type === "text" &&
      node.data.toLowerCase().trim().indexOf("@solution") !== -1
    ) {
      const [, id] = node.data.split(":");
      return this.timeLockSolution === 0 || this.solutions[id] == null ? (
        <td key={Math.random()}>
          <Button style={solutionButton} name="@solution" type="link">
            <Icon type="star" theme="filled" /> {t("Débloquer la solution")}
          </Button>
        </td>
      ) : (
        <td key={Math.random()}>
          <Button disabled={true} name="@solution" type="link">
            <Icon type="lock" theme="filled" /> {t("Débloquer la solution")}
          </Button>
        </td>
      );
    } else if (
      node.type === "text" &&
      node.data.toLowerCase().trim().indexOf("@aide") !== -1
    ) {
      const [, id] = node.data.split(":");
      return this.timeLockHelp === 0 || this.solutions[id] === null ? (
        <td key={Math.random()}>
          <Button className="lc-pink" name="@help" type="link">
            <Icon type="bulb" theme="filled" /> {t("Un peu d'aide")}
          </Button>
        </td>
      ) : (
        <td key={Math.random()}>
          <Button disabled={true} name="@help" type="link">
            <Icon type="lock" theme="filled" /> {t("Un peu d'aide")}
          </Button>
        </td>
      );
    } else if (
      node.type === "text" &&
      node.data.toLowerCase().trim().indexOf("@tip") !== -1
    ) {
      const [, id] = node.data.split(":");
      const content = [];

      if (this.solutions[id]) {
        for (const e of this.solutions[id]) {
          content.push(<p key={Math.random()}>{e.textContent}</p>);
        }
      }

      return (
        <Popover
          key={Math.random()}
          placement="right"
          content={content}
          trigger="click"
        >
          <Button
            style={infoButton}
            name="@tip"
            type="link"
            icon="info-circle"
          ></Button>
        </Popover>
      );
    } else if (
      node.type === "text" &&
      node.data.toLowerCase().trim().indexOf("@video") !== -1
    ) {
      const [, urlVideo] = node.data.split("::");
      return (
        <Player
          key={Math.random()}
          autoPlay={false}
          src={urlVideo}
          fluid={true}
          width={600}
        >
          <BigPlayButton position="center" />
          <LoadingSpinner />
        </Player>
      );
    } else if (
      node.type === "text" &&
      node.data.toLowerCase().trim().indexOf("@meta-description") !== -1
    ) {
      return null;
    } else if (
      node.type === "text" &&
      node.data.toLowerCase().trim().indexOf("@meta-tag") !== -1
    ) {
      return null;
    } else if (node.type === "tag" && node.name === "a") {
      if (!node.children[0]) {
        return null;
      }

      let href =
        node.attribs && node.attribs.href
          ? node.attribs.href.split("?q=")[1]?.split("&sa=")[0]
          : "#";
      return (
        <a
          key={(Date.now() + Math.random()).toString()}
          target="_blank"
          rel="noopener noreferrer"
          href={href}
        >
          {node.children[0].data}
        </a>
      );
    }

    if (node.type === "tag") {
      let defaultClass =
        node.attribs.class !== undefined ? node.attribs.class : "";
      node.attribs.class = defaultClass + " gContentP";
      let elmt = convertNodeToElement(node, index, this.transform);
      return elmt;
    }
  };

  setImageStyles = (type, styles) => {
    let arr = styles.split("; ");
    let style;

    if (type === "span") {
      style = arr
        .map(e =>
          e.includes("width")
            ? `max-width: ${e.split(": ")[1]}`
            : e.includes("height")
              ? `max-height: ${e.split(": ")[1]}`
              : e
        )
        .join("; ");
    } else if (type === "img") {
      style = arr
        .map(e =>
          e.includes("width")
            ? `max-width: ${e.split(": ")[1]}`
            : e.includes("height")
              ? `max-height: ${e.split(": ")[1]}`
              : e
        )
        .join("; ");

      style += " width: 100%; height: auto;";
    }
    return style;
  };

  //handle unlock of solutions and tips
  handleSolution = e => {
    if (
      (this.timeLockSolution === 0 || this.timeLockHelp === 0) &&
      (e.target.name === "@solution" || e.target.name === "@help")
    ) {
      const { user, batch, id, overviewProject } = this.props;

      const ev = e.target;

      const body = {
        userId: user._id,
        userType: user.type,
        lastName: user.lastname,
        firstName: user.firstname,
        batchId: batch._id,
        id: ev.closest("table").id,
        type: ev.name,
        projectId: overviewProject?._id,
        stepId: id,
      };

      if (global.SOCKET)
        global.SOCKET.emit("studentUnlockHelp", { body: body });

      fetch(
        `${global.URI_BACKEND}/user/last-request/${user._id}/${e.target.name}`
      )
        .then(response => response.json())
        .then(data => {
          if (data.result === true) {
            const mainTable = ev.closest("table");
            const table = this.doc.content.body.querySelector(
              `#${mainTable.id} tbody`
            );

            if (!table) return;

            for (const e of this.solutions[mainTable.id]) {
              table.appendChild(e);
            }

            if (
              this.solutions[mainTable.id][0].parentNode &&
              this.solutions[mainTable.id][0].parentNode.children[0]
            ) {
              this.solutions[mainTable.id][0].parentNode.children[0].remove();
            } else {
              message.warn(
                "Une erreur est survenue, veuillez réessayer ultérieurement."
              );
              return;
            }

            this.solutions[mainTable.id] = null;

            const courseList = [];
            //var childrenList = this.doc.content.body.children;

            var childrenList = this.doc.content
              .getElementById("contents")
              .getElementsByTagName("div")[0].children;

            /*  if (childrenList[0].tagName == 'DIV' && childrenList[1].tagName == 'DIV') {
                        childrenList = this.doc.content.getElementById("contents").getElementsByTagName("div")[0].children
                    } */

            for (const child of childrenList) {
              if (child.tagName === "H1" && child.innerText.length > 0) {
                courseList.push({ title: child.innerText });
                continue;
              } else if (courseList.length === 0) {
                continue;
              }

              if (!courseList[courseList.length - 1]) courseList.push({});
              if (!courseList[courseList.length - 1].content)
                courseList[courseList.length - 1].content = [];

              courseList[courseList.length - 1].content += child.outerHTML;
            }

            this.courseList = courseList;
            const courses = this.courseList.map(e => ({
              title: e.title,
              content: ReactHtmlParser(e.content, {
                transform: this.transform,
              }),
              mainGroup: e.mainGroup,
              secondaryGroup: e.secondaryGroup,
            }));

            this.setState({ courses });

            this.unlockHelp();
            this.unlockSolution();
            this.lock();
          }
        });
    }
  };

  unlockSolution = () => {
    this.timeLockSolution = 0;

    const courses = this.courseList.map(e => ({
      title: e.title,
      content: ReactHtmlParser(e.content, { transform: this.transform }),
      mainGroup: e.mainGroup,
      secondaryGroup: e.secondaryGroup,
    }));
    this.setState({ courses });
  };

  unlockHelp = () => {
    this.timeLockHelp = 0;

    const courses = this.courseList.map(e => ({
      title: e.title,
      content: ReactHtmlParser(e.content, { transform: this.transform }),
      mainGroup: e.mainGroup,
      secondaryGroup: e.secondaryGroup,
    }));
    this.setState({ courses });
  };

  lock = () => {
    this.timeLockSolution = 15 * 1000 * 60; // 15 mins
    this.timeLockHelp = 7 * 1000 * 60; // 7 mins

    const courses = this.courseList.map(e => ({
      title: e.title,
      content: ReactHtmlParser(e.content, { transform: this.transform }),
      mainGroup: e.mainGroup,
      secondaryGroup: e.secondaryGroup,
    }));
    this.setState({ courses });
  };

  checkUnlockTime = () => {
    const { user } = this.props;

    if (this.timeLockHelp !== 0 || this.timeLockSolution !== 0) {
      fetch(`${global.URI_BACKEND}/user/last-request/${user._id}`)
        .then(response => response.json())
        .then(data => {
          if (data.result === true) {
            this.setState({ lastRequest: data.user.last_request });

            if (
              moment().isAfter(moment(data.user.last_request).add(7, "minutes"))
            ) {
              this.unlockHelp();
            }

            if (
              moment().isAfter(
                moment(data.user.last_request).add(15, "minutes")
              )
            ) {
              this.unlockSolution();
            }
          } else {
            setTimeout(this.unlockSolution, this.timeLockSolution);
            setTimeout(this.unlockHelp, this.timeLockHelp);
          }
        });
    }
  };

  //copy password for solution git repo
  copyClipboard = txt => {
    var el = document.createElement("textarea");

    el.value = txt;
    el.style.visible = "hidden";

    document.body.appendChild(el);
    el.select();

    document.execCommand("copy");
    document.body.removeChild(el);

    message.success("Link copied! Password: L@C@psule");
  };

  //handle declarative progression clicks
  onChangeBox = i => {
    const { user, batch, id, stepData, updateProgress } = this.props;
    const { checked, stepPercentageTable, progressState } = this.state;

    const cpyChecked = [...checked];
    var tempStepProgression = null;
    var progressStateCopy = [];

    if (progressState) progressStateCopy = progressState;

    // var progressStateCopy = progressState
    if (progressState[i].percentage === 0) {
      progressStateCopy[i].percentage = 100;
      progressStateCopy[i].date = moment().format();
      progressStateCopy[i].mainGroupSections = progressState[i].mainGroup
        ? progressState[i].mainGroup
        : stepData.mainGroup;
      progressStateCopy[i].secondaryGroupSections = progressState[i]
        .secondaryGroup
        ? progressState[i].secondaryGroup
        : stepData.secondaryGroup;

      if (stepData.stepType === "slide" && stepData.stepType_v2 === "course") {
        tempStepProgression = progressStateCopy[i].percentage;
      } else {
        tempStepProgression =
          progressStateCopy[i].percentage *
          progressStateCopy[i].weightInPercentage;
      }

      // Gestion checkbox
      this.setState({ progressState: progressStateCopy });
      cpyChecked.push(i);
      this.setState({ checked: cpyChecked });
    } else {
      progressStateCopy[i].percentage = 0;
      progressStateCopy[i].date = moment().format();
      progressStateCopy[i].mainGroupSections = stepData.mainGroup;
      progressStateCopy[i].secondaryGroupSections = stepData.secondaryGroup;
      this.setState({ progressState: progressStateCopy });

      if (stepData.stepType === "slide" && stepData.stepType_v2 === "course") {
        tempStepProgression = progressStateCopy[i].percentage;
      } else {
        tempStepProgression =
          progressStateCopy[i].percentage *
          progressStateCopy[i].weightInPercentage;
      }
      // Gestion checkbox
      const indexOfId = cpyChecked.indexOf(i);
      cpyChecked.splice(indexOfId, 1);
      this.setState({ checked: cpyChecked });
    }

    stepPercentageTable[i] = tempStepProgression;

    var stepPercentage = null;

    if (stepData.stepType === "slide" && stepData.stepType_v2 === "course") {
      stepPercentage = Math.round(
        stepPercentageTable.reduce((a, b) => a + b, 0) /
        stepPercentageTable.length
      );
    } else {
      stepPercentage = stepPercentageTable.reduce((a, b) => a + b, 0) / 100;
    }

    if (stepPercentage >= 99) stepPercentage = 100;
    var type = null;

    type = stepData.stepType_v2;

    var tempStepProgression2 = 0;

    let body = {
      student: user._id,
      batch: batch._id,
      step: id,
      stepPercentage: stepPercentage,
      type: type,
      stackDay: stepData.day,
      optional: stepData.optional ? stepData.optional : false,
      title: stepData.text,
      mainGroup: stepData.mainGroup,
      secondaryGroup: stepData.secondaryGroup,
      sections: this.state.progressState,
      scoreResult: cpyChecked?.length,
      questionCount: this.state.progressState.length,
    };

    fetch(`${global.URI_BACKEND}/stepprogression/`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(data => {
        if (data.success && data.stepProgression) {
          for (const e of data.stepProgression.progression) {
            if (e.step === stepData.stepId) {
              tempStepProgression2 = Math.round(e.stepPercentage);
            }
          }
          this.setState({ stepProgression: tempStepProgression2 });
        }
      })
      .then(() => updateProgress())
      .catch(console.error);
  };

  //handle scroll to section
  handleSectionSelect = (section, ref, target) => {
    this.setState({ section });
    ref.current.scrollTo(0, target.offsetTop);
  };

  setTabRef = (ref, type) => {
    if (ref) {
      if (type === "courses") {
        let index = this.tabRefs.findIndex(e => e === ref);

        index < 0 ? this.tabRefs.push(ref) : (this.tabRefs[index] = ref);
      } else if (type === "resume") {
        let index = this.resumeTabRefs.findIndex(e => e === ref);

        index < 0
          ? this.resumeTabRefs.push(ref)
          : (this.resumeTabRefs[index] = ref);
      }
    }
  };

  setLinkRef = ref => {
    const { current } = this.state;

    if (ref) {
      if (current === "courses") {
        let index = this.linkRefs.findIndex(e => e === ref);

        index < 0 ? this.linkRefs.push(ref) : (this.linkRefs[index] = ref);
      } else if (current === "resume") {
        let index = this.resumeLinkRefs.findIndex(e => e === ref);

        index < 0
          ? this.resumeLinkRefs.push(ref)
          : (this.resumeLinkRefs[index] = ref);
      }
    }
  };

  getTabLink = link => {
    if (link) {
      const section = parseInt(link.split("-")[1]);
      this.setState({ section });
    }
  };

  render() {
    const {
      body,
      progress_bar,
      course_body,
      scroll_container,
      checkbox,
      checkbox_checked,
      course_title_container,
      card,
      course_container,
      tab_content,
      livecode,
      resource,
      anchor,
      link_title,
      anchor_wrapper,
    } = styles;
    const {
      current,
      overview,
      courses,
      sumEachState,
      checked,
      progressState,
      resumes,
      section,
      stepProgression,
      noRessource,
    } = this.state;
    const {
      stepData,
      overviewProject,
      slides,
      iframeTitle,
      resume,
      videos,
      resources,
      progress,
      batch,
      externalData,
      user,
      t,
    } = this.props;
    const stepType = stepData.stepType_v2;

    //course cards
    let courseCards;
    if (current === "courses" && courses?.length) {
      if (["project", "challenge", "more", "course"].includes(stepType)) {
        courseCards = courses.map((e, i) => (
          <div key={i + 1} id={`panel-${i}`} ref={ref => this.setTabRef(ref)}>
            <div className="tab-header" style={course_title_container}>
              <span>{e.title}</span>
              <span>
                {progressState?.[i]?.percentage === 100 &&
                  !stepData.integrated && (
                    <Progress
                      type="circle"
                      percent={100}
                      width={25}
                      strokeColor="#98EDC4"
                    />
                  )}
              </span>
            </div>
            <div style={{ ...card, paddingBottom: 70 }}>
              {e.content}
              {sumEachState[i] === 0 ||
                stepData.progress === false ||
                stepData.integrated ? null : (
                <Checkbox
                  key={i}
                  checked={checked.includes(i)}
                  onChange={() => this.onChangeBox(i)}
                  style={
                    checked.includes(i)
                      ? Object.assign({ ...checkbox }, { ...checkbox_checked })
                      : checkbox
                  }
                >
                  {stepData.stepType === "slide" && stepType === "course"
                    ? t("J'ai lu cette partie")
                    : t("J'ai fini cette partie")}
                </Checkbox>
              )}
            </div>
          </div>
        ));
      } else {
        courseCards = courses.map((e, i) => (
          <div
            key={i + 1}
            id={`panel-${i}`}
            ref={ref => this.setTabRef(ref, "courses")}
          >
            <div className="tab-header" style={course_title_container}>
              {e.title}
            </div>
            <div style={card}>{e.content}</div>
          </div>
        ));
      }
    }

    //resume cards
    let resumeCards;

    if (current === "resume" && resumes?.length) {
      resumeCards = resumes.map((e, i) => (
        <div
          key={i + 1}
          id={`panel-${i}`}
          ref={ref => this.setTabRef(ref, "resume")}
        >
          <div className="tab-header" style={course_title_container}>
            {e.title}
          </div>
          <div style={card}>{e.resume}</div>
        </div>
      ));
    }

    //slides
    const slideVersion =
      slides &&
        slides.uri &&
        slides.uri.indexOf("https://docs.google.com/") !== -1
        ? 2
        : 1;
    var slide;
    if (slideVersion === 1) {
      slide = `https://ariane.lacapsule.academy${slides?.uri}`;
    } else {
      slide = slides?.uri;
    }
    //enable resources
    let isEnabled = stepData.stepType_v2 === "course" ? true :
      progress?.stepId === stepData._id ? progress?.enableLiveCode : false;
    let isAfter = moment().isAfter(moment(batch.date.end));
    const progressBar = () => (
      <Progress
        style={progress_bar}
        strokeWidth={5}
        percent={stepProgression > 2 ? stepProgression : 0}
        strokeColor="#98EDC4"
        success={{ strokeColor: "#98EDC4" }}
        format={() => (
          <span style={{ color: "black", fontWeight: 600 }}>
            {stepProgression || 0}%
          </span>
        )}
      />
    );

    return (
      <Row style={body} id="course">
        <Col xs={{ span: 24, offset: 0 }} xl={{ span: 15, offset: 1 }}>
          <Row className="progress">{progressBar()}</Row>
          <Row style={scroll_container}>
            {/* <SimpleBar style={ { height: '100%' } }> */}
            <Col style={course_body} className="tabs-content">
              {noRessource ? (
                <p className="course-content" style={{ margin: "20px" }}>
                  {t("Vous devez synchroniser les ressources")}
                </p>
              ) : (
                <Tabs
                  className="content-tabs"
                  defaultActiveKey={current}
                  activeKey={current}
                  onChange={this.toggleTabs}
                  style={tab_content}
                >
                  {stepType === "project" && overviewProject?.externalData && (
                    <TabPane
                      tab={t("Overview")}
                      key="overview"
                      style={course_container}
                    >
                      {!overview ? (
                        <Spin size="large" style={{ margin: "auto" }} />
                      ) : (
                        <SimpleBar className="simplebar-custom">
                          <Row id="overview" style={card}>
                            <Col span={24}>{overview}</Col>
                          </Row>
                        </SimpleBar>
                      )}
                    </TabPane>
                  )}
                  {externalData && (
                    <TabPane
                      tab={
                        stepType === "course" ? t("Cours") : t("Instructions")
                      }
                      style={course_container}
                      key="courses"
                      id="content"
                    >
                      {!courses ? (
                        <Row>
                          <Col
                            span={24}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 50,
                            }}
                          >
                            <Spin size="large" style={{ margin: "auto" }} />
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <Row className="select-course-menu">
                            <Col span={24}>
                              <Select
                                className="ant-custom-select"
                                value={
                                  section < courses.length
                                    ? courses[section].title
                                    : courses[0].title
                                }
                                placeholder={
                                  <span>
                                    {section < courses.length
                                      ? courses[section].title
                                      : courses[0].title}
                                  </span>
                                }
                                onChange={section =>
                                  this.handleSectionSelect(
                                    section,
                                    this.courseContainer,
                                    this.tabRefs[section]
                                  )
                                }
                              >
                                {courses.map((e, i) => (
                                  <Option
                                    style={{ color: "rgb(67, 67, 67)" }}
                                    key={i}
                                    value={i}
                                  >
                                    {e.title || t("-- Titre manquant --")}
                                  </Option>
                                ))}
                              </Select>
                            </Col>
                          </Row>
                          <SimpleBar
                            className="simplebar-custom"
                            scrollableNodeProps={{
                              ref: this.courseContainer,
                              id: "course-container",
                            }}
                          >
                            <Row>
                              <Col span={24} onClick={this.handleSolution}>
                                {courseCards}
                              </Col>
                            </Row>
                          </SimpleBar>
                        </>
                      )}
                    </TabPane>
                  )}
                  {slides && (
                    <TabPane
                      tab={t("Slides")}
                      key="slides"
                      id="slide-container"
                    >
                      {slideVersion === 1 ? (
                        <>
                          <iframe
                            id="slides"
                            title={`${iframeTitle}`}
                            src={slide}
                            width="100%"
                            height="420"
                            scrolling="no"
                            frameBorder="0"
                            controls={false}
                          />
                          <Icon
                            type="arrows-alt"
                            style={{
                              cursor: "pointer",
                              fontSize: 20,
                              color: "#08c",
                            }}
                            onClick={() => {
                              document
                                .getElementById("slides")
                                .contentWindow.Reveal.triggerKey(70);
                            }}
                          />
                          <Icon
                            type="video-camera"
                            style={{
                              paddingLeft: "20px",
                              cursor: "pointer",
                              fontSize: 20,
                              color: "#08c",
                            }}
                            onClick={() => {
                              document
                                .getElementById("slides")
                                .contentWindow.RevealNotes.open();
                            }}
                          />
                        </>
                      ) : (
                        <iframe
                          id="slides"
                          title={`${iframeTitle}`}
                          src={slide}
                          frameBorder="0"
                          width="100%"
                          height="520"
                          scrolling="no"
                          allowfullscreen="true"
                          mozallowfullscreen="true"
                          webkitallowfullscreen="true"
                        />
                      )}
                    </TabPane>
                  )}
                  {resume && (
                    <TabPane
                      tab={t("Résumé")}
                      key="resume"
                      style={course_container}
                      id="resume"
                    >
                      <>
                        {!resumes ? (
                          <Row>
                            <Col
                              span={24}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 50,
                              }}
                            >
                              <Spin size="large" style={{ margin: "auto" }} />
                            </Col>
                          </Row>
                        ) : (
                          <>
                            <Row className="select-course-menu">
                              <Col span={24}>
                                <Select
                                  className="ant-custom-select"
                                  value={
                                    section < resumes.length
                                      ? resumes[section].title
                                      : resumes[0].title
                                  }
                                  placeholder={
                                    <span>
                                      {section < resumes.length
                                        ? resumes[section].title
                                        : resumes[0].title}
                                    </span>
                                  }
                                  onChange={section =>
                                    this.handleSectionSelect(
                                      section,
                                      this.resumeContainer,
                                      this.resumeTabRefs[section]
                                    )
                                  }
                                >
                                  {resumes.map((e, i) => (
                                    <Option
                                      style={{ color: "rgb(67, 67, 67)" }}
                                      key={i}
                                      value={i}
                                    >
                                      {e.title || t("-- Titre manquant --")}
                                    </Option>
                                  ))}
                                </Select>
                              </Col>
                            </Row>
                            <SimpleBar
                              className="simplebar-custom"
                              scrollableNodeProps={{
                                ref: this.resumeContainer,
                                id: "resume-container",
                              }}
                            >
                              <Row>
                                <Col span={24}>{resumeCards}</Col>
                              </Row>
                            </SimpleBar>
                          </>
                        )}
                      </>
                    </TabPane>
                  )}
                  {videos?.uri /*&& ["project"].includes(stepType)*/ && (
                    <TabPane tab="Vidéo" key="videos">
                      <Col
                        span={24}
                        style={{ height: `${window.innerHeight / 2}px` }}
                        id="video"
                        className={
                          isEnabled ||
                            isAfter ||
                            [
                              "assistant",
                              "teacher",
                              "manager",
                              "superadmin",
                            ].includes(user.type)
                            ? null
                            : "lock"
                        }
                      >
                        <Player
                          ref="player"
                          onLoad={() =>
                            console.log("Player onload", this.player.getState())
                          }
                          autoPlay={false}
                          poster={global.URI_FRONTEND + videos?.splashscreen}
                          src={videos?.uri}
                          fluid={false}
                          height="100%"
                          volume={0.2}
                        >
                          <BigPlayButton position="center" />
                          <LoadingSpinner />
                        </Player>
                      </Col>
                    </TabPane>
                  )}
                  {resources?.filter(e =>
                    e.resourceType === "livecode"
                      ? isEnabled || isAfter || user.type !== "student"
                      : true
                  )?.length &&
                    [
                      "project",
                      "challenge",
                      "more",
                      "exercise",
                      "quizz",
                    ].includes(stepType) && (
                      <TabPane tab={t("Ressources")} key="ressources">
                        <List
                          size="large"
                          style={{ width: 500 }}
                          bordered={isEnabled}
                          dataSource={resources}
                          renderItem={item => {
                            if (item.resourceType === "livecode") {
                              return isEnabled ||
                                isAfter ||
                                user.type !== "student" ? (
                                <Item style={livecode}>
                                  <span
                                    onClick={() =>
                                      this.copyClipboard(
                                        `git clone ${item.uri}`
                                      )
                                    }
                                  >
                                    git clone {item.uri.substring(0, 60)}...
                                  </span>
                                </Item>
                              ) : (
                                <div />
                              );
                            } else {
                              let uri =
                                item?.uri?.indexOf("http") === 0
                                  ? item.uri
                                  : `${global.URI_FRONTEND}${item.uri}`;
                              return (
                                <Item style={isEnabled ? livecode : resource}>
                                  <a
                                    style={{ color: "black" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={uri}
                                  >
                                    {item.title}
                                  </a>
                                </Item>
                              );
                            }
                          }}
                        />
                      </TabPane>
                    )}
                </Tabs>
              )}
            </Col>
            {/* </SimpleBar> */}
          </Row>
        </Col>
        <Col xs={{ span: 0 }} lg={{ span: 8 }} style={anchor_wrapper}>
          {current === "courses" && courses && (
            <div
              className="anchor-course-menu ant-affix"
              style={{ marginTop: "35px" }}
            >
              {progressBar()}
              <Anchor
                style={anchor}
                getContainer={() => document.querySelector(`#course-container`)}
                onChange={link => this.getTabLink(link)}
              >
                {courses.map((e, i) => (
                  <Link
                    key={i}
                    href={`#panel-${i}`}
                    title={
                      <p style={link_title}>
                        {e.title || t("-- Titre manquant --")}
                      </p>
                    }
                    ref={ref => this.setLinkRef(ref)}
                  />
                ))}
              </Anchor>
            </div>
          )}
          {current === "resume" && resumes && (
            <Anchor
              style={anchor}
              className="anchor-course-menu"
              getContainer={() => document.querySelector(`#resume-container`)}
              onChange={link => this.getTabLink(link)}
            >
              {resumes.map((e, i) => (
                <Link
                  key={i}
                  href={`#panel-${i}`}
                  title={
                    <p style={link_title}>
                      {e.title || t("-- Titre manquant --")}
                    </p>
                  }
                  ref={ref => this.setLinkRef(ref)}
                />
              ))}
            </Anchor>
          )}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps({ progression, batch, user, day, language }) {
  return { progression, batch, user, day, language };
}

export default connect(mapStateToProps, null)(withTranslation()(Course));

const styles = {
  // body: {
  //     padding: 15
  // },
  progress_bar: {
    position: "sticky",
    paddingLeft: 15,
    paddingRight: 15
  },
  scroll_container: {
    height: "calc(100vh - 160px)",
    boxSizing: "border-box",
  },
  course_body: {
    height: "100%",
  },
  solutionButton: { color: "#7838d5" },
  checkbox: {
    position: "absolute",
    right: -70,
    bottom: 20,
    fontFamily: "'Poppins', sans-serif",
    color: "#010031",
    fontSize: "12px",
    fontWeight: 500,
    borderRadius: "30px",
    padding: "5px 13px",
    border: "1px solid #E5E5E5",
  },
  checkbox_checked: {
    border: "1px solid #98EDC4",
  },
  course_title_container: {
    position: "-webkit-sticky",
    position: "sticky",
    top: -1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff",
    zIndex: 99,
    borderRadius: "2px 2px 0 0",
    border: "1px solid #F0F0F0",
    height: 33,
    margin: 0,
    padding: 15,
    paddingLeft: 60,
    fontSize: "13px",
    color: "#010031",
    fontWeight: 600,
    letterSpacing: "0.05em",
    lineHeight: "24px",
    textTransform: "uppercase",
    fontFamily: "'Poppins', sans-serif",
  },
  card: {
    backgroundColor: "#ffffff",
    //padding: "15px 25px 15px 25px",
    position: "relative",
    width: "605px",
    margin: "auto",
    paddingTop: 40
  },
  course_container: {
    width: "100%",
    overflow: "visible",
    backgroundColor: "#ffffff",
  },
  tab_content: {
    overflowX: "hidden",
  },
  livecode: {
    backgroundColor: "#ffffff",
    padding: "16px 24px",
    color: "#395E5A",
    cursor: "pointer",
  },
  resource: {
    backgroundColor: "#ffffff",
    padding: "16px 24px",
    border: "1px solid #D9D9D9",
  },
  anchor: {
    // minWidth: 'fit-content',
    position: "relative",
    padding: "20px 20px 20px 30px",
    width: "fit-content",
    minWidth: "260px",
    maxWidth: "100%",
  },
  link_title: {
    minWidth: "240px",
    verticalAlign: "middle",
    whiteSpace: "normal",
    margin: 0,
    position: "relative",
  },
  anchor_wrapper: {
    paddingLeft: 30,
  },
};
