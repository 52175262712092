import typeChecker from '../../typeChecker';

function updateExpression(tc_upd = {}, pb_upd = {}) {
  // '++' | '--';
  const check = {};

  if (tc_upd.operator) check.operator = false;
  if (tc_upd.argument) check.argument = false;

  if (pb_upd.operator && tc_upd.operator === pb_upd.operator) {
    check.operator = true;
  }

  if (pb_upd.argument?.type && tc_upd.argument?.type === pb_upd.argument?.type) {
    check.argument = typeChecker(tc_upd.argument, pb_upd.argument);
  }

  return Object.values(check).every(e => e === true);
}

export default updateExpression;