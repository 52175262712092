import React, { Component, Fragment } from "react";
import { Icon,  Button, Popover } from 'antd';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CopyButton from "./CopyButton";

export default function transform(node, index) {
  if (node.type === 'text' && node.data.toLowerCase().trim().indexOf('@solution') !== -1) {
    let [, id] = node.data.split(':');
    return (this.timeLock == 0 || this.solutions[id] == null) ?
      <td><Button style={{ color: '#7838d5' }} name="@solution" type="link"><Icon type="star" theme="filled" /> Afficher la solution</Button></td>
      :
      <td><Button disabled name="@solution" type="link"><Icon type="lock" theme="filled" /> Afficher la solution</Button></td>

  } else if (node.type === 'text' && node.data.toLowerCase().trim().indexOf('@aide') !== -1) {
    let [, id] = node.data.split(':');
    return (this.timeLock == 0 || this.solutions[id] == null)
      ? <td><Button style={{ color: '#F94A56' }} name="@help" type="link"><Icon type="bulb" theme="filled" /> Un peu d'aide</Button></td>
      : <td><Button disabled name="@help" type="link"><Icon type="lock" theme="filled" /> Un peu d'aide</Button></td>

  } else if (node.type === 'text' && node.data.toLowerCase().trim().indexOf('@tip') !== -1) {
    let [, id] = node.data.split(':');
    let content = [];
    if (this.solutions[id]) {
      for (let i = 0; i < this.solutions[id].length; i++) {
        content.push(<p>{this.solutions[id][i].textContent}</p>);
      }
    }

    return <Popover placement="right" content={content} trigger="click"><Button style={{ color: '#0077cd' }} name="@tip" type="link" icon="info-circle"></Button></Popover>;

  } else if (node.type === 'text' && node.data.toLowerCase().trim().indexOf('@video') !== -1) {
    let [, urlVideo] = node.data.split('::');
    // console.log(urlVideo);
    return <Player
      autoPlay={false}
      src={urlVideo}
      fluid={false}
      width={600}
    >
      <BigPlayButton position="center" />
      <LoadingSpinner />
    </Player>;
  }
    // if (node.type === "tag") {
    //   node.attribs.class = node.attribs.class + " gContentP";
    //   let elmt = convertNodeToElement(node, index, this.transform);
    //   return elmt;
    // }
    // else if (node.type === 'text' && node.data.toLowerCase().trim().indexOf('@code') !== -1) {
    //   let [, language] = node.data.split(':');
    //   console.log(node.parent.parent.parent.parent.parent.parent);

    // }
    
    else if (node.type === 'tag' 
      && node.name === 'table' 
      && (codeTitle = node.children[0].children[0].children[0].children[0].children[0].children[0].data.toLowerCase().trim()) 
      && codeTitle.indexOf('@code') !== -1) {
      
      var codeTitle;
      let [, language] = codeTitle.split(':');
      let code = '';
      let line = node.children[0].children[1].children[0].children;
      for(let i=0; i<line.length; i++) {
        code += line[i].children[0].children[0].data+"\n";
      }
      return(
        <div className="codeSnippets" style={{position:'relative'}}>
          <CopyButton valueToCopy={code}/>
          <SyntaxHighlighter language={language} style={monokaiSublime}>
            {code}
          </SyntaxHighlighter>
        </div>
      )
    }

  }