import React, { useState } from 'react'

//STYLES____________________
import './mvpdashboard.css'

//UI________________________
import { Layout, Menu } from 'antd'

//REDUX_______________________
import { connect } from 'react-redux'

//COMPONENTS___________________
import Products from './Products'
import SubmittedProjects from './SubmittedProjects'
import Header from '../Header/Header'

//SCROLLBAR_____________________________
import SimpleBar from 'simplebar-react'

//TRANSLATION
import { useTranslation } from 'react-i18next';

const { Item } = Menu
const { Sider, Content } = Layout

const MVPDashboard = ({ user }) => {
    //STATE HOOKS______________________
    const [keys, setKeys] = useState('1')
    const [collapsed, setCollapsed] = useState(false)
    const { t } = useTranslation();

    //FUNCTIONS_________________________
    const onCollapse = () => {
        setCollapsed(!collapsed)
    }

    return (
        <>
        <Header enableButtonAndSearch />
        <Layout className="mvp-layout">
            {
                user.type !== 'student' && user.type !== 'assistant' &&
                <Sider style={ styles.container } collapsible collapsed={ collapsed } onCollapse={ onCollapse } className={ collapsed ? 'custom-sider-collapsed' : (keys === '1' ? "custom-sider white-border" : 'custom-sider red-border') }>
                    <Menu 
                    mode="inline"
                    inlineCollapsed={ false } 
                    defaultSelectedKeys={ keys }
                    selectedKeys={ keys }
                    >
                        <Item key="1" style={ styles.item } onClick={ ()=>setKeys('1') }>MVP</Item>
                        <Item key="2" onClick={ ()=>setKeys('2') }>{t("MVP Soumis")}</Item>
                    </Menu>
                </Sider>
            }
            <Content style={ styles.contentContainer } className="mvp-content">
                <SimpleBar style={ { height: '100%' } }>
                    { 
                        keys === '1' ? 
                        <Products collapsed={ collapsed } />
                        : keys === '2' ?
                        <SubmittedProjects collapsed={ collapsed } />
                        : null
                    }
                </SimpleBar>
            </Content>
        </Layout>
        </>
    )
}

function mapStateToProps({ user }) {
    return { user }
}
export default connect(mapStateToProps, null)(MVPDashboard)

const styles = {
    container: {
        height: '100%',
        backgroundColor: '#ffffff',
        position: 'relative'
    },
    item: {
        fontWeight: 300,
        fontSize: '14px',
        color: '#898C8F',
        fontFamily: 'Poppins, sans-serif',
        paddingLeft: '24px'
    },
    submenu: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentContainer: {
        height: '100%',
    },
    button: {
        borderColor: '#F94A56',
        width: '43px',
        height: '40px',
        position: 'absolute',
        top: 4,
        right: 0,
        borderRadius: 0,
        zIndex: 200
    },
    buttonCollapsed: {
        right: 'auto',
        left: 0
    }
}
