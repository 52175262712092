import React, { Component } from 'react';
import { Col, Row, Tooltip, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ProjectCard from './ProjectCard';
import Vote from './Products/Vote';

class Products extends Component {
  state = {
    myProject: {},
    projects: [],
    fullProjectList: [],
    // dates: {}
    mvpVoteDay:0
  }

  componentDidMount() {
    const { batchid, user } = this.props;


    fetch(`${global.URI_BACKEND}/project/batch/${batchid}`).then(res => res.json()).then(data => {
      if (!data?.error) {
        const { projects, mvpVoteDay } = data;

        const fullProjectList = projects.slice();
        const myProject = projects.find(project => project?.team.find(member => member?.userId === user._id)) || projects.find(project => project?.owner?.userId === user._id) || {};

        if (myProject._id) projects.sort((a, b) => a.team.find(e => e.userId === user._id) && !b.team.find(e => e.userId === user._id) ? -1 : b.team.find(e => e.userId === user._id) && !a.team.find(e => e.userId === user._id) ? 1 : 0 );
        this.setState({ myProject, projects, fullProjectList, mvpVoteDay /*, dates */});
      }
    });
  }

  render() {
  const { card, rowTop, description, title, avatar, button, buttonText, card_container, col, /* rowBottom, pitchTitle, pitchDescription */ } = styles;
    const { myProject: { _id, name = null, owner, team = [], /*pitch = {},*/ voted }, projects, fullProjectList, mvpVoteDay /*, dates */} = this.state;
    const { user, collapsed, batch } = this.props;
    const avatars = [];
    if (team?.length > 0 && voted) {
      team.forEach(e => {
        avatars.push(<Tooltip key={e.userId} title={`${e.firstname} ${e.lastname}`} placement="bottomRight">
          <Avatar src={`../images/avatar/${e.userId}.jpg`} style={avatar} size={48} shape="square" icon="user" />
        </Tooltip>);
      });
    } else {
      avatars.push(<Tooltip key={owner?.userId} title={`${owner?.firstname} ${owner?.lastname}`} placement="bottomRight">
        <Avatar src={ owner?.userId ? `../images/avatar/${owner?.userId}.jpg` : `../images/avatar/_male-default.jpg`} style={avatar} size={48} shape="square" />
      </Tooltip>);
    }

    // const { currentDay } = dates;

    const projectsCreationDays = batch?.currentDay?.countDay < mvpVoteDay;
    const projectsVotesDay = batch?.currentDay?.countDay === mvpVoteDay;

    const cards = [];
    const groupsCreated = projects.some(project => project.voted && project.team.length > 0);
    projects.sort((_, b) => b._id === _id || b.owner.userId === user._id ? 1 : -1);
    projects.forEach(p => {
      if (groupsCreated && !p.voted) {
        return;
      }

      if (projectsCreationDays || projectsVotesDay || (p.team.length > 0 && p.voted)) {
        cards.push(<ProjectCard collapsed={collapsed} key={p._id} voted={p.voted} owner={p.owner} id={p._id} name={p.name} pitch={p.pitch} team={p.team} />);
      }
    });

    
    return (
      <Row>
          {
            user.type === 'student' && 
            <Col span={24} style={col}>
                { 
                (projectsCreationDays) &&
                  <div style={card}>
                    <Row style={rowTop} type="flex" justify="space-between" align="middle">
                      <Col span={24}>
                        <div style={description}>Mon projet</div>
                        <div style={title}>{name}</div>
                      </Col>
                      <Col span={24} style={{ marginTop: 10 }}>
                        {avatars}
                      </Col>
                    </Row>

                    <Row type="flex" justify="center" align="middle">
                      <Link to={`/project/${_id || ''}`} type="primary" style={button} id="my-project__button">
                            <span style={buttonText}>Proposer un projet</span>
                      </Link>
                    </Row>
                  </div>
                }

                {
                  projectsVotesDay && 
                  <Vote key={Date.now()} userId={user._id} options={fullProjectList.map(e => ({ _id: e._id, name: e.name }))} />
                }
            </Col>
          }
        <Col span={ collapsed || user.type === 'student' || user.type === 'assistant' ? 23 : 24 } offset={ collapsed || user.type === 'student' || user.type === 'assistant' ? 1 : 0 }>
          <Row type="flex" style={ card_container }>
            {cards}
          </Row>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps({ batchid, user, batch }) {
  return { batchid, user, batch };
}

export default connect(mapStateToProps)(Products);

const styles = {
  card: { background: 'white', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', margin: 10, minWidth: 240 },
  rowTop: { borderBottom: '1px solid #f5f5f5', padding: '15px 25px' },
  rowBottom: { padding: '0 25px' },
  description: { fontSize: 14, color: '#bfbfbf' },
  title: { fontSize: 22, color: '#323232', fontWeight: 'bold' },
  avatar: { borderRadius: 9 },
  pitchTitle: { fontSize: 17, color: '#F94A56', fontWeight: 'bold', marginTop: 20 },
  pitchDescription: { fontSize: 14, color: '#444444', fontWeight: 300, marginTop: 10 },
  button: { margin: '25px 0 25px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F94A56', borderRadius: 4, width: 186, height: 44, textAlign: 'center' },
  buttonText: { color: '#ffffff', fontWeight: 500, fontSize: 14 },
  card_container: {
    // justifyContent: 'center',
    width: '100%'
  },
  col: {
    display: 'flex',
    alignItems: 'center'
  }
}