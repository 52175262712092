import React, { useState, useEffect } from 'react';

// MOMENT____________________________
import moment from 'moment';

//UI_________________________________
import { Popover, List, Avatar, Spin } from 'antd';
import './Header.css';

//REDUX___________________________
import { connect } from 'react-redux';

// TRANSLATION -------------------
import { useTranslation } from 'react-i18next';

const Language = ({ user, language, setLanguage }) => {
  const [flag, setFlag] = useState('')
  const [loading, setLoading] = useState(false)

  const { i18n } = useTranslation();

  const languages = [
    {
      title: 'Français',
      key: 'fr',
      source: 'https://flagicons.lipis.dev/flags/4x3/fr.svg'
    },
    {
      title: 'English',
      key: 'en',
      source: 'https://flagicons.lipis.dev/flags/4x3/gb.svg'
    },
    // {
    //   title: 'Português',
    //   key: 'pt',
    //   source: 'https://flagicons.lipis.dev/flags/4x3/pt.svg'
    // },
  ];

  useEffect(() => {
    // setLanguage(language || user.preferredLanguage)
    setFlag(languages.find(el => el.key === language)?.source || languages.find(el => el.key === user.preferredLanguage)?.source)
    i18n.changeLanguage(language || user.preferredLanguage)
    moment.locale(language || user.preferredLanguage)
  }, [])

  const changeLanguage = async function (lang) {
    if (lang !== language) {
      setLoading(true)
      setLanguage(lang)
      setFlag(languages.find(el => el.key === lang)?.source)
      i18n.changeLanguage(lang)
      moment.locale(lang)
      await fetch(`${global.URI_BACKEND}/user/set-language/${user._id}/${lang}`)
      setLoading(false)
    }
  }

  return <></> // Supprimer cette ligne et décommenter les lignes suivantes pour afficher le bouton de langue
  // return <Popover content={
  //         <List
  //         itemLayout="horizontal"
  //         dataSource={languages}
  //         renderItem={item => (
  //           <List.Item>
  //             <List.Item.Meta
  //               avatar={<Avatar style={styles.listFlags} src={item.source} />}
  //               title={<a onClick={() => changeLanguage(item.key)}>{item.title}</a>}
  //             />
  //           </List.Item>
  //         )}
  //       />
  //     } >
  //       {!loading ? <Avatar style={styles.langBtn} src={flag} /> : <Spin style={styles.spinner} />}
  //     </Popover>

}

function mapStateToProps({ user, batch, day, language }) {
  return { user, batch, day, language };
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: function (language) {
      dispatch({ type: 'setLanguage', language });
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Language)

const styles = {
  langBtn: {
    marginRight: 20,
    width: 22, 
    height: 22, 
    border: '1.5px solid rgb(188, 188, 188)'
  },
  spinner: {
    marginRight: 20,
    width: 22, 
    height: 22, 
  },
  listFlags: {
    width: 22, 
    height: 22, 
  }
}