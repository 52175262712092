import React, {Component} from 'react';
import { Col, Descriptions, Icon, Spin, Button, Tooltip, Modal, Input, Form, List, Avatar, Divider } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import uid2 from 'uid2';


const { Item } = Form;
const { Search } = Input;
moment.locale('en');

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class CampusInfo extends Component {
  state = {
    visibleStaff: false,
    staff: [],
    userSearch: [],
    visible: false,
    confirmLoading: false,
    address: null,
    searchValue: false,
  };

  componentDidMount() {
    if(this.props.campus.staff.length !== 0){
      fetch(global.URI_BACKEND + `/batch/currentStaff/campus/${this.props.campus._id}`).then(res => res.json()).then(async data => data.success ? this.setState({staff: data.staff}) : null)
    }
  }

  showModalStaff = () => {
    this.setState({visibleStaff: true});
  };

  handleCancelStaff = () => {
    this.setState({visibleStaff: false});
  };

  Search = (value) => {
    const regex = new RegExp(`${value}.*`, 'i');
    this.setState({userSearch: this.props.allStaff.filter(e => e.fullname.search(regex) !== -1)});
  };

  addStaff = (user) => {
    fetch(global.URI_BACKEND + `/batch/addStaff`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({batch:this.props.campus, user:{_id: user._id, type: user.type}, type:'campus'})
    }).then(res => res.json()).then(async data => data.success ? this.setState({staff: [...this.state.staff, {fullname: user.fullname, type: user.type, _id: user._id}]}) : null)
  };

  delStaff = (user) => {
    fetch(global.URI_BACKEND + `/batch/deleteStaff/campus/${this.props.campus._id}/${JSON.stringify({_id: user._id, type: user.type})}/${this.props.campus.campus}`, { method: 'DELETE' }).then(res => res.json()).then(async data => data.success ? this.setState({staff: this.state.staff.filter(e => e._id !== user._id)}) : null)
  };

  showModal = () => {
    this.setState({visible: true});
};


handleSubmit = (e) => {
  e.preventDefault();

  const { campus } = this.props;
  const { address } = this.state;
  
  this.setState({ confirmLoading: true, logError: '' }, () => {

        const body = { id: campus._id, address: address ? address : null };

        fetch(global.URI_BACKEND + '/campus', {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
          }).then(res => res.json()).then(async data => {
            if (data.success) {
            
            this.props.changeCampus(data.batch, this.props.index)
            this.setState({ visible: false, confirmLoading: false });

            } else {
                this.setState({ logError: "Erreur, le campus n'a pas pu être modifié" });
            }
        });
   
  });
};

handleCancel = () => {
  this.setState({visible: false});
};


  render() {
    const {center} = styles;
    const { campus } = this.props;
    const { staff, visibleStaff, userSearch, visible, confirmLoading, logError, searchValue } = this.state;

    const isStaff = (id) => {
      let isInStaff = staff.filter(e => e._id === id);
      return isInStaff.length !== 0 ? true : false
    }

    return (
      <div style={{backgroundColor:'white', borderRadius:20, padding:20, margin:15, borderBottom:"1px solid #E7E8ED", borderRight:"1px solid #E7E8ED"}}>
        <Descriptions title={`Campus ${campus.campus.charAt(0).toUpperCase()+campus.campus.slice(1)}`}>
          <Descriptions.Item span={1.5} label="Adresse">{campus.address} <Tooltip title="Modifier l'adresse"><Icon type="edit" style={{fontSize:15, color:'#F94A56', marginRight:15, marginTop:10}} onClick={this.showModal}/></Tooltip></Descriptions.Item>
          <Descriptions.Item label="Staff"><a onClick={this.showModalStaff}>Voir Staff</a> ({staff.length}{staff.length < 2 ? ' affecté' : ' affectés'})</Descriptions.Item>
          {campus.campusPhone ? <Descriptions.Item label="Phone">{campus.campusPhone}</Descriptions.Item> : null}
        </Descriptions>

        {/*Modal add staff*/}
         <Modal
          title={`Gestion du staff - Campus ${campus.campus.charAt(0).toUpperCase()+campus.campus.slice(1)}`}
          visible={visibleStaff}
          onOk={this.handleCancelStaff}
          onCancel={this.handleCancelStaff}
        >
            <h4>Current Staff</h4>
            {staff.length === 0 ?
            <p>No one assigned.</p>
            :<List>
            {staff.map((e, i)=>{
              return (<List.Item key={i+4} >
              <List.Item.Meta
                avatar={<Avatar src={`${global.URI_FRONTEND}/images/avatar/${e._id}.jpg`} />}
                title={e.fullname}
                description={e.type}
              />
              <Icon type="close-circle" style={{fontSize:19 , color:'red'}}  onClick={()=>this.delStaff(e)}/>
            </List.Item>)
            })}
            </List>}
            <Divider style={{marginTop:0}}  />
            <Search placeholder="Search Staff" style={{marginBottom:10}} allowClear onSearch={(value) => {
              if(value){
                this.Search(value);
                this.setState({searchValue:true});
              }else{
                this.setState({userSearch:[], searchValue:false})
              }}} />
            {userSearch.length === 0 && searchValue?
            <p>No user found.</p>
            :<List>
            {userSearch.map((e, i)=>{
              return (<List.Item key={i+3} >
              <List.Item.Meta
                avatar={<Avatar src={`${global.URI_FRONTEND}/images/avatar/${e._id}.jpg`} />}
                title={e.fullname}
                description={e.type}
              />
              {isStaff(e._id) ? 
              <Icon type="check-circle" style={{fontSize:19, color: 'lime'}} />
              : <Tooltip title="Add to staff"><Icon type="check-circle" style={{fontSize:19, color: 'grey'}} onClick={()=>this.addStaff(e)}/></Tooltip>
               }
            </List.Item>)
            })}
            </List>}
        </Modal>

        {/*Modal edit batch*/}
      <Modal
        title={`Modifier le Campus ${campus.campus.charAt(0).toUpperCase()+campus.campus.slice(1)}`}
        visible={visible}
        onOk={this.handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={this.handleCancel}
      > 
        <Form style={center}> 
          <Input placeholder="Address" defaultValue={campus.address} onChange={(e)=>this.setState({address:e.target.value})}/>
        </Form>
        {logError && <p style={{color:'red'}}>{logError}</p>}
      </Modal>  
    </div>
    );
  }
}


class EditCampus extends Component {
    state = {
        visible: false,
        confirmLoading: false,
        logError: '',
        allStaff: [],
        campus: [],
        loadingScreen: true,
    };
    
    componentDidMount() {
      this.props.form.validateFields();
      
      fetch(global.URI_BACKEND + '/batch/allCampus').then(res => res.json()).then(async data => data.campus ? this.setState({campus: data.campus, allStaff: data.users, loadingScreen: false}) : null)
    
    }

    showModal = () => {
        this.setState({visible: true});
    };


    handleSubmit = (e) => {
      e.preventDefault();

      this.setState({ confirmLoading: true, logError: '' }, () => {

        this.props.form.validateFields((err, values) => {
          if (!err) {
            const { city, address, phone } = values;

            const body = { city: city.toLowerCase(), address};

            if(phone) body.phone = phone;

            fetch(global.URI_BACKEND + '/batch/campus', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
              }).then(res => res.json()).then(async data => {
                if (data.success) {
                
                let tempCampus = [...this.state.campus];
                tempCampus.unshift(data.campus)
                this.setState({ visible: false, confirmLoading: false, campus: tempCampus});

                }else{
                    this.setState({ logError: "Erreur, le batch n'a pas pu être créé" });
                }
            });
          }
        });
      });
    };

    handleCancel = () => {
      this.setState({visible: false});
    };

    changeCampus = (campus, i) => {
      let tempCampus = [...this.state.campus];
      tempCampus[i] = campus;
      this.setState({campus: tempCampus});
    };


  render() {

    const { center } = styles;
    const { visible, confirmLoading, allStaff, campus, loadingScreen, logError} = this.state;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    const cityError = isFieldTouched('city') && getFieldError('city');
    const addressError = isFieldTouched('address') && getFieldError('address');

    let campuslist = campus.map((campus, i)=> {
        return (<CampusInfo key={uid2(5)} index={i} campus={campus} changeCampus={this.changeCampus} allStaff={allStaff}/>);
      });

    return (
    <Col span={24}>
    {this.props.user.type === 'superadmin' ?
      <Tooltip title="Créer un nouveau campus" placement="right">
        <Button type="primary" ghost shape="circle" onClick={this.showModal} style={{fontSize: 20, margin: 10, marginLeft: 25}}>+</Button>
      </Tooltip>
    : null}

      {loadingScreen && <div style={{...center, height:500}}><Spin indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />} /></div>}

      {campuslist} 

      {/*Modal create campus*/}
      <Modal
        title="Créer un nouveau campus"
        visible={visible}
        onOk={this.handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={this.handleCancel}
        okButtonProps={{disabled:hasErrors(getFieldsError())}}
      >
        <Form style={{...center, flexDirection:'column'}}>

          <Item style={{marginBottom:5,width:'80%'}} name="city" validateStatus={cityError ? 'error' : ''} help={cityError || ''} >
            {getFieldDecorator('city', {
              rules: [{ required: true, message: 'Please enter city name.' }]
            })(<Input placeholder="City Name"/> )} 
          </Item> 
        
          <Item style={{marginBottom:5, width:'80%'}} name="address" validateStatus={addressError ? 'error' : ''} help={addressError || ''} >
          {getFieldDecorator('address', {
              rules: [{ required: true, message: 'Please enter address.' }]
          })(<Input  placeholder="Address"/> )} 
          </Item> 

          <Item style={{marginBottom:5, width:'80%'}} name="phone" >
          {getFieldDecorator('phone')(<Input  placeholder="Phone"/> )} 
          </Item> 

          {logError && <p style={{color:'red'}}>{logError}</p>}
        </Form>
      </Modal>
    </Col>);
  }
}

function mapStateToProps({ user }) {
  return { user };
}

const WrappedEditCampus = Form.create({ name: 'create_campus' })(EditCampus);
export default connect(mapStateToProps, null)(WrappedEditCampus);

const styles = {
    item: {marginBottom:5, width:'88%'},
    select: {width: 120, marginBottom:15},
    center: { display: 'flex', justifyContent: "center", alignItems: 'center' },
    m5: {margin:10}
}