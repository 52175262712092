import React, { useEffect, useState } from "react";

//STYLES__________________
import "../Content/Flashcard.css";
import "./stepheader.css";
import { iconSrc } from "../../../static-data";

//NAVIGATION_____________
import { Link } from "react-router-dom";

//UI______________________
import { Row, Tag, Progress, Avatar, Rate, Icon } from "antd";

//REDUX________________________
import { connect } from "react-redux";

//TRANSLATION________________________
import { useTranslation } from "react-i18next";

const tags = {
  course: "COURS",
  project: "PROJET",
  exercise: "CHALLENGE",
  quizz: "QUIZ",
};

/**
 * Function to determine overall unlock status of step
 * @param {Object} resource 
 * @returns {Boolean}
 */
const isEnabled = resource => {
  if (!resource.enable) return false;

  if (!resource.progress) return true;

  if (resource.stepType_v2 === "rncp" && resource.tag !== "positionnement") return resource.unlockedQuiz;

  if (resource.stepType_v2 === "flashcard") return resource.unlockedFlashCard;

  return resource.progressUnlocked;
}

/**
 * Function to determine individual student unlock status
 * @param {Object} resource 
 * @returns {Boolean}
 */
const isStudentEnabled = resource => {
  if (!resource.progress) return true;

  if (["rncp", "flashcard"].includes(resource.stepType_v2) && resource.tag !== "positionnement") return true;

  return resource.progressUnlocked;
}

const Resource = ({
  QCMUnlocked,
  flashcardsUnlocked,
  resource,
  user,
  day,
  toggleModal,
  changeStep,
  index,
  stepsUnlocked,
  batch,
  showLocked = false,
  showDay = false,
}) => {
  //TRANSLATION________________________
  const { t } = useTranslation();

  //STATE HOOKS______________________
  const [questionCount, setQuestionCount] = useState(null);
  // const [score, setScore] = useState(null);
  const [isProject, setIsProject] = useState(null);

  //EFFECT HOOKS____________________
  useEffect(() => {
    const getQuizzData = async () => {
      if (resource.scorePercentage && resource.questionCount) {
        setQuestionCount(resource.questionCount);
        // setScore(
        //   Math.round((resource.questionCount * resource.scorePercentage) / 100)
        // );
      } else {
        const data = await fetch(
          `${global.URI_BACKEND}/stepprogression/user/${user._id}/${batch._id}`
        );
        const json = await data.json();

        if (json.success) {
          let questions = json.progression.progression.find(
            e => e.step === resource.stepId
          )?.sections;

          if (questions) {
            setQuestionCount(questions.length);
            // setScore(questions.filter(e => e.result).length);
          }
        }
      }
    };
    if (
      (resource.stepType_v2 === "quizz" ||
        resource.stepType_v2 === "rncp" ||
        resource.stepType_v2 === "flashcard") &&
      (resource?.stepProgress === 100 || resource?.stepPercentage === 100)
    ) {
      getQuizzData();
    }
  }, [resource, batch._id, user._id]);

  useEffect(() => {
    if (resource.text === "Projet") {
      fetch(`${global.URI_BACKEND}/project/batch/${batch._id}`)
        .then(res => res.json())
        .then(data => {
          if (!data?.error) {
            const { projects } = data;

            const myProject =
              projects.find(project =>
                project?.team.find(member => member?.userId === user._id)
              ) ||
              projects.find(project => project?.owner?.userId === user._id) ||
              null;
            setIsProject(myProject);
          }
        });
    }
  }, [batch, resource, user]);

  //FUNCTIONS________________________
  const toggleStep = () => {
    if (isClickable) {
      if (changeStep) changeStep(resource);
      if (toggleModal) toggleModal();
    }
  };

  const toggleUnlockStyle = () => {

    if (isLocked && isClickable) return { ...styles.modal_content, ...styles.blockedOther };

    if (isLocked && !isClickable) return { ...styles.modal_content, ...styles.blockedStudent }

    return styles.modal_content;
  };

  let isCompleted = false;
  let isInError = false;

  if (resource.scoreResult && resource.questionCount) {
    if (resource.scoreResult >= resource.questionCount) {
      isCompleted = true;
    } else {
      isInError = true;
    }
  }

  const isLocked = !isEnabled(resource);
  const isClickable = !isLocked || !["student", "workshop"].includes(user.type) ? true : false;
  const step = (
    <Row onClick={() => toggleStep()} style={toggleUnlockStyle()}>
      <div style={{ ...styles.content_section, ...styles.content_header }}>
        {showDay && resource.day && (
          <h4 style={styles.day_text}>
            {t("Jour", { count: 1 })} {resource.day}
          </h4>
        )}
        <Avatar
          shape="square"
          size="large"
          className={resource.stepType_v2}
          style={styles.avatar}
        >
          {resource.imagepath ? (
            <img
              style={styles.img}
              src={resource?.imagepath || day?.imagepath || ""}
              alt={resource.text}
            />
          ) : (
            <i
              className={
                iconSrc[resource.tag || resource.secondaryGroup] +
                ` category-icon ${resource.tag}`
              }
            />
          )}
        </Avatar>
        <div>
          <h5 style={{ ...styles.modal_title, margin: 0 }}>{resource.text}</h5>
          <h6
            style={{
              ...styles.modal_title,
              ...styles.modal_subtitle,
              margin: 0,
            }}
          >
            {t(resource.secondaryGroup)}
          </h6>
        </div>
      </div>
      <div style={{ ...styles.content_section, ...styles.content_footer }}>
        {resource.optional ? (
          <Tag
            style={{ ...styles.tag, ...styles.optionalTag }}
            title="optionnel"
            className={`optional${isCompleted && " finished"}`}
          >
            OPT.
          </Tag>
        ) : // : resource.stepType_v2 === "rncp" ||
          //   resource.stepType_v2 === "flashcard" ? (
          //   <i
          //     className="icon-medal"
          //     style={{ ...styles.optionalTag, fontSize: "22px" }}
          //   />
          // )
          resource.stepType_v2 === "briefing" ? (
            <i
              className="icon-brain-cog"
              style={{ ...styles.optionalTag, fontSize: "22px" }}
            />
          ) : null}
        {resource.stepType_v2 && (
          <Tag
            style={{ ...styles.tag, ...styles.typeTag }}
            className={resource.stepType_v2}
          >
            {t(
              tags[resource.stepType_v2] || resource.stepType_v2.toUpperCase()
            )}
          </Tag>
        )}
        {Boolean(resource.scoreResult && resource.questionCount) && (
          <p style={styles.score}>
            {resource.scoreResult}/{resource.questionCount}
          </p>
        )}
        {resource.secondaryGroup !== "Briefing" &&
          resource.secondaryGroup !== "Review" &&
          resource.progress !== false ? (
          <>
            {
              <>
                {isLocked ? (
                  <Progress
                    style={{ ...styles.progress, marginRight: -3 }}
                    type="circle"
                    percent={0}
                    width={30}
                    format={() => (
                      !isStudentEnabled(resource) && <i className="icon-lock" style={styles.icon} />
                    )}
                  />
                ) : resource.optional ? (
                  <Rate
                    count={1}
                    disabled
                    allowHalf
                    value={isCompleted ? 1 : 0}
                  />
                ) : isInError ? (
                  <Progress
                    style={styles.progress}
                    type="circle"
                    percent={100}
                    width={20}
                    trailColor={"#F94A56"}
                    className={"ant-progress-resource"}
                    status={"exception"}
                    format={() => <Icon type="close" />}
                  />
                ) : (
                  <Progress
                    style={styles.progress}
                    type="circle"
                    percent={isCompleted ? 100 : 0}
                    width={20}
                    trailColor={
                      isCompleted
                        ? "rgb(152, 237, 196)"
                        : "rgba(217, 217, 217, 0.46)"
                    }
                    className={"ant-progress-resource"}
                    status={isCompleted ? "success" : "active"}
                    format={() => (isCompleted ? <Icon type="check" /> : <></>)}
                  />
                )}
              </>
            }
          </>
        ) : (
          <div style={{ width: "35px", marginLeft: 12 }}></div>
        )}
      </div>
    </Row>
  );

  return isClickable ? (
    <Link
      to={
        resource.text === "Projet"
          ? isProject
            ? `/projects/${isProject._id}`
            : `/projects`
          : `/batch/${resource.stepType}/${resource.stepId}`
      }
      style={{ width: "100%" }}
    >
      {step}
    </Link>
  ) : (
    <>{step}</>
  );
};

function mapStateToProps({ user, batch }) {
  return { user, batch };
}

export default connect(mapStateToProps, null)(Resource);

const styles = {
  modal_content: {
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "65px",
    border: "1px solid #f0f2f5",
    minWidth: "540px",
    width: "100%",
    position: "relative",
    cursor: "pointer",
  },
  content_section: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: "0px 20px",
  },
  content_header: {
    position: "absolute",
    left: 0,
  },
  content_footer: {
    position: "absolute",
    right: 0,
  },
  tag: {
    borderRadius: "50px",
    fontSize: "9px",
    fontWeight: 600,
    padding: "0px 6px",
    lineHeight: "13.5px",
    fontFamily: '"Poppins", sans-serif',
    position: "absolute",
    margin: 0,
  },
  typeTag: {
    right: 132,
  },
  optionalTag: {
    position: "absolute",
    right: 235,
  },
  // typeTag: {
  //     margin: 'auto 0px',
  //     position: "absolute",
  //     right: 130
  // },
  img: {
    objectFit: "contain",
    height: "32px",
    width: "auto",
  },
  avatar: {
    marginRight: 12,
  },
  progress: {
    fontWeight: 600,
  },
  icon: {
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.17)",
  },
  blockedStudent: {
    opacity: 0.4,
    cursor: "not-allowed",
  },
  blockedOther: {
    opacity: 0.4,
  },
  modal_title: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
  },
  modal_subtitle: {
    fontWeight: 300,
  },
  placeholder: {
    width: "35px",
    marginLeft: 12,
  },
  score: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 500,
    fontSize: "13px",
    position: "absolute",
    right: 75,
    color: "#898C8F",
    margin: "auto 0px",
  },
  column: {
    position: "absolute",
    right: 220,
  },
  day_text: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    color: "#5247EA",
    fontSize: "14px",
    margin: "0px 20px 0px 0px",
  },
};
