import React, { Component } from "react";
import { Drawer, Icon, notification, Button } from "antd";
import Draw from "../../components/draw";
import { connect } from "react-redux";
import moment from "moment";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

class Satisfaction extends Component {
  state = {
    step: this.props?.step,
    selectedNotion: null,
    selectedProject: null,
    fetching: false,
    closable: false,
    hasSigned: false,
    hasFeeling: this.props?.hasFeeling,
  };

  componentDidMount() {
    const { step } = this.state;
    const { batch, user } = this.props;

    // if (batch.city !== "Remote" && !user.remote) {
    //   if (batch.currentDay.countDay > 1 && step === 1) this.setState({ step: step + 1 })
    //   else this.onClose();
    // }
  }

  nextStep = () => {
    const { step } = this.state;
    const { user, batchid } = this.props;

    if (step < 2) {
      this.setState({ step: step + 1 }, () => {
        if (step > 1 && user.type !== "student") this.onClose();
      });
    } else if (step === 2 && this.state.selectedNotion !== null) {
      this.setState({ step: step + 1 });
    } else if (
      step === 3 &&
      this.state.selectedNotion !== null &&
      this.state.selectedProject !== null
    ) {
      this.setState({ fetching: true });
      const { selectedNotion, selectedProject } = this.state;

      fetch(`${global.URI_BACKEND}/feelingofday/`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user: user._id,
          batch: batchid,
          date: Date.now(),
          notionUnderstood: selectedNotion,
          projectProgress: selectedProject,
        }),
      })
        .then(res => res.json())
        .then(data => {
          this.setState({ fetching: false });
          if (data?.success) {
            const { feeling: registered } = data;

            if (registered) {
              notification.success({
                message: "Feedback",
                description: "Merci pour tes retours !",
              });
            } else {
              notification.warn({
                message: "Feedback",
                description: "Données déjà enregistrées",
              });
            }

            this.setState({ step: 1, closable: true }, () => {
              this.onClose();
            });
          }
        });
    }
  };

  onClose = () => {
    const { closeDrawer } = this.props;

    closeDrawer();
  };

  onSend = () => {

    const { step, hasFeeling } = this.state;
    const {
      user: { batchList },
      batch,
      batch: { currentDay },
      hasFeeling: hasFeelingProps,
      duringBatch,
    } = this.props;


    const { userType } = batchList.find(el => el.batchId === batch._id);

    let lastCourseDay =
      batch?.planning[batch.planning?.length - 1]?.days[
        batch.planning[batch.planning.length - 1].days?.length - 1
      ]?.countDay;

    batch.planning.forEach(week =>
      week.days.forEach(day => {
        if (day.dayType === "lastCourse") lastCourseDay = day.countDay;
      })
    );

    if (userType === "student") {
      // Case student
      if (
        duringBatch &&
        currentDay.countDay > 1 &&
        currentDay.countDay <= lastCourseDay + 1 &&
        !hasFeeling &&
        !hasFeelingProps
      ) {
        this.setState({ step: step + 1 });
      } else {
        this.setState({ step: 1 }, () => {
          this.onClose();
        });
      }
    } else {
      // Case teacher
      this.setState({ closable: true }, () => {
        this.onClose();
      });
    }
  };

  selectNotion = selectedNotion => {
    this.setState({ selectedNotion });
  };

  selectProject = selectedProject => {
    this.setState({ selectedProject });
  };

  emCheck = hasSigned => {
    const { am, pm } = hasSigned;
    const midday = moment.utc().startOf("day").add(12, "hours");

    if (
      (am && pm) ||
      (am && moment.utc().isBefore(midday)) ||
      (pm && moment.utc().isAfter(midday))
    )
      this.setState({ hasSigned: true });
  };

  render() {
    const { step, selectedNotion, selectedProject, closable, fetching } =
      this.state;
    const { user, batchid, visible, t, duringBatch } = this.props;

    const notions = [
      { emoji: "very_happy", text: t("J'ai compris tous les concepts"), id: 1 },
      {
        emoji: "happy",
        text: t("J'ai globalement compris les concepts"),
        id: 2,
      },
      {
        emoji: "neutral",
        text: t("J'ai moyennement compris les concepts"),
        id: 3,
      },
      {
        emoji: "bad",
        text: t("Je n'ai globalement pas compris les concepts"),
        id: 4,
      },
      { emoji: "very_bad", text: t("Je n'ai rien compris"), id: 5 },
    ];

    const notionsList = notions.map(({ emoji, text, id }) => {
      let className = "";

      if (selectedNotion === id) className += " satisfaction-selected";

      return (
        <p key={id} className={className} onClick={() => this.selectNotion(id)}>
          <img src={`/images/emojis/${emoji}.png`} alt={`${emoji} face`} />
          <span>{text}</span>
        </p>
      );
    });

    const Project = [
      { emoji: "grinning", text: t("J'ai commencé les challenges"), id: 1 },
      { emoji: "happy", text: t("J'ai fini en avance"), id: 2 },
      { emoji: "smiling", text: t("J'ai fini dans les temps"), id: 3 },
      { emoji: "sleepy", text: t("Je n'ai pas fini"), id: 4 },
    ];

    const ProjectList = Project.map(({ emoji, text, id }) => {
      let className = "";

      if (selectedProject === id) className += " satisfaction-selected";

      return (
        <p
          key={id}
          className={className}
          onClick={() => this.selectProject(id)}
        >
          <img src={`/images/emojis/${emoji}.png`} alt={`${emoji} face`} />
          <span>{text}</span>
        </p>
      );
    });
    return (
      <Drawer
        drawerStyle={{ background: "#f7f7f7", minHeight: "100vh" }}
        placement="right"
        onClose={this.onClose}
        visible={visible}
        destroyOnClose={true}
        closable={false}
        maskClosable={closable}
        width={470}
        id="satisfaction"
      >
        <img
          src="/images/backgrounds/emargement.jpg"
          alt="students smiling"
          className="satisfaction-img"
        />

        {step === 1 && (
          <div className="satisfaction-container satisfaction-bg-1">
            <Draw
              newKey={Date.now()}
              emCheck={this.emCheck}
              // showDrawer={this.emargementModal}
              // forceModalDisplay={this.forceModalDisplay}
              student={user}
              batchId={batchid}
              onSend={this.onSend}
              isForcedDuringBatch={false}
              duringBatch={duringBatch}

            />
          </div>
        )}

        {step === 2 && (
          <div className="satisfaction-container satisfaction-bg-2">
            <div
              className="satisfaction-q1"
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
            >
              <p>{t("Comment s'est passée")}</p>
            </div>

            <div
              className="satisfaction-q2"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <p>{t("As-tu bien compris la notion du jour ?")}</p>
            </div>

            <div className="satisfaction-choices">
              {notionsList}

              <button
                className="satisfaction-button"
                onClick={this.nextStep}
                disabled={selectedNotion == null}
              >
                <Icon type="arrow-right" className="satisfaction-icon" />
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="satisfaction-container satisfaction-bg-2">
            <div
              className="satisfaction-q1"
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
            >
              <p>{t("Comment s'est passée")}</p>
            </div>

            <div
              className="satisfaction-q2"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <p>{t("Ou en étais-tu dans ton projet")}</p>
            </div>

            <div className="satisfaction-choices">
              {ProjectList}

              <Button
                className="satisfaction-button"
                style={{ fontWeight: 700, fontSize: "15px" }}
                type="primary"
                onClick={this.nextStep}
                loading={fetching}
                disabled={selectedProject == null}
              >
                {t("Envoyer")}
              </Button>
            </div>
          </div>
        )}
      </Drawer>
    );
  }
}

function mapStateToProps({ user, batch, batchid, stackid }) {
  return { user, batch, batchid, stackid };
}

export default connect(mapStateToProps)(withTranslation()(Satisfaction));
