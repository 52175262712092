import React, { Component } from "react";
// import ReactQuill from 'react-quill';
import AceEditor from "react-ace";
import moment from "moment";
import {
  Col,
  Row,
  Button,
  Icon,
  Tabs,
  Tooltip,
  Steps,
  Modal,
  notification,
  Popover,
  Switch,
  Input,
  Select,
  Progress,
} from "antd";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-tomorrow_night_bright";
import "./Code.css";
import "../Content/Course.css";

import Worker from "./file.worker.js";
import * as esprima from "esprima";
import test from "./esprima/esprima_tests";
import checkRank from "../../../../utils/checkRank";

import skulpt from "./skulpt";
import skulptStdlib from "./skulptStdlib";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

import importInfiniteLoopDetector from "./infiniteLoopDetector";
var infiniteLoopDetector = importInfiniteLoopDetector;

const { TabPane } = Tabs;
const { Step } = Steps;
const { Option } = Select;

class Code extends Component {
  defaultDisplay = {
    projSpan: 8,
    editContOffset: 0,
    editContSpan: 16,
    editSpan: 12,
    viewSpan: 12,
  };

  state = {
    loading: false,
    contentDisplay: null,
    fullscreen: false,
    display: this.defaultDisplay,
    index: 0, // indicates the number of already answered question(s) inside the active section
    width: "1000",
    practiceIndex: 0,
    practiceSelected: 0,
    practiceLength: this.props.practice?.length - 1,
    contentTab: [],
    challengeHeight: 0,
    redirect: false,
    progressList: [],
    percents: {},
    practices: [], // already complete practices
    practices2: [], // ??
    loadCode: undefined,
    exerciseSection: [],
    confirmModalDisplay: false,
    confirmModalDisplayRestricted: false,
    modifiedPractice: null,
    current: 0,
    modalTab: "tdd",
    tddCorrection: null,
    readOnly: false,
    editMode: false,
    solutionDisplay: null,
    resultTDD: "",
    resultESParse: "",
    esParse: false,
    tddParse: false,
    editing: false,
    hasScript: false,
    codeEdit: false,
    newTab: null,
    solutionModal: false,
    solutionIndexDisplay: null,
    logAndErrorDisplay: [],
    isSubmitting: false,
  };

  constructor(props) {
    super(props);
    this.challengeDiv = React.createRef();
    this.challengeScroll = React.createRef();
  }

  displays = [
    { value: "terminal", label: "Console" },
    { value: "dom", label: "Browser" },
  ];

  codefiles = [];
  updateState = state => this.setState(state);
  timeout;

  componentDidMount() {
    window.onmessage = event => {
      // console.log(event.data);
      this.state.logAndErrorDisplay.push(event.data);
      this.setState({ logAndErrorDisplay: this.state.logAndErrorDisplay });
    };

    this.onLoadAndUpdate().then(percents => {
      const { practice } = this.props;
      let lastStartedStep = 0;
      while (
        lastStartedStep < practice.length - 1 &&
        percents[practice[lastStartedStep]._id] === 100
      ) {
        lastStartedStep++;
      }
      // console.log(lastStartedStep)
      this.setState({ practiceIndex: lastStartedStep });
    });

    window.addEventListener("keydown", this.downHandler.bind(this));
    window.addEventListener("keyup", this.upHandler.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    const { practiceIndex, editing } = this.state;
    const { practiceIndex: prevPracticeIndex } = prevState;
    const { practice, next, stepsList } = this.props;
    const { practice: prevPractice } = prevProps;

    if (
      !editing &&
      (practice[0]?._id !== prevPractice[0]?._id ||
        practiceIndex !== prevPracticeIndex)
    ) {
      this.onLoadAndUpdate();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    window.removeEventListener("keydown", this.downHandler.bind(this));
    window.removeEventListener("keyup", this.upHandler.bind(this));
  }

  keysPressed = {};

  downHandler(e) {
    let key = e.key;
    this.keysPressed[key] = true;
    if (this.keysPressed["Control"] && this.keysPressed["Enter"]) {
      this.handleSubmit(true);
    }
  }

  // If released key is our target key then set to false
  upHandler = ({ key }) => {
    delete this.keysPressed[key];
  };

  interval = 5;
  timer;

  onLoadAndUpdate = async (
    loadPractice = null,
    loadQuestion = null,
    fileTab = null
  ) => {
    this.timer = setInterval(
      () => this.unlockSteps(),
      this.interval * 1000 * 60
    );

    if (this.unlockSteps().QCMUnlocked && this.unlockSteps().flashcardsUnlocked)
      clearInterval(this.timer);

    const {
      stepsList,
      id,
      progression,
      practice,
      user,
      stepData,
      tracks,
      batch,
      changeDay,
      t: nextIndex,
    } = this.props;
    const { practiceIndex: statePracticeIndex } = this.state;
    let practiceIndex = loadPractice || statePracticeIndex;

    let next;


    const current = stepsList.find(e => e.stepId === id);

    // const currentPos = stepsTmp.findIndex(
    //   e => e.stepId === s.stepId
    // );
    // const nextTmpPos = Math.min(
    //   currentPos + 1,
    //   stepsTmp.length - 1
    // ); // checker la condition de fin de liste (via un min entre next et length liste)
    // const nextPos = stepsList.findIndex(
    //   e => e.stepId === stepsTmp[nextTmpPos]._id
    // );

    this.setState({ current });

    let progress;
    let practices2 = null;

    for (const e of progression) {
      if (next && progression.stepId === next.stepId) progress = e;
    }

    if (!progress?.enable) next = false;

    await fetch(
      `${global.URI_BACKEND}/stepprogression/user/${user._id}/${batch._id}`
    )
      .then(res => res.json())
      .then(data => {
        if (data.success && data.progression !== null) {
          for (const d of data.progression.progression) {
            if (d.step === stepData.stepId) {
              practices2 = d.sections;
            }
          }
        }
      });

    let percents = {};
    if (practices2) {
      for (const e of practices2) {
        if (!percents[e.practiceId]) percents[e.practiceId] = 0;

        percents[e.practiceId] = Math.round(e.percentage);

        for (const c of e.codefiles) {
          if (!this.codefiles[c._id]) this.codefiles[c._id] = {};

          //this.codefiles[c._id].codefile = c.codefile;
        }
      }
    }

    let currentPractice = practices2?.find(
      p => p.practiceId === practice[practiceIndex]._id
    );
    const loadCode = currentPractice?.codefiles;
    let contentTab = practice[practiceIndex]?.codefiles?.map(e => ({
      name: e.name,
      code: e.code || loadCode || "",
      filetype: e.filetype || e.type || "javascript",
    }));

    if (loadPractice !== null && loadQuestion !== null) {
      let solution =
        practice[practiceIndex]?.questions?.[loadQuestion]?.solution;
      if (solution.length) contentTab = solution;
    }
    let state = {
      index: 0,
      current,
      next,
      practiceSelected: practiceIndex,
      contentTab,
      /* progressList, */ percents,
      /* practices, */ loadCode,
      practices2,
    };

    const prac = currentPractice || {};

    // Update checks challenges and student code when loading

    const sectionPercentage =
      Math.round(100 / practice?.[practiceIndex]?.questions?.length) || 0;

    if (percents[prac.practiceId] === 100) {
      // if 100% section done
      state.index = practice[practiceIndex].questions.length;
      //state.index = ((percents[prac.practiceId] / 100) * prac.challenges?.length);
      state.loadCode = currentPractice?.codefiles;
    } else if (percents[prac.practiceId] === sectionPercentage) {
      // if 1 challenge of section is done
      state.index = 1;
    } else if (!percents[prac.practiceId]) {
      // if none of the challenges are done
      state.index = 0;
      // If 3 or 4 challenges and 2 done
    } else if (
      (practice[practiceIndex].questions.length === 3 &&
        percents[prac.practiceId] === 67) ||
      (practice[practiceIndex].questions.length === 4 &&
        percents[prac.practiceId] === 50)
    ) {
      state.loadCode = currentPractice?.codefiles;
      state.index = 2;
      // If 4 challenges and 3 done
    } else if (
      practice[practiceIndex].questions.length === 4 &&
      percents[prac.practiceId] === 75
    ) {
      state.loadCode = currentPractice?.codefiles;
      state.index = 3;
    }

    if (loadQuestion !== null && loadPractice !== null)
      state.contentTab = contentTab;
    else if (fileTab) state.contentTab = fileTab;
    else state.contentTab = state.loadCode || contentTab;

    if (this.challengeDiv?.current?.clientHeight)
      state.challengeHeight = this.challengeDiv.current.clientHeight;

    this.setState(state, () => {
      this.handleSubmit();
    });
    return percents;
  };

  unlockSteps() {
    const { batch, stackType, stepData } = this.props;
    const day = stepData.day;

    const currentDay = batch?.currentDay?.countDay;

    const endBatch = moment.utc().isAfter(moment.utc(batch?.date?.end));
    const afternoon = moment().isAfter(
      moment().startOf("day").add(12, "hours")
    );
    const afterSix = moment().isAfter(moment().startOf("day").add(18, "hours"));
    const isPast = day && currentDay > day.day;
    const isToday =
      (day && day.day === currentDay) ||
      (stackType?.keyMode === "workshop" &&
        moment(batch?.date?.start).date() >= moment().date());

    return {
      QCMUnlocked: (isToday && afternoon) || endBatch || isPast,
      flashcardsUnlocked:
        (isToday && afterSix) ||
        endBatch ||
        isPast ||
        batch?.stackType === "workshop",
      stepsUnlocked: isPast || endBatch || isToday,
    };
  }

  isSubmitting = false;

  prepareJS = (script, virtualPage, parser) => {
    let newScript = window.minify(
      "<script>" + script + "</script>",
      { collapseWhitespace: true, minifyCSS: true, minifyJS: true }
    );

    const virtualPageJS = parser.parseFromString(
      newScript,
      "text/html"
    );
    const externalJS = virtualPageJS.getElementsByTagName("script")[0];

    if (externalJS) virtualPage.body.appendChild(externalJS);

    const blob = new Blob(
      ["<!DOCTYPE html>" + virtualPage.documentElement.innerHTML],
      { type: "text/html" }
    );
    let contentDisplay = URL.createObjectURL(blob);
    this.setState({ contentDisplay, renderStatus: "" });
  }

  handleSubmit = (
    triggerByUser = false,
    codeinit = null,
    codeDisplay = null
  ) => {
    console.log({ isSubmitting: this.isSubmitting, time: new Date() })
    if (this.isSubmitting) return;

    this.isSubmitting = true;
    this.setState({ logAndErrorDisplay: [] });
    const {
      practiceLength,
      practiceIndex,
      contentTab,
      index,
      solutionDisplay,
    } = this.state;
    const { practice, batch, stepData, updateProgress } = this.props;
    if (practiceLength < practiceIndex) return false;

    let tddfiles = practice?.[practiceIndex]?.tddfiles;
    let tdd;
    var functionTDD;

    // if (!tddfiles) return false;

    let functionSplit = tddfiles?.split("#FUNCTION#");
    if (functionSplit?.length === 2) {
      functionTDD = functionSplit[0];
      tddfiles = functionSplit[1];
    }

    let tddSplit = tddfiles?.split("#PART#");

    // if (!tddSplit[index] && practice[practiceIndex].display !== 'terminal' && index < practice[practiceIndex].challenges.length) return false;

    tdd = tddSplit?.[index] || "";

    this.setState({ loading: true });
    let responseGroup = { html: "", css: "", script: "", scriptType: "" };
    let tabs = codeinit || contentTab;

    for (const e of tabs) {
      let keyName =
        e.filetype !== "html" && e.filetype !== "css" ? "script" : e.filetype;
      if (e?.code?.length > 0) {
        responseGroup[keyName] += e.code;
        if (keyName === "script") {
          responseGroup.scriptType = e.filetype;
        }
        //responseGroup.scriptType = "python";
      }
    }

    // Terminal display
    let display = codeDisplay || practice[practiceIndex].display;

    if (display === "terminal") {
      // Force HTML view to terminal display
      responseGroup.html = `
      <html>
      <head></head>
      <body style="background-color: rgb(26, 26, 26); font-family: 'Dark Mono', 'Fira Code', monospace; height: 100vh; color: #FFFFFF;"></body>
      </html>
      `;
    }

    try {
      // Minify HTML & CSS file
      if (responseGroup?.css?.length > 0)
        responseGroup.css = window.minify(
          "<style>" + responseGroup.css + "</style>",
          { collapseWhitespace: true, minifyCSS: true, minifyJS: true }
        );

      if (responseGroup?.html?.length)
        responseGroup.html = window.minify(responseGroup.html, {
          collapseWhitespace: true,
          minifyCSS: true,
          minifyJS: true,
        });
    } catch (e) {
      console.log(e);
    }

    // Virtual HTML Page
    const parser = new DOMParser();
    const virtualPage = parser.parseFromString(responseGroup.html, "text/html");

    // Add embed CSS
    let virtualPageCSS = parser.parseFromString(responseGroup.css, "text/html");
    let externalCSS = virtualPageCSS.getElementsByTagName("style")[0];

    if (externalCSS) virtualPage.head.appendChild(externalCSS);

    let tests = { esprima: true, tdd: true };
    const studentJSCode = responseGroup.script;

    const monWorker = new Worker();

    monWorker.postMessage({
      html: virtualPage.documentElement.innerHTML,
      code: responseGroup.script,
      tdd,
      functionTDD,
      scriptType: responseGroup.scriptType,
    });

    monWorker.onmessage = e => {
      // let testResult = true;
      // RESULTS OF TDD TESTING CONDITIONS
      if (process.env.REACT_APP_ENV !== "production") {
        console.log("onmessage ", e.data.error);
      }

      let nbErrors = 0;
      const nbTotalTests = e.data.result ? e.data.result.length : 1;
      if (e.data.error) {
        tests.tdd = false;
        nbErrors = 1;
      } else if (e.data && e.data.result) {
        for (const el of e.data.result) {
          if (el.status !== "pass") {
            tests.tdd = false;
            nbErrors++;
          }
        }
      }
      // console.log(e.data)
      //if (practice[practiceIndex]?.display === 'terminal') {
      // Override console.log to display screen

      let outPut = `
        var msg = '';
          for (var i = 0; i < arguments.length; i++) {
            let arg = arguments[i];

            if (typeof arg == 'object'){
              msg += JSON.stringify(arg)+' ';
            } else {
              msg += arg+' ';
            }
          }`;


      outPut +=
        practice[practiceIndex]?.display === "terminal"
          ? `
          let txt = document.createTextNode(msg);
          let div = document.createElement('div');
          if(type == 'error') {
            div.style.color = "red";
          } 
          div.appendChild(txt);
          document.body.appendChild(div);`
          : `window.top.postMessage({msg, type}, '*');`;

      const customConsole = `
            console.log = function() {
              let type = 'std';
              ${outPut}
            }
            console.error = function () {
              let type = 'error';
              ${outPut}
            }
            `;

      let codeToRun;
      if (responseGroup.scriptType === "python") {
        codeToRun = `
            ${skulpt}
            ${skulptStdlib}
            function outf(text) { 
                //var mypre = document.getElementById("output"); 
                //mypre.innerHTML = mypre.innerHTML + text; 
                console.log(text);
            } 
            
            function builtinRead(x) {
                if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined)
                        throw "File not found: '" + x + "'";
                return Sk.builtinFiles["files"][x];
            }
      
            Sk.pre = "output";
            Sk.configure({output:outf, read:builtinRead, __future__: Sk.python3}); 
            
            (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = 'mycanvas';
            var myPromise = Sk.misceval.asyncToPromise(function() {
                return Sk.importMainWithBody("<stdin>", false, \`${responseGroup.script}\`, true);
            });
            myPromise.then(function(mod) {
                
            },
              function(err) {
                console.log(err.toString(), 'error');
            })`;
      } else {
        codeToRun = responseGroup.script;
      }

      if (e.data.error) {
        responseGroup.script = `
            ${customConsole}
            console.error(\`${e.data.error.toString()}\`);
          `;
      } else {
        responseGroup.script = `
              ${customConsole}
                try {  
                  ${codeToRun}                 
                } catch(error){
                  console.error(error.message);
                }
            `;
      }
      //}

      monWorker.addEventListener("error", (event) => {
        let functionTDDtemp = functionTDD ? functionTDD : "";
        let functionTDDCount = functionTDDtemp.split(/\r\n|\r|\n/).length;
        let line = event.lineno - functionTDDCount;
        responseGroup.script = `
          ${customConsole}
          console.error(\`${event.message.toString()} (line ${line})\`);
          `;
        this.prepareJS(responseGroup.script, virtualPage, parser);
      });

      // const virtualPageJS = parser.parseFromString(
      //   responseGroup.script,
      //   "text/html"
      // );
      // const externalJS = virtualPageJS.getElementsByTagName("script")[0];

      // if (externalJS) virtualPage.body.appendChild(externalJS);


      // Disabled esprima : to delete 
      //RESULTS OF ESPRIMA TESTING CONDITIONS
      /*
      let solution =
        practice?.[practiceIndex]?.questions?.[index]?.solutionArray;
      if (
        solution?.length > 0 &&
        responseGroup?.script?.length > 0 &&
        responseGroup.script !== "<script></script>"
      ) {
        let esParsed = false;
        try {
          esParsed = esprima.parseScript(studentJSCode, {});
        } catch (error) {
          console.warn("Parsing failed");
        }
        if (esParsed && practice?.[practiceIndex]?.questions?.[index]) {
          tests.esprima = test(
            esParsed.body,
            practice[practiceIndex].questions[index].solutionArray
          );
        }
      }*/

      const errorMessages = [
        "Est-ce que tu t'es bien relu ?",
        "Prends le temps de relire la consigne.",
        "Try again. 🌀",
        "Hummm ... 🤨 T'es sur de toi ?",
        "Désolé ta solution n'est pas la bonne",
        "Non, non et non ! Essaye encore 👻"
      ]
      // console.log(tests)
      //CHECK IF ALL APPLIED TESTING CONDITIONS ARE FULFILLED
      let testResult = Object.values(tests).every(Boolean) ? true : false;

      if (triggerByUser && solutionDisplay === null) {
        if (!testResult) {
          if (nbErrors < nbTotalTests) {
            notification.warn({
              message: "Presque !",
              description:
                "Est-ce que tu as pensé à tous les cas de figure ? 🤔",
            });
          } else {
            notification.error({
              message: "Mauvaise réponse",
              description: errorMessages[Math.floor(Math.random() * errorMessages.length)],
              // icon: <span role="img" aria-label="wrong">❌</span>
            });
          }
        } else {
          notification.success({
            message: "Bravo ! Tu as réussi 🎉",
          });
          const { user, practice } = this.props;

          const contentTabs = contentTab?.map(e => ({
            code: e.code,
            name: e.name,
            filetype: e.filetype,
          }));
          let practices2 = null;

          let stepPercentage = 0;
          const percentage = Math.round(
            100 / (practice?.[practiceIndex]?.questions?.length / (index + 1))
          );
          let scoreResult = percentage === 100 ? 1 : 0;

          if (this.state.practices2) {
            for (let i = 0; i < this.state.practices2.length; i++) {
              stepPercentage =
                stepPercentage + this.state.practices2[i].percentage;
              if (this.state.practices2[i].percentage === 100) scoreResult++;
            }
            stepPercentage = stepPercentage + percentage;
            stepPercentage = Math.round(stepPercentage / practice.length);
          } else {
            stepPercentage = Math.round(percentage / practice.length);
          }

          if (stepPercentage >= 99) stepPercentage = 100;

          const body = {
            student: user._id,
            batch: batch._id,
            step: stepData.stepId,
            type: "exercise",
            stackDay: stepData.day,
            title: stepData.text,
            mainGroup: stepData.mainGroup,
            secondaryGroup: stepData.secondaryGroup,
            stepPercentage: stepPercentage,
            sectionTitle: practice[practiceIndex].title,
            questionCount: practice.length,
            scoreResult,
            date: moment().format(),
            percentage: percentage,
            index: practiceIndex,
            weightInPercentage: Math.round(
              100 / practice[practiceIndex].questions.length
            ),
            practiceId: practice[practiceIndex]?._id,
            questionId: practice[practiceIndex]?.questions[index]?._id,
            codefile: contentTab?.[0]?.code,
            codefiles: contentTabs,
            mainGroupSections: practice[practiceIndex].mainGroup
              ? practice[practiceIndex].mainGroup
              : stepData.mainGroup,
            secondaryGroupSections: practice[practiceIndex].secondaryGroup
              ? practice[practiceIndex].secondaryGroup
              : stepData.secondaryGroup,
          }; // Data sent to backend

          if (body.percentage <= 100) {
            fetch(
              `${global.URI_BACKEND}/stepprogression/exercises/${user._id}`,
              {
                method: "put",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
              }
            )
              .then(res => res.json())
              .then(data => {
                if (data.progression !== null) {
                  for (const d of data.progression) {
                    if (d.step === stepData.stepId) {
                      practices2 = d.sections;
                    }
                  }

                  let percents = {};
                  for (const e of practices2) {
                    if (!percents[e._id]) percents[e.practiceId] = 0;

                    percents[e.practiceId] = Math.round(e.percentage);
                  }
                  this.setState({ practices2 }, () => this.nextStep(percents));
                } else {
                  this.nextStep();
                }
              })
              .then(() => updateProgress());
          }
        }
      }

      this.setState({ loading: false }, () => {
        // const blob = new Blob(
        //   ["<!DOCTYPE html>" + virtualPage.documentElement.innerHTML],
        //   { type: "text/html" }
        // );

        // const contentDisplay = URL.createObjectURL(blob);

        this.isSubmitting = false;
        //this.setState({ contentDisplay, renderStatus: "" });
        this.prepareJS(responseGroup.script, virtualPage, parser);
      });
    };
  };

  onChange = (value, index) => {
    let { contentTab } = this.state;
    clearInterval(this.timeout);
    this.buttonDisabled = true;

    contentTab[index].code = value;
    this.timeout = setTimeout(() => this.updateState({ contentTab }), 300);
  };

  onFullScreenClick = () => {
    const { fullscreen, width } = this.state;
    let display = this.defaultDisplay;

    if (!fullscreen)
      display = {
        projSpan: 0,
        editContOffset: 0,
        editContSpan: 24,
        editSpan: 12,
        viewSpan: 12,
      };

    this.setState({ fullscreen: !fullscreen, display, width: width + 1 });
  };

  //EXERCISE NAVIGATION___________________________
  selectPractice = current => {
    const { practices2 } = this.state;
    const { practice } = this.props;

    const loadCode = practices2?.find(
      p => p.practiceId === practice[current]._id
    )?.codefiles;

    const contentTab =
      loadCode ||
      practice[current]?.codefiles?.map(e => ({
        name: e.name,
        code: e.code || loadCode || "",
        filetype: e.filetype,
      }));

    this.setState(
      {
        practiceIndex: current,
        contentTab,
        index: 0,
        loadCode,
        solutionDisplay: null,
      },
      () => {
        this.handleSubmit();
      }
    );
  };

  selectStep = index => {
    const { practice } = this.props;
    const { practiceIndex, practices2 } = this.state;
    let { contentTab } = this.state;

    const loadCode = practices2?.find(
      p => p.practiceId === practice[practiceIndex]._id
    )?.codefiles;

    contentTab = loadCode ?? contentTab;

    this.setState({ index, loadCode, contentTab }, () => { });
  };

  nextStep = percents => {
    const { practice } = this.props;
    const { index, practiceIndex, practices2, solutionDisplay } = this.state;
    let { contentTab } = this.state;

    if (index < practice[practiceIndex].questions.length) {
      const loadCode = practices2?.find(
        p => p.practiceId === practice[practiceIndex]._id
      )?.codefiles;

      contentTab = loadCode ?? contentTab;
      let state = { index: index + 1, loadCode, contentTab, percents };
      if (solutionDisplay !== null) state.solutionDisplay = solutionDisplay + 1;
      this.setState(state, () => { });
    }

    let success;
    let progression;
    let scoreInPercent;
    let questionTotal = 0;
    let questionFinished = 0;
    let courses = this.props.practice;

    for (let i in courses) {
      questionTotal += courses[i].questions.length;
      if (i === practiceIndex) {
        questionFinished += index;
      } else if (i < practiceIndex) {
        questionFinished += courses[i].questions.length;
      }
    }

    success = questionFinished === questionTotal ? "finished" : "in progress";
    progression = Math.ceil((questionFinished / questionTotal) * 100);
    scoreInPercent = progression;
  };

  nextChallenge = () => {
    const { practiceIndex, practiceLength, percents } = this.state;
    const { practice } = this.props;

    if (practiceIndex < practiceLength) {
      if (percents[practice[practiceIndex + 1]._id]) {
        this.setState(
          {
            contentTab: practice[practiceIndex + 1]?.codefiles?.map(e => ({
              name: e.name,
              code: e.code,
              filetype: e.filetype,
            })),
            index: 0,
            practiceIndex: practiceIndex + 1,
            solutionDisplay: null,
          },
          () => {
            this.challengeScroll.current.getScrollElement().scrollTop = 0;
            this.handleSubmit();
          }
        );
      } else {
        const loadCode = this.state.practices2?.find(
          p => p.practiceId === practice[practiceIndex + 1]._id
        )?.codefiles;
        this.setState(
          {
            contentTab: practice[practiceIndex + 1]?.codefiles?.map(e => ({
              name: e.name,
              code: loadCode || "",
              filetype: e.filetype,
            })),
            index: 0,
            practiceIndex: practiceIndex + 1,
            loadCode,
            solutionDisplay: null,
            contentDisplay: null,
          },
          () => {
            this.challengeScroll.current.getScrollElement().scrollTop = 0;
            this.handleSubmit();
          }
        );
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  toggleEditorMode = (practiceIndex, questionIndex, status) => {
    const { practice, id } = this.props;

    let jsCode = practice?.[practiceIndex]?.questions?.[questionIndex]?.rawCode;
    let hasScript = practice?.[practiceIndex]?.codefiles?.some(
      e => e.filetype === "javascript" || e.filetype === "python"
    );

    if (status !== "solution") {
      if (status === "canUpdate") {
        this.setState({ confirmModalDisplay: true });
      } else {
        this.setState({ confirmModalDisplayRestricted: true });
      }
    }

    let tddfiles = practice?.[practiceIndex]?.tddfiles || "";

    let functionSplit = tddfiles.split("#FUNCTION#");
    if (functionSplit.length === 2) {
      tddfiles = functionSplit[1];
    }

    let tddSplit = tddfiles.split("#PART#")[questionIndex];

    let questionHasSolution = practice?.[practiceIndex]?.questions?.[
      questionIndex
    ]?.solution?.find(
      e => e.filetype === "javascript" || e.filetype === "python"
    )?.code;

    let modalTab = "tdd";
    let state = {
      correctionStep: questionIndex,
      correctionPart: practice?.[practiceIndex]?.title,
      correctionExercise: "",
      correctionCode: jsCode || "",
      tddCorrection: tddSplit || "",
      correctionPracticeId: id,
      correctionCourseId: practice?.[practiceIndex]?._id,
      correctionQuestionId:
        practice?.[practiceIndex]?.questions?.[questionIndex]?._id,
      oldCode: jsCode ? true : false,
      questionHasSolution,
      editing: true,
      hasScript,
      modalTab,
    };

    this.setState(state);
  };

  onSolutionEdit = val => {
    this.setState({ correctionCode: val });
  };

  //VALIDATION DE TESTS TDD CONTRE SOLUTION ENREGRISTRÉE
  confirmNewTdd = async (submit = true) => {
    const {
      tddCorrection,
      correctionCourseId,
      correctionQuestionId,
      correctionPracticeId,
      correctionStep,
      practiceIndex,
    } = this.state;
    const { practice, updatePractice } = this.props;

    try {
      let tdd = tddCorrection;
      let tddfiles = practice?.[practiceIndex]?.tddfiles;
      let contentTab =
        practice?.[practiceIndex]?.questions?.[correctionStep]?.solution;
      // console.log(tddfiles)
      let functionTDD;

      let functionSplit = tddfiles.split("#FUNCTION#");
      if (functionSplit.length === 2) {
        functionTDD = functionSplit[0];
        tddfiles = functionSplit[1];
      }

      let tddSplit = tddfiles.split("#PART#");

      tddSplit[correctionStep] = tdd;
      tddfiles = tddSplit.join("#PART#");
      if (functionTDD) tddfiles = `${functionTDD}#FUNCTION#${tddfiles}`;

      let responseGroup = { html: "", css: "", script: "", scriptType: "" };

      for (const e of contentTab) {
        let keyName =
          e.filetype != "html" && e.filetype != "css" ? "script" : e.filetype;
        if (e?.code?.length > 0) {
          responseGroup[keyName] += e.code;
          if (keyName == "script") {
            responseGroup.scriptType = e.filetype;
          }
        }
      }

      // Terminal display
      if (practice[practiceIndex].display === "terminal") {
        // Force HTML view to terminal display
        responseGroup.html = `
        <html>
        <head></head>
        <body style="background-color: rgb(26, 26, 26); font-family: 'Dark Mono', 'Fira Code', monospace; height: 100vh; color: #FFFFFF;"></body>
        </html>
        `;
      }

      // Minify HTML & CSS file
      if (responseGroup?.css?.length > 0)
        responseGroup.css = window.minify(
          "<style>" + responseGroup.css + "</style>",
          { collapseWhitespace: true, minifyCSS: true, minifyJS: true }
        );

      if (responseGroup?.html?.length)
        responseGroup.html = window.minify(responseGroup.html, {
          collapseWhitespace: true,
          minifyCSS: true,
          minifyJS: true,
        });

      // Virtual HTML Page
      const parser = new DOMParser();
      const virtualPage = parser.parseFromString(
        responseGroup.html,
        "text/html"
      );

      // Add embed CSS
      let virtualPageCSS = parser.parseFromString(
        responseGroup.css,
        "text/html"
      );
      let externalCSS = virtualPageCSS.getElementsByTagName("style")[0];

      if (externalCSS) virtualPage.head.appendChild(externalCSS);

      const monWorker = new Worker();

      monWorker.postMessage({
        html: virtualPage.documentElement.innerHTML,
        code: responseGroup.script,
        tdd,
        functionTDD,
        scriptType: responseGroup.scriptType,
      });
      monWorker.onmessage = async e => {
        let testResult = true;

        if (e.data.error) {
          testResult = false;
        } else {
          if (e.data && e.data.result) {
            for (const el of e.data.result) {
              if (el.status !== "pass") testResult = false;
            }
          } else {
            testResult = false;
          }
        }
        // console.log(e.data)
        if (!testResult)
          this.setState({
            tddParse: false,
            resultTDD:
              "Une erreur est survenue lors du parsing du code. Vérifiez les tests et la solution.",
          });
        else {
          if (submit) {
            const body = {
              practiceId: correctionPracticeId,
              courseId: correctionCourseId,
              questionId: correctionQuestionId,
              tddfiles,
            };
            if (body.questionId) {
              const response = await fetch(
                `${global.URI_BACKEND}/practice/edit/tddfiles`,
                {
                  method: "put",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(body),
                }
              );

              const data = await response.json();

              if (data.success) {
                updatePractice(data.practice);
                this.onLoadAndUpdate(practiceIndex, correctionStep);

                notification.success({
                  message: "Modification effectuée",
                  description:
                    "La modification de la correction a bien été effectuée !",
                });
                this.setState({ tddCorrection: "" });
                this.removeCorrectionStates();
              }
            } else {
              this.setState({
                tddParse: false,
                resultTDD:
                  "Une erreur est survenue lors de l'enregistrement en base de données.",
              });
            }
          } else {
            this.setState({
              tddParse: true,
              resultTDD: "Le parsing du code a été effectué avec succès.",
            });
          }
        }
      };
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Erreur d'enregistrement",
        description:
          "Une erreur est survenue lors de l'enregistrement du code en base de données.",
      });
    }
  };

  //VALIDATION DE TEST ESPRIMA CONTRE SOLUTION ENREGRISTRÉE
  runESParse = () => {
    const { practiceIndex, correctionStep, correctionCode } = this.state;
    const { practice } = this.props;
    let testResult = false;
    let resultESParse = "";

    let solution =
      practice[practiceIndex]?.questions?.[correctionStep]?.solution;
    let esParsed = false;
    let solutionParsed = false;

    if (solution) {
      let solutionCode = solution?.find(
        e => e.filetype === "javascript" || e.filetype === "python"
      )?.code;

      if (solutionCode) {
        try {
          solutionParsed = esprima.parseScript(solutionCode, {});
          esParsed = esprima.parseScript(correctionCode, {});
        } catch (error) {
          console.warn("Parsing failed");
        }
      }

      // console.log(solutionParsed.body, esParsed.body)
      if (esParsed && solutionParsed) {
        testResult = test(solutionParsed.body, esParsed.body);
      }
      if (testResult) {
        resultESParse = "Le test a bien été éffectué.";
      } else if (!testResult || !esParsed || !solutionParsed) {
        resultESParse = "Une erreur est survenue lors du parsing du code.";
      }
    } else {
      resultESParse =
        "Aucune solution n'est enregistré pour cette étape. Le test n'a pas pu être validé.";
    }

    this.setState({ resultESParse, esParse: testResult });
    return { testResult, esParsed };
  };

  confirmCorrection = async () => {
    const {
      correctionCode,
      correctionPracticeId,
      correctionCourseId,
      correctionQuestionId,
      practiceIndex,
      correctionStep,
    } = this.state;
    const { updatePractice } = this.props;
    try {
      let { testResult, esParsed } = this.runESParse();

      if (testResult) {
        const body = {
          practiceId: correctionPracticeId,
          courseId: correctionCourseId,
          questionId: correctionQuestionId,
          solutionArray: esParsed?.body,
          rawCode: correctionCode,
        };

        if (Object.values(body).every(e => !!e)) {
          const response = await fetch(
            `${global.URI_BACKEND}/practice/edit/esprima`,
            {
              method: "put",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(body),
            }
          );

          const data = await response.json();

          if (data.success) {
            updatePractice(data.practice);
            this.onLoadAndUpdate(practiceIndex, correctionStep);

            notification.success({
              message: "Modification effectuée",
              description:
                "La modification de la correction a bien été effectuée !",
            });
            this.removeCorrectionStates();
          }
        } else {
          this.setState({
            esParse: false,
            resultESParse:
              "Une erreur est survenue lors de l'enregistrement en base de données",
          });
        }
      }
    } catch (error) {
      this.setState({
        esParse: false,
        resultESParse: "Une erreur est survenue lors du parsing du code",
      });
    }
  };

  confirmCorrectionCancel = () => {
    this.removeCorrectionStates();
  };

  toggleSolutionModal = (index, state) => {
    const { solutionDisplay } = this.state;
    if (index !== undefined) {
      if (state) {
        this.toggleSolutionDisplay(index, false);
      } else {
        this.setState({ solutionModal: true, solutionIndexDisplay: index });
      }
    } else {
      if (solutionDisplay !== null)
        this.toggleSolutionDisplay(solutionDisplay, true);
      this.setState({ solutionModal: false, solutionIndexDisplay: index });
    }
  };

  closeSolutionModal = () => {
    this.setState({ modalTab: "tdd", confirmModalDisplay: false });
  };

  removeCorrectionStates = () => {
    this.setState({
      oldCode: false,
      confirmModalDisplay: false,
      correctionStep: null,
      correctionExercise: null,
      correctionCode: null,
      correctionPracticeId: null,
      correctionCourseId: null,
      correctionQuestionId: null,
      tddCorrection: "",
      editing: false,
      modalTab: "tdd",
      esParse: false,
      resultESParse: "",
      tddParse: false,
      resultTDD: "",
      hasScript: false,
      readOnly: false,
    });
  };

  toggleModalTab = modalTab => {
    this.setState({ modalTab });
  };

  //AFFICHAGE DE SOLUTION D'EXO SUR L'INTERFACE
  toggleSolutionDisplay = (index, permission) => {
    const { practiceIndex, solutionDisplay, loadCode, solutionModal } =
      this.state;
    const { practice } = this.props;

    if (permission) {
      this.toggleEditorMode(practiceIndex, index, "solution");
      this.setState({ editMode: true, readOnly: false });
    } else this.setState({ readOnly: true });

    let question = practice[practiceIndex]?.questions[index];
    let solution = question?.solution;
    let codefiles = practice[practiceIndex]?.codefiles;

    if (solutionDisplay !== index)
      this.setState({
        contentTab: solution?.length ? solution : codefiles,
        solutionDisplay: index,
        codeEdit: false,
      });
    else {
      this.setState({
        contentTab: loadCode || codefiles,
        solutionDisplay: null,
      });
      this.removeCorrectionStates();
    }
    if (solutionModal) {
      this.setState({ solutionModal: false });
    }
  };

  //MODIFICATION DE SOLUTION GENERALE DE L'EXO
  submitSolution = async () => {
    const { contentTab, practiceIndex, correctionStep, correctionQuestionId } =
      this.state;
    const { practice, id, updatePractice } = this.props;

    const body = {
      practiceId: id,
      courseId: practice?.[practiceIndex]?._id,
      questionId: correctionQuestionId,
      solution: contentTab,
    };

    if (Object.values(body).every(e => !!e)) {
      this.handleSubmit(false);
      const data = await fetch(`${global.URI_BACKEND}/practice/edit/solution`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const json = await data.json();
      if (json.success) {
        updatePractice(json.practice);

        this.onLoadAndUpdate(practiceIndex, correctionStep);
        notification.success({
          message: "Modification effectuée",
          description:
            "La modification de la correction a bien été effectuée !",
        });
      } else {
        notification.error({
          message: "Erreur d'enregistrement",
          description:
            "Une erreur est survenue lors de l'enregistrement en base de données",
        });
      }
    } else {
      notification.error({
        message: "Erreur d'enregistrement",
        description:
          "Une erreur est survenue lors de l'enregistrement en base de données",
      });
    }
  };

  //IMPORT DE SOLUTION POUR CRÉER UN NOUVEAU PARSE ESPRIMA
  importSolution = () => {
    const { practiceIndex, correctionStep } = this.state;
    const { practice } = this.props;
    let question = practice?.[practiceIndex]?.questions?.[correctionStep];
    let correctionCode = question?.solution?.find(
      e => e.filetype === "javascript" || e.filetype === "python"
    )?.code;
    if (correctionCode) this.setState({ correctionCode });
    else
      this.setState({
        esParse: false,
        resultESParse: "Aucune solution trouvée.",
      });
  };

  onTDDEdit = (val, step, index) => {
    const { practice } = this.props;

    let tddfiles = practice[index]?.tddfiles || "";

    let bodyTDD = "";
    let functionSplit = tddfiles.split("#FUNCTION#");
    if (functionSplit.length === 2) {
      bodyTDD = functionSplit[1];
    } else {
      bodyTDD = tddfiles;
    }

    bodyTDD = bodyTDD.split("#PART#");

    if (!bodyTDD)
      bodyTDD = Array.from({ length: step + 1 }, (e, i) =>
        i === step ? val : ""
      );
    else if (bodyTDD.length > step) bodyTDD[step] = val;
    else
      notification.error({
        message: "Erreur d'enregistrement",
        description:
          "Une erreur est survenue lors du parsing du code ou de son enregistrement en base de données",
      });

    this.setState({ tddCorrection: val });
  };

  //MODIFICATION DU CODE INITIAL DANS L'INTERFACE
  toggleCodeInitEdit = bool => {
    const { practiceIndex, loadCode } = this.state;
    const { practice } = this.props;

    // this.toggleEditorMode(practiceIndex, "solution")
    this.setState({ readOnly: false });

    let codefiles = practice[practiceIndex]?.codefiles;

    if (bool) {
      this.setState({
        contentTab: codefiles || [],
        codeEdit: true,
        solutionDisplay: null,
      });
    } else {
      this.setState({ contentTab: loadCode || codefiles, codeEdit: false });
      // this.removeCorrectionStates()
    }
  };
  handleEditCodeinit = async () => {
    const { contentTab, practiceIndex } = this.state;
    const { id, practice, updatePractice } = this.props;

    const body = {
      courseId: practice[practiceIndex]._id,
      practiceId: id,
      codefiles: contentTab,
    };

    const data = await fetch(`${global.URI_BACKEND}/practice/edit/codefiles`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    const json = await data.json();

    if (json.success) {
      updatePractice(json.practice);
      this.onLoadAndUpdate(practiceIndex);

      notification.success({
        message: "Modification effectuée",
        description: "La modification du code a bien été effectuée !",
      });
    } else {
      notification.error({
        message: "Erreur d'enregistrement",
        description:
          "Une erreur est survenue lors de l'enregistrement en base de données",
      });
    }
  };

  //AJOUT DE NOUVEL ONGLET
  newCodeFile = (value, name) => {
    const { newTab } = this.state;
    let tab = newTab || {};

    tab[name.trim()] = value.trim();
    this.setState({ newTab: tab });
  };

  addCodeFile = async () => {
    const { contentTab, newTab, practiceIndex } = this.state;
    const { id, practice, updatePractice } = this.props;

    if (contentTab.findIndex(e => e.name === newTab.name) > -1) {
      notification.error({
        message: "Erreur",
        description: "Un fichier à ce nom existe déjà",
      });
      return;
    }

    if (newTab?.filetype && newTab?.name) {
      let addTab = { ...newTab, code: "" };

      if (newTab.filetype === "html")
        addTab.code = `<!DOCTYPE html>\n<html>\n\t<head>\n\t</head>\n\t<body>\n\t</body>\n</html>`;
      let codefiles = [...contentTab, addTab];
      const body = {
        courseId: practice[practiceIndex]._id,
        practiceId: id,
        codefiles,
      };

      const data = await fetch(
        `${global.URI_BACKEND}/practice/edit/codefiles`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      const json = await data.json();

      if (json.success) {
        updatePractice(json.practice);
        this.onLoadAndUpdate(practiceIndex, null, codefiles);
        this.setState({ addTab: null });
        notification.success({
          message: "Modification effectuée",
          description: "La modification du code a bien été effectuée !",
        });
      } else {
        notification.error({
          message: "Erreur d'enregistrement",
          description:
            "Une erreur est survenue lors de l'enregistrement en base de données",
        });
      }
    }
  };

  //CHANGE DISPLAY SETTING
  handleNewDisplay = async val => {
    const { practiceIndex } = this.state;
    const { id, practice, updatePractice } = this.props;

    const body = {
      courseId: practice[practiceIndex]._id,
      practiceId: id,
      display: val,
    };

    const data = await fetch(`${global.URI_BACKEND}/practice/edit/display`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    const json = await data.json();

    if (json.success) {
      updatePractice(json.practice);
      this.onLoadAndUpdate(
        practiceIndex,
        null,
        practice?.[practiceIndex]?.codefiles
      );
      this.handleSubmit(false, json.practice?.[practiceIndex]?.codefiles, val);
      notification.success({
        message: "Modification effectuée",
        description: "La modification du code a bien été effectuée !",
      });
    } else {
      notification.error({
        message: "Erreur d'enregistrement",
        description:
          "Une erreur est survenue lors de l'enregistrement en base de données",
      });
    }
  };

  render() {
    const {
      addTabButton,
      questionCol,
      codeInitButton,
      sPractice,
      hInherit,
      editorTabBar,
      editorContainer,
      tabPaneBrowser,
      editor,
      prevContainer,
      preview,
      fsButton,
      fsIcon,
      runButton,
    } = styles;
    const {
      codeEdit,
      hasScript,
      esParse,
      tddParse,
      resultESParse,
      resultTDD,
      practiceIndex,
      width,
      index,
      redirect,
      fullscreen,
      display: { projSpan, editContOffset, editContSpan, editSpan, viewSpan },
      loading,
      challengeHeight,
      contentTab,
      contentDisplay,
      percents,
      correctionStep,
      correctionPart,
      confirmModalDisplay,
      modalTab,
      tddCorrection,
      correctionCode,
      readOnly,
      solutionDisplay,
      editing,
      solutionModal,
      solutionIndexDisplay,
      logAndErrorDisplay,
    } = this.state;
    const { practice, user, title, stackType, t, next } = this.props;

    const hasPermissions = checkRank(user, {
      allowedRanks: ["superadmin", "admin", "manager", "teacher", "assistant"],
    });
    const hasSuperPermissions = checkRank(user, {
      allowedRanks: ["superadmin", "admin", "teacher"],
    });

    if (redirect === true) {
      if (!next) return <Redirect to={`/home/today`} />;
      else return <Redirect to={`/batch/${next?.stepType}/${next?.stepId}`} />;
    }

    let solution;
    if (editing) {
      solution = practice[practiceIndex]?.questions?.[correctionStep]?.solution;
    } else {
      solution = practice[practiceIndex]?.questions?.[index]?.solution;
    }

    let currentQuestion;

    const steps = practice.map((e, iPractice) => {
      const questions = e.questions.map((ec, iQuestion) => {
        const { solutionIndexDisplay, solutionDisplay } = this.state;
        const disable = iPractice <= practiceIndex && iQuestion <= index;
        const active =
          solutionIndexDisplay === solutionDisplay &&
          solutionIndexDisplay === iQuestion;
        const textColor =
          iPractice <= practiceIndex && iQuestion <= index
            ? "#000000a6"
            : "#00000054";
        const pictoColor =
          iPractice <= practiceIndex && iQuestion < index
            ? "#2ecc71"
            : textColor;
        const opacity =
          iPractice <= practiceIndex && iQuestion <= index ? 1 : 0.4;

        return (
          <div
            key={iQuestion}
            style={{ display: "flex", maxWidth: "100%", height: "fit-content" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {hasSuperPermissions && (
                <Tooltip
                  placement="topLeft"
                  title={t("Editer test", { count: iQuestion + 1 })}
                >
                  <Button
                    type="primary"
                    ghost={true}
                    size="small"
                    onClick={() =>
                      this.toggleEditorMode(iPractice, iQuestion, "canUpdate")
                    }
                  >
                    <Icon type="edit" />
                  </Button>
                </Tooltip>
              )}

              {hasPermissions && (
                <Tooltip
                  placement="bottomLeft"
                  title={
                    hasSuperPermissions
                      ? t("Editer solution", { count: iQuestion + 1 })
                      : t("Voir correction", { count: iQuestion + 1 })
                  }
                >
                  <Switch
                    checked={solutionDisplay === iQuestion}
                    onChange={() =>
                      this.toggleSolutionDisplay(
                        iQuestion,
                        hasSuperPermissions ? true : false
                      )
                    }
                    size="small"
                    style={{ marginTop: 5 }}
                  />
                  {/* <Button type="primary" ghost={ solutionDisplay === iQuestion ? false : true } style={{ marginTop: 5 }} size="small" onClick={ () => this.toggleSolutionDisplay(iQuestion, hasSuperPermissions ? true : false )}><Icon type="code"/></Button> */}
                </Tooltip>
              )}

              {user.type === "student" &&
                disable &&
                !stackType.keyStack.includes("katas-night") && (
                  <Tooltip
                    placement="bottomLeft"
                    title={
                      active
                        ? t("Effacer solution", { count: iQuestion + 1 })
                        : t("Afficher solution", { count: iQuestion + 1 })
                    }
                  >
                    <Button
                      style={{ color: "#F94A56", padding: "0px" }}
                      onClick={() =>
                        this.toggleSolutionModal(iQuestion, active)
                      }
                      type="link"
                    >
                      <Icon
                        type="bulb"
                        theme={active ? "filled" : "outlined"}
                      />
                    </Button>
                  </Tooltip>
                )}
            </div>
            <div style={{ opacity: opacity }}>
              <table className="noBorder">
                <tbody>
                  <tr>
                    <Icon
                      type="check-circle"
                      style={{
                        color: pictoColor,
                        float: "left",
                        marginLeft: 10,
                        marginRight: 5,
                        marginTop: 8,
                      }}
                    />
                    <p style={{ margin: "0px 20px 0px 0px" }}>
                      {ec.instruction}
                    </p>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      });

      if (iPractice === practiceIndex) {
        const disabled = index < practice[practiceIndex].questions.length;

        currentQuestion = (
          <div
            ref={this.challengeDiv}
            style={{ flex: 1, margin: "20px 0", width: "100%" }}
          >
            <SimpleBar
              forceVisible
              id="practice"
              ref={this.challengeScroll}
              style={{ height: "100%", padding: "0 20px" }}
            >
              <div>
                <table className="noBorder">
                  <tbody>
                    <tr>
                      <td>{e.course}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <h2>{t("Exercices")}</h2>
              </div>
              <div>{questions}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 30,
                  marginTop: 20,
                }}
              >
                <Button
                  type="primary"
                  ghost={true}
                  disabled={disabled}
                  onClick={() => this.nextChallenge()}
                >
                  {t("Suivant")}
                </Button>
              </div>
            </SimpleBar>
          </div>
        );
      }

      const status =
        iPractice === practiceIndex
          ? "process"
          : percents[e._id] === 100
            ? "finish"
            : "wait";

      return (
        <Step
          key={iPractice}
          title={e.title}
          status={status}
        // description={`${percents[e._id] ?? 0}%`}
        />
      );
    });

    let editorList = contentTab || [];
    const aceEditorList = editorList.map((item, i) => {
      return (
        <TabPane tab={item?.name || "Code"} key={i}>
          <div className="vertical" style={{ marginTop: 5, flex: 1 }}>
            <AceEditor
              showPrintMargin={false}
              style={editor}
              mode={item?.filetype || "javascript"}
              onChange={val => this.onChange(val, i)}
              name={`aceEditor-${i}`}
              value={item?.code || ""}
              editorProps={{ $blockScrolling: false }}
              theme="tomorrow_night_bright"
              setOptions={{
                useWorker: false,
                fontFamily: '"Dank Mono", "Fira Code", monospace',
                fontSize: 14.5,
              }}
              wrapEnabled={true}
              width={width}
              readOnly={readOnly}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            {hasSuperPermissions && (
              <>
                {solutionDisplay !== null && (
                  <Button
                    loading={loading}
                    onClick={() => this.submitSolution()}
                    style={{ ...runButton, width: 100 }}
                    type="primary"
                    size="small"
                    className="run-button"
                  >
                    {t("Enregistrer")}
                  </Button>
                )}
                {codeEdit && (
                  <Button
                    loading={loading}
                    onClick={() => this.handleEditCodeinit()}
                    style={{ ...runButton, width: 100 }}
                    type="primary"
                    size="small"
                    className="run-button"
                  >
                    {t("Enregistrer")}
                  </Button>
                )}
              </>
            )}
            <Button
              loading={loading}
              onClick={() =>
                this.handleSubmit(solutionDisplay || codeEdit ? false : true)
              }
              style={runButton}
              type="primary"
              size="small"
              className="run-button"
              title={t("CTRL + ENTRÉE")}
            >
              Run
            </Button>
          </div>
        </TabPane>
      );
    });

    let styleConsoleResult, styleBrowserResult;
    //let titlePreview;
    let display = practice[practiceIndex]?.display;
    // if (!display) display = 'terminal'

    //if (display === 'terminal') {
    styleConsoleResult = {
      ...preview,
      backgroundColor: "#282C34",
      color: "#FFFFFF",
      fontFamily: '"Dank Mono", "Fira Code", monospace',
    };
    //  titlePreview = 'Console';
    //} else {
    styleBrowserResult = {
      ...preview,
      backgroundColor: "white",
      color: "initial",
    };
    //  titlePreview = 'Browser';
    //}

    return (
      <>
        {practice.length > 1 && (
          <Row style={{ padding: "0px 65px" }}>
            <Col>
              <Steps
                current={practiceIndex}
                size="small"
                onChange={this.selectPractice}
                type="navigation"
              >
                {steps}
              </Steps>
            </Col>
          </Row>
        )}
        <Row
          style={{
            ...sPractice,
            height: `calc(100vh - ${practice.length > 1 ? 158 : 110}px)`,
          }}
          type="flex"
        >
          <Col span={projSpan} style={questionCol}>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                border: "1px solid #f0f2f5",
                borderRadius: 4,
                display: "flex",
                position: "relative",
                paddingTop: hasSuperPermissions ? 30 : 5,
              }}
            >
              {hasSuperPermissions && (
                <div style={codeInitButton}>
                  <Tooltip
                    title={t(`Editer le code d'initalisation`)}
                    placement="topLeft"
                  >
                    <Switch
                      size="small"
                      checked={codeEdit}
                      onChange={bool => this.toggleCodeInitEdit(bool)}
                    />
                  </Tooltip>
                </div>
              )}
              {currentQuestion}
            </div>
          </Col>
          <Col offset={editContOffset} span={editContSpan}>
            <Row id="code-area" style={hInherit}>
              <Col
                span={editSpan}
                style={{
                  flex: 1,
                  display: "flex",
                  backgroundColor: "rgb(26, 26, 26)",
                  position: "relative",
                }}
              >
                <div style={{ position: "absolute", right: 5, top: 7 }}>
                  {codeEdit && (
                    <Popover
                      id="add-file"
                      trigger="click"
                      placement="bottomRight"
                      title={t("Ajouter un fichier")}
                      content={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Input
                            style={{ width: "100%" }}
                            placeholder="Nom du fichier"
                            name="name"
                            onChange={e =>
                              this.newCodeFile(e.target.value, e.target.name)
                            }
                          />
                          <Input
                            style={{ width: "100%", margin: "8px 0px" }}
                            placeholder="Type de fichier"
                            name="filetype"
                            onChange={e =>
                              this.newCodeFile(e.target.value, e.target.name)
                            }
                          />
                          <Button
                            type="primary"
                            size="small"
                            style={{ width: 60, alignSelf: "flex-end" }}
                            ghost
                            onClick={this.addCodeFile}
                          >
                            {t("Ajouter")}
                          </Button>
                        </div>
                      }
                    >
                      <span
                        style={{ ...addTabButton, marginRight: 5 }}
                        type="link"
                      >
                        <Icon style={fsIcon} type="plus" />
                      </span>
                    </Popover>
                  )}
                  {aceEditorList.length > 0 && (
                    <Tooltip title={fullscreen ? t("Réduire") : t("Agrandir")}>
                      <span
                        onClick={this.onFullScreenClick}
                        style={addTabButton}
                        type="link"
                      >
                        <Icon
                          style={fsIcon}
                          type={fullscreen ? "fullscreen-exit" : "fullscreen"}
                        />
                      </span>
                    </Tooltip>
                  )}
                </div>

                <Tabs tabBarStyle={editorTabBar} style={editorContainer}>
                  {aceEditorList}
                </Tabs>
              </Col>

              <Col id="code-preview" span={viewSpan} style={prevContainer}>
                {codeEdit && (
                  <Popover
                    title={t("Sélectionner un affichage")}
                    trigger="click"
                    placement="bottomRight"
                    content={
                      <Select
                        onChange={this.handleNewDisplay}
                        style={{ width: "100%" }}
                        placeholder={t("Sélectionner un affichage")}
                      >
                        {this.displays.map(e => (
                          <Option value={e.value}>{e.label}</Option>
                        ))}
                      </Select>
                    }
                  >
                    <span style={fsButton} type="link">
                      <Icon
                        style={{ ...fsIcon, position: "relative", right: 5 }}
                        type="plus"
                      />
                    </span>
                  </Popover>
                )}
                {display === "terminal" ? (
                  <Tabs tabBarStyle={editorTabBar} style={editorContainer}>
                    <TabPane tab="Console" key="console" style={tabPaneBrowser}>
                      <iframe
                        className="viewer"
                        title="preview"
                        style={styleConsoleResult}
                        src={contentDisplay}
                      />
                    </TabPane>
                  </Tabs>
                ) : (
                  <Tabs tabBarStyle={editorTabBar} style={editorContainer}>
                    <TabPane tab="Browser" key="browser" style={tabPaneBrowser}>
                      <iframe
                        className="viewer"
                        title="preview"
                        style={styleBrowserResult}
                        src={contentDisplay}
                      />
                    </TabPane>
                    <TabPane tab="Console" key="console" style={tabPaneBrowser}>
                      <div
                        className="viewer"
                        title="preview"
                        style={{
                          ...styleConsoleResult,
                          padding: "8px",
                        }}
                      >
                        {logAndErrorDisplay.map(elmt => {
                          if (elmt.type == "error") {
                            return (
                              <div style={{ color: "red" }}>{elmt.msg}</div>
                            );
                          } else {
                            return <div>{elmt.msg}</div>;
                          }
                        })}
                      </div>
                    </TabPane>
                  </Tabs>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          width="60vw"
          style={{ width: "60vw", maxWidth: "900px" }}
          title={t("Modification de correction")}
          visible={confirmModalDisplay}
          onOk={
            modalTab === "esprima"
              ? this.confirmCorrection
              : modalTab === "tdd"
                ? this.confirmNewTdd
                : console.warn("invalid tab")
          }
          onCancel={this.confirmCorrectionCancel}
        >
          {!solution || solution?.length === 0 ? (
            <p>{t("Enregister une solution pour cette étape")}</p>
          ) : (
            <Tabs activeKey={modalTab} onChange={this.toggleModalTab}>
              {hasScript && (
                <TabPane
                  tab={
                    <span>
                      <Icon type="check-square" />
                      ESParse
                    </span>
                  }
                  key="esprima"
                >
                  <p>
                    {t("Enregistrer le code saisi")} <strong>{title}</strong>,{" "}
                    {t("partie")} <strong>{correctionPart}</strong>,{" "}
                    {t("étape")} <strong>{correctionStep + 1}</strong>.
                  </p>
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {!solution ? (
                        <p>{t("Aucune solution enregistrée")}</p>
                      ) : (
                        <p>{t("Solution actuelle")} :</p>
                      )}
                      <Tooltip
                        placement="topRight"
                        title={t("Importer la solution enregistrée")}
                      >
                        <Button
                          type="primary"
                          onClick={() => this.importSolution()}
                          ghost={true}
                          size="small"
                        >
                          <Icon type="upload" />
                        </Button>
                      </Tooltip>
                    </div>
                    <AceEditor
                      showPrintMargin={false}
                      style={{ height: 300, width: "100%" }}
                      mode={"javascript"}
                      name={`aceEditor-solution-${title}-${correctionPart}-${correctionStep + 1
                        }`}
                      value={correctionCode || ""}
                      editorProps={{ $blockScrolling: false }}
                      theme="tomorrow_night_bright"
                      setOptions={{
                        useWorker: false,
                        fontFamily: '"Dank Mono", "Fira Code", monospace',
                        fontSize: 14.5,
                      }}
                      wrapEnabled={true}
                      width={width}
                      onChange={val => this.onSolutionEdit(val)}
                    />
                  </>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 10,
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginRight: 5, marginBottom: 0 }}>
                      {resultESParse.length > 0 && (
                        <Icon
                          type={esParse ? "check-circle" : "close-circle"}
                          style={{
                            color: esParse ? "#2ecc71" : "#F94A56",
                            float: "left",
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        />
                      )}
                      {resultESParse}
                    </p>
                    <Button type="primary" onClick={() => this.runESParse()}>
                      Test
                    </Button>
                  </div>
                </TabPane>
              )}
              <TabPane
                tab={
                  <span>
                    <Icon type="bug" /> TDD
                  </span>
                }
                key="tdd"
              >
                <AceEditor
                  showPrintMargin={false}
                  style={{ height: "700px", maxHeight: "50vh", width: "100%" }}
                  mode={"javascript"}
                  name={`aceEditor-tdd-${title}-${correctionPart}-${correctionStep + 1
                    }`}
                  value={tddCorrection || ""}
                  editorProps={{ $blockScrolling: false }}
                  theme="tomorrow_night_bright"
                  setOptions={{
                    useWorker: false,
                    fontFamily: '"Dank Mono", "Fira Code", monospace',
                    fontSize: 14.5,
                  }}
                  wrapEnabled={true}
                  width={width}
                  onChange={val =>
                    this.onTDDEdit(val, correctionStep, practiceIndex)
                  }
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginRight: 5, marginBottom: 0 }}>
                    {resultTDD.length > 0 && (
                      <Icon
                        type={tddParse ? "check-circle" : "close-circle"}
                        style={{
                          color: tddParse ? "#2ecc71" : "#F94A56",
                          float: "left",
                          marginLeft: 10,
                          marginRight: 5,
                        }}
                      />
                    )}
                    {resultTDD}
                  </p>
                  <Button
                    type="primary"
                    onClick={() => this.confirmNewTdd(false)}
                  >
                    {t("Test")}
                  </Button>
                </div>
              </TabPane>
            </Tabs>
          )}
        </Modal>

        <Modal
          visible={solutionModal}
          onCancel={() => this.toggleSolutionModal()}
          title={"Afficher la solution ?"}
          footer={false}
          bodyStyle={styles.modal_body}
          width="fit-content"
          style={{ top: 56 }}
        >
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <p style={styles.modal_subtitle}>{t("Débloquer une solution")}</p>
            <p style={styles.modal_subtitle}>{t("Tu regardes trop tôt")}</p>
            <p style={styles.modal_subtitle}>{t("Avoir bien assimilé")}</p>
            <div style={styles.buttonContainer}>
              <Button
                style={styles.outlineButton}
                onClick={() =>
                  this.toggleSolutionDisplay(solutionIndexDisplay, false)
                }
              >
                {t("Afficher la solution")}
              </Button>
            </div>
          </Row>
        </Modal>
      </>
    );
  }
}

function mapStateToProps({
  stepsList,
  user,
  progression,
  batch,
  tracks,
  stackType,
}) {
  return { stepsList, user, progression, batch, tracks, stackType };
}

function mapDispatchToProps(dispatch) {
  return {
    changeDay: function (day) {
      dispatch({ type: "changeDay", day });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Code));

const styles = {
  sPractice: {
    flex: 1,
    margin: "0px 10px 10px 10px",
    boxShadow: "8px 10px 10px 10px rgba(222, 226, 230, 0.3)",
  },
  sInstruction: { marginTop: "10px" },
  hInherit: { display: "flex", height: "100%" },
  editorTabBar: { margin: 0, borderColor: "#272821" },
  editorContainer: { flex: 1, height: "100%", position: "relative" },
  tabPaneBrowser: { backgroundColor: "white", height: "100%" },
  editor: { width: "100%", height: "100%" },
  prevContainer: {
    height: "inherit",
    backgroundColor: "rgb(26, 26, 26)",
    position: "relative",
  },
  preview: { height: "inherit", width: "100%", border: "0px solid #e8e8e8" },
  fsButton: {
    position: "absolute",
    padding: 0,
    fontSize: 18,
    top: 7,
    right: 5,
    zIndex: 9,
    cursor: "pointer",
  },
  addTabButton: {
    position: "relative",
    zIndex: 9,
    cursor: "pointer",
    fontSize: 18,
  },
  addButton: {
    position: "absolute",
    padding: 0,
    fontSize: 18,
    top: 7,
    right: 30,
    zIndex: 9,
    cursor: "pointer",
  },
  fsIcon: { color: "#1890ff" },
  spIcon: { float: "left", paddingRight: 10, paddingTop: 15 },
  runButton: {
    margin: "4px 4px 10px 4px",
    backgroundColor: "#1890ff",
    border: "transparent",
    fontSize: 14,
    width: 55,
  },
  space: { marginTop: 16 },
  questionCol: { position: "relative", height: "100%" },
  codeInitButton: {
    position: "absolute",
    top: 15,
    left: 15,
    height: 30,
    display: "flex",
    alignItems: "center",
  },
  modal_subtitle: {
    fontWeight: 300,
    fontFamily: '"Poppins", sans-serif',
    fontSize: "14px",
    lineHeight: "21px",
  },
  modal_body: {
    backgroundColor: "#F6F7FB",
    padding: 33,
    overflow: "scroll",
    maxHeight: "80vh",
    width: "35vw",
  },
  modal_content: {
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "65px",
    border: "1px solid #f0f2f5",
    minWidth: "500px",
    width: "60vw",
    position: "relative",
  },
  buttonContainer: {
    // width: '90%',
    flexDirection: "column",
    display: "flex",
    paddingTop: 20,
    justifyContent: "center",
    alignItems: "end",
  },
  outlineButton: {
    width: 170,
    height: 43,
    backgroundColor: "#ffffff",
    borderRadius: "50px",
    border: "2px solid #5247EA",
    color: "#5247EA",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: 14,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 3px",
  },
};
