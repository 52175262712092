import React, { useState } from 'react'

//STYLES________________________
import './adminsider.css'
import '../../MVPDashboard/mvpdashboard.css'

//UI___________________________
import { Menu, Layout } from 'antd'

//REDUX_________________________
import { connect } from 'react-redux'

//NAVIGATION____________________
import { Link } from 'react-router-dom'

//TRANSLATION________________________
import { useTranslation } from 'react-i18next';

const { SubMenu, Item } = Menu
const { Sider } = Layout

const AdminSider = ({ user, batch, keys, setKeys, menu }) => {
    //TRANSLATION________________________
    const { t } = useTranslation();
    
    const [collapsed, setCollapsed] = useState(false)

    //FUNCTIONS_________________________
    const onCollapse = () => {
        setCollapsed(!collapsed)
    }

    return (
        <Sider style={ styles.container } collapsible collapsed={ collapsed } onCollapse={ onCollapse } className={ collapsed ? 'custom-sider-collapsed' : 'custom-sider red-border' }>
            <Menu 
            mode="inline"
            inlineCollapsed={ false } 
            defaultSelectedKeys={ keys }
            selectedKeys={ keys }
            defaultOpenKeys={ menu === 'batch' ? ['students']  : ['campusSub'] } 
            openKeys={ ['students', 'campusSub'] }>
                {
                    menu === 'batch' &&
                    <SubMenu key="students" title={t("Élèves")} style={ styles.submenu } expandIcon={ <></> }>
                        <Item key="buddies" style={ styles.item } onClick={ ()=>setKeys(['buddies']) }><Link to='/batch/admin/buddies'>{t("Buddies")}</Link></Item>
                        <Item key="feedback" onClick={ ()=>setKeys(['feedback']) }><Link to='/batch/admin/feedback'>{t("Feedback")}</Link></Item>
                        <Item key="emargement" onClick={ ()=>setKeys(['emargement']) }><Link to="/batch/admin/emargement">{t("Emargement")}</Link></Item>
                        {/* <Item key="notifications" onClick={ ()=>setKeys(['notifications']) }><Link to="#">Notifications</Link></Item> */}
                        <Item key="users"  onClick={ ()=>setKeys(['users']) }><Link to="/batch/admin/users">{t("Gestion des utilisateurs")}</Link></Item>
                    </SubMenu>
                }
                {
                    menu === 'campus' &&
                    <SubMenu key="campusSub" title="Campus" style={ styles.submenu } expandIcon={ <></> }>
                        <Item key="batchedit" style={ styles.item } onClick={ ()=>setKeys(['batchedit']) }><Link to="/batch/admin/batchedit">Batch</Link></Item>
                        <Item key="campusedit" style={ styles.item } onClick={ ()=>setKeys(['campusedit']) }><Link to="/batch/admin/campusedit">Campus</Link></Item>
                    </SubMenu>
                }
            </Menu>
        </Sider>
    )
}

function mapStateToProps({ user, batch, menu }) {
    return { user, batch, menu }
}

export default connect(mapStateToProps, null)(AdminSider);

const styles = {
    container: {
        height: '100%',
        backgroundColor: '#ffffff',
    },
    item: {
        fontWeight: 300,
        fontSize: '14px',
        color: '#898C8F',
        fontFamily: 'Poppins, sans-serif',
        paddingLeft: '24px'
    },
    submenu: {
        display: 'flex',
        flexDirection: 'column',
        // color: '#898C8F',
        // textTransform: 'uppercase',
        // width: '100%',
        // height: '47px',
        // padding: '12px 24px',
        // cursor: 'pointer',
        // position: 'relative',
        // fontWeight: 600,
        // fontFamily: 'Poppins, sans-serif',
        // borderBottom: '0.5px solid #F0F2F5',
    },
}