import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Row } from 'antd';
import { connect } from 'react-redux';

import GroupsLists from './Groups/GroupsList';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

class Groups extends Component {
  render() {
    const { userList, batch, t } = this.props;

    return !userList ? <Redirect to='/' /> : (
      <>
      {
        batch.date && Date.now() <= new Date(batch.date.end).getTime() ?
        <GroupsLists />
        : 
        <>
          <Row style={styles.userList}>
            <p>{t("Ce batch est désormais terminé")}</p>
            <div style={styles.mainLine}></div>
          </Row>
          <Row style={styles.userList}>
            <p>{t("Les groupes ne peuvent plus être générés")}</p>
          </Row>
        </>
      }
      </>
    )
  }
}

function mapStateToProps({ batchuserlist, batch }) {
  return { userList: batchuserlist, batch };
}

export default connect(mapStateToProps)(withTranslation()(Groups));

const styles = {
  userList: { padding: 35, textAlign: 'center', fontFamily: "'Poppins', sans-serif" },
  avatar: { width: '80%', maxWidth: 200, borderRadius: '50%', margin: '5px 0 10px 0' },
  userInfo: { margin: 0, fontSize: 13, },
  ownInfo: { margin: 0, fontSize: 13, color: '#097D8A' },
  mainLine: { height: 1, width: '80%', marginLeft: '10%', backgroundColor: 'lightGrey' },
  teacher: { fontSize: 11, fontStyle: 'italic', color: '#EB5D56' },
  assistant: { fontSize: 11, fontStyle: 'italic', color: '#097D8A' },
  student: { fontSize: 11, fontStyle: 'italic', color: '#808080' }
};