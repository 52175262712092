import React, { Component } from 'react';
import { Col, Row, Icon, Input,Button } from 'antd';

import Repository from './Database/Repository';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

class Database extends Component {
  state = {
    id: '',
    schema: null,
    repositories: [],
    editSchema: false,
    editRepositories: false,
    componentIsMounted: false,
    editable: false
  }

  edit = {}
  timeout = {}

  componentDidMount() {
    const { database: { schema } = {}, repositories, id, editable } = this.props;

    this.setState({ componentIsMounted: true, schema, repositories, id, editable });
  }

  onEditHandle = e => {
    let type = e.replace('edit', '');
    type = type[0].toLowerCase() + type.slice(1);
    
    if (this.state[e]) {
      if (e === 'editSchema') {
        const { id } = this.state;
        const { user: { firstname, lastname, _id}, batchid, updateId, updateDatabase } = this.props;
    
        const myself = { firstname, lastname, userId: _id };
        const dataToSend = id ? { [type]: this.state[type].trim() } : { [type]: this.state[type].trim(), myself, batchid };

        fetch(`${global.URI_BACKEND}/project/${id || 'new'}/database/${type}`, {
          method: 'put',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataToSend)
        }).then(res => res.json()).then(data => {
          if (!data?.error) {
            const { _id } = data;

            if (this.timeout[e]) clearTimeout(this.timeout[e]);
            updateId(_id);
            this.setState({ [type]: data.database[type], [e]: !this.state[e] }, () => {
              const { schema } = this.state;
              updateDatabase({ schema });
            });
          }
        });
      } else if (e === 'editRepositories') {
        this.form.handleSubmit();
      }

    } else {
      this.edit[type] = this.state[type];
      // this.timeout[e] = setTimeout(() => this.onCancelEdit(e), 30000);
      this.setState({ [e]: !this.state[e] });
    }
  }

  onCancelEdit = e => {
    this.setState({ [e]: false });
  }

  setStateInParent = newState => {
    const { updateRepositories } = this.props;
    this.setState(newState, () => {
      const { repositories } = newState;
      updateRepositories(repositories);
    });
  }

  onInputChange = e => {
    const { id, value } = e.target;

    this.setState({ [id]: value });
  }

  render() {
    const { card, title, description, link, imageContainer, image, editIcon, noData } = styles;
    const { schema, repositories, editSchema, editRepositories, componentIsMounted, id, editable } = this.state;
    const { user, batchid, updateId, t } = this.props;

    // console.log('in database render', repositories)

    return <Row gutter={28}>
      <Col xs={24} xl={12}>
        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>DB Schema</div>
            {/* {
              !editSchema && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editSchema')} />
                </div>
                : editSchema && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editSchema')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editSchema')} />
                </div>
            } */}
          </Row>
          <div style={description}>
            {/* {
              !editSchema
                ? schema
                  ? <a style={link} href={schema} target="_blank" rel="noopener noreferrer">{schema}</a>
                  : <div style={noData}>Lien incorrect ou manquant</div>
                : <Input id="schema" placeholder={schema} onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editSchema')} value={schema} />
            } */}

            <a href={`/bddtools?${id}`} target="_blank">
              <Button style={styles.buttonProject}>{t("Ouvrir db schema")}</Button>
            </a>

            <Row style={imageContainer} type="flex" justify="center">
              <img src="/mysql-database.png" alt="database" style={image} />
            </Row>
          </div>
        </div>
      </Col>

      <Col xs={24} xl={12}>
        {
          <div style={card}>
            <Row type="flex" justify="space-between" style={title}>
              <div>{t("myRepository", {count: repositories?.length})}</div>
              {
                !editRepositories && editable
                  ? <div>
                    <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editRepositories')} />
                  </div>
                  : editRepositories && <div>
                    <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editRepositories')} />
                    <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editRepositories')} />
                  </div>
              }
            </Row>
            <Row style={description}>
              {componentIsMounted && <Repository setStateInParent={this.setStateInParent} projectId={id} updateId={updateId} user={user} batchid={batchid} wrappedComponentRef={form => this.form = form} editRepositories={editRepositories} updateRepositories={this.updateRepositories} repositories={repositories} />}
            </Row>
          </div>
        }
      </Col>
    </Row>
  }
}

export default withTranslation()(Database)

const styles = {
  card: { borderRadius: 9, backgroundColor: 'white', marginBottom: 22, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)' },
  title: { padding: 24, fontWeight: 'bold', fontSize: 17, borderBottom: '1px solid #f5f5f5' },
  description: { padding: 24, fontWeight: 'light', fontSize: 14, color: '#444444' },
  link: { fontSize: 14 },
  imageContainer: { display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 10 },
  image: { width: '100%', borderRadius: 11 },
  flex: { display: 'flex', alignItems: 'center' },
  editIcon: { color: '#000000', opacity: 0.65, fontSize: 18, cursor: 'pointer', marginLeft: 10 },
  noData: { color: '#bbb' }
}