import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Col, Row, Spin, Icon, notification } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";

import StepHeader from "./Container/StepHeader";
import SelectorButtons from "./Container/Menu/SelectorButtons";
import Menu from "./Container/Menu/Menu";
import Practice from "./Container/Practice";

import QuizzDisplay from "./Container/Quizz/QuizzDisplay";
import Flashcard from "./Container/Content/Flashcard";

//STYLES___________________________
import "./Container/container.css";
import Course from "./Container/Content/Course";

import Transform from "../Components/Transform";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

class Container extends Component {
  state = {
    contentLoading: false,
    type: null,
    title: null,
    subtitle: null,
    review: [],
    slides: [],
    videos: [],
    resume: [],
    externalData: null,
    practice: [],
    stepType_v2: null,
    stepId: null,
    overviewProject: null,
    titleKey: null,
    quizz: [],
    flashcard: [],
    resources: [],
    scrollTop: 0,
    rawPropositions: [],
    stepData: {},
    noRessource: false,
    postprogramSteps: [],
    step: -1,
    next: -1,
    previous: -1,
    day: null,
  };

  doc = { overview: null, content: null };

  componentDidMount() {
    this.loadEnabledSteps().then(() => this.fetchData());
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: { url: prevUrl },
      language: prevLanguage,
    } = prevProps;
    const {
      match: { url },
      type,
      language,
    } = this.props;

    if (prevUrl !== url || prevLanguage !== language) {
      this.loadEnabledSteps().then(() => this.fetchData());
      if (type === "quizz") this.setState({ questionCount: 0 });
    }
  }

  loadContent = async (url, type) => {
    const { stepData } = this.state;

    const id = stepData.stepId;
    var dataRaw = "";

    if (!url) return false;

    //let request = await fetch(`${global.URI_BACKEND}/content/external-load?uri=${encodeURI(url)}`);
    //let data = await request.text()

    await fetch(
      `${global.URI_BACKEND}/scrapRessources/${id}/${type}/${this.props.language}`
    )
      .then(response => response.json())
      .then(data => {
        if (data.success && data.rawResponse) {
          this.setState({ noRessource: false });
          dataRaw = data.rawResponse;
          return;
        }

        this.setState({ noRessource: true });
      });
    if (dataRaw) {
      // Charger les données de la BDD

      dataRaw = dataRaw.replace(/(\.[a-z0-9]+){/gi, `#${type} $1{`);
      dataRaw = dataRaw.replace(/}([a-z0-9]+){/gi, `}#${type} $1{`);

      //data = data.replace(/(\.[a-z0-9]+){/ig, `#${type} $1{`);
      //data = data.replace(/}([a-z0-9]+){/ig, `}#${type} $1{`);

      const parser = new DOMParser();
      this.doc = parser.parseFromString(dataRaw, "text/html");

      let newStyle = this.doc.body.querySelector("style");
      if (!newStyle) {
        newStyle = this.doc.querySelector("style");
      }

      newStyle.id = `style-${type}`;

      const oldStyle = document.querySelector(`#style-${type}`);
      if (oldStyle) oldStyle.remove();

      const styleElmt = document.getElementsByTagName("head")[0];

      styleElmt.insertBefore(newStyle, styleElmt.firstChild);

      const sheet = document.querySelector("style").sheet;
      if (sheet) {
        sheet.insertRule(
          `#${type} table.noBorder td { border: 0px !important; }`,
          document.querySelector("style").sheet.cssRules.length
        );
      }
      // if (sheet) {
      //   sheet.insertRule(
      //     `#${type} table.noBorder td { border: 0px !important; }`,
      //     document.querySelector("style").sheet.cssRules.length
      //   );
      // }

      let tableList = this.doc.body.querySelectorAll("table");

      let formatResult = [];
      let nbQuestion = 1;
      for (const e of tableList) {
        // On récupère l'ensemble des tr du table
        let trList = e.querySelectorAll("tr");

        // On vérifie que la première ligne est bien une question
        let type = trList[0].textContent.toLowerCase().trim();

        if (
          ["@title"].indexOf(type) === -1 &&
          ["@question"].indexOf(type) === -1
        )
          continue;

        // On initialise l'objet
        let question = {
          id: nbQuestion,
          title: "",
          question: [],
          propositions: [],
          answer: [],
          code: [],
          codeflashcard: [],
          answerCode: [],
        };

        // On initialise le type de la ligne
        let nbprops = 1;

        // On boucle sur les lignes
        for (const tr of trList) {
          // On ne garde que les tr du tableau principal
          if (tr.parentElement.firstChild) {
            let previousElt = tr.parentElement.firstChild.textContent
              .toLowerCase()
              .trim();
            if (
              ["@title"].indexOf(previousElt) === -1 &&
              ["@question"].indexOf(previousElt)
            )
              continue;
          }

          // On check le type de ligne
          let content = tr.textContent.toLowerCase().trim();

          if (["@title"].indexOf(content) !== -1) {
            type = "title";
            continue;
          }

          if (["@question"].indexOf(content) !== -1) {
            type = "question";
            continue;
          }

          if (["@question-code"].indexOf(content) !== -1) {
            type = "question-code";
            continue;
          }

          if (["@proposition"].indexOf(content) !== -1) {
            type = "proposition";
            continue;
          }

          if (["@proposition-code"].indexOf(content) !== -1) {
            type = "proposition-code";
            continue;
          }

          if (["@response"].indexOf(content) !== -1) {
            type = "response";
            continue;
          }

          if (["@response-code"].indexOf(content) !== -1) {
            type = "response-code";
            continue;
          }

          if (["@solution"].indexOf(content) !== -1) {
            type = "solution";
            continue;
          }

          if (["@maingroup"].indexOf(content) !== -1) {
            type = "maingroup";
            continue;
          }

          if (["@secondarygroup"].indexOf(content) !== -1) {
            type = "secondarygroup";
            continue;
          }

          if (["@docid"].indexOf(content) !== -1) {
            type = "docid";
            continue;
          }

          // if (['@display'].indexOf(content) !== -1) {
          //     type = "display"
          //     continue
          // }

          function convertFromHTML(text) {
            const map = {
              "&amp;": "&",
              "&lt;": "<",
              "&gt;": ">",
              "&quot;": '"',
              "&#039;": "'",
              "&nbsp;": " ",
              "<br>": "\n",
            };

            return text.replace(
              /(&amp;|&lt;|&gt;|&quot;|&#039;|&nbsp;|<br>)/g,
              function (m) {
                return map[m];
              }
            );
          }

          if (type === "title") {
            content = tr.textContent;
            question.title = content;
          }

          if (type === "question") {
            // let content = tr.querySelector('span')?.innerText || tr.innerHTML;

            question.question = ReactHtmlParser(tr.innerHTML, {
              transform: Transform,
            });
          }

          if (type === "question-code") {
            let code = "";

            for (const e of tr.querySelector("td").childNodes) {
              const spanList = e.querySelectorAll("span");

              if (spanList.length === 1) {
                code += `${spanList[0].innerText?.replace(/\s{2}/g, "  ")}\n`;
              } else {
                let temp = "";
                for (const span of spanList) {
                  temp += `${convertFromHTML(
                    span.innerHTML?.replace(/\s{2}/g, " ")
                  )}`;
                }
                code += `${temp}\n`;
              }
            }
            question.codeflashcard = ReactHtmlParser(tr.outerHTML, {
              transform: this.transform,
            });

            question.code = code;
          }

          if (type === "proposition" /*|| type === "proposition-code" */) {
            let tableProp = {};
            tableProp.id = nbprops;
            content = ReactHtmlParser(tr.innerHTML, {
              transform: this.transform,
            });
            tableProp.text = content;
            tableProp.solution = null;
            tableProp.withCode = type === "proposition-code";

            question.propositions.push(tableProp);
            nbprops++;
          }

          if (type === "proposition-code") {
            let tableProp = {};
            content = "";

            tableProp.id = nbprops;
            for (const e of tr.querySelector("td").childNodes) {
              const spanList = e.querySelectorAll("span");

              if (spanList.length === 1) {
                content += `${spanList[0].innerText?.replace(/\s{2}/g, " ")}\n`;
              } else {
                let temp = "";
                for (const span of spanList) {
                  temp += `${convertFromHTML(
                    span.innerHTML?.replace(/\s{2}/g, " ")
                  )}`;
                }
                content += `${temp}\n`;
              }
            }

            tableProp.text = content;
            tableProp.solution = null;
            tableProp.withCode = type === "proposition-code";

            question.propositions.push(tableProp);
            nbprops++;
          }

          if (type === "response") {
            question.answer = ReactHtmlParser(
              tr.querySelector("span")?.innerText || tr.innerHTML,
              { transform: this.transform }
            );
          }

          if (type === "response-code") {
            question.answerCode = ReactHtmlParser(tr.outerHTML, {
              transform: this.transform,
            });
          }

          if (type === "solution" && question?.propositions?.[content - 1]) {
            question.propositions[content - 1].solution = true;
          }

          if (type === "maingroup") {
            if (tr.querySelector("table")) {
              // Checks if a table tag is present to differentiate a quiz from an exercise
              const maingroupTemp = ReactHtmlParser(tr.innerHTML, {
                transform: this.transform,
              });
              question.mainGroup = maingroupTemp[0];
            } else {
              const maingroupTemp = ReactHtmlParser(
                tr.querySelector("span")?.innerText || tr.innerHTML,
                { transform: this.transform }
              );
              question.mainGroup = maingroupTemp[0];
            }
          }

          if (type === "secondarygroup") {
            if (tr.querySelector("table")) {
              // Checks if a table tag is present to differentiate a quiz from an exercise
              const secondarygroupTemp = ReactHtmlParser(tr.innerHTML, {
                transform: this.transform,
              });
              question.secondaryGroup = secondarygroupTemp[0];
            } else {
              const secondarygroupTemp = ReactHtmlParser(
                tr.querySelector("span")?.innerText || tr.innerHTML,
                { transform: this.transform }
              );
              question.secondaryGroup = secondarygroupTemp[0];
            }
          }
        }
        formatResult.push(question);

        nbQuestion++;
      }
      return formatResult;
    }
  };

  fetchData = () => {
    this.setState({ contentLoading: true }, () => {
      let {
        match: {
          params: { id, type },
        },
        postprogram,
        batch,
        tracks,
        dailySteps,
        language,
      } = this.props;

      if (postprogram === true) {
        fetch(`${global.URI_BACKEND}/step/postprogramsteps/${batch.stackId}`)
          .then(response => response.json())
          .then(data => {
            this.setState({ postprogramSteps: data.postProgramSteps });
            for (const step of data.postProgramSteps) {
              if (step.stepId === id) {
                this.setState({ stepData: step });
              }
            }
          });
      } else {
        const stepData = dailySteps.find(step => step.stepId === id);
        if (stepData) this.setState({ stepData });
      }

      if (type) {
        if (type === "exercise") type = "practice";

        let qParams = postprogram ? "postprogram=true" : null;
        fetch(
          `${global.URI_BACKEND}/${type.toLowerCase()}/${id}/${batch.stackId
          }/${language}?${qParams}`
        )
          .then(r => r.json())
          .then(async data => {
            if (!data[type]) return;

            const {
              text: subtitle,
              title: subtitle2,
              titleKey,
              review,
              externalData,
              stepType_v2,
              stepId,
              slides,
              videos,
              resume,
              sections: practice,
              questions: quizz,
              resources,
              integrated,
            } = data[type];
            const { title } = data;

            let quizzGdocs = null;
            let practiceData = null;
            let flashCardData = null;

            if (type === "quizz") {
              quizzGdocs = await this.loadContent(externalData, "quizz");
              if (quizzGdocs) {
                this.setState({ rawPropositions: quizzGdocs });
                this.setState({
                  contentLoading: false,
                  type,
                  title,
                  subtitle: subtitle || subtitle2,
                  review,
                  externalData,
                  stepType_v2,
                  stepId,
                  overviewProject: data.overviewProject,
                  slides,
                  videos,
                  resume,
                  titleKey,
                  practice,
                  quizz: quizzGdocs,
                  resources,
                  integrated,
                });
              }
            }

            if (type === "flashcard") {
              flashCardData = await this.loadContent(externalData, "flashcard");
              if (flashCardData) {
                this.setState({
                  contentLoading: false,
                  type,
                  title,
                  subtitle: subtitle || subtitle2,
                  review,
                  externalData,
                  stepType_v2,
                  stepId,
                  overviewProject: data.overviewProject,
                  slides,
                  videos,
                  resume,
                  titleKey,
                  practice,
                  flashcard: flashCardData,
                  resources,
                  integrated,
                });
              }
            }

            if (type === "practice") {
              practiceData = await this.loadContent(externalData, "practice");
              if (practiceData) {
                for (let i = 0; i < practiceData.length; i++) {
                  if (practice[i]) {
                    practice[i].title = practiceData[i].title;
                    practice[i].course = practiceData[i].question;
                    if (practiceData[i].mainGroup) {
                      practice[i].mainGroup = practiceData[i].mainGroup;
                      practice[i].secondaryGroup =
                        practiceData[i].secondaryGroup;
                    }

                    practice[i].questions = practiceData[i].propositions.map(
                      (proposition, j) => {
                        return {
                          _id: practice[i].questions[j]?._id,
                          instruction: proposition.text,
                          rawCode: practice[i].questions[j]?.rawCode,
                          solutionArray:
                            practice[i].questions[j]?.solutionArray || [],
                          // tddfiles: practice[i].challenges[j]?.tddfiles,
                          solution: practice[i].questions[j]?.solution,
                        };
                      }
                    );
                  }
                }
                this.setState({
                  contentLoading: false,
                  type,
                  title,
                  subtitle: subtitle || subtitle2,
                  review,
                  externalData,
                  stepType_v2,
                  stepId,
                  overviewProject: data.overviewProject,
                  slides,
                  videos,
                  resume,
                  titleKey,
                  practice,
                  resources,
                  integrated,
                });
              }
            }

            this.setState({
              contentLoading: false,
              type,
              title,
              subtitle: subtitle || subtitle2,
              review,
              externalData,
              stepType_v2,
              stepId,
              overviewProject: data.overviewProject,
              slides,
              videos,
              resume,
              titleKey,
              resources,
              integrated,
            });
          });
      }
    });
  };

  /*  setProgression = (progression) => {
     fetch(global.URI_BACKEND + '/progression', {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify(progression)
     }).then(res => res.json()).then(console.log).catch(console.error);
   } */

  onScroll = e => {
    const offset =
      e.currentTarget.scrollTop - e.currentTarget.getBoundingClientRect().top;

    this.setState({ scrollTop: Math.max(0, offset) });
  };

  updatePractice = async data => {
    try {
      // this.setState({ contentLoading: true })
      let externalData = data.externalData;
      let practice = data.sections;

      if (practice) {
        let practiceData = await this.loadContent(externalData, "practice");

        if (practiceData) {
          for (let i = 0; i < practiceData.length; i++) {
            if (practice[i]) {
              practice[i].title = practiceData[i].title;
              practice[i].course = practiceData[i].question;
              if (practiceData[i].mainGroup) {
                practice[i].mainGroup = practiceData[i].mainGroup;
                practice[i].secondaryGroup = practiceData[i].secondaryGroup;
              }

              practice[i].questions = practiceData[i].propositions.map(
                (proposition, j) => {
                  return {
                    _id: practice[i].questions[j]?._id,
                    instruction: proposition.text,
                    rawCode: practice[i].questions[j]?.rawCode,
                    solutionArray:
                      practice[i].questions[j]?.solutionArray || [],
                    // tddfiles: practice[i].challenges[j]?.tddfiles,
                    solution: practice[i].questions[j]?.solution,
                  };
                }
              );
            }
          }
          this.setState({ /*contentLoading: false, */ externalData, practice });
        }
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Erreur d'enregistrement",
        description:
          "Une erreur est survenue lors de l'enregistrement en base de données.",
      });
    }
  };

  navigateStep = step => {
    const { history } = this.props;
    history.push(`/batch/${step.stepType}/${step.stepId}`);
  };

  isEnabled = resource => {
    if (!resource.enable) return false;

    if (!resource.progress) return true;

    if (
      resource.stepType_v2 === "rncp" &&
      resource.tag !== "positionnement"
    )
      return resource.unlockedQuiz;

    if (resource.stepType_v2 === "flashcard") return resource.unlockedFlashCard;

    return resource.progressUnlocked;
  };

  changeStep = () => {
    const {
      dailySteps,
      user,
      match: {
        params: { id },
      },
    } = this.props;

    const index = dailySteps.findIndex(el => el.stepId === id);

    const QCMUnlocked = dailySteps.some(
      el => el.secondaryGroup === "QCM de la semaine" && el.unlockedQuiz
    );
    const flashcardsUnlocked = dailySteps.some(
      el => el.stepType_v2 === "flashcard" && el.unlockedFlashCard
    );

    let stepsTmp = [...dailySteps];

    if (user.type === "student") {
      stepsTmp = stepsTmp.filter(e => e?.mainGroup !== "Teachers");
      if (!QCMUnlocked)
        stepsTmp = stepsTmp.filter(
          e => e.secondaryGroup !== "QCM de la semaine"
        );
      if (!flashcardsUnlocked)
        stepsTmp = stepsTmp.filter(e => e.stepType_v2 !== "flashcard");
    }

    const filteredIndex = stepsTmp.findIndex(e => e.stepId === id);

    let prevStep = stepsTmp?.[filteredIndex - 1];
    let nextStep = stepsTmp?.[filteredIndex + 1];

    const isAdmin = !["student", "workshop"].includes(user.type);
    this.setState({
      step: index,
      previous:
        prevStep && (this.isEnabled(prevStep) || isAdmin)
          ? dailySteps.findIndex(el => el.stepId === prevStep.stepId)
          : -1,
      next:
        nextStep && (this.isEnabled(nextStep) || isAdmin)
          ? dailySteps.findIndex(el => el.stepId === nextStep.stepId)
          : -1,
    });
  };

  updateProgress = async () => {
    const { batchid, user, dailySteps, stepsList } = this.props;

    const data = await fetch(
      `${global.URI_BACKEND}/step/allProgress?batchId=${batchid}&userId=${user._id}`
    );
    const json = await data.json();
    if (json.success) {
      /**
       * first index after last step begun by student
       */
      const newSteps = dailySteps.map((e, i) => {
        if (json.progressGlobal[e.stepId]) {
          e.stepProgress = json.progressGlobal[e.stepId].stepPercentage;
          e.scoreProgress =
            json.progressGlobal[e.stepId].scorePercentage ||
            json.progressGlobal[e.stepId].stepPercentage;
          e.questionCount =
            json.progressGlobal[e.stepId]?.questionCount ||
            json.progressGlobal[e.stepId]?.sections?.length;
          e.ownSections = json.progressGlobal[e.stepId]?.sections;
          e.scoreResult = json.progressGlobal[e.stepId].scoreResult;
        }

        if (e.mainGroup === "Teachers") e.progress = false;

        if (json.progressStudent.includes(e.stepId) || !e.progress) {
          e.progressUnlocked = true;
        } else {
          e.progressUnlocked = false;
        }

        return e;
      });

      stepsList(newSteps);
      this.changeStep();
    }
  };

  getStackDay = stepId => {
    const { tracks } = this.props;

    for (const t of tracks) {
      for (const p of t.projects) {
        for (const d of p.days) {
          if (d.steps.some(step => step.stepId === stepId)) {
            return { day: d, project: p, track: t };
          }
        }
      }
    }

    return {};
  };

  loadEnabledSteps = () => {
    const {
      batch,
      language,
      stepsList,
      batchid,
      tracks,
      match: {
        params: { id },
      },
      changeDay,
      user,
      day,
      postprogram
    } = this.props;

    if (postprogram) return Promise.resolve();

    this.setState({ contentLoading: true });

    const { day: dayTmp, project, track } = this.getStackDay(id);
    let date;

    for (let week of batch.planning) {
      for (let day of week.days) {
        if (day.countDay === dayTmp.day) {
          changeDay(moment(day.date));
          date = day.date;
        }
      }
    }

    return fetch(
      `${global.URI_BACKEND}/step?batchId=${batchid}&userId=${user._id}&date=${date}&language=${language}`
    )
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          dayTmp.steps = data.steps;
          dayTmp.imagepath = project.imagepath;
          dayTmp.language = language;
          dayTmp.title = dayTmp.data.find(d => d.language === language)?.title
            ? dayTmp.data.find(d => d.language === language)?.title
            : dayTmp.data[0].title;

          const list = data.steps.map(function (step) {
            return {
              ...step,
              trackId: track._id,
              projectId: project._id,
              dayId: dayTmp._id,
              day: dayTmp.day,
            };
          });

          const stepData = list.find(step => step.stepId === id);
          this.setState({ day: dayTmp, stepData });

          stepsList(list);
        }
      })
      .then(() => this.updateProgress());
  };

  synchronizeRessource = () => {
    this.setState({ contentLoading: true });

    const { stepData, overviewProject } = this.state;
    const { postprogram, language } = this.props;

    if (postprogram === true) {
      const stepId = stepData.stepId;
      const stepType = "course";
      fetch(
        `${global.URI_BACKEND}/scrapRessources/fill-database/${stepId}/${stepType}/${language}`
      )
        .then(response => response.json())
        .then(data => {
          if (!data?.error) {
            this.fetchData();
            this.setState({ contentLoading: false });
          }
        });
    } else {
      const stepId = stepData.stepId;
      const stepType = stepData.stepType_v2;
      fetch(
        `${global.URI_BACKEND}/scrapRessources/fill-database/${stepId}/${stepType}/${language}`
      )
        .then(response => response.json())
        .then(data => {
          if (stepType === "exercise" && !data.success) {
            notification.error({
              message: "Erreur d'enregistrement",
              description: `Une erreur est survenue lors de l'enregistrement en base de données.
              ${data.errorMessage || ""}
              Vérifiez la structure du document avant de réessayer.`,
            });
          }

          this.fetchData();
          this.setState({ contentLoading: false });
        });
    }
  };

  render() {
    const { path, content, loading } = styles;
    const {
      contentLoading,
      overviewProject,
      type,
      subtitle,
      review,
      externalData,
      slides,
      videos,
      resume,
      practice,
      titleKey,
      quizz,
      flashcard,
      resources,
      rawPropositions,
      stepData,
      noRessource,
      stepHasProgression,
      step: stepIndex,
      day,
      previous: previousIndex,
      next: nextIndex,
    } = this.state;
    const {
      match: {
        params: { id },
        url,
      },
      batch,
      postprogram,
      progression,
      user,
      stackType,
      t,
      dailySteps,
    } = this.props;

    const isProgressLocked = ["student", "workshop"].includes(user.type) && !(
      ["rncp", "flashcard"].includes(stepData.stepType_v2) ||
      stepData.secondaryGroup === "positionnement"
    ) &&
      stepData?.progress &&
      !stepData?.progressUnlocked;

    let isAfter = moment().isAfter(moment(batch?.date?.end));
    if (stackType?.keyMode === "workshop")
      isAfter = moment().isAfter(moment(batch?.date?.start));

    let progress = {};
    if (postprogram) {
      progress.enable = true;
    } else {
      for (const e of progression) {
        if (e.stepId === stepData._id) {
          progress = e;
          break;
        }
      }
    }

    const step = dailySteps[stepIndex];
    const next = dailySteps[nextIndex];
    const previous = dailySteps[previousIndex];

    if (
      !contentLoading &&
      !progress?.enable &&
      !isAfter &&
      user.type === "student"
    ) {
      return (
        <Layout
          style={{
            padding: "10px 8px 5px 25px",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 20,
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          <Icon style={{ fontSize: 40 }} type="lock" />
          <p>
            Ne soyez pas si impatient !{" "}
            <span role="img" aria-label="wink">
              😉
            </span>
          </p>
          <p>Cette ressource se débloquera le jour venu</p>
        </Layout>
      );
    } else if (postprogram && !id) {
      return (
        <Layout
          style={{
            padding: "10px 8px 5px 25px",
            marginTop: "30px",
            marginRight: "20%",
            fontWeight: 300,
            fontSize: "14px",
            fontFamily: '"Poppins", sans-serif',
          }}
        >
          <p>
            Ca y est! Tu sais enfin coder après ces semaines intensives à La
            Capsule.{" "}
            <span role="img" aria-label="emoji">
              👏
            </span>
          </p>

          <i>Tu dois sûrement te demander “Et maintenant?”...</i>

          <p>
            C’est pour répondre à cette question que l’on a créé le Career
            Playbook !{" "}
            <span role="img" aria-label="emoji">
              🆕
            </span>
          </p>

          <strong>Le Career Playbook, c’est quoi ?</strong>

          <p>Ce sont des ressources made by La Capsule avec:</p>
          <ul>
            <li>
              nos meilleurs conseils pour démarrer{" "}
              <span role="img" aria-label="emoji">
                💬
              </span>
            </li>
            <li>
              des vidéos très inspirantes{" "}
              <span role="img" aria-label="emoji">
                ⏯
              </span>
            </li>
            <li>
              des listes de podcasts, chaînes Youtube et livres pour approfondir{" "}
              <span role="img" aria-label="emoji">
                🔎
              </span>
            </li>
            <li>
              des tests techniques pour t’entraîner{" "}
              <span role="img" aria-label="emoji">
                💻
              </span>
            </li>
            <li>
              et bien plus encore! A toi de fouiller{" "}
              <span role="img" aria-label="emoji">
                😉
              </span>
            </li>
          </ul>

          <p>
            Tu l’as compris, tu y trouveras un tas d’informations pour te lancer
            dans ta vie professionnelle, que tu sois développeur, entrepreneur,
            product manager ou freelance! On a tout compilé ici pour
            t’accompagner à travers ton expérience post-La Capsule.
          </p>

          <p>
            Bien sûr, c’est un format évolutif, mis à jour régulièrement afin de
            t’offrir un suivi optimal.
            <span role="img" aria-label="emoji">
              🔥
            </span>
          </p>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <StepHeader
            synchronizeRessource={this.synchronizeRessource}
            stepData={stepData}
            postprogram={postprogram}
            stepHasProgression={stepHasProgression}
            resetStepProgression={this.resetStepProgression}
            updateProgress={this.updateProgress}
            step={step}
            previous={previous}
            next={next}
            day={day}
            changeStep={this.navigateStep}
            loadEnabledSteps={this.loadEnabledSteps}
          />
          <Layout
            id="contentLayout"
            onScroll={this.onScroll}
            style={{
              flex: 1,
              overflowY: "scroll",
              backgroundColor: "transparent",
            }}
          >
            {contentLoading ? (
              <Layout
                id="contentLayout"
                onScroll={this.onScroll}
                style={{
                  flex: 1,
                  overflowY: "scroll",
                  backgroundColor: "transparent",
                }}
              >
                <div style={loading}>
                  <Spin size="large" />
                </div>
              </Layout>
            ) : noRessource ? (
              <Layout
                style={{
                  padding: "10px 8px 5px 25px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 20,
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                <Icon style={{ fontSize: 40 }} type="lock" />
                <p>{t("Vous devez synchroniser les ressources")}.</p>
              </Layout>
            ) : isProgressLocked ? (
              <Layout
                style={{
                  padding: "10px 8px 5px 25px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 20,
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                <Icon style={{ fontSize: 40 }} type="lock" />
                <p>
                  Ne soyez pas si impatient !{" "}
                  <span role="img" aria-label="wink">
                    😉
                  </span>
                </p>
                <p>
                  Vous devez finir toutes les étapes précedentes avant de
                  débloquer cette ressource.
                </p>
              </Layout>
            ) : (
              <>
                {type !== "project" && <Row style={path} id="path"></Row>}

                <Layout.Content style={content}>
                  {noRessource ? (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "50px",
                      }}
                    >
                      {t("Vous devez synchroniser les ressources")}
                    </div>
                  ) : type === "slide" ? (
                    <Course
                      postprogram={postprogram}
                      stepData={stepData}
                      progress={progress}
                      scrollTop={this.state.scrollTop}
                      overviewProject={overviewProject}
                      id={id}
                      resources={resources}
                      iframeTitle={subtitle}
                      review={review?.[0]}
                      externalData={externalData}
                      slides={slides?.[0]}
                      videos={videos?.[0]}
                      resume={resume?.[0]}
                      url={url}
                      next={next}
                      updateProgress={this.updateProgress}
                    />
                  ) : type === "practice" ? (
                    <Practice
                      stepData={stepData}
                      progress={progress}
                      id={id}
                      practice={practice}
                      type={type}
                      title={subtitle}
                      titleKey={titleKey}
                      setProgression={this.setProgression}
                      updatePractice={this.updatePractice}
                      next={next}
                      updateProgress={this.updateProgress}
                    />
                  ) : type === "quizz" ? (
                    <Row>
                      <Col span={24}>
                        <QuizzDisplay
                          stepData={stepData}
                          progress={progress}
                          questions={quizz}
                          rawPropositions={rawPropositions}
                          id={id}
                          type={type}
                          setProgressionTest={this.setProgressionTest}
                          url={url}
                          next={next}
                          updateProgress={this.updateProgress}
                        />
                      </Col>
                      <Col offset={1} span={5}>
                        {type !== "quizz" && !postprogram ? (
                          <SelectorButtons id={id} type={type} />
                        ) : null}
                        <Menu />
                      </Col>
                    </Row>
                  ) : type === "flashcard" ? (
                    <Row>
                      <Col span={24}>
                        <Flashcard
                          stepData={stepData}
                          /* progress={progress} */
                          flashcard={flashcard}
                          id={id}
                          type={type}
                          /* setProgression={this.setProgression} */
                          setProgressionTest={this.setProgressionTest}
                          url={url}
                          next={next}
                          updateProgress={this.updateProgress}
                        />
                      </Col>
                      <Col offset={1} span={5}>
                        {type !== "flashcard" && !postprogram ? (
                          <SelectorButtons id={id} type={type} />
                        ) : null}
                        <Menu />
                      </Col>
                    </Row>
                  ) : null}
                </Layout.Content>
              </>
            )}
          </Layout>
        </Layout>
      );
    }
  }
}

function mapStateToProps({
  progression,
  tracks,
  batch,
  user,
  stackType,
  language,
  stepsList,
  batchid,
  day,
}) {
  return {
    progression,
    tracks,
    batch,
    user,
    stackType,
    language,
    dailySteps: stepsList,
    batchid,
    day,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectFirstStep: function (firstStep) {
      dispatch({ type: "selectFirstStep", firstStep });
    },
    stepsList: function (stepsList) {
      dispatch({ type: "stepsList", stepsList });
    },
    changeDay: function (day) {
      dispatch({ type: "changeDay", day });
    },
  };
}

const ContainerRedux = connect(mapStateToProps, mapDispatchToProps)(Container);

export default withRouter(withTranslation()(ContainerRedux));

const styles = {
  path: { margin: "5px 0 0 0", padding: 0 },
  content: { flex: 1, flexDirection: "column", position: "relative" },
  mb: { marginBottom: 24 },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
};
