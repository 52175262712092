import typeChecker from "../../typeChecker"

function statement(tc = {}, pb = {}) { 
    let check = {}   
    for (const key in tc) {
        if (tc[key]?.type) {
            check[key] = typeChecker(tc[key], pb[key])
        } else {
            check[key] = tc[key] === pb[key]
        }
    }

    return Object.values(check).every(e => e === true)
}

export default statement;