import React, { Component } from 'react';
import { Col, Row, Icon, Input } from 'antd';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

const { TextArea } = Input;

class Pitch extends Component {
  state = {
    id: '',
    target: null,
    problem: null,
    solution: null,
    originality: null,
    uriSlide: null,
    editTarget: false,
    editProblem: false,
    editSolution: false,
    editOriginality: false,
    editUriSlide: false,
    editable: false
  }

  edit = {}
  timeout = {}

  componentDidMount() {
    const { pitch: { target, problem, solution, originality, uriSlide } = {}, id, editable } = this.props;

    this.setState({ target, problem, solution, originality, uriSlide, id, editable });
  }

  onEditHandle = e => {
    let type = e.replace('edit', '');
    type = type[0].toLowerCase() + type.slice(1);

    if (this.state[e] && this.state[type] !== this.edit[type]) {
      const { user: { firstname, lastname, _id }, batchid, updateId, updatePitch } = this.props;
      const { id } = this.state;

      const myself = { firstname, lastname, userId: _id };
      const dataToSend = id ? { [type]: this.state[type].trim() } : { [type]: this.state[type].trim(), myself, batchid };

      fetch(`${global.URI_BACKEND}/project/${id || 'new'}/pitch/${type}`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend)
      }).then(res => res.json()).then(data => {
        if (!data?.error) {
          const { _id } = data;

          if (this.timeout[e]) clearTimeout(this.timeout[e]);
          updateId(_id);
          this.setState({ [type]: data.pitch[type], [e]: !this.state[e], id: _id }, () => {
            const { target, problem, solution, originality, uriSlide } = this.state;
            updatePitch({ target, problem, solution, originality, uriSlide });
          });
        }
      });
    } else {
      this.edit[type] = this.state[type];
      this.timeout[e] = setTimeout(() => this.onCancelEdit(e), 120000);
      this.setState({ [e]: !this.state[e] });
    }
  }

  onCancelEdit = e => {
    let type = e.replace('edit', '');
    type = type[0].toLowerCase() + type.slice(1);

    if (this.timeout[e]) clearTimeout(this.timeout[e]);

    this.setState({ [e]: false, [type]: this.edit[type] });
  }

  onInputChange = e => {
    const { id, value } = e.target;

    this.setState({ [id]: value });
  }

  render() {
    const { card, title, description, link, slideContainer, image, editIcon, noData } = styles;
    const { t } = this.props
    const { target, problem, solution, originality, uriSlide, editTarget, editProblem, editSolution, editOriginality, editUriSlide, editable } = this.state;

    // console.log('Je suis dans le render de Pitch', id)

    return <Row gutter={28}>
      <Col xs={24} xl={12}>
        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>{t("Cible")}</div>
            {
              !editTarget && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editTarget')} />
                </div>
                : editTarget && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editTarget')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editTarget')} />
                </div>
            }
          </Row>
          <Row style={description}>
            {
              !editTarget
                ? target
                  ? <div>{target}</div>
                  : <div style={noData}>{t("Choix cible projet")}</div>
                : editTarget && <TextArea rows={2} id="target" onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editTarget')} value={target} />
            }
          </Row>
        </div>

        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>{t("Problématique")}</div>
            {
              !editProblem && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editProblem')} />
                </div>
                : editProblem && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editProblem')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editProblem')} />
                </div>
            }
          </Row>
          <Row style={description}>
            {
              !editProblem
                ? problem
                  ? <div>{problem}</div>
                  : <div style={noData}>{t("Choix problématique projet")}</div>
                : editProblem && <TextArea rows={2} id="problem" onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editProblem')} value={problem} />
            }
          </Row>
        </div>

        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>{t("Votre solution en deux mots")}</div>
            {
              !editSolution && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editSolution')} />
                </div>
                : editSolution && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editSolution')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editSolution')} />
                </div>
            }
          </Row>
          <Row style={description}>
            {
              !editSolution
                ? solution
                  ? <div>{solution}</div>
                  : <div style={noData}>{t("Choix solution projet")}</div>
                : editSolution && <TextArea rows={2} id="solution" onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editSolution')} value={solution} />
            }
          </Row>
        </div>
      </Col>

      <Col xs={24} xl={12}>
        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>{t("Son originalité")}</div>
            {
              !editOriginality && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editOriginality')} />
                </div>
                : editOriginality && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editOriginality')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editOriginality')} />
                </div>
            }
          </Row>
          <Row style={description}>
            {
              !editOriginality
                ? originality
                  ? <div>{originality}</div>
                  : <div style={noData}>{t("Choix originalité projet")}</div>
                : editOriginality && <TextArea rows={2} id="originality" onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editOriginality')} value={originality} />
            }
          </Row>
        </div>

        <div style={card}>
          <Row type="flex" justify="space-between" style={title}>
            <div>{t("Url Slides")}</div>
            {
              !editUriSlide && editable
                ? <div>
                  <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editUriSlide')} />
                </div>
                : editUriSlide && <div>
                  <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editUriSlide')} />
                  <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editUriSlide')} />
                </div>
            }
          </Row>
          <Row style={description}>
            {
              !editUriSlide
                ? <div>
                  {
                    uriSlide
                      ? uriSlide && <a style={link} href={`${uriSlide}`} target="_blank" rel="noopener noreferrer">{uriSlide}</a>
                      : <div style={noData}>{t("Lien incorrect ou manquant")}</div>
                  }
                  <Row style={slideContainer} type="flex" justify="center">
                    {
                      !uriSlide
                        ? <img src="/slides.png" alt="slides" style={image} />
                        : uriSlide && <iframe title="demo slide" src={`${uriSlide}/embed`} width="450" height="300" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    }
                  </Row>
                </div>
                : <Input id="uriSlide" onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editUriSlide')} value={uriSlide} />
            }
          </Row>
        </div>
      </Col>
    </Row>;
  }
}

export default withTranslation()(Pitch)

const styles = {
  card: { borderRadius: 9, backgroundColor: 'white', marginBottom: 22, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)' },
  title: { padding: 24, fontWeight: 'bold', fontSize: 17, borderBottom: '1px solid #f5f5f5' },
  description: { padding: 24, fontWeight: 'light', fontSize: 14, color: '#444444' },
  link: { fontSize: 14, marginBottom: 20 },
  slideContainer: { marginTop: 20, marginBottom: 10 },
  image: { width: '100%', maxWidth: 650, borderRadius: 11 },
  editIcon: { color: '#000000', opacity: 0.65, fontSize: 18, cursor: 'pointer', marginLeft: 10 },
  noData: { color: '#bbb' }
}