import React, { Component } from 'react';
import { Menu, Layout, Icon } from 'antd';
import { connect } from 'react-redux';
import './SiderMenu.css';
import { Link } from 'react-router-dom';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

class SiderMenu2 extends Component {

  state = {
    collapsed: false,
  }

  onCollapse = () => {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  };

  onCollapse2 = () => {
    const { collapsed } = this.state;

    if (collapsed == true) {
      this.setState({ collapsed: !collapsed });
    }
  };

  render() {
    const { sider, menu, menuTitle, text } = styles;
    const { postProgram } = this.props;
    const { collapsed } = this.state;

    const icons = {
      'Développeur': 'code',
      'Entrepreneur': 'rocket',
      'Freelance': 'home'
    };

    let styleLink = text;
    let postProgramMenu;

    if (postProgram[0] && postProgram[0].chapters) {
      postProgramMenu = postProgram.map((group, i) => {
        return <SubMenu key={'g' + i} title={<><Icon onClick={this.onCollapse2} type={icons[group.title]} /><span style={menuTitle}>{group.title}</span></>}>
          {
            group.chapters.map((chapter, i2) => {
              const url = `/post-program/slide/${chapter.stepId}`;

              return <Item key={'g' + i + 's' + i2}>
                <Link to={url} style={styleLink}>{chapter.text}</Link>
              </Item>;
            })
          }
        </SubMenu>
      });
    }

    return (<Sider width={220} collapsible collapsed={collapsed} onCollapse={this.onCollapse} id="career-playbook__menu" theme="light" style={sider}>
      <Menu inlineIndent={10} mode="inline" style={menu}>
        {postProgramMenu}
      </Menu>
    </Sider>);
  }
}


function mapStateToProps(state) {
  return { postProgram: state.postProgram };
}

export default connect(mapStateToProps)(SiderMenu2);

const styles = {
  sider: {
    background: '#fff', overflow: 'auto',
    width: 'auto',
    left: 0,
  },
  menu: { height: '100%', borderRight: 0 },
  title: { fontWeight: 'bold' },
  menuTitle: { fontWeight: 'bold', marginRight: 10 },
  selected: { backgroundColor: '#FC5255', color: 'white' },
  noSelected: { color: '#FC5659' },
  text: { overflow: 'hidden', textOverflow: 'ellipsis' },
};
