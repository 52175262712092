import React, { Component } from 'react';
import { Row, Col, Avatar, Icon, Form, Input, Button, Menu, Badge, Radio, DatePicker, Alert, message, Drawer, Select, List, Table, Divider, Tag } from 'antd';
import SimpleBar from 'simplebar-react';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, LineChart, Line, CartesianGrid, XAxis, YAxis, BarChart, Bar, Tooltip } from 'recharts';
import { connect } from 'react-redux';
import moment from 'moment';
import checkRank from '../../utils/checkRank';
import Profile from '../Profile';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

class UserInfo extends Component {
    state = {
        messages: [],
        edit: false,
        user: this.props.editUser,
        current: 'user',
        visible: false,
        visibleRDV: false,
        value: 'rendez-vous',
        simpleMessage: '',
        privateMessage: '',
        appointment: '',
        privateAppointment: '',
        appointmentDate: null,
        logError: false,
        logErrorRDV: false,
        detailsIndex: null,
        msgIndex: null,
        conversationId: null,
        valueMenu: 2,
    };

    componentDidMount() {
        if (checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) {
            fetch(global.URI_BACKEND + `/userCommunication/retrieveMessages/${this.props.editUser._id}/${this.props.batch._id}`).then(res => res.json()).then(async data => this.setState({ messages: data.messages }))
        } else {
            this.setState({ messages: this.props.messages });
        }
    }

    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    onChangeMenu = e => {
        this.setState({
            valueMenu: e.target.value,
        });
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    showModalRDV = () => {
        this.setState({
            visibleRDV: true,
        });
    };

    selectDay = day => {
        const { setDay } = this.props;

        setDay(day);
    };

    // SELECT WEEK
    handleChange = value => {
        console.log(`selected ${value}`);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const body = { id: this.state.user._id };
                let tempUser = { ...this.state.user };

                for (e in values) {
                    if (values[e] !== undefined) {
                        if (e === 'firstname' || e === 'lastname') {
                            body[e] = values[e].charAt(0).toUpperCase() + values[e].slice(1);
                            tempUser[e] = values[e].charAt(0).toUpperCase() + values[e].slice(1);
                        } else {
                            body[e] = values[e];
                            tempUser[e] = values[e];
                        }
                    }
                };

                if (body.firstname && body.lastname) {
                    body.fullname = body.firstname + " " + body.lastname;
                    tempUser.fullname = body.firstname + " " + body.lastname;
                } else if (body.firstname && !body.lastname) {
                    body.fullname = body.firstname + " " + tempUser.lastname;
                    tempUser.fullname = body.firstname + " " + tempUser.lastname;
                } else if (!body.firstname && body.lastname) {
                    body.fullname = tempUser.firstname + " " + body.lastname;
                    tempUser.fullname = tempUser.firstname + " " + body.lastname;
                }

                fetch(global.URI_BACKEND + '/user/userUpdate', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body)
                }).then(res => res.json()).then(async data => {
                    if (data.result) {
                        this.setState({ user: tempUser, edit: false });
                        this.props.parentEdit(tempUser);
                    }
                });
            }
        });
    };

    handleClick = e => {
        this.setState({
            current: e.key,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            conversationId: null,
        });
    };

    handleCancelRDV = e => {
        this.setState({
            visibleRDV: false,
        });
    };

    Receivers = (message) => {
        let receivers = [];
        let findStudents = message.students.filter(e => e !== this.props.currentUser._id);
        let findTeachers = message.teachers.filter(e => e !== this.props.currentUser._id);
        findStudents.forEach(e => receivers.push(e));
        findTeachers.forEach(e => receivers.push(e));
        return receivers;
    };

    SendAppointment = () => {
        const { appointmentDate, appointment, privateAppointment, value } = this.state;

        const body = { users: [this.props.currentUser, this.state.user], type: value }
        if (appointmentDate) body.appointmentDate = appointmentDate;
        let messages = [];
        if (appointment) messages.push({ text: appointment, sender: this.props.currentUser._id });
        if (privateAppointment && messages[0]) {
            messages[0].note = privateAppointment;
        } else if (privateAppointment) {
            messages.push({ note: privateAppointment, sender: this.props.currentUser._id });
        }

        body.messages = messages;

        fetch(global.URI_BACKEND + `/userCommunication`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }).then(res => res.json()).then(async data => {
            if (data.success) {
                this.handleCancelRDV();
                this.props.addMessage(data.message);
                if (checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) this.setState({ messages: [data.message, ...this.state.messages] });
                message.info('Message envoyé.');
                global.SOCKET.emit('newMessage', { receiver: this.Receivers(data.message), msg: (this.props.currentUser !== this.state.user) ? data.message : null });
            } else {
                this.setState({ logErrorRDV: true });
            }
        })
    };

    Respond = () => {
        const { appointment, privateAppointment, conversationId } = this.state;

        let messages = {};
        if (appointment) messages = { text: appointment, sender: this.props.currentUser._id };
        if (privateAppointment && Object.keys(messages).length > 0) {
            messages.note = privateAppointment;
        } else if (privateAppointment) {
            messages = { note: privateAppointment, sender: this.props.currentUser._id };
        }

        const body = { conversation: conversationId, messages };

        fetch(global.URI_BACKEND + `/userCommunication/newMessage`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }).then(res => res.json()).then(async data => {
            if (data.success) {
                this.handleCancel();
                message.info('Message envoyé.');
                if (checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) {
                    let index = this.state.messages.findIndex(e => e._id === data.message._id);
                    let tempMsg = [...this.state.messages];
                    tempMsg[index] = data.message;
                    this.setState({ messages: tempMsg });
                }
                if (appointment) {
                    this.props.addMessage(data.message);
                    global.SOCKET.emit('newMessage', { receiver: this.Receivers(data.message), msg: (this.props.currentUser !== this.state.user) ? data.message : null });
                }
            } else {
                this.setState({ logError: true });
            }
        })
    };


    render() {
        const { input, buttonStyle, subtitle, radioStyle, text, contentPanel, contentTitle, borders, content, tagStylePurple, tagStylePink, pinkIcon, purpleIcon, tablerowbackground } = styles;
        const { messages, user, visible, visibleRDV, simpleMessage, privateAppointment, privateMessage, appointment, appointmentDate, detailsIndex, msgIndex, logError, logErrorRDV, conversationId, current, valueMenu } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { t } = this.props

        console.log('USER', user)

        console.log('MESSAGES', this.props.messages)

        const columns = [
            {
                title: t('Jour', {count: 1}),
                dataIndex: 'jour',
                key: 'jour',
            },
            {
                title: t('Matin'),
                dataIndex: 'matin',
                key: 'matin',
                align: 'center',
            },
            {
                title: t('Après-midi'),
                dataIndex: 'aprem',
                key: 'aprem',
                align: 'center',
            },
            {
                title: 'Office Hours',
                key: 'oh',
                dataIndex: 'oh',
                align: 'center',
            },
        ];

        const data = [
            {
                key: '1',
                jour: '27/06/2021',
                matin: <Badge count={'X'} style={{ backgroundColor: 'red', color: '#FFF' }} />,
                aprem: <Badge count={'X'} style={{ backgroundColor: 'red', color: '#FFF' }} />,
                oh: <Badge count={'X'} style={{ backgroundColor: 'red', color: '#FFF' }} />,
            },
            {
                key: '2',
                jour: '09/07/2021',
                matin: <Badge count={'X'} style={{ backgroundColor: 'red', color: '#FFF' }} />,
                aprem: <Badge count={'✓'} style={{ backgroundColor: '#52c41a', color: '#FFF' }} />,
                oh: <Badge count={'✓'} style={{ backgroundColor: '#52c41a', color: '#FFF' }} />,
            },
            {
                key: '3',
                jour: '16/07/2021',
                matin: <Badge count={'X'} style={{ backgroundColor: 'red', color: '#FFF' }} />,
                aprem: <Badge count={'✓'} style={{ backgroundColor: '#52c41a', color: '#FFF' }} />,
                oh: <Badge count={'✓'} style={{ backgroundColor: '#52c41a', color: '#FFF' }} />,
            },
        ];

        const columnsEvaluationWeek = [
            { title: t('Evaluation'), key: 'evaluation', dataIndex: 'evaluation' },
            { title: t('Lundi'), key: 'lundi', dataIndex: 'lundi', align: 'center' },
            { title: t('Mardi'), key: 'mardi', dataIndex: 'mardi', align: 'center' },
            { title: t('Mercredi'), key: 'mercredi', dataIndex: 'mercredi', align: 'center' },
            { title: t('Jeudi'), key: 'jeudi', dataIndex: 'jeudi', align: 'center' },
            { title: t('Vendredi'), key: 'vendredi', dataIndex: 'vendredi', align: 'center' },
        ];
    

        const dataEvaluationWeek = [
            {
                key: '1',
                evaluation: <span style={tagStylePink}>{t("Quiz").toUpperCase()}</span>,
                lundi: <span>2/3 <Icon style={pinkIcon} type="check-circle" theme="filled" /></span>,
                mardi: <span>6/10 <Icon style={pinkIcon} type="check-circle" theme="filled" /></span>,
                mercredi: <span>- <Icon style={pinkIcon} type="check-circle" theme="filled" /></span>,
                jeudi: <span>2/3 <Icon style={pinkIcon} type="check-circle" theme="filled" /></span>,
                vendredi: <span>3/4 <Icon style={pinkIcon} type="check-circle" theme="filled" /></span>,
            },
            {
                key: '2',
                evaluation: <span style={tagStylePurple}>{t("Exercice").toUpperCase()}</span>,
                lundi: <span>89% <Icon style={purpleIcon} type="flag" theme="filled" /></span>,
                mardi: <span>34% <Icon style={purpleIcon} type="flag" theme="filled" /></span>,
                mercredi: <span>45% <Icon style={purpleIcon} type="flag" theme="filled" /></span>,
                jeudi: <span>69% <Icon style={purpleIcon} type="flag" theme="filled" /></span>,
                vendredi: <span>87% <Icon style={purpleIcon} type="flag" theme="filled" /></span>,
            },
            {
                key: '3',
                evaluation: <span style={tagStylePink}>{t("Projet").toUpperCase()}</span>,
                lundi: <span>89% <Icon style={pinkIcon} type="rocket" theme="filled" /></span>,
                mardi: <span>45% <Icon style={pinkIcon} type="rocket" theme="filled" /></span>,
                mercredi: <span>87% <Icon style={pinkIcon} type="rocket" theme="filled" /></span>,
                jeudi: <span>37% <Icon style={pinkIcon} type="rocket" theme="filled" /></span>,
                vendredi: <span>65% <Icon style={pinkIcon} type="rocket" theme="filled" /></span>,
            },
            {
                key: '4',
                evaluation: <span style={tagStylePurple}>{t("Challenge").toUpperCase()}</span>,
                lundi: <span>89% <Icon style={purpleIcon} type="star" theme="filled" /></span>,
                mardi: <span>34% <Icon style={purpleIcon} type="star" theme="filled" /></span>,
                mercredi: <span>45% <Icon style={purpleIcon} type="star" theme="filled" /></span>,
                jeudi: <span>69% <Icon style={purpleIcon} type="star" theme="filled" /></span>,
                vendredi: <span>87% <Icon style={purpleIcon} type="star" theme="filled" /></span>,
            },
            {
                key: '5',
                evaluation: <span style={tagStylePink}>{t("Flashcard").toUpperCase()}</span>,
                lundi: <span>89% <Icon style={pinkIcon} type="thunderbolt" theme="filled" /></span>,
                mardi: <span>45% <Icon style={pinkIcon} type="thunderbolt" theme="filled" /></span>,
                mercredi: <span>87% <Icon style={pinkIcon} type="thunderbolt" theme="filled" /></span>,
                jeudi: <span>37% <Icon style={pinkIcon} type="thunderbolt" theme="filled" /></span>,
                vendredi: <span>65% <Icon style={pinkIcon} type="thunderbolt" theme="filled" /></span>,
            },
            {
                key: '6',
                evaluation: <span style={{ color: '#000', fontWeight: '500' }}>{t("Évaluation finale").toUpperCase()}</span>,
                vendredi: <span style={{ color: '#000', fontWeight: '500' }}>79%</span>,
            },
        ];

        const columnsEvaluationBatch = [
            { title: t("S") + '1', key: 'S1', dataIndex: 'S1', align: 'center' },
            { title: t("S") + '2', key: 'S2', dataIndex: 'S2', align: 'center' },
            { title: t("S") + '3', key: 'S3', dataIndex: 'S3', align: 'center' },
            { title: t("S") + '4', key: 'S4', dataIndex: 'S4', align: 'center' },
            { title: t("S") + '5', key: 'S5', dataIndex: 'S5', align: 'center' },
            { title: t("S") + '6', key: 'S6', dataIndex: 'S6', align: 'center' },
            { title: t("S") + '7', key: 'S7', dataIndex: 'S7', align: 'center' },
            { title: t("S") + '8', key: 'S8', dataIndex: 'S8', align: 'center' },
        ];

        const dataEvaluationBatch = [
            {
                key: '1',
                S1: <span>62% <Icon style={purpleIcon} type="trophy" theme="filled" /></span>,
                S2: <span>59% <Icon style={purpleIcon} type="trophy" theme="filled" /></span>,
                S3: <span>33% <Icon style={purpleIcon} type="trophy" theme="filled" /></span>,
                S4: <span>98% <Icon style={purpleIcon} type="trophy" theme="filled" /></span>,
                S5: <span>56% <Icon style={purpleIcon} type="trophy" theme="filled" /></span>,
                S6: <span>78% <Icon style={purpleIcon} type="trophy" theme="filled" /></span>,
                S7: <span>29% <Icon style={purpleIcon} type="trophy" theme="filled" /></span>,
                S8: <span>38% <Icon style={purpleIcon} type="trophy" theme="filled" /></span>,
            },
        ];

        const dataRadarChartWeek = [
            { category: 'SC1', A: 120 },
            { category: 'SC2', A: 98 },
            { category: 'SC3', A: 86 },
            { category: 'SC4', A: 99 },
            { category: 'SC5', A: 85 },
        ];

        const dataRadarChartBatch = [
            { category: t('Frontend'), A: 120 },
            { category: t('Backend'), A: 98 },
            { category: t('Base de données'), A: 86 },
            { category: 'React/React Native', A: 99 },
            { category: 'Sprint produit', A: 85 },
            { category: t('Algorithmie'), A: 85 },
            { category: t('Sécurité'), A: 85 },
            { category: t('Workflow'), A: 85 },

        ];

        const dataAssiduiteWeek = [
            { day: t('Lundi'), nbrAbs: 100 },
            { day: t('Mardi'), nbrAbs: 75 },
            { day: t('Mercredi'), nbrAbs: 98 },
            { day: t('Jeudi'), nbrAbs: 25 },
            { day: t('Vendredi'), nbrAbs: 45 },
        ]

        const dataAssiduiteBatch = [
            { week: 'S1', nbrAbs: 100 },
            { week: 'S2', nbrAbs: 75 },
            { week: 'S3', nbrAbs: 98 },
            { week: 'S4', nbrAbs: 25 },
            { week: 'S5', nbrAbs: 45 },
            { week: 'S6', nbrAbs: 78 },
            { week: 'S7', nbrAbs: 65 },
            { week: 'S8', nbrAbs: 44 },
        ]

        const arianeSolutionChartStudent = [{ name: t("S") + '1', student: 10 }, { name: t("S") + '2', student: 8 }, { name: t("S") + '3', student: 13 }, { name: t("S") + '4', student: 18 }, { name: t("S") + '5', student: 22 }, { name: t("S") + '6', student: 26 }, { name: t("S") + '7', student: 18 }, { name: t("S") + '8', student: 12 }];
        const arianeSolutionChartTeacher = [{ name: t("S") + '1', student: 10, batch: 8 }, { name: t("S") + '2', student: 8, batch: 12 }, { name: t("S") + '3', student: 13, batch: 5 }, { name: t("S") + '4', student: 18, batch: 14 }, { name: t("S") + '5', student: 22, batch: 15 }, { name: t("S") + '6', student: 26, batch: 18 }, { name: t("S") + '7', student: 18, batch: 18 }, { name: t("S") + '8', student: 12, batch: 9 }];

        const solutionChartStudent = [{ name: t("S") + '1', student: 10 }, { name: t("S") + '2', student: 8 }, { name: t("S") + '3', student: 13 }, { name: t("S") + '4', student: 18 }, { name: t("S") + '5', student: 22 }, { name: t("S") + '6', student: 26 }, { name: t("S") + '7', student: 18 }, { name: t("S") + '8', student: 12 }];
        const solutionChartTeacher = [{ name: t("S") + '1', student: 10, batch: 8 }, { name: t("S") + '2', student: 8, batch: 12 }, { name: t("S") + '3', student: 13, batch: 5 }, { name: t("S") + '4', student: 18, batch: 14 }, { name: t("S") + '5', student: 22, batch: 15 }, { name: t("S") + '6', student: 26, batch: 18 }, { name: t("S") + '7', student: 18, batch: 18 }, { name: t("S") + '8', student: 12, batch: 9 }];


        return (
            <Row type="flex" style={{ flex: 1 }}>
                <Menu onClick={this.handleClick} selectedKeys={current} mode="horizontal" style={{ width: '100%', height: 'fit-content' }}>
                    <Menu.Item key="user">
                        <Icon type="user" />
                        {t("Compte")}
                    </Menu.Item>
                    <Menu.Item key="profil">
                        <Icon type="user" />
                        {t("Profil")}
                    </Menu.Item>
                    <Menu.Item key="dashboard">
                        <Icon type="dashboard" />
                        {t("Compétences")}
                    </Menu.Item>
                    {current == 'dashboard' &&
                        <Menu.Item disabled='true' style={{ marginLeft: '40vw' }}>
                            <Radio.Group onChange={this.onChangeMenu} defaultValue={2}>
                                <Radio value={1} >{t("Au batch")}</Radio>
                                <Radio value={2} >{t("À la semaine")}</Radio>
                                <Select defaultValue="S1" style={{ width: 120 }} onChange={this.handleChange} disabled={valueMenu == 1 ? true : false}>
                                    <Option value="S1">{t("Semaine")} 1</Option>
                                    <Option value="S2">{t("Semaine")} 2</Option>
                                    <Option value="S3">{t("Semaine")} 3</Option>
                                    <Option value="S4">{t("Semaine")} 4</Option>
                                    <Option value="S5">{t("Semaine")} 5</Option>
                                    <Option value="S6">{t("Semaine")} 6</Option>
                                    <Option value="S7">{t("Semaine")} 7</Option>
                                    <Option value="S8">{t("Semaine")} 8</Option>
                                </Select>
                            </Radio.Group>
                        </Menu.Item>
                    }
                </Menu>

                {
                    current === 'user' &&
                    <>
                        <Col span={13}>

                            <div style={{ margin: 'auto', marginTop: 65, width: '80%', backgroundColor: 'white', padding: 20, borderRadius: 8, }}>
                                <Row>
                                    <Col span={6}>
                                        <Avatar shape="square" size={100} src={`${global.URI_FRONTEND}/images/avatar/${user._id}.jpg`} icon="user" />
                                    </Col>
                                    {!this.state.edit ?
                                        <Col span={18}>
                                            {checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) && <Icon type="edit" onClick={() => this.setState({ edit: true })} style={{ fontSize: 20, color: '#F94A56', position: 'absolute', right: 0, top: 0 }} />}
                                            <h1 style={{ fontSize: 26, marginBottom: 5 }}>{user.fullname}</h1>
                                            <Badge count={user.type.charAt(0).toUpperCase() + user.type.slice(1)} style={{ backgroundColor: '#0E1763', fontSize: 14, }} />

                                            <div style={{ marginTop: 20 }}>
                                                {user.phone && <p style={text}><Icon type="phone" /> {user.phone.number ? user.phone.number : user.phone}</p>}
                                                <p style={text}><Icon type="mail" /> {user.email}</p>
                                                {user.address && <p style={text}><Icon type="home" /> {user.address.street + ', ' + user.address.zipcode + ' ' + user.address.city}</p>}
                                            </div>
                                        </Col>
                                        :
                                        <Col span={18}>
                                            <Form onSubmit={this.handleSubmit} layout='vertical' >
                                                <div style={{ display: 'flex' }}>
                                                    <Item style={input} name="firstname" >
                                                        {getFieldDecorator('firstname')(<Input placeholder={user.firstname} />)}
                                                    </Item>
                                                    <Item style={{ ...input, marginLeft: 15 }} name="lastname" >
                                                        {getFieldDecorator('lastname')(<Input placeholder={user.lastname} />)}
                                                    </Item>
                                                </div>
                                                <Item style={input} name="type" >
                                                    {getFieldDecorator('type')(
                                                        <Select placeholder={user.type.charAt(0).toUpperCase() + user.type.slice(1)} style={{ width: 140 }} >
                                                            <Option value="student">Student</Option>
                                                            <Option value="assistant">Teacher Assistant</Option>
                                                            <Option value="teacher">Teacher</Option>
                                                            <Option value="manager">Manager</Option>
                                                        </Select>)}
                                                </Item>
                                                <Item style={input} name="email" >
                                                    {getFieldDecorator('email')(<Input placeholder={user.email} addonBefore={<Icon type="mail" />} />)}
                                                </Item>
                                                <Item style={input} name="phone" >
                                                    {getFieldDecorator('phone')(<Input placeholder={user.phone ? user.phone.number : ''} addonBefore={<Icon type="phone" />} />)}
                                                </Item>
                                                <Item style={input} name="address" >
                                                    {getFieldDecorator('address')(<Input placeholder={user.address ? user.address.street + ', ' + user.address.zipcode + ' ' + user.address.city : ''} addonBefore={<Icon type="home" />} />)}
                                                </Item>
                                                <div style={{ display: 'flex' }}>
                                                    <Button type="primary" htmlType="submit">Ok</Button>
                                                    <Button onClick={() => this.setState({ edit: false })} style={{ marginLeft: 20 }}>{t("Annuler")}</Button>
                                                </div>
                                            </Form>
                                        </Col>
                                    }
                                </Row>
                            </div>

                        </Col>
                        <Col span={7} style={{ height: '100%' }}>

                            <div style={{ marginTop: 25, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <h3>{t("Mes rendez-vous").toUpperCase()}</h3>
                                {/* <List
                                    style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 10, paddingRight: 10, width: '100%', maxHeight: '65vh', overflow: 'scroll', marginBottom: 10, }}
                                    itemLayout="horizontal"
                                    dataSource={checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) ? messages : this.props.messages}
                                    renderItem={(item, i) => (
                                        <>
                                            <List.Item split={false} style={{ backgroundColor: 'white', borderRadius: 8, padding: 10, marginBottom: 10 }} key={i}>
                                                <List.Item.Meta
                                                    onClick={() => detailsIndex === null ? this.setState({ detailsIndex: i }) : this.setState({ detailsIndex: null })} style={{ cursor: 'pointer' }}
                                                    avatar={<Avatar size={40} src={`http://localhost:3001/images/avatar/${item.messages[0].sender._id}.jpg`} />}
                                                    title={<div style={{ display: 'flex', justifyContent: 'space-between' }}>{item.messages[0].sender.firstname}
                                                        <div><Badge count={item.type === 'rendez-vous' ? "RDV" : item.type === 'office hours' ? "Office Hours" : item.type.charAt(0).toUpperCase() + item.type.slice(1)} style={{ backgroundColor: '#f94a5648', color: '#f94a56', marginLeft: 5 }} />
                                                            {item.type !== 'conseils' ? <Badge count={moment(item.appointmentDate).format('DD-MM-YYYY')} style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: '#0c0fc9e0', fontWeight: 'bold' }} /> : null}
                                                        </div></div>}
                                                    description={<div>{item.messages[0].text}<br />
                                                        {item.messages[0].note && checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) ? <><span style={{ textDecoration: 'underline' }}>Note à l'équipe:</span> {item.messages[0].note}<br /></> : null}
                                                        <span style={{ fontSize: 'smaller' }}>envoyé le {moment(item.messages[0].date).format('DD-MM-YYYY')}</span></div>}
                                                />
                                                {checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) && <Icon type="form" onClick={() => this.setState({ visible: true, conversationId: item._id })} />}
                                            </List.Item>
                                            {i === detailsIndex ?
                                                item.messages.slice(1).map((msg, j) => {
                                                    if (msg.text || checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) {
                                                        return (
                                                            <List.Item style={{ marginTop: '-10px', backgroundColor: 'white', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, padding: 10, marginBottom: 10 }} key={j + i + 10}>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar size={40} src={`http://localhost:3001/images/avatar/${msg.sender._id}.jpg`} />}
                                                                    title={msg.sender.firstname}
                                                                    description={<div>{msg.text ? <>{msg.text}<br /></> : null}
                                                                        {msg.note && checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) ? <><span style={{ textDecoration: 'underline' }}>Note à l'équipe:</span> {msg.note}<br /></> : null}
                                                                        <span style={{ fontSize: 'smaller' }}>envoyé le {moment(msg.date).format('DD-MM-YYYY')}</span></div>}
                                                                />
                                                            </List.Item>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })
                                                : null}
                                        </>
                                    )}
                                /> */}
                                <List
                                    style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 10, paddingRight: 10, width: '100%', maxHeight: '65vh', overflow: 'scroll', marginBottom: 10, }}
                                    itemLayout="horizontal"
                                    dataSource={checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) ? messages : this.props.messages}
                                    renderItem={(item, i) => (
                                        <>
                                            <List.Item split={false} style={{ backgroundColor: 'white', borderRadius: 8, padding: 10, marginBottom: 10 }} key={i}>
                                                <List.Item.Meta
                                                    onClick={() => detailsIndex === null ? this.setState({ detailsIndex: i }) : this.setState({ detailsIndex: null })} style={{ cursor: 'pointer' }}
                                                    avatar={<Avatar size={40} src={`http://localhost:3001/images/avatar/${item.messages[0].sender._id}.jpg`} />}
                                                    title={<div style={{ display: 'flex', justifyContent: 'space-between' }}>Julie
                                                        <div><Badge count={item.type === 'rendez-vous' ? "RDV" : item.type === 'office hours' ? "Office Hours" : item.type.charAt(0).toUpperCase() + item.type.slice(1)} style={{ backgroundColor: '#f94a5648', color: '#f94a56', marginLeft: 5 }} />
                                                            {item.type !== 'conseils' ? <Badge count={moment(item.appointmentDate).format('DD-MM-YYYY')} style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: '#0c0fc9e0', fontWeight: 'bold' }} /> : null}
                                                        </div></div>}
                                                    description={<div>{item.messages[0].text}<br />
                                                        {item.messages[0].note && checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) ? <><span style={{ textDecoration: 'underline' }}>{t("Note à l'équipe")}:</span> {item.messages[0].note}<br /></> : null}
                                                        <span style={{ fontSize: 'smaller' }}>{t("envoyé le")} {moment(item.messages[0].date).format('DD-MM-YYYY')}</span></div>}
                                                />
                                                {checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) && <Icon type="form" onClick={() => this.setState({ visible: true, conversationId: item._id })} />}
                                            </List.Item>
                                            {i === detailsIndex ?
                                                item.messages.slice(1).map((msg, j) => {
                                                    if (msg.text || checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) {
                                                        return (
                                                            <List.Item style={{ marginTop: '-10px', backgroundColor: 'white', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, padding: 10, marginBottom: 10 }} key={j + i + 10}>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar size={40} src={`http://localhost:3001/images/avatar/${msg.sender._id}.jpg`} />}
                                                                    title={msg.sender.firstname}
                                                                    description={<div>{msg.text ? <>{msg.text}<br /></> : null}
                                                                        {msg.note && checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) ? <><span style={{ textDecoration: 'underline' }}>{t("Note à l'équipe")}:</span> {msg.note}<br /></> : null}
                                                                        <span style={{ fontSize: 'smaller' }}>{t("envoyé le")} {moment(msg.date).format('DD-MM-YYYY')}</span></div>}
                                                                />
                                                            </List.Item>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })
                                                : null}
                                        </>
                                    )}
                                />
                                {checkRank(this.props.currentUser, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] }) && <Button size='small' style={buttonStyle} onClick={this.showModalRDV}>+ {t("Nouveau message")}</Button>}
                            </div>

                        </Col>
                    </>
                }

                {
                    current === 'profil' &&
                    <p>{t("Bonjour")}</p>
                }

                {
                    current === 'dashboard' &&
                    <div style={content}>

                        <Col span={6} style={contentPanel}>
                            <div style={contentTitle}>{t("Mes solutions")} Ariane</div>

                            {valueMenu == 1 ?
                                user.type == 'student' ?
                                    <SimpleBar style={{ height: '25vh' }}>
                                        <BarChart
                                            width={300}
                                            height={180}
                                            data={arianeSolutionChartStudent}
                                            margin={{
                                                top: 15,
                                                right: 0,
                                                left: -8,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="student" fill="#E94653" />
                                        </BarChart>
                                    </SimpleBar>

                                    :

                                    <SimpleBar style={{ height: '25vh' }}>
                                        <BarChart
                                            width={300}
                                            height={180}
                                            data={arianeSolutionChartTeacher}
                                            margin={{
                                                top: 15,
                                                right: 0,
                                                left: -8,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="student" fill="#E94653" />
                                            <Bar dataKey="batch" fill="#E94653" opacity={0.6} />
                                        </BarChart>
                                    </SimpleBar>

                                :

                                user.type == 'student' ?

                                    <SimpleBar style={{ height: '20vh' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', margin: '10px' }}>

                                            <div style={{ height: '120px', width: '200px', background: 'linear-gradient(90deg, rgba(233,73,86,1) 60%, rgba(235,104,115,1) 87%)', color: 'white', margin: 2, marginRight: 10, borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', }}>
                                                <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8', textAlign: 'center' }}>{t("Depuis le début de la semaine")}</span>
                                                <span style={{ fontSize: '42px', fontWeight: '500' }}>14</span>
                                                <span style={{ fontSize: '12px', fontWeight: '400' }}>{t("solutions débloquées")}</span>
                                            </div>
                                            <div style={{ height: '120px', width: '200px', background: 'linear-gradient(90deg, rgba(237,131,133,1) 0%, rgba(242,181,109,1) 52%)', color: 'white', margin: 2, marginLeft: 10, borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', }}>
                                                <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8' }}>{t("Aujourd'hui")}</span>
                                                <span style={{ fontSize: '42px', fontWeight: '500' }}>3</span>
                                                <span style={{ fontSize: '12px', fontWeight: '400' }}>{t("solutions débloquées")}</span>
                                            </div>
                                        </Row>
                                    </SimpleBar>

                                    :

                                    <SimpleBar style={{ height: '25vh' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', margin: '10px' }}>

                                            <div style={{ height: '160px', width: '260px', background: 'linear-gradient(90deg, rgba(233,73,86,1) 60%, rgba(235,104,115,1) 87%)', color: 'white', margin: 2, marginRight: 10, borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', }}>
                                                <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8', textAlign: 'center' }}>{t("Depuis le début de la semaine")}</span>
                                                <span style={{ fontSize: '42px', fontWeight: '500' }}>14</span>
                                                <span style={{ fontSize: '12px', fontWeight: '400' }}>{t("solutions débloquées")}</span>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginBottom: '6px' }}>
                                                    <div style={{ border: '2px solid white', color: 'white', height: '30px', width: '30px', marginLeft: '4px', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> + 3</div>
                                                    <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8', marginLeft: '4px' }}>{t("vs")} batch</span>
                                                </div>

                                            </div>
                                            <div style={{ height: '160px', width: '260px', background: 'linear-gradient(90deg, rgba(237,131,133,1) 0%, rgba(242,181,109,1) 52%)', color: 'white', margin: 2, marginLeft: 10, borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', }}>
                                                <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8' }}>{t("Aujourd'hui")}</span>
                                                <span style={{ fontSize: '42px', fontWeight: '500' }}>3</span>
                                                <span style={{ fontSize: '12px', fontWeight: '400' }}>{t("solutions débloquées")}</span>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginBottom: '6px' }}>
                                                    <div style={{ border: '2px solid white', color: 'white', height: '30px', width: '30px', marginLeft: '4px', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> - 2</div>
                                                    <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8', marginLeft: '4px' }}>{t("vs")} batch</span>
                                                </div>
                                            </div>
                                        </Row>
                                    </SimpleBar>
                            }

                            <div style={contentTitle}>{t("Mes solutions")} teacher</div>

                            {valueMenu == 1 ?
                                user.type == 'student' ?
                                    <SimpleBar style={{ height: '25vh' }}>
                                        <BarChart
                                            width={300}
                                            height={180}
                                            data={solutionChartStudent}
                                            margin={{
                                                top: 15,
                                                right: 0,
                                                left: -20,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="student" fill="#5247ea" />
                                        </BarChart>
                                    </SimpleBar>

                                    :

                                    <SimpleBar style={{ height: '25vh' }}>
                                        <BarChart
                                            width={300}
                                            height={180}
                                            data={solutionChartTeacher}
                                            margin={{
                                                top: 15,
                                                right: 0,
                                                left: -20,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="student" fill="#5247ea" />
                                            <Bar dataKey="batch" fill="#5247ea" opacity={0.6} />
                                        </BarChart>
                                    </SimpleBar>


                                : user.type == 'student' ?

                                    <SimpleBar style={{ height: '20vh' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', margin: '10px' }}>
                                            <div style={{ height: '120px', width: '200px', background: 'linear-gradient(90deg, rgba(46,68,245,1) 27%, rgba(82,71,234,1) 75%)', color: 'white', margin: 2, marginRight: 10, borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', }}>
                                                <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8', textAlign: 'center' }}>{t("Depuis le début de la semaine")}</span>
                                                <span style={{ fontSize: '42px', fontWeight: '500' }}>5</span>
                                                <span style={{ fontSize: '12px', fontWeight: '400' }}>{t("help requests")}</span>
                                            </div>
                                            <div style={{ height: '120px', width: '200px', background: 'linear-gradient(90deg, rgba(82,71,234,1) 43%, rgba(133,126,238,0.7259278711484594) 87%)', color: 'white', margin: 2, marginLeft: 10, borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', }}>
                                                <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8' }}>{t("Aujourd'hui")}</span>
                                                <span style={{ fontSize: '42px', fontWeight: '500' }}>2</span>
                                                <span style={{ fontSize: '12px', fontWeight: '400' }}>{t("help requests")}</span>
                                            </div>
                                        </Row>
                                    </SimpleBar>

                                    :

                                    <SimpleBar style={{ height: '25vh' }}>
                                        <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', margin: '10px' }}>
                                            <div style={{ height: '160px', width: '260px', background: 'linear-gradient(90deg, rgba(46,68,245,1) 27%, rgba(82,71,234,1) 75%)', color: 'white', margin: 2, marginRight: 10, borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', }}>
                                                <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8', textAlign: 'center' }}>{t("Depuis le début de la semaine")}</span>
                                                <span style={{ fontSize: '42px', fontWeight: '500' }}>5</span>
                                                <span style={{ fontSize: '12px', fontWeight: '400' }}>{t("help requests")}</span>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginBottom: '6px' }}>
                                                    <div style={{ border: '2px solid white', color: 'white', height: '30px', width: '30px', marginLeft: '4px', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> + 1</div>
                                                    <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8', marginLeft: '4px' }}>{t("vs")} batch</span>
                                                </div>
                                            </div>
                                            <div style={{ height: '160px', width: '260px', background: 'linear-gradient(90deg, rgba(82,71,234,1) 43%, rgba(133,126,238,0.7259278711484594) 87%)', color: 'white', margin: 2, marginLeft: 10, borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', }}>
                                                <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8' }}>{t("Aujourd'hui")}</span>
                                                <span style={{ fontSize: '42px', fontWeight: '500' }}>2</span>
                                                <span style={{ fontSize: '12px', fontWeight: '400' }}>{t("help requests")}</span>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginBottom: '6px' }}>
                                                    <div style={{ border: '2px solid white', color: 'white', height: '30px', width: '30px', marginLeft: '4px', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> + 2</div>
                                                    <span style={{ fontSize: '12px', fontWeight: '300', opacity: '0.8', marginLeft: '4px' }}>{t("vs")} batch</span>
                                                </div>
                                            </div>
                                        </Row>
                                    </SimpleBar>
                            }

                            <div style={{ ...contentTitle, marginTop: 20 }}>{t("Mon ressenti")}</div>
                            {valueMenu == 1 ?
                                <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', margin: '10px' }}>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/happy.png`} alt={'happy face'} />
                                        <div marginTop='4vh'>{t("S")}1</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/very_happy.png`} alt={'very_happy face'} />
                                        <div marginTop='4vh'>{t("S")}2</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/happy.png`} alt={'happy face'} />
                                        <div marginTop='4vh'>{t("S")}3</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/bad.png`} alt={'bad face'} />
                                        <div marginTop='4vh'>{t("S")}4</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/neutral.png`} alt={'neutral face'} />
                                        <div marginTop='4vh'>{t("S")}5</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/happy.png`} alt={'happy face'} />
                                        <div marginTop='4vh'>{t("S")}6</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/bad.png`} alt={'bad face'} />
                                        <div marginTop='4vh'>{t("S")}7</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/neutral.png`} alt={'neutral face'} />
                                        <div marginTop='4vh'>{t("S")}8</div>
                                    </Col>
                                </Row>
                                :
                                <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', margin: '10px' }}>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/happy.png`} alt={'happy face'} />
                                        <div marginTop='4vh'>{t("Lundi")}</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/very_happy.png`} alt={'very_happy face'} />
                                        <div marginTop='4vh'>{t("Mardi")}</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/happy.png`} alt={'happy face'} />
                                        <div marginTop='4vh'>{t("Mercredi")}</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/bad.png`} alt={'bad face'} />
                                        <div marginTop='4vh'>{t("Jeudi")}</div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img height='25px' src={`/images/emojis/neutral.png`} alt={'neutral face'} />
                                        <div marginTop='4vh'>{t("Vendredi")}</div>
                                    </Col>
                                </Row>}
                        </Col>

                        <Col span={10} style={{ ...contentPanel, marginLeft: '3vw' }}>
                            <div style={contentTitle}>{t("Mon arbre de compétences")}</div>
                            {valueMenu == 1 ? '' : <div style={{ letterSpacing: 0.7, fontSize: 13, fontWeight: 700, color: '#272727' }}>React/React Native</div>}
                            <div className="vertical" ref={this.lectureDiv} style={{ ...borders, backgroundColor: '#F6F7FB' }}>
                                <div style={{ height: '35vh', backgroundColor: '#F6F7FB', margin: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <RadarChart cx={250} cy={140} outerRadius={120} width={500} height={300} data={valueMenu == 1 ? dataRadarChartBatch : dataRadarChartWeek}>
                                        <PolarGrid gridType='circle' />
                                        <PolarAngleAxis dataKey="category" />
                                        <PolarRadiusAxis angle={30} domain={[0, 150]} />
                                        <Radar name="Mike" dataKey="A" stroke="#5247ea" strokeWidth={2} fill="#8884d8" fillOpacity={0.4} />
                                    </RadarChart>
                                </div>
                            </div>

                            <div style={contentTitle}>{t("Mes évaluations")}</div>
                            <div className="vertical" ref={this.lectureDiv} style={{ ...borders, backgroundColor: '#F6F7FB' }}>
                                <SimpleBar style={{ height: '30vh', backgroundColor: '#F6F7FB' }}>
                                    {valueMenu == 1 ? <Table columns={columnsEvaluationBatch} dataSource={dataEvaluationBatch} pagination={false} /> : <Table rowClassName={(record, index) => index == 5 ? tablerowbackground : null} columns={columnsEvaluationWeek} dataSource={dataEvaluationWeek} pagination={false} />}
                                </SimpleBar>
                            </div>
                        </Col>

                        <Col span={6} style={{ ...contentPanel, marginLeft: '3vw' }}>
                            <div style={contentTitle}>{t("Mon assiduité")}</div>
                            <div className="vertical" ref={this.lectureDiv} style={{ ...borders, backgroundColor: '#F6F7FB' }}>
                                <SimpleBar style={{ height: '30vh', backgroundColor: '#F6F7FB' }}>
                                    <LineChart width={360} height={180} data={valueMenu == 1 ? dataAssiduiteBatch : dataAssiduiteWeek} margin={{ top: 15, right: 0, left: -30, bottom: 0 }}>
                                        <Line type="monotone" dataKey="nbrAbs" stroke="#5247ea" strokeWidth={3} />
                                        <CartesianGrid stroke="#ccc" />
                                        <XAxis dataKey={valueMenu == 1 ? "week" : "day"} />
                                        <YAxis />
                                    </LineChart>
                                </SimpleBar>
                            </div>

                            <div style={{ height: '60px', width: '100%', background: 'linear-gradient(90deg, rgba(43,29,232,1) 23%, rgba(124,72,197,1) 51%, rgba(233,70,83,1) 97%)', color: 'white', margin: 2, marginRight: 10, borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-end', }}>
                                <span style={{ fontSize: '12px', fontWeight: '500' }}>{valueMenu == 1 ? t('Depuis le début du batch') : t('Depuis le début de la semaine')}<span style={{ fontSize: '42px', margin: 10 }}> {valueMenu == 1 ? '74%' : '68%'}</span></span>
                            </div>


                            <div style={contentTitle}>{t("Mes absences")}</div>
                            <div className="vertical" ref={this.lectureDiv} style={borders}>
                                <SimpleBar style={{ height: '29vh', backgroundColor: '#F6F7FB' }}>
                                    <Table columns={columns} dataSource={data} pagination={false} />
                                </SimpleBar>
                            </div>
                        </Col>
                    </div>
                }



                <Drawer
                    visible={visible}
                    width={400}
                    onClose={this.handleCancel}
                >
                    <div style={{ paddingBottom: 40 }}>
                        <h3 style={{ textAlign: 'center' }}>{t("Nouveau message")}</h3>
                        <p style={subtitle}>{t("Message")}:</p>
                        <TextArea placeholder={t("Écrivez votre message")} autoSize={{ minRows: 3 }} value={appointment} onChange={(e => this.setState({ appointment: e.target.value }))} />
                        <p style={subtitle}>Note à l'équipe:</p>
                        <TextArea placeholder={("Écrivez un message privé")} autoSize={{ minRows: 3 }} value={privateAppointment} onChange={(e => this.setState({ privateAppointment: e.target.value }))} />
                        {logError && <Alert message="Error" type="error" showIcon />}
                        <Button type='primary' style={{ position: 'absolute', right: 22, bottom: 15 }} onClick={conversationId ? this.Respond : this.Send}>{t("Envoyer")}</Button>
                    </div>
                </Drawer>

                <Drawer
                    visible={visibleRDV}
                    width={400}
                    onClose={this.handleCancelRDV}
                >
                    <div style={{ paddingBottom: 40 }}>
                        <h3 style={{ textAlign: 'center' }}>{t("Nouveau message")}</h3>
                        <p style={subtitle}>{t("Type de message")}:</p>
                        <Radio.Group onChange={this.onChange} value={this.state.value}>
                            <Radio style={radioStyle} value={'rendez-vous'}>
                                {t("Rendez-vous")}
                            </Radio>
                            <Radio style={radioStyle} value={'office hours'}>
                                Office Hours
                            </Radio>
                            <Radio style={radioStyle} value={'event'}>
                                {t("Événement")}
                            </Radio>
                            <Radio style={radioStyle} value={'conseils'}>
                                {t("Conseils")}
                            </Radio>
                        </Radio.Group>
                        {this.state.value !== 'conseils' && <><p style={subtitle}>{t("Date et heure")}:</p>
                            <DatePicker showTime value={appointmentDate} onChange={(value => this.setState({ appointmentDate: value }))} /></>}
                        <p style={subtitle}>{t("Message")}:</p>
                        <TextArea placeholder={t("Écrivez votre message")} autoSize={{ minRows: 3 }} value={appointment} onChange={(e => this.setState({ appointment: e.target.value }))} />
                        <p style={subtitle}>{t("Note à l'équipe")}:</p>
                        <TextArea placeholder={t("Écrivez un message privé")} autoSize={{ minRows: 3 }} value={privateAppointment} onChange={(e => this.setState({ privateAppointment: e.target.value }))} />
                        {logErrorRDV && <Alert message="Error" type="error" showIcon />}
                        <Button type='primary' style={{ position: 'absolute', right: 22, bottom: 15 }} onClick={this.SendAppointment}>{t("Envoyer")}</Button>
                    </div>
                </Drawer>

            </Row >)
    }
}

function mapStateToProps({ user, batch, messages }) {
    return { currentUser: user, batch, messages };
}

function mapDispatchToProps(dispatch) {
    return {
        setDay: function (day) {
            dispatch({ type: 'changeDay', day });
        },
        addMessage: function (message) {
            dispatch({ type: 'addMessage', message });
        },
    }
}

const WrappedUserInfo = Form.create({ name: 'edit_user' })(UserInfo);
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WrappedUserInfo));

const styles = {
    content: { margin: 10, marginTop: 0, flex: 1 },
    text: { fontSize: '14px', fontWeight: 300, fontFamily: 'roboto' },
    borders: { backgroundColor: 'white', marginTop: 7, borderRadius: 3, flex: 1, maxHeight: 500 },
    contentSectionCalendar: { padding: '20px 25px 10px', width: 'calc(100% - 350px)', display: 'flex', flexDirection: 'column' },
    contentPanel: { marginBottom: 10, display: 'flex', flexDirection: 'column', fontFamily: 'roboto', fontWeight: 300 },
    contentTitle: { letterSpacing: 0.7, fontSize: 13, fontWeight: 700, textTransform: 'uppercase', color: '#272727', marginTop: '3vh', marginBottom: '2vh' },
    input: { marginBottom: 15 },
    buttonStyle: { color: '#F94A56', border: '1.5px solid #F94A56', fontWeight: '500', backgroundColor: 'transparent', alignSelf: 'flex-end' },
    messageStyle: { backgroundColor: 'white', height: 220, width: 400, overflow: 'scroll', borderRadius: 12, marginTop: 10, marginBottom: 20, border: '1px solid rgb(231, 232, 237)' },
    subtitle: { marginTop: 15, marginBottom: 5 },
    radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    },
    tagStylePurple: { backgroundColor: "#EBE9FE", color: '#5247ea', width: 80, height: 22, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', margin: '0 10px 0 5px', borderRadius: 12, fontSize: 12, textTransform: 'uppercase', letterSpacing: 0.44 },
    tagStylePink: { backgroundColor: "#FAE7E7", color: "#F94A56", width: 80, height: 22, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', margin: '0 10px 0 5px', borderRadius: 12, fontSize: 12, textTransform: 'uppercase', letterSpacing: 0.44 },
    purpleIcon: { color: "#5247ea", opacity: 0.6 },
    pinkIcon: { color: "#F94A56", opacity: 0.6 },
    tablerowbackground: { backgroundColor: '#F94A56', color: 'white' }
}