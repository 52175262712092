import React, { Component } from 'react';

export default class Spacer extends Component {
  render() {
    return (
      <div>
        {
          this.props.vh
          ? <div style={{marginBottom: this.props.space + 'vh'}}/>
          : <div style={{marginBottom: this.props.space}}/>
        }
      </div>
    )
  }
}
