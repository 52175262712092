import React, { Component } from 'react';
import { message, Tag, Button, Select, Input, /*Radio,*/ Icon } from 'antd';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;
const { success, error } = message;
// const { Group, Button: RadioButton } = Radio;

// const optionsList = [
//   { label: 'Recrutement', value: 'Recrutement' },
//   { label: 'Recherche', value: 'Recherche' }
// ];

class Recruitment extends Component {
  state = {
    tags: [],
    text: '',
    title: 'Recrutement'
  }

  componentDidMount() {
    const { announcement: { title = "", text = "", tags = [] } } = this.props;

    this.setState({ title, text, tags });
  }

  onSave = () => {
    const { setStateInParent, _id } = this.props;
    const { title, text, tags } = this.state;

    fetch(`${global.URI_BACKEND}/user/profile/${_id}/announcement`, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title, text, tags })
    }).then(res => res.json()).then(data => {
      const { announcement } = data;

      setStateInParent({ announcement, editAnnouncement: false });
    });
  }

  onEdit = () => {
    const { onEditAnnouncement } = this.props;

    onEditAnnouncement();
  }

  onTitleChange = ({ target: { value } }) => {
    this.setState({ title: value });
  }

  onTechChange = tags => {
    this.setState({ tags });
  }

  onTextChange = ({ target: { value } }) => {
    this.setState({ text: value });
  }

  onCancel = () => {
    const { title, text, tags, setStateInParent } = this.props;

    this.setState({ title, text, tags });
    setStateInParent({ editAnnouncement: false });
  }

  refreshAnnouncement = () => {
    const { _id } = this.props;
    fetch(`${global.URI_BACKEND}/user/profile/${_id}/announcement/refresh`, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' }
    }).then(res => res.json()).then(data => {
      if (data.success) {
        success("Votre annonce a été rafraichie avec succès !");
      } else {
        error("Une erreur est survenue lors du rafraichissement. Veuillez réessayer plus tard.");
      }
    });
  }

  deleteAnnouncement = () => {
    this.setState({ title: '', text: '', tags: [] });
  }

  render() {
    const { box, titleStyle, description, editIcon } = styles;
    const { editAnnouncement, selfProfile, t } = this.props;
    const { /*title,*/ text, tags } = this.state;

    const notEmpty = text?.length > 0 || tags?.length > 0;

    const technologies = {
      'HTML': 'orange',
      'JavaScript': 'gold',
      'JS': 'gold',
      'Node': 'green',
      'Node.js': 'green',
      'NodeJS': 'green',
      'MongoDB': 'green',
      'Mongoose': 'green',
      'React': 'cyan',
      'React.js': 'cyan',
      'ReactJS': 'cyan',
      'CSS': 'blue',
      'BDD': 'red',
      'Services et librairies': 'blue'
    }

    const specialities = tags.map(e => {
      const color = technologies[e] || null;
      return <Tag key={`specialities-${e}`} className="recruitment-technologies-tag" color={color}>{e}</Tag>
    });

    // const titleOptions = optionsList.map(e => <RadioButton key={e.label} value={e.value}>{e.value}</RadioButton>);

    return <div style={box} id="recruitment">
      <div className="recruitment__row">
        <div style={titleStyle}>
          {/* {
            editAnnouncement
              ? <Group value={title} buttonStyle="solid" onChange={this.onTitleChange}>{titleOptions}</Group>
              : <div>{title}</div>
          } */}
          <div>{t("Recrutement")}</div>

          {
            selfProfile && <span style={{ position: 'absolute', right: 0, top: 0 }}>
              { !editAnnouncement ? <Icon style={editIcon} type="edit" className="profile--edit__button" onClick={this.onEdit} /> : <div></div>}
              {(notEmpty && !editAnnouncement) && <Icon style={editIcon} className="profile--edit__button" type="redo" onClick={this.refreshAnnouncement} />}
              {(notEmpty && editAnnouncement) && <Icon style={editIcon} className="profile--edit__button" type="minus-circle-o" onClick={this.deleteAnnouncement} />}
            </span>
          }
        </div>
      </div>
      {
        (editAnnouncement || notEmpty) && <div className="recruitment__row">
          <div className="technologies__row">
            {
              editAnnouncement
                ? <Select style={{ width: '100%' }} mode="multiple" placeholder={t("Spécialités")} onChange={this.onTechChange} value={tags}>
                  <Option value="HTML">HTML</Option>
                  <Option value="JS">JS</Option>
                  <Option value="CSS">CSS</Option>
                  <Option value="React">React</Option>
                  <Option value="BDD">{t("BDD")}</Option>
                  <Option value="Services et librairies">{t("Services et librairies")}</Option>
                  <Option value="Node.js">Node.js</Option>
                  <Option value="Product Manager">Product Manager</Option>
                  <Option value="PO">PO</Option>
                </Select>
                : specialities
            }
          </div>
          {
            (editAnnouncement || text) && <div style={description}>
              {
                editAnnouncement
                  ? <TextArea rows={8} id="description" placeholder={t("Description")} value={text} onChange={this.onTextChange} />
                  : <div>{text}</div>
              }
            </div>
          }
          {
            editAnnouncement && <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button type="primary" ghost onClick={this.onCancel}>{t("Annuler")}</Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={this.onSave}>{t("Enregistrer")}</Button>
              </div>
            </div>
          }
        </div>
      }
    </div>
  }
}

export default withTranslation()(Recruitment)

const styles = {
  box: { borderRadius: 9, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', backgroundColor: 'white', marginBottom: 27, position: 'relative' },
  titleStyle: { fontSize: 17, fontWeight: 'bold', letterSpacing: .82, color: '#414141', position: 'relative' },
  description: { marginTop: 20, color: '#8f8f8f', letterSpacing: .01, fontWeight: 300, fontSize: 14 },
  editIcon: { color: '#000000', opacity: 0.65, fontSize: 24, cursor: 'pointer' }
}