import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Switch, Icon, Checkbox } from 'antd';
import { Link } from 'react-router-dom';

import arrow from '../../images/arrow.svg';
import locked from '../../images/locked.svg';

class Day extends Component {

  isLocked = true;

  display = () => {
    this.props.openMenuItem(
      !this.props.tracks[this.props.trackPosition].projects[this.props.projectPosition].open,
      'project',
      this.props.projectId,
      this.props.trackPosition
    );
  }

  //is played when a switch has been pressed. Recolting ids and fetching to backend to modify active parameter
  switchChange = (checked, stepPosition) => {
    var datas = {
      'batchId': this.props.batchId,
      'stepId': this.props.tracks[this.props.trackPosition].projects[this.props.projectPosition].days[this.props.dayPosition].steps[stepPosition]._id,
      'enable': checked
    }

    fetch(global.URI_BACKEND + '/batch/progression', {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datas)
    }).then(res => res.json()).then(data => {
      if (data.success) {
        this.props.activeLiveCode(data.batch)
        if (global.SOCKET) {
          global.SOCKET.emit('refreshBatch', { batchId: data.batch.id });
        }
      }
    });
  }

  //enable Livecode in Slide.js -> ressource
  CheckboxChange = (event, stepPosition, part) => {
    
    var datas = {
      'batchId': this.props.batchId,
      'stepId': this.props.tracks[this.props.trackPosition].projects[this.props.projectPosition].days[this.props.dayPosition].steps[stepPosition]._id,
      'enableLiveCode': event.target.checked
    }
    fetch(global.URI_BACKEND + '/batch/progression', {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datas)
    }).then(res => res.json()).then(data => {
      if (data.success) {
        this.props.activeLiveCode(data.batch)

        global.SOCKET.emit('refreshBatch', { batchId: data.batch.id, });
      }
    });
  }

  checkBoxChallenge = (event, stepPosition, part) => {
    var datas = {
      'batchId': this.props.batchId,
      'stepId': this.props.tracks[this.props.trackPosition].projects[this.props.projectPosition].days[this.props.dayPosition].steps[stepPosition]._id,
      'enableLiveCode': event.target.checked
    }
  }

  render() {
    var isStudent = true;
    if (this.props.userData.type === 'teacher') {
      isStudent = false
    }
    let steps = this.props.steps
      ? this.props.steps.map((step, i) => {
        let isStepEnabled = false;
        let Livecode = false;

        if (this.props.batchProgression[step._id]) {
          if (this.props.batchProgression[step._id].enable) {
            isStepEnabled = true;
          }
          if (this.props.batchProgression[step._id].enableLiveCode) {
            Livecode = true;
          }
        }

        let iconType;
        if (step.stepType === 'quizz') {
          iconType = 'question-circle'
        } else if (step.stepType === 'exercise') {
          iconType = 'code-o'
        } else if (step.stepType_v2 === 'project') {
          iconType = 'build';
        } else if (step.stepType_v2 === 'challenge') {
          iconType = 'experiment';
        } else if (step.stepType === "checkpoint") {
          iconType = 'check-circle';
        } else if (step.stepType === "ressource") {
          iconType = 'link';
        } else if (step.stepType === "slide") {
          iconType = 'read';
        }
        // user progression check
        let styleIcon = styles.readyToStart;
        if (this.props.userData.type !== 'student') {
          styleIcon = styles.inProgress;
        } else if (this.props.userProgression) {
          for (let stepUserProgression of this.props.userProgression) {
            if (stepUserProgression.sectionId === step._id) {
              if (stepUserProgression.status === 'finished') {
                if (stepUserProgression.sectionType === "quizz" && stepUserProgression.success >= 33 && stepUserProgression.success < 66) {
                  styleIcon = styles.finishedAverage;
                } else if (stepUserProgression.sectionType === "quizz" && stepUserProgression.success < 33) {
                  styleIcon = styles.finishedBad;
                } else {
                  styleIcon = styles.finished
                }
              } else if (stepUserProgression.status === 'in progress') {
                styleIcon = styles.inProgress
              } else {
              }
            }
          }
        } else if (step.stepType === "checkpoint" || step.stepType === "ressource") {
          styleIcon = (isStepEnabled) ? styles.finished : styles.inProgress;
        }

        return (
          <Row type="flex" align="middle" className="rowStep" key={i}>
            <div style={styles.subLine}></div>
            <Col offset={7} span={24} style={styles.containerStep}>
              {
                isStepEnabled || !isStudent
                  ? <div style={styles.stepContainer}>
                    <Icon type={iconType} style={styleIcon} />
                    {
                      !isStudent
                        ? <Switch size="small" style={styles.switch} onChange={(checked) => this.switchChange(checked, i)} defaultChecked={isStepEnabled} />
                        : null
                    }
                    <Link style={styles.color} to={`/batch/${step.stepType}/${step.stepId}`}>
                      {
                        step.text
                          ? step.text
                          : null
                      }
                    </Link>
                    {
                      !isStudent && step.stepType_v2 && step.stepType_v2 === "project"
                        ? <Checkbox defaultChecked={Livecode} style={{ marginLeft: 15, fontSize: 10 }} onChange={(checked) => this.CheckboxChange(checked, i, 'livecode-part')}>Livecode</Checkbox>
                        : !isStudent && step.stepType_v2 && step.stepType_v2 === "challenge"
                          ? <Checkbox defaultChecked={Livecode} style={{ marginLeft: 15, fontSize: 10 }} onChange={(checked) => this.CheckboxChange(checked, i, 'livecode-part')}>Livecode</Checkbox>
                          : !isStudent && step.stepType_v2 && step.stepType_v2 === "more"
                            ? <Checkbox defaultChecked={Livecode} style={{ marginLeft: 15, fontSize: 10 }} onChange={(checked) => this.CheckboxChange(checked, i, 'livecode-part')}>Livecode</Checkbox>
                            : !isStudent && step.stepType_v2 && step.stepType_v2 === "quizz"
                              ? <Checkbox defaultChecked={Livecode} style={{ marginLeft: 15, fontSize: 10 }} onChange={(checked) => this.CheckboxChange(checked, i, 'livecode-part')}>Livecode</Checkbox>
                              : !isStudent && step.stepType_v2 && step.stepType_v2 === "exercise"
                                ? <Checkbox defaultChecked={Livecode} style={{ marginLeft: 15, fontSize: 10 }} onChange={(checked) => this.CheckboxChange(checked, i, 'livecode-part')}>Livecode</Checkbox>

                                : null
                    }

                  </div>
                  : <div style={styles.stepContainerDisable}>
                    <Icon style={styleIcon} type="lock" /> {step.text}
                  </div>
              }
            </Col>
          </Row>

        )
      })
      : null

    return (
      <Row>
        <div style={styles.subLine}></div>
        <Row type="flex" justify="space-around" align="middle" style={styles.rowDay} >
          <Col span={12}>
            <h4 onClick={!this.isLocked
              ? () => this.display()
              : null} style={styles.subDay}>{
                this.props.title
                  ? this.props.title
                  : null
              }</h4>
          </Col>
        </Row>
        <Col span={24}>
          {steps}
        </Col>
        <div style={styles.subLine}></div>
      </Row>
    );
  }
}

class Project extends Component {

  isLocked = true;

  display = () => {
    this.props.openMenuItem(
      !this.props.tracks[this.props.trackPosition].projects[this.props.projectPosition].open,
      'project',
      this.props.projectId,
      this.props.trackPosition
    );
  }

  render() {
    this.isLocked = this.props.days.length > 0
      ? false
      : null
    let days = this.props.days
      ? this.props.days.map((day, i) => {

        var userProgression;
        if (this.props.userProgression) {
          for (let dayUserProgression of this.props.userProgression) {
            if (dayUserProgression.dayId === day._id) {
              userProgression = dayUserProgression.sections;
              break;
            }
          }
        }

        return <Day openMenuItem={this.props.openMenuItem}
          key={i}
          batchId={this.props.batchId}
          stackId={this.props.stackId}
          trackPosition={this.props.trackPosition}
          projectPosition={this.props.projectPosition}
          dayPosition={i}
          title={day.title}
          steps={day.steps}
          batchProgression={this.props.batchProgression}
          userProgression={userProgression}
          tracks={this.props.tracks}
          userData={this.props.userData}
          activeLiveCode={this.props.activeLiveCode}
          className="TOTOTOTOTOTOOTOT"
        />
      })
      : null

    return (
      <Col span={24} style={styles.subCollapse}>
        <Row type="flex" justify="space-around" align="middle" style={{ padding: "10px 10px 10px 30px" }}>

          <Col offset={4} span={12}>
            <a>
              <h4 onClick={!this.isLocked
                ? () => this.display()
                : null} style={styles.subTitle}>{
                  this.props.title
                    ? this.props.title
                    : null
                }</h4>
            </a>
          </Col>
          {
            !this.isLocked
              ? <Col span={1} style={styles.arrowContainer}>
                <p onClick={() => this.display()}>
                  <a>
                    <img src={arrow} style={this.props.tracks[this.props.trackPosition].projects[this.props.projectPosition].open
                      ? styles.upperArrow
                      : styles.downArrow} alt={arrow} />
                  </a>
                </p>
              </Col>
              : null
          }
        </Row>
        <Row>
          <Col span={24} style={this.props.tracks[this.props.trackPosition].projects[this.props.projectPosition].open ? styles.containerDays : styles.displayNone}>
            {days}
          </Col>
        </Row>
        <div style={styles.subLine}></div>
      </Col>
    );
  }
}

class Track extends Component {

  isLocked = true;

  display = () => {
    this.props.openMenuItem(!this.props.tracks[this.props.trackPosition].open, 'track', this.props.trackId);
  }

  render() {

    this.isLocked = this.props.projects.length > 0
      ? false
      : null
    let projects = this.props.projects
      ? this.props.projects.map((project, i) => {

        var userProgression;
        if (this.props.userProgression) {
          for (let projectUserProgression of this.props.userProgression) {
            if (projectUserProgression.projectId === project._id) {
              userProgression = projectUserProgression.days;
              break;
            }
          }
        }
        return <Project openMenuItem={this.props.openMenuItem}
          key={i}
          batchId={this.props.batchId}
          stackId={this.props.stackId}
          trackPosition={this.props.trackPosition}
          projectPosition={i}
          projectId={project._id}
          title={project.title}
          text={project.text}
          days={project.days}
          batchProgression={this.props.batchProgression}
          userProgression={userProgression}
          tracks={this.props.tracks}
          userData={this.props.userData}
          activeLiveCode={this.props.activeLiveCode}
        />
      })
      : null;

    return (<Col style={styles.background}>
      <Row type="flex" justify="space-around" align="middle" style={{ padding: 25 }}>

        <Col span={2} style={styles.center}>
          {
            !this.isLocked
              ? <img src={this.props.trackImage ? this.props.trackImage : null} style={styles.images} alt={
                this.props.trackImage
                  ? this.props.trackImage
                  : null
              } />
              : <img src={locked} style={{ width: 40 }} alt={locked} />
          }
        </Col>
        <Col style={styles.noMargin} span={14}>
          <a>
            <h4 onClick={!this.isLocked ? () => this.display() : null} className="lc-pink" style={styles.title}>
              {
                this.props.title
                  ? this.props.title
                  : null
              }
            </h4>
          </a>
        </Col>
        {
          !this.isLocked
            ? <Col offset={1} span={2} style={styles.arrowContainer}>
              <p onClick={() => this.display()}>
                <a>
                  <img src={arrow} style={this.props.tracks[this.props.trackPosition].open ? styles.upperArrow : styles.downArrow} alt={arrow} />
                </a>
              </p>
            </Col>
            : null
        }
      </Row>
      <div style={this.props.tracks[this.props.trackPosition].open ? styles.displayFlex : styles.displayNone}>
        <Col span={24}>
          <div style={styles.mainLine}></div>
          {projects}
        </Col>
      </div>
    </Col>);
  }
}

class Menu extends Component {

  render() {
    let Tracks = this.props.tracks.map((track, i) => {
      var progress = {};
      for (let i = 0; i < this.props.progression.length; i++) {
        progress[this.props.progression[i].stepId] = this.props.progression[i];
      }

      var userProgression;
      if (this.props.userData && this.props.userData.progression.tracks) {
        for (let trackUserProgression of this.props.userData.progression.tracks) {
          if (trackUserProgression.trackId == track._id) {
            userProgression = trackUserProgression.projects;
            break;
          }
        }
      }

      return <Track openMenuItem={this.props.openMenuItem}
        key={i}
        trackImage={global.URI_BACKEND + '/' + track.imagepath}
        batchId={this.props.batchId}
        stackId={this.props.stackId}
        trackId={track._id}
        trackPosition={i}
        title={track.title}
        text={track.text}
        projects={track.projects}
        batchProgression={progress}
        userProgression={userProgression}
        tracks={this.props.tracks}
        userData={this.props.userData}
        activeLiveCode={this.props.activeLiveCode}

      />
    });
    return (<Col span={24}>
      {Tracks}
    </Col>);
  }
}

function mapStateToProps({ stackid: stackId, batchid: batchId, tracks, progression, user: userData }) {
  return { stackId, batchId, tracks, progression, userData };
}

function mapDispatchToProps(dispatch) {
  return {
    openMenuItem: function (isOpen, levelType, levelId, trackPosition) {
      dispatch({ type: 'openmenuitem', isOpen, levelType, levelId, trackPosition });
    },
    //update de progression pour ON/OFF un livecode depuis Collapse.js
    activeLiveCode: function (batch) {
      dispatch({ type: 'progression', progression: batch.enabledStep });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

const styles = {
  arrowContainer: { marginTop: 15 },
  background: { backgroundColor: '#fff', minHeight: 100, boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.3)', marginBottom: 20, },
  images: { width: '100%', maxWidth: '60px', height: '100%' },
  downArrow: { maxWidth: 15 },
  upperArrow: { maxWidth: 15, transform: 'rotate(180deg)' },
  color: { color: "#606060" },
  mainLine: { height: 1, width: '100%', backgroundColor: 'lightGrey', },
  subLine: { height: 1, width: '100%', backgroundColor: 'lightGrey', },
  subTitle: { color: '#087e8b', paddingTop: 5, fontSize: 16 },
  rowDay: { padding: "10px 10px 10px 30px" },
  subDay: { paddingTop: 5, fontSize: 16, color: "#606060" },
  subCollapse: { backgroundColor: '#fff' },
  noMargin: { margin: 0 },
  list: { marginBottom: 7 },
  title: { paddingTop: 5, fontSize: 16 },
  switch: { marginRight: 10 },
  locked: { width: 15, color: '#aaaaaa', marginRight: 20, fontSize: 21 },
  readyToStart: { width: 15, color: '#aaaaaa', marginRight: 20, fontSize: 21 },
  finished: { width: 15, color: '#008000', marginRight: 20, fontSize: 21 },
  inProgress: { width: 15, marginRight: 20, fontSize: 21 },
  finishedAverage: { width: 15, color: '#e67e22', marginRight: 20, fontSize: 21 },
  finishedBad: { width: 15, color: '#e74c3c', marginRight: 20, fontSize: 21 },
  stepContainer: { color: 'rgba(0, 0, 0, 0.65)', display: 'flex', alignItems: 'center' },
  stepContainerDisable: { color: 'rgba(0, 0, 0, 0.45)', display: 'flex', alignItems: 'center' },
  center: { display: 'flex', justifyContent: "center", alignItems: 'center' },
  displayFlex: { display: 'flex' },
  displayNone: { display: 'none' },
  containerDays: { display: "flex", flexDirection: "column" },
  containerStep: { minHeight: 60, display: 'flex', alignItems: 'center' }
}