import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';

import logo from './Lacapsule-logo-blanc.png';
import logoSimple from './Lacapsule-logo-simple.png'
import { connect } from 'react-redux'

function Logo({ batch, mobile, toggleMenu }) {
  const [mode, setMode] = useState("desktop")
  const { container, rowHeight, height } = styles;

  const logoImg = <img className="logo" src={mobile ? logoSimple : logo} style={height} alt="logo"/>

  return (<Col style={container}>
    <Row align="middle" justify="center" type="flex" style={rowHeight}>
      {
        batch.stackType !== 'workshop' ?
        <Link to="/" onClick={ () => toggleMenu('batch') }>{ logoImg }</Link>
        :
        <>{ logoImg }</>
      }
    </Row>
  </Col>);
}

function mapStateToProps({ batch }) {
  return { batch }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleMenu: menu => {
      dispatch({ type: 'toggleMenu', menu })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logo);

const styles = {
  container: { padding: '0px 15px 0px 20px' },
  rowHeight: { height: 40 },
  height: { height: "20px" }
}
