import React, { Component } from 'react';

export default class UserProfile extends Component {
  state = {
    selected: '',
    isDeveloper: false,
    isFreelance: false,
    isProductOwner: false,
    isContractor: false
  }

  componentDidMount() {
    const { isDeveloper, isFreelance, isProductOwner, isContractor } = this.props;

    this.setState({ isDeveloper, isFreelance, isProductOwner, isContractor });
  }

  handleClick = name => {
    const { isDeveloper, isFreelance, isProductOwner, isContractor } = this.state;

    const newState = { isDeveloper, isFreelance, isProductOwner, isContractor };

    for (const e of Object.keys(newState)) {
      if (name === e) newState[e] = true;
      else newState[e] = false;
    }

    this.setState(newState, () => {
      const { setStateInParent } = this.props;

      setStateInParent({ career: newState }, true);
    });
  }

  render() {
    const { tags, jobStatusStyle, jobStatusStyleNotSelected, hover } = styles;
    const { editUser } = this.props;
    const { isDeveloper, isFreelance, isProductOwner, isContractor } = this.state;

    const specialities = [
      { name: 'Développeur', status: isDeveloper, prop: 'isDeveloper' },
      { name: 'Freelance', status: isFreelance, prop: 'isFreelance' },
      { name: 'Product Owner', status: isProductOwner, prop: 'isProductOwner' },
      { name: 'Entrepreneur', status: isContractor, prop: 'isContractor' }
    ];

    const specs = specialities.map(e => <div key={e.name} data-active={e.status} onClick={() => this.handleClick(e.prop)}>
      <div style={hover}>
        <span style={e.status ? jobStatusStyle : jobStatusStyleNotSelected}>{e.name}</span>
      </div>
    </div>);

    return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>Profil :</div>
      <div style={tags} id="user-profile__row">
        {
          editUser
            ? specs
            : specs.filter(e => e?.props?.['data-active'] === true)
        }
      </div>
    </div>
  }
}

const styles = {
  hover: { cursor: 'pointer', display: 'flex', alignItems: 'center' },
  tags: { display: 'flex', marginLeft: 5 },
  jobStatusStyle: { fontSize: 9, fontWeight: 'bold', color: '#040031', borderRadius: 4, backgroundColor: 'rgba(4, 0, 49, 0.05)', padding: 6 },
  jobStatusStyleNotSelected: { fontSize: 9, fontWeight: 'bold', color: '#b3b3b3', borderRadius: 4, backgroundColor: '#F6F7FB', padding: 6 },
}