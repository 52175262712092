import typeChecker from '../../typeChecker';

function memberExpression(tc_memExp = {}, pb_memExp = {}) {
  const check = {};

  if (tc_memExp.object) check.object = false;
  if (tc_memExp.property) check.property = false;

  if (pb_memExp.object?.type && tc_memExp.object?.type === pb_memExp.object?.type) {
    check.object = typeChecker(tc_memExp.object, pb_memExp.object);
  }

  if (pb_memExp.property?.type && tc_memExp.property?.type === pb_memExp.property?.type) {
    check.property = typeChecker(tc_memExp.property, pb_memExp.property);
  }

  return Object.values(check).every(e => e === true);
}

export default memberExpression;