import React, { useState, useEffect } from "react";

//STYLES__________________________
import "./teacherdashboards.css";
import { dashboardstyles } from "./dashboardstyles";

//COMPONENTS______________________
import DataDisplay from "./components/DataDisplay";
import Student from "./components/Student";
// import DashboardNavMenu from './components/DashboardNavMenu'
import FeelingEmoji from "./components/FeelingEmoji";
import BarChartLabel from "./components/BarChartLabel";
import BarChartLabelFlashcards from "./components/BarChartLabelFlashcards";
import CustomTick from "./components/CustomTick";
import moment from "moment";
import "moment/locale/fr";
import ForceEmargement from "../../Header/ForceEmargement";

//UI_______________________________
import {
  Row,
  Col,
  Table,
  Icon,
  Progress,
  Tag,
  Spin,
  Modal,
  Divider,
  Badge,
  Steps
} from "antd";
import SimpleBar from "simplebar-react";

//CHARTS____________________________
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  Bar,
  Legend,
  YAxis,
} from "recharts";

//REDUX_________________________________
import { connect } from "react-redux";

// TRANSLATION -------------------
import { useTranslation } from "react-i18next";

const tags = {
  course: "COURS",
  project: "PROJET",
  exercise: "CHALLENGE",
  quizz: "QUIZ",
};

const TeacherDashboard = ({
  batch,
  batchAverage,
  weeklyFeelings,
  yesterdayFeelings,
  evaluations,
  flashcards,
  dailyAbsence,
  dataLoading,
  absencesLoading,
  addStudentPane,
  stackType,
  calendarMode,
  selectedDay,
  batchid,
  progressStudentsData,
}) => {
  //STATE HOOKS___________________________
  // const [view, setView] = useState('batch');
  const [visibleModal, setVisibleModal] = useState(false);
  const [nbrStudentFeeling, setNbrStudentFeeling] = useState(0);
  const [batchPerFeeling, setbatchPerFeeling] = useState([]);

  const [nbrStudentPerEvaluation, setNbrStudentPerEvaluation] = useState(0);
  const [batchPerEvaluation, setBatchPerEvaluation] = useState([]);
  const [evaluationModal, setEvaluationModal] = useState(false);

  const [nbrStudentPerFlashcard, setNbrStudentPerFlashcard] = useState(0);
  const [batchPerFlashcard, setBatchPerFlashcard] = useState([]);
  const [flashcardModal, setFlashcardModal] = useState(false);

  const [visibleProgressModal, setVisibleProgressModal] = useState(false);
  const [nbrStudentPerProgress, setNbrStudentPerProgress] = useState(0);
  const [batchPerProgress, setBatchPerProgress] = useState([]);

  const { t } = useTranslation();

  //? Forcer la signature
  const [forcedVisible, setForcedVisible] = useState(false);
  const [duringBatch, setDuringBatch] = useState("");
  // const [currentStudent, setCurrentStudent] = useState(null);
  const [forcedUser, setForcedUser] = useState(null);

  const forceVisibleFunc = () => {
    setForcedVisible(true);
  };
  const forceVisibleClosed = () => {
    setForcedVisible(false);
  };
  const changeDuringBatch = (value, student) => {
    setDuringBatch(value);
    // setCurrentStudent(student);
    setForcedUser({ _id: student.id, name: student.name });
  };

  useEffect(() => {
    for (const students of batch) {
      for (const step of progressStudentsData) {
        step.progressions.map(e => {
          if (e._id === students.student.id) {
            students.stepTitle = e.stepTitle
            students.currentStepType = e.type
          }
        })
      }
    }
  });

  //DATA_________________________
  const allStudentColumns = [
    {
      title: <h3 style={styles.tableText}>{t("Élève")}</h3>,
      dataIndex: "student",
      key: "student",
      align: "center",
      width: '18%',
      render: student =>
        student ? (
          <Student student={student} onClick={addStudentPane} />
        ) : (
          <Spin />
        ),
    },
    {
      title: <h3 style={styles.tableText}>{t("Avancée")}</h3>,
      dataIndex: "stepTitle",
      align: "center",
      key: "stepTitle",
      render: (stepTitle, data) =>
        stepTitle ? (
          <>
            <div style={{ color: '#272727', fontSize: 15, fontWeight: '500', lineHeight: '15px' }}>{t(tags[data.currentStepType] || data.currentStepType.toUpperCase())}</div>
            <span className="ant-steps-item-description">{stepTitle}</span>
          </>
        ) : (
          <></>
        ),
    },
    {
      title: <h3 style={styles.tableText}>{t("Solutions Ariane")}</h3>,
      dataIndex: "solutions",
      key: "solutions",
      align: "center",
      render: solutions => (
        <>
          {/* {
                    solutions === undefined ?
                    <Spin />
                    :
                    */}
          <span style={styles.tableText}>
            {solutions || 0}
            <Icon type="info-circle" style={styles.tableIcon} />
          </span>
          {/* } */}
        </>
      ),
    },
    {
      title: <h3 style={styles.tableText}>{t("Help requests")}</h3>,
      dataIndex: "helprequests",
      key: "helprequests",
      align: "center",
      render: helprequests => (
        <>
          {helprequests === undefined ? (
            <Spin />
          ) : (
            <span style={styles.tableText}>
              {helprequests || 0}
              <Icon type="question-circle" style={styles.tableIcon} />
            </span>
          )}
        </>
      ),
    },
    {
      title: (
        <h3 style={styles.tableText}>
          {t("Ressenti de la")}{" "}
          {calendarMode === "day" &&
            moment(selectedDay)
              .utc()
              .startOf("day")
              .isBefore(moment().utc().startOf("day"))
            ? t("journée")
            : t("veille")}
        </h3>
      ),
      dataIndex: "feeling",
      key: "feeling",
      align: "center",
      render: feeling =>
        feeling !== undefined ? (
          <FeelingEmoji feeling={feeling} size="medium" />
        ) : (
          <Spin />
        ),
    },
    {
      title: <h3 style={styles.tableText}>{t("Assiduité")}</h3>,
      dataIndex: "diligence",
      key: "diligence",
      align: "center",
      render: diligence => (
        <span style={styles.tableText}>
          {diligence || 0}%
          <i className="icon-diligence" style={styles.tableIcon} />
        </span>
      ),
    },
  ];

  const absenceColumns = [
    {
      title: <h3 style={styles.tableText}>{t("Élève")}</h3>,
      dataIndex: "student",
      key: "student",
      align: "center",
      render: student => <Student student={student} onClick={addStudentPane} />,
    },
    {
      title: <h3 style={styles.tableText}>{t("Nombre d'absences")}</h3>,
      dataIndex: "absences",
      key: "absences",
      align: "center",
      render: absences => (
        <Tag
          color={
            absences >= 5
              ? "#F94A56"
              : absences >= 3
                ? "rgba(249, 74, 86, 0.5)"
                : "rgba(249, 74, 86, 0.17)"
          }
          style={absences < 3 ? { ...styles.tag, color: "#000" } : styles.tag}
        >
          {t("jour", { count: absences })}
        </Tag>
      ),
    },
  ];

  const dailyAbsenceColumns = [
    {
      title: <h3 style={styles.tableText}>{t("Élève")}</h3>,
      dataIndex: "student",
      key: "student",
      align: "center",
      render: student => (
        <>
          <Student student={student} onClick={addStudentPane} />
          {/* setCurrentStudent(student) */}
        </>
      ),
    },
    /* {
            title: <h3 style={styles.tableText}>OH</h3>,
            dataIndex: 'absences',
            key: 'absences',
            align: 'center',
            render: absences => <Tag color={absences >= 5 ? '#F94A56' : absences >= 3 ? 'rgba(249, 74, 86, 0.5)' : 'rgba(249, 74, 86, 0.17)'} style={absences < 3 ? { ...styles.tag, color: '#000' } : styles.tag}>{absences} jours</Tag>
        }, */
  ];
  const { day } = dailyAbsence;

  if (day?.morning) {
    dailyAbsenceColumns.push({
      title: <h3 style={styles.tableText}>{t("Matin")}</h3>,
      // dataIndex: 'absMorning',
      key: "absMorning",
      align: "center",
      render: record => (
        <Badge
          onClick={() => {
            changeDuringBatch("morning", record.student);
            forceVisibleFunc();
          }}
          dot={true}
          color={record.absMorning ? "#F94A56" : "#98EDC4"}
        />
      ),
    });
  }
  if (day?.afternoon) {
    dailyAbsenceColumns.push({
      title: <h3 style={styles.tableText}>{t("Après-midi")}</h3>,
      // dataIndex: 'absAfternoon',
      key: "absAfternoon",
      align: "center",
      render: record => (
        <Badge
          onClick={() => {
            changeDuringBatch("afternoon", record.student);
            forceVisibleFunc();
          }}
          dot={true}
          color={record.absAfternoon ? "#F94A56" : "#98EDC4"}
        />
      ),
    });
  }
  if (day?.evening) {
    dailyAbsenceColumns.push({
      title: <h3 style={styles.tableText}>{t("Soir")}</h3>,
      // dataIndex: 'absEvening',
      key: "absEvening",
      align: "center",
      render: record => (
        <Badge
          onClick={() => {
            changeDuringBatch("evening", record.student);
            forceVisibleFunc();
          }}
          dot={true}
          color={record.absEvening ? "#F94A56" : "#98EDC4"}
        />
      ),
    });
  }

  // if (day?.officeHours) {
  //     dailyAbsenceColumns.push({
  //         title: <h3 style={styles.tableText}><i>Office Hours</i></h3>,
  //         dataIndex: 'absOH',
  //         key: 'absOH',
  //         align: 'center',
  //         render: absOH => <Badge dot={true} color={absOH ? '#F94A56' : '#98EDC4'} />
  //     })
  // }

  const studentsPerFeeling = [
    {
      dataIndex: "student",
      key: "student",
      align: "center",
      render: student =>
        student ? (
          <Student student={student} onClick={toggleStudentPane} />
        ) : (
          <Spin />
        ),
    },
    {
      dataIndex: "competences",
      align: "center",
      key: "competences",
      width: "10vw",
      render: competences =>
        competences ? (
          <div style={styles.radarIcon}>{competences}%</div>
        ) : (
          <Spin />
        ),
    },
    {
      dataIndex: "weekFeeling",
      key: "weekFeeling",
      align: "center",
      width: "15vw",
      render: weekFeeling =>
        weekFeeling !== undefined ? (
          <FeelingEmoji feeling={weekFeeling} size="medium" />
        ) : (
          <Spin />
        ),
    },
    {
      dataIndex: "diligence",
      key: "diligence",
      align: "center",
      width: "9vw",
      render: diligence => (
        <span style={styles.tableText}>
          {diligence || 0}%
          <i className="icon-diligence" style={styles.tableIcon} />
        </span>
      ),
    },
  ];

  //TO FIX
  const studentsPerEvaluation = [
    {
      dataIndex: "student",
      key: "student",
      align: "center",
      render: student =>
        student ? (
          <Student student={student} onClick={toggleStudentPane} />
        ) : (
          <Spin />
        ),
    },
    {
      dataIndex: "competences",
      align: "center",
      key: "competences",
      width: "10vw",
      render: competences =>
        competences ? (
          <div style={styles.radarIcon}>{competences}%</div>
        ) : (
          <Spin />
        ),
    },
    {
      dataIndex: "selectedWeekEvaluation",
      key: "selectedWeekEvaluation",
      align: "center",
      width: "15vw",
      render: selectedWeekEvaluation =>
        selectedWeekEvaluation !== undefined ? (
          <span>{selectedWeekEvaluation} %</span>
        ) : (
          <Spin />
        ),
    },
    {
      dataIndex: "diligence",
      key: "diligence",
      align: "center",
      width: "9vw",
      render: diligence => (
        <span style={styles.tableText}>
          {diligence || 0}%
          <i className="icon-diligence" style={styles.tableIcon} />
        </span>
      ),
    },
  ];

  const studentsPerFlashcard = [
    {
      dataIndex: "student",
      key: "student",
      align: "center",
      render: student =>
        student ? (
          <Student student={student} onClick={toggleStudentPane} />
        ) : (
          <Spin />
        ),
    },
    {
      dataIndex: "competences",
      align: "center",
      key: "competences",
      width: "10vw",
      render: competences =>
        competences ? (
          <div style={styles.radarIcon}>{competences}%</div>
        ) : (
          <div style={styles.radarIcon}>0%</div>
        ),
    },
    {
      dataIndex: "selectedFlashcards",
      key: "selectedFlashcards",
      align: "center",
      width: "10vw",
      render: selectedFlashcards =>
        selectedFlashcards !== undefined && selectedFlashcards !== "NR" ? (
          <span>{selectedFlashcards} %</span>
        ) : (
          0
        ),
    },
    {
      dataIndex: "ratioFlashcard",
      key: "ratioFlashcard",
      align: "center",
      width: "14vw",
      render: ratioFlashcard =>
        ratioFlashcard !== undefined ? <span>{ratioFlashcard}</span> : <Spin />,
    },
  ];

  const studentsPerProgress = [
    {
      dataIndex: "studentId",
      key: "studentId",
      align: "center",
      width: '17%',
      render: studentId => {
        return (studentId ? (
          <Student student={studentId} onClick={toggleStudentPane} />
        ) : (
          <Spin />
        ))
      }
    },
    {
      dataIndex: "sectionTitle",
      align: "center",
      key: "sectionTitle",
      width: '16%',
      render: sectionTitle =>
        sectionTitle ? (
          <>
            <h4 style={styles.tableResource}>{sectionTitle}</h4>
          </>
        ) : (
          <></>
        ),
    },
    {
      dataIndex: "solutions",
      key: "solutions",
      align: "center",
      width: '16%',
      render: solutions =>
        solutions !== undefined && solutions !== "NR" ? (
          <span>{solutions}</span>
        ) : (
          0
        ),
    },
    {
      dataIndex: "helprequests",
      key: "helprequests",
      align: "center",
      width: '16%',
      render: helprequests =>
        helprequests !== undefined ? <span>{helprequests}</span> : <Spin />,
    },
    {
      dataIndex: "feeling",
      key: "feeling",
      align: "center",
      width: '16%',
      render: feeling =>
        feeling !== undefined ? <FeelingEmoji feeling={feeling} size="medium" /> : <Spin />,
    },
    {
      dataIndex: "diligence",
      key: "diligence",
      align: "center",
      width: '16%',
      render: diligence =>
        diligence !== undefined ? <span>{diligence}%</span> : <Spin />,
    },
  ];

  //FUNCTIONS_________________
  const toggleStudentPane = student => {
    addStudentPane(student);
    setEvaluationModal(false);
    setVisibleModal(false);
    setVisibleProgressModal(false)
  };

  const feelingsModal = (nbrStudents, feeling) => {
    let batchPerFeelingCopy = batch.filter(e => e.weekFeeling === feeling);

    setbatchPerFeeling(batchPerFeelingCopy);
    setNbrStudentFeeling(nbrStudents);

    if (nbrStudents !== 0) {
      setVisibleModal(true);
    }
  };

  const cancelFeelingsModal = () => {
    setVisibleModal(false);
  };

  const evaluationsModal = (value, stepId, range) => {
    console.log("value", value, "stepId", stepId, "range", range)

    let batchPerEvaluationCopy = batch.map(e => {
      const test = e?.weekEvaluations?.find(f => f.stepId === stepId);

      return { ...e, selectedWeekEvaluation: test?.score || "NR" };
    });

    let batchPerEvaluationCopy2 = [];

    if (range === "low") {
      batchPerEvaluationCopy2 = batchPerEvaluationCopy.filter(
        e => e.selectedWeekEvaluation <= 50
      );
    } else if (range === "medium") {
      batchPerEvaluationCopy2 = batchPerEvaluationCopy.filter(
        e => e.selectedWeekEvaluation > 50 && e.selectedWeekEvaluation < 70
      );
    } else if (range === "high") {
      batchPerEvaluationCopy2 = batchPerEvaluationCopy.filter(
        e => e.selectedWeekEvaluation >= 70
      );
    }

    setBatchPerEvaluation(batchPerEvaluationCopy2);
    setNbrStudentPerEvaluation(value);

    if (value !== 0 && value !== null) {
      setEvaluationModal(true);
    }
  };

  //OLD
  // const evaluationsModal = (value, weekNumber, range) => {
  //   let batchPerEvaluationCopy = batch.map(e => {
  //     const test = e?.weekEvaluations?.find(f => f.week === weekNumber);

  //     return { ...e, selectedWeekEvaluation: test?.score || "NR" };
  //   });

  //   let batchPerEvaluationCopy2 = [];

  //   if (range === "low") {
  //     batchPerEvaluationCopy2 = batchPerEvaluationCopy.filter(
  //       e => e.selectedWeekEvaluation <= 50
  //     );
  //   } else if (range === "medium") {
  //     batchPerEvaluationCopy2 = batchPerEvaluationCopy.filter(
  //       e => e.selectedWeekEvaluation > 50 && e.selectedWeekEvaluation < 70
  //     );
  //   } else if (range === "high") {
  //     batchPerEvaluationCopy2 = batchPerEvaluationCopy.filter(
  //       e => e.selectedWeekEvaluation >= 70
  //     );
  //   }

  //   setBatchPerEvaluation(batchPerEvaluationCopy2);
  //   setNbrStudentPerEvaluation(value);

  //   if (value !== 0 && value !== null) {
  //     setEvaluationModal(true);
  //   }
  // };

  const cancelEvaluationModal = () => {
    setEvaluationModal(false);
  };

  const progressModal = (studentStep) => {
    setVisibleProgressModal(true)
    let batchPerProgressCopy = progressStudentsData.filter(e => e.stepId === studentStep)

    let studentProgressArray = []
    let progressions = batchPerProgressCopy[0].progressions

    for (const progression of progressions) {
      const studentData = batch.find(e => e.student.id === progression._id)
      const lastSection = progression.sections.findLast(e => e.percentage > 0 ? e.percentage > 0 : e)

      let studentProgress = {
        studentId: {
          id: studentData?.student.id,
          name: studentData?.student.name,
          avatar: studentData?.student.avatar
        },
        stepPercentage: progression.stepPercentage,
        sectionTitle: lastSection?.sectionTitle,
        sectionPercentage: lastSection?.percentage,
        solutions: studentData?.solutions,
        helprequests: studentData?.helprequests,
        feeling: studentData?.feeling,
        diligence: studentData?.diligence
      }

      studentData && studentProgressArray.push(studentProgress)
    }
    setBatchPerProgress(studentProgressArray)
    setNbrStudentPerProgress(studentProgressArray.length)
  };

  const cancelProgressModal = () => {
    setVisibleProgressModal(false);
  };

  const flashcardsModal = (value, dayNumber, range) => {
    let batchPerFlashcardCopy = batch.map(e => {
      const flashcardsFinder = e?.flashcards?.find(f => f.day === dayNumber);

      return {
        ...e,
        selectedFlashcards: flashcardsFinder?.score || "NR",
        nbrFlashcards: flashcardsFinder?.totalFlashcards,
        nbrStudentFlashcard: flashcardsFinder?.studentTotalFlashcards,
        ratioFlashcard:
          flashcardsFinder?.studentTotalFlashcards +
          "/" +
          flashcardsFinder?.totalFlashcards,
      };
    });

    let batchPerFlashcardCopy2 = [];

    if (range === "low") {
      batchPerFlashcardCopy2 = batchPerFlashcardCopy.filter(
        e =>
          e.selectedFlashcards <= 50 &&
          e.selectedFlashcards > 0 &&
          e.nbrStudentFlashcard === e.nbrFlashcards
      );
    } else if (range === "medium") {
      batchPerFlashcardCopy2 = batchPerFlashcardCopy.filter(
        e =>
          e.selectedFlashcards > 50 &&
          e.selectedFlashcards < 70 &&
          e.nbrStudentFlashcard === e.nbrFlashcards
      );
    } else if (range === "high") {
      batchPerFlashcardCopy2 = batchPerFlashcardCopy.filter(
        e =>
          e.selectedFlashcards >= 70 &&
          e.nbrStudentFlashcard === e.nbrFlashcards
      );
    } else if (range === "notFinished") {
      batchPerFlashcardCopy2 = batchPerFlashcardCopy.filter(
        e => e.nbrStudentFlashcard < e.nbrFlashcards
      );
    }

    setBatchPerFlashcard(batchPerFlashcardCopy2);
    setNbrStudentPerFlashcard(value);

    if (value !== 0 && value !== null) {
      setFlashcardModal(true);
    }
  };

  const cancelFlashcardModal = () => {
    setFlashcardModal(false);
  };

  //STEPS BAR_______________
  const { Step } = Steps;

  return (
    <div className="tableHeaderCentered">
      {forcedVisible && (
        <ForceEmargement
          closeDrawer={forceVisibleClosed}
          forcedUser={forcedUser}
          forceVisible={forcedVisible}
          batchId={batchid}
          duringBatch={duringBatch}
          selectedDay={selectedDay}
        />
      )}
      {/* <DashboardNavMenu view={ view } setView={ setView } /> */}

      <Modal
        bodyStyle={styles.modal}
        style={{ marginTop: "-45px" }}
        width={"65vw"}
        footer={null}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                ...styles.heavyFont,
                width: "16vw",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{t("Tous les élèves")}</span>
              <Badge
                count={t("élève", {
                  count: nbrStudentFeeling ? nbrStudentFeeling : "NR",
                })}
                style={styles.badge}
              />
            </div>
            <div
              style={{
                ...styles.lightFont,
                width: "30vw",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "3vw",
              }}
            >
              <span>{t("Compétences")}</span>
              <Divider type="vertical" />
              <span>{t("Ressenti de la") + " " + t("semaine")}</span>
              <Divider type="vertical" />
              <span>{t("Assiduité")}</span>
            </div>
          </div>
        }
        visible={visibleModal}
        closable={true}
        onCancel={cancelFeelingsModal}
      >
        <SimpleBar className="tableHeaderCentered" style={{ height: "100%", width: "100%" }}>
          <Table
            columns={studentsPerFeeling}
            dataSource={batchPerFeeling}
            pagination={false}
            showHeader={false}
            style={{ maxHeight: "100%" }}
          />
        </SimpleBar>
      </Modal>

      <Modal
        bodyStyle={styles.modal}
        style={{ marginTop: "-45px" }}
        width={"65vw"}
        footer={null}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                ...styles.heavyFont,
                width: "16vw",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Tous les élèves</span>
              <Badge
                count={t("élève", {
                  count: nbrStudentPerEvaluation
                    ? nbrStudentPerEvaluation
                    : "NR",
                })}
                style={styles.badge}
              />
            </div>
            <div
              style={{
                ...styles.lightFont,
                width: "30vw",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "3vw",
              }}
            >
              <span>{t("Compétences")}</span>
              <Divider type="vertical" />
              <span>{t("Score")}</span>
              <Divider type="vertical" />
              <span>{t("Assiduité")}</span>
            </div>
          </div>
        }
        visible={evaluationModal}
        closable={true}
        onCancel={cancelEvaluationModal}
      >
        <SimpleBar style={{ height: "100%", width: "100%" }}>
          <Table
            columns={studentsPerEvaluation}
            dataSource={batchPerEvaluation}
            pagination={false}
            showHeader={false}
            style={{ maxHeight: "100%" }}
          />
        </SimpleBar>
      </Modal>

      <Modal
        bodyStyle={styles.modal}
        style={{ marginTop: "-45px" }}
        width={"65vw"}
        footer={null}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                ...styles.heavyFont,
                width: "16vw",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{t("Tous les élèves")}</span>
              <Badge
                count={t("élève", {
                  count: nbrStudentPerFlashcard ? nbrStudentPerFlashcard : "NR",
                })}
                style={styles.badge}
              />
            </div>
            <div
              style={{
                ...styles.lightFont,
                width: "30vw",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "3vw",
              }}
            >
              <span>{t("Compétences")}</span>
              <Divider type="vertical" />
              <span>{t("Score")}</span>
              <Divider type="vertical" />
              <span>{t("Nbr de flashcards")}</span>
            </div>
          </div>
        }
        visible={flashcardModal}
        closable={true}
        onCancel={cancelFlashcardModal}
      >
        <SimpleBar style={{ height: "100%", width: "100%" }}>
          <Table
            columns={studentsPerFlashcard}
            dataSource={batchPerFlashcard}
            pagination={false}
            showHeader={false}
            style={{ maxHeight: "100%" }}
          />
        </SimpleBar>
      </Modal>

      {
        (calendarMode === "batch" || calendarMode === "week") && (
          <Row style={styles.snapshotRow}>
            <Col style={dashboardstyles.contentPanel} span={7} offset={1}>
              <h2 style={dashboardstyles.contentTitle}>
                {t("Élèves en difficulté")}
              </h2>
              <div style={dashboardstyles.contentBody}>
                {dataLoading ? (
                  <Spin style={{ margin: "auto" }} />
                ) : !dataLoading &&
                  batch.filter(e => e.competences && e.competences <= 60)
                    .length === 0 ? (
                  <p style={styles.infoText}>{t("Aucune donnée")}</p>
                ) : (
                  <SimpleBar style={{ height: "100%" }}>
                    {batch.length > 0 &&
                      batch
                        .filter(e => e.competences && e.competences <= 60)
                        .map((e, i) => (
                          <div key={i} style={styles.studentRow}>
                            <Student
                              student={{
                                ...e.student,
                                percentage: e.competences,
                              }}
                              onClick={addStudentPane}
                              withPercentage
                              size="small"
                            />
                          </div>
                        ))}
                  </SimpleBar>
                )}
              </div>
            </Col>
            <Col style={dashboardstyles.contentPanel} span={7}>
              <h2 style={dashboardstyles.contentTitle}>
                {t("Élèves en avance")}
              </h2>
              <div style={dashboardstyles.contentBody}>
                {dataLoading ? (
                  <Spin style={{ margin: "auto" }} />
                ) : !dataLoading &&
                  batch.filter(
                    e => e.competences === 100 && e.competencesChallenge >= 33
                  ).length === 0 ? (
                  <p style={styles.infoText}>{t("Aucune donnée")}</p>
                ) : (
                  <SimpleBar style={{ height: "100%" }}>
                    {batch.length > 0 &&
                      batch
                        .filter(
                          e =>
                            e.competences === 100 &&
                            e.competencesChallenge >= 33
                        )
                        .map((e, i) => (
                          <div key={i} style={styles.studentRow}>
                            <Student
                              student={{
                                ...e.student,
                                percentage: e.competences,
                              }}
                              onClick={addStudentPane}
                              withPercentage
                              size="small"
                            />
                          </div>
                        ))}
                  </SimpleBar>
                )}
              </div>
            </Col>
            <Col span={3}>
              <DataDisplay
                title={t("Moyenne du batch")}
                data={
                  dataLoading ? (
                    <Spin />
                  ) : batchAverage ? (
                    `${Math.round(batchAverage)}%`
                  ) : (
                    "0 %"
                  )
                }
              />
            </Col>
            <Col style={dashboardstyles.contentPanel} span={5}>
              <h2 style={dashboardstyles.contentTitle}>
                {t("Ressenti de la") + " " + t("semaine")}
              </h2>
              <div
                style={{
                  ...dashboardstyles.contentBody,
                  padding: 40,
                  justifyContent: "space-around",
                }}
                id="feeling-container"
              >
                {stackType?.keyMode === "part-time" ? (
                  <p style={styles.infoText}>{t("Aucune donnée")}</p>
                ) : dataLoading ? (
                  <Spin style={{ margin: "auto" }} />
                ) : weeklyFeelings?.total === 0 ? (
                  <p style={styles.infoText}>{t("Aucune donnée")}</p>
                ) : (
                  <>
                    <div style={styles.feeling_wrapper}>
                      <FeelingEmoji feeling={1} size="medium" />
                      <div style={styles.progress_wrapper}>
                        <Progress
                          strokeColor="#5247EA"
                          percent={Math.round(
                            (weeklyFeelings["1"] / weeklyFeelings.total) * 100
                          )}
                          showInfo={false}
                          style={{ marginLeft: 5 }}
                        />
                        <span
                          onClick={() => {
                            feelingsModal(weeklyFeelings["1"], 1);
                          }}
                          className="hover-underline"
                          style={{
                            ...styles.feeling_count,
                            left: `calc(${Math.round(
                              (weeklyFeelings["1"] / weeklyFeelings.total) * 100
                            )}% + 10px)`,
                          }}
                        >
                          {weeklyFeelings["1"]}
                        </span>
                      </div>
                    </div>
                    <div style={styles.feeling_wrapper}>
                      <FeelingEmoji feeling={2} size="medium" />
                      <div style={styles.progress_wrapper}>
                        <Progress
                          strokeColor="#5247EA"
                          percent={Math.round(
                            (weeklyFeelings["2"] / weeklyFeelings.total) * 100
                          )}
                          showInfo={false}
                          style={{ marginLeft: 5 }}
                        />
                        <span
                          onClick={() => {
                            feelingsModal(weeklyFeelings["2"], 2);
                          }}
                          className="hover-underline"
                          style={{
                            ...styles.feeling_count,
                            left: `calc(${Math.round(
                              (weeklyFeelings["2"] / weeklyFeelings.total) * 100
                            )}% + 10px)`,
                          }}
                        >
                          {weeklyFeelings["2"]}
                        </span>
                      </div>
                    </div>
                    <div style={styles.feeling_wrapper}>
                      <FeelingEmoji feeling={3} size="medium" />
                      <div style={styles.progress_wrapper}>
                        <Progress
                          strokeColor="#5247EA"
                          percent={Math.round(
                            (weeklyFeelings["3"] / weeklyFeelings.total) * 100
                          )}
                          showInfo={false}
                          style={{ marginLeft: 5 }}
                        />
                        <span
                          onClick={() => {
                            feelingsModal(weeklyFeelings["3"], 3);
                          }}
                          className="hover-underline"
                          style={{
                            ...styles.feeling_count,
                            left: `calc(${Math.round(
                              (weeklyFeelings["3"] / weeklyFeelings.total) * 100
                            )}% + 10px)`,
                          }}
                        >
                          {weeklyFeelings["3"]}
                        </span>
                      </div>
                    </div>
                    <div style={styles.feeling_wrapper}>
                      <FeelingEmoji feeling={4} size="medium" />
                      <div style={styles.progress_wrapper}>
                        <Progress
                          strokeColor="#5247EA"
                          percent={Math.round(
                            (weeklyFeelings["4"] / weeklyFeelings.total) * 100
                          )}
                          showInfo={false}
                          style={{ marginLeft: 5 }}
                        />
                        <span
                          onClick={() => {
                            feelingsModal(weeklyFeelings["4"], 4);
                          }}
                          className="hover-underline"
                          style={{
                            ...styles.feeling_count,
                            left: `calc(${Math.round(
                              (weeklyFeelings["4"] / weeklyFeelings.total) * 100
                            )}% + 10px)`,
                          }}
                        >
                          {weeklyFeelings["4"]}
                        </span>
                      </div>
                    </div>
                    <div style={styles.feeling_wrapper}>
                      <FeelingEmoji feeling={5} size="medium" />
                      <div style={styles.progress_wrapper}>
                        <Progress
                          strokeColor="#5247EA"
                          percent={Math.round(
                            (weeklyFeelings["5"] / weeklyFeelings.total) * 100
                          )}
                          showInfo={false}
                          style={{ marginLeft: 5 }}
                        />
                        <span
                          onClick={() => {
                            feelingsModal(weeklyFeelings["5"], 5);
                          }}
                          className="hover-underline"
                          style={{
                            ...styles.feeling_count,
                            left: `calc(${Math.round(
                              (weeklyFeelings["5"] / weeklyFeelings.total) * 100
                            )}% + 10px)`,
                          }}
                        >
                          {weeklyFeelings["5"]}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        )
      }
      {(calendarMode === "day" && progressStudentsData.length > 0) && (
        <Row style={styles.tableRow}>
          <Col span={22} offset={1} className="table-container">
            <h2 style={{ ...dashboardstyles.contentTitle, textAlign: 'left' }}>Avancée de la journée</h2>

            <Modal
              bodyStyle={styles.modal}
              style={{ marginTop: "-45px" }}
              className="progressModal"
              width={"65vw"}
              footer={null}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      ...styles.heavyFont,
                      width: "16%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Badge
                      count={t("élève", {
                        count: nbrStudentPerProgress
                          ? nbrStudentPerProgress
                          : "NR",
                      })}
                      style={styles.badge}
                    />
                  </div>
                  <div
                    style={{
                      ...styles.lightFont,
                      width: "80%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginLeft: "3vw",
                      marginRight: "1vw",
                    }}
                  >
                    <span style={styles.progressTable}>{t("Avancée")}</span>
                    <Divider type="vertical" />
                    <span style={styles.progressTable}>{t("Solutions Ariane")}</span>
                    <Divider type="vertical" />
                    <span style={styles.progressTable}>{t("Demandes d'aide")}</span>
                    <Divider type="vertical" />
                    <span style={styles.progressTable}>{t("Ressenti de la veille")}</span>
                    <Divider type="vertical" />
                    <span style={styles.progressTable}>{t("Assiduité")}</span>
                  </div>
                </div>
              }
              visible={visibleProgressModal}
              closable={true}
              onCancel={cancelProgressModal}
            >
              <SimpleBar style={{ height: "100%", width: "100%" }}>
                <Table
                  columns={studentsPerProgress}
                  dataSource={batchPerProgress}
                  pagination={false}
                  showHeader={false}
                  style={{ maxHeight: "99%" }}
                />
              </SimpleBar>
            </Modal>
            <div style={dashboardstyles.contentBody}>
              <SimpleBar style={{ width: "100%", padding: 40 }}>
                {/* nombre d'étapes + 1 pour éviter d'utiliser le style "current" */}
                <Steps className="stepsbar" style={progressStudentsData.length === 1 ? { justifyContent: 'center' } : {}} progressDot current={progressStudentsData.length + 1}>
                  {progressStudentsData.length > 0 &&
                    progressStudentsData.map(data => {
                      return (<Step
                        description={[
                          <span
                            onClick={() => {
                              progressModal(data.stepId);
                            }}
                            className="hover-underline"
                            style={styles.progress}
                          >
                            {data.progressions.length || 0}
                          </span>,
                          <div className="ant-steps-item-title" style={{ display: 'block' }}>
                            {t(tags[data.stepType_v2] || data.stepType_v2.toUpperCase())}
                          </div>,
                          <span>{data.text}</span>
                        ]}
                      />)
                    })}
                </Steps>
              </SimpleBar>
            </div>
          </Col>
        </Row>
      )}

      <Row style={styles.tableRow}>
        <Col span={22} offset={1} className="table-container">
          <h2 style={{ ...dashboardstyles.contentTitle, textAlign: "left" }}>
            {t("Tous les élèves")}
          </h2>
          <SimpleBar
            style={{
              maxHeight: "40vh",
              minHeight: `${76 * (batch?.length < 4 ? batch?.length : 4)}px`,
            }}
          >
            {dataLoading ? (
              <div
                style={{
                  background: "white",
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin style={{ margin: "auto" }} />
              </div>
            ) : (
              <Table
                columns={allStudentColumns}
                dataSource={batch}
                pagination={false}
                style={{ maxHeight: "100%" }}
              />
            )}
          </SimpleBar>
        </Col>
      </Row>

      {calendarMode === "batch" || calendarMode === "week" ? (
        <Row style={styles.tableRow}>
          {calendarMode === "batch" ? (
            <Col
              span={11}
              offset={1}
              style={{ ...dashboardstyles.contentPanel, paddingLeft: 0 }}
            >
              <h2 style={dashboardstyles.contentTitle}>{t("évaluations")}</h2>
              <div
                style={{
                  ...dashboardstyles.contentBody,
                  height: "45vh",
                  padding: "20px 55px 20px 30px",
                }}
              >
                {evaluations.length === 0 || dataLoading ? (
                  <Spin style={{ margin: "auto" }} />
                ) : (
                  <ResponsiveContainer height="100%" width="100%">
                    <BarChart
                      data={evaluations}
                      barGap={0}
                      barCategoryGap="20%"
                      margin={{ left: -30 }}
                    >
                      <XAxis
                        dataKey="name"
                        tickLine={false}
                        stroke="#C4C4C4"
                        tick={<CustomTick color="#010031" />}
                      />
                      <YAxis
                        tickLine={false}
                        tick={false}
                        stroke="#C4C4C4"
                        domain={[`dataMin - 0.2`, "dataMax + 1"]}
                      />
                      <Bar
                        isAnimationActive={false}
                        name="0 - 50%"
                        dataKey="low"
                        fill="#5247EA"
                        stackId="a"
                        label={
                          <BarChartLabel
                            data={evaluations}
                            range={"low"}
                            onClick={evaluationsModal}
                          />
                        }
                        barSize={40}
                      />
                      <Bar
                        isAnimationActive={false}
                        name="50 - 70%"
                        dataKey="medium"
                        fill="#B081C1"
                        stackId="a"
                        label={
                          <BarChartLabel
                            data={evaluations}
                            range={"medium"}
                            onClick={evaluationsModal}
                          />
                        }
                        barSize={40}
                      />
                      <Bar
                        isAnimationActive={false}
                        name="70 - 100%"
                        dataKey="high"
                        fill="#F94A5680"
                        stackId="a"
                        label={
                          <BarChartLabel
                            data={evaluations}
                            range={"high"}
                            onClick={evaluationsModal}
                            color="#010031"
                          />
                        }
                        barSize={40}
                      />
                      <Legend wrapperStyle={{ left: 20 }} />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
            </Col>
          ) : (
            <Col
              span={11}
              offset={1}
              style={{ ...dashboardstyles.contentPanel, paddingLeft: 0 }}
            >
              {stackType?.keyMode === "part-time" ? (
                ""
              ) : (
                <>
                  <h2 style={dashboardstyles.contentTitle}>
                    {t("Dernières flashcards")}
                  </h2>
                  <div
                    style={{
                      ...dashboardstyles.contentBody,
                      height: "45vh",
                      padding: "20px 55px 20px 30px",
                    }}
                  >
                    {dataLoading || flashcards.length === 0 ? (
                      <Spin style={{ margin: "auto" }} />
                    ) : (
                      <ResponsiveContainer height="100%" width="100%">
                        <BarChart
                          layout="vertical"
                          data={flashcards}
                          barGap={0}
                          barCategoryGap="20%"
                        >
                          <XAxis
                            type="number"
                            tickLine={false}
                            stroke="#C4C4C4"
                            tick={<CustomTick color="#010031" />}
                          />
                          <YAxis
                            dataKey="name"
                            type="category"
                            tickLine={false}
                            tick={<CustomTick color="#010031" />}
                            stroke="#C4C4C4"
                            domain={[
                              `dataMin - ${stackType?.keyStack === "fullstack-js" ? 1 : 0.2
                              }`,
                              "dataMax + 1",
                            ]}
                          />
                          <Bar
                            isAnimationActive={false}
                            name="notFinished"
                            dataKey="notFinished"
                            fill="#cccaca"
                            stackId="a"
                            label={
                              <BarChartLabelFlashcards
                                data={flashcards}
                                range={"notFinished"}
                                onClick={flashcardsModal}
                                color="#010031"
                              />
                            }
                            barSize={30}
                          />
                          <Bar
                            isAnimationActive={false}
                            name="0 - 50%"
                            dataKey="low"
                            fill="#F94A56"
                            stackId="a"
                            label={
                              <BarChartLabelFlashcards
                                data={flashcards}
                                range={"low"}
                                onClick={flashcardsModal}
                                color="#010031"
                              />
                            }
                            barSize={30}
                          />
                          <Bar
                            isAnimationActive={false}
                            name="50 - 70%"
                            dataKey="medium"
                            fill="#ff7d86"
                            stackId="a"
                            label={
                              <BarChartLabelFlashcards
                                data={flashcards}
                                range={"medium"}
                                onClick={flashcardsModal}
                                color="#010031"
                              />
                            }
                            barSize={30}
                          />
                          <Bar
                            isAnimationActive={false}
                            name="70 - 100%"
                            dataKey="high"
                            fill="#ffbdc1"
                            stackId="a"
                            label={
                              <BarChartLabelFlashcards
                                data={flashcards}
                                range={"high"}
                                onClick={flashcardsModal}
                                color="#010031"
                              />
                            }
                            barSize={30}
                          />
                          <Legend wrapperStyle={{ left: 20 }} />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </>
              )}
            </Col>
          )}

          <Col
            span={11}
            style={{ ...dashboardstyles.contentPanel, paddingRight: 0 }}
          >
            {stackType?.keyMode === "part-time" && calendarMode === "week" ? (
              ""
            ) : (
              <>
                <h2 style={dashboardstyles.contentTitle}>{t("Absents")}</h2>
                <div
                  style={{ ...dashboardstyles.contentBody, height: "45vh" }}
                  className="table-container"
                >
                  {absencesLoading || dataLoading ? (
                    <Spin style={{ margin: "auto" }} />
                  ) : (
                    <SimpleBar style={{ height: "100%" }}>
                      <Table
                        columns={absenceColumns}
                        dataSource={batch
                          .filter(e => (e.absences ? true : false))
                          .sort((a, b) => b.absences - a.absences)}
                        pagination={false}
                      />
                    </SimpleBar>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      ) : (
        <Row style={styles.tableRow}>
          <Col
            span={11}
            offset={1}
            style={{ ...dashboardstyles.contentPanel, paddingLeft: 0 }}
          >
            {stackType?.keyMode === "part-time" ? (
              ""
            ) : (
              <>
                <h2 style={dashboardstyles.contentTitle}>{t("Absents")}</h2>
                {/* <div style={{ ...dashboardstyles.contentBody, height: '45vh', padding: '20px 55px 20px 30px' }}> */}
                <div
                  style={{ ...dashboardstyles.contentBody, height: "45vh" }}
                  className="table-container"
                >
                  {absencesLoading || dataLoading ? (
                    <Spin style={{ margin: "auto " }} />
                  ) : (
                    <SimpleBar style={{ maxHeight: "calc(30vh + 78px)" }}>
                      <Table
                        columns={dailyAbsenceColumns}
                        dataSource={batch.filter(
                          e =>
                            e.absMorning === true ||
                            e.absAfternoon === true ||
                            e.absEvening ||
                            e.absOfficeHours
                        )}
                        pagination={false}
                      />
                    </SimpleBar>
                  )}
                </div>
                {/* </div> */}
              </>
            )}
          </Col>

          <Col
            span={11}
            style={{ ...dashboardstyles.contentPanel, paddingRight: 0 }}
          >
            {stackType?.keyMode === "part-time" ? (
              ""
            ) : (
              <>
                <h2 style={dashboardstyles.contentTitle}>
                  {t("Ressenti de la") + " " + t("journée")}
                </h2>
                {/* <div style={{ ...dashboardstyles.contentBody, height: '45vh' }} className="table-container"> */}
                <div
                  style={{
                    ...dashboardstyles.contentBody,
                    padding: 40,
                    justifyContent: "space-around",
                    height: "45vh",
                  }}
                  id="feeling-container"
                >
                  {dataLoading ? (
                    <Spin style={{ margin: "auto" }} />
                  ) : yesterdayFeelings?.total === 0 ? (
                    <p style={styles.infoText}>{t("Aucune donnée")}</p>
                  ) : (
                    <>
                      <div style={styles.feeling_wrapper}>
                        <FeelingEmoji feeling={1} size="medium" />
                        <div style={styles.progress_wrapper}>
                          <Progress
                            strokeColor="#5247EA"
                            percent={Math.round(
                              (yesterdayFeelings["1"] /
                                yesterdayFeelings.total) *
                              100
                            )}
                            showInfo={false}
                            style={{ marginLeft: 5 }}
                          />
                          <span
                            onClick={() => {
                              feelingsModal(yesterdayFeelings["1"], 1);
                            }}
                            className="hover-underline"
                            style={{
                              ...styles.feeling_count,
                              left: `calc(${Math.round(
                                (yesterdayFeelings["1"] /
                                  yesterdayFeelings.total) *
                                100
                              )}% + 10px)`,
                            }}
                          >
                            {yesterdayFeelings["1"]}
                          </span>
                        </div>
                      </div>
                      <div style={styles.feeling_wrapper}>
                        <FeelingEmoji feeling={2} size="medium" />
                        <div style={styles.progress_wrapper}>
                          <Progress
                            strokeColor="#5247EA"
                            percent={Math.round(
                              (yesterdayFeelings["2"] /
                                yesterdayFeelings.total) *
                              100
                            )}
                            showInfo={false}
                            style={{ marginLeft: 5 }}
                          />
                          <span
                            onClick={() => {
                              feelingsModal(yesterdayFeelings["2"], 2);
                            }}
                            className="hover-underline"
                            style={{
                              ...styles.feeling_count,
                              left: `calc(${Math.round(
                                (yesterdayFeelings["2"] /
                                  yesterdayFeelings.total) *
                                100
                              )}% + 10px)`,
                            }}
                          >
                            {yesterdayFeelings["2"]}
                          </span>
                        </div>
                      </div>
                      <div style={styles.feeling_wrapper}>
                        <FeelingEmoji feeling={3} size="medium" />
                        <div style={styles.progress_wrapper}>
                          <Progress
                            strokeColor="#5247EA"
                            percent={Math.round(
                              (yesterdayFeelings["3"] /
                                yesterdayFeelings.total) *
                              100
                            )}
                            showInfo={false}
                            style={{ marginLeft: 5 }}
                          />
                          <span
                            onClick={() => {
                              feelingsModal(yesterdayFeelings["3"], 3);
                            }}
                            className="hover-underline"
                            style={{
                              ...styles.feeling_count,
                              left: `calc(${Math.round(
                                (yesterdayFeelings["3"] /
                                  yesterdayFeelings.total) *
                                100
                              )}% + 10px)`,
                            }}
                          >
                            {yesterdayFeelings["3"]}
                          </span>
                        </div>
                      </div>
                      <div style={styles.feeling_wrapper}>
                        <FeelingEmoji feeling={4} size="medium" />
                        <div style={styles.progress_wrapper}>
                          <Progress
                            strokeColor="#5247EA"
                            percent={Math.round(
                              (yesterdayFeelings["4"] /
                                yesterdayFeelings.total) *
                              100
                            )}
                            showInfo={false}
                            style={{ marginLeft: 5 }}
                          />
                          <span
                            onClick={() => {
                              feelingsModal(yesterdayFeelings["4"], 4);
                            }}
                            className="hover-underline"
                            style={{
                              ...styles.feeling_count,
                              left: `calc(${Math.round(
                                (yesterdayFeelings["4"] /
                                  yesterdayFeelings.total) *
                                100
                              )}% + 10px)`,
                            }}
                          >
                            {yesterdayFeelings["4"]}
                          </span>
                        </div>
                      </div>
                      <div style={styles.feeling_wrapper}>
                        <FeelingEmoji feeling={5} size="medium" />
                        <div style={styles.progress_wrapper}>
                          <Progress
                            strokeColor="#5247EA"
                            percent={Math.round(
                              (yesterdayFeelings["5"] /
                                yesterdayFeelings.total) *
                              100
                            )}
                            showInfo={false}
                            style={{ marginLeft: 5 }}
                          />
                          <span
                            onClick={() => {
                              feelingsModal(yesterdayFeelings["5"], 5);
                            }}
                            className="hover-underline"
                            style={{
                              ...styles.feeling_count,
                              left: `calc(${Math.round(
                                (yesterdayFeelings["5"] /
                                  yesterdayFeelings.total) *
                                100
                              )}% + 10px)`,
                            }}
                          >
                            {yesterdayFeelings["5"]}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {/* </div> */}
          </Col>
        </Row>
      )}
    </div>
  );
};

function mapStateToProps({ batchid, batchuserlist, stackType }) {
  return { batchid, batchuserlist, stackType };
}

export default connect(mapStateToProps, null)(TeacherDashboard);

const styles = {
  selectContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "24px",
  },
  select: {
    width: "200px",
  },
  snapshotRow: {
    height: "35vh",
    minHeight: "300px",
    display: "flex",
  },
  tableText: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#010031",
    margin: 0,
    fontFamily: '"Poppins", sans-serif',
  },
  tableResource: {
    marginTop: 10,
    marginBottom: 0,
    lineHeight: '180%',
    //whiteSpace: 'nowrap',
  },
  tableIcon: {
    marginLeft: 6,
    color: "rgba(249, 74, 86, 0.5)",
  },
  tableRow: {
    padding: "10px 24px",
  },
  studentRow: {
    borderBottom: "0.5px solid #E5E5E5",
  },
  emoji: {
    height: 27,
    width: "auto",
    marginRight: 14,
  },
  feeling_wrapper: {
    display: "flex",
    margin: "5px 0px",
  },
  feeling_count: {
    fontWeight: 600,
    fontSize: "12px",
    color: "#898C8F",
    position: "absolute",
    top: 5.6,
    cursor: "pointer",
  },
  progress_wrapper: {
    position: "relative",
    width: "80%",
    display: "flex",
    alignItems: "center",
  },
  tag: {
    borderRadius: "10px",
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
  },
  radarIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#5247EA",
    fontWeight: 600,
    fontSize: "10px",
    backgroundImage: "url('/images/radar.svg')",
    width: "70px",
    height: "70px",
    margin: "auto",
  },
  feelingText: {
    margin: 0,
    fontWeight: 300,
    color: "#989C8F",
    fontSize: "14px",
    fontFamily: '"Poppins", sans-serif',
    lineHeight: "14px",
  },
  infoText: {
    margin: "auto",
    fontWeight: 300,
    color: "#989C8F",
    fontSize: "14px",
    fontFamily: '"Poppins", sans-serif',
    maxWidth: "200px",
  },
  divAvance: {
    marginTop: "70px",
    width: "30px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  circleAvance: {
    border: "2px #5247EA solid",
    width: "12px",
    height: "12px",
    borderRadius: 100,
    margin: "4px",
  },
  fontAvance: {
    marginTop: "2px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
  },
  numberAvance: { fontFamily: "Poppins", fontSize: "14px", fontWeight: 600 },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: "url('/images/backgrounds/ArianeBackground.png')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "60vh",
    overflow: "scroll",
  },
  badge: {
    height: "18px",
    width: "80px",
    backgroundColor: "white",
    color: "#5247EA",
    fontSize: "14px",
    fontWeight: 600,
  },
  lightFont: { fontSize: "14px", fontWeight: 300 },
  heavyFont: { fontSize: "14px", fontWeight: 600 },
  progressTable: {
    width: '13vw',
    textAlign: 'center',
    fontSize: 12,
  },
  progress: {
    display: 'block',
    textAlign: 'center',
    fontWeight: 600,
    color: '#010031'
  }
};
