import React, {useState, useEffect, useCallback} from 'react';
import {Col, Row, Button, Modal, message, Upload, Icon} from 'antd';
import Webcam from "react-webcam";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default function WebCam(props) {

  const [imageData, setImageData] = useState(null);
  const [croppedImg, setCroppedImg] = useState(null);

  const webcamRef = React.useRef(null);
  const cropper = React.useRef(null);

  const capture = () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImageData(imageSrc)
  };

  // const uploadPic = (event) => {
  //   console.log(event.file.originFileObj);
  //   getBase64(event.file.originFileObj, imageUrl =>
  //     setImageData(imageUrl)
  //   );
  // };

  const uploadPic = (file) => {
    console.log(file);
    getBase64(file, imageUrl =>
      setImageData(imageUrl)
    );
  };

  const onCancelClick = () => {
    setImageData(null);
    props.handleCancel()
  };

  const onRetryClick = (e) => {
    e.persist();
    setImageData(null)
  };

  const getImageCropped = () => {

    let dataUrl = cropper.current.getCroppedCanvas({width: 300, heigth: 300}).toBlob(function(blob){
      const data = new FormData();
      data.append('image', blob, props.studentId);

      fetch('https://api-v1.lacapsule.academy/avatar', {
        method: 'POST',
        body: data
      })
      .then(res => res.json())
      .then(data => {
        if (data.result === true) {
          fetch(`${global.URI_BACKEND}/user/avatar-update`, {
            method: 'PUT',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: `studentId=${props.studentId}`
          })
          .then(res => res.json())
          .then(data => {
            if (data.result === true) {
              message.success('Photo enregistrée avec succès !')
              setImageData(null);
              setCroppedImg(null);
              props.handleCancel()
            } else {
              message.error('Erreur lors de l\' enregistrement de l\'avatar...')
            }
          })
          .catch(error => console.log('erreur lors du fetch...', error))
        } else {
          message.error('Erreur lors de l\' enregistrement de l\'avatar...')
        }
      })
      .catch(error => console.log(error))
    }, 'image/jpeg', 0.9);
  };

  const uploadProps = {
    beforeUpload: file => {
      uploadPic(file);
      return false;
    },
    showUploadList: false
  };

  return (
    <div>
      {imageData === null
        ? <div>
            <Webcam
              audio={false}
              mirrored={true}
              width={450}
              heigth={450}
              screenshotFormat="image/jpeg"
              ref={webcamRef}
              // videoConstraints={{deviceId: device.deviceId}}
            />
            <Row style={styles.marginRow} type="flex" align="middle">
              <Col span={12} align="center" justify="center">
                <Upload {...uploadProps}
                    // onChange={uploadPic}
                >
                  <Button size="large" icon="upload">Click to upload</Button>
                </Upload>
              </Col>
              <Col span={12} align="center" justify="center">
                <Button type="primary" size="large" icon="camera" onClick={capture}>Snapshot</Button>
              </Col>
            </Row>
          </div>
        : <div className={styles.cropper}>
            <Row type="flex" align="middle" justify="center">
              <Cropper
                background={false}
                viewMode={1}
                // highlight={false}
                src={imageData}
                ref={cropper}
                style={{ width: 450, height: 450 }}
                aspectRatio={4 / 4}
                autoCropArea={1}
                minCropBoxHeight={200}
                minCropBoxWidth={200}
              />
            </Row>
            <Row style={styles.marginRow} type="flex" justify="space-around" align="middle">
              <Col span={6} align="center" justify="center">
                <Button size="large" icon="backward" onClick={onRetryClick}>Retry</Button>
              </Col>
              <Col span={6} align="center" justify="center">
                <Button type="primary" size="large" icon="check" onClick={getImageCropped}>Crop & save</Button>
              </Col>
            </Row>
          </div>
      }
    </div>
  )
}

const styles = {
  marginRow: {
    marginTop: 10
  },
  cropper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}
