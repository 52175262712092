import React, { Component } from 'react';
// import ReactQuill from 'react-quill';
import AceEditor from 'react-ace';
import moment from 'moment';
import { Col, Row, Button, Icon, Tabs, Tooltip, Steps, Modal, notification } from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-monokai';
/* import './Code.css';

import Worker from './file.worker.js';
import * as esprima from 'esprima';
import test from './esprima/esprima_tests';
import checkRank from '../../../../utils/checkRank'; */

const { TabPane } = Tabs;
const { Step } = Steps;

class testAce extends Component {
    defaultDisplay = {
        projSpan: 10,
        editContOffset: 0,
        editContSpan: 14,
        editSpan: 12,
        viewSpan: 12
    }

    state = {
        loading: false,
        contentDisplay: null,
        fullscreen: false,
        index: 0,
        width: '1000',
        practiceIndex: 0,
        practiceSelected: 0,
        contentTab: [],
        challengeHeight: 0,
        redirect: false,
        progressList: [],
        percents: {},
        practices: [], // already complete practices
        practices2: [], // ??
        loadCode: undefined,
        exerciseSection: [],
        confirmModalDisplay: false,
        modifiedPractice: null,
        current: 0
    }




    render() {
        const { sPractice, hInherit, editorTabBar, editorContainer, tabPaneBrowser, editor, prevContainer, preview, fsButton, fsIcon, runButton } = styles;
        const { practice, user, title } = this.props;





        return (<>
            <AceEditor
                showPrintMargin={false}
                id="editor"
                style={{ height: 200, width: '100%' }}
                mode={'javascript'}
                theme='monokai'
                name={`aceEditor-`}
                value={''}
                editorProps={{ $blockScrolling: false }}
                setOptions={{ useWorker: false }}
                wrapEnabled={true}
                width={400}

            />



        </>);
    }
}



export default testAce;

const styles = {
    sPractice: { flex: 1 },
    sInstruction: { marginTop: '10px' },
    hInherit: { display: 'flex', height: '100%' },
    editorTabBar: { margin: 0, borderColor: '#272821' },
    editorContainer: { flex: 1, height: '100%' },
    tabPaneBrowser: { backgroundColor: 'white', height: '100%' },
    editor: { width: '100%', height: '100%' },
    prevContainer: { height: 'inherit', backgroundColor: '#000000' },
    preview: { height: 'inherit', width: '100%', border: '0px solid #e8e8e8' },
    fsButton: { position: 'absolute', padding: 0, fontSize: 18, top: 7, right: 5, zIndex: 9, cursor: 'pointer' },
    fsIcon: { color: '#1890ff' },
    spIcon: { float: 'left', paddingRight: 10, paddingTop: 15 },
    runButton: { margin: 4, backgroundColor: '#1890ff', border: 'transparent', fontSize: 14, width: 70 },
    space: { marginTop: 16 }
};