import React, { Component } from 'react';
import { Button, message } from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';


export default class CopyButton extends Component {

    constructor(){
        super();
        this.onClick = this.onClick.bind(this);
    }


    onClick(){
        message.success('Copied !');
    }

    render() {
        return (
            <CopyToClipboard text={this.props.valueToCopy}>
                <div style={{ position: 'absolute', right: '5px', top: '5px'}} >
                    <Button size="small" onClick={this.onClick} icon="copy" />
                </div>
            </CopyToClipboard>
        
        );
    }
}