import React from 'react'

//STYLES______________________
import { dashboardstyles } from '../dashboardstyles'

export default function DataDisplay({ title, data, style = {}, insetStyle = {}}) {
    return (
        <div style={{ ...dashboardstyles.contentPanel, ...style }}>
            <h2 style={{ ...dashboardstyles.contentTitle, textAlign: 'start' }}>{title}</h2>
            <div style={{ ...styles.purpleContainer, ...insetStyle }} >
                <span style={styles.numberStyle}>{data}</span>
            </div>
        </div>
    )
}

const styles = {
    purpleContainer: {
        color: '#ffffff',
        backgroundColor: '#5247ea',
        width: '100%',
        height: '100px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        borderRadius: 4,
        backgroundImage: 'url(/images/backgrounds/bg_quizz_3.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingRight: 10
    },
    numberStyle: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: 36,
        fontFamily: '"Poppins", sans-serif'
    },
    contentPanel: {
        padding: '10px 24px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'roboto',
        fontWeight: 300,
        height: '100%',
        alignItems: 'flex-start',
        width: '100%'
    },
}
