import typeChecker from '../../typeChecker';

function arrayExpression(tc_arr = {}, pb_arr = {}) {
  const elements = [];

  tc_arr_loop: for (let i = 0; i < tc_arr.elements?.length; i++) {
    const tc_elem = tc_arr.elements[i];

    for (let j = 0; j < pb_arr.elements?.length; j++) {
      const pb_elem = pb_arr.elements[j];

      elements[i] = typeChecker(tc_elem, pb_elem);

      if (elements[i]) {
        continue tc_arr_loop;
      }
    }
  }

  return (elements.length === tc_arr.elements?.length && elements.every(e => e === true));
}

export default arrayExpression;