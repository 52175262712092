import typeChecker from '../../typeChecker';

function assignmentExpression(tc_assign = {}, pb_assign = {}) {
  // '=' | '*=' | '**=' | '/=' | '%=' | '+=' | '-=' | '<<=' | '>>=' | '>>>=' | '&=' | '^=' | '|=';
  const check = {};

  if (tc_assign.operator) check.operator = false;
  if (tc_assign.left) check.left = false;
  if (tc_assign.right) check.right = false;

  if (pb_assign.operator && tc_assign.operator === pb_assign.operator) {
    check.operator = true;
  }

  if (pb_assign.left?.type && tc_assign.left?.type === pb_assign.left?.type) {
    check.left = typeChecker(tc_assign.left, pb_assign.left);
  }

  if (pb_assign.right?.type && tc_assign.right?.type === pb_assign.right?.type) {
    check.right = typeChecker(tc_assign.right, pb_assign.right);
  }

  return Object.values(check).every(e => e === true);
}

export default assignmentExpression;