import React, {Component} from 'react';
import { Col, Descriptions, Icon, Spin, Button, Tooltip, Modal, Input, Form, List, Avatar, Divider, Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import uid2 from 'uid2';


const { Item } = Form;
const { Option } = Select;
moment.locale('en');

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class CRMInfo extends Component {

    deleteItem = () => {
        fetch(global.URI_BACKEND + `/crm/${this.props.CRM._id}`, {method:'DELETE'}).then(res => res.json()).then(async data => data.success ? this.props.delCRM(this.props.index) : null)
    
    }

  render() {
    const { CRM } = this.props;
   
    return (
      <div style={{backgroundColor:'white',display:'flex', alignItems:'center', borderRadius:20, padding:20, margin:15, borderBottom:"1px solid #E7E8ED", borderRight:"1px solid #E7E8ED"}}>
        <Descriptions title={CRM.firstname+' '+CRM.lastname}>
          <Descriptions.Item label="Email">{CRM.email}</Descriptions.Item>
          <Descriptions.Item label="CRM ID">{CRM.crmId}</Descriptions.Item>
          <Descriptions.Item label="Type">{CRM.type} </Descriptions.Item>
        </Descriptions>
        <Icon type="close-circle" style={{fontSize:19, color:'#F94A56', marginRight:15}} onClick={this.deleteItem}/>
      </div>
    );
  }
}


class EditCRM extends Component {
    state = {
        visible: false,
        confirmLoading: false,
        logError: '',
        allStaff: [],
        CRM: [],
        loadingScreen: true,
    };
    
    componentDidMount() {
      this.props.form.validateFields();
      fetch(global.URI_BACKEND + '/crm').then(res => res.json()).then(async data => data?.success ? this.setState({CRM: data.crm, allStaff: data.data, loadingScreen: false}) : null)
    }

    showModal = () => {
        this.setState({visible: true});
    };


    handleSubmit = (e) => {
      e.preventDefault();

      this.setState({ confirmLoading: true, logError: '' }, () => {

        this.props.form.validateFields((err, values) => {
          if (!err) {

            fetch(global.URI_BACKEND + '/CRM', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
              }).then(res => res.json()).then(async data => {
                if (data.success) {
                
                let tempCRM = [...this.state.CRM];
                tempCRM.unshift(data.CRM)
                this.setState({ visible: false, confirmLoading: false, CRM: tempCRM, allStaff: this.state.allStaff.filter(e => e.ownerId !== values.id)});
                this.props.form.resetFields();
                }else{
                    this.setState({ logError: "Erreur, l'utilisateur CRM n'a pas pu être créé" });
                }
            });
          }
        });
      });
    };

    handleCancel = () => {
      this.setState({visible: false});
    };

    delCRM = (i) => {
      let tempCRM = [...this.state.CRM];
      tempCRM.splice(i,1);
      this.setState({CRM: tempCRM});
    };


  render() {

    const { center } = styles;
    const { visible, confirmLoading, allStaff, CRM, loadingScreen, logError} = this.state;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    const typeError = isFieldTouched('type') && getFieldError('type');
    const idError = isFieldTouched('id') && getFieldError('id');

    let CRMlist = CRM.map((CRM, i)=> {
        return (<CRMInfo key={uid2(5)} index={i} CRM={CRM} delCRM={this.delCRM} allStaff={allStaff}/>);
      });

    return (
    <Col span={24}>
  
      <Tooltip title="Créer un nouvel utilisateur CRM" placement="right">
        <Button type="primary" ghost shape="circle" onClick={this.showModal} style={{fontSize: 20, margin: 10, marginLeft: 25}}>+</Button>
      </Tooltip>


      {loadingScreen && <div style={{...center, height:500}}><Spin indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />} /></div>}

      {CRMlist} 

      {/*Modal create CRM*/}
      <Modal
        title="Créer un nouvel utilisateur CRM"
        visible={visible}
        onOk={this.handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={this.handleCancel}
        okButtonProps={{disabled:hasErrors(getFieldsError())}}
      >
        <Form style={{...center, flexDirection:'column'}}>

          <Item style={{marginBottom:5, width:'80%'}} name="type" validateStatus={typeError ? 'error' : ''} help={typeError || ''} >
          {getFieldDecorator('type', {
              rules: [{ required: true, message: 'Please enter type.' }]
          })(<Select  placeholder="Select a type">
              <Option value="sales">Sales</Option>
              <Option value="admissions manager">Admissions Manager</Option>
            </Select> )} 
          </Item> 

          <Item style={{marginBottom:5, width:'80%'}} name="id" validateStatus={idError ? 'error' : ''} help={idError || ''} >
          {getFieldDecorator('id', {
              rules: [{ required: true, message: 'Please enter CRM ID.' }]
          })(<Select  placeholder="Select a CRM ID">
              {allStaff.map((el, i) =>{
                return <Option key={i} value={JSON.stringify(el)}>{el.email}</Option>
              })}
            </Select> )} 
          </Item> 
          {logError && <p style={{color:'red'}}>{logError}</p>}
        </Form>
      </Modal>
    </Col>);
  }
}

function mapStateToProps({ user }) {
  return { user };
}

const WrappedEditCRM = Form.create({ name: 'create_CRM' })(EditCRM);
export default connect(mapStateToProps, null)(WrappedEditCRM);

const styles = {
    item: {marginBottom:5, width:'88%'},
    select: {width: 120, marginBottom:15},
    center: { display: 'flex', justifyContent: "center", alignItems: 'center' },
    m5: {margin:10}
}