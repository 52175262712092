import React, { Component } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

import checkRank from '../../utils/checkRank';

const { Item } = Menu;

class ArianeMenu extends Component {
  menuSelect = e => {
    const { currentSelected, onMenuClick, itemSelected } = this.props;

    if (currentSelected !== null) onMenuClick(e.key);
    itemSelected(e.key);
  }

  render() {
    const { menu } = styles;
    const { currentSelected, type, t } = this.props;

    return (<Menu onClick={this.menuSelect} selectedKeys={currentSelected} mode="horizontal" style={menu}>
      <Item key="today">
        <Link to={`/home/today`}>{t("Aujourd'hui")}</Link>
      </Item>
      <Item key="projects">
        <Link to={`/home/projects`}>{t("Projets")}</Link>
      </Item>
      <Item key="ressources">
        <Link to={`/home/ressources`}>{t("Ressources")}</Link>
      </Item>
      <Item key="products">
        <Link to={`/home/products`}>MVP</Link>
      </Item>
      {
        (checkRank({ type }, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })) &&
        <Item key="submitted">
          <Link to={`/home/submitted`}>{t("MVP soumis")}</Link>
        </Item>
      }
    </Menu>);
  }
};

const styles = {
  menu: { display: "flex", justifyContent: "center" }
};

function mapDispatchToProps(dispatch) {
  return {
    itemSelected: function (key) {
      dispatch({ type: 'currentSelection', currentSelected: key });
    }
  }
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(ArianeMenu));