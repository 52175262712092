import React, { Component } from "react";
import { Drawer, Icon, notification, Button } from "antd";
import Webcam from "../../components/webcam";
import { connect } from "react-redux";
import moment from "moment";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

class AddPhoto extends Component {
  state = {
    step: this.props?.step,
    selectedNotion: null,
    selectedProject: null,
    fetching: false,
    closable: false,
    hasSigned: false,
    hasFeeling: this.props?.hasFeeling,
  };

  onClose = () => {
    const { closeDrawer } = this.props;

    closeDrawer();
  };

  handleUpload = () => {
    const { onAvatarSave } = this.props;
    this.setState({ closable: true });
    onAvatarSave();
    this.onClose();
  };

  render() {
    const { step, selectedNotion, selectedProject, closable, fetching } =
      this.state;
    const { visible, user } = this.props;

    return (
      <Drawer
        drawerStyle={{ background: "#f7f7f7", minHeight: "100vh" }}
        placement="right"
        onClose={this.onClose}
        visible={visible}
        destroyOnClose={true}
        closable={false}
        maskClosable={closable}
        width={470}
        id="add-photo"
      >
        <img
          src="/images/backgrounds/emargement.jpg"
          alt="students smiling"
          className="satisfaction-img"
        />

        <div style={styles.textContainer}>
          <h1 style={styles.title}>Avatar</h1>
          <p style={styles.text}>Bienvenue à La Capsule !</p>
          <p style={{ ...styles.text, marginBottom: 14 }}>
            Afin de faciliter l'échange avec l'équipe et avec tes collègues,
            merci d'ajouter une photo à ton profil Ariane avant de continuer.
            😊
          </p>

          <Webcam
            studentId={user._id}
            userConnected={user._id}
            handleCancel={this.handleUpload}
          />
        </div>
      </Drawer>
    );
  }
}

function mapStateToProps({ user, batch, batchid, stackid }) {
  return { user, batch, batchid, stackid };
}

function mapDispatchToProps(dispatch) {
  return {
    onAvatarSave: function () {
      dispatch({ type: "userAvatar", avatar: true });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddPhoto));

const styles = {
  title: {
    fontFamily: '"Raleway", sans-serif',
    textTransform: "uppercase",
    fontSize: "1rem",
    letterSpacing: ".1em",
    textAlign: "center",
    fontWeight: 700,
    margin: "10px 0px 5px 0px",
  },
  text: {
    fontFamily: '"Raleway", sans-serif',
    fontWeight: 500,
    margin: "8px 0px",
    textAlign: "center",
    boxSizing: "border-box",
  },
  textContainer: {
    position: "relative",
    left: -5,
    boxSizing: "border-box",
    width: 470,
    padding: "0px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
