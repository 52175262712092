import React, { Component, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Button, Modal, Card, Row, Col, Layout, Checkbox, Popover } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker2";
import { getCurrentTime } from "../../functions/currentTime";
import PinInput from "react-pin-input";
// import socketIOClient from "socket.io-client";

import Draw from "../../components/draw";

// TRANSLATION -------------------
import { withTranslation } from "react-i18next";

//STYLES________________________________
import "./admin.css";

const { Content } = Layout;

class StudentList extends Component {
  state = {
    id: null,
    student:
      this.props.user && this.props.user.type === "student"
        ? this.props.user
        : {},
    visible:
      this.props.user && this.props.user.type === "student" ? true : false,
    date: [moment.utc().startOf("day"), moment.utc().endOf("day")],
    newKey: Date.now(),
    multiple: true,
    showHideDates: true,
    popoverIsOpen: false,
    popoverValue: "",
    duringBatch: null,
    allEmargements: [],
  };

  popoverValidation = "2016";

  componentDidMount = () => {
    const { date } = this.props.batch;

    const pageLocked = localStorage.getItem("pageLocked");
    if (pageLocked) this.setState({ showHideDates: false });
    
    //get current time
    this.newEmargement();

    // Check if supposed to sign every minute
    const signingIntervalInMinutes = 1;

    const signingInterval = setInterval(() => {
      this.newEmargement();
    }, signingIntervalInMinutes * 60 * 1000);

    this.setState({ signingInterval });

    if (!Array.isArray(date)) {
      const now = moment.utc().startOf("day");
      const end = moment.utc(date.end).endOf("day");
      this.setState({
        date: [moment.utc(date.start).startOf("day"), now > end ? end : now],
      });
    }
  };

  componentWillUnmount() {
    if (this.state.signingInterval) {
      clearInterval(this.state.signingInterval);
      this.setState({ signingInterval: null });
    }
  }

  newEmargement = async () => {
    const { _id, currentDay } = this.props.batch;
    //get current time
    let { duringBatch } = await getCurrentTime(this.props.batch.currentDay);
    this.setState({ duringBatch });

    if (!duringBatch) {
      this.setState({ allEmargements: [] });
      return;
    }
    
    //get emargement for every students
    fetch(
      `${global.URI_BACKEND}/emargement/batch/${_id}/${duringBatch}/${currentDay.date}`
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ allEmargements: data.allEmargements });
      });
  };

  onSign = () => {
    this.onCancel();
    this.newEmargement();
  };

  togglePopover = () => {
    if (!this.props.navigationIsVisible) {
      this.setState({ popoverIsOpen: !this.state.popoverIsOpen });
    }
  };

  handlePopoverChange = value => {
    this.setState({ popoverValue: value });
  };

  showDrawer = student => {
    if (!this.props.navigationIsVisible) {
      if (student._id === (this.state.student && this.state.student._id)) {
        this.setState({ visible: !this.state.visible });
      } else {
        this.setState({
          student,
          visible: true,
        });
      }
    }
  };

  onChange = (date, dateString) => {
    if (!Array.isArray(date)) {
      const { start } =
        this.props.batch && "date" in this.props.batch && this.props.batch.date;
      date = [moment.utc(start).startOf("day"), date];
    }
    this.setState({ date });
  };

  onCancel = () => {
    this.setState({ visible: false });
  };

  disabledDate = current => {
    const {
      date: { start, end },
    } = this.props.batch;

    const isBeforeBatch = moment
      .utc(current)
      .isBefore(moment.utc(start).startOf("day"));
    const isAfterToday = moment.utc().endOf("day");
    const isAfterBatch = moment
      .utc(current)
      .isAfter(moment.utc(end))
      .endOf("day");

    const weekDay = !(
      moment.utc(current).day() !== 0 || moment.utc(current).day() !== 6
    );

    return (
      current && weekDay && (isBeforeBatch || isAfterToday || isAfterBatch)
    );
  };

  ranges = () => {
    const { date } =
      this.props.batch && "date" in this.props.batch && this.props.batch;

    if (!date) return [moment.utc().startOf("day"), moment.utc().endOf("day")];

    const start = moment.utc(date.start).startOf("day");

    let end = moment.utc().endOf("day");
    if (moment.utc().isAfter(moment.utc(date.end).endOf("day")))
      moment.utc(date.end).endOf("day");

    return { "This Batch": [start, end] };
  };

  checkboxChange = e => {
    const { date } = this.props.batch;

    const start = moment.utc(date.start).startOf("day");
    // const now = moment.utc().startOf('day');
    // const end = moment.utc(date.end).endOf('day');

    let end = moment.utc().endOf("day");
    if (moment.utc().isAfter(end)) end = moment.utc(date.end).endOf("day");

    this.setState({ date: [start, end], multiple: e.target.checked });
  };

  enableRange = () => {
    const {
      date: { start, end },
    } = this.props.batch;

    return {
      min: moment.utc(start || 0).startOf("day"),
      max: moment.utc(end || 0).endOf("day"),
    };
  };

  onDateChange = (selectedDate, index) => {
    let { date } = this.state;

    if (index < 0 && index > 1) return;
    if (!Array.isArray(date)) date = [null, null];
    date[index] = selectedDate;
    if (date[1] < date[0]) date = [date[1], date[0]];

    this.setState({ date });
  };

  render() {
    const {
      background,
      content,
      contentBackground,
      link,
      mb,
      bodyModal,
      modalContent,
      goBackAndDates,
    } = styles;
    const { user, batch, userList, t } = this.props;
    const {
      date,
      multiple,
      popoverValue,
      student,
      duringBatch,
      allEmargements,
    } = this.state;

    const datesAreDifferent = !moment.utc(date[0]).isSame(moment.utc(date[1]));
    let datesInUrl = "";
    if (multiple && datesAreDifferent)
      datesInUrl = `${moment.utc(date[0], "YYYY-MM-DD").format("YYYY-MM-DD")}/`;
    datesInUrl += moment.utc(date[1], "YYYY-MM-DD").format("YYYY-MM-DD");

    const url =
      date && date[0] && date[1]
        ? `${global.URI_BACKEND}/emargement/v2/batch/${batch._id}/date/${datesInUrl}?from=${window.location.href}`
        : null;

    if (this.props.user.type === "student") {
      return <Redirect to={`/studentList`} />;
    } else {
      const { min, max } = this.enableRange();

      const multipleDates = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            className="datepicker-input__custom"
            value={date[0]}
            onChange={value => this.onDateChange(value, 0)}
            isGregorian={true}
            inputFormat="YYYY-MM-DD"
            showTodayButton={false}
            timePicker={false}
            min={min}
            max={max}
            style={{ width: 130 }}
          />
          <span id="datepicker-input__span">~</span>
          <DatePicker
            className="datepicker-input__custom"
            value={date[1]}
            onChange={value => this.onDateChange(value, 1)}
            isGregorian={true}
            inputFormat="YYYY-MM-DD"
            showTodayButton={false}
            timePicker={false}
            min={min}
            max={max}
            style={{ width: 130 }}
          />
        </div>
      );

      return (
        <div>
          <Layout style={background}>
            <Content style={content}>
              <div style={contentBackground}>
                <Row style={{ padding: "30px 0" }}>
                  <Col offset={1} span={22}>
                    <div style={goBackAndDates}>
                      <Popover
                        id="PINPopover"
                        placement="right"
                        content={
                          this.state.popoverIsOpen && (
                            <PinInput
                              length={4}
                              initialValue=""
                              secret
                              value={popoverValue}
                              onChange={this.handlePopoverChange}
                              type="numeric"
                              inputMode="number"
                              focus={true}
                              inputStyle={{ width: 30, height: 30 }}
                              onComplete={value => {
                                if (value === this.popoverValidation) {
                                  this.props.showHideNavigation();
                                  this.setState({
                                    popoverIsOpen: false,
                                    showHideDates: true,
                                  });
                                  localStorage.removeItem("pageLocked");
                                  this.props.togglePageLocked(false);
                                }
                              }}
                            />
                          )
                        }
                        title="Entrez votre code PIN"
                        trigger="click"
                        visible={this.state.popoverIsOpen}
                        onVisibleChange={this.togglePopover}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            if (this.props.navigationIsVisible) {
                              this.props.showHideNavigation();
                              this.setState({ showHideDates: false });
                              localStorage.setItem("pageLocked", true);
                            }
                          }}
                        >
                          <i className="icon-lock" style={{ fontSize: 18 }} />
                        </Button>
                      </Popover>
                      {this.state.showHideDates && (
                        <Row style={mb}>
                          <Col>
                            <Checkbox
                              defaultChecked={true}
                              onChange={this.checkboxChange}
                            >
                              {t("Sélectionner plusieurs jours")}
                            </Checkbox>
                          </Col>
                          <Col style={{ display: "flex" }}>
                            {multiple ? (
                              multipleDates
                            ) : (
                              <DatePicker
                                value={date[1]}
                                onChange={value => this.onDateChange(value, 1)}
                                isGregorian={true}
                                inputFormat="YYYY-MM-DD"
                                showTodayButton={false}
                                timePicker={false}
                                min={min}
                                max={max}
                              />
                            )}
                            <Button
                              type="primary"
                              style={link}
                              disabled={date ? false : true}
                              href={url}
                              target="_blank"
                            >
                              {t("Link")}
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <Avatar
                      navigationIsVisible={this.props.navigationIsVisible}
                      allEmargements={allEmargements}
                      duringBatch={duringBatch}
                      showDrawer={this.showDrawer}
                      batchName={batch.name}
                      batchId={batch._id}
                      user={user}
                      userList={userList}
                    />

                    <Modal
                      closeIcon={<CloseOutlined style={{ fontSize: 25, color: "#F94A56", position: "relative", top: 40, right: 40 }} />}
                      width={"100vw"}
                      style={{ padding: 0, top: 0, position: "absolute", margin: 0, left: 0 }}
                      centered
                      visible={this.state.visible}
                      onCancel={this.onCancel}
                      footer={null}
                      bodyStyle={bodyModal}
                    >
                      <div style={modalContent} className="emargement-panel-modal">
                        <img
                          alt={student._id}
                          style={styles.avatar}
                          src={
                            student.avatar
                              ? `${global.URI_FRONTEND}/images/avatar/${student._id}.jpg`
                              : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`
                          }
                          //onError={(e) => e.target.src = `${global.URI_FRONTEND}/avatar/_male-default.jpg`}
                        />
                        <h2>{student.fullname}</h2>
                        <Draw
                          newKey={Date.now()}
                          duringBatch={duringBatch}
                          batchDates={batch.date}
                          showDrawer={this.showDrawer}
                          student={this.state.student}
                          batchId={batch._id}
                          onSend={this.onSign}
                          size="large"
                        />
                      </div>
                    </Modal>
                  </Col>
                </Row>
              </div>
            </Content>
          </Layout>
        </div>
      );
    }
  }
}

class Avatar extends Component {
  capitalize = str => str.replace(/(?:^|\s)\S/g, a => a.toUpperCase());

  constructor(props) {
    super(props);
    this.state = {
      stats: {
        emargement: {
          morning: true,
          afternoon: false,
        },
      },
    };
  }

  state = {
    visible: false,
  };

  showDrawer = student => {
    const { duringBatch } = this.props;
    if (duringBatch) {
      this.props.showDrawer(student);
    }
  };

  render() {
    const { emargementSignature } = styles;
    let list = [];
    if (this.props.userList) {
      for (var i = 0; i < this.props.userList.length; i++) {
        if (this.props.userList[i].type === "student") {
          let e = this.props.userList[i];

          const personalEmargement = this.props.allEmargements.find(
            em => em.user === e._id
          );

          let card = (
            <Col
              key={i}
              xs={12}
              sm={5}
              lg={7}
              xl={5}
              xxl={4}
              className="user-card"
            >
              <Card
                onClick={() => this.showDrawer(e)}
                bordered={false}
                bodyStyle={styles.cardBodyStyle}
                style={{
                  cursor: this.props.navigationIsVisible ? "auto" : "pointer",
                  minHeight: 240,
                }}
              >
                <img
                  alt={e._id}
                  style={styles.avatar}
                  src={
                    e.avatar
                      ? `${global.URI_FRONTEND}/images/avatar/${e._id}.jpg`
                      : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`
                  }
                  onError={e =>
                    (e.target.src = `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`)
                  }
                />

                {/* <div style={styles.mainLine} /> */}
                <div>
                  <p
                    style={
                      e._id !== this.props.user._id
                        ? styles.userInfo
                        : styles.ownInfo
                    }
                  >
                    {e.firstname} {e.lastname}
                  </p>
                </div>

                {/* Test Matin et soir infos ---- */}
                {personalEmargement && (
                  <img
                    style={emargementSignature}
                    src={personalEmargement.signature}
                    alt=""
                  />
                )}
              </Card>
            </Col>
          );

          let userFound = false;
          for (let el of e.batchList) {
            if (this.props.batchId === el.batchId && el.type === "student") {
              userFound = true;
              list.push(card);
            }
          }
          if (!userFound) {
            list.push(card);
          }
        }
      }
    } else {
      return <Redirect to="/" />;
    }

    return (
      <Col style={{ padding: 10 }}>
        {list.length > 0 ? (
          <Row style={styles.userList}>
            <h3 style={{ width: "80%", fontFamily: "'Poppins', sans-serif" }}>{this.props.batchName}</h3>
            <div style={styles.mainLine} /> {list}
          </Row>
        ) : null}
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
    userList: state.batchuserlist,
    user: state.user,
    batch: state.batch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    togglePageLocked(pageLocked) {
      dispatch({ type: "togglePageLocked", pageLocked })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StudentList));

const styles = {
  userList: {
    // backgroundColor: '#fff',
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 15,
    textAlign: "center",
    marginBottom: 10,
  },
  avatar: {
    width: "80%",
    maxWidth: 115,
    borderRadius: "50%",
    marginBottom: 20
  },
  userInfo: {
    margin: 0,
    fontSize: 14,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold"
  },
  ownInfo: {
    margin: 0,
    fontSize: 13,
    color: "#097D8A",
  },
  mainLine: {
    height: 1,
    width: "100%",
    //marginLeft: '10%',
    backgroundColor: "lightGrey",
    marginBottom: 30,
  },
  background: {
    // backgroundColor: 'white'
  },
  content: {
    minHeight: "100vh",
  },
  goBackAndDates: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentBackground: {
    backgroundColor: "transparent",
    // minHeight: '100vh'
    height: "100%",
  },
  cardBodyStyle: {
    background: "transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: "center",
    padding: "40px 25px"
  },
  teacher: {
    fontSize: 11,
    fontStyle: "italic",
    color: "#EB5D56",
  },
  assistant: {
    fontSize: 11,
    fontStyle: "italic",
    color: "#097D8A",
  },
  student: {
    fontSize: 11,
    fontStyle: "italic",
    color: "#808080",
  },
  emargementSignature: {
    width: "100%",
  },
  link: {
    marginLeft: 10,
  },
  mb: {
    marginBottom: 10,
  },
  bodyModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100vh",
    minHeight: "100vh",
    backgroundColor: "#F6F6FA",
    borderRadius: 10,
    width: "100vw",
    paddingTop: "120px"
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 580
  },
  checkbox: {
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    justifyContent: "start",
    position: "absolute",
    left: "-4%",
    top: "3%",
    fontWeight: "bold",
    fontSize: "13px",
  },
  check: {
    marginTop: "5px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "Column",
    fontSize: "13px",
  },
};
