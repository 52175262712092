import React, { Component } from 'react';
import moment from 'moment';

import { Redirect} from "react-router-dom";
import { Card, Row, Col, Layout, Badge, Button, DatePicker, Icon } from 'antd';
import { connect } from 'react-redux';

import Userpanel from './userpanel';
import Header from '../header';
import SideMenu from '../sidemenu';

import checkRank from '../utils/checkRank';

const { Content } = Layout;

const dateFormat = 'YYYY/MM/DD';


class Avatar extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      student: []
    }
  }

  async componentDidMount() {

    console.log('----------Dans mon DidMount--------------')
    console.log('test des props userlist', this.props.userList)
    const { userList } = this.props;
    const studentList = userList.filter(e => e.type === 'student');

    // On met à jour notre state des etudiants avec le store
    await this.setState({ student: studentList }, () => {
      // Une fois que le setState a été fait
      // On fetch notre route Stats avec l'id du batch en cours pour recevoir les stat de tous les etudiants
      this.fetchStats(this.props.date);
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      await this.fetchStats(this.props.date);
    }
  }

  fetchStats = date => {
    fetch(`${global.URI_BACKEND}/stats/students/batch/${this.props.batchId}/dates/${moment(date).startOf('day', 'YYYY-MM-DD').format('YYYY-MM-DD')}/${moment(date).endOf('day', 'YYYY-MM-DD').format('YYYY-MM-DD')}`).then(response => response.json()).then(data => {
      const { batch: { daysSinceBeginning = 0 } } = data;
      const studentArray = [];
      for (const e of this.state.student.slice()) {
        delete e.stats;
        if (!e.stats) e.stats = {};
        if (!e.stats.requestDiffAverage) e.stats.requestDiffAverage = 0;
        for (const el of data.emargements) {
          if (e._id === el.student._id) {
            if (!e.stats.emargementList) e.stats.emargementList = [];
            e.stats.emargementList.push({ signatures: el.signatures, date: el.date });
            if (moment(el.date).isSame(moment(date), 'day')) {
              e.stats.emargement = el.signatures;
            }
          }
        }
        for (const el of data.students) {
          if (e._id === el.user._id) {
            for (const request of el.statsList) {
              e.stats.requestsList.push(request);
              if (moment(request.date).isSame(moment(date), 'day')) {
                e.stats.request = request;
                e.stats.requestDiffAverage = Math.round((request.totalRequests / daysSinceBeginning) || 0);
              }
            }
          }
        }
        studentArray.push(e);
      }
      this.setState({ student: studentArray });
    });
  }

  capitalize = (str) => str.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

  handleClick(student) {
    console.log('Dans enfant -->', student._id)

    // we send the student to our studentList component so we can send it to our reducer
    this.props.handleClickParent(student._id)
  }



  // handleClick(id){
  //   console.log('click detecté')
  //   this.props.history.push(`/studentStat/${id}`)
  //   // return <Redirect to={`/studentStat/${id}`}/>
  // }

  render() {

    console.log('New student array-->', this.state.student)


    let list = {
      student: [],
      teacher: [],
      assistant: []
    };

    if (this.state.student) {

      for (var i = 0; i < this.state.student.length; i++) {

        let e = this.state.student[i];

        // console.log('e.stats.emargement pour ' + e.firstname + ' ' + e.lastname + ':', e.stats && e.stats.emargement);

        let card = <Col key={i} xs={24} sm={12} lg={8} xl={8}>
          {/* // let card = <Col key={i} span={8}> */}

          <Card bordered={false} bodyStyle={styles.cardBodyStyle}>
            <div
              className="student-online"
              style={{
                position: "absolute",
                right: "30%",
                bottom: "106%"
              }}>
              <Badge
                status={e.online
                  ? "processing"
                  : "default"} />
            </div>

            <img
              alt={e._id}
              style={styles.avatar}
              src={e.avatar
                ? `${global.URI_FRONTEND}/images/avatar/${e._id}.jpg`
                : `${global.URI_FRONTEND}/avatar/_male-default.jpg`}
              onError={(e) => e.target.src = `${global.URI_BACKEND}/avatar/_male-default.jpg`}
              onClick={() => this.handleClick(e)}
            />

            <div style={styles.name}>
              <div>{e.firstname}</div>
              <div>{e.lastname}</div>
            </div>

            {/* ------------------ STATS ----------------- */}

            {
              e.stats ?

                <div>
                  <div style={styles.checkbox}>
                    {/* <Checkbox checked={e.stats.emargement.morning}>Matin</Checkbox> */}
                    {e.stats.emargement && e.stats.emargement.am ?
                      <div>Matin <Icon theme="twoTone" twoToneColor="#52c41a" type="check-circle" /></div>
                      : <div>Matin <Icon type="check-circle" theme="twoTone" twoToneColor="#b2b2b2" /></div>}

                    {e.stats.emargement && e.stats.emargement.pm ?
                      <div>Soir <Icon theme="twoTone" twoToneColor="#52c41a" type="check-circle" /></div>
                      : <div>Soir <Icon type="check-circle" theme="twoTone" twoToneColor="#b2b2b2" /></div>}
                    {/* <Checkbox checked={e.stats.emargement.afternoon} style={{margin:0}}>Soir</Checkbox> */}
                  </div>

                  <div style={{ marginTop: '28%', display: 'flex', flexDirection: 'column', minWidth: '180px' }}>

                    {/* Ligne 1 */}
                    <div style={styles.rowCenter}>

                      {
                        e.stats.totalPoint ?
                          <Badge count={e.stats.totalPoint} style={styles.creditNumber} />
                          : null
                      }

                      <div style={styles.creditTimer}>Crédits<span></span></div>

                      <Button shape="circle" type="danger" size='large'>+1</Button>

                    </div>

                    {/* Ligne 2 */}

                    {
                      e.stats.requestDiffAverage || e.stats.requestDiffAverage == 0 ?

                        <div style={styles.rowCenter}>
                          {e.stats.requestDiffAverage > 0 ?
                            <div style={styles.helpMeStat}>
                              <div style={{ color: 'red' }}>+{e.stats.requestDiffAverage}</div>
                              <div style={styles.mainLine} />
                              <div style={{ height: '16px' }}></div>
                            </div>
                            :
                            <div style={styles.helpMeStat}>
                              <div style={{ height: '16px' }}></div>
                              <div style={styles.mainLine} />
                              <div style={{ color: 'green' }}>{e.stats.requestDiffAverage}</div>
                            </div>
                          }
                          <div style={styles.creditTimer}>Clics sur Help me</div>
                        </div>
                        :
                        null
                    }

                    {/* Ligne 3 */}
                    {e.stats.quizzResults ?
                      <div style={styles.rowCenter}>
                        <Badge count={`+${e.stats.quizzResults}%`} style={styles.quizzResultat} />
                        <div style={styles.creditTimer}>Résultats quiz</div>
                      </div>
                      : null}

                  </div>
                </div>
                : null
            }

          </Card>
        </Col>

        let userFound = false;
        for (let el of e.batchList) {
          if (this.props.batchId === el.batchId) {
            userFound = true;
            list[el.userType].push(card);
          };
        };
        if (!userFound) {
          list[e.type].push(card);
        };
      };

    } else {
      return <Redirect to='/' />;
    };

    return (
      <Col>
        {list.student.length > 0
          ? <Row style={styles.userList}>

            {list.student}

          </Row>
          : null}


      </Col>
    );
  };
};

class StudentStat extends Component {


  constructor(props) {
    super(props);
    const { date: { end } } = this.props.batch;
    this.state = {
      displayStatDetails: false,
      studentId: null,
      date: moment().isBefore(moment(end)) ? moment() : moment(end)
    }
    this.handleClickinParent = this.handleClickinParent.bind(this)
  }

  handleClickinParent(studentId) {

    this.setState({
      displayStatDetails: true,
      studentId: studentId
    })
  }

  disabledDate = current => {
    const { date: { start, end } } = this.props.batch;
    return current && current < moment(start).startOf('day') || (current > moment().endOf('day') || current > moment(end).endOf('day'));
  }

  onDateChange = (date, dateString) => {
    this.setState({ date });
  }

  render() {
    if (this.state.displayStatDetails) {

      return (<Redirect to={`/studentStat/${this.state.studentId}`} />)

    } else {


      return (
        <div>
          <Header />
          <Layout style={styles.layout}>
            {
              checkRank(this.props.user, { allowedRanks: ['superadmin', 'manager', 'admin', 'teacher'] })
                ? <SideMenu disableNavigation="disableNavigation" />
                : null
            }
            <Layout style={styles.background}>
              <Content style={styles.content}>
                <div style={styles.contentBackground}>



                  <Row>
                    <Col style={styles.marginTop} offset={1} span={4}>
                      <Userpanel name={this.props.user.firstname} />
                    </Col>

                    <Col offset={1} span={16}>
                      <Row>
                        <Col style={{ marginTop: '154px', marginBottom: '20px' }} >
                          <div style={{ display: 'flex', flexDirection: 'Row', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ marginLeft: '5px', marginRight: '5px', width: 'fit-content' }}><DatePicker onChange={this.onDateChange} disabledDate={this.disabledDate} defaultValue={this.state.date} format={dateFormat} /></div>
                            {/* <div style={{marginLeft:'5px', marginRight:'5px',width:'fit-content'}}><DatePicker defaultValue={moment('2019/12/06', dateFormat)} format={dateFormat} /></div> */}
                          </div>

                        </Col>
                      </Row>

                      {/* <div style={{height:'50px', width:'100%', backgroundColor:'red'}}></div> */}

                      <Avatar
                        batchName={this.props.batch.name}
                        batchId={this.props.batch._id}
                        batch={this.props.batch}
                        date={this.state.date}
                        user={this.props.user}
                        userList={this.props.userList}
                        handleClickParent={this.handleClickinParent}
                      />



                    </Col>

                  </Row>
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
      );
    }


  };
};


function mapStateToProps(state) {
  return { userList: state.batchuserlist, user: state.user, batch: state.batch };
};

export default connect(mapStateToProps)(StudentStat);

const styles = {

  rowCenter: {
    display: 'flex',
    flexDirection: 'Row',
    alignItems: 'center',
    justifyContent: 'start',
    marginBottom: '8px'
  },
  columnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  helpMeStat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '14px',
    paddingRight: '15px'
  },
  creditNumber: {
    // backgroundColor:'#dd9db4',
    backgroundColor: '#fc6761',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: 'bold',
    fontSize: '15px'
  },
  quizzResultat: {
    // backgroundColor:'#9dd5cf',
    backgroundColor: '#41cc34',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontWeight: 'bold',
    fontSize: '15px'
  },
  creditTimer: {
    fontWeight: 'bold',
    paddingLeft: '8px',
    paddingRight: '8px'
  },
  addCreditButton: {
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '15px',
  },
  marginTop: {
    marginTop: 154
  },
  layout: {
    minHeight: '100vh'
  },
  userList: {
    backgroundColor: '#fff',
    padding: 15,
    textAlign: 'center',
    marginBottom: 10
  },
  avatar: {
    width: '25%',
    maxWidth: 80,
    borderRadius: '50%',
    position: 'absolute',
    right: '36%',
    bottom: '83%'
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'Column',
    alignItems: 'start',
    justifyContent: 'start',
    position: 'absolute',
    left: '7%',
    top: '3%',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  name: {
    display: 'flex',
    flexDirection: 'Column',
    alignItems: 'start',
    justifyContent: 'start',
    position: 'absolute',
    right: '12%',
    top: '3%',
    fontWeight: 'bold',
    fontSize: '10px',
    // color:'#fc6761'
  },
  userInfo: {
    margin: 0,
    fontSize: 13
  },
  ownInfo: {
    margin: 0,
    fontSize: 13,
    color: '#097D8A'
  },
  mainLine: {
    height: "2px",
    width: '150%',
    // marginLeft: '10%',
    backgroundColor: 'lightgray',
    // marginBottom: 10
  },
  background: {
    backgroundColor: 'white'
  },
  content: {
    minHeight: '79vh',
    maxHeight: '79vh'
  },
  contentBackground: {
    backgroundColor: '#F6F6FA',
    minHeight: '100vh'
  },
  cardBodyStyle: {
    padding: 10,
    border: 'solid',
    borderColor: 'lightGrey',
    borderWidth: '2px',
    height: '200px',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '30px',
    marginBottom: '30px',
    minWidth: '210px'
  },
  teacher: {
    fontSize: 11,
    fontStyle: 'italic',
    color: '#EB5D56'
  },
  assistant: {
    fontSize: 11,
    fontStyle: 'italic',
    color: '#097D8A'
  },
  student: {
    fontSize: 11,
    fontStyle: 'italic',
    color: '#808080'
  }
};
