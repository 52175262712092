import React, { useState } from "react";
import TimerDashboard from "./HelpRequest/TimerDashboard";
import { Avatar, Icon, Spin, Popover, Button } from "antd";
import checkRank from "../../../utils/checkRank";

const notions = [
  {
    emoji: "very_happy",
    alt: "very happy face",
  },
  {
    emoji: "happy",
    alt: "happy face",
  },
  {
    emoji: "neutral",
    alt: "neutral face",
  },
  {
    emoji: "bad",
    alt: "bad face",
  },
  {
    emoji: "very_bad",
    alt: "very bad face",
  },
];
export default function ({ requestItem, user, availableHelpers, loadHelpers }) {
  const [selectedHelper, setSelectedHelper] = useState("");
  const [visible, setVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const actualTime = requestItem?.teachers?.length ? new Date(requestItem.teachers[0].date.start).getTime() : new Date(requestItem.date.start).getTime();

  const handleOpen = bool => {
    setVisible(bool);
    if (bool) loadHelpers();
  };

  const reassignRequest = () => {
    if (!selectedHelper) return;

    global.SOCKET.emit("reassignRequest", {
      requestId: requestItem._id,
      helperId: selectedHelper,
    });

    setVisible(false);
    setSelectedHelper("");
  };

  const closeRequest = () => {
    const { _id, batchId, userId } = requestItem;

    global.SOCKET.emit("teacherClosesRequest", {
      requestId: _id,
      batchId,
      userId,
    });
  };

  const handleClose = e => {
    e.stopPropagation();

    if (!isDeleting) {
      setIsDeleting(true);
      closeRequest();
    }
  };

  const isRequestHelper = (requestItem.teachers || []).some(
    helper => helper.teacherId === user._id
  );

  const {
    card,
    avatar,
    image,
    section,
    popover,
    button,
    closeWrapper,
    imageWrapper,
  } = styles;
  const alternateHelpers = availableHelpers.filter(
    helper => !requestItem?.teachers?.some(el => el.teacherId === helper._id)
  );

  const popoverContent = availableHelpers.length ? (
    <div style={popover}>
      {alternateHelpers.length ? (
        <>
          <span>Sélectionnez un nouveau TA / teacher pour reassigner la demande.</span>
          {alternateHelpers.map(helper => {
            const isSelected = selectedHelper === helper._id;
            return (
              <Avatar
                onClick={() => setSelectedHelper(helper._id)}
                key={helper._id}
                shape="square"
                className="request-avatar"
                title={`${helper.firstname} ${helper.lastname}`}
                style={{
                  ...avatar,
                  height: 45,
                  width: 45,
                  minWidth: 45,
                  boxSizing: "border-box",
                  opacity: isSelected ? 1 : 0.4,
                  boxShadow: isSelected
                    ? "rgb(0 0 0 / 16%) 0px 2px 4px"
                    : "rgb(0 0 0 / 3%) 0px 2px 4px",
                }}
                src={
                  helper.avatar
                    ? `${global.URI_FRONTEND}/images/avatar/${helper._id}.jpg`
                    : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`
                }
              />
            );
          })}
          <Button
            style={button}
            onClick={() => reassignRequest()}
            disabled={!selectedHelper}
          >
            OK
          </Button>
        </>
      ) : (
        <span>Aucun helper disponible.</span>
      )}
    </div>
  ) : (
    <div>
      <Spin size="small" style={{ padding: 30 }} />
    </div>
  );

  const helpers = (
    <div style={{ ...section, justifyContent: "space-between" }}>
      {requestItem?.teachers?.length ? (
        <>
          <Avatar
            shape="square"
            className="request-avatar"
            style={avatar}
            title={`${requestItem?.teachers?.[0]?.firstname} ${requestItem?.teachers?.[0]?.lastname}`}
            src={
              requestItem?.teachers?.[0]?.teacherId
                ? `${global.URI_FRONTEND}/images/avatar/${requestItem?.teachers?.[0]?.teacherId}.jpg`
                : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`
            }
          />
          <div style={{ ...section, textAlign: "right", minWidth: 30 }}>
            {requestItem?.teachers?.[0] && (
              <span className="request-text">
                {requestItem?.teachers?.[0]?.firstname}{" "}
                {requestItem?.teachers?.[0]?.lastname}
              </span>
            )}
          </div>
        </>
      ) : (
        <div style={{ ...section, textAlign: "left" }}>
          <span className="request-text">En attente . . .</span>
        </div>
      )}
    </div>
  );

  const helperSection = checkRank(user, {
    allowedRanks: ["superadmin", "manager", "admin", "teacher"],
  }) ? (
    <Popover
      visible={visible}
      trigger="click"
      content={popoverContent}
      placement="left"
      onVisibleChange={handleOpen}
    >
      {helpers}
    </Popover>
  ) : (
    helpers
  );

  return (
    <div style={card}>
      <div style={section}>
        <TimerDashboard startDate={actualTime} isAttributed={Boolean(requestItem?.teachers?.length)} type="badge" />
      </div>
      <div style={{ ...section, justifyContent: "space-between" }}>
        <div style={{ ...section, textAlign: "left" }}>
          <span className="request-text">
            {requestItem?.firstname} {requestItem?.lastname}
          </span>
        </div>
        <div style={section}>
          <div style={imageWrapper}>
            {requestItem.notionUnderstood && (
              <img
                style={image}
                src={`/images/emojis/${notions[requestItem.notionUnderstood - 1]?.emoji
                  }.png`}
                alt={`${notions[requestItem.notionUnderstood - 1].name}`}
              />
            )}
            <Avatar
              shape="square"
              style={avatar}
              className="request-avatar"
              src={
                requestItem.userId
                  ? `${global.URI_FRONTEND}/images/avatar/${requestItem.userId}.jpg`
                  : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`
              }
            />
          </div>
          {requestItem.buddy.map(e => (
            <Avatar
              key={e._id}
              shape="square"
              style={avatar}
              className="request-avatar"
              title={`${e.firstname} ${e.lastname}`}
              src={
                e._id
                  ? `${global.URI_FRONTEND}/images/avatar/${e._id}.jpg`
                  : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`
              }
            />
          ))}
        </div>
      </div>
      {helperSection}
      <div style={section}>
        {(isRequestHelper ||
          checkRank(user, {
            allowedRanks: ["superadmin", "manager", "admin", "teacher"],
          })) && (
            <Button
              style={closeWrapper}
              onClick={handleClose}
            >
              <Icon type="close-circle" />
            </Button>
          )}
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "grid",
    width: "100%",
    gridTemplateColumns:
      "90px calc((100% - 135px) / 2) calc((100% - 135px) / 2) 45px",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: 7,
    marginBottom: 20,
    boxShadow: "rgb(0 0 0 / 3%) 0px 2px 4px",
    padding: "8px 20px",
  },
  avatar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EBE9FE",
    borderRadius: 6,
    boxShadow: "rgb(0 0 0 / 3%) 0px 2px 4px",
    boxSizing: "content-box",
    marginRight: 5
  },
  image: {
    height: "25px",
    zIndex: 2,
    position: "absolute",
    left: "-10px",
    top: "-10px",
  },
  section: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    fontWeight: "bold",
    fontSize: "0.7rem",
    position: "relative",
  },
  closeWrapper: {
    margin: "2% 2% 2% 4%",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none",
    background: "transparent",
    position: "relative",
  },
  popover: {
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#f94a56",
    fontWeight: "bold",
    color: "white",
    boxShadow: "rgb(0 0 0 / 3%) 0px 2px 4px",
  },
  imageWrapper: {
    position: "relative",
  },
};
