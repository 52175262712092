import React, { useState, useEffect, useRef } from "react";

//STYLES__________________
import "../Content/Flashcard.css";
import "./stepheader.css";

//NAVIGATION_____________
import { Link, useHistory } from "react-router-dom";

//UI______________________
import { Row, Button, Icon, Modal, Tooltip, Switch, Layout, Menu } from "antd";

//SCROLLBAR_____________________________
import SimpleBar from 'simplebar-react'

//REDUX________________________
import { connect } from "react-redux";

//COMPONENTS_______________
import Resource from "./Resource";
import { SyllabusCategory } from "../../../Syllabus/SyllabusCategory";

//TRANSLATION________________________
import { useTranslation } from "react-i18next";

const { Item } = Menu
const { Sider, Content } = Layout

const { Group } = Button;

const tags = {
  course: "COURS",
  project: "PROJET",
  exercise: "CHALLENGE",
  quizz: "QUIZ",
};

const StepHeader = ({
  batchid,
  user,
  synchronizeRessource,
  stepData,
  postprogram,
  steps,
  loadEnabledSteps,
  updateProgress,
  step,
  previous,
  next,
  changeStep,
  day,
  language,
}) => {
  const history = useHistory()

  //TRANSLATION________________________
  const { t } = useTranslation();

  //STATE HOOKS_______________________
  const [collapsed, setCollapsed] = useState(true)

  const [stepsBackUnlocked, setStepsBackUnlocked] = useState(false);
  const [liveCodeBackUnlocked, setLiveCodeBackUnlocked] = useState(false);
  const [quizBackUnlocked, setQuizBackUnlocked] = useState(false);
  const [flashCardBackUnlocked, setFlashCardBackUnlocked] = useState(false);

  const [open, setOpen] = useState(false);

  //SYLLABUS___________________
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStack = async () => {
      setLoading(true);
      const data = await fetch(
        `${global.URI_BACKEND}/syllabus/course/${batchid}/${language}`
      );
      const json = await data.json();

      if (json.result) {
        setCategories(json.syllabus.categories);
        setLoading(false);
      }
    };

    if (batchid) {
      loadStack();
      //loadProgress();
    }
  }, [batchid, language, user._id]);

  //RESIZE MODAL___________________
  useEffect(() => {
    const handleResize = (e) => {
      if (e.target.innerWidth < 950) {
        setCollapsed(true)
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    const checkResource = async resourceId => {
      const data = await fetch(
        `${global.URI_BACKEND}/step/stepstatus?batchId=${batchid}&stepId=${resourceId}`
      );
      const json = await data.json();

      if (json.enable === true) {
        setStepsBackUnlocked(true);
      } else {
        setStepsBackUnlocked(false);
      }

      if (json.success) {
        setLiveCodeBackUnlocked(json.enableLiveCode);
        setQuizBackUnlocked(json.unlockedQuiz);
        setFlashCardBackUnlocked(json.unlockedFlashCard);
      }
    };

    if (step) checkResource(step._id)
  }, [step, batchid])


  //FUNCTIONS_________________________
  const onCollapse = () => {
    setCollapsed(!collapsed)
  }

  const toggleModal = () => {
    if (!open) updateProgress();
    setOpen(!open);
  };

  const changeSwitch = async () => {
    if (step?._id) {
      const data = await fetch(
        `${global.URI_BACKEND}/step/changestepstatus?batchId=${batchid}&stepId=${step._id}`
      );
      const json = await data.json();

      if (json.enable) {
        setStepsBackUnlocked(true);
      }

      setStepsBackUnlocked(!stepsBackUnlocked);
    }
  };

  const changeSwitchLiveCode = async () => {
    if (step?._id) {
      const data = await fetch(
        `${global.URI_BACKEND}/step/changelivecodestatus?batchId=${batchid}&stepId=${step._id}`
      );
      const json = await data.json();

      if (json.enableLiveCode) {
        setLiveCodeBackUnlocked(true);
      } else {
        setLiveCodeBackUnlocked(false);
      }
    }
  };

  const changeSwitchQuiz = async () => {
    if (step?._id) {
      const data = await fetch(
        `${global.URI_BACKEND}/step/changequizstatus?batchId=${batchid}&stepId=${step._id}`
      );
      const json = await data.json();

      if (json.unlockedQuiz) {
        setQuizBackUnlocked(true);
      } else {
        setQuizBackUnlocked(false);
      }
    }
  };

  const changeSwitchFlashcard = async () => {
    if (step?._id) {
      const data = await fetch(
        `${global.URI_BACKEND}/step/changeflashcardstatus?batchId=${batchid}&stepId=${step._id}`
      );
      const json = await data.json();

      if (json.unlockedFlashCard) {
        setFlashCardBackUnlocked(true);
      } else {
        setFlashCardBackUnlocked(false);
      }
    }
  };

  const onStepClick = (e) => {
    const stepType = e.steps[0].stepType;
    const id = e.steps[0].stepId;

    history.push(`/batch/${stepType}/${id}`);
  }

  return step ? (
    <Row style={styles.container}>
      <h2 style={styles.title}>{step.text}</h2>
      <Group>
        {previous ? (
          <Tooltip
            title={`${t(
              tags[previous.stepType_v2] || previous.stepType_v2.toUpperCase()
            )} - ${previous.text}`}
            placement="left"
          >
            <Link to={`/batch/${previous.stepType}/${previous.stepId}`}>
              <Button
                ghost={true}
                size="small"
                style={{ ...styles.button, ...styles.left }}
              >
                <Icon type="arrow-left" />
              </Button>
            </Link>
          </Tooltip>
        ) : (
          <Button
            ghost={true}
            size="small"
            disabled
            style={{ ...styles.button, ...styles.left }}
          ></Button>
        )}
        <Button
          ghost={true}
          style={styles.button}
          onClick={() => toggleModal()}
        >
          <Icon type={open ? "close" : "menu"} />
          {t("Étapes")}
        </Button>
        {next ? (
          <Tooltip
            title={`${t(
              tags[next.stepType_v2] || next.stepType_v2.toUpperCase()
            )} - ${next.text}`}
            placement="right"
          >
            <Link to={`/batch/${next.stepType}/${next.stepId}`}>
              <Button
                ghost={true}
                size="small"
                style={{ ...styles.button, ...styles.right }}
              >
                <Icon type="arrow-right" />
              </Button>
            </Link>
          </Tooltip>
        ) : (
          <Button
            ghost={true}
            size="small"
            disabled
            style={{ ...styles.button, ...styles.right }}
          ></Button>
        )}
      </Group>
      {stepData?.stepType_v2 !== "review" &&
        !postprogram &&
        (user.type === "superadmin" || user.type === "teacher") && (
          <div>
            <Switch
              style={styles.switch}
              checkedChildren="Cours"
              unCheckedChildren="Cours"
              checked={stepsBackUnlocked}
              onChange={changeSwitch}
              title={t("Débloquer une ressource")}
            />
            {stepData?.stepType_v2 === "challenge" ||
              stepData?.stepType_v2 === "project" ? (
              <Switch
                style={styles.switch2}
                checkedChildren="Solution"
                unCheckedChildren="Solution"
                checked={liveCodeBackUnlocked}
                onChange={changeSwitchLiveCode}
                title={t("Débloquer la solution/vidéo/livecode")}
              />
            ) : (
              ""
            )}
            {stepData?.stepType_v2 === "rncp" ? (
              <Switch
                style={styles.switch2}
                checkedChildren="Quizz"
                unCheckedChildren="Quizz"
                checked={quizBackUnlocked}
                onChange={changeSwitchQuiz}
                title={t("Débloquer le quiz")}
              />
            ) : (
              ""
            )}
            {stepData?.stepType_v2 === "flashcard" ? (
              <Switch
                style={styles.switch2}
                checkedChildren="Flashcard"
                unCheckedChildren="Flashcard"
                checked={flashCardBackUnlocked}
                onChange={changeSwitchFlashcard}
                title={t("Débloquer la flashcard")}
              />
            ) : (
              ""
            )}
            <Button
              style={styles.syncButton}
              type="danger"
              shape="round"
              size="default"
              onClick={synchronizeRessource}
              title={t("Synchroniser la ressource")}
            >
              <Icon type="cloud-upload" />
            </Button>
          </div>
        )}
      <Modal
        visible={open}
        onCancel={toggleModal}
        title={day && day.title}
        footer={false}
        bodyStyle={{ ...styles.modal_body, minWidth: collapsed ? 648 : 900 }}
        width="fit-content"
        style={{ top: 56 }}
        className="sider-inner-modal"
      >
        <Layout style={{ maxHeight: "80vh" }}>
          <Sider style={styles.container} collapsible collapsed={collapsed} onCollapse={onCollapse} className={collapsed ? 'custom-sider-collapsed' : /*(keys === '1' ?*/ "custom-sider white-border syllabusSider" /* : 'custom-sider red-border')*/}>
            <SimpleBar style={{ height: '100%' }}>


              {categories &&
                categories.length > 0 &&
                categories.map((e, i) => (
                  <SyllabusCategory
                    category={e}
                    key={i}
                    index={i}
                    type='course'
                    size='small'
                    onItemClick={onStepClick}
                  />
                ))
              }


            </SimpleBar>
          </Sider>

          <Content style={styles.modalContent}>
            <Row style={{ width: "100%" }}>
              <Row>
                <h3 style={styles.modal_title}>{t("Comprendre").toUpperCase()}</h3>
              </Row>
              <Row>
                <h4 style={{ ...styles.modal_title, ...styles.modal_subtitle }}>
                  {t("Notions journée")}
                </h4>
              </Row>
              <Row style={{ width: "100%" }}>
                <SimpleBar style={{ maxHeight: "calc(80vh - 130px)"}}>
                  {steps?.length > 0 &&
                    steps
                      .filter(e =>
                        user.type === "student" ? e.mainGroup !== "Teachers" : true
                      )
                      .map((e, i) => (
                        <Resource
                          resource={e}
                          key={i}
                          index={i}
                          day={day}
                          toggleModal={toggleModal}
                          changeStep={changeStep}
                        />
                      ))}
                </SimpleBar>
              </Row>
            </Row>
          </Content>
        </Layout>
      </Modal>
    </Row>
  ) : postprogram && (user.type === "superadmin" || user.type === "teacher") ? (
    <Row style={styles.container2}>
      {(user.type === "superadmin" || user.type === "teacher") && (
        <div>
          <Button
            style={styles.syncButton2}
            type="danger"
            shape="round"
            size="default"
            onClick={synchronizeRessource}
            title={t("Synchroniser la ressource")}
          >
            <Icon type="cloud-upload" />
          </Button>
        </div>
      )}
    </Row>
  ) : (
    <></>
  );
};

function mapStateToProps({
  tracks,
  batchid,
  batch,
  user,
  stackType,
  language,
  stepsList
}) {
  return { tracks, user, batchid, batch, stackType, language, steps: stepsList };
}

function mapDispatchToProps(dispatch) {
  return {
    stepsList: function (stepsList) {
      dispatch({ type: "stepsList", stepsList });
    },
    changeDay: function (day) {
      dispatch({ type: "changeDay", day });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepHeader);

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    backgroundColor: "#ffffff",
    width: "100vw",
    padding: "8px 0px",
    maxHeight: "80vh",
  },
  container2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "5vh",
    padding: "10px 0px",
  },
  title: {
    position: "absolute",
    left: 20,
    color: "rgba(137, 140, 143, 0.9)",
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: "14px",
    textTransform: "uppercase",
    margin: 0,
    lineHeight: "27px",
  },
  button: {
    borderTop: "1.5px solid #F94A56",
    borderBottom: "1.5px solid #F94A56",
    color: "#F94A56",
    height: "26px",
    fontWeight: 600,
    fontFamily: '"Poppins", sans-serif',
    fontSize: "12px",
  },
  left: {
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",
    borderLeft: "1.5px solid #F94A56",
    borderRight: "1.5px solid #F94A56",
    width: "30px",
  },
  right: {
    borderTopRightRadius: "15px",
    borderBottomRightRadius: "15px",
    borderLeft: "1.5px solid #F94A56",
    borderRight: "1.5px solid #F94A56",
    width: "30px",
  },
  modal_title: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
  },
  modal_subtitle: {
    fontWeight: 300,
  },
  modal_body: {
    backgroundColor: "#F6F7FB",
    padding: 0,
    overflow: "scroll",
    maxHeight: "80vh",
    width: "60vw",
  },
  modal_content: {
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "65px",
    border: "1px solid #f0f2f5",
    minWidth: "500px",
    width: "60vw",
    position: "relative",
  },
  content_section: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: "0px 20px",
  },
  content_header: {
    position: "absolute",
    left: 0,
  },
  content_footer: {
    position: "absolute",
    right: 0,
  },
  tag: {
    borderRadius: "50px",
    fontSize: "9px",
    fontWeight: 600,
    padding: "0px 6px",
    lineHeight: "13.5px",
    margin: "0px 36px",
  },
  img: {
    objectFit: "contain",
    height: "32px",
    width: "auto",
  },
  avatar: {
    marginRight: 12,
  },
  progress: {
    fontWeight: 600,
  },
  icon: {
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.17)",
  },
  blockedStudent: {
    opacity: 0.4,
    cursor: "not-allowed",
  },
  blockedOther: {
    opacity: 0.4,
  },
  syncButton: {
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 200,
  },
  syncButton2: {
    position: "absolute",
    top: 5,
    right: 15,
    zIndex: 200,
  },
  switch: {
    position: "absolute",
    top: 10,
    right: 60,
    zIndex: 200,
  },
  switch2: {
    position: "absolute",
    top: 10,
    right: 130,
    zIndex: 200,
    //backgroundColor: '#5247EA',
  },
  switch3: {
    position: "absolute",
    top: 10,
    right: 200,
    zIndex: 200,
    //backgroundColor: '#5247EA',
  },
  modalContent: {
    padding: 33,
    maxHeight: "80vh"
  },
};
