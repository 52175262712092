import React from 'react'

//STYLES__________________
import '../teacherdashboards.css'

//UI______________________
import { Row, Col, Radio, Select } from 'antd'

//SCROLLBAR__________________
import SimpleBar from 'simplebar-react'

//TRANSLATION________________________
import { useTranslation } from 'react-i18next';

const { Option } = Select
const { Group } = Radio

const DashboardNavMenu = ({ view, setView, showWeek = true }) => {
    //TRANSLATION________________________
    const { t } = useTranslation();

    //FUNCTIONS_____________________________
    const onRadioChange = e => {
        setView(e.target.value)
    }

    return (
        <Row>
            <Col span={ 22 } offset={ 1 } style={ styles.selectContainer } className="select-container">
                <div style={ styles.container }>
                    <div style={ styles.row }>
                        <p style={ styles.heading }>{t("Période")}:</p>
                        <Group onChange={ (e)=>onRadioChange(e) } defaultValue={ view }>
                            <Radio value="batch" checked={ view === 'batch' }>Batch</Radio>
                            {
                                showWeek &&
                                <Radio value="week" checked={ view === 'week' }>{t("Semaine")}</Radio>
                            }
                            <Radio value="day" checked={ view === 'day' }>{t("Aujourd'hui")}</Radio>
                        </Group>
                    </div>
                    {
                        view === 'week' &&
                        <div style={ styles.row }>
                            <p style={ styles.heading }>{t("Semaine")}:</p> 
                            <Select style={ styles.select } defaultValue={ 1 }>
                                <Option value={ 1 }>{t("Semaine")} 1</Option>
                                <Option value={ 2 }>{t("Semaine")} 2</Option>
                                <Option value={ 3 }>{t("Semaine")} 3</Option>
                                <Option value={ 3 }>{t("Semaine")} 4</Option>
                                <Option value={ 5 }>{t("Semaine")} 5</Option>
                                <Option value={ 6 }>{t("Semaine")} 6</Option>
                                <Option value={ 7 }>{t("Semaine")} 7</Option>
                                <Option value={ 8 }>{t("Semaine")} 8</Option>
                            </Select>
                        </div>
                    }
                </div>
            </Col>
        </Row>
    )
}
export default DashboardNavMenu

const styles = {
    selectContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '24px'
    },
    select: {
        width: '150px'
    },
    container: {
        width: '400px',
        border: "1px solid #E5E5E5",
        backgroundColor: '#ffffff',
        borderRadius: '3px',
        padding: '10px 30px',
    },
    heading: {
        fontWeight: 'bold',
        color: '#010031',
        margin: '0px 16px 0px 0px'
    },
    row: {
        display: "flex",
        alignItems: 'center',
        margin: '3px 0px'
    }
}