import { arrayExpression, assignmentExpression, binaryExpression, callExpression, functionExpression, memberExpression, objectExpression, thisExpression, updateExpression } from './expressions_and_patterns/';
import { blockStatement, expressionStatement, forStatement, identifier, ifStatement, literal, property, variableDeclaration, functionDeclaration, statement } from './statements_and_declarations/';

function typeChecker(tc = {}, pb = {}) {
  if (tc.type && pb.type && tc.type === pb.type) {
    // console.log('>> typeChecker =', pb.type); // DEBUG

    switch (pb.type) {
      /* Statements and Declarations */
      case 'BlockStatement':
        /* utilisé pour regrouper 0 ou plusieurs déclarations, ex:
          for (var i = 0; i < 5; i++) {
            ... // le corps de la boucle est de type 'BlockStatement'
          };
        */
        return blockStatement(tc, pb);

      case 'Identifier':
        /* nom de variable, fonction, object, etc ... ex: 
          toto = 2 + 5; // cible le nom 'toto'
          object.property = 'value'; // cible le nom 'object'
        */
        return identifier(tc, pb);

      case 'Literal':
        /* valeur, ex:
          toto = 5; // 5 est de type 'Literal'
          name = "John"; // "John" est de type 'Literal'
        */
        return literal(tc, pb);

      case 'Property':
        return property(tc, pb);

      case 'VariableDeclaration':
        // var toto = 5;
        return variableDeclaration(tc.declarations, pb.declarations);

      /* Expressions and Patterns */
      case 'ArrayExpression':
        // var array = [0, 1, 2]; // `[0, 1, 2]` est de type 'ArrayExpression'
        return arrayExpression(tc, pb);

      case 'AssignmentExpression':
        // minute = 30;
        return assignmentExpression(tc, pb);

      case 'BinaryExpression':
      case 'LogicalExpression': // var1 && var2
        // 10 + 5;
        return binaryExpression(tc, pb);

      case 'CallExpression':
        // array.push(); OU console.log(); OU func();
        return callExpression(tc, pb);

      case 'FunctionExpression':
        // var f = function() { ... };
        return functionExpression(tc, pb);

      case 'ExpressionStatement':
        /* une portion de code représentant une ou plusieurs instructions, ex:
          >> 3;
          >> 'toto';
        */
        return expressionStatement(tc.expression, pb.expression);

      case 'ForStatement':
        // for (var i = 0; i < 5; i++) { ... }
        return forStatement(tc, pb);

      case 'IfStatement':
        // if (...) { ... }
        return ifStatement(tc, pb);

      case 'MemberExpression':
        // array.push; OU console.log;
        return memberExpression(tc, pb);

      case 'ObjectExpression':
        // var object = { ... }; // `{ ... }` est de type 'ObjectExpression'
        return objectExpression(tc, pb);

      case 'ThisExpression': // this
        return thisExpression(tc, pb);

      case 'UpdateExpression':
        // i++;
        return updateExpression(tc, pb);
      
      case "FunctionDeclaration":
      case 'ArrowFunctionExpression': // var f = () => { ... };
      case 'ForOfStatement': // for...of
      case 'ForInStatement': // for...in
      case 'UnaryExpression': // +5
      case 'TemplateLiteral':
      case "TemplateElement":
        return functionDeclaration(tc, pb)
      
      case 'ReturnStatement': 
      case 'EmptyStatement': // ?
        return statement(tc, pb)// return
      /* Unused Statements and Declarations */
      case 'BreakStatement': // break
      case 'ContinueStatement': // continue
      case 'LabeledStatement': // label: for (...) { ... }
      case 'SwitchStatement': // switch (...) { ... }
      case 'ThrowStatement': // throw
      case 'TryStatement': // try { ... }
      case 'WhileStatement': // while (...) { ... }

      /* Unused Expressions and Patterns */
      // eslint-disable-next-line no-fallthrough
      case 'AwaitExpression': // await
      case 'ConditionalExpression': // if ? then : else
      case 'NewExpression': // new
        console.log(`DEBUG: typeChecker > Found unused type: ${pb?.type}.`); // DEBUG
        return false;

      default:
        console.log(`DEBUG: typeChecker > Unknown type: ${pb.type}.`); // DEBUG
        return false;
    }
  } else {
    console.warn(`Types are different : \`${tc.type}\` and \`${pb.type}\`.`); // DEBUG
    return false;
  }
}

export default typeChecker;