import React, { Component } from 'react';
import { Layout, Col, Row, Icon, Avatar, Input, Tabs, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Header from './Header/Header';
// import Menu from './Header/Menu';
import Pitch from './Project/Pitch';
import Prototype from './Project/Prototype';
import Database from './Project/Database';
// import SiderIcons from './Components/SiderIcons';
import Demo from './Project/Demo';
// import { Redirect } from 'react-router-dom';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

const { TabPane } = Tabs;
// const { Sider } = Layout;

class Project extends Component {
  state = {
    id: '',
    team: [],
    name: null,
    titleWidth: 0,
    editName: false,
    tabKey: '',
    selectedKey: '1',
    isComponentMounted: false,
    pitch: {},
    projectPrototype: {},
    database: {},
    repositories: [],
    demo: {},
    editable: false
  }

  edit = {}
  timeout = {}

  componentDidMount() {
    const { user, batchid, match: { params: { pid } } } = this.props;

    if (!pid) {
      fetch(`${global.URI_BACKEND}/project/myproject/${user._id}`).then(r => r.json()).then(project => {
        if (project && !project?.error) {
          const { name, _id: id, owner, team, pitch, projectPrototype, database, repositories, demo } = project;
          // const editable = team.some(u => u.userId === user._id);
          const editable = owner?.userId === user._id || team?.some(u => u.userId === user._id);

          this.setState({ project, name, id, team, pitch, projectPrototype, database, repositories, demo, batchid, isComponentMounted: true, tabKey: Date.now(), editable });
        } else {
          this.setState({ project: {}, batchid, isComponentMounted: true, tabKey: Date.now(), editable: true });
        }
      });
    } else {
      fetch(`${global.URI_BACKEND}/project/${pid}`).then(r => r.json()).then(data => {
        if (!data?.error) {
          const { project, project: { name, _id: id, owner, team, pitch, projectPrototype, database, repositories, demo } } = data;
          // const editable = team.some(u => u.userId === user._id);

          const editable = owner?.userId === user._id || team?.some(u => u.userId === user._id);

          this.setState({ project, name, id, team, pitch, projectPrototype, database, repositories, demo, batchid, isComponentMounted: true, tabKey: Date.now(), editable });
        } else {
          this.setState({ project: {}, batchid, isComponentMounted: true, tabKey: Date.now(), editable: true });
        }
      });
    }
  }

  onEditHandle = e => {
    if (this.state[e] && this.state.name !== this.edit.name) {
      const { user: { firstname, lastname, _id: userId } } = this.props;
      const { name, id, batchid } = this.state;

      const myself = { firstname, lastname, userId, fullname: `${firstname} ${lastname}` };
      const dataToSend = id ? { name: name.trim() } : { name: name.trim(), myself, batchid };

      fetch(`${global.URI_BACKEND}/project/${id || 'new'}/name`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend)
      }).then(res => res.json()).then(data => {
        if (!data?.error) {
          const { _id: id, team } = data;
          if (this.timeout.name) clearTimeout(this.timeout.name);
          this.updateId(id);
          this.setState({ name: data.name, [e]: !this.state[e], id, team, tabKey: Date.now() });
        }
      });
    } else {
      this.edit.name = this.state.name;
      this.timeout.name = setTimeout(() => this.onCancelEdit(e), 120000);
      this.setState({ [e]: !this.state[e] });
    }
  }

  onCancelEdit = e => {
    if (this.timeout.name) clearTimeout(this.timeout.name);

    this.setState({ [e]: false, name: this.edit.name });
  }

  onInputChange = e => {
    const { id, value } = e.target;

    this.setState({ [id]: value });
  }

  updateId = id => {
    this.setState({ id });
  }

  onHandleChange = selectedKey => {
    this.setState({ tabKey: Date.now(), selectedKey });
  }

  updatePitch = pitch => {
    this.setState({ pitch });
  }

  updateProjectPrototype = projectPrototype => {
    this.setState({ projectPrototype });
  }

  updateDatabase = database => {
    this.setState({ database });
  }

  updateRepositories = repositories => {
    this.setState({ repositories });
  }

  updateDemo = demo => {
    this.setState({ demo });
  }

  render() {
    const { layout, title, avatar, editIcon, flex, input, noData } = styles;
    const { editName, name, id, team = [], batchid, tabKey, selectedKey = '0', isComponentMounted, pitch, projectPrototype: prototype, database, repositories = [], demo, editable } = this.state;
    const { user, day, location: { pathname }, match, t } = this.props;

    const avatars = team.map(e => <Tooltip key={e.userId} title={`${e.firstname} ${e.lastname}`} placement="bottomRight">
      <Avatar src={ e.avatar ? `../images/avatar/${e.userId}.jpg` : `../images/avatar/_male-default.jpg`} style={avatar} size={48} shape="square" />
    </Tooltip>).reduce((prev, curr) => [prev, ' ', curr], []);


    return (<Layout style={layout}>
      <Header />
      {/* <Menu currentSelected={null}/> */} 
      <Layout>
      {/* <Sider width={40} style={{ background: '#fff' }}>
          <SiderIcons day={day} user={user} path={pathname} match={match} />
        </Sider> */}
          

        <div style={title}>
          <Row type="flex" justify="space-between">
            <Col flex="auto" style={flex}>
              {
                !editName && <div style={flex}>
                  {
                    name
                      ? <div style={flex}>{name}</div>
                      : <div style={{ ...flex, ...noData }}>{t("Nom du Projet")}</div>
                  }
                  {
                    editable && <div style={flex}>
                      <Icon style={editIcon} type="edit" className="my-project--edit__button" onClick={() => this.onEditHandle('editName')} />
                    </div>
                  }
                </div>
              }
              {
                editName && <div style={flex}>
                  <div style={flex}>
                    <Input style={input} id="name" onChange={this.onInputChange} onPressEnter={() => this.onEditHandle('editName')} value={name} />
                  </div>
                  <div style={flex}>
                    <Icon style={editIcon} type="save" className="my-project--edit__button" onClick={() => this.onEditHandle('editName')} />
                    <Icon style={editIcon} type="close" className="my-project--edit__button" onClick={() => this.onCancelEdit('editName')} />
                  </div>
                </div>
              }
            </Col>
            <Col flex="auto">
              {avatars}
            </Col>
          </Row>

          <Row>
            {
              isComponentMounted && <Tabs key={tabKey} onChange={this.onHandleChange} activeKey={selectedKey} defaultActiveKey="1" size="large">
                <TabPane tab={t("Pitch")} key="1">
                  <Pitch id={id} user={user} batchid={batchid} editable={editable} updateId={this.updateId} updatePitch={this.updatePitch} pitch={pitch} />
                </TabPane>
                <TabPane tab={t("Prototype")} key="2">
                  <Prototype id={id} user={user} batchid={batchid} editable={editable} updateId={this.updateId} updateProjectPrototype={this.updateProjectPrototype} prototype={prototype} />
                </TabPane>
                <TabPane tab={t("Base de données")} key="3">
                  <Database id={id} user={user} batchid={batchid} editable={editable} updateId={this.updateId} updateDatabase={this.updateDatabase} updateRepositories={this.updateRepositories} database={database} repositories={repositories} />
                </TabPane>
                <TabPane tab={t("Demo")} key="4">
                  <Demo id={id} user={user} batchid={batchid} editable={editable} updateId={this.updateId} updateDemo={this.updateDemo} demo={demo} />
                </TabPane>
              </Tabs>
            }
          </Row>
        </div>
      </Layout>
    </Layout>);
  }
}

function mapStateToProps({ batchid, user }) {
  return { batchid, user };
}

export default connect(mapStateToProps)(withTranslation()(Project));

const styles = {
  layout: { minHeight: '100vh', height: 'auto' },
  title: { padding: '20px 20px 17px 20px', fontFamily: 'roboto', fontSize: 28, fontWeight: 'light', color: 'black', backgroundColor: '#F6F7FB' },
  avatar: { borderRadius: 9 },
  editIcon: { color: '#000000', opacity: 0.65, marginLeft: 15, fontSize: 24, cursor: 'pointer' },
  flex: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  input: { width: 250 },
  noData: { color: '#bbb' }
}