import React, { Component } from 'react';
import { Row } from 'antd';

import LoginForm from './Login/LoginForm';
import Logo from './Login/Logo';

export default class Login extends Component {
  render() {
    const { background } = styles;
    const { match: { params: addBatchId } } = this.props

    return (<Row style={background}>
      <Logo />
      <LoginForm addBatchId={ addBatchId && addBatchId.addBatchId }/>
    </Row>);
  }
}

const styles = {
  background: { backgroundColor: '#F6F7FB', height: '100vh' },
}