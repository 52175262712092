import React from "react";

const emojis = [
  { img: "very_happy", message: "J'ai compris tous les concepts" },
  { img: "happy", message: "J'ai globalement compris les concepts" },
  { img: "neutral", message: "J'ai moyennement compris les concepts" },
  { img: "bad", message: "Je n'ai globalement pas compris les concepts" },
  { img: "very_bad", message: "Je n'ai rien compris" },
];
export default function FeelingEmoji({ feeling, size = "small" }) {
  const emoji = emojis[feeling - 1];

  const img = emoji ? emoji.img : "";
  const message = emoji ? emoji.message : "";

  return feeling ? (
    <img
      style={{ height: size === "small" ? 23 : 27 }}
      alt={message}
      src={`${global.URI_FRONTEND}/images/emojis/${img}.png`}
      title={message}
    />
  ) : (
    <></>
  );
}
