export default function user(state = "", action) {
  if (action.type === "user") {
    return { ...action.user };
  } else if (action.type === "userStatus") {
    let status = action.status;
    return { ...state, status };
  } else if (action.type === "userProgress") {
    var userCopy = { ...state };
    userCopy.progression = action.progress;
    return userCopy;
  } else if (action.type === "userAvatar") {
    return { ...state, avatar: action.avatar };
  } else {
    return state;
  }
}
