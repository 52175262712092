import React, { Component } from 'react'

//UI__________________________
import { Modal, Avatar, Tooltip, Button } from 'antd';

//NAVIGATION___________________
import { Link } from 'react-router-dom';

//REDUX________________________
import { connect } from 'react-redux'

//COMPONENTS_________________________
import TeamSelection from './SubmittedProjects/TeamSelection';
import ModalFooter from './SubmittedProjects/ModalFooter';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

export class ProjectCard extends Component {
  state = {
    visible: false,
    students: []
  }

  componentDidMount() {
    const { team, votes, voted, showMod } = this.props;
    if (showMod) {
      const students = team.map(e => {
        const student = e.student;

        return ({ _id: student?._id || student, userId: student?._id || student, student, fullname: student?.fullname || e.fullname || `${e.firstname} ${e.lastname}`, firstname: student?.firstname || e.firstname, lastname: student?.lastname || e.lastname, like: e.like, selected: voted ? true : e.selected });
      });

      for (const e of votes) {
        if (!students.some(v => v._id === e.student._id || e.userId)) {
          const { student: { _id, fullname, firstname, lastname } = {}, student, like, selected } = e;

          students.push({ _id, userId: _id, student: student._id, fullname: fullname || `${firstname} ${lastname}`, firstname, lastname, like, selected });
        }
      }

      this.setState({ students, visible: false, voted });
    }
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  closeModal = () => {
    this.setState({ visible: false });
  }

  updateTeam = students => {
    this.setState({ students });
  }

  sendData = () => {
    const { students, visible } = this.state;
    const { id } = this.props;

    fetch(`${global.URI_BACKEND}/project/admin/team/${id}`, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ team: students.filter(e => e.selected) })
    }).then(res => res.json()).then(data => {
      if (!data?.error) {
        const { team, votes, voted } = data;

        const students = team.map(e => ({ ...e, selected: true }));
        for (const e of votes) {
          if (!students.some(v => v.userId === e.student._id || e.userId)) {
            const { student: { _id, fullname, firstname, lastname } = {}, student, like, selected } = e;

            students.push({ _id, userId: _id, student: student._id, fullname: fullname || `${firstname} ${lastname}`, firstname, lastname, like, selected });
          }
        }

        this.setState({ visible: !visible, students, voted }, () => {
          const { updateProject } = this.props;

          updateProject(data);
        });
      }
    });
  }

  render() {
    const { card, header, avatar, title, subtitle, text, body, button_container, outline_button, filled_button } = styles;
    const { id = '', name, pitch = {}, team = [], owner = {}, voted = false, user, batchid, updateProject, showMod = false, collapsed, t } = this.props;
    const { visible, students } = this.state

    let content = pitch.problem ? pitch.problem.split(' ').slice(0, 25).slice(0, pitch.problem.lastIndexOf(/[,.]/g)).join(' ') : ''
    return (
      <>
        <div style={collapsed ? card : Object.assign({ ...card }, { margin: 15 })}>
          <div style={header}>
            <h4 style={title}>{name}</h4>
            <div>
              {
                team.length > 0 && voted ?
                  team.map(e =>
                    <Tooltip key={e.userId} title={`${e?.firstname} ${e?.lastname}`} placement="bottomRight">
                      <Avatar style={avatar} src={e.userId ? `${global.URI_FRONTEND}/images/avatar/${e.userId}.jpg` : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`} size={40} shape="square" />
                    </Tooltip>
                  ) :
                  <Tooltip key={owner?.userId} title={`${owner?.firstname} ${owner?.lastname}`} placement="bottomRight">
                    <Avatar style={avatar} src={`${global.URI_FRONTEND}/images/avatar/${owner?.userId}.jpg`} size={40} shape="square" />
                  </Tooltip>
              }
            </div>
          </div>
          <div style={body}>
            <div>
              <h5 style={subtitle}>{t("Cible")}</h5>
              <p style={text}><span role="img" aria-label="rocket">🚀</span>{pitch?.target}</p>

              <h5 style={subtitle}>{t("Problématique")}</h5>
              <p style={text}>{content}{pitch.problem && content.length !== pitch.problem.length && <Link to={`/project/${id}`} style={{ color: '#000000' }}>{t("Voir plus")}</Link>}</p>
            </div>
            <div style={button_container}>
              <Link to={`/project/${id}`}><Button style={showMod ? outline_button : Object.assign({ ...outline_button }, { width: '195px' })}>{t("Afficher le projet")}</Button></Link>
              {
                user.type !== 'student' && user.type !== 'assistant' && showMod &&
                <Button style={filled_button} onClick={this.showModal}>{voted ? t(`Modifier l'équipe`) : t(`Organiser une équipe`)}</Button>
              }
            </div>
          </div>
        </div>
        {
          user.type !== 'student' && user.type !== 'assistant' && showMod &&
          <Modal
            title={voted ? `${name} - Modification d'équipe` : `${name} - Sélection d'équipe`}
            bodyStyle={{ padding: 0 }}
            width={441}
            visible={visible}
            onCancel={this.closeModal}
            onOk={this.closeModal}
            footer={<ModalFooter projectId={id}
              sendData={this.sendData}
              updateTeam={this.updateTeam}
              updateProject={updateProject}
              batchid={batchid} />}
            destroyOnClose
            centered>
            <TeamSelection
              key={Date.now()}
              voted={voted}
              students={students}
              updateTeam={this.updateTeam}
              sendData={this.sendData}
              projectId={id} />
          </Modal>
        }
      </>
    );
  }
}

function mapStateToProps({ user, batchid }) {
  return { user, batchid }
}
export default connect(mapStateToProps, null)(withTranslation()(ProjectCard));

const styles = {
  card: {
    background: 'white',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    width: 533,
    minWidth: 340,
    minHeight: 330,
    display: 'grid',
    gridTemplateRows: '50px 1fr',
    margin: 30
  },
  header: {
    backgroundColor: '#5247EA',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 13px 0px 20px',
    width: '100%',
    minWidth: '100%'
  },
  avatar: {
    margin: '0px 3px'
  },
  title: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    color: '#ffffff',
    fontSize: 16,
    margin: 0
  },
  subtitle: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    color: '#010031',
    fontSize: 14,
    margin: '16px 0px 5px 0px'
  },
  text: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 300,
    color: '#010031',
    fontSize: 14,
    margin: 0
  },
  body: {
    padding: '10px 20px 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: '100%'
  },
  button_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  outline_button: {
    width: 170,
    height: 43,
    backgroundColor: '#ffffff',
    borderRadius: '50px',
    border: '2px solid #5247EA',
    color: '#5247EA',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: 14,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 3px'
  },
  filled_button: {
    width: 170,
    height: 43,
    backgroundColor: '#5247EA',
    borderRadius: '50px',
    color: '#ffffff',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: 14,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 3px'
  }
}