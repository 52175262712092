import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
// import Sider from './Batch/Sider';
import Header from './Header/Header';
import Container from './Batch/Container';
import Admin from './Admin';
// import WorkshopModeration from './WorkshopModeration';
// import StepHeader from './Batch/Container/StepHeader';


class Dashboard extends Component {
  state = {
    selectedKeys: [],
    headerIsVisible: true,
  }

  selectKeys = selectedKeys => {
    this.setState({ selectedKeys });
  }

  showHideHeader = () => {
    this.setState({ headerIsVisible: !this.state.headerIsVisible })
  }

  render() {
    const { layout } = styles;
    // const { selectedKeys } = this.state;
    const { user: { _id }, match } = this.props;
    const { headerIsVisible } = this.state;

    if (!_id) return <Redirect to="/" />;
    let componentToDisplay = <Container selectKeys={this.selectKeys} />;

    if (match?.params?.type === 'admin') {
      componentToDisplay = <Admin showHideHeader={this.showHideHeader} selectKeys={this.selectKeys} params={match?.params?.id} />;

    }
    // else if(match?.params?.type === 'workshop'){
    //   componentToDisplay = <WorkshopModeration />;
    // }

    return (
      <Layout style={layout}>
        {headerIsVisible && <Header />}
        {/* {
        match?.params?.type !== 'admin' &&
        <StepHeader step={ match?.params?.id } type={ match?.params?.type }/>
      } */}
        <Layout style={styles.content} className="ant-layout-has-sider">
          {/* <Sider match={match} path={match.path} step={match.params.id} selectedKeys={selectedKeys} /> */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', backgroundImage: 'url(/images/backgrounds/ArianeBackground.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', boxSizing: 'border-box', width: '100%' }}> */}
          {componentToDisplay}
          {/* </div> */}
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps({ user }) {
  return { user };
}

export default withRouter(connect(mapDispatchToProps)(Dashboard));

const styles = {
  layout: { height: '100vh', fontFamily: 'roboto' },
  content: { display: 'flex', flex: 1, overflow: 'auto', backgroundImage: 'url(/images/backgrounds/ArianeBackground.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', boxSizing: 'border-box', width: '100%' }
};
