import React, { Component } from 'react';
import { Upload, Icon, message } from 'antd';
import ImgCrop from 'antd-img-crop';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  // console.log((file.size / 1024 / 1024) < 0.5, file.type);

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) message.error('You can only upload JPG/PNG file!');

  const isLt250K = (file.size / 1024 / 1024) < 0.25;
  if (!isLt250K) message.error('Image must be smaller than 250KB!');

  return isJpgOrPng && isLt250K;
}

function fakeRequest({ onSuccess }) {
  setTimeout(() => { onSuccess('ok') }, 1);
}

export default class CompanyIcon extends Component {
  state = {
    loading: false,
    imageUrl: null
  }

  componentDidMount = () => {
    const { imageUrl } = this.props;

    this.setState({ imageUrl });
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }

    let { images } = this.props;
    const { setStateInParent, k } = this.props;

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        images[k] = imageUrl;
        setStateInParent({ imageUrl, loading: false, images });
        this.setState({ imageUrl, loading: false });
      });
    }
  }

  transformFile = file => {
    // console.log(file);
  }

  render() {
    const { imageUrl, loading } = this.state;

    const uploadButton = (<div>
      <Icon type={loading ? 'loading' : 'plus'} />
    </div>);

    // console.log(imageUrl)

    return (<ImgCrop>
      <Upload name="avatar" listType="picture-card" showUploadList={false} customRequest={fakeRequest} beforeUpload={beforeUpload} transformFile={this.transformFile} onChange={this.handleChange}>
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    </ImgCrop>);
  }
}