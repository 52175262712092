import React, { Component } from "react";
import { Layout, Col, Row } from "antd";
import { Redirect } from "react-router-dom";
import Header from "./Header/Header";
// import SiderIcons from './Components/SiderIcons';
import Today from "./Batch/Today";
// import Ressources from './Dashboard/Ressources';
import Calendar from "./Dashboard/Calendar";
// import Projects from './Dashboard/Projects';
// import TeacherDashboard from './Dashboard/TeacherDashboard';
import NPSDrawer from "./Dashboard/NPS";

import { connect } from "react-redux";
import moment from "moment";

// const { Sider } = Layout;

class Dashboard extends Component {
  breakpoint = 930;

  state = {
    current: this.props.menuSelected,
    visible: false,
    steps: [],
    stepsLoaded: false,
    showCalendar: window.innerWidth > this.breakpoint,
  };

  componentDidMount() {
    const {
      batch: {
        _id: batchid,
        /* countDay,*/ batchNumber,
        date: { end: endDate },
      },
      day,
      selectFirstStep,
      user,
      language,
      stackType,
    } = this.props;
    const fetchUrl = `${
      global.URI_BACKEND
    }/step?batchId=${batchid}&date=${moment(day, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    )}&language=${language || user.preferredLanguage}&userId=${user._id}`;

    this.setState({ stepsLoaded: false });
    fetch(fetchUrl)
      .then(res => res.json())
      .then(data => {
        const { steps } = data;
        this.setSteps(steps);
        const filteredSteps =
          steps?.filter(e => e.mainGroup !== "Teachers") || [];
        if (filteredSteps.length > 0) {
          selectFirstStep(filteredSteps[0]);
        }
      });

    if (
      Date.now() > new Date(endDate).getTime() &&
      batchNumber >= 28 &&
      user.type === "student" &&
      !user.nps
    ) {
      this.setState({ visible: false });
    }

    if (stackType.keyMode === "workshop")
      this.setState({ showCalendar: false });
    else window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    const { stackType } = this.props;
    if (stackType.keyMode !== "workshop")
      window.removeEventListener("resize", this.resize);
  }

  onMenuClick = menu => {
    this.setState({ current: menu });
  };

  selectDay = day => {
    const { setDay } = this.props;
    setDay(day);
  };

  npsClose = () => {
    this.setState({ visible: false });
  };

  resize = () => {
    this.setState({ showCalendar: window.innerWidth > this.breakpoint });
  };

  setSteps = async steps => {
    const {
      user,
      batch: { _id: batchid },
      stepsList,
    } = this.props;

    const data = await fetch(
      `${global.URI_BACKEND}/step/allProgress?batchId=${batchid}&userId=${user._id}`
    );
    const json = await data.json();

    if (json.success) {
      /**
       * first index after last step begun by student
       */

      let stepsTmp = steps?.map((e, i) => {
        if (json.progressGlobal[e.stepId]) {
          e.stepProgress = json.progressGlobal[e.stepId].stepPercentage;
          e.scoreProgress =
            json.progressGlobal[e.stepId].scorePercentage ||
            json.progressGlobal[e.stepId].stepPercentage;
          e.questionCount =
            json.progressGlobal[e.stepId]?.questionCount ||
            json.progressGlobal[e.stepId]?.sections?.length;
          e.ownSections = json.progressGlobal[e.stepId]?.sections;
          e.scoreResult = json.progressGlobal[e.stepId].scoreResult;
        }

        if (e.mainGroup === "Teachers") e.progress = false;

        if (json.progressStudent.includes(e.stepId) || !e.progress) {
          e.progressUnlocked = true;
        } else {
          e.progressUnlocked = false;
        }

        return e;
      });

      stepsTmp && stepsList(stepsTmp);
      this.setState({ steps: stepsTmp });
      this.setState({ stepsLoaded: true });
    }
  };

  setStepsLoaded = bool => {
    this.setState({ stepsLoaded: bool });
  };

  render() {
    const { layout, contentSectionCalendar, contentSection } = styles;
    const { user, day, stackType, batch } = this.props;
    const { visible, steps, stepsLoaded, showCalendar } = this.state;

    if (
      ["workshop", "student"].includes(user.type) &&
      stackType.keyMode === "workshop" &&
      !batch.name.match(/katas/i) &&
      moment.utc().isBefore(moment(batch.date.start))
    ) {
      return <Redirect to="/workshops/waiting" />;
    }

    return (
      <Layout style={layout}>
        <Header />
        <Layout style={{ flex: 1 }}>
          <Row id="dashboard" type="flex" style={{ flex: 1 }}>
            <Col style={showCalendar ? contentSectionCalendar : contentSection}>
              <Today day={day} steps={steps} stepsLoaded={stepsLoaded} />
            </Col>
            {showCalendar && (
              <Calendar
                user={user}
                selectDay={this.selectDay}
                setSteps={this.setSteps}
                setStepsLoaded={this.setStepsLoaded}
              />
            )}
            {visible && (
              <NPSDrawer
                visible={visible}
                userId={user.trellocardId}
                npsClose={this.npsClose}
              />
            )}
          </Row>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps({
  user,
  menuSelected,
  tracks,
  batch,
  progression,
  day,
  language,
  stackType,
}) {
  return {
    user,
    menuSelected,
    tracks,
    batch,
    progression,
    day,
    language,
    stackType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDay: function (day) {
      dispatch({ type: "changeDay", day });
    },
    selectFirstStep: function (firstStep) {
      dispatch({ type: "selectFirstStep", firstStep });
    },
    stepsList: function (stepsList) {
      dispatch({ type: "stepsList", stepsList });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const styles = {
  layout: { height: "100vh" },
  contentSectionCalendar: {
    padding: "20px 25px 10px",
    width: "calc(100% - 350px)",
    display: "flex",
    flexDirection: "column",
  },
  contentSection: {
    padding: "20px 25px 10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "all .3s ease-in-out",
  },
  button: { fontWeight: 700, letterSpacing: 0.2 },
  menu: { display: "flex", justifyContent: "center" },
};
