import React from "react";

//STYLES_______________________
import "../../Container/Content/Flashcard.css";
import "../teacherdashboards.css";

//UI___________________________
import { Modal, Progress, Spin, Icon } from "antd";

//COMPONENTS_________________
import Resource from "../../Container/StepHeader/Resource";

//SCROLLBAR_______________________
import SimpleBar from "simplebar-react";

//REDUX_____________________________
import { connect } from "react-redux";

const CompetencesModal = ({
  visible,
  openModal,
  closeModal,
  competence,
  loading,
}) => {

  return (
    <Modal
      visible={visible}
      style={{ top: 56, minWidth: 580 }}
      width="62vw"
      bodyStyle={{ padding: 0, maxHeight: "80vh" }}
      title={competence?.title || ""}
      footer={null}
      onCancel={closeModal}
      className="competence-modal"
      onOk={openModal}
      closeIcon={
        <>
          <Icon
            type="close"
            style={{ marginLeft: 10, cursor: "pointer", color: "#ffffff" }}
            onClick={() => closeModal()}
          />
        </>
      }
    >
      {loading ? (
        <div style={styles.spin_container}>
          <Spin style={{ margin: "auto" }} />
        </div>
      ) : competence ? (
        <SimpleBar style={{ maxHeight: "80vh", width: "100%" }}>
          <div
            style={{
              ...styles.resource_container,
              padding: "20px 15px",
            }}
          >
            <div style={styles.descriptionText}>
              <h3 style={{ margin: 0, fontSize: 14 }}>
                <span style={{ fontWeight: 600 }}>Objectif</span><br/>{competence.detail}
              </h3>
              {competence && (
                <Progress
                  type="circle"
                  width={54}
                  className="ant-progress-header"
                  style={{ marginLeft: 18 }}
                  status={
                    competence?.overallProgress > 0 ? "success" : "active"
                  }
                  success={{ strokeColor: "#98EDC4" }}
                  percent={competence?.overallProgress || 0}
                  format={() => (
                    <span style={{ fontWeight: 600 }}>
                      {competence?.overallProgress || 0}%
                    </span>
                  )}
                  // width={35}
                />
              )}
            </div>
          </div>
          {competence.resources.map((e, i) => (
            <>
              <div key={`header-${i}`} style={styles.header}>
                <h4 style={styles.headerTitle}>
                  {e.secondaryGroup}{e.detail && ` - ${e.detail}`}
                </h4>
                {e.enableProgress ? (
                  <Progress
                    type="circle"
                    // status={"success"}
                    success={{ strokeColor: "rgb(82, 71, 234)" }}
                    percent={e.progress}
                    className={"ant-progress-blue" + e.progress === 0 ? " ant-progress-null" : ""}
                    width={40}
                    format={() => (
                      <span
                        style={{
                          color: e.progress === 0 ? "white" : "rgb(82, 71, 234)",
                          fontWeight: 800
                        }}
                      >
                        {`${e.progress || 0}%`}
                      </span>
                    )}
                  />
                ) : (
                  <Progress
                    type="circle"
                    width={40}
                    trailColor="rgba(0, 0, 0, 0.17)"
                    percent={0}
                    format={() => (
                      <i className="icon-lock" style={styles.icon} />
                    )}
                  />
                )}
              </div>
              <div key={`body-${i}`} style={styles.resource_container}>
                {e.steps.map((f, j) => (
                  <Resource
                    resource={f}
                    key={j}
                    showDay
                    // QCMUnlocked={f.unlockedQuiz}
                    // flashcardsUnlocked={f.unlockedFlashCard}
                    showLocked
                    // stepsUnlocked={f.enable}
                  />
                ))}
              </div>
            </>
          ))}
        </SimpleBar>
      ) : (
        <></>
      )}
    </Modal>
  );
};

function mapStateToProps({ batch, stackType }) {
  return { batch, stackType };
}

export default connect(mapStateToProps, null)(CompetencesModal);

const styles = {
  header: {
    width: "100%",
    backgroundColor: "rgba(82, 71, 234, 0.17)",
    color: "#5247EA",
    padding: "12px 32px 12px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    color: "#5247EA",
    margin: 0,
    fontWeight: 600,
  },
  resource_container: {
    width: "100%",
    padding: 20,
    backgroundColor: "#F6F7FB",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  descriptionText: {
    color: "#000000",
    // fontWeight: 300,
    margin: "auto",
    fontSize: "14px",
    padding: 10,
    fontWeight: 400,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff",
    width: "100%",
  },
  icon: {
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.17)",
  },
  spin_container: {
    maxHeight: "100%",
    minHeight: "30vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
