import React, { Component } from 'react';
import { Modal, Switch, Row, Col } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

class Status extends Component {
  state = {
    visible: false,
    checked: false
  }

  componentDidMount() {
    const { user, batch } = this.props;

    const { start, end } = batch.date;
    const duringBatch = moment.utc().isBetween(moment.utc(start).startOf('day'), moment.utc(end).endOf('day'));

    if (duringBatch) {
      let checked = false;
      if (user.status === 'available') checked = true;

      global.SOCKET.on('change', this.change);

      const visible = Math.abs(moment().diff(user.handshake)) > 1800000;

      this.setState({ checked, visible });
    }
  }

  componentDidUpdate(prevProps) {
    const { user: { status } } = this.props;
    const { user: { status: prevStatus } } = prevProps;

    if (status !== prevStatus) {
      let checked = false;
      if (status === 'available') checked = true;

      this.setState({ checked });
    }
  }

  componentWillUnmount() {
    global.SOCKET.off('change', this.change);
  }

  toggleVisibility = () => {
    this.setState({ visible: true });
  }

  closeModal = () => {
    this.setState({ visible: false }, () => {
      const { checked } = this.state;

      if (checked) {
        const { forceDisplay } = this.props;

        forceDisplay(true);
      }
    });
  }

  switchChange = (checked) => {
    const { batchid, user } = this.props;

    const status = checked ? 'available' : 'away';

    this.setState({ loading: true }, () => {
      global.SOCKET.emit('change', { batchId: batchid, teacherId: user._id, status });
    });
  }

  change = (data) => {
    let checked = false;
    if (data?.status === 'available') checked = true;
    this.setState({ checked, loading: false });
  }

  render() {
    const { visible, checked, loading } = this.state;
    const { user } = this.props;
    const { bold } = styles;

    if (!user?._id) return null;

    const status = checked ? "Disponible" : "Absent";

    let statusColor = { fontWeight: 'bold' };

    if (checked) statusColor.color = '#34AD79';
    else statusColor.color = 'red';

    const disabled = user.status === 'busy';

    const statusDisplay = user.status === 'busy' ? 'Occupé' : status;

    return <Modal centered title="Veuillez définir votre statut" visible={visible} onCancel={this.closeModal} footer={null}>
      <Row type="flex" align="middle" justify="center" gutter={[5, 10]}>
        <Col span={24}>
          <Row type="flex" justify="center">
            <div style={bold}>Vous êtes actuellement : <span style={statusColor}>{statusDisplay}</span>.</div>
            {
              user.status === 'busy'
                ? <div>Vous <span style={statusColor}>êtes actuellement</span> sollicité par un étudiant ayant besoin d'aide !</div>
                : <div>Vous <span style={statusColor}>{checked ? 'pouvez' : 'ne pouvez pas'}</span> être solicité par un étudiant ayant besoin d'aide.</div>
            }
          </Row>
        </Col>
        <Col span={24}>
          <Row type="flex" align="middle" justify="center" gutter={5}>
            <Col flex="auto">
              Êtes-vous disponible ?
              </Col>
            <Col flex="auto">
              <Switch disabled={disabled} checked={checked} loading={loading} onChange={this.switchChange} unCheckedChildren="Non" checkedChildren="Oui" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>;
  }
}

function mapStateToProps({ user, batch, batchid }) {
  return { user, batch, batchid };
}

export default connect(mapStateToProps)(Status);

const styles = {
  bold: { fontWeight: 'bold' }
};