import typeChecker from '../../typeChecker';

function ifStatement(tc_if = {}, pb_if = {}) {
  const found = { test: false, consequent: false };

  if (!found.test && pb_if.test?.type && tc_if.test?.type === pb_if.test?.type) {
    found.test = typeChecker(tc_if.test, pb_if.test);
  }

  if (found.test && pb_if.consequent?.type && tc_if.consequent?.type === pb_if.consequent?.type) {
    found.consequent = typeChecker(tc_if.consequent, pb_if.consequent);
  }

  return Object.values(found).every(e => e === true);
}

export default ifStatement;