import React, { useState } from 'react'

//NAVIGATION___________________
import { Link } from 'react-router-dom'

const ClassmateCard = ({ person, batch, batchId, isStudent, isAlumni }) => {
    //STATE HOOKS______________________________
    const [hover, setHover] = useState(false)

    //FORMAT___________________________________
    const avatar = person.avatar ? `${global.URI_FRONTEND}/images/avatar/${person._id}.jpg` : `${global.URI_FRONTEND}/images/avatar/_male-default.jpg`
    const formattedName = person.fullname
    .split(' ')
    .map(e => {
        let index = e.indexOf('-')
        return index >= 0 ? 
        `${e[0].toUpperCase()}${e.slice(1, index + 1).toLowerCase()}${e[index + 1].toUpperCase()}${e.slice((index + 2)).toLowerCase()}`
        : `${e[0].toUpperCase()}${e.slice(1).toLowerCase()}`
    })
    .join(' ')

    return (
        <Link to={ `/profile/${ person._id }` }>
            <div style={ hover ? Object.assign({...styles.card }, { ...styles.card_hover }) : styles.card } onMouseEnter={ ()=>setHover(true) } onMouseLeave={ ()=>setHover(false) }>
                <div style={ person.avatar ? styles.card_banner : Object.assign({ ...styles.card_banner }, { paddingBottom: 4 }) }>
                    <img src={ avatar } alt={ formattedName } style={ person.avatar ? styles.img : Object.assign({ ...styles.img}, { objectFit: 'scale-down' }) }/>
                </div>
                <div style={ styles.card_menu }>
                    <h6 style={ styles.card_title }>{ formattedName }</h6>
                    {   
                        !isAlumni &&
                        <p style={ styles.card_subtitle }>{ isAlumni ? (person.batchList?.find(e => e.userType === 'student')?.name || person.type) : isStudent ? batch.name : person.batchList.find(e => e.batchId === batchId) ? person.batchList.find(e => e.batchId === batchId).userType : person.type !== 'superadmin' ? person.type : 'assistant' }</p>
                    }
                </div>
            </div>
        </Link>
    )
}

export default ClassmateCard;

const styles = {
    card: {
        width: '250px',
        display: 'grid',
        gridTemplateRows: '149px 35px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '3px',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        margin: '10px'
    },
    card_hover: {
        boxShadow: '3px 5px 6px rgba(0, 0, 0, 0.3)',        
    },
    card_banner: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)', 
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',  
    },
    card_menu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 12px',
    },
    card_title: {
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '16px',
        margin: 0,
        color: '#010031',
        fontFamily: "'Poppins', sans-serif",
        overflow: 'hidden',
        width: '175px',
        maxHeight: '30px'
    },
    card_subtitle: {
        fontWeight: 300,
        fontSize: '11px',
        lineHeight: '14px',
        textAlign: 'right',
        margin: 0,
        color: '#010031',
        fontFamily: "'Poppins', sans-serif",
    },
}