import React, { useState, useEffect } from "react";

// REDUX________________________________
import { connect } from "react-redux";

//UI____________________________
import { Layout, Tabs, Radio, DatePicker, Menu } from "antd";
import SimpleBar from "simplebar-react";

//MOMENT_____________________________
import moment from "moment";
import "moment/locale/fr";

//NAVIGATION__________________________
import { useParams, Redirect } from "react-router";

//COMPONENTS_______________________
import Header from "../../Header/Header";
import Positionnement from "./components/Positionnement";
import TeacherDashboard from "./TeacherDashboard";
import StudentDashboard from "./StudentDashboard";
import HelpRequestsHub from "./HelpRequestHub";

import "./Dashboard.css";

//TRANSLATION________________________
import { useTranslation } from "react-i18next";
import { getCurrentTime } from '../../../functions/currentTime';

const { TabPane } = Tabs;
const { Content } = Layout;
const { WeekPicker } = DatePicker;

// moment.locale('fr');

const Dashboards = ({
  batch,
  batchid,
  batchuserlist,
  user,
  stackType,
  language,
  refreshDashboard,
}) => {
  //PARAMETERS_______________________
  const { mode, id } = useParams();

  //TRANSLATION________________________
  const { t } = useTranslation();

  //STATE HOOKS________________________
  const [key, setKey] = useState(mode === "teacher" && id ? id : "batch");

  const [batchInfo, setBatchInfo] = useState([]);
  const [batchAverage, setBatchAverage] = useState(null);

  const [weeklyFeelings, setWeeklyFeelings] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    total: 0,
  });
  const [yesterdayFeelings, setYesterdayFeelings] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    total: 0,
  });
  const [evaluations, setEvaluations] = useState([
    // { week: 1, name: t("S") + "1" },
    // { week: 2, name: t("S") + "2" },
    // { week: 3, name: t("S") + "3" },
    // { week: 4, name: t("S") + "4" },
    // { week: 5, name: t("S") + "5" },
    // { week: 6, name: t("S") + "6" },
    // { week: 7, name: t("S") + "7" },
    // { week: 8, name: t("S") + "8" },
  ]);
  const [flashcards, setFlashcards] = useState([]);

  const [dataLoading, setDataLoading] = useState(true);
  const [absencesLoading, setAbsencesLoading] = useState(true);

  const [positionnementBatch, setPositionnementBatch] = useState([]);

  const [studentPanes, setStudentPanes] = useState([]);
  const [studentFeelings, setStudentFeelings] = useState([]);
  const [buddyList, setBuddyList] = useState([]);

  const [clickDate, setClickDate] = useState(moment().utc());
  const [calendarMode, setCalendarMode] = useState("day");
  const [radioMode, setRadioMode] = useState("day");

  const [dailyAbsence, setDailyAbscence] = useState([]);

  const [progressStudentsData, setProgressStudentsData] = useState([]);

  //const [defaultCalendarValue, setDefaultCalendarValue] = useState();

  const planning = batch.planning
    .map(e => e.days.map(f => moment(f.date).utc()))
    .flat()
    .filter(e => e.isBefore(batch.date.end));

  var startDate;
  var endDate;
  var defaultCalendarValue;
  let findWeek;
  let batchWeek;

  if (moment(clickDate).isAfter(moment(batch.date.end))) {
    defaultCalendarValue = moment(batch.date.end);
    findWeek = moment.utc(batch.date.end).week();
    batchWeek = findWeek - moment(batch.date.start).week();
  } else if (moment(batch.date.start).isAfter(moment(clickDate))) {
    defaultCalendarValue = moment(batch.date.start);
    findWeek = moment.utc(batch.date.start).week();
    batchWeek = findWeek - moment(batch.date.start).week();
  } else {
    defaultCalendarValue = moment();
    findWeek = moment.utc(clickDate).week();
    batchWeek = findWeek - moment(batch.date.start).week();
  }

  if (calendarMode === "batch") {
    startDate = moment.utc(batch.date.start).startOf("day");
    moment.utc().isAfter(moment.utc(batch.date.start))
      ? (endDate = moment.utc())
      : (endDate = startDate);
  } /*else if (calendarMode === "week") {
    startDate = moment
      .utc(batch.planning[batchWeek]?.days[0].date)
      .startOf("day");
    endDate = moment
      .utc(
        batch.planning[batchWeek].days[
          batch.planning[batchWeek].days.length - 1
        ]?.date
      )
      .endOf("day");
    if (
      moment.utc().isBefore(endDate) &&
      moment.utc().week() === moment.utc(endDate).week()
    )
      endDate = moment.utc();
  }*/ else if (calendarMode === "day") {
    startDate = moment.utc(clickDate).utc().startOf("day");
    endDate = moment.utc(clickDate).utc().endOf("day");
  }

  //EFFECT HOOKS_________________________
  useEffect(() => {
    const fetchFeelings = async () => {
      const weekFeelingData = await fetch(
        `${global.URI_BACKEND}/teacherdashboard/week-cumulated-feeling?batchId=${batchid}&startDay=${startDate}&mode=${calendarMode}`
      );
      const weekFeelingJSON = await weekFeelingData.json();

      if (weekFeelingJSON?.success) {
        let weekFeelingTable = [];

        if (weekFeelingJSON) {
          let temp = { ...weeklyFeelings };
          let total = 0;

          weekFeelingJSON.weeklyFeeling.forEach(e => {
            temp[`${e.notionUnderstood}`] = e.nbrStudents;
            total += e.nbrStudents;
          });

          setWeeklyFeelings({ ...temp, total });

          for (const e of weekFeelingJSON.weeklyFeeling) {
            for (const f of e.students) {
              weekFeelingTable.push({
                studentFullname: f.fullname,
                weekFeeling: e.notionUnderstood,
              });
            }
          }
        }

        const feelingData = await fetch(
          `${global.URI_BACKEND}/teacherdashboard/feeling-of-day?batchId=${batchid}&day=${clickDate}`
        );
        const feelingJSON = await feelingData.json();

        if (feelingJSON.success) {
          setStudentFeelings(feelingJSON.studentFeeling);
          return { weekFeelingTable, feelingJSON };
        }
      }
    };

    // BATCH, WEEK AND DAY
    const fetchSolutions = async () => {
      const data = await fetch(
        `${global.URI_BACKEND}/teacherdashboard/cumulated-solutions?batchId=${batchid}&startDay=${startDate}&endDay=${endDate}`
      );
      const json = await data.json();

      if (json?.success) return json;
    };

    // BATCH AND WEEK
    // Recuperation de la table d'emargement
    const fetchAbsences = async () => {
      const data = await fetch(
        `${global.URI_BACKEND}/teacherdashboard/total-emargementv2?batchId=${batchid}&startDay=${startDate}&endDay=${endDate}` /*&mode=${calendarMode}*/
      );
      const json = await data.json();

      if (json?.success) return json;
    };

    // BATCH, WEEK AND DAY
    const fetchRequests = async () => {
      const data = await fetch(
        `${global.URI_BACKEND}/teacherdashboard/cumulated-requests?batchId=${batchid}&startDay=${startDate}&endDay=${endDate}`
      );
      const json = await data.json();

      if (json?.success) return json;
    };

    const fetchEvaluations = async () => {
      let weeks = batch.planning.filter(
        e => !["review", "rncp", "project"].includes(e.weekType)
      ).length;

      if (weeks > 0) {
        const evaluationData = await fetch(
          `${global.URI_BACKEND}/teacherdashboard/studentevaluations?batchId=${batchid}`
        );
        const evaluationJSON = await evaluationData.json();

        //console.log(evaluationJSON.allEvaluations)

        if (evaluationJSON?.success) {
          //DEFAULT AXE X
          const quizData = evaluationJSON.allEvaluations;

          //DATA GRAPHIC
          let evalRes = {}
          let evalResArr = []
          let studentEvalResults = evaluationJSON.studentEvalResults;

          for (let i = 0; i < quizData.length; i++) {
            const completedQuiz = studentEvalResults.find(f => f._id.stepId === quizData[i].stepId)
            let high = completedQuiz?.scores.filter(f => f.score >= 70).length
            let medium = completedQuiz?.scores.filter(f => f.score < 70 && f.score > 50).length
            let low = completedQuiz?.scores.filter(f => f.score <= 50).length

            evalRes = {
              name: `N${i + 1}`,
              high: high || 0,
              medium: medium || 0,
              low: low || 0,
              stepId: quizData[i].stepId
            }

            evalResArr.push(evalRes)
          }

          setEvaluations(evalResArr);

          //DATA MODAL

          //console.log(evaluationJSON)

          let weekEvaluationTable = [];
          for (const e of evaluationJSON.studentEvalResults) {
            for (const f of e.scores) {
              weekEvaluationTable.push({
                studentFullname: f.fullname,
                stepId: e._id.stepId,
                score: f.score,
              });
            }
          }

          return weekEvaluationTable;
        }

        return [];
      }

      // OLD

      // if (weeks > 0) {
      //   const evaluationData = await fetch(
      //     `${global.URI_BACKEND}/teacherdashboard/studentevaluations?batchId=${batchid}`
      //   );
      //   const evaluationJSON = await evaluationData.json();

      //   if (evaluationJSON?.success) {
      //     let temp = Array.from({ length: weeks }, (v, k) => ({
      //       week: k + 1,
      //       name: `S${k + 1}`,
      //     })).map((e, i) => {
      //       let evalData = evaluationJSON.weeklyEvalResults.find(
      //         f => f.weekNumber === e.week
      //       );

      //       let high = evalData?.scores.filter(f => f.score >= 70).length;
      //       let medium = evalData?.scores.filter(
      //         f => f.score < 70 && f.score > 50
      //       ).length;
      //       let low = evalData?.scores.filter(f => f.score <= 50).length;

      //       return {
      //         ...e,
      //         high: high || 0,
      //         medium: medium || 0,
      //         low: low || 0,
      //         title: evalData?._id?.title,
      //       };
      //     });
      //     setEvaluations(temp);

      //     let weekEvaluationTable = [];
      //     for (const e of evaluationJSON.weeklyEvalResults) {
      //       for (const f of e.scores) {
      //         weekEvaluationTable.push({
      //           studentFullname: f.fullname,
      //           week: e.weekNumber,
      //           score: f.score,
      //         });
      //       }
      //     }

      //     return weekEvaluationTable;
      //   }

      //   return [];
      // }
    };

    // BATCH, WEEK AND DAY
    const fetchCompetences = async () => {
      const competenceData = await fetch(
        `${global.URI_BACKEND}/teacherdashboard/studentcompetencies/${
          /*language*/ "fr"
        }?batchId=${batchid}&startDay=${startDate}&endDay=${endDate}`
      );
      const competenceJSON = await competenceData.json();

      if (competenceJSON?.success) {
        return competenceJSON;
      }
    };

    const fetchBuddies = async () => {
      const data = await fetch(
        `${global.URI_BACKEND}/teacherdashboard/getbuddies?batchId=${batchid}`
      );
      const json = await data.json();

      if (json?.success) {
        setBuddyList(json.buddiesList);

        return json.buddiesList;
      }

      return [];
    };

    const fetchPositionnementData = async () => {
      const data = await fetch(
        `${global.URI_BACKEND}/positionnement/${batchid}`
      );
      const json = await data.json();
      if (json.success) {
        setPositionnementBatch(json.batchList);

        return json.batchList;
      }

      return [];
    };

    // WEEK

    const fetchFlashcardsWeek = async () => {
      const data = await fetch(
        `${global.URI_BACKEND}/teacherdashboard/weekflashcards?batchId=${batchid}&startDay=${startDate}&endDay=${endDate}`
      );
      const json = await data.json();

      if (json?.success) {
        setFlashcards(json.flashcardsWeekTable);

        let flashcardsTable = [];
        for (const e of json.flashcardsWeekTable) {
          for (const f of e.students) {
            flashcardsTable.push({
              studentFullname: f.fullname,
              day: e.name,
              totalFlashcards: e.nbrFlashcards,
              studentTotalFlashcards: f.nbrFlashcardsStudent,
              score: f.globalStudentPercentage,
            });
          }
        }
        return flashcardsTable;
      }
    };

    // DAY
    // Recuperation de la liste des personnes absences de la journee selectionnee [clickDate]
    const fetchAbsencesDay = async () => {
      const time = await getCurrentTime()

      const data = await fetch(
        `${global.URI_BACKEND}/teacherdashboardday/abscencestable?batchId=${batchid}&date=${clickDate}&timestamp=${time.timestamp}`
      );
      const json = await data.json();

      if (json?.success) {
        setDailyAbscence(json);
        return json;
      }
    };

    const fetchFeelingsDay = async () => {
      const data = await fetch(
        `${global.URI_BACKEND}/teacherdashboardday/cumulateddayfeeling?batchId=${batchid}&date=${clickDate}`
      );
      const json = await data.json();

      if (json?.success) {
        let yesterdayFeelingTable = [];

        if (json) {
          let temp = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, total: 0 };
          let total = 0;

          json.dayFeelings.forEach(e => {
            temp[`${e.notionUnderstood}`] = e.nbrStudents;
            total += e.nbrStudents;
          });

          setYesterdayFeelings({ ...temp, total });

          for (const e of json.dayFeelings) {
            for (const f of e.students) {
              yesterdayFeelingTable.push({
                studentFullname: f.fullname,
                yesterdayFeeling: e.notionUnderstood,
              });
            }
          }
        }

        return yesterdayFeelingTable;
      }
    };

    const fetchProgressionDay = async () => {
      const data = await fetch(
        `${global.URI_BACKEND}/teacherdashboardday/dailyprogression/${
          /*language*/ "fr"
        }?batchId=${batchid}&date=${clickDate}`
      );
      const json = await data.json();

      if (json?.success) {
        setProgressStudentsData(json.allStudentProgress)
        return json
      };
    };

    const importData = async () => {
      setDataLoading(true);

      let feelings = fetchFeelings();
      let solutions = fetchSolutions();
      let emargements;
      if (
        !(
          moment.utc(startDate).isAfter(moment.utc(batch?.currentDay?.date)) &&
          moment.utc(endDate).isAfter(moment.utc(batch?.currentDay?.date))
        )
      )
        emargements = fetchAbsences();

      let requests = fetchRequests();
      let evaluations = fetchEvaluations();
      let competences = fetchCompetences();
      let buddies = fetchBuddies();
      let positionnement = fetchPositionnementData();

      let flashcards;
      let absencesDay;
      let feelingsDay;
      let progressionDay;

      let data;

      if (calendarMode === "batch") {
        data = await Promise.allSettled([
          feelings,
          solutions,
          emargements,
          requests,
          evaluations,
          competences,
          buddies,
          positionnement,
        ]);
      } /*else if (calendarMode === "week") {
        flashcards = fetchFlashcardsWeek();
        data = await Promise.allSettled([
          feelings,
          solutions,
          emargements,
          requests,
          evaluations,
          competences,
          buddies,
          positionnement,
          flashcards,
        ]);
      }*/ else if (calendarMode === "day") {
        absencesDay = fetchAbsencesDay();
        feelingsDay = fetchFeelingsDay();
        progressionDay = fetchProgressionDay();
        data = await Promise.allSettled([
          feelings,
          solutions,
          emargements,
          requests,
          evaluations,
          competences,
          buddies,
          positionnement,
          absencesDay,
          feelingsDay,
          progressionDay,
        ]);
      }

      let batchData = batchuserlist
        .filter(e => e.batchList.some(
          f => f.userType === "student" && f.batchId === batchid
        ))
        .map((e, i) => {
          try {
            let hasFlashcards;
            let detailedAbsence;
            let yesterdayFeeling;
            let progressionDay;

            const weekFeelings =
              data[0].status === "fulfilled" &&
              data[0]?.value?.weekFeelingTable.find(
                f => f.studentFullname === e.fullname
              );
            const hasFeeling =
              data[0].status === "fulfilled" &&
              data[0].value?.feelingJSON?.studentFeeling.find(
                f => f.user === e._id
              );
            const solutions =
              data[1].status === "fulfilled" &&
              data[1]?.value?.cumulatedStudentsSolutions.find(
                f => f.studentId === e._id
              );
            // const absences = data[2].status === 'fulfilled' && data[2]?.value?.absenceTable.find(f => f.userId === e._id)
            const presences =
              data[2].status === "fulfilled" &&
              data[2]?.value?.emargeTable?.find(f => f._id === e._id);
            const hasRequests =
              data[3].status === "fulfilled" &&
              data[3]?.value?.cumulatedStudentLiveRequests.find(
                f => f._id === e._id
              );
            const weekEvaluations =
              data[4].status === "fulfilled" &&
              data[4].value?.filter(f => f.studentFullname === e.fullname);
            const hasCompetences =
              data[5].status === "fulfilled" &&
              data[5]?.value?.allStudentCompetencies.find(
                f => f._id === e._id
              );
            /*if (calendarMode === "week") {
              hasFlashcards =
                data[8].status === "fulfilled" &&
                data[8].value?.filter(f => f.studentFullname === e.fullname);
            } else*/ if (calendarMode === "day") {
              detailedAbsence =
                data[8].status === "fulfilled" &&
                data[8].value?.DayAbsencesTable?.filter(
                  f => f.fullname === e.fullname
                );
              yesterdayFeeling =
                data[9].status === "fulfilled" &&
                data[9].value?.filter(f => f.studentFullname === e.fullname);
              progressionDay =
                data[10].status === "fulfilled" &&
                data[10].value?.progressionDay?.filter(
                  f => f.fullName === e.fullname
                );
            }

            if (calendarMode === "batch") {
              return {
                student: {
                  id: e._id,
                  name: e.fullname,
                  avatar: e.avatar,
                },
                key: i,
                competences: hasCompetences?.totalStudentCompetency.totalProgress || null,
                competencesBonus:
                  hasCompetences?.totalStudentCompetency.totalBonusProgress || null,
                weekFeeling: weekFeelings?.weekFeeling || "NR",
                feeling: hasFeeling ? hasFeeling.notionUnderstood : null,
                solutions: solutions?.allSolutions || 0,
                absences: presences?.absenceDays || null,
                diligence: presences ? presences?.presencePerc : 0,
                helprequests: hasRequests ? hasRequests.allRequests : 0,
                weekEvaluations: weekEvaluations || "NR",
              };
            } /* else if (calendarMode === "week") {
              return {
                student: {
                  id: e._id,
                  name: e.fullname,
                  avatar: e.avatar,
                },
                key: i,
                competences: hasCompetences?.totalStudentCompetency.totalProgress || null,
                competencesBonus:
                  hasCompetences?.totalStudentCompetency.totalBonusProgress || null,
                weekFeeling: weekFeelings?.weekFeeling || "NR",
                feeling: hasFeeling ? hasFeeling.notionUnderstood : null,
                solutions: solutions?.allSolutions || 0,
                absences: presences?.absenceDays || null,
                diligence: presences ? presences?.presencePerc : 0,
                helprequests: hasRequests ? hasRequests.allRequests : 0,
                weekEvaluations: weekEvaluations || "NR",
                flashcards: hasFlashcards || "NR",
              };
            } */ else if (calendarMode === "day") {
              return {
                student: {
                  id: e._id,
                  name: e.fullname,
                  avatar: e.avatar,
                },
                key: i,
                //competences: progressionDay[0]?.progressionPercentage || null,
                // weekFeeling: weekFeelings?.weekFeeling || 'NR',
                yesterdayFeelings: yesterdayFeeling?.yesterdayFeeling || "NR",
                feeling: hasFeeling ? hasFeeling.notionUnderstood : null,
                solutions: solutions?.allSolutions || 0,
                // absences: absences?.absence || null,
                absMorning: detailedAbsence[0]?.morning === 0 ? true : false,
                absAfternoon:
                  detailedAbsence[0]?.afternoon === 0 ? true : false,
                absEvening: detailedAbsence[0]?.evening === 0 ? true : false,
                absOH: detailedAbsence[0]?.officeHours === 0 ? true : false,
                diligence: presences ? presences?.presencePerc : 0,
                helprequests: hasRequests ? hasRequests.allRequests : 0,
                // weekEvaluations: weekEvaluations || 'NR',
              };
            } else {
              return {
                student: {
                  id: e._id,
                  name: e.fullname,
                  avatar: e.avatar,
                },
                key: i,
                competences: null,
                weekFeeling: "NR",
                yesterdayFeelings: "NR",
                feeling: null,
                solutions: 0,
                absences: null,
                absMorning: false,
                absAfternoon: false,
                absEvening: false,
                absOH: false,
                diligence: 0,
                helprequests: 0,
                weekEvaluations: "NR",
                flashcards: "NR",
              };
            }
          } catch (error) {
            //console.error(error);

            return {
              student: {
                id: e._id,
                name: e.fullname,
                avatar: e.avatar,
              },
              key: i,
              competences: null,
              weekFeeling: "NR",
              yesterdayFeelings: "NR",
              feeling: null,
              solutions: 0,
              absences: null,
              absMorning: false,
              absAfternoon: false,
              absEvening: false,
              absOH: false,
              diligence: 0,
              helprequests: 0,
              weekEvaluations: "NR",
              flashcards: "NR",
            };
          }
        })
        .sort((a, b) => {
          return (!a.feeling && b.feeling) || a.feeling < b.feeling
            ? 1
            : (a.feeling && !b.feeling) || a.feeling > b.feeling
              ? -1
              : 0;
        });

      let average =
        data[5].status === "fulfilled"
          ? data[5]?.value?.allBatchCompetencies.average
          : "NR";

      setBatchAverage(average);
      setBatchInfo(batchData);
      setAbsencesLoading(false);
      setDataLoading(false);
    };

    if (
      batchuserlist &&
      mode === "teacher" &&
      !["student", "assistant"].includes(user.type)
    ) {
      importData().catch(err => console.error(err));
    }
  }, [clickDate, refreshDashboard /*language*/])

  //FUNCTIONS____________________________
  const onKeyChange = key => {
    setKey(key);
  };

  const addStudentPane = student => {
    let studentPane = studentPanes.find(e => e.id === student.id);
    if (!studentPane) {
      setStudentPanes([...studentPanes, student]);
    }
    setKey(student.id);
    setRadioMode("batch");
    setClickDate(moment().utc());
    setCalendarMode("batch");
  };

  const deleteStudentPane = (key, action) => {
    if (action === "remove") {
      setStudentPanes(studentPanes.filter(e => e.id !== key));
      setKey("batch");
    }
  };

  const onChange = e => {
    setClickDate(moment().utc());
    setCalendarMode(e.target.value);
    setRadioMode(e.target.value);
  };

  const onChangeDateWeek = (date, dateString) => {
    setClickDate(date);
    //setCalendarMode("week");
  };

  const onChangeDateDay = (date, dateString) => {
    setClickDate(date);
    setCalendarMode("day");
  };

  const disabledDate = current => {
    return !planning.find(e => e.isSame(current.utc().startOf("day")))
      ? true
      : false;
  };

  const permanentPanes = [
    {
      title: "Batch",
      content: (
        <TeacherDashboard
          batch={batchInfo}
          batchAverage={batchAverage}
          evaluations={evaluations}
          flashcards={flashcards}
          dataLoading={dataLoading}
          absencesLoading={absencesLoading}
          weeklyFeelings={weeklyFeelings}
          yesterdayFeelings={yesterdayFeelings}
          calendarMode={calendarMode}
          dailyAbsence={dailyAbsence}
          addStudentPane={addStudentPane}
          selectedDay={clickDate}
          progressStudentsData={progressStudentsData}
        />
      ),
      key: "batch",
      closable: false,
    },
    {
      title: t("Help requests"),
      content: (
        <HelpRequestsHub
          batchData={batchInfo}
          studentFeelings={studentFeelings}
          buddyList={buddyList}
        />
      ),
      key: "helprequests",
      closable: false,
    },
    {
      title: t("Positionnement"),
      content: (
        <Positionnement
          batch={positionnementBatch}
          addStudentPane={addStudentPane}
        />
      ),
      key: "positionnement",
      closable: false,
    },
  ];

  return mode === "teacher" && user.type === "student" ? (
    <Redirect to="/home/today" />
  ) : (
    <Layout style={styles.page_container} className="dashboard-content">
      <Header />
      {mode === "student" ? (
        <StudentDashboard studentId={user._id} />
      ) : mode === "teacher" && user.type === "assistant" ? (
        <HelpRequestsHub buddyList={buddyList} />
      ) : mode === "teacher" ? (
        <div>
          {/* {key != 'batch' || key === 'positionnement' || key === 'helprequests' ? "" : */}
          {!['positionnement', 'helprequests'].includes(key) &&
            <Menu style={{ textAlign: "end" }} mode="horizontal">
              <Menu.Item disabled="true">
                <Radio.Group onChange={onChange} value={radioMode}>
                  <Radio
                    value={"batch"} /* disabled={key != 'batch' ? true : false} */
                  >
                    {t("Au batch")}
                  </Radio>
                  {/* <Radio
                    value={"week"} disabled={key != 'batch' ? true : false}
                  >
                    {t("À la semaine")}
                  </Radio> */}
                  {key === "batch" && (
                    <Radio value={"day"} disabled={key !== "batch"}>
                      {t("À la journée")}
                    </Radio>
                  )}
                </Radio.Group>
                {radioMode === "batch" ? (
                  <WeekPicker
                    defaultValue={defaultCalendarValue}
                    disabledDate={disabledDate}
                    placeholder="Select week"
                    disabled={true}
                  />
                ) /*: radioMode === "week" ? (
                  <WeekPicker
                    defaultValue={defaultCalendarValue}
                    disabledDate={disabledDate}
                    style={{ zIndex: 1 }}
                    onChange={onChangeDateWeek}
                    placeholder="Select week"
                    allowClear={
                      false
                    } disabled={key != 'batch' ? true : false}
                  />
                )*/ : radioMode === "day" ? (
                    <DatePicker
                      defaultValue={defaultCalendarValue}
                      disabledDate={disabledDate}
                      style={{ zIndex: 1 }}
                      onChange={onChangeDateDay}
                      bordered={false}
                      allowClear={
                        false
                      } /* disabled={key != 'batch' ? true : false}  */
                    />
                  ) : (
                    ""
                  )}
              </Menu.Item>
            </Menu>
          }
          {/* } */}
          <Tabs
            activeKey={key}
            type="editable-card"
            onChange={onKeyChange}
            onEdit={deleteStudentPane}
            hideAdd
          >
            {permanentPanes.map(e => (
              <TabPane tab={e.title} key={e.key} closable={e.closable}>
                <Content className="teacher-dashboard">
                  <SimpleBar forceVisible style={{ height: "100%" }}>
                    {e.content}
                  </SimpleBar>
                </Content>
              </TabPane>
            ))}
            {studentPanes.length > 0 &&
              studentPanes.map(e => (
                <TabPane tab={e.name} key={e.id} closable={true}>
                  <Content className="teacher-dashboard">
                    <SimpleBar forceVisible style={{ height: "100%" }}>
                      <StudentDashboard
                        studentId={e.id}
                        mode="teacher"
                        calendarModeParent={calendarMode}
                        clickDateParent={clickDate}
                      />
                    </SimpleBar>
                  </Content>
                </TabPane>
              ))}
          </Tabs>
        </div>
      ) : null}
    </Layout>
  );
};

function mapStateToProps({
  batch,
  batchid,
  batchuserlist,
  user,
  stackType,
  language,
  refreshDashboard,
}) {
  return {
    batch,
    batchid,
    batchuserlist,
    user,
    stackType,
    language,
    refreshDashboard,
  };
}

export default connect(mapStateToProps, null)(Dashboards);

const styles = {
  page_container: {
    height: "100vh",
  },
};
