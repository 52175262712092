import React, { Component } from 'react';
import { Row, Col, notification, Modal, Button, Spin } from 'antd';
import { connect } from 'react-redux';

//import HeaderModal from '../../../headermodal';
import checkRank from '../../../utils/checkRank';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

class GroupPairs extends Component {
  render() {
    const { usersGrouped } = this.props;

    var userpairs = usersGrouped.map((pair, index) => {
      return (<Col key={`users-grouped-${index}`} span={10} >
        {pair.avatar}{pair.firstname} <br /> {pair.lastname}
      </Col>);
    });

    return (<Col style={styles.pairs} span={10}>
      {userpairs}
    </Col>);
  }
}

class GroupsLists extends Component {
  state = {
    visibleModalGenGroups: false,
    groupsList: [],
    isFetching: false,
  }

  openNotification = (type) => {
    let description;

    if (type === 'success') description = 'The groups generation is a success !';
    else description = 'A mistake happened during the groups generation process...';

    notification[type]({ message: 'Groups generation', description: description });
  }

  componentDidMount() {
    global.SOCKET.on('groupsGeneration', data => {
      if (data.success) {
        this.setState({ visibleModalGenGroups: false, groupsList: data.groups });

        this.openNotification('success');
      } else {
        this.openNotification('error');
      }
    });
  }

  generateGroups = () => {
    this.setState({ isFetching: true })

    const { batchId } = this.props;
    //global.SOCKET.emit('groupsGeneration', { batchId });

    fetch(`${global.URI_BACKEND}/crons/generateGroups?batchId=${batchId}`)
      .then(res => res.json())
      .then(data => {
        //fetch to get modified batch
        fetch(`${global.URI_BACKEND}/batch/data/${batchId}`)
          .then(res => res.json())
          .then(data => {
            this.setState({ isFetching: false })

            if (data) {
              this.setState({ visibleModalGenGroups: false, groupsList: data.data.group_history.groupList });
              this.openNotification('success');
            } else {
              this.openNotification('error');
            }
          })
      })
  }

  showModalGenGroups = () => {
    this.setState({ visibleModalGenGroups: true });
  }

  handleCancel = () => this.setState({ visibleModalGenGroups: false });

  render() {
    const { groupsList } = this.state;
    const { batch, userList, user, buddyList, t } = this.props;

    let buddies;

    groupsList.length !== 0
      ? buddies = groupsList
      : buddies = batch.group_history.groupList;

    let buddiesList = buddies.map((buddy, index) => {
      let buddiesPairs = [];

      for (const b of buddy) {
        let avatar = null;

        for (const u of userList) {
          if (u._id === b._id) {
            const src = u.avatar
              ? `${global.URI_FRONTEND}/images/avatar/${u._id}.jpg`
              : `${global.URI_FRONTEND}/avatar/_male-default.jpg`;

            avatar = <img style={styles.avatar} src={src} alt="avatar" />;
          }
        }

        buddiesPairs.push({ firstname: b.firstname, lastname: b.lastname, avatar: avatar });
      }

      buddyList(buddies)

      return (<GroupPairs key={index} usersGrouped={buddiesPairs} />);
    });


    return (<div>
      <div style={{ minHeight: '100vh' }}>
        <Col>
          <Row style={styles.userList}>
            <h3>{t("Groupes du jour")}</h3>
            <div style={styles.mainLine}></div>
          </Row>
          <div style={styles.genGroupStyle}>
            {
              checkRank(user, { allowedRanks: ['teacher', 'superadmin', 'admin', 'manager'] }) &&
              <p style={styles.genGroupsButtonStyle} onClick={this.showModalGenGroups}>{t("Générer de nouveaux groupes")}</p>
            }
          </div>
        </Col>
        {buddies && <Row type="flex" justify="space-around" align="middle">{buddiesList}</Row>}
      </div>
      <Modal
        /*title={<HeaderModal />}*/
        visible={this.state.visibleModalGenGroups}
        width={300}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} key="submit" type="primary" onClick={() => this.generateGroups()} disabled={this.state.isFetching}>
            {this.state.isFetching ? <Spin size='small' style={{ position: 'relative', top: 2 }} /> : "Ok"}
          </Button>,
        ]}
      >
        {t("Confirmez pour nouveaux groupes...")}
      </Modal>
    </div>);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    buddyList: function (buddies) {
      dispatch({ type: 'buddylist', buddylist: buddies });
    }
  }
}

function mapStateToProps({ batchuserlist, user, batch, buddies, batchid, stackid }) {
  return { userList: batchuserlist, user, batch, buddies, batchId: batchid, stackid };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupsLists));

const styles = {
  userList: { paddingTop: 15, textAlign: 'center', marginBottom: 10 },
  mainLine: { height: 1, width: '80%', marginLeft: '10%', backgroundColor: 'lightGrey', marginBottom: 10 },
  genGroupStyle: { display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', },
  genGroupsButtonStyle: { borderRadius: 10, backgroundColor: '#FC5D57', padding: '3px', display: 'inline', width: '40%', boxShadow: '2px 2px 2px #F1F1F1', cursor: 'pointer', color: '#FFFFFF' },
  avatar: { width: "80%", borderRadius: '50%', paddingTop: 10, paddingBottom: 10, marginLeft: 10, display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', },
  pairs: { marginBottom: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', minHeight: 150 }
};
