import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Col, Row, Tabs, Spin, Collapse, Card, Button, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';

import '../Batch/Container/Content/Course.css';
import './FranchisedContainer.css';

// TRANSLATION -------------------
import { withTranslation } from 'react-i18next';

const { TabPane } = Tabs;
const { Panel } = Collapse;

class FranchisedContainer extends Component {
  state = {
    selectedContent: {},
    contentLoading: false,
    noRessource: false,
  }

  doc = { overview: null, content: null };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { selectedManual: prevSelectedManual, language: prevLanguage } = prevProps;
    const { selectedManual, language } = this.props;

    if (prevSelectedManual._id !== selectedManual._id || prevLanguage !== language) {
      const selectedContent = selectedManual?.content?.[0] || {};

      this.setState({ selectedContent }, () => {
        this.fetchData();
      });
    }
  }

  transform = (node, index) => {
    if (node.type === 'tag') {
      node.attribs.class += " gContentP";

      return convertNodeToElement(node, index, this.transform);
    }
  }


  fetchData = async () => {
    const { selectedContent } = this.state;
    const { selectedManual, chapterId, language } = this.props;
    const { titleContainer } = styles

    try {
      const { _id, type, title, url, gdocUrl } = selectedContent;
      var manualId = selectedManual._id
      let data;
      let content;

      if (type == 'doc') {

        if (!url) return false;

        const request = await fetch(`${global.URI_BACKEND}/scrapRessources/franchised/${_id}/${manualId}/${chapterId}/${language}`).then(response => response.json()).then(data => {

          if (data.rawResponse == undefined || data.rawResponse == null) {

            this.setState({ noRessource: true })

          } else {

            this.setState({ noRessource: false })
            data = data.rawResponse.replace(/(\.[a-z0-9]+){/ig, `#${type} $1{`);
            data = data.replace(/}([a-z0-9]+){/ig, `}#${type} $1{`);

            const parser = new DOMParser();
            //const doc = parser.parseFromString(data, 'text/html');
            this.doc[type] = parser.parseFromString(data, "text/html");

            const courseList = [];

            var newStyle = this.doc[type].body.querySelector('style');
            if (!newStyle) {
              newStyle = this.doc[type].querySelector('style');
            }
            newStyle.id = `style-${type}`;

            const oldStyleDoc = document.querySelector(`#style-${type}`);
            if (oldStyleDoc) oldStyleDoc.remove();

            const oldStyleContent = document.querySelector(`#style-content`);
            if (oldStyleContent) oldStyleContent.remove();

            const oldStyleResume = document.querySelector(`#style-resume`);
            if (oldStyleResume) oldStyleResume.remove();

            const styleElmt = document.getElementsByTagName('head')[0];
            styleElmt.insertBefore(newStyle, styleElmt.firstChild);

            const sheet = document.querySelector('style').sheet;

            if (sheet) {
              sheet.insertRule(`#${type} table.noBorder td { border: 0px !important; }`, document.querySelector('style').sheet.cssRules.length);
            }


            // var childrenList = doc.body.children;

            var childrenList = this.doc[type].getElementById("contents").getElementsByTagName("div")[0].children

            // CONDITION TO ENCOUNTER CHANGES IN GDOC STRUCTURE - (div around all children in new version)

            /* if (childrenList[0].tagName == 'DIV' && childrenList[1].tagName == 'DIV') {
              childrenList = doc.getElementById("contents").getElementsByTagName("div")[0].children
            } */

            for (const child of childrenList) {

              if (child.tagName === 'H1' && child.innerText.length > 0) {
                courseList.push({ title: child.innerText });
                continue;
              } else if (courseList.length === 0) continue;

              if (!courseList[courseList.length - 1]) courseList.push({});
              if (!courseList[courseList.length - 1].content) courseList[courseList.length - 1].content = [];

              courseList[courseList.length - 1].content += child.outerHTML;
            }
            content = courseList.map(e => ({ title: e.title, content: ReactHtmlParser(e.content, { transform: this.transform }) }));
          }
        })

        if (content != null) {
          data = await content.map((e, i) =>
            <div key={i + 1} id={`panel-${i}`}>
              <div style={titleContainer}><h2 style={{ margin: 0, padding: 15, fontSize: '16px' }} className="section-title">{e.title}</h2></div>
              <Card>{e.content}</Card>
            </div>
          )
        }

      } else if (type == 'pdf') {

        data = <>
          {gdocUrl && <div style={{ marginBottom: '15px' }}>Lien Google Docs : <a href={gdocUrl} target="_blank">{gdocUrl}</a></div>}
          <iframe id={`${type}-${_id}`} title={`${title}`} src={url} frameBorder="0" width="100%" height="520" scrolling="no" allowFullScreen={true} mozallowfullscreen="true" webkitallowfullscreen="true" />
        </>;

      } else if (type == 'video') {
        data = <Col span={24} style={{ height: `${window.innerHeight / 2}px` }} id="video">
          <Player ref={ref => this.player = ref} onload={() => console.log('Player onload', this.player.getState())} autoPlay={false} src={url} fluid={false} height='100%'>
            <BigPlayButton position="center" />
            <LoadingSpinner />
          </Player>
        </Col>;
      } else if (type == 'slide') {
        data = <iframe id="slides" src={url} frameBorder="0" width="100%" height="100%" scrolling="no" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />
      }

      this.setState({ [selectedContent._id]: data });
    } catch (error) {
      console.error(error);
    }
  }

  onScroll = (e) => {
    const offset = e.currentTarget.scrollTop - e.currentTarget.getBoundingClientRect().top;

    this.setState({ scrollTop: Math.max(0, offset) });
  }

  onTabClick = async key => {
    const { selectedManual } = this.props;

    const keySplit = key.split('-');

    const selectedContent = selectedManual?.content?.find(content => content._id === keySplit[keySplit.length - 1]);

    if (!this.state[selectedContent._id]) {
      this.setState({ selectedContent }, () => {
        this.fetchData();
      });
    }
  }

  synchronizeRessource = () => {

    const { selectedContent } = this.state;
    const { selectedManual, chapterId, language } = this.props;

    this.setState({ contentLoading: true });

    var stepId = selectedContent._id;
    var manualId = selectedManual._id;

    fetch(`${global.URI_BACKEND}/scrapRessources/fill-database-franchise/${stepId}/${manualId}/${chapterId}/${language}`).then(response => response.json()).then(data => {
      this.fetchData()
      this.setState({ contentLoading: false });
    })
  }

  render() {
    const { mb, loading } = styles;
    const { selectedManual, title, user, t } = this.props;
    const { contentLoading, noRessource, resources } = this.state;

    const manualTabs = selectedManual?.content?.map(({ _id, title }) => {
      return <TabPane id="content" key={`tab-${_id}`} tab={<span onMouseEnter={() => this.onTabClick(_id)} className="noselect">{title}</span>}>
        {this.state[_id] || <Spin size="large" />}
      </TabPane>;
    });

    if (!selectedManual._id) {
      return (<Layout style={{ padding: '10px 8px 5px 25px', marginTop: '30px', /*marginRight: '20%',*/ fontWeight: 300, fontSize: '14px' }}>
        <Spin size="large" />
      </Layout>)
    } else {
      return (

        <Layout id="contentFranchise" onScroll={this.onScroll} style={{ padding: '10px 8px 5px 25px', flex: 1 }}>

          {user.type === 'superadmin' &&
            <Row style={styles.container2}>
              <Button style={styles.syncButton} type="danger" shape="round" size="default" onClick={this.synchronizeRessource} title="Synchroniser la ressource">
                <Icon type="cloud-upload" />
              </Button>
            </Row>
          }

          {contentLoading ?
            <div style={loading}>
              <Spin size='large' />
            </div>
            : noRessource == true ? <p style={{ margin: "20px", textAlign: 'center' }}>{t("Vous devez synchroniser les ressources")}</p>
              :
              <>
                <Row style={mb}>
                  <Col style={{ fontSize: 24 }}>{title}</Col>
                </Row>

                <Col span={17} className="tabs-content">
                  <Tabs onTabClick={this.onTabClick} onMouseEnter={this.onTabClick} className="content-tabs">
                    {manualTabs}
                  </Tabs>
                </Col>
              </>
          }


        </Layout>

      );
    }
  }
}

function mapStateToProps({ progression, tracks, batch, user, language }) {
  return { progression, tracks, batch, user, language };
}

function mapDispatchToProps(dispatch) {
  return {
    selectFirstStep: function (firstStep) {
      dispatch({ type: 'selectFirstStep', firstStep });
    }
  }
}

const ContainerRedux = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FranchisedContainer));

export default withRouter(ContainerRedux);

const styles = {
  path: { margin: '5px 0 0 0', padding: 0 },
  content: { display: 'flex', flexDirection: 'column' },
  mb: { marginBottom: 24 },
  loading: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' },
  titleContainer: { position: '-webkit-sticky', position: 'sticky', display: 'flex', justifyContent: 'space-between', top: '0px', zIndex: 99, backgroundColor: '#ffffff', borderRadius: '2px 2px 0 0', border: '1px solid #f0f0f0', height: '66px', alignItems: 'center' },
  container2: { display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: '100%', height: '5vh', padding: '10px 0px' },
  syncButton: { position: 'absolute', top: 5, right: 15, zIndex: 200 },
  loading: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }

};
