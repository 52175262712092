export default function messages(state = [], action) {
    if  (action.type === 'messages') {
      return action.messages;
    }else if(action.type === 'addMessage'){
      let exists = state.findIndex(e => e._id === action.message._id);
      if(exists !== -1){
        let tempMsg = [...state];
        tempMsg[exists] = action.message;
        return tempMsg;
      }else{
        return [action.message, ...state];
      }
    }
    return state;
  }