import React, { useState, useEffect } from "react";

//NAVIGATION______________________
import { useParams } from "react-router-dom";

//REDUX_________________________
import { connect } from "react-redux";

//STYLES_______________________
import "./syllabus.css";

//UI___________________________
import { Layout, Modal, Row, Spin, Col, Input, Select, Switch } from "antd";

//COMPONENTS____________________
import Header from "../Header/Header";
import { SyllabusCategory } from "./SyllabusCategory";
import Resource from "../Batch/Container/StepHeader/Resource";
import moment from "moment";

//SCROLLBAR_________________________
import SimpleBar from "simplebar-react";

// TRANSLATION -------------------
import { useTranslation } from "react-i18next";

const { Content } = Layout;
const { Option } = Select;

const Syllabus = ({ stack, batchid, batch, user, tracks, setDay, language }) => {
  //PARAMS______________________________
  // const { pageType } = useParams();

  const [pageType, setPageType] = useState("course");

  //STATE HOOKS__________________________
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(true);

  const [countDay, setCountDay] = useState(null);
  const [storedModalDays, setStoredModalDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  const [open, setOpen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [searchResult, setSearchResult] = useState(null);

  const [progress, setProgress] = useState({});

  const { t } = useTranslation();

  // Permet de changer le type de page a afficher
  function onChange(checked) {
    setPageType(checked ? "title" : "course");
  }

  const loadProgress = async () => {
    const data = await fetch(
      `${global.URI_BACKEND}/step/allProgress?batchId=${batchid}&userId=${user._id}`
    );
    const json = await data.json();
    setProgress(json);
  };

  //EFFECT HOOKS___________________________
  useEffect(() => {
    const loadStack = async () => {
      setLoading(true);
      const data = await fetch(
        `${global.URI_BACKEND}/syllabus/${pageType}/${batchid}/${language}`
      );
      const json = await data.json();

      if (json.result) {
        setCategories(json.syllabus.categories);
        setLoading(false);
      }
    };

    if (batchid) {
      loadStack();
      loadProgress();
    }
  }, [batchid, pageType, language, user._id]);

  useEffect(() => {
    setStoredModalDays([]);
  }, [language]);

  //FUNCTIONS________________________
  const toggleModal = () => {
    setOpen(!open);
  };

  const changeDay = day => {
    if (
      !storedModalDays.find(el => el.day === day && el.language === language)
    ) {
      setLoadingModal(true);
      const date = getStackDate(day);
      fetch(`${global.URI_BACKEND}/step?batchId=${batchid}&userId=${user._id}&date=${date}&language=${language}`)
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            for (const t of tracks) {
              for (const p of t.projects) {
                for (const d of p.days) {
                  if (d.day === day) {

                    let dayTmp = { ...d };
                    dayTmp.steps = data.steps;
                    dayTmp.imagepath = p.imagepath;
                    dayTmp.language = language;
                    dayTmp.title = d.data.find(d => d.language === language)
                      ?.title
                      ? d.data.find(d => d.language === language)?.title
                      : d.data[0].title;

                    const addDay = [...storedModalDays, dayTmp];
                    const selectDayTmp = addDay.find(el => el.day === day);

                    setStoredModalDays(addDay);
                    loadProgress()
                    if (selectDayTmp) {
                      setCountDay(day);
                      setSelectedDay(selectDayTmp);
                      setLoadingModal(false);
                    }
                  }
                }
              }
            }
          }
        });
      // setSelectedDay(null);
    } else {
      setSelectedDay(
        storedModalDays.find(el => el.day === day && el.language === language)
      );
    }
    toggleModal();
  };

  const getStackDate = numDay => {
    const { planning } = batch;
    let date = moment(batch.date.start);
    for (let week of planning) {
      for (let day of week.days) {
        if (day.countDay === numDay) {
          date = moment(day.date);
          break;
        }
      }
    }

    return date;
  }

  const onItemClick = (e) => {
    const isEnabled = e.enabled || !["student", "workshop"].includes(user.type);
    if (isEnabled) {
      changeDay(e.day);
    }
  };

  const selectDay = () => {
    const date = getStackDate(selectedDay?.day);
    setDay(date);
  };

  const selectResource = id => {
    let dayTmp;

    for (const t of tracks) {
      for (const p of t.projects) {
        for (const d of p.days) {
          for (const s of d.steps) {
            if (s.stepId === id) {
              dayTmp = d.day;
              break;
            }
          }
        }
      }
    }

    const date = getStackDate(dayTmp);
    setDay(date);
  };

  const updateProgress = async (mode, data) => {
    const updateSteps = steps => {
      return steps.map(e => {
        if (progress.progressGlobal[e.stepId]) {
          e.stepProgress = progress.progressGlobal[e.stepId].stepPercentage;
          e.scoreProgress =
            progress.progressGlobal[e.stepId].scorePercentage ||
            progress.progressGlobal[e.stepId].stepPercentage;
          e.questionCount =
            progress.progressGlobal[e.stepId]?.questionCount ||
            progress.progressGlobal[e.stepId]?.sections?.length;
          e.ownSections = progress.progressGlobal[e.stepId]?.sections;
          e.scoreResult = progress.progressGlobal[e.stepId].scoreResult;
        }

        if (e.mainGroup === "Teachers") e.progress = false;

        if (progress.progressStudent.includes(e.stepId) || !e.progress) {
          e.progressUnlocked = true;
        } else {
          e.progressUnlocked = false;
        }

        return e;
      })
    }

    if (progress.success) {
      if (mode === "day") {
        let dayTmp = { ...data };

        dayTmp.steps = updateSteps(dayTmp.steps);

        const addDay = [...storedModalDays, dayTmp];
        const selectDayTmp = addDay.find(el => el.day === countDay);

        setStoredModalDays(addDay);
        if (selectDayTmp) {
          setSelectedDay(selectDayTmp);
        }
      } else if (mode === "search") {
        const stepsTmp = updateSteps(data);

        setSearchResult(stepsTmp);
        setLoadingSearch(false);
      }
    }
  };

  const onSearch = async value => {
    if (value !== "") {
      setLoadingSearch(true);
      const data = await fetch(`${global.URI_BACKEND}/slide/search`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          search: value,
          batchId: batchid,
          language: language,
        }),
      });
      const json = await data.json();
      setSearchResult(json.steps);
      updateProgress("search", json.steps);
    } else {
      setSearchResult(null);
    }
  };

  return (
    <Layout style={styles.page_container} className="hide-scrollbar">
      <Header enableButtonAndSearch />
      <Layout style={styles.page_container} className="hide-scrollbar">
        <div></div>
        <Content className="hide-scrollbar">
          {
            <Row style={styles.menu_container}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <Input.Search
                  style={styles.search_menu}
                  placeholder={t("Rechercher une ressource")}
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  onSearch={onSearch}
                  allowClear
                  loading={loadingSearch}
                  size="large"
                />
              </Col>
            </Row>
          }

          <Row>
            <Col span={24} offset={6}>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <div>
                  <span style={styles.titleSwitch}>Type d'affichage</span>
                  Par semaine{" "}
                  <Switch
                    className="ant-switch-syllabus"
                    style={{ margin: 6 }}
                    onChange={onChange}
                  />{" "}
                  Par module RNCP
                </div>
              </div>
            </Col>
          </Row>
          {loading ? (
            <Col style={styles.center_spinner}>
              <Spin />
            </Col>
          ) : (
            <>
              {loadingSearch ? (
                <Col style={styles.center_spinner}>
                  <Spin />
                </Col>
              ) : !searchResult /*|| searchValue === '' */ ? (
                categories &&
                categories.length > 0 &&
                categories.map((e, i) => (
                  <SyllabusCategory
                    category={e}
                    key={i}
                    index={i}
                    type={pageType}
                    onItemClick={onItemClick}
                  />
                ))
              ) : (
                <Col style={styles.search_container}>
                  <Col style={styles.search_menu}>
                    <h3 style={styles.title}>
                      {t("Ressource", { count: searchResult?.length })}
                    </h3>
                    <SimpleBar
                      forceVisible
                      style={{
                        maxHeight: "calc(100vh - 250px)",
                        marginLeft: 20,
                      }}
                    >
                      {searchResult?.length > 0 &&
                        searchResult
                          .filter(e =>
                            user.type === "student"
                              ? e.mainGroup !== "Teachers"
                              : true
                          )
                          .map((e, i) => (
                            <div onClick={() => selectResource(e.stepId)}>
                              <Resource
                                resource={e}
                                key={i}
                                // stepsUnlocked={e.enable}
                                index={i}
                                // QCMUnlocked={e.unlockedQuiz}
                                // flashcardsUnlocked={e.unlockedFlashCard}
                                day={selectedDay}
                              />
                            </div>
                          ))}
                    </SimpleBar>
                  </Col>
                </Col>
              )}
              <Modal
                visible={open}
                onCancel={toggleModal}
                title={selectedDay?.title || t("Chargement")}
                footer={false}
                bodyStyle={styles.modal_body}
                width="fit-content"
                style={{ top: 56 }}
              >
                <Row style={{ width: "100%" }}>
                  {loadingModal ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Spin />
                    </div>
                  ) : (
                    <>
                      <Row>
                        <h3 style={styles.modal_title}>
                          {t("Comprendre").toUpperCase()}
                        </h3>
                      </Row>
                      <Row>
                        <h4
                          style={{
                            ...styles.modal_title,
                            ...styles.modal_subtitle,
                          }}
                        >
                          {t("Notions abordées")}
                        </h4>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        {selectedDay?.steps.length > 0 &&
                          selectedDay?.steps
                            .filter(e =>
                              user.type === "student"
                                ? e.mainGroup !== "Teachers"
                                : true
                            )
                            .map((e, i) => (
                              <div onClick={() => selectDay()}>
                                <Resource
                                  resource={e}
                                  key={i}
                                  index={i}
                                  // QCMUnlocked={e.unlockedQuiz}
                                  // stepsUnlocked={e.enable}
                                  // flashcardsUnlocked={e.unlockedFlashCard}
                                  day={selectedDay}
                                  toggleModal={
                                    toggleModal
                                  }
                                /* changeStep={changeStep} */
                                />
                              </div>
                            ))}
                      </Row>
                    </>
                  )}
                </Row>
              </Modal>
            </>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

function mapStateToProps({ batchid, user, tracks, batch, language, stack }) {
  return { batchid, user, tracks, batch, language, stack };
}

function mapDispatchToProps(dispatch) {
  return {
    setDay: function (day) {
      dispatch({ type: "changeDay", day });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Syllabus);

const styles = {
  page_container: {
    height: "100%",
    overflowY: "scroll",
    width: "100vw",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  search_menu: {
    minWidth: "500px",
    maxWidth: "740px",
    width: "60%",
    margin: "auto",
  },
  search_container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 29,
  },
  center_spinner: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 29,
  },
  menu_container: {
    paddingTop: 29,
    paddingBottom: 29,
    position: "sticky",
    top: 0,
    left: 0,
    backgroundColor: "#F6F7FB",
    zIndex: 1000,
  },
  modal_title: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
  },
  modal_subtitle: {
    fontWeight: 300,
  },
  modal_body: {
    backgroundColor: "#F6F7FB",
    padding: 33,
    overflow: "scroll",
    maxHeight: "80vh",
    width: "60vw",
  },
  modal_content: {
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "65px",
    border: "1px solid #f0f2f5",
    minWidth: "500px",
    width: "60vw",
    position: "relative",
  },
  title: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "21.09px",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    position: "relative",
    left: "2%",
    color: "#010031",
    fontFamily: '"Poppins", sans-serif',
  },
  titleSwitch: {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: '"Poppins", sans-serif',
    marginRight: "10px",
  },
};
