import React, { Component } from 'react';
import { Layout, Spin, Icon } from 'antd';
import Header from './Header/Header';
// import SiderIcons from './Components/SiderIcons';
import UserInfo from './UserFile/UserInfo';
import { connect } from 'react-redux';

// const { Sider } = Layout;

class UserFile extends Component {
    state={
        user: null,
        loading: true,
    }
    componentDidMount(){

        const url = window.location.href;
        const data = url.split("/user/")[1]
        if(data){
            fetch(global.URI_BACKEND + `/user/info/${data}`).then(res => res.json()).then(async data => {
                if(data.result){
                 this.setState({user:data.user, loading:false}) ;
            }})
        }
        
    }

    userEdit = (user) => {
        console.log(user)
      }

    render(){
        const {layout} = styles;
        // const { currentUser, match, day } = this.props;

        return (
            <Layout style={layout}>
                <Header />
                
                <Layout style={{ flex: 1 }}>
                    {/* <Sider width={40} style={{ background: '#fff' }}>
                    <SiderIcons day={day} user={currentUser} path={''} match={match} />
                    </Sider> */}

                   {!this.state.loading && this.state.user ? <UserInfo editUser={this.state.user} parentEdit={this.userEdit}/>
                   : <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh'}}><Spin indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />} /></div>}

                </Layout>

            </Layout>
        )
    }
}

function mapStateToProps({ user, day }) {
    return { currentUser: user, day };
}
  
export default connect(mapStateToProps, null)(UserFile);

const styles = {
    layout: { minHeight: '100vh', height: 'auto' },
}