import { toRegex } from 'diacritic-regex';

function literal(tc_lit = {}, pb_lit = {}) {
  if (typeof tc_lit.value === 'string' && typeof pb_lit.value === 'string') {
    const reg = toRegex()(tc_lit.value); // "Léo" -> /L[EeÈèÉéÊêËë][OoÒòÓóÔôÕõÖö]/i

    return !!(pb_lit.value?.match(reg));
  }

  return tc_lit.value === pb_lit.value;
}

export default literal;