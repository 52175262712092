var infiniteLoopDetector = (function() {
    var map = {}
  
    function infiniteLoopDetector(id) {
      if (id in map) {
        if (Date.now() - map[id] > 1000) {
          delete map[id];
          let error = new Error('InfiniteLoopError');
          throw error;
        }
      } else { 
        map[id] = Date.now()
      }
    }
  
    infiniteLoopDetector.wrap = function(codeStr) {
      if (typeof codeStr !== 'string') {
        throw new Error('Can only wrap code represented by string, not any other thing at the time! If you want to wrap a function, convert it to string first.')
      }
      // this is not a strong regex, but enough to use at the time
      return codeStr.replace(/for *\(.*\{|while *\(.*\{|do *\{/g, function(loopHead) {
        var id = parseInt(Math.random() * Number.MAX_SAFE_INTEGER)
        return `infiniteLoopDetector(${id});${loopHead}infiniteLoopDetector(${id});`
      })
    }
  
    infiniteLoopDetector.unwrap = function(codeStr) {
      return codeStr.replace(/infiniteLoopDetector\([0-9]*?\);/g, '')
    }
  
    return infiniteLoopDetector
  }())

export default infiniteLoopDetector;